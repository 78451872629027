import React from 'react';

import {
	Typography, Divider, Paper
} from '@material-ui/core';

import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const DoctorPatientsRatio = (props) => {
	const doctorPatientsRatio = props.doctorPatientsRatio;
	
	const toRatio = (ratio) => {
		return '1 : '+ratio.toFixed();
	}

	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={5}>
			<div className="card-title" style={{paddingTop: 8, paddingBottom: 8 }}>BUDDY-PATIENTS RATIO</div>
			<Divider/>
			<div className="pie-chart" align="center">
				<CircularProgressbarWithChildren
						counterClockwise
						strokeWidth={8}
						value={10}
						styles={{
							path: {
								stroke: '#3AD5C1',
							},
						}}
					>
					<CircularProgressbar
						strokeWidth={6}
						value={1 - (doctorPatientsRatio / 100)}
						maxValue={1}
						text={toRatio(doctorPatientsRatio)}
						styles={{
							trail:{
								stroke: 'transparent',
							},
							path: {
								stroke: '#4C6FEE',
							},
							text: {
								fill: '#4C6FEE',
								fontSize: '18px'
							}
						}}
					/>
				</CircularProgressbarWithChildren>
				<br/>
				<Typography variant='caption' style={{ color: '#3AD5C1' }}>TARGET </Typography>
				/
				<Typography variant='caption' style={{ color: '#4C6FEE' }}> ACTUAL</Typography>

			</div>
		</Paper>
	);
}

export default DoctorPatientsRatio;