import Cookies from 'universal-cookie';
import React from 'react';
import axios from 'axios';

let cookies = null;
if (process.env.NODE_ENV === 'development') {
	cookies = new Cookies();
}

class QBLogout extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		};
		if (process.env.NODE_ENV === 'development') {
			const token = Buffer.from(`${this.state.email}:${this.state.password}`, 'utf8').toString('base64');
			const url = 'https://covidcheck.upcebu.edu.ph/dev/api/qb/auth';
			axios.delete(url, {headers: {'Authorization': `Basic ${token}`}})
				.then(response => {
					this.props.onLogout();
				})
				.catch(error => {
					this.props.onLogout();
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 'https://covidcheck.upcebu.edu.ph/dev/api/qb/auth' : 'https://covidcheck.upcebu.edu.ph/api/qb/auth';
			axios.delete(url, {withCredentials: true})
				.then(response => {
					this.props.onLogout();
				})
				.catch(error => {
					this.props.onLogout();
				});
		}
	}

	render() {
		return null;
	}
}

export default QBLogout;
