import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  Redirect
} from "react-router-dom";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BoardMenu from './BoardMenu';

import EditHealthStatus from './EditHealthStatus';
import AddHealthStatus from './AddHealthStatus';
import ViewPatients from './ViewPatients';
import ViewDoctors from './ViewDoctors';
import AdmitPatient from './AdmitPatient_Form';
import AddUpdateDoctor from './AddUpdateDoctor';
import HealthStatusMonitoring from './HealthStatusMonitoring';
import QBLoginComponent from './QBLoginComponent';
import QBLogout from './QBLogout';
import QBChangePasswordComponent from './QBChangePasswordComponent';
import PatientInfo from './PatientInfo';
import UpdatePatientInfo from './UpdatePatientInfo';
import EditLabTest from './EditLabTest';
import AddLabTest from './AddLabTest';
import Dashboard from './Dashboard';
import LaboratoryTests from './LaboratoryTests';

import Menu from '@material-ui/core/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import {isMobile} from 'react-device-detect';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
		background: '#eee',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
	background: '#4C6FEE',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
    width: theme.spacing(9),
    },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function QBMainBoard(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [open, setOpen] = React.useState(props.loggedIn ? (isMobile ? false : true) : false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openUser, setOpenUser] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpenUser((prevOpen) => !prevOpen);
    };

    const handleCloseUser = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenUser(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenUser(false);
        }
    }

    const prevOpen = React.useRef(openUser);
    React.useEffect(() => {
        if (prevOpen.current === true && openUser === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = openUser;
    }, [openUser]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, (props.loggedIn ? open : false) && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                {(() => {
                    if(props.loggedIn){
                        return(
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon />
                            </IconButton>
                        );
                    }
                })()}
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    Quarantine Buddy
                </Typography>
                {(() => {
                    if(props.loggedIn){
                        return(
                            <>
                                <IconButton 
                                    color="inherit"
                                    ref={anchorRef}
                                    aria-controls={openUser ? 'actions-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}>
                                    { !isMobile && <Typography component="h1" variant="subtitle1" color="inherit" noWrap style={{paddingRight: 10}}>{props.currentUser}</Typography> }
                                    <AccountCircleIcon />
                                </IconButton>
                                <Popper open={openUser} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseUser}>
                                                    <MenuList autoFocusItem={openUser} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                        <MenuItem component={RouterLink} onClick={handleCloseUser} to='/qb-change-password'>
                                                            Change Password
                                                        </MenuItem>
                                                        <MenuItem component={RouterLink} onClick={handleCloseUser} to='/qb-logout'>
                                                            Logout
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </>
                        );
                    }
                })()}
            </Toolbar>
            </AppBar>
            {(() => {
                if(props.loggedIn){
                    if(isMobile && open){
                        return(
                            <Drawer
                                variant="permanent"
                                classes={{
                                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                                }}
                                open={open}
                            >
                                <div className={classes.toolbarIcon}>
                                    <IconButton onClick={handleDrawerClose}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                                <BoardMenu 
                                    loggedIn={props.loggedIn} 
                                    handleDrawerOpen={handleDrawerOpen}
                                    handleDrawerClose={handleDrawerClose} 
                                    drawerOpen={open}/>
                            </Drawer>
                        );
                    }else if(!isMobile){
                        return(
                            <Drawer
                                variant="permanent"
                                classes={{
                                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                                }}
                                open={open}
                            >
                                <div className={classes.toolbarIcon}>
                                    <IconButton onClick={handleDrawerClose}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                                <BoardMenu 
                                    loggedIn={props.loggedIn} 
                                    handleDrawerOpen={handleDrawerOpen}
                                    handleDrawerClose={handleDrawerClose} 
                                    drawerOpen={open}/>
                            </Drawer>
                        );
                    }
                }
            })()}
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    <Switch>
                        {/** Set `/qb` as prefix of pathname for qb-related paths **/}
                        { props.redirectTo && <Redirect to={props.redirectTo} />}
                        <Route path="/qb-login" render={() => (<QBLoginComponent onLogin={props.onLogin}/>)}/>
                        { props.loggedIn && <Route path = "/qb-logout" render={() => (<QBLogout onLogout={props.onLogout} />)} /> }
                        { props.loggedIn && <Route path = "/qb-change-password" render={() => (<QBChangePasswordComponent onSuccess={props.onChangePasswordSuccess} />)} /> }
                        { props.loggedIn && <Route path = "/qb/:facility" component = {(props) => <Dashboard {...props} key={window.location.pathname}/>} />}
                        { props.loggedIn && <Route path = "/qb" component = {Dashboard} />}
                        { props.loggedIn && <Route path = "/qb-patients" component = {ViewPatients} />}
                        { props.loggedIn && <Route path = "/qb-admit-patient" component = {AdmitPatient} />}
                        { props.loggedIn && <Route path = "/qb-patient-info/:id" component = {PatientInfo} />}
                        { props.loggedIn && <Route path = "/qb-update-patient/:id" component = {UpdatePatientInfo} />}
                        { props.loggedIn && <Route path = "/qb-patient-health-status/:id" component = {HealthStatusMonitoring} />}
                        { props.loggedIn && <Route path = "/qb-doctors" component = {ViewDoctors} />}
                        { props.loggedIn && <Route path = "/qb-admit-patient" component = {AdmitPatient} />}
                        { props.loggedIn && <Route path = "/qb-add-health-status/:id" component = {AddHealthStatus} />}
                        { props.loggedIn && <Route path = "/qb-update-health-status/:patient_id/:log_id" component = {EditHealthStatus} />}
                        { props.loggedIn && <Route path = "/qb-add-doctor" component = {AddUpdateDoctor} />}
                        { props.loggedIn && <Route path = "/qb-update-doctor/:id" component = {AddUpdateDoctor} />}
                        { props.loggedIn && <Route path = "/qb-patient-lab-tests/:id" component = {LaboratoryTests} />}
                        { props.loggedIn && <Route path = "/qb-add-lab-test/:patient_id" component = {AddLabTest} />}
                        { props.loggedIn && <Route path = "/qb-update-lab-test/:patient_id/:lab_test_id" component = {EditLabTest} />}
                    </Switch>
                </Container>
            </main>
        </div>
    );
}
