import React from 'react';
import moment from 'moment';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';
import TestActionMenu from './TestActionMenu';

import { Link, withRouter } from 'react-router-dom';

import {
	Button, Divider, Paper, Grid, Typography
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const customStyles = {
	headCells: {
		style: {
			textAlign: 'center',
			color: '#202124',
			fontSize: '12px',
			fontWeight: 'bold',
		},
	},
};

class ViewTests extends React.PureComponent {
	state = {
		tests: [],
		report: {},
		patietName: '',
		reportId: this.props.match.params.id,
		loading: false,
	};

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
                    this.setState({
                    	report: result.data,
                        patientName: result.data.name,
                    });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
                   	this.setState({
                    	report: result.data,
                        patientName: result.data.name,
                    });
				});
		}
		this.loadData();
	}

	loadData = () => {
		this.setState({ loading: true });
		let url = `/api/reports/${this.state.reportId}/tests?`;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						tests: result.data.covidTests,
						loading: false
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						tests: result.data.covidTests,
						loading: false
					});
				});
		}
	}

	filterTests = () => {
		this.loadData();
	}

	render(){
		const columns = [
			{ cell: item => <TestActionMenu row={item} filterTests={this.filterTests}/>,
				allowOverflow: true,
				button: true,
				width: '15px',
			},
			{ name: 'Date', sortable: true, width: '150px',
				cell: item => item.date_time ? moment(item.date_time).format('L') : '',
			},
			{ name: 'Type of Test', selector: 'type', sortable: true, width: '250px',
			},
			{ name: 'Result', selector: 'result', wrap: true,
			},
			{ name: 'Remarks', selector: 'remarks', wrap: true,
			},
		];

		return(
			<>
				<Paper style={{ padding : 20 }}>
					<div align='left'>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							COVID Tests
						</Typography>
						<Typography component="h1" variant="subtitle1" color="textPrimary" gutterBottom>
							{this.state.patientName}
						</Typography>
					</div>
					<Divider/>
					<br/>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left'>
					        	<Button align='left' size="small" variant="outlined" startIcon={<ArrowBackIcon />} 
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			component={Link} to='/view-reports'>
				        			Back</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button size="small"
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									variant="outlined"
									color="primary"
									component={Link}
									to ={`/report-add-covid-test/${this.state.reportId}`}
									startIcon={<AddIcon />}>
										Add COVID Test
								</Button>
							</div>
						</Grid>
					</Grid>
					<Divider/>
					<DataTable
						noHeader
						columns={columns}
						data={this.state.tests}
						progressPending={this.state.loading}
						progressComponent={<TableLoader loadingText="COVID Tests"/>}
						pagination
						customStyles={customStyles}
					/>
				</Paper>
			</>
		);
	}
}

export default withRouter(ViewTests);