import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import {
    Divider, Paper, Typography, TextField, Grid
} from '@material-ui/core';

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 5px;	

	td {
		padding: 5px;
	}
`;

class UpdateQuarantineInfo extends Component {
	state = {
		report: {},
		aqfError: false,
		loading:false,
		date_responded_error: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
				})
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
				})
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		event.preventDefault();
		let valid = true;

		if(this.state.report.admit_quarantine_facility.trim() === ""){
			valid = false;
			this.setState({ aqfError: true });
		}else{
			this.setState({ aqfError: false });
		}
		if(!this.state.report.date_responded){
			valid = false;
			this.setState({ date_responded_error: true });
		}else{
			this.setState({ date_responded_error: false });
		}

		if(valid){
			this.setState({ loading: true });
			const body = { 
				"admit_quarantine_facility" : this.state.report.admit_quarantine_facility,
				"date_responded": this.state.report.date_responded instanceof Date ? this.state.report.date_responded.toISOString() : this.state.report.date_responded 
			}

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}else{
							this.setState({ loading: false });
						}
					});
			} else {
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}else{
							
							this.setState({ loading: false });
						}
					});
			}
		}
	}

	handleChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.value;
		this.setState({ report: report });
	}

	dateStr = d => {
		if(d == null){
			d = new Date();
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		const classes = {margin:1};
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div className="for_select" align="left" style={{ width: 350}}>
                        <Paper style={{padding: 10}}>
                            <Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
                                Update Quarantine Information
                            </Typography>
                            <Divider/>
		                    <Typography component="h1" variant="subtitle1" color="textSecondary" style={{ padding: 10 }} align='left' gutterBottom>
                                Patient: {this.state.report.name}
                            </Typography>
                            <Table className="adPatient-table">
							<tbody>
								<tr>
									<td>
									<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
										{'Date Started Monitoring'}
									</Typography>
									<TextField
										id="outlined-basic" 
										variant="outlined" 
										fullWidth={true}
										className={classes.margin}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
										type = "date"
										onChange={ this.handleChange('date_responded') }
										value = { this.dateStr(this.state.report.date_responded) }
										InputLabelProps={{ shrink: true, }}
										error = { this.state.date_responded_error }
										helperText = { this.state.date_responded_error_text }
									/>
									</td>
								</tr>
								<br/>
								<tr>
									<td>
										<TextField
					                    	label={"Admission/Quarantine Facility"}
											onChange={this.handleChange('admit_quarantine_facility')}
					                        value = {this.state.report.admit_quarantine_facility}
					                        error = { this.state.aqfError }
											id="outlined-basic" 
											variant="outlined" 
											fullWidth={true}
											className={classes.margin}
											InputLabelProps={{
												shrink: true,
												className: classes.floatingLabelFocusStyle,
											}}
										/>
									</td>
								</tr>
							</tbody>
							</Table>
                            <br/>
                            <Divider/>
                            <br/>
                            <Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<div align='left'>
							        	<Button align='left' size="large" variant="outlined" color='secondary'
							        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
							        			onClick={this.handleBack}>
						        			CANCEL</Button>
									</div>
								</Grid>
								<Grid item xs={12} md={6}>
									<div align='right' style={{ paddingBottom: 10 }}>
										<Button
											fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
											size='large'
											variant='contained'
		                                    color='primary'
											onClick={this.handleConfirm} 
                                            disabled = {this.state.loading}>
                                                {this.state.loading? "SAVING..":"SAVE"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Paper>
						</div>
			      	</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateQuarantineInfo;
