import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class UpdateCaseInfo extends Component {
	state = {
		case_data: {},
		admission_facility: "",
		history_travel_exposure: "",
		date_lab_confirmation_error: false,
		date_lab_confirmation_error_text: "",
	}

	componentDidMount(){
		window.scrollTo(0,0);

		const caseId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ case_data: result.data });
					this.setState({ admission_facility: this.state.case_data.admission_facility, history_travel_exposure: this.state.case_data.history_travel_exposure });
				});
		} else {
			const casesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}` : `https://covidcheck.upcebu.edu.ph/api/cases/${caseId}`;
			
			axios(casesUrl, {withCredentials: true})
				.then(result => {
					this.setState({ case_data: result.data });
					this.setState({ admission_facility: this.state.case_data.admission_facility, history_travel_exposure: this.state.case_data.history_travel_exposure });
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-cases');
	}

	handleConfirm = event => {
		event.preventDefault();

		if(this.state.case_data.date_lab_confirmation == null || this.state.case_data.date_lab_confirmation == ""){
			this.setState({ date_lab_confirmation_error: true, date_lab_confirmation_error_text: "Input is required"});
		}else{

			const case_data = this.state.case_data;
			if(case_data.date_admission == ""){
				case_data.date_admission = null;
			}
			if(case_data.date_onset_symptoms == ""){
				case_data.date_onset_symptoms = null;
			}
			if(case_data.date_recovered == ""){
				case_data.date_recovered = null;
			}
			if(case_data.date_deceased == ""){
				case_data.date_deceased = null;
			}
			const caseId = case_data.id;
			delete case_data.id;
			delete case_data.name;
			delete case_data.age;
			delete case_data.sex;
			delete case_data.nationality;
			delete case_data.address_barangay;
			delete case_data.address_city;

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}`, case_data, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-cases');
						}
					});
			} else {
				const casesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}` : `https://covidcheck.upcebu.edu.ph/api/cases/${caseId}`;
			
				axios.put(casesUrl, case_data, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-cases');
						}
					});
			}
		}
	}

	handleChange = field => event => {
		const case_data = this.state.case_data;
		case_data[field] = event.target.value;
		this.setState({ case_data: case_data });
		this.setState({
			admission_facility: this.state.case_data.admission_facility,
		 	history_travel_exposure: this.state.case_data.history_travel_exposure });
	}

	dateStr = d => {
		if(d == null){
			return "";
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<p>{this.state.case_data.name} ({this.state.case_data.status})<br/>Case Information</p>
							<br/>
						</div>
						<div>
							<TextField
		                        label = "Date of Laboratory Confirmation"
		                        type = "date"
		                        onChange={ this.handleChange('date_lab_confirmation') }
		                        value = { this.dateStr(this.state.case_data.date_lab_confirmation) }
		                        style = {{ width: 250 }}
		                        InputLabelProps={{ shrink: true, }}
		                        error = { this.state.date_lab_confirmation_error }
		                        helperText = { this.state.date_lab_confirmation_error_text }
		                    />
		                    <br/>
		                    <br/>
							<TextField
		                        label = "History of Travel / Exposure"
		                        onChange={ this.handleChange('history_travel_exposure') }
		                        value = { this.state.history_travel_exposure }
		                        style = {{ width: 250 }}
		                    />
		                    <br/>
		                    <br/>
		                    <TextField
		                        label = "Date of Onset of Symptoms"
		                        type = "date"
		                        onChange={ this.handleChange('date_onset_symptoms') }
		                        value = { this.dateStr(this.state.case_data.date_onset_symptoms) }
		                        style = {{ width: 250 }}
		                        InputLabelProps={{ shrink: true, }}
		                    />
		                    <br/>
		                    <br/>
		                    <TextField
		                        label = "Date of Admission/Quarantine"
		                        type = "date"
		                        onChange={ this.handleChange('date_admission') }
		                        value = { this.dateStr(this.state.case_data.date_admission) }
		                        style = {{ width: 250 }}
		                        InputLabelProps={{ shrink: true, }}
		                    />
		                    <br/>
		                    <br/>
		                    <TextField
		                        label = "Admission/Quarantine Facility"
		                        onChange={ this.handleChange('admission_facility') }
		                        value = { this.state.admission_facility }
		                        style = {{ width: 250 }}
		                    />
		                    {(() => {
		                    	if(this.state.case_data.status == "Recovered"){
		                    		return(
		                    			<React.Fragment>
		                    				<br/>
						                    <br/>
						                    <TextField
						                        label = "Date Recovered"
						                        type = "date"
						                        onChange={ this.handleChange('date_recovered') }
						                        value = { this.dateStr(this.state.case_data.date_recovered) }
						                        style = {{ width: 250 }}
						                        InputLabelProps={{ shrink: true, }}
						                    />
		                    			</React.Fragment>
		                    		);
		                    	}else if(this.state.case_data.status == "Deceased"){
		                    		return(
		                    			<React.Fragment>
		                    				<br/>
						                    <br/>
						                    <TextField
						                        label = "Date Deceased"
						                        type = "date"
						                        onChange={ this.handleChange('date_deceased') }
						                        value = { this.dateStr(this.state.case_data.date_deceased) }
						                        style = {{ width: 250 }}
						                        InputLabelProps={{ shrink: true, }}
						                    />
		                    			</React.Fragment>
		                    		);
		                    	}
		                    })()}
						</div>
						<div>
							<br/>
		                    <Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
		                    &nbsp;&nbsp;&nbsp;
		                    <Button size="small" variant="contained" color="primary" onClick={this.handleConfirm}>Confirm</Button>
						</div>
					</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateCaseInfo;
