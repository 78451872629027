import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ContactsIcon from '@material-ui/icons/Contacts';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import SelectReact from 'react-select';

import './QB_Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const Table = styled.table`
	padding: 20px;	

	td {
		padding: 5px;
	}
`;

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4C6FEE',
    },
	'& .MuiInput-underline-root': {
		'& fieldset': {
			borderBottomColor: '#4C6FEE',
		},
		'&:after': {
		    borderBottomColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderBottomColor: '#4C6FEE',
		},
    },
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderColor: '#3AD5C1',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#4C6FEE',
		},
    },
  },
})(TextField);

const customStyles = {
  	control: (base, state) => ({
      ...base,
	 background: 'white',
	 height: 56,
      borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
      "&:hover": {
        borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
      }
    }),
    menu: base => ({
		...base,
		zIndex: 100
	})
}

const discharge_options = [
	{ value: 'Recovered', label: 'Recovered' },
	{ value: 'Discharged', label: 'Transfer to Hospital' }
];

const dateStr = (d) => {
	if(d == null){
		d = new Date();
	}
	const dateNow = new Date(d);
	const year = dateNow.getFullYear();
	const monthWithOffset = dateNow.getUTCMonth() + 1;
	const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
	const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
	const date_str = `${year}-${month}-${date}`;
	return date_str;
}

const filterDoctors = (doctors, patientFacilityId) => {
	const newDoctors = [];
	doctors.map(d => {
		if(d.assigned_facilities.includes(patientFacilityId)){
			newDoctors.push(d);
		}
	});
	return newDoctors;
}

const PatientActionMenu = (props) => {
	const item = props.row;
	const daysLapsed = props.daysLapsed;
	const userRole = props.userRole;
	const classes = {};

	const [anchorEl, setAnchorEl] = React.useState(null);

	const [openDelete, setOpenDelete] = React.useState(false);

	const [openDischarge, setOpenDischarge] = React.useState(false);
	const [dateDischarged, setDateDischarged] = React.useState(dateStr(new Date()));
	const [dischargeMemo, setDischargeMemo] = React.useState('');
	const [errorDateDischarge, setErrorDateDischarge] = React.useState(false);
	const [dischargeOption, setDischargeOption] = React.useState({ value: 'Recovered', label: 'Recovered' });

	const [openDeceased, setOpenDeceased] = React.useState(false);
	const [dateDeceased, setDateDeceased] = React.useState(dateStr(new Date()));
	const [errorDateDeceased, setErrorDateDeceased] = React.useState(false);
	const [timeDeath, setTimeDeath] = React.useState('08:00');
	const [causeOfDeath, setCauseOfDeath] = React.useState('');
	const [errorTimeDeath, setErrorTimeDeath] = React.useState(false);

	const [openAssignDoctor, setOpenAssignDoctor] = React.useState(false);
	const [doctors, setDoctors] = React.useState(filterDoctors(props.doctorsOption, item.facility_id));
	const [selectedDoctor, setSelectedDoctor] = React.useState(null);

	const [openGraduate, setOpenGraduate] = React.useState(false);
	const [dateGraduated, setDateGraduated] = React.useState(null);
	const [errorDateGraduated, setErrorDateGraduated] = React.useState(false);

	const handleOpenGraduate = () => {
		setDateGraduated(dateStr(new Date(new Date(item.date_status).getTime() + (14 * 24 * 60 * 60 * 1000))));
		setOpenGraduate(true);
		setAnchorEl(null);
	}

	const handleCloseGraduate = () => {
		setOpenGraduate(false);
	}

	const handleConfirmGraduate = () => {
		if(dateGraduated){
			setErrorDateGraduated(false);
			const patientId = item.id;
			const body = {
				status: 'Completed Home Quarantine',
				date_status: (dateGraduated instanceof Date) ? dateGraduated.toISOString() : dateGraduated
			};
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}
		}else{
			setErrorDateGraduated(true);
		}
	}

	const handleOpenDelete = () => {
		setOpenDelete(true);
		setAnchorEl(null);
	}

	const handleCloseDelete = () => {
		setOpenDelete(false);
	}

	const handleDelete = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${item.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						props.filterPatients();
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${item.id}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${item.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						props.filterPatients();
					}
				});
		}
	}

	const handleOpenDischarge = () => {
		setOpenDischarge(true);
		setAnchorEl(null);
	}

	const handleCloseDischarge = () => {
		setOpenDischarge(false);
	}

	const handleDischarge = () => {
		if(dateDischarged){
			setErrorDateDischarge(false);
			const patientId = item.id;
			const body = {
				status: dischargeOption.value,
				date_status: (dateDischarged instanceof Date) ? dateDischarged.toISOString() : dateDischarged,
				discharge_memo: dischargeMemo
			};
			if(selectedDoctor){
				body.doctor_id = selectedDoctor.value;
			}
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}
		}else{
			setErrorDateDischarge(true);
		}
	}

	const handleOpenDeceased = () => {
		setOpenDeceased(true);
		setAnchorEl(null);
	}

	const handleCloseDeceased = () => {
		setOpenDeceased(false);
	}

	const handleDeceased = () => {
		var valid = true;
		if(!dateDeceased){
			valid = false;
			setErrorDateDeceased(true);
		}else{
			setErrorDateDeceased(false);
		}
		if(!timeDeath){
			valid = false;
			setErrorTimeDeath(true);
		}else{
			setErrorTimeDeath(false);
		}

		if(valid){
			const patientId = item.id;
			const time = timeDeath;
			const date = new Date(dateDeceased);
			const datetime = new Date(date.getFullYear(), date.getUTCMonth(), 
								date.getUTCDate(), parseInt(time.split(':')[0]),
								 parseInt(time.split(':')[1]), 0);
			const body = {
				status: 'Deceased',
				date_status: datetime.toISOString(),
				cause_of_death: causeOfDeath
			};
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}
		}
	}

	const handleOpenAssignDoctor = () =>{
		setSelectedDoctor(doctors.filter(d => d.value === item.doctor_id));
		setOpenAssignDoctor(true);
		setAnchorEl(null);
	}

	const handleCloseAssignDoctor = () => {
		setOpenAssignDoctor(false);
	}

	const handleAssignDoctor = () => {
		if(selectedDoctor){
			const patientId = item.id;
			const body = {
				doctor_id: selectedDoctor.value
			};
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							props.filterPatients();
						}
					});
			}
		}
	}

	const handleFieldChange = field => event => {
		if(field === 'date_discharge'){
			setDateDischarged(event.target.value);
		}else if(field === 'discharge_memo'){
			setDischargeMemo(event.target.value);
		}else if(field === 'date_deceased'){
			setDateDeceased(event.target.value);
		}else if(field === 'time_death'){
			setTimeDeath(event.target.value);
		}else if(field === 'cause_of_death'){
			setCauseOfDeath(event.target.value);
		}else if(field === 'date_graduated'){
			setDateGraduated(event.target.value);
		}
	}

	const handleChangeOption = selectedOption => {
		setDischargeOption(selectedOption);
	}

	const handleChangeDoctor = selectedOption => {
		setSelectedDoctor(selectedOption);
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return(
		<React.Fragment>
			<Button size="small" 
					aria-controls="actions-menu" 
					aria-haspopup="true" 
					startIcon={<ArrowForwardIosIcon/>}
					onClick={handleClick}>
			</Button>
			<Menu
				id="actions-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{(() => {
					if(userRole === 'Admin' || userRole === 'Coordinator'){
						return(
							<>
								<MenuItem component={Link} to={`/qb-patient-info/${item.id}`}>
									View Patient Information
								</MenuItem>
								{ userRole === 'Admin' ?
								<MenuItem component={Link} to={`/qb-update-patient/${item.id}`}>
									Update Patient Information
								</MenuItem> : null }
								<Divider/>
							</>
						);
					}
				})()}
				{(() => {
					if((item.status === 'Admitted' || item.status === 'Recovered') && userRole === 'Coordinator'){
						return(
							<>
								<MenuItem onClick={handleOpenAssignDoctor}>
									Assign Attending Doctor
								</MenuItem>
								<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openAssignDoctor}
									keepMounted
									onClose={handleCloseAssignDoctor}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Attending Doctor</DialogTitle>
									<DialogContent>
										<Typography component="h2" variant="subtitle1" color="textPrimary" gutterBottom>
									 		{item.last_name}, {item.first_name} {item.middle_name}
								    	</Typography>
								    	<Divider/>
								    	<Table className="adPatient-table">
										    <tbody>
										    	<Typography component="h1" variant="body2" color="textSecondary" gutterBottom style={{ paddingLeft: 5 }}>
													Attending Doctor
												</Typography>
												<tr>
													<td>
														<SelectReact
															styles={customStyles}
															options={doctors}
															value={selectedDoctor}
															searchable
															placeholder="Attending Doctor"
															fullWidth={true}
															onChange={handleChangeDoctor}
														/>
													</td>
												</tr>
											</tbody>
											<br/>
											<br/>
											<br/>
											<br/>
											<br/>
											<br/>
											<br/>
										</Table>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseAssignDoctor} color="primary">
											Cancel
										</Button>
										<Button onClick={handleAssignDoctor} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
							</>
						);
					}
				})()}
				<MenuItem component={Link} to={`/qb-patient-health-status/${item.id}`}>
					Health Status Monitoring
				</MenuItem>
				<MenuItem component={Link} to={`/qb-patient-lab-tests/${item.id}`}>
					Laboratory Tests
				</MenuItem>
				<Divider/>
				{(() => {
					if(item.status === 'Admitted' && userRole === 'Admin'){
						return(
							<>
								<MenuItem onClick={handleOpenDischarge}>
									Discharge
								</MenuItem>
								<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openDischarge}
									keepMounted
									onClose={handleCloseDischarge}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Discharge Patient</DialogTitle>
									<DialogContent>
										<Typography component="h2" variant="subtitle1" color="textPrimary" gutterBottom>
									 		{item.last_name}, {item.first_name} {item.middle_name}
								    	</Typography>
								    	<Divider/>
								    	<Table className="adPatient-table">
										    <tbody>
												<tr>
													<td>
														<CssTextField 
															onChange={handleFieldChange('date_discharge')}
															value = {dateDischarged}
															type='date'
															id="outlined-basic" 
															label="Date Discharged" 
															variant="outlined" 
															fullWidth={true}
															className={classes.margin}
															error={errorDateDischarge}
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
												<Typography component="h1" variant="body2" color="textSecondary" gutterBottom style={{ paddingLeft: 5 }}>
													Nature of Discharge
												</Typography>
												<tr>
													<td>
														<SelectReact
															styles={customStyles}
															options={discharge_options}
															value={dischargeOption}
															placeholder="Nature of Discharge"
															fullWidth={true}
															onChange={handleChangeOption}
														/>
													</td>
												</tr>
												{(() => {
													if(dischargeOption.value === 'Recovered'){
														return(
															<tr>
																<td>
																	<SelectReact
																		styles={customStyles}
																		options={doctors}
																		value={selectedDoctor}
																		searchable
																		placeholder="Attending Doctor"
																		fullWidth={true}
																		onChange={handleChangeDoctor}
																	/>
																</td>
															</tr>
														);
													}
												})()}	
												<tr>
													<td>
														<CssTextField
															rows={4} 
															onChange={handleFieldChange('discharge_memo')}
															value = {dischargeMemo}
															id="outlined-basic" 
															label="Discharge Note" 
															variant="outlined"  
															fullWidth={true}
															multiline
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
											</tbody>
										</Table>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseDischarge} color="primary">
											Cancel
										</Button>
										<Button onClick={handleDischarge} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
							</>
						);
					}
				})()}
				{(() => {
					if(item.status === 'Admitted' && userRole === 'Admin'){
						return(
							<>
								<MenuItem onClick={handleOpenDeceased}>
									Deceased
								</MenuItem>
								<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openDeceased}
									keepMounted
									onClose={handleCloseDeceased}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Patient Deceased</DialogTitle>
									<DialogContent>
										<Typography component="h2" variant="subtitle1" color="textPrimary" gutterBottom>
									 		{item.last_name}, {item.first_name} {item.middle_name}
								    	</Typography>
								    	<Divider/>
								    	<Table className="adPatient-table">
										    <tbody>
												<tr>
													<td>
														<CssTextField 
															onChange={handleFieldChange('date_deceased')}
															value = {dateDeceased}
															type='date'
															id="outlined-basic" 
															label="Date Deceased" 
															variant="outlined" 
															fullWidth={true}
															className={classes.margin}
															error={errorDateDeceased}
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
												<tr>
													<td>
														<CssTextField 
															onChange={handleFieldChange('time_death')}
															value = {timeDeath}
															type='time'
															id="outlined-basic" 
															label="Time of Death" 
															variant="outlined" 
															fullWidth={true}
															className={classes.margin}
															error={errorTimeDeath}
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
												<tr>
													<td>
														<CssTextField
															rows={4} 
															onChange={handleFieldChange('cause_of_death')}
															value = {causeOfDeath}
															id="outlined-basic" 
															label="Cause of Death" 
															variant="outlined"  
															fullWidth={true}
															multiline
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
											</tbody>
										</Table>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseDeceased} color="primary">
											Cancel
										</Button>
										<Button onClick={handleDeceased} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
							</>
						);
					}
				})()}
				{(() => {
					if(item.status === 'Recovered' && userRole === 'Admin' && daysLapsed === '> 14'){
						return(
							<>
								<MenuItem onClick={handleOpenGraduate}>
									Completed After-Recovery Home Quarantine
								</MenuItem>
								<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openGraduate}
									keepMounted
									onClose={handleCloseGraduate}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Completed Home Quarantine</DialogTitle>
									<DialogContent>
										<Typography component="h2" variant="subtitle1" color="textPrimary" gutterBottom>
									 		{item.last_name}, {item.first_name} {item.middle_name}
								    	</Typography>
								    	<Divider/>
								    	<Table className="adPatient-table">
										    <tbody>
												<tr>
													<td>
														<CssTextField 
															onChange={handleFieldChange('date_graduated')}
															value = {dateGraduated}
															type='date'
															id="outlined-basic" 
															label="Date Completed Home Quarantine" 
															variant="outlined" 
															fullWidth={true}
															className={classes.margin}
															error={errorDateGraduated}
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
											</tbody>
										</Table>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseGraduate} color="primary">
											Cancel
										</Button>
										<Button onClick={handleConfirmGraduate} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
							</>
						);
					}
				})()}
				{(() => {
					if(userRole === 'Admin'){
						return(
							<>
								<Divider/>
								<MenuItem onClick={handleOpenDelete}>
									Delete
								</MenuItem>
								<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openDelete}
									keepMounted
									onClose={handleCloseDelete}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Delete Patient</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-slide-description">
											Delete selected Patient - {item.last_name}, {item.first_name} {item.middle_name}?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseDelete} color="primary">
											Cancel
										</Button>
										<Button onClick={handleDelete} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
							</>
						);
					}
				})()}
			</Menu>
		</React.Fragment>
	);
}

export default PatientActionMenu;