import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from 'material-ui/Checkbox';

import {
    Divider, Paper, Typography, Grid
} from '@material-ui/core';

import '../Forms.css';

const mobilePhonesBreakPoint = 400;

class UpdateSymptoms extends Component {
	state = {
        report: {},
        loading: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
				})
		} else {
            const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
				})
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
        this.setState({ loading: true });
		event.preventDefault();

		const report = this.state.report;
		const reportId = report.id;
		delete report.id;


		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, report, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false });
                    }
				}).catch(() => {
					const r = this.state.report;
					r.id = reportId;
                    this.setState({ 
                        report: r,
                        loading:false,
                     });
				});
		} else {
            const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios.put(url, report, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}
				}).catch(() => {
					const r = this.state.report;
					r.id = reportId;
                    this.setState({ 
                        report: r,
                        loading:false,
                     });
				});
		}
	}

	handleCheckChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.checked;
		this.setState({ report: report });
	}

	render() {
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div className="for_select" align="left" style={{ width: 350}}>
                        <Paper style={{padding: 10}}>
                            <Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
                                Update Symptoms
                            </Typography>
                            <Divider/>
		                    <Typography component="h1" variant="subtitle1" color="textSecondary" style={{ padding: 10 }} align='left' gutterBottom>
                                Patient: {this.state.report.name}
                            </Typography>
                            <div className="for_select" style={{paddingLeft: 10}}>
		                    <Checkbox
                                color="primary"
                                label="Fever (Hilanat)"
                                value="s_fever"
                                onCheck={this.handleCheckChange('s_fever')}
                                checked={this.state.report.s_fever}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Cough (Gahi nga ubo)"
                                value="s_cough"
                                onCheck={this.handleCheckChange('s_cough')}
                                checked={this.state.report.s_cough}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Short Breath(Lisud ug ginhawa)"
                                value="s_short_breath"
                                onCheck={this.handleCheckChange('s_short_breath')}
                                checked={this.state.report.s_short_breath}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Fatigue (Kakapoy sa panglawas)"
                                value="s_fatigue"
                                onCheck={this.handleCheckChange('s_fatigue')}
                                checked={this.state.report.s_fatigue}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Phlegm (Plema)"
                                value="s_phlegm"
                                onCheck={this.handleCheckChange('s_phlegm')}
                                checked={this.state.report.s_phlegm}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Muscle or Joint Pain (Sakit ang kalawasan)"
                                value="s_muscle_joint_pain"
                                onCheck={this.handleCheckChange('s_muscle_joint_pain')}
                                checked={this.state.report.s_muscle_joint_pain}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Sore Throat (Sakit ang tutunlan)"
                                value="s_sore_throat"
                                onCheck={this.handleCheckChange('s_sore_throat')}
                                checked={this.state.report.s_sore_throat}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Headache (Labad ang ulo)"
                                value="s_headache"
                                onCheck={this.handleCheckChange('s_headache')}
                                checked={this.state.report.s_headache}
                            />
                            <br/>
                            <Checkbox
                                label="Chills (Pagkurog sa kalawasan)"
                                value="s_chills"
                                onCheck={this.handleCheckChange('s_chills')}
                                checked={this.state.report.s_chills}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Vomiting (Nagsuka-suka)"
                                value="s_vomiting"
                                onCheck={this.handleCheckChange('s_vomiting')}
                                checked={this.state.report.s_vomiting}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Stuffy Nose (Ping-ot ang ilong)"
                                value="s_stuffed_nose"
                                onCheck={this.handleCheckChange('s_stuffed_nose')}
                                checked={this.state.report.s_stuffed_nose}
                            />
                            <br/>
                            <Checkbox
                                color="primary"
                                label="Diarrhea (Kalibanga)"
                                value="s_diarrhea"
                                onCheck={this.handleCheckChange('s_diarrhea')}
                                checked={this.state.report.s_diarrhea}
                            />
                            </div>
                            <br/>
                            <Divider/>
                            <br/>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <div align='left'>
                                        <Button align='left' size="large" variant="outlined" color='secondary'
                                                fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
                                                onClick={this.handleBack}>
                                            CANCEL</Button>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div align='right' style={{ paddingBottom: 10 }}>
                                        <Button
                                            fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
                                            size='large'
                                            variant='contained'
                                            color='primary'
                                            onClick={this.handleConfirm}
                                            disabled = {this.state.loading}>
                                                {this.state.loading? "SAVING..":"SAVE"}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
						</div>
			      	</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}

}

export default UpdateSymptoms;
