import Cookies from 'universal-cookie';
import LoginComponent from './components/LoginComponent';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';
import { Divider, Typography } from '@material-ui/core';

import './Login.css';

let cookies = null;
if (process.env.NODE_ENV === 'development') {
	cookies = new Cookies();
}

class LogIn extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			errors: ''
		};
	}

	onLogin = () => {
		const body = {
			username: this.state.email,
			password: this.state.password
		};
		if (process.env.NODE_ENV === 'development') {
			const token = Buffer.from(`${this.state.email}:${this.state.password}`, 'utf8').toString('base64');
			const url = 'https://covidcheck.upcebu.edu.ph/dev/api/auth';
			axios.post(url, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(response => {
					if (process.env.NODE_ENV === 'development') {
						cookies.set('user_role', response.data.role);
						cookies.set('user_city', response.data.city);
						cookies.set('user_barangay', response.data.barangay);
						cookies.set('user_email', response.data.email);
						cookies.set('user_terms_accepted', response.data.terms_accepted);
					}
					this.props.onLogin(response.data);
				})
				.catch(error => {
					this.setState({
						errors: 'Invalid username and/or password.'
					});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/auth' : 'https://covidcheck.upcebu.edu.ph/api/auth';
			axios.post(url, body, {withCredentials: true})
				.then(response => {
					if (process.env.NODE_ENV === 'development') {
						cookies.set('user_role', response.data.role);
						cookies.set('user_city', response.data.city);
						cookies.set('user_barangay', response.data.barangay);
						cookies.set('user_email', response.data.email);
						cookies.set('user_terms_accepted', response.data.terms_accepted);
					}
					this.props.onLogin(response.data);
				})
				.catch(error => {
					this.setState({
						errors: 'Invalid username and/or password.'
					});
				});
		}
	}

	onPasswordChange = event => {
		this.setState({
			password: event.target.value
		});
	}

	onEmailChange = event => {
		this.setState({
			email: event.target.value
		});
	}

	render = () => {
		return (
			<MuiThemeProvider>
				<>
				<div className="wrapper_home">
					<div className="typo-divider">
						Admin Log-in
						<br/><Typography variant="caption">For HSU log-in, to monitor employees' status</Typography>
					</div>
					<div className="wrapper_login">
						<LoginComponent
							onLogin={this.onLogin}
							onEmailChange={this.onEmailChange}
							onPasswordChange={this.onPasswordChange}
							email={this.state.email}
							password={this.state.password}
							errors={this.state.errors}
						/>
					</div>
				</div>
				</>
			</MuiThemeProvider>
		);
	}
}

export default LogIn;

