import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const TableLoader = ({loadingText}) => (
	<div style={{ padding: '24px' }}>
		<CircularProgress size={75} />
		<div>Loading {loadingText}...</div>
	</div>
);

export default TableLoader;