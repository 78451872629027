import React, { Component } from 'react';
import axios from 'axios';

import QBLogin from './QBLogin';

import Cookies from 'universal-cookie';

let cookies = null;
if (process.env.NODE_ENV === 'development') {
	cookies = new Cookies();
}

class QBLoginComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			errors: ''
		};
	}

	onLogin = () => {
		const body = {
			username: this.state.email,
			password: this.state.password
		};
		if (process.env.NODE_ENV === 'development') {
			const token = Buffer.from(`${this.state.email}:${this.state.password}`, 'utf8').toString('base64');
			const url = 'https://covidcheck.upcebu.edu.ph/dev/api/qb/auth';
			axios.post(url, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(response => {
					if (process.env.NODE_ENV === 'development') {
						cookies.set('qb_id', response.data.id);
						cookies.set('qb_role', response.data.role);
						cookies.set('qb_username', response.data.username);
						cookies.set('qb_reset_password', response.data.reset_password);
						cookies.set('qb_terms_accepted', response.data.terms_accepted);
						cookies.set('qb_assigned_facilities', response.data.assigned_facilities);
					}
					this.props.onLogin(response.data);
				})
				.catch(error => {
					this.setState({
						errors: 'Invalid username and/or password.'
					});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 'https://covidcheck.upcebu.edu.ph/dev/api/qb/auth' : 'https://covidcheck.upcebu.edu.ph/api/qb/auth';
			axios.post(url, body, {withCredentials: true})
				.then(response => {
					if (process.env.NODE_ENV === 'development') {
						cookies.set('qb_id', response.data.id);
						cookies.set('qb_role', response.data.role);
						cookies.set('qb_username', response.data.username);
					}
					this.props.onLogin(response.data);
				})
				.catch(error => {
					this.setState({
						errors: 'Invalid username and/or password.'
					});
				});
		}
	}

	onPasswordChange = event => {
		this.setState({
			password: event.target.value
		});
	}

	onEmailChange = event => {
		this.setState({
			email: event.target.value
		});
	}

	render(){
		return(
			<QBLogin 
				onLogin={this.onLogin}
				onPasswordChange={this.onPasswordChange}
				onEmailChange={this.onEmailChange}
				errors={this.state.errors}/>
		);
	}
}

export default QBLoginComponent;