import React from 'react';
import {HorizontalBar } from 'react-chartjs-2';
import styled from 'styled-components';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const AgeDistributionCard = (props) => {
	const state = {
	  labels: ['0-14', '15-29', '30-44',
			   '45-59', '60-74', '> 75'],
	  datasets: [
		{
		  label: 'Patient Count',
		  backgroundColor: '#556CB3',
		  borderColor: '#556CB3',
		  borderWidth: 1,
		  data: props.age_group
		}
	  ]
	  
	}
	
	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={10}>
			<div className="card-title" align='left' style={{paddingTop: 8, paddingBottom: 8 }}>AGE DISTRIBUTION</div>
			<Divider style={{ backgroundColor: '#f0f0f0'}}/>
				<div align='center' className="dashboard-maxWidth" style={{ padding: 10 }}>
					<HorizontalBar 
					  data={state}
					  options={{
						legend:{
						  display:false
						},
						scales: {
							xAxes: [{
								gridLines: {
									drawOnChartArea: true
								},
								scaleLabel: {
									display: true,
									labelString: 'Count',
								    fontColor: "#556CB3",
									padding: 5,
								    fontSize: 12,
								},
								ticks: {
								    fontSize: 10,
									minRotation: 0,
									maxRotation: 0,
									precision:0,
									beginAtZero: true,
									min: 0
								},
							}],
							yAxes: [{
								gridLines: {
									drawOnChartArea: false
								},
								scaleLabel: {
									display: true,
									color: 'red',
									labelString: 'Age Group',
								    fontColor: "#556CB3",
								    fontSize: 12
								},
								ticks: {
								    fontSize: 10,
								},
							}]
						}
					  }}
					/>
				</div>
				<div className="dashboard-minWidth">
					<br/>
					<div className="minWidth-left">
						<Typography variant='body2' style={{ color: '#000' }}>AGE GROUP&nbsp;:</Typography><br/>
						<Typography variant='body2' style={{ color: '#303030' }}>0 - 14&nbsp;:</Typography><br/>
						<Typography variant='body2' style={{ color: '#303030' }}>15 - 29&nbsp;:</Typography><br/>
						<Typography variant='body2' style={{ color: '#303030' }}>30 - 44&nbsp;:</Typography><br/>
						<Typography variant='body2' style={{ color: '#303030' }}>45 - 59&nbsp;:</Typography><br/>
						<Typography variant='body2' style={{ color: '#303030' }}>60 - 74&nbsp;:</Typography><br/>
						<Typography variant='body2' style={{ color: '#303030' }}>75 - 100&nbsp;:</Typography>
					</div>
					<div className="minWidth-right">
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;COUNT</Typography><br/>
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;{props.age_group[0]} </Typography><br/>
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;{props.age_group[1]} </Typography><br/>
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;{props.age_group[2]} </Typography><br/>
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;{props.age_group[3]} </Typography><br/>
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;{props.age_group[4]} </Typography><br/>
						<Typography variant='body2' style={{ color: '#556CB3' }}>&nbsp;{props.age_group[5]} </Typography>
					</div>
					<br/>
				</div>
		</Paper>
	);
}

export default AgeDistributionCard;