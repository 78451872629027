import React, { Component } from 'react';
import '../Forms.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];

const age_marks = [
    {
    value: 1,
    label: '-',
    },
    {
    value: 100,
    label: '+',
    },
];

function valuetext(value) {
    return `${value} years old`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}


export class SelfAssessment_Terms extends Component {
  state = {
      errorTextYear : ""
  }
    componentDidMount() {
        window.scrollTo(0, 0);
    };

    //Function to Move Forward
    continue = e => {
		e.preventDefault();
		if(isNaN(this.props.values.age.trim())){
			this.setState({ errorTextYear: "Input a valid age" });
		}else{
			if(this.props.values.age < 1 || this.props.values.age > 120){
				this.setState({ errorTextYear: "Input a valid age" });
			}else{
				this.setState({ errorTextYear: "" });
				this.props.nextStep();
			}
		}
	};
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, handleNumberChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress">
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
                            <div className = "progress_change">
                                <h2>Personal Information</h2>
                            </div>
							<h1>Please enter your age.</h1>
							<TextField
								type='number'
								floatingLabelText = "Age"
								pattern= "[a-z]{1,15}"
								onChange={handleNumberChange('age')}
								defaultValue = {values.age}
								style={{ width: 300, marginLeft: 10, padding: 0}}
								margin="dense"
								min={0} 
								errorText = {this.state.errorTextYear}
							/>
              
			  
                            <div class="bot_nav">
                                <button class = "button-backchoice"
                                    primary = {true}
                                    onClick = {this.back}>
                                        &#60;  Back
                                </button>
                                <button class = "button-nextchoice"
                                    primary = {true}
                                    onClick = {this.continue}>
                                        Continue
                                </button>
                            </div>

                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

const PrettoSlider = withStyles({
  root: {
    color: '#4c6fee',
    height: 8,
    width: 300,
    marginTop: 60,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default SelfAssessment_Terms;
