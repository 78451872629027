import React from 'react';
import {
  Link
} from "react-router-dom";

import './Home.css';
import home_img from './undraw_social_distancing.png';
import News from './News';
import Help from './Help';
import Report from './Report';
import Intro from './Intro';
import CoVcheckDashboard from './components/CoVcheckDashboard/CoVcheckDashboard';
import { Divider, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Home = (props) => {
	return (
		<>
			{(() => {
				if(props.loggedIn && (cookies.get('user_role') === 'Health Service' || cookies.get('user_role') === 'Admin')){
					return(
						<CoVcheckDashboard />
					);
				} else {
					return(
						<div className="wrapper_home">
						{console.log(cookies.get('user_role'))}
							<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
							<img class="home_img" src={home_img} />
							
							<div className="bottom-nav">
								<Link to = '/report' ><button class = "home-btn">Begin Self-Assessment</button></Link>
								<Link to = '/update' ><button class = "home-btn">Send Update</button></Link>
								<Link to = '/getstatus' ><button class = "home-btn">Get Clearance</button></Link>
								<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><b class="link_this" onClick = {props.handleClickOpen}>About CovidCheck</b>
							</div>
							<div className="normal-nav">
								<h2>Get CovidChecked!</h2>
								<p>CovidCheck is an application developed to collect health data of the personnel of business organizations or public institutions through self-reporting. 
								<br/><br/>The data will be used by the establishment’s health service unit to assess the health status of their workforce and to ensure that only those who are at low risk of COVID-19 are allowed to report for work. This will also provide the administrators with timely information to plan and manage task assignments and work arrangements in their respective departments. 
								</p>
								<Link to = '/report' class="button-choice">Begin Self-Assessment</Link>
								<br/>
								<br/>
								<br/>
								<br/>
								<h6 className="footnote"><i>This application is a work in progress, and will be updated to reflect additional features hereafter. Your comments and suggestions will be highly significant in contributing to upcoming features of this app. Email us at : firecheckupcebu@gmail.com</i></h6>
								<br/>
							
							</div>
							<Dialog
								open={props.d_window}
								onClose={props.handleClose}
								aria-describedby="alert-dialog-description"
								repositionOnUpdate={false}
								style={{ marginTop: '60px' }}
							>
								<div className="dialog_window_home">
									<h2>Get CovidChecked!</h2>
									<br/><p>CovidCheck is an application developed to collect health data of the personnel of business organizations or public institutions through self-reporting.</p>
									<p>The data will be used by the establishment’s health service unit to assess the health status of their workforce and to ensure that only those who are at low risk of COVID-19 are allowed to report for work. This will also provide the administrators with timely information to plan and manage task assignments and work arrangements in their respective departments. 
									</p>
									<button className = "button-nextchoice" onClick = {props.handleClose}>Back</button>
									<br/>
									<br/></div>
							</Dialog>
						</div>
						
					);
				}
			})()}
		</>
	);
}

export default Home;
