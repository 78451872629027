import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import styled, { keyframes } from 'styled-components';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MapIcon from '@material-ui/icons/Map';
import AddIcon from '@material-ui/icons/Add';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ReportInfoExpandedComponent from './ReportInfoExpandedComponent';
import TableLoader from './TableLoader';
import Select from 'react-select';

import options_barangays from '../Barangays';
import options_cities from '../Cities';
import lat_long from '../lat_long';

import Cookies from 'universal-cookie';
import { ExcelRenderer } from 'react-excel-renderer';

import {isMobile} from 'react-device-detect';

import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

/*
	REPORTS STATUS:
		0 - PENDING
		1 - RESPONDED
		2 - TESTED
		3 - TESTED POSITIVE
		4 - TESTED NEGATIVE
		5 - QUARANTINE GRADUATE
*/

const status_str = ["PENDING", "ALLOWED ENTRY", "NOT ALLOWED ENTRY"];

const cookies = new Cookies();

const cookies_key = {
	selected_source: 'selected_source',
	selected_status: 'selected_status',
	selected_classification: 'selected_classification',
	selected_city: 'selected_city',
	selected_barangay: 'selected_barangay',
	selected_company: 'selected_company',
	filter_text: 'filter_text',
	per_page: 'per_page',
	page: 'page',
	user_city: 'user_city',
	user_barangay: 'user_barangay',
	user_role: 'user_role',
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function symptoms(fever, cough, short_breath, fatigue, phlegm, muscle_joint_pain, sore_throat,
	headache, chills, vomiting, stuffed_nose, diarrhea){
	let str = "";
	if(fever){
		str = str+"Fever, ";
	}
	if(cough){
		str = str+"Cough, ";
	}
	if(short_breath){
		str = str+"Shortness of Breath, ";
	}
	if(fatigue){
		str = str+"Fatigue, ";
	}
	if(phlegm){
		str = str+"Phlegm, ";
	}
	if(muscle_joint_pain){
		str = str+"Muscle or Joint Pain, ";
	}
	if(sore_throat){
		str = str+"Sore Throat, ";
	}
	if(headache){
		str = str+"Headache, ";
	}
	if(chills){
		str = str+"Chills, ";
	}
	if(vomiting){
		str = str+"Vomiting, ";
	}
	if(stuffed_nose){
		str = str+"Stuffy Nose, ";
	}
	if(diarrhea){
		str = str+"Diarrhea";
	}
	return str;
}


function morbidity(cancer, tuberculosis, asthma, diabetes, high_blood_pressure, renal_failure, immunodeficiency_conditions, others){
	let str = "";
	if(cancer){
		str = str + "Cancer, ";
	}
	if(tuberculosis){
		str = str + "Tuberculosis, ";
	}
	if(asthma){
		str = str + "Asthma, ";
	}
	if(diabetes){
		str = str + "Diabetes, ";
	}
	if(high_blood_pressure){
		str = str + "High Blood Pressure, ";
	}
	if(renal_failure){
		str = str + "Renal Failure, ";
	}
	if(immunodeficiency_conditions){
		str = str + "Immunodeficiency Conditions, ";
	}
	if(others != ""){
		str = str + others;
	}
	return str;
}

const customStyles = {
	headCells: {
		style: {
		  color: '#202124',
		  fontSize: '12px',
		  fontWeight: 'bold',
		},
	},
};

const conditionalRowStyles = [
	{ when: row => row.status == 0,
		style: {
			backgroundColor: 'rgba(255,176,66,0.5)',
		},
	},
	{ when: row => (row.status === 2 && (row.meta.latest_update.date_created ? (checkDateIfWithin24Hrs(row.meta.latest_update.date_created) ? row.meta.latest_update.s_fever : true) : true))
		|| (row.status === 1 && (row.meta.latest_update.date_created ? row.meta.latest_update.s_fever : false)),
		style: {
			backgroundColor: 'rgba(153,0,0,0.3)',
		},
	}
];

const checkDateIfWithin24Hrs = (date) => {
	return (moment().diff(moment(date), 'hours') <= 24);
}

const columns = [
	{ name: 'Name/Age/Sex', selector: 'name', sortable: true, wrap: true,
		cell: item => <div align="left">{item.name}<br/>({item.sex} / {item.age})</div>,
	},
	{ name: 'Nationality', selector: 'nationality', sortable: true, center: true, },
	{ name: 'Address', selector: 'address', wrap: true,
		cell: item => <div align="left">{item.address_street}, {item.address_sitio}, {item.address_barangay}, {item.address_city}, {item.address_province}</div>,
	},
	{ name: 'Symptoms', selector: 'symptoms', wrap: true,
		cell: item => <div align="left"> {
				symptoms(item.s_fever, item.s_cough, item.s_short_breath, item.s_fatigue, item.s_phlegm,
				item.s_muscle_joint_pain, item.s_sore_throat, item.s_headache, item.s_chills, item.s_vomiting,
				item.s_stuffed_nose, item.s_diarrhea)
			}</div>,
	},
	{ name: 'Comorbidity', selector: 'comorbidity', wrap: true,
		cell: item => <div align="left"> {
				morbidity(item.m_cancer, item.m_tuberculosis, item.m_asthma, item.m_diabetes, item.m_high_blood_pressure, item.m_renal_failure,
				item.m_immunodeficiency_conditions, item.m_others)
			}</div>,
	},
	{ name: 'Classification', selector: 'classification', sortable: true, center:true,
	},
	{ name: 'Status', selector: 'status', sortable: true, center:true, wrap: true,
		cell: item => <div>
						<div align="center">{status_str[item.status]}</div>
					  </div>,
	},
	{ name: '# of Days Monitored', sortable: true, center:true, wrap: true,
		cell: item => <div>
						<div align="center">{
							item.date_responded &&
							(item.status === 2 ? (getQuarantineDays (item.date_responded) > 14 ? '> 14' : getQuarantineDays(item.date_responded)) : '')}</div>
					  </div>,
	},
];

const dialog_columns = [
	{ name: 'NAME', selector: 'name', sortable: true, wrap: true, width: '150px',
		cell: item => <div align="left">{item.name}</div>,
	},
	{ name: 'CLASSIFICATION', selector: 'classification', sortable: true, center:true,
	},
	{ name: '# OF DAYS QUARANTINED', sortable: true, center:true,
		cell: item => <div>
						<div align="center">{getQuarantineDays (item.date_responded)}</div>
					  </div>,
	},
];

const TextField = styled.input`
	height: 35px;
	width: 250px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
	cursor: pointer;
	}
`;

const status_options = [
	{value:-1, label:'Status (All)'},
	{value:0, label:'Pending'},
	{value:1, label:'Allowed Entry'},
	{value:2, label:'Not Allowed Entry'},
];

const classification_options = [
	{value:'', label:'Classification (All)'},
	{value:'ILI-1', label:'ILI-1'},
	{value:'ILI-2A', label:'ILI-2A'},
	{value:'ILI-2B', label:'ILI-2B'},
	{value:'SARI', label:'SARI'},
	{value:'PUM', label:'PUM'},
	{value:'SAFE-1', label:'SAFE-1'},
	{value:'SAFE-2', label:'SAFE-2'}
];

const source_options = [
	{value:'', label:'Source (All)'},
	{value:'Self-report', label:'Self-Report'},
	{value:'Encoded', label:'Encoded'},
];

const getQuarantineDays = (date_responded) => {
	const time = '08:00';
	var dateResponded = new Date(date_responded);
	dateResponded = new Date(dateResponded.getFullYear(), dateResponded.getUTCMonth(),
							dateResponded.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
	var today = new Date();
	today = new Date(today.getFullYear(), today.getUTCMonth(),
							today.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
	const diffTime = today.getTime() - dateResponded.getTime();
	const diffDays = Math.ceil(diffTime / (1000*3600*24));
	return diffDays;
}

class ViewReports extends React.PureComponent {
	_exporter;
	export = () => {
		this._exporter.save();
	}

	state = {
		reports: [],
		excelReports: [],
		loading: true,
		totalRows: 0,
		perPage: 10,
		page: 1,
		addPatientDialog: false,
		filterText: "",
		selectedSourceValue: '',
		selectedSourceOption: {value: '', label:'Source (All)'},
		selectedStatusValue: -1,
		selectedStatusOption: {value:-1, label:'Status (All)'},
		selectedClassificationValue: '',
		selectedClassificationOption: {value:'', label:'Classification (All)'},
		userRole: '',
		selectedCityValue: '',
		selectedCityOption: {value:'', label: 'City (All)'},
		selectedBarangayValue: '',
		selectedBarangayOption: {value:'', label: 'Barangay (All)'},
		filtersPanel: false,
		_barangay_options: [],
		quarantineGraduateDialog: false,
		graduateNominees: [],
		selectedGraduates: [],
		selectedRowsText: '',

		setOpen: cookies.get('user_terms_accepted') === 'true' ? false : true,

		companyOptions: [],
		selectedCompany: '',
		selectedCompanyOption: {value:'', label:'Company (All)'},
	}

	loadData = (perPage, page, name, status, classification) => {

		this.setState({ loading: true });
		const offset = (perPage * page) - perPage;

		let url = `/api/reports?limit=${perPage}&offset=${offset}`;
		if(name){
			url = `${url}&name=%${name}%`;
		}
		if(this.state.selectedSourceValue){
			url = `${url}&submitted_by=${this.state.selectedSourceValue}`;
		}
		if(status >= 0){
			url = `${url}&status=${status}`;
		}
		if(classification){
			url = `${url}&classification=${classification}`;
		}
		if(this.state.selectedCityOption.value){
			url = `${url}&address_city=${this.state.selectedCityOption.value}`;
		}
		if(this.state.selectedCityOption.value && this.state.selectedBarangayOption.value){
			url = `${url}&address_barangay=${this.state.selectedBarangayOption.value}`;
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}&order_by=classification&order_direction=ASC`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						reports: result.data.reports,
						totalRows: result.data.total_rows,
						loading: false
					}, () => {
						if(this.state.reports.length){
							this.setState({ filtersPanel: false });
						}
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev${url}&order_by=classification&order_direction=ASC` : `https://covidcheck.upcebu.edu.ph${url}&order_by=classification&order_direction=ASC`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						reports: result.data.reports,
						totalRows: result.data.total_rows,
						loading: false
					}, () => {
						if(this.state.reports.length){
							this.setState({ filtersPanel: false });
						}
					});
				});
		}
	}

	loadCompanies = () => {
		this.setState({ loading: true });
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			var val = '';
			var url = `/api/reports/companies?company=${val}`;
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					var options = [{value:'', label:'Company (All)'}];
					result.data.companies.map(element => {
						if(element){
							options.push({label: element, value: element});
						}
					});
					this.setState({
						companyOptions: options,
						loading: false
					});
				});
		}else{
			var val = '';
			var url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			`https://covidcheck.upcebu.edu.ph/dev/api/reports/companies?company=${val}` :
			`https://covidcheck.upcebu.edu.ph/api/reports/companies?company=${val}`;
			axios(url, {withCredentials: true})
				.then(result => {
					var options = [{value:'', label:'Company (All)'}];
					result.data.companies.map(element => {
						if(element){
							options.push({label: element, value: element});
						}
					});
					this.setState({
						companyOptions: options,
						loading: false
					});
				});
		}
	}

	componentDidMount(){
		window.scrollTo(0,0);

		//set this from cookies
		const userRole = cookies.get(cookies_key.user_role);

		let selectedSourceOption = cookies.get(cookies_key.selected_source);
		if(selectedSourceOption == null){
			cookies.set(cookies_key.selected_source, this.state.selectedSourceOption);
			selectedSourceOption = this.state.selectedSourceOption;
		}

		let selectedStatusOption = cookies.get(cookies_key.selected_status);
		if(selectedStatusOption == null){
			cookies.set(cookies_key.selected_status, this.state.selectedStatusOption);
			selectedStatusOption = this.state.selectedStatusOption;
		}

		let selectedClassificationOption = cookies.get(cookies_key.selected_classification);
		if(selectedClassificationOption == null){
			cookies.set(cookies_key.selected_classification, this.state.selectedClassificationOption);
			selectedClassificationOption = this.state.selectedClassificationOption;
		}

		let selectedCityOption = cookies.get(cookies_key.selected_city);
		if(selectedCityOption == null){
			cookies.set(cookies_key.selected_city, this.state.selectedCityOption);
			selectedCityOption = this.state.selectedCityOption;
		}

		let selectedBarangayOption = cookies.get(cookies_key.selected_barangay);
		if(selectedBarangayOption == null){
			cookies.set(cookies_key.selected_barangay, this.state.selectedBarangayOption);
			selectedBarangayOption = this.state.selectedBarangayOption;
		}

		let selectedCompanyOption = cookies.get(cookies_key.selected_company);
		if(selectedCompanyOption == null){
			cookies.set(cookies_key.selected_company, this.state.selectedCompanyOption);
			selectedCompanyOption = this.state.selectedCompanyOption;
		}

		let filterText = cookies.get(cookies_key.filter_text);
		if(filterText == null){
			cookies.set(cookies_key.filter_text, this.state.filterText);
			filterText = this.state.filterText;
		}

		let perPage = cookies.get(cookies_key.per_page);
		if(perPage == null){
			cookies.set(cookies_key.per_page, this.state.perPage);
			perPage = this.state.perPage;
		}

		let page = cookies.get(cookies_key.page);
		if(page == null){
			cookies.set(cookies_key.page, this.state.page);
			page = this.state.page;
		}

		this.setState({
			selectedSourceValue: selectedSourceOption.value,
			selectedSourceOption: selectedSourceOption,
			selectedStatusValue: selectedStatusOption.value,
			selectedStatusOption: selectedStatusOption,
			selectedClassificationValue: selectedClassificationOption.value,
			selectedClassificationOption: selectedClassificationOption,
			selectedCityValue: selectedCityOption.value,
			selectedCityOption: selectedCityOption,
			selectedBarangayValue: selectedBarangayOption.value,
			selectedBarangayOption: selectedBarangayOption,
			selectedCompany: selectedCompanyOption.value,
			selectedCompanyOption: selectedCompanyOption,
			filterText: filterText,
			perPage: perPage,
			page: page,
			userRole: userRole
		}, () => {
			this.loadData(perPage, page, filterText,
			selectedStatusOption.value, selectedClassificationOption.value);
			this.loadReportsForExcel();
			this.loadCompanies();
		});
	}

	handleAcceptTerms = () => {
        this.setState({
            setOpen: false
		});
		const body = {
			terms_accepted: true
		};
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/auth/prefs`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
				});
		}else{
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/auth/prefs` : `https://covidcheck.upcebu.edu.ph/api/auth/prefs`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
				});
		}
		cookies.set('user_terms_accepted', true);
	};

	handleOpenGraduate = event => {
		let url = `/api/reports?limit=999999999&offset=0`;

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.checkQuarantineGraduateNominees(result.data.reports);
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.checkQuarantineGraduateNominees(result.data.reports);
				});
		}
	}

	checkQuarantineGraduateNominees = (nominees) => {
		let graduateNominees = [];
		nominees.map(n => {
			if(getQuarantineDays(n.date_responded) > 14 && n.status === 2){
				graduateNominees.push(n);
			}
		});
		this.setState({
			graduateNominees: graduateNominees,
			quarantineGraduateDialog: true });
	}

	handleCloseGraduate = event => {
		this.setState({ quarantineGraduateDialog: false });
	}

	handleConfirmGraduate = event => {
		if(this.state.selectedGraduates.length){
			const length = this.state.selectedGraduates.length;
			let index = 0;
			this.state.selectedGraduates.map((g) => {
				const body = { "status" : 1, "classification": 'SAFE-2' }
				if (process.env.NODE_ENV === 'development') {
					const username = 'testing@example.com';
					const password = 'p@ssw0rd';
					const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
					axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${g.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							index = index + 1;
							if(index === length){
								this.filterReports();
								this.loadReportsForExcel();
								this.setState({
									selectedGraduates: [],
									quarantineGraduateDialog: false
								});
							}
						});
				} else {
					const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 `https://covidcheck.upcebu.edu.ph/dev/api/reports/${g.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${g.id}`;
					axios.put(url, body, {withCredentials: true})
						.then(result => {
							index = index + 1;
							if(index === length){
								this.filterReports();
								this.loadReportsForExcel();
								this.setState({
									selectedGraduates: [],
									quarantineGraduateDialog: false
								});
							}
						});
				}
			});
		}
	}

	handleAddPatient = event => {
		this.setState({ addPatientDialog: true });
	};

	handleCloseAddPatient = () => {
		this.setState({ addPatientDialog: false });
	};

	handlePageChange = page => {
		cookies.set(cookies_key.page, page);
		this.setState({ page });
		this.loadData(this.state.perPage, page, this.state.filterText,
		this.state.selectedStatusValue, this.state.selectedClassificationValue);
	}

	handlePerRowsChange = (perPage, page) => {
		cookies.set(cookies_key.per_page, perPage);
		this.setState({ perPage });
		this.loadData(perPage, page, this.state.filterText,
		this.state.selectedStatusValue, this.state.selectedClassificationValue);
	}

	handleChangeFilterText = e => {
		this.setState({ filterText: e.target.value});
	}

	handleChangeSource = selectedOption => {
		this.setState({
			selectedSourceValue: selectedOption.value,
			selectedSourceOption: selectedOption
		});
	}

	handleChangeStatus = selectedOption => {
		this.setState({
			selectedStatusValue: selectedOption.value,
			selectedStatusOption: selectedOption
		});
	}

	handleChangeClassification = selectedOption => {
		this.setState({
			selectedClassificationValue: selectedOption.value,
			selectedClassificationOption: selectedOption
		});
	}

	handleChangeBarangay = selectedOption => {
		this.setState({
			selectedBarangayValue: selectedOption.value,
			selectedBarangayOption: selectedOption
		});
	}

	handleChangeCompany = selectedOption => {
		this.setState({
			selectedCompany: selectedOption.value,
			selectedCompanyOption: selectedOption
		});
	}

	handleChangeCity = selectedOption => {
		this.setState({
			selectedCityValue: selectedOption.value,
			selectedCityOption: selectedOption,
			selectedBarangayValue: '',
			selectedBarangayOption: {value:'', label: 'Barangay (All)'}
		});
	}

	filtersStr = () => {
		const str = 'Filters: ';
		const src_str = (this.state.selectedSourceValue ? `${this.state.selectedSourceValue} ` : 'Source (All) ');
		const stat_str = (this.state.selectedStatusValue >= 0 ? `/ ${status_str[this.state.selectedStatusValue]} ` : '/ Status (All) ');
		const class_str = (this.state.selectedClassificationValue ? `/ ${this.state.selectedClassificationValue} ` : '/ Classification (All) ');
		const city_str = ('/ '+(this.state.selectedCityValue ? `${this.state.selectedCityValue} ` : 'City (All) '));
		const barangay_str = this.state.selectedCityValue ? ('/ '+(this.state.selectedBarangayValue ? `${this.state.selectedBarangayValue} ` : 'Barangay (All) ')) : '';
		//const company_str = (this.state.userRole === 'Admin' || this.state.cityLevelUser || this.state.barangayLevelUser) ? ('/ ' + (this.state.selectedCompany ? `${this.state.selectedCompany} ` : 'Company (All) ')) : '';
		const name_str = (this.state.filterText ? ('/ '+`Name (${this.state.filterText})`) : '');
		return str+src_str+stat_str+class_str+city_str+barangay_str+name_str;
 	}

	filterReports = () => {
		cookies.set(cookies_key.selected_source, this.state.selectedSourceOption);
		cookies.set(cookies_key.selected_status, this.state.selectedStatusOption);
		cookies.set(cookies_key.selected_classification, this.state.selectedClassificationOption);
		cookies.set(cookies_key.selected_city, this.state.selectedCityOption);
		cookies.set(cookies_key.selected_barangay, this.state.selectedBarangayOption);
		cookies.set(cookies_key.selected_company, this.state.selectedCompanyOption);
		cookies.set(cookies_key.filter_text, this.state.filterText);
		cookies.set(cookies_key.page, 1);
		this.setState({ page: 1 }, () => {
			this.loadData(this.state.perPage, 1,
						 this.state.filterText, this.state.selectedStatusValue,
						 this.state.selectedClassificationValue);
			this.loadReportsForExcel();
		});
	}

	loadReportsForExcel = () => {
		let url = `/api/reports?limit=999999999&offset=0`;

		if(this.state.selectedSourceValue){
			url = `${url}&submitted_by=${this.state.selectedSourceValue}`;
		}
		if(this.state.filterText){
			url = `${url}&name=%${this.state.filterText.toLowerCase()}%`;
		}
		if(this.state.selectedStatusValue >= 0){
			url = `${url}&status=${this.state.selectedStatusValue}`;
		}
		if(this.state.selectedClassificationValue){
			url = `${url}&classification=${this.state.selectedClassificationValue}`;
		}
		if(this.state.selectedCityOption.value){
			url = `${url}&address_city=${this.state.selectedCityOption.value}`;
		}
		if(this.state.selectedCityOption.value && this.state.selectedBarangayOption.value){
			url = `${url}&address_barangay=${this.state.selectedBarangayOption.value}`;
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}&order_by=classification&order_direction=ASC`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						excelReports: result.data.reports
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev${url}&order_by=classification&order_direction=ASC` : `https://covidcheck.upcebu.edu.ph${url}&order_by=classification&order_direction=ASC`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						excelReports: result.data.reports
					});
				});
		}
	}

	reportToExcel = (reports) => {
		let data = [];
		reports.map((r) => {
			const xl = {};
			Object.assign(xl, r);
			const address = {
				"address": (r.address_street ? r.address_street+", " : '')+(r.address_sitio ? r.address_sitio+", " : '')+
				r.address_barangay+", "+r.address_city+", "+r.address_province
			};
			const symps = {"symptoms": symptoms(r.s_fever, r.s_cough, r.s_short_breath, r.s_fatigue, r.s_phlegm,
				r.s_muscle_joint_pain, r.s_sore_throat, r.s_headache, r.s_chills, r.s_vomiting,
				r.s_stuffed_nose, r.s_diarrhea)}
			const comorbs = {"comorbidity": morbidity(r.m_cancer, r.m_tuberculosis, r.m_asthma, r.m_diabetes, r.m_high_blood_pressure, r.m_renal_failure,
				r.m_immunodeficiency_conditions, r.m_others)}
			const status = {"status": status_str[r.status]}
			const responded_date = {"responded_date": r.date_responded && moment(r.date_responded).format('L')}
			const quarantine_days = {"quarantine_days": r.date_responded && getQuarantineDays(r.date_responded)}
			const exposure_travel = {"exposure_travel": r.has_visited_infected_areas ? 'YES' : 'NO'}
			const exposure_person = {"exposure_person": r.has_met_infected ? 'YES' : 'NO'}
			const testing_date = {"testing_date": r.date_testing && moment(r.date_testing).format('L')}
			data.push(Object.assign(xl, address, symps, comorbs, status,
				responded_date, quarantine_days, exposure_travel, exposure_person, testing_date));
		});
		return data;
	}

	handlePanelChange = (panel) => (event, isExpanded) => {
		this.setState({ filtersPanel: isExpanded ? panel : false });
	}

	handleDialogSelectedRows = table => {
		let selectedRowsText = '';
		if(table.selectedRows.length){
			selectedRowsText = 'No. of selected patient(s): '+table.selectedRows.length;
		}
		this.setState({
			selectedRowsText: selectedRowsText,
			selectedGraduates: table.selectedRows });
	}

	toProperCase = (str) => {
		str = str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
		if(str.includes('-')){
			str = str.split('-').map(w => w[0].toUpperCase() + w.substr(1)).join('-');
		}
		return str;
	}

	handleFileUpload = event => {
		const xlFile = event.target.files[0];
		if(xlFile){
			ExcelRenderer(xlFile, (err, resp) => {
				if(err){
					// console.log(err);
				}
				else{
					const rows = resp.rows;
					const total_rows = rows.length-1;
					let index = 0;
					const expected_rows = [
						"Name",
						"Age",
						"Sex",
						"Nationality",
						"Contact Number",
						"Street",
						"Sitio",
						"Barangay",
						"City",
						"Province",
						"Classification",
						"Date Responded or Begin Monitoring\r\nmm/dd/yyyy",
						"Admission/Quarantine Facility",
						"Visited Infected Area(s)",
						"Met Infected Person(s)"
					]
					if(rows[index].length === 15){
						rows.map(row => {
							if(index > 0){
								const report = {
									deleted: false,
									name: row[0],
									age: row[1],
									sex: row[2] ? (row[2].toLowerCase() === 'male' ? 'Male' : (row[2].toLowerCase() === 'female' ? 'Female' : '')) : '',
									nationality: row[3] ? row[3] : '',
									contact_number: row[4] ? row[4] : '',
									address_street: row[5] ? row[5] : '',
									address_sitio: row[6] ? row[6] : '',
									address_barangay: row[7] ? this.toProperCase(row[7]) : '',
									address_city: row[8] ? this.toProperCase(row[8]) : '',
									address_province: row[9] ? this.toProperCase(row[9]) : '',
									classification: row[10],
									status: 1,
									date_responded: row[11],
									admit_quarantine_facility: row[12] ? row[12] : '',
									has_visited_infected_areas: row[13] === 'YES' ? true : false,
									has_met_infected: row[14] === 'YES' ? true : false
								};

								report.s_fever = false;
								report.s_cough = false;
								report.s_short_breath = false;
								report.s_fatigue = false;
								report.s_phlegm = false;
								report.s_muscle_joint_pain = false;
								report.s_sore_throat = false;
								report.s_headache = false;
								report.s_chills = false;
								report.s_vomiting = false;
								report.s_stuffed_nose = false;
								report.s_diarrhea = false;

								const latlng = lat_long.filter((item) => (item.link_barangay === report.address_barangay && item.link_city === report.address_city));
								if(latlng.length){
									report.address_latitude = latlng[0].lat;
									report.address_longitude = latlng[0].long;
								}else{
									report.address_latitude = null;
									report.address_longitude = null;
								}

								report.date_testing = null;
								report.testing_center = '';

								report.m_cancer = false;
								report.m_tuberculosis = false;
								report.m_asthma = false;
								report.m_diabetes = false;
								report.m_high_blood_pressure = false;
								report.m_renal_failure = false;
								report.m_immunodeficiency_conditions = false;
								report.m_others = '';

								report.has_symptoms = false;
								report.submitted_by = 'Encoded';

								if (process.env.NODE_ENV === 'development') {
									axios.post('https://covidcheck.upcebu.edu.ph/dev/api/reports', report)
										.then(result => {
										}).catch(err => {
											// console.log(report.name+' - '+err.message);
										})
								}else{
									const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/reports' : 'https://covidcheck.upcebu.edu.ph/api/reports';
									axios.post(url, report)
										.then(result => {
										}).catch(err => {
											// console.log(report.name+' - '+err.message);
										})
								}
							}
							if(index === total_rows){
								this.filterReports();
								this.loadReportsForExcel();
							}
							index = index + 1;
						});
					}else{
						window.alert('Invalid excel format');
					}
				}
			});
		}
	}

	render(){
		let filtered_city_options = [];
		let filtered_barangay_options = [];
		filtered_city_options = [{value:'', label: 'City (All)'}].concat(options_cities.filter((o) => o.link === 'Cebu'));
		if(this.state.selectedCityValue){
			filtered_barangay_options = [{value:'', label: 'Barangay (All)'}].concat(options_barangays.filter((o) => o.link === this.state.selectedCityValue));
		}
		return(
			<MuiThemeProvider>
            	<React.Fragment>
            			<div>
						<Dialog
                            open={this.state.setOpen}
                            onClose={this.handleAcceptTerms}
                            aria-describedby="alert-dialog-description"
							repositionOnUpdate={false}
							style={{ marginTop: '60px' }}
                        >
							<div className="dialog_window">
							<h6>Terms of Use and Privacy Policy</h6>
							<p>
								The University of the Philippines Cebu (UP Cebu) Firecheck Team developed the CoVcheck application in light of the rising cases of the CoronaVirus Disease 2019 (COVID-19) in the Philippines. This application is developed by Firecheck for the local government units of Cebu province and is intended for the use of the province's constituents.
							</p><p>
								CoVcheck is an application developed to collect data about potential COVID19 transmissions through self-reporting. The data will be used by the local government and concerned agencies to identify targeted actions to help many of its constituents especially those who will need special care.
							</p><p>
								This document is used to inform users regarding the policies with the collection, use, and disclosure of Personal Information shared through the CoVcheck application.
							</p><p>
								By accessing or using CoVcheck you agree to be bound by these Terms. If you disagree with any part of  the terms then you may not access the Service.
							</p>
							<h7>TERMS OF USE</h7>
							<p>The use of the CoVcheck, especially the Self-Assessment application, is for the public and is free of use.
							</p><p>Your access to and use of CoVcheck is conditioned on your acceptance of and compliance with the Terms set here. Specifically, you agree to the collection and use of information in relation to the application’s purpose. The Personal Information that we collect is used for expediting the monitoring of the spread of the CoronaVirus Disease 2019 (COVID-19) through reporting of potential infection and self-assessment.
							</p><p>We will not use or share your information with anyone except as described in the Privacy Policy section of this document.
							</p><p>This application relies upon publicly available data from multiple sources that may not always agree and are constantly subject to changes pending further scientific developments. Reliance on the application for medical guidance may not be enough for some special cases. Any and all unusual medical concerns must still be directed towards and addressed by qualified health professionals and hospital clinics near you. The use of this application in commerce by other parties is strictly prohibited.
							</p><p>The University of the Philippines Cebu hereby disclaims any and all representations and warranties with respect to the use of this application, including accuracy, fitness for use, and merchantability.
							</p>
							<h7>Information Collection and Use</h7>
							<p>For better assessment and tracking purposes, we will require you to provide us truthfully with certain personally identifiable information, including but not limited to name, age, sex, nationality, home address, and contact number. Some personal health information will also be collected. The information that we request will be retained by us and used as described in this privacy policy.
							</p><p>The application does use third party services that may validate information to identify you and point your exact location.
							</p>
							<h7>Cookies</h7>
							<p>Cookies are small pieces of text used to store information on web browsers. User data such as User ID, Name, Role, City or Barangay affiliation are stored in the cookies. These data are eventually deleted from the cookie upon log-out. Cookies enable CoVcheck to enforce security measures and improve the navigability of the application for a better user experience.
							</p>
							<h7>Security</h7>
							<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
							</p>
							<h7>Links to Other Sites</h7>
							<p>The website may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these applications. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
							</p>
							<h7>PRIVACY POLICY</h7><br/>
							<h7>Confidentiality under the Philippine Law</h7>
							<p>The information that we collect from you are generally protected as privileged communications and it is our responsibility to keep that information confidential.
							</p>
							<h7>Collection and Processing of Personal Data</h7>
							<p>The data that you provide through the CoVcheck Self-Assessment application will be stored and used by the local government to identify measures to contain local transmissions and plan for rehabilitation and support activities.
							</p><p>All the user inputs will be summarized to provide the local government with an overview of potential COVID cases in the locality. This will prompt the local government to identify strategic measures for containment and public support. Individual records will also be used to help the local government monitor persons of interest for possible infection.
							</p><p>We urge the public to report truthfully.
							</p>
							<h7>Technical and Health Service Providers</h7>
							<p>The data that we will collect will be shared with the following agencies:
							</p><p>1.	LGU Mayor’s Office
							</p><p>2.	City Disaster Risk Reduction and Management Office
							</p><p>3.	City Health Office
							</p><p>4.	Department of Health
							</p><p>Each of the above stated agencies shall execute their own Non-Disclosure Agreements per employee to further ensure confidentiality of the data that will be collected.
							</p><p>
							</p><p>We may also employ other party’s units and companies and individuals due to the following reasons:
							</p><p>●	To facilitate the functionality of the CoVcheck application; (internet service provider, etc.)
							</p><p>●	To provide the necessary medical emergency service on our behalf; (Health Services Units, Disaster Response Units)
							</p><p>●	To deliver information dissemination for public awareness  (print and non-print media)
							</p><p>●	To assist in the monitoring and assessment of the submitted/reported cases (doctor/health experts)
							</p><p>We want to inform users that other parties may have access to your Personal Information. The reason is to perform the tasks assigned to them in the management of the health crisis. However, they are obligated not to disclose or use the information for any other purpose.
							Children’s Privacy
							</p><p>The CoVcheck application does not exempt anyone. Thus, a minor’s parent or guardian should be the one to provide us with personal information or at least is aware that your child has provided us with personal information so that we will be able to do necessary actions.
							</p>
							Changes to This Privacy Policy
							<p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
							</p>
							<h7>CONTACT US</h7>
							<p>If you have any questions or suggestions about our Terms of Use and Privacy Policy, do not hesitate to contact us at firecheckupcebu@gmail.com.
							</p>
							<button className = "button-nextchoice" onClick = {this.handleAcceptTerms}>Back</button>
							<br/>
						</div>
						</Dialog>
            				<Paper style={{ padding: 10 }}>
            					<div align='center'>
            					<ExpansionPanel
            						expanded={this.state.filtersPanel === 'panel'}
            						onChange={this.handlePanelChange('panel')}
            						style={{ width: 350 }}>
            						<ExpansionPanelSummary
							          expandIcon={<ExpandMoreIcon />}
							          aria-controls="panel1a-content"
							          id="panel-header"
							        >
							        	<Typography color='primary'>Filter Reports</Typography>
            						</ExpansionPanelSummary>
            						<ExpansionPanelDetails>
            							<div className="for_select">
            								<div className="for_select">
												<Select
													options={source_options}
													value={this.state.selectedSourceOption}
													placeholder="Source"
													onChange={this.handleChangeSource}
													style={{ width: 100}}
												/>
											</div>
											<br/>
											<div className="for_select">
												<Select
													options={status_options}
													value={this.state.selectedStatusOption}
													placeholder="Status"
													onChange={this.handleChangeStatus}
													style={{ width: 100}}
												/>
											</div>
											<br/>
											<div className="for_select">
												<Select
													options={classification_options}
													value={this.state.selectedClassificationOption}
													placeholder="Classification"
													onChange={this.handleChangeClassification}
													style={{ width: 100}}
												/>
											</div>
											<br/>
											<div className="for_select">
												<Select
													options={filtered_city_options}
													value={this.state.selectedCityOption}
													placeholder="City"
													onChange={this.handleChangeCity}
													style={{ width: 100}}
												/>
											</div>
											<br/>
											{(() => {
												if(this.state.selectedCityValue){
													return(
														<React.Fragment>
															<div className="for_select">
																<Select
																	options={filtered_barangay_options}
																	value={this.state.selectedBarangayOption}
																	placeholder="Barangay"
																	onChange={this.handleChangeBarangay}
																	style={{ width: 100}}
																/>
															</div>
															<br/>
														</React.Fragment>
													);
												}
											})()}
											{(() => {
												if(false){
													return(
														<React.Fragment>
															<div className="for_select">
																<Select
																	options={this.state.companyOptions}
																	value={this.state.selectedCompanyOption}
																	placeholder="Company"
																	onChange={this.handleChangeCompany}
																	style={{ width: 100}}
																/>
															</div>
															<br/>
														</React.Fragment>
													);
												}
											})()}
											<TextField id="search" type="text"
												placeholder="Filter By Name"
												value={this.state.filterText}
												onChange={this.handleChangeFilterText} />
											<br/>
											<br/>
											<Button size="small"
													color="primary"
													variant="contained"
													startIcon={<FindReplaceIcon />}
													onClick={this.filterReports}>
												Filter
											</Button>
											<br/>
										</div>
									</ExpansionPanelDetails>
								</ExpansionPanel>
								</div>
								{(() => {
									if(!this.state.filtersPanel){
										return(
											<>
												<br/>
												<Typography variant='overline'>
													{this.filtersStr()}
												</Typography>
											</>
										);
									}
								})()}
							</Paper>
						</div>
						<Divider />
            			<DataTable
            				noHeader={true}
							columns={columns}
							data={this.state.reports}
							expandableRows
							expandableRowsComponent={<ReportInfoExpandedComponent
																history={this.props.history}
																filterReports={this.filterReports}
																loadReportsForExcel={this.loadReportsForExcel}
																userRole={this.state.userRole}/>}
							progressPending={this.state.loading}
      						progressComponent={<TableLoader loadingText="Reports"/>}
      						pagination
      						paginationServer
      						paginationTotalRows={this.state.totalRows}
      						paginationPage={this.state.page}
      						paginationPerPage={this.state.perPage}
      						onChangeRowsPerPage={this.handlePerRowsChange}
      						onChangePage={this.handlePageChange}
      						conditionalRowStyles={conditionalRowStyles}
      						customStyles={customStyles}
      						subHeader
      						subHeaderAlign={'right'}
      						subHeaderWrap={false}
      						subHeaderComponent={
      							<>
								
      								<Button size="small"
									variant="contained"
									color="primary"
				      				onClick={this.handleAddPatient}
									startIcon={!isMobile && <AddIcon />}>
										{isMobile ? 'Add' : 'Add Employee'}
									</Button>
									
									<Dialog
										fullWidth={true}
						        		maxWidth={'xs'}
										keepMounted
										TransitionComponent={Transition}
										onClose={this.handleCloseAddPatient}
										aria-labelledby="add-patient-dialog-title"
										open={this.state.addPatientDialog}>
										<DialogTitle id="add-patient-dialog-title">Add Employee</DialogTitle>
										<List>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/ILI-1")}>
												<ListItemText primary="ILI-1 (Influenza-Like Illness - 1)" />
											</ListItem>
											<Divider/>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/ILI-2A")}>
												<ListItemText primary="ILI-2A (Influenza-Like Illness - 2A)" />
											</ListItem>
											<Divider/>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/ILI-2B")}>
												<ListItemText primary="ILI-2B (Influenza-Like Illness - 2B)" />
											</ListItem>
											<Divider/>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/SARI")}>
												<ListItemText primary="SARI (Severe Acute Respiratory Infection)" />
											</ListItem>
											<Divider/>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/PUM")}>
												<ListItemText primary="PUM (Person Under Monitoring)" />
											</ListItem>
											<Divider/>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/SAFE-1")}>
												<ListItemText primary="SAFE-1" />
											</ListItem>
											<Divider/>
											<ListItem autoFocus button onClick={() => this.props.history.push("/add-patient/SAFE-2")}>
												<ListItemText primary="SAFE-2" />
											</ListItem>
										</List>
										<DialogActions>
											<Button onClick={this.handleCloseAddPatient} color="primary">
												Cancel
											</Button>
										</DialogActions>
									</Dialog>
									&nbsp;&nbsp;&nbsp;
									<Button size="small"
									variant="contained"
									color="primary"
				      				onClick={this.handleOpenGraduate}
									startIcon={!isMobile && <CastForEducationIcon />}>
										{isMobile ? 'Graduates' : 'Monitoring Graduates' }
									</Button>
									<Dialog
										fullWidth={true}
						        		maxWidth={'sm'}
										keepMounted
										TransitionComponent={Transition}
										onClose={this.handleCloseGraduate}
										aria-labelledby="graduate-dialog-title"
										open={this.state.quarantineGraduateDialog}>
									<DialogTitle id="graduate-dialog-title">
										Monitoring Graduate(s)
									</DialogTitle>
									<DialogContent>
										{this.state.selectedRowsText ?
										<div align='center' style={{ paddingBottom: 10 }}>
											<Typography color='primary'>{this.state.selectedRowsText}</Typography>
										</div> : null}
										<Divider/>
										<DataTable
											noHeader
											columns={dialog_columns}
											data={this.state.graduateNominees}
											customStyles={customStyles}
											selectableRows
											selectableRowSelected={row => row.name}
											onSelectedRowsChange={this.handleDialogSelectedRows}
										/>
									</DialogContent>
									<DialogActions>
										<Button onClick={this.handleCloseGraduate} color="secondary">
											Cancel
										</Button>
										{(() => {
											if(this.state.graduateNominees.length){
												return(
													<Button onClick={this.handleConfirmGraduate} color="primary">
														Confirm
													</Button>
												);
											}
										})()}
									</DialogActions>
									</Dialog>
									{(() => {
										if(this.state.reports.length){
											return(
												<>
													&nbsp;&nbsp;&nbsp;
													<Button size="small"
														color="primary"
														variant="contained"
														startIcon={!isMobile && <MapIcon/>}
														component={Link}
														to="/view-reports/heatmap">
														Map
													</Button>
													&nbsp;&nbsp;&nbsp;
													<Button size="small"
														color="primary"
														variant="contained"
														onClick={this.export}
														startIcon={!isMobile && <GetAppIcon/>}
														>
														Export
													</Button>
													<ExcelExport
									                    data={this.reportToExcel(this.state.excelReports)}
									                    fileName={"Reports - "+moment(new Date()).format('L')+".xlsx"}
									                    ref={(exporter) => { this._exporter = exporter; }}
									                >
									                	<ExcelExportColumn field="name" title="Name" width={200} />
												        <ExcelExportColumn field="age" title="Age" width={75} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="sex" title="Sex" width={75} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="nationality" title="Nationality" width={100} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="contact_number" title="Contact Number" width={150} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="address" title="Address" width={400} />
												        <ExcelExportColumn field="designation" title="Designation" />
												        <ExcelExportColumn field="department" title="Department" />
												        <ExcelExportColumn field="classification" title="Classification" width={100} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="symptoms" title="Symptoms" />
												        <ExcelExportColumn field="comorbidity" title="Comorbidities" />
												        <ExcelExportColumn field="status" title="Status" cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="responded_date" title="Date Started Quarantine" cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="admit_quarantine_facility" title="Admission/Quarantine Facility" />
												        <ExcelExportColumn field="quarantine_days" title="# of Days Quarantined" cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="exposure_travel" title="Visited Infected Area(s)" cellOptions={{ textAlign: 'center' }} />
									                </ExcelExport>
						                		</>
						                	);
						            	}
					                })()}
      							</>
      						}
						/>
            	</React.Fragment>
            </MuiThemeProvider>
		);
	}
}

export default ViewReports;
