import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
    Divider, Paper, Typography, TextField, Grid
} from '@material-ui/core';

const mobilePhonesBreakPoint = 400;

class UpdateClassification extends Component {
	state = {
		report: {},
		classification: this.props.match.params.classification,
		status: '1',
		loading: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
					this.setState({ classification: this.state.report.classification }, () => {
						if(this.state.classification === 'SAFE-1' || this.state.classification === 'SAFE-2'){
							this.setState({ status: '1' });
						}else{
							this.setState({ status: '2' });
						}
					});
				})
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
					this.setState({ classification: this.state.report.classification }, () => {
						if(this.state.classification === 'SAFE-1' || this.state.classification === 'SAFE-2'){
							this.setState({ status: '1' });
						}else{
							this.setState({ status: '2' });
						}
					});
				})
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		event.preventDefault();
		let valid = true;

		if(this.state.report.date_responded == null || this.state.report.date_responded == ""){
			valid = false;
			this.setState({ date_responded_error: true, date_responded_error_text: "Input is required!"});
		}

		if(valid){
			this.setState({ loading: true, });

			const body = { "status" : parseInt(this.state.status),
					"classification": this.state.classification};

			if(body.status === 2){
				body.admit_quarantine_facility = this.state.report.admit_quarantine_facility;
				body.date_responded = this.state.report.date_responded;
			}

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						// console.log(result);
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}else{
							this.setState({ loading: false, });
						}
					})
			} else {
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						// console.log(result);
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}else{
							this.setState({ loading: false, });
						}
					});
			}
		}
	}

	handleChange = field => event => {
		const value = event.target.value;
		this.setState({ [field]: value });
		if(field === 'classification'){
			if(value === 'SAFE-1' || value === 'SAFE-2'){
				this.setState({ status: '1' });
			}else{
				this.setState({ status: '2' });
			}
		}else if(field === 'date_responded' || field === 'admit_quarantine_facility'){
			const report = this.state.report;
			report[field] = value;
			this.setState({ report });
		}
	}

	dateStr = d => {
		if(d == null){
			d = new Date();
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		const classes = {margin:1};
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div className="for_select" align="left" style={{ width: 350}}>
                        <Paper style={{padding: 10}}>
                            <Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
                                Update Classification
                            </Typography>
                            <Divider/>
		                    <Typography component="h1" variant="subtitle1" color="textSecondary" style={{ padding: 10 }} align='left' gutterBottom>
                                Patient: {this.state.report.name}
                            </Typography>
                            <div className="for_select" style={{paddingLeft: 10}}>
								<FormControl component="fieldset">
									<RadioGroup
										aria-label="classification"
										name="classification1"
										value={this.state.classification}
										onChange={this.handleChange('classification')}
									>
										<FormControlLabel value="ILI-1" control={<Radio color="primary" />} label="ILI-1 (Influenza-Like Illness - 1)" />
										<FormControlLabel value="ILI-2A" control={<Radio color="primary" />} label="ILI-2A (Influenza-Like Illness - 2A)" />
										<FormControlLabel value="ILI-2B" control={<Radio color="primary" />} label="ILI-2B (Influenza-Like Illness - 2B)" />
										<FormControlLabel value="SARI" control={<Radio color="primary" />} label="SARI (Severe Acute Respiratory Infection)" />
										<FormControlLabel value="PUM" control={<Radio color="primary" />} label="PUM (Person Under Monitoring)" />
										<FormControlLabel value="SAFE-1" control={<Radio color="primary" />} label="SAFE-1" />
										<FormControlLabel value="SAFE-2" control={<Radio color="primary" />} label="SAFE-2" />
									</RadioGroup>
								</FormControl>
							</div>
							<br/>
							<Divider/>
		                    <br/>
		                    <div style={{paddingLeft:10, paddingRight:10}}>
			                    <Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Entry Status
								</Typography>
								<FormControlLabel
									control={<Radio
										checked={this.state.status === '1'}
										onChange={this.handleChange('status')}
										value='1'
										name="radio-button-allowed"
										style={{ color: 'green' }}
										inputProps={{ 'aria-label': 'Allowed' }}
									/>}
									label="Allowed"
								/>
								&nbsp;&nbsp;&nbsp;
								<FormControlLabel
									control={<Radio
											checked={this.state.status === '2'}
											onChange={this.handleChange('status')}
											value='2'
											name="radio-button-not-allowed"
											style={{ color: 'red' }}
											inputProps={{ 'aria-label': 'Not Allowed' }}
										/>}
									label="Not Allowed"
								/><br/>
								<br/>
								{this.state.status === '2' ?
								<>
									<TextField
										id="outlined-basic"
										variant="outlined"
				                        label = "Date Started Monitoring"
				                        type = "date"
				                        onChange={ this.handleChange('date_responded') }
				                        value = { this.dateStr(this.state.report.date_responded) }
				                        fullWidth
				                        className={classes.margin}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
				                        error = { this.state.date_responded_error }
				                        helperText = { this.state.date_responded_error_text }
				                    />
									<br/>
									<br/>
				                    <TextField
				                   	 	id="outlined-basic"
				                   	 	variant="outlined"
				                        label = "Admission/Quarantine Facility"
				                        onChange={ this.handleChange('admit_quarantine_facility') }
				                        fullWidth
				                        value = { this.state.report.admit_quarantine_facility }
				                        error = {this.state.aqfError}
				                        helperText = { this.state.aqfErrorText }
				                        className={classes.margin}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
				                    />
				                    <br/>
									<br/>
			                    </> : null}
		                    </div>
                            <Divider/>
                            <br/>
                            <Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<div align='left'>
							        	<Button align='left' size="large" variant="outlined" color='secondary'
							        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
							        			onClick={this.handleBack}>
						        			CANCEL</Button>
									</div>
								</Grid>
								<Grid item xs={12} md={6}>
									<div align='right' style={{ paddingBottom: 10 }}>
										<Button
											fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
											size='large'
											variant='contained'
		                                    color='primary'
											onClick={this.handleConfirm}
											disabled = {this.state.loading}>
												{this.state.loading? "SAVING..":"SAVE"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Paper>
						</div>
			      	</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}

}

export default UpdateClassification;
