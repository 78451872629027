export default [
	{lat:	9.8191	, long:	123.3767	, link_barangay:	'Matutinao'	, link_city:	'Badian'	},
	{lat:	9.8240	, long:	123.4369	, link_barangay:	'Patong'	, link_city:	'Badian'	},
	{lat:	9.8695	, long:	123.3958	, link_barangay:	'Poblacion'	, link_city:	'Badian'	},
	{lat:	9.8112	, long:	123.3863	, link_barangay:	'Sanlagan'	, link_city:	'Badian'	},
	{lat:	9.8824	, long:	123.4597	, link_barangay:	'Santicon'	, link_city:	'Badian'	},
	{lat:	9.8660	, long:	123.4598	, link_barangay:	'Sohoton'	, link_city:	'Badian'	},
	{lat:	9.8027	, long:	123.4013	, link_barangay:	'Sulsugan'	, link_city:	'Badian'	},
	{lat:	9.8488	, long:	123.4189	, link_barangay:	'Talayong'	, link_city:	'Badian'	},
	{lat:	9.8557	, long:	123.3970	, link_barangay:	'Taytay'	, link_city:	'Badian'	},
	{lat:	9.8844	, long:	123.4383	, link_barangay:	'Tigbao'	, link_city:	'Badian'	},
	{lat:	9.8464	, long:	123.4518	, link_barangay:	'Tiguib'	, link_city:	'Badian'	},
	{lat:	9.8416	, long:	123.3948	, link_barangay:	'Tubod'	, link_city:	'Badian'	},
	{lat:	9.8835	, long:	123.3789	, link_barangay:	'Zaragosa'	, link_city:	'Badian'	},
	{lat:	10.4776	, long:	123.7158	, link_barangay:	'Abucayan'	, link_city:	'Balamban'	},
	{lat:	10.4994	, long:	123.7175	, link_barangay:	'Aliwanay'	, link_city:	'Balamban'	},
	{lat:	10.4386	, long:	123.7088	, link_barangay:	'Arpili'	, link_city:	'Balamban'	},
	{lat:	10.5109	, long:	123.7139	, link_barangay:	'Baliwagan'	, link_city:	'Balamban'	},
	{lat:	10.4672	, long:	123.7878	, link_barangay:	'Bayong'	, link_city:	'Balamban'	},
	{lat:	10.5325	, long:	123.7585	, link_barangay:	'Biasong'	, link_city:	'Balamban'	},
	{lat:	10.4619	, long:	123.7093	, link_barangay:	'Buanoy'	, link_city:	'Balamban'	},
	{lat:	10.5309	, long:	123.8068	, link_barangay:	'Cabagdalan'	, link_city:	'Balamban'	},
	{lat:	10.4853	, long:	123.8581	, link_barangay:	'Cabasiangan'	, link_city:	'Balamban'	},
	{lat:	10.4955	, long:	123.7283	, link_barangay:	'Cambuhawe'	, link_city:	'Balamban'	},
	{lat:	11.0507	, long:	124.0097	, link_barangay:	'Sudlonon'	, link_city:	'Bogo City'	},
	{lat:	11.0450	, long:	123.9909	, link_barangay:	'Taytayan'	, link_city:	'Bogo City'	},
	{lat:	9.6440	, long:	123.4747	, link_barangay:	'Arbor'	, link_city:	'Boljoon'	},
	{lat:	9.6557	, long:	123.4733	, link_barangay:	'Baclayan'	, link_city:	'Boljoon'	},
	{lat:	9.6663	, long:	123.4786	, link_barangay:	'El Pardo'	, link_city:	'Boljoon'	},
	{lat:	9.6043	, long:	123.4649	, link_barangay:	'Granada'	, link_city:	'Boljoon'	},
	{lat:	9.6145	, long:	123.4569	, link_barangay:	'Lower Becerril'	, link_city:	'Boljoon'	},
	{lat:	9.6201	, long:	123.4307	, link_barangay:	'Lunop'	, link_city:	'Boljoon'	},
	{lat:	9.6776	, long:	123.4396	, link_barangay:	'Nangka'	, link_city:	'Boljoon'	},
	{lat:	9.6256	, long:	123.4575	, link_barangay:	'Poblacion'	, link_city:	'Boljoon'	},
	{lat:	9.6582	, long:	123.4376	, link_barangay:	'San Antonio'	, link_city:	'Boljoon'	},
	{lat:	9.5963	, long:	123.4572	, link_barangay:	'South Granada'	, link_city:	'Boljoon'	},
	{lat:	9.6302	, long:	123.4130	, link_barangay:	'Upper Becerril'	, link_city:	'Boljoon'	},
	{lat:	10.8779	, long:	124.0136	, link_barangay:	'Bagacay'	, link_city:	'Borbon'	},
	{lat:	10.8361	, long:	123.9651	, link_barangay:	'Bili'	, link_city:	'Borbon'	},
	{lat:	10.8071	, long:	124.0109	, link_barangay:	'Bingay'	, link_city:	'Borbon'	},
	{lat:	10.8530	, long:	123.9681	, link_barangay:	'Bongdo Gua'	, link_city:	'Borbon'	},
	{lat:	10.8543	, long:	123.9507	, link_barangay:	'Bongdo'	, link_city:	'Borbon'	},
	{lat:	10.8564	, long:	124.0008	, link_barangay:	'Bongoyan'	, link_city:	'Borbon'	},
	{lat:	10.8555	, long:	124.0278	, link_barangay:	'Cadaruhan'	, link_city:	'Borbon'	},
	{lat:	10.8246	, long:	123.9622	, link_barangay:	'Cajel'	, link_city:	'Borbon'	},
	{lat:	10.8196	, long:	124.0176	, link_barangay:	'Campusong'	, link_city:	'Borbon'	},
	{lat:	10.8307	, long:	123.9880	, link_barangay:	'Clavera'	, link_city:	'Borbon'	},
	{lat:	10.8089	, long:	123.9081	, link_barangay:	'Bongon'	, link_city:	'Tabuelan'	},
	{lat:	10.8207	, long:	123.9370	, link_barangay:	'Dalid'	, link_city:	'Tabuelan'	},
	{lat:	10.8570	, long:	123.9317	, link_barangay:	'Kanlim-Ao'	, link_city:	'Tabuelan'	},
	{lat:	10.8823	, long:	123.9442	, link_barangay:	'Kanluhangon'	, link_city:	'Tabuelan'	},
	{lat:	10.8844	, long:	123.9228	, link_barangay:	'Kantubaon'	, link_city:	'Tabuelan'	},
	{lat:	10.8422	, long:	123.9283	, link_barangay:	'Mabunao'	, link_city:	'Tabuelan'	},
	{lat:	10.8893	, long:	123.8981	, link_barangay:	'Maravilla'	, link_city:	'Tabuelan'	},
	{lat:	10.8571	, long:	123.8967	, link_barangay:	'Olivo'	, link_city:	'Tabuelan'	},
	{lat:	10.8164	, long:	123.8724	, link_barangay:	'Poblacion'	, link_city:	'Tabuelan'	},
	{lat:	10.8398	, long:	123.8854	, link_barangay:	'Tabunok'	, link_city:	'Tabuelan'	},
	{lat:	10.8707	, long:	123.8933	, link_barangay:	'Tigbawan'	, link_city:	'Tabuelan'	},
	{lat:	10.8667	, long:	123.9160	, link_barangay:	'Villahermosa'	, link_city:	'Tabuelan'	},
	{lat:	10.2410	, long:	123.8328	, link_barangay:	'Biasong'	, link_city:	'Talisay City'	},
	{lat:	10.2710	, long:	123.8485	, link_barangay:	'Bulacao'	, link_city:	'Talisay City'	},
	{lat:	10.2821	, long:	123.8371	, link_barangay:	'Cadulawan'	, link_city:	'Talisay City'	},
	{lat:	10.3140	, long:	123.8106	, link_barangay:	'Camp IV'	, link_city:	'Talisay City'	},
	{lat:	10.2511	, long:	123.8531	, link_barangay:	'Cansojong'	, link_city:	'Talisay City'	},
	{lat:	10.2415	, long:	123.8394	, link_barangay:	'Dumlog'	, link_city:	'Talisay City'	},
	{lat:	10.2922	, long:	123.8179	, link_barangay:	'Jaclupan'	, link_city:	'Talisay City'	},
	{lat:	10.2719	, long:	123.8357	, link_barangay:	'Lagtang'	, link_city:	'Talisay City'	},
	{lat:	10.2589	, long:	123.8300	, link_barangay:	'Lawaan I'	, link_city:	'Talisay City'	},
	{lat:	10.2535	, long:	123.8366	, link_barangay:	'Lawaan II'	, link_city:	'Talisay City'	},
	{lat:	9.9583	, long:	123.4615	, link_barangay:	'Cabadiangan'	, link_city:	'Alcantara'	},
	{lat:	9.9741	, long:	123.4189	, link_barangay:	'Cabil-Isan'	, link_city:	'Alcantara'	},
	{lat:	9.9722	, long:	123.4094	, link_barangay:	'Candabong'	, link_city:	'Alcantara'	},
	{lat:	9.9671	, long:	123.4341	, link_barangay:	'Lawaan'	, link_city:	'Alcantara'	},
	{lat:	9.9649	, long:	123.4883	, link_barangay:	'Manga'	, link_city:	'Alcantara'	},
	{lat:	9.9804	, long:	123.4069	, link_barangay:	'Palanas'	, link_city:	'Alcantara'	},
	{lat:	9.9735	, long:	123.4037	, link_barangay:	'Poblacion'	, link_city:	'Alcantara'	},
	{lat:	9.9638	, long:	123.4060	, link_barangay:	'Polo'	, link_city:	'Alcantara'	},
	{lat:	9.9700	, long:	123.4631	, link_barangay:	'Salagmaya'	, link_city:	'Alcantara'	},
	{lat:	9.7051	, long:	123.4843	, link_barangay:	'Atabay'	, link_city:	'Alcoy'	},
	{lat:	9.6805	, long:	123.4789	, link_barangay:	'Daan-Lungsod'	, link_city:	'Alcoy'	},
	{lat:	9.6915	, long:	123.4964	, link_barangay:	'Guiwang'	, link_city:	'Alcoy'	},
	{lat:	9.7029	, long:	123.4411	, link_barangay:	'Nug-As'	, link_city:	'Alcoy'	},
	{lat:	9.7305	, long:	123.4681	, link_barangay:	'Pasol'	, link_city:	'Alcoy'	},
	{lat:	9.7176	, long:	123.4681	, link_barangay:	'Poblacion'	, link_city:	'Alcoy'	},
	{lat:	9.7427	, long:	123.4696	, link_barangay:	'Pugalo'	, link_city:	'Alcoy'	},
	{lat:	9.6969	, long:	123.4699	, link_barangay:	'San Agustin'	, link_city:	'Alcoy'	},
	{lat:	9.7825	, long:	123.3992	, link_barangay:	'Compostela'	, link_city:	'Alegria'	},
	{lat:	9.7340	, long:	123.3956	, link_barangay:	'Guadalupe'	, link_city:	'Alegria'	},
	{lat:	9.7149	, long:	123.3473	, link_barangay:	'Legaspi'	, link_city:	'Alegria'	},
	{lat:	9.7061	, long:	123.4117	, link_barangay:	'Lepanto'	, link_city:	'Alegria'	},
	{lat:	9.7866	, long:	123.3628	, link_barangay:	'Madridejos'	, link_city:	'Alegria'	},
	{lat:	9.7132	, long:	123.3764	, link_barangay:	'Montpeller'	, link_city:	'Alegria'	},
	{lat:	9.7286	, long:	123.3499	, link_barangay:	'Poblacion'	, link_city:	'Alegria'	},
	{lat:	9.7519	, long:	123.3522	, link_barangay:	'Santa Filomena'	, link_city:	'Alegria'	},
	{lat:	9.7584	, long:	123.3945	, link_barangay:	'Valencia'	, link_city:	'Alegria'	},
	{lat:	10.2197	, long:	123.5774	, link_barangay:	'Angilan'	, link_city:	'Aloguinsan'	},
	{lat:	10.2013	, long:	123.5407	, link_barangay:	'Bojo'	, link_city:	'Aloguinsan'	},
	{lat:	10.2339	, long:	123.5633	, link_barangay:	'Bonbon'	, link_city:	'Aloguinsan'	},
	{lat:	10.1865	, long:	123.5912	, link_barangay:	'Esperanza'	, link_city:	'Aloguinsan'	},
	{lat:	10.1936	, long:	123.6074	, link_barangay:	'Kandingan'	, link_city:	'Aloguinsan'	},
	{lat:	10.1913	, long:	123.5336	, link_barangay:	'Kantabogon'	, link_city:	'Aloguinsan'	},
	{lat:	10.1878	, long:	123.5530	, link_barangay:	'Kawasan'	, link_city:	'Aloguinsan'	},
	{lat:	10.1917	, long:	123.5704	, link_barangay:	'Olango'	, link_city:	'Aloguinsan'	},
	{lat:	10.2148	, long:	123.5493	, link_barangay:	'Poblacion'	, link_city:	'Aloguinsan'	},
	{lat:	10.2050	, long:	123.5970	, link_barangay:	'Punay'	, link_city:	'Aloguinsan'	},
	{lat:	10.1845	, long:	123.6147	, link_barangay:	'Rosario'	, link_city:	'Aloguinsan'	},
	{lat:	10.1718	, long:	123.5647	, link_barangay:	'Saksak'	, link_city:	'Aloguinsan'	},
	{lat:	10.2077	, long:	123.5710	, link_barangay:	'Tampa-An'	, link_city:	'Aloguinsan'	},
	{lat:	10.1687	, long:	123.5771	, link_barangay:	'Toyokon'	, link_city:	'Aloguinsan'	},
	{lat:	10.1605	, long:	123.5945	, link_barangay:	'Zaragosa'	, link_city:	'Aloguinsan'	},
	{lat:	9.9271	, long:	123.5186	, link_barangay:	'Alambijud'	, link_city:	'Argao'	},
	{lat:	9.9467	, long:	123.5137	, link_barangay:	'Anajao'	, link_city:	'Argao'	},
	{lat:	9.9700	, long:	123.5146	, link_barangay:	'Apo'	, link_city:	'Argao'	},
	{lat:	9.8863	, long:	123.4940	, link_barangay:	'Balaas'	, link_city:	'Argao'	},
	{lat:	9.8432	, long:	123.5571	, link_barangay:	'Balisong'	, link_city:	'Argao'	},
	{lat:	9.9234	, long:	123.6054	, link_barangay:	'Binlod'	, link_city:	'Argao'	},
	{lat:	9.8617	, long:	123.5743	, link_barangay:	'Bogo'	, link_city:	'Argao'	},
	{lat:	9.8747	, long:	123.5643	, link_barangay:	'Bug-Ot'	, link_city:	'Argao'	},
	{lat:	9.9354	, long:	123.6099	, link_barangay:	'Bulasa'	, link_city:	'Argao'	},
	{lat:	9.8726	, long:	123.4919	, link_barangay:	'Butong'	, link_city:	'Argao'	},
	{lat:	9.8976	, long:	123.5030	, link_barangay:	'Calagasan'	, link_city:	'Argao'	},
	{lat:	9.8705	, long:	123.5217	, link_barangay:	'Canbantug'	, link_city:	'Argao'	},
	{lat:	9.8785	, long:	123.5917	, link_barangay:	'Canbanua'	, link_city:	'Argao'	},
	{lat:	9.9131	, long:	123.5133	, link_barangay:	'Cansuje'	, link_city:	'Argao'	},
	{lat:	9.9553	, long:	123.5955	, link_barangay:	'Capio-An'	, link_city:	'Argao'	},
	{lat:	9.8289	, long:	123.5514	, link_barangay:	'Casay'	, link_city:	'Argao'	},
	{lat:	9.8989	, long:	123.5441	, link_barangay:	'Catang'	, link_city:	'Argao'	},
	{lat:	9.9713	, long:	123.5402	, link_barangay:	'Colawin'	, link_city:	'Argao'	},
	{lat:	9.8504	, long:	123.5336	, link_barangay:	'Conalum'	, link_city:	'Argao'	},
	{lat:	9.9594	, long:	123.6124	, link_barangay:	'Guiwanon'	, link_city:	'Argao'	},
	{lat:	9.9657	, long:	123.5627	, link_barangay:	'Gutlang'	, link_city:	'Argao'	},
	{lat:	9.9060	, long:	123.5976	, link_barangay:	'Jampang'	, link_city:	'Argao'	},
	{lat:	9.8837	, long:	123.5646	, link_barangay:	'Jomgao'	, link_city:	'Argao'	},
	{lat:	9.8824	, long:	123.5826	, link_barangay:	'Lamacan'	, link_city:	'Argao'	},
	{lat:	9.8872	, long:	123.6055	, link_barangay:	'Langtad'	, link_city:	'Argao'	},
	{lat:	9.9660	, long:	123.6087	, link_barangay:	'Langub'	, link_city:	'Argao'	},
	{lat:	9.8646	, long:	123.5628	, link_barangay:	'Lapay'	, link_city:	'Argao'	},
	{lat:	9.9330	, long:	123.5413	, link_barangay:	'Lengigon'	, link_city:	'Argao'	},
	{lat:	9.8573	, long:	123.5143	, link_barangay:	'Linut-Od'	, link_city:	'Argao'	},
	{lat:	9.9303	, long:	123.5793	, link_barangay:	'Mabasa'	, link_city:	'Argao'	},
	{lat:	9.9463	, long:	123.5570	, link_barangay:	'Mandilikit'	, link_city:	'Argao'	},
	{lat:	9.9511	, long:	123.5321	, link_barangay:	'Mompeller'	, link_city:	'Argao'	},
	{lat:	9.8947	, long:	123.5623	, link_barangay:	'Panadtaran'	, link_city:	'Argao'	},
	{lat:	9.8778	, long:	123.6022	, link_barangay:	'Poblacion'	, link_city:	'Argao'	},
	{lat:	9.9491	, long:	123.5819	, link_barangay:	'Sua'	, link_city:	'Argao'	},
	{lat:	9.9647	, long:	123.5842	, link_barangay:	'Sumaguan'	, link_city:	'Argao'	},
	{lat:	9.9124	, long:	123.5705	, link_barangay:	'Tabayag'	, link_city:	'Argao'	},
	{lat:	9.8527	, long:	123.5544	, link_barangay:	'Talaga'	, link_city:	'Argao'	},
	{lat:	9.8947	, long:	123.5835	, link_barangay:	'Talaytay'	, link_city:	'Argao'	},
	{lat:	9.9488	, long:	123.6113	, link_barangay:	'Talo-Ot'	, link_city:	'Argao'	},
	{lat:	9.8778	, long:	123.5426	, link_barangay:	'Tiguib'	, link_city:	'Argao'	},
	{lat:	9.9388	, long:	123.5115	, link_barangay:	'Tulang'	, link_city:	'Argao'	},
	{lat:	9.8712	, long:	123.5823	, link_barangay:	'Tulic'	, link_city:	'Argao'	},
	{lat:	9.9265	, long:	123.5524	, link_barangay:	'Ubaub'	, link_city:	'Argao'	},
	{lat:	9.9089	, long:	123.5541	, link_barangay:	'Usmad'	, link_city:	'Argao'	},
	{lat:	10.5921	, long:	123.8166	, link_barangay:	'Agbanga'	, link_city:	'Asturias'	},
	{lat:	10.5978	, long:	123.7881	, link_barangay:	'Agtugop'	, link_city:	'Asturias'	},
	{lat:	10.6225	, long:	123.7870	, link_barangay:	'Bago'	, link_city:	'Asturias'	},
	{lat:	10.5756	, long:	123.8314	, link_barangay:	'Bairan'	, link_city:	'Asturias'	},
	{lat:	10.6116	, long:	123.8387	, link_barangay:	'Banban'	, link_city:	'Asturias'	},
	{lat:	10.5872	, long:	123.8393	, link_barangay:	'Baye'	, link_city:	'Asturias'	},
	{lat:	10.6040	, long:	123.7670	, link_barangay:	'Bog-O'	, link_city:	'Asturias'	},
	{lat:	10.5535	, long:	123.8814	, link_barangay:	'Kaluangan'	, link_city:	'Asturias'	},
	{lat:	10.5979	, long:	123.8387	, link_barangay:	'Lanao'	, link_city:	'Asturias'	},
	{lat:	10.5804	, long:	123.7396	, link_barangay:	'Langub'	, link_city:	'Asturias'	},
	{lat:	10.5690	, long:	123.7358	, link_barangay:	'Looc Norte'	, link_city:	'Asturias'	},
	{lat:	10.6308	, long:	123.8213	, link_barangay:	'Lunas'	, link_city:	'Asturias'	},
	{lat:	10.5738	, long:	123.8042	, link_barangay:	'Magcalape'	, link_city:	'Asturias'	},
	{lat:	10.5767	, long:	123.7797	, link_barangay:	'Manguiao'	, link_city:	'Asturias'	},
	{lat:	10.6289	, long:	123.7690	, link_barangay:	'New Bago'	, link_city:	'Asturias'	},
	{lat:	10.5498	, long:	123.7226	, link_barangay:	'Owak'	, link_city:	'Asturias'	},
	{lat:	10.5647	, long:	123.7185	, link_barangay:	'Poblacion'	, link_city:	'Asturias'	},
	{lat:	10.6177	, long:	123.8934	, link_barangay:	'Saksak'	, link_city:	'Asturias'	},
	{lat:	10.5548	, long:	123.7546	, link_barangay:	'San Isidro'	, link_city:	'Asturias'	},
	{lat:	10.5955	, long:	123.7464	, link_barangay:	'San Roque'	, link_city:	'Asturias'	},
	{lat:	10.6404	, long:	123.7587	, link_barangay:	'Santa Lucia'	, link_city:	'Asturias'	},
	{lat:	10.6134	, long:	123.8105	, link_barangay:	'Santa Rita'	, link_city:	'Asturias'	},
	{lat:	10.5513	, long:	123.8262	, link_barangay:	'Tag-Amakan'	, link_city:	'Asturias'	},
	{lat:	10.5548	, long:	123.8504	, link_barangay:	'Tagbubonga'	, link_city:	'Asturias'	},
	{lat:	10.6187	, long:	123.7527	, link_barangay:	'Tubigagmanok'	, link_city:	'Asturias'	},
	{lat:	10.5829	, long:	123.7582	, link_barangay:	'Tubod'	, link_city:	'Asturias'	},
	{lat:	10.5549	, long:	123.7951	, link_barangay:	'Ubogon'	, link_city:	'Asturias'	},
	{lat:	9.8266	, long:	123.4096	, link_barangay:	'Alawijao'	, link_city:	'Badian'	},
	{lat:	9.8131	, long:	123.3731	, link_barangay:	'Balhaan'	, link_city:	'Badian'	},
	{lat:	9.8555	, long:	123.3875	, link_barangay:	'Banhigan'	, link_city:	'Badian'	},
	{lat:	9.8339	, long:	123.4177	, link_barangay:	'Basak'	, link_city:	'Badian'	},
	{lat:	9.8021	, long:	123.4234	, link_barangay:	'Basiao'	, link_city:	'Badian'	},
	{lat:	9.8469	, long:	123.3757	, link_barangay:	'Bato'	, link_city:	'Badian'	},
	{lat:	9.8931	, long:	123.4158	, link_barangay:	'Bugas'	, link_city:	'Badian'	},
	{lat:	9.8198	, long:	123.4003	, link_barangay:	'Calangcang'	, link_city:	'Badian'	},
	{lat:	9.7912	, long:	123.4234	, link_barangay:	'Candiis'	, link_city:	'Badian'	},
	{lat:	9.8610	, long:	123.4241	, link_barangay:	'Dagatan'	, link_city:	'Badian'	},
	{lat:	9.8598	, long:	123.4072	, link_barangay:	'Dobdob'	, link_city:	'Badian'	},
	{lat:	9.8263	, long:	123.3779	, link_barangay:	'Ginablan'	, link_city:	'Badian'	},
	{lat:	9.8573	, long:	123.3777	, link_barangay:	'Lambug'	, link_city:	'Badian'	},
	{lat:	9.8352	, long:	123.3799	, link_barangay:	'Malabago'	, link_city:	'Badian'	},
	{lat:	9.8750	, long:	123.4067	, link_barangay:	'Malhiao'	, link_city:	'Badian'	},
	{lat:	9.8825	, long:	123.4124	, link_barangay:	'Manduyong'	, link_city:	'Badian'	},
	{lat:	10.4748	, long:	123.7562	, link_barangay:	'Cansomoroy'	, link_city:	'Balamban'	},
	{lat:	10.4639	, long:	123.7348	, link_barangay:	'Cantibas'	, link_city:	'Balamban'	},
	{lat:	10.5193	, long:	123.7252	, link_barangay:	'Cantuod'	, link_city:	'Balamban'	},
	{lat:	10.4510	, long:	123.7648	, link_barangay:	'Duangan'	, link_city:	'Balamban'	},
	{lat:	10.4238	, long:	123.7541	, link_barangay:	'Gaas'	, link_city:	'Balamban'	},
	{lat:	10.5181	, long:	123.8544	, link_barangay:	'Ginatilan'	, link_city:	'Balamban'	},
	{lat:	10.5097	, long:	123.7719	, link_barangay:	'Hingatmonan'	, link_city:	'Balamban'	},
	{lat:	10.5034	, long:	123.7969	, link_barangay:	'Lamesa'	, link_city:	'Balamban'	},
	{lat:	10.5364	, long:	123.7866	, link_barangay:	'Liki'	, link_city:	'Balamban'	},
	{lat:	10.5137	, long:	123.8234	, link_barangay:	'Luca'	, link_city:	'Balamban'	},
	{lat:	10.4857	, long:	123.8248	, link_barangay:	'Matun-Og'	, link_city:	'Balamban'	},
	{lat:	10.5311	, long:	123.7201	, link_barangay:	'Nangka'	, link_city:	'Balamban'	},
	{lat:	10.4867	, long:	123.7234	, link_barangay:	'Pondol'	, link_city:	'Balamban'	},
	{lat:	10.5001	, long:	123.7345	, link_barangay:	'Prenza'	, link_city:	'Balamban'	},
	{lat:	10.5054	, long:	123.7163	, link_barangay:	'Santa Cruz-Santo Niño'	, link_city:	'Balamban'	},
	{lat:	10.5157	, long:	123.7407	, link_barangay:	'Singsing'	, link_city:	'Balamban'	},
	{lat:	10.4502	, long:	123.8064	, link_barangay:	'Sunog'	, link_city:	'Balamban'	},
	{lat:	10.4957	, long:	123.7634	, link_barangay:	'Vito'	, link_city:	'Balamban'	},
	{lat:	11.2337	, long:	123.7470	, link_barangay:	'Atop-Atop'	, link_city:	'Bantayan'	},
	{lat:	11.1879	, long:	123.7635	, link_barangay:	'Baigad'	, link_city:	'Bantayan'	},
	{lat:	11.1839	, long:	123.7216	, link_barangay:	'Bantigue'	, link_city:	'Bantayan'	},
	{lat:	11.2099	, long:	123.7086	, link_barangay:	'Baod'	, link_city:	'Bantayan'	},
	{lat:	11.1741	, long:	123.7211	, link_barangay:	'Binaobao'	, link_city:	'Bantayan'	},
	{lat:	11.0994	, long:	123.6680	, link_barangay:	'Botigues'	, link_city:	'Bantayan'	},
	{lat:	11.0847	, long:	123.6477	, link_barangay:	'Doong'	, link_city:	'Bantayan'	},
	{lat:	11.1976	, long:	123.7102	, link_barangay:	'Guiwanon'	, link_city:	'Bantayan'	},
	{lat:	11.1454	, long:	123.6442	, link_barangay:	'Hilotongan'	, link_city:	'Bantayan'	},
	{lat:	11.2353	, long:	123.7093	, link_barangay:	'Kabac'	, link_city:	'Bantayan'	},
	{lat:	11.2062	, long:	123.7267	, link_barangay:	'Kabangbang'	, link_city:	'Bantayan'	},
	{lat:	11.2334	, long:	123.7270	, link_barangay:	'Kampingganon'	, link_city:	'Bantayan'	},
	{lat:	11.1971	, long:	123.7431	, link_barangay:	'Kangkaibe'	, link_city:	'Bantayan'	},
	{lat:	11.0530	, long:	123.6278	, link_barangay:	'Lipayran'	, link_city:	'Bantayan'	},
	{lat:	11.0740	, long:	123.6416	, link_barangay:	'Luyongbaybay'	, link_city:	'Bantayan'	},
	{lat:	11.1762	, long:	123.7491	, link_barangay:	'Mojon'	, link_city:	'Bantayan'	},
	{lat:	11.1574	, long:	123.7496	, link_barangay:	'Obo-Ob'	, link_city:	'Bantayan'	},
	{lat:	11.2227	, long:	123.6984	, link_barangay:	'Patao'	, link_city:	'Bantayan'	},
	{lat:	11.2198	, long:	123.7143	, link_barangay:	'Putian'	, link_city:	'Bantayan'	},
	{lat:	11.2035	, long:	123.7627	, link_barangay:	'Sillon'	, link_city:	'Bantayan'	},
	{lat:	11.1585	, long:	123.7178	, link_barangay:	'Suba'	, link_city:	'Bantayan'	},
	{lat:	11.1438	, long:	123.7297	, link_barangay:	'Sulangan'	, link_city:	'Bantayan'	},
	{lat:	11.1557	, long:	123.7326	, link_barangay:	'Sungko'	, link_city:	'Bantayan'	},
	{lat:	11.2192	, long:	123.7521	, link_barangay:	'Tamiao'	, link_city:	'Bantayan'	},
	{lat:	11.1676	, long:	123.7282	, link_barangay:	'Ticad'	, link_city:	'Bantayan'	},
	{lat:	10.1223	, long:	123.5167	, link_barangay:	'Azucena'	, link_city:	'Barili'	},
	{lat:	10.1042	, long:	123.5484	, link_barangay:	'Bagakay'	, link_city:	'Barili'	},
	{lat:	10.0710	, long:	123.5129	, link_barangay:	'Balao'	, link_city:	'Barili'	},
	{lat:	10.1171	, long:	123.5277	, link_barangay:	'Bolocboloc'	, link_city:	'Barili'	},
	{lat:	10.0893	, long:	123.5151	, link_barangay:	'Budbud'	, link_city:	'Barili'	},
	{lat:	10.1405	, long:	123.5892	, link_barangay:	'Bugtong Kawayan'	, link_city:	'Barili'	},
	{lat:	10.1394	, long:	123.5555	, link_barangay:	'Cabcaban'	, link_city:	'Barili'	},
	{lat:	10.1371	, long:	123.5364	, link_barangay:	'Cagay'	, link_city:	'Barili'	},
	{lat:	10.1062	, long:	123.5267	, link_barangay:	'Campangga'	, link_city:	'Barili'	},
	{lat:	10.1365	, long:	123.5068	, link_barangay:	'Candugay'	, link_city:	'Barili'	},
	{lat:	10.1153	, long:	123.5595	, link_barangay:	'Dakit'	, link_city:	'Barili'	},
	{lat:	10.1459	, long:	123.5211	, link_barangay:	'Giloctog'	, link_city:	'Barili'	},
	{lat:	10.0927	, long:	123.4738	, link_barangay:	'Giwanon'	, link_city:	'Barili'	},
	{lat:	10.1146	, long:	123.4883	, link_barangay:	'Guibuangan'	, link_city:	'Barili'	},
	{lat:	10.0973	, long:	123.5207	, link_barangay:	'Gunting'	, link_city:	'Barili'	},
	{lat:	10.1644	, long:	123.5262	, link_barangay:	'Hilasgasan'	, link_city:	'Barili'	},
	{lat:	10.1256	, long:	123.5000	, link_barangay:	'Japitan'	, link_city:	'Barili'	},
	{lat:	10.1257	, long:	123.5462	, link_barangay:	'Kalubihan'	, link_city:	'Barili'	},
	{lat:	10.0689	, long:	123.5337	, link_barangay:	'Kangdampas'	, link_city:	'Barili'	},
	{lat:	10.0828	, long:	123.5273	, link_barangay:	'Luhod'	, link_city:	'Barili'	},
	{lat:	10.0979	, long:	123.4919	, link_barangay:	'Lupo'	, link_city:	'Barili'	},
	{lat:	10.0881	, long:	123.4873	, link_barangay:	'Luyo'	, link_city:	'Barili'	},
	{lat:	10.1102	, long:	123.5039	, link_barangay:	'Maghanoy'	, link_city:	'Barili'	},
	{lat:	10.0952	, long:	123.5022	, link_barangay:	'Maigang'	, link_city:	'Barili'	},
	{lat:	10.1544	, long:	123.5618	, link_barangay:	'Malolos'	, link_city:	'Barili'	},
	{lat:	10.1267	, long:	123.5808	, link_barangay:	'Mantalongon'	, link_city:	'Barili'	},
	{lat:	10.0860	, long:	123.5404	, link_barangay:	'Mantayupan'	, link_city:	'Barili'	},
	{lat:	10.0877	, long:	123.5594	, link_barangay:	'Mayana'	, link_city:	'Barili'	},
	{lat:	10.0996	, long:	123.4792	, link_barangay:	'Minolos'	, link_city:	'Barili'	},
	{lat:	10.1058	, long:	123.4977	, link_barangay:	'Nabunturan'	, link_city:	'Barili'	},
	{lat:	10.1116	, long:	123.5402	, link_barangay:	'Nasipit'	, link_city:	'Barili'	},
	{lat:	10.0820	, long:	123.4993	, link_barangay:	'Pancil'	, link_city:	'Barili'	},
	{lat:	10.0553	, long:	123.5277	, link_barangay:	'Pangpang'	, link_city:	'Barili'	},
	{lat:	10.1541	, long:	123.5394	, link_barangay:	'Paril'	, link_city:	'Barili'	},
	{lat:	10.1302	, long:	123.5235	, link_barangay:	'Patupat'	, link_city:	'Barili'	},
	{lat:	10.1088	, long:	123.5111	, link_barangay:	'Poblacion'	, link_city:	'Barili'	},
	{lat:	10.1298	, long:	123.5080	, link_barangay:	'San Rafael'	, link_city:	'Barili'	},
	{lat:	10.1204	, long:	123.5086	, link_barangay:	'Santa Ana'	, link_city:	'Barili'	},
	{lat:	10.1075	, long:	123.4874	, link_barangay:	'Sayaw'	, link_city:	'Barili'	},
	{lat:	10.1391	, long:	123.5757	, link_barangay:	'Tal-Ot'	, link_city:	'Barili'	},
	{lat:	10.1174	, long:	123.5733	, link_barangay:	'Tubod'	, link_city:	'Barili'	},
	{lat:	10.1726	, long:	123.5386	, link_barangay:	'Vito'	, link_city:	'Barili'	},
	{lat:	11.0047	, long:	123.9701	, link_barangay:	'Anonang Norte'	, link_city:	'Bogo City'	},
	{lat:	10.9940	, long:	123.9671	, link_barangay:	'Anonang Sur'	, link_city:	'Bogo City'	},
	{lat:	11.0206	, long:	123.9707	, link_barangay:	'Banban'	, link_city:	'Bogo City'	},
	{lat:	10.9804	, long:	123.9666	, link_barangay:	'Binabag'	, link_city:	'Bogo City'	},
	{lat:	11.0415	, long:	124.0072	, link_barangay:	'Bungtod'	, link_city:	'Bogo City'	},
	{lat:	11.0516	, long:	124.0058	, link_barangay:	'Carbon'	, link_city:	'Bogo City'	},
	{lat:	11.0485	, long:	123.9710	, link_barangay:	'Cayang'	, link_city:	'Bogo City'	},
	{lat:	11.0404	, long:	124.0016	, link_barangay:	'Cogon'	, link_city:	'Bogo City'	},
	{lat:	11.0192	, long:	123.9996	, link_barangay:	'Dakit'	, link_city:	'Bogo City'	},
	{lat:	11.0701	, long:	123.9640	, link_barangay:	'Don Pedro Rodriguez'	, link_city:	'Bogo City'	},
	{lat:	11.0488	, long:	124.0204	, link_barangay:	'Gairan'	, link_city:	'Bogo City'	},
	{lat:	10.9956	, long:	124.0102	, link_barangay:	'Guadalupe'	, link_city:	'Bogo City'	},
	{lat:	10.9960	, long:	123.9862	, link_barangay:	'La Paz'	, link_city:	'Bogo City'	},
	{lat:	11.0493	, long:	124.0018	, link_barangay:	'La Purisima Concepcion'	, link_city:	'Bogo City'	},
	{lat:	11.0285	, long:	124.0193	, link_barangay:	'Libertad'	, link_city:	'Bogo City'	},
	{lat:	11.0500	, long:	124.0048	, link_barangay:	'Lourdes'	, link_city:	'Bogo City'	},
	{lat:	11.0274	, long:	123.9810	, link_barangay:	'Malingin'	, link_city:	'Bogo City'	},
	{lat:	11.0119	, long:	124.0310	, link_barangay:	'Marangog'	, link_city:	'Bogo City'	},
	{lat:	11.0458	, long:	124.0327	, link_barangay:	'Nailon'	, link_city:	'Bogo City'	},
	{lat:	10.9909	, long:	124.0332	, link_barangay:	'Odlot'	, link_city:	'Bogo City'	},
	{lat:	11.0463	, long:	124.0128	, link_barangay:	'Pandan'	, link_city:	'Bogo City'	},
	{lat:	11.0651	, long:	123.9871	, link_barangay:	'Polambato'	, link_city:	'Bogo City'	},
	{lat:	11.0539	, long:	124.0088	, link_barangay:	'Sambag'	, link_city:	'Bogo City'	},
	{lat:	11.0531	, long:	124.0043	, link_barangay:	'San Vicente'	, link_city:	'Bogo City'	},
	{lat:	11.0523	, long:	124.0095	, link_barangay:	'Santo Niño'	, link_city:	'Bogo City'	},
	{lat:	11.0533	, long:	124.0063	, link_barangay:	'Santo Rosario'	, link_city:	'Bogo City'	},
	{lat:	11.0276	, long:	124.0349	, link_barangay:	'Siocon'	, link_city:	'Bogo City'	},
	{lat:	10.8800	, long:	123.9746	, link_barangay:	'Don Gregorio Antigua'	, link_city:	'Borbon'	},
	{lat:	10.8415	, long:	123.9877	, link_barangay:	'Laaw'	, link_city:	'Borbon'	},
	{lat:	10.8145	, long:	123.9899	, link_barangay:	'Lugo'	, link_city:	'Borbon'	},
	{lat:	10.8783	, long:	123.9979	, link_barangay:	'Managase'	, link_city:	'Borbon'	},
	{lat:	10.8379	, long:	124.0171	, link_barangay:	'Poblacion'	, link_city:	'Borbon'	},
	{lat:	10.8585	, long:	123.9827	, link_barangay:	'Sagay'	, link_city:	'Borbon'	},
	{lat:	10.8772	, long:	123.9561	, link_barangay:	'San Jose'	, link_city:	'Borbon'	},
	{lat:	10.8686	, long:	124.0322	, link_barangay:	'Tabunan'	, link_city:	'Borbon'	},
	{lat:	10.8791	, long:	124.0349	, link_barangay:	'Tagnucan'	, link_city:	'Borbon'	},
	{lat:	10.0946	, long:	123.6266	, link_barangay:	'Bolinawan'	, link_city:	'Carcar'	},
	{lat:	10.1589	, long:	123.6159	, link_barangay:	'Buenavista'	, link_city:	'Carcar'	},
	{lat:	10.1612	, long:	123.6290	, link_barangay:	'Calidngan'	, link_city:	'Carcar'	},
	{lat:	10.1382	, long:	123.6555	, link_barangay:	'Can-Asujan'	, link_city:	'Carcar'	},
	{lat:	10.1163	, long:	123.6070	, link_barangay:	'Guadalupe'	, link_city:	'Carcar'	},
	{lat:	10.1213	, long:	123.6433	, link_barangay:	'Liburon'	, link_city:	'Carcar'	},
	{lat:	10.0972	, long:	123.5908	, link_barangay:	'Napo'	, link_city:	'Carcar'	},
	{lat:	10.0819	, long:	123.6203	, link_barangay:	'Ocana'	, link_city:	'Carcar'	},
	{lat:	10.1113	, long:	123.6704	, link_barangay:	'Perrelos'	, link_city:	'Carcar'	},
	{lat:	10.1004	, long:	123.6342	, link_barangay:	'Poblacion I'	, link_city:	'Carcar'	},
	{lat:	10.0957	, long:	123.6439	, link_barangay:	'Poblacion II'	, link_city:	'Carcar'	},
	{lat:	10.1078	, long:	123.6386	, link_barangay:	'Poblacion III'	, link_city:	'Carcar'	},
	{lat:	10.0900	, long:	123.6744	, link_barangay:	'Tuyom'	, link_city:	'Carcar'	},
	{lat:	10.1403	, long:	123.6160	, link_barangay:	'Valencia'	, link_city:	'Carcar'	},
	{lat:	10.1059	, long:	123.6525	, link_barangay:	'Valladolid'	, link_city:	'Carcar'	},
	{lat:	10.5926	, long:	124.0091	, link_barangay:	'Baring'	, link_city:	'Carmen'	},
	{lat:	10.6030	, long:	124.0124	, link_barangay:	'Cantipay'	, link_city:	'Carmen'	},
	{lat:	10.6093	, long:	123.9999	, link_barangay:	'Cantukong'	, link_city:	'Carmen'	},
	{lat:	10.5976	, long:	123.9950	, link_barangay:	'Cantumog'	, link_city:	'Carmen'	},
	{lat:	10.6243	, long:	123.9418	, link_barangay:	'Caurasan'	, link_city:	'Carmen'	},
	{lat:	10.5918	, long:	124.0195	, link_barangay:	'Cogon East'	, link_city:	'Carmen'	},
	{lat:	10.5880	, long:	124.0154	, link_barangay:	'Cogon West'	, link_city:	'Carmen'	},
	{lat:	10.5949	, long:	123.9755	, link_barangay:	'Corte'	, link_city:	'Carmen'	},
	{lat:	10.5766	, long:	124.0144	, link_barangay:	'Dawis Norte'	, link_city:	'Carmen'	},
	{lat:	10.5645	, long:	124.0172	, link_barangay:	'Dawis Sur'	, link_city:	'Carmen'	},
	{lat:	10.5808	, long:	123.9806	, link_barangay:	'Hagnaya'	, link_city:	'Carmen'	},
	{lat:	10.5845	, long:	123.9945	, link_barangay:	'Ipil'	, link_city:	'Carmen'	},
	{lat:	10.5904	, long:	123.9537	, link_barangay:	'Lanipga'	, link_city:	'Carmen'	},
	{lat:	10.6020	, long:	123.9407	, link_barangay:	'Liboron'	, link_city:	'Carmen'	},
	{lat:	10.6160	, long:	123.9797	, link_barangay:	'Lower Natimao-An'	, link_city:	'Carmen'	},
	{lat:	10.5947	, long:	124.0274	, link_barangay:	'Luyang'	, link_city:	'Carmen'	},
	{lat:	10.5827	, long:	124.0175	, link_barangay:	'Poblacion'	, link_city:	'Carmen'	},
	{lat:	10.6079	, long:	124.0190	, link_barangay:	'Puente'	, link_city:	'Carmen'	},
	{lat:	10.5999	, long:	123.9648	, link_barangay:	'Sac-On'	, link_city:	'Carmen'	},
	{lat:	10.5710	, long:	123.9998	, link_barangay:	'Triumfo'	, link_city:	'Carmen'	},
	{lat:	10.6190	, long:	123.9584	, link_barangay:	'Upper Natimao-An'	, link_city:	'Carmen'	},
	{lat:	10.6986	, long:	123.9636	, link_barangay:	'Agsuwao'	, link_city:	'Catmon'	},
	{lat:	10.6489	, long:	123.9493	, link_barangay:	'Amancion'	, link_city:	'Catmon'	},
	{lat:	10.6841	, long:	123.9489	, link_barangay:	'Anapog'	, link_city:	'Catmon'	},
	{lat:	10.6909	, long:	123.9921	, link_barangay:	'Bactas'	, link_city:	'Catmon'	},
	{lat:	10.6368	, long:	123.9819	, link_barangay:	'Basak'	, link_city:	'Catmon'	},
	{lat:	10.6241	, long:	124.0165	, link_barangay:	'Binongkalan'	, link_city:	'Catmon'	},
	{lat:	10.6676	, long:	123.9522	, link_barangay:	'Bongyas'	, link_city:	'Catmon'	},
	{lat:	10.6635	, long:	123.9856	, link_barangay:	'Cabungaan'	, link_city:	'Catmon'	},
	{lat:	10.6677	, long:	123.9677	, link_barangay:	'Cambangkaya'	, link_city:	'Catmon'	},
	{lat:	10.7108	, long:	123.9957	, link_barangay:	'Can-Ibuang'	, link_city:	'Catmon'	},
	{lat:	10.6803	, long:	124.0066	, link_barangay:	'Catmondaan'	, link_city:	'Catmon'	},
	{lat:	10.7249	, long:	124.0118	, link_barangay:	'Corazon'	, link_city:	'Catmon'	},
	{lat:	10.7265	, long:	124.0018	, link_barangay:	'Duyan'	, link_city:	'Catmon'	},
	{lat:	10.7183	, long:	124.0137	, link_barangay:	'Flores'	, link_city:	'Catmon'	},
	{lat:	10.6935	, long:	123.9784	, link_barangay:	'Ginabucan'	, link_city:	'Catmon'	},
	{lat:	10.7081	, long:	124.0069	, link_barangay:	'Macaas'	, link_city:	'Catmon'	},
	{lat:	10.6519	, long:	124.0106	, link_barangay:	'Panalipan'	, link_city:	'Catmon'	},
	{lat:	10.7288	, long:	124.0084	, link_barangay:	'San Jose Poblacion'	, link_city:	'Catmon'	},
	{lat:	10.7220	, long:	123.9831	, link_barangay:	'Tabili'	, link_city:	'Catmon'	},
	{lat:	10.7071	, long:	123.9851	, link_barangay:	'Tinabyonan'	, link_city:	'Catmon'	},
	{lat:	10.4429	, long:	123.8691	, link_barangay:	'Adlaon'	, link_city:	'Cebu City'	},
	{lat:	10.4427	, long:	123.9118	, link_barangay:	'Agsungot'	, link_city:	'Cebu City'	},
	{lat:	10.3362	, long:	123.9110	, link_barangay:	'Apas'	, link_city:	'Cebu City'	},
	{lat:	10.3801	, long:	123.8610	, link_barangay:	'Babag'	, link_city:	'Cebu City'	},
	{lat:	10.3818	, long:	123.9187	, link_barangay:	'Bacayan'	, link_city:	'Cebu City'	},
	{lat:	10.3456	, long:	123.9112	, link_barangay:	'Banilad'	, link_city:	'Cebu City'	},
	{lat:	10.2800	, long:	123.8745	, link_barangay:	'Basak Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.2845	, long:	123.8772	, link_barangay:	'Basak San Nicolas'	, link_city:	'Cebu City'	},
	{lat:	10.4200	, long:	123.9126	, link_barangay:	'Binaliw'	, link_city:	'Cebu City'	},
	{lat:	10.3681	, long:	123.8292	, link_barangay:	'Bonbon'	, link_city:	'Cebu City'	},
	{lat:	10.3743	, long:	123.8934	, link_barangay:	'Budla-An'	, link_city:	'Cebu City'	},
	{lat:	10.3215	, long:	123.8533	, link_barangay:	'Buhisan'	, link_city:	'Cebu City'	},
	{lat:	10.2844	, long:	123.8455	, link_barangay:	'Bulacao'	, link_city:	'Cebu City'	},
	{lat:	10.3386	, long:	123.7951	, link_barangay:	'Buot-Taup Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.3595	, long:	123.8884	, link_barangay:	'Busay'	, link_city:	'Cebu City'	},
	{lat:	10.2985	, long:	123.8892	, link_barangay:	'Calamba'	, link_city:	'Cebu City'	},
	{lat:	10.4648	, long:	123.8974	, link_barangay:	'Cambinocot'	, link_city:	'Cebu City'	},
	{lat:	10.3139	, long:	123.9006	, link_barangay:	'Camputhaw'	, link_city:	'Cebu City'	},
	{lat:	10.3111	, long:	123.8958	, link_barangay:	'Capitol Site'	, link_city:	'Cebu City'	},
	{lat:	10.3054	, long:	123.9128	, link_barangay:	'Carreta'	, link_city:	'Cebu City'	},
	{lat:	10.2714	, long:	123.8715	, link_barangay:	'Cogon Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.3046	, long:	123.9014	, link_barangay:	'Cogon Ramos'	, link_city:	'Cebu City'	},
	{lat:	10.2988	, long:	123.9056	, link_barangay:	'Day-As'	, link_city:	'Cebu City'	},
	{lat:	10.2904	, long:	123.8852	, link_barangay:	'Duljo'	, link_city:	'Cebu City'	},
	{lat:	10.2914	, long:	123.8990	, link_barangay:	'Ermita'	, link_city:	'Cebu City'	},
	{lat:	10.3181	, long:	123.8785	, link_barangay:	'Guadalupe'	, link_city:	'Cebu City'	},
	{lat:	10.4341	, long:	123.8879	, link_barangay:	'Guba'	, link_city:	'Cebu City'	},
	{lat:	10.3114	, long:	123.9068	, link_barangay:	'Hippodromo'	, link_city:	'Cebu City'	},
	{lat:	10.2667	, long:	123.8669	, link_barangay:	'Inayawan'	, link_city:	'Cebu City'	},
	{lat:	10.2939	, long:	123.9014	, link_barangay:	'Kalubihan'	, link_city:	'Cebu City'	},
	{lat:	10.3501	, long:	123.8744	, link_barangay:	'Kalunasan'	, link_city:	'Cebu City'	},
	{lat:	10.2955	, long:	123.9023	, link_barangay:	'Kamagayan'	, link_city:	'Cebu City'	},
	{lat:	10.3281	, long:	123.9155	, link_barangay:	'Kasambagan'	, link_city:	'Cebu City'	},
	{lat:	10.2830	, long:	123.8637	, link_barangay:	'Kinasang-An Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.2960	, long:	123.8848	, link_barangay:	'Labangon'	, link_city:	'Cebu City'	},
	{lat:	10.3321	, long:	123.9006	, link_barangay:	'Lahug'	, link_city:	'Cebu City'	},
	{lat:	10.3024	, long:	123.9095	, link_barangay:	'Lorega'	, link_city:	'Cebu City'	},
	{lat:	10.4769	, long:	123.8853	, link_barangay:	'Lusaran'	, link_city:	'Cebu City'	},
	{lat:	10.3181	, long:	123.9094	, link_barangay:	'Luz'	, link_city:	'Cebu City'	},
	{lat:	10.4576	, long:	123.9158	, link_barangay:	'Mabini'	, link_city:	'Cebu City'	},
	{lat:	10.3148	, long:	123.9180	, link_barangay:	'Mabolo'	, link_city:	'Cebu City'	},
	{lat:	10.3908	, long:	123.8750	, link_barangay:	'Malubog'	, link_city:	'Cebu City'	},
	{lat:	10.2847	, long:	123.8834	, link_barangay:	'Mambaling'	, link_city:	'Cebu City'	},
	{lat:	10.2941	, long:	123.8961	, link_barangay:	'Pahina Central'	, link_city:	'Cebu City'	},
	{lat:	10.2930	, long:	123.8934	, link_barangay:	'Pahina San Nicolas'	, link_city:	'Cebu City'	},
	{lat:	10.3425	, long:	123.8349	, link_barangay:	'Pamutan'	, link_city:	'Cebu City'	},
	{lat:	10.2891	, long:	123.8511	, link_barangay:	'Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.2956	, long:	123.9064	, link_barangay:	'Pari-An'	, link_city:	'Cebu City'	},
	{lat:	10.4693	, long:	123.9180	, link_barangay:	'Paril'	, link_city:	'Cebu City'	},
	{lat:	10.2906	, long:	123.8949	, link_barangay:	'Pasil'	, link_city:	'Cebu City'	},
	{lat:	10.3935	, long:	123.9213	, link_barangay:	'Pit-Os'	, link_city:	'Cebu City'	},
	{lat:	10.3969	, long:	123.9087	, link_barangay:	'Pulangbato'	, link_city:	'Cebu City'	},
	{lat:	10.3946	, long:	123.8371	, link_barangay:	'Pung-Ol-Sibugay'	, link_city:	'Cebu City'	},
	{lat:	10.2921	, long:	123.8681	, link_barangay:	'Punta Princesa'	, link_city:	'Cebu City'	},
	{lat:	10.2935	, long:	123.8601	, link_barangay:	'Quiot Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.3021	, long:	123.8943	, link_barangay:	'Sambag I'	, link_city:	'Cebu City'	},
	{lat:	10.2975	, long:	123.8949	, link_barangay:	'Sambag II'	, link_city:	'Cebu City'	},
	{lat:	10.2980	, long:	123.9011	, link_barangay:	'San Antonio'	, link_city:	'Cebu City'	},
	{lat:	10.3787	, long:	123.9074	, link_barangay:	'San Jose'	, link_city:	'Cebu City'	},
	{lat:	10.2927	, long:	123.8898	, link_barangay:	'San Nicolas Central'	, link_city:	'Cebu City'	},
	{lat:	10.2930	, long:	123.9075	, link_barangay:	'San Roque'	, link_city:	'Cebu City'	},
	{lat:	10.3026	, long:	123.8992	, link_barangay:	'Santa Cruz'	, link_city:	'Cebu City'	},
	{lat:	10.3495	, long:	123.8556	, link_barangay:	'Sapangdaku'	, link_city:	'Cebu City'	},
	{lat:	10.2900	, long:	123.8894	, link_barangay:	'Sawang Calero'	, link_city:	'Cebu City'	},
	{lat:	10.3623	, long:	123.7942	, link_barangay:	'Sinsin'	, link_city:	'Cebu City'	},
	{lat:	10.4094	, long:	123.8838	, link_barangay:	'Sirao'	, link_city:	'Cebu City'	},
	{lat:	10.2925	, long:	123.9042	, link_barangay:	'Sr Santo Niño'	, link_city:	'Cebu City'	},
	{lat:	10.2903	, long:	123.8926	, link_barangay:	'Suba Poblacion'	, link_city:	'Cebu City'	},
	{lat:	10.3847	, long:	123.7899	, link_barangay:	'Sudlon I'	, link_city:	'Cebu City'	},
	{lat:	10.4053	, long:	123.8033	, link_barangay:	'Sudlon II'	, link_city:	'Cebu City'	},
	{lat:	10.2986	, long:	123.9089	, link_barangay:	'T. Padilla'	, link_city:	'Cebu City'	},
	{lat:	10.4265	, long:	123.8236	, link_barangay:	'Tabunan'	, link_city:	'Cebu City'	},
	{lat:	10.4526	, long:	123.8509	, link_barangay:	'Tagbao'	, link_city:	'Cebu City'	},
	{lat:	10.3624	, long:	123.9128	, link_barangay:	'Talamban'	, link_city:	'Cebu City'	},
	{lat:	10.4212	, long:	123.8534	, link_barangay:	'Taptap'	, link_city:	'Cebu City'	},
	{lat:	10.3015	, long:	123.9130	, link_barangay:	'Tejero'	, link_city:	'Cebu City'	},
	{lat:	10.2959	, long:	123.9096	, link_barangay:	'Tinago'	, link_city:	'Cebu City'	},
	{lat:	10.3024	, long:	123.8699	, link_barangay:	'Tisa'	, link_city:	'Cebu City'	},
	{lat:	10.3139	, long:	123.8339	, link_barangay:	'To-Ong Pardo'	, link_city:	'Cebu City'	},
	{lat:	10.3047	, long:	123.9048	, link_barangay:	'Zapatera'	, link_city:	'Cebu City'	},
	{lat:	10.4799	, long:	123.9848	, link_barangay:	'Bagalnga'	, link_city:	'Compostela'	},
	{lat:	10.4868	, long:	123.9702	, link_barangay:	'Basak'	, link_city:	'Compostela'	},
	{lat:	10.4784	, long:	123.9987	, link_barangay:	'Buluang'	, link_city:	'Compostela'	},
	{lat:	10.4568	, long:	123.9694	, link_barangay:	'Cabadiangan'	, link_city:	'Compostela'	},
	{lat:	10.4615	, long:	123.9802	, link_barangay:	'Cambayog'	, link_city:	'Compostela'	},
	{lat:	10.4627	, long:	124.0140	, link_barangay:	'Canamucan'	, link_city:	'Compostela'	},
	{lat:	10.4587	, long:	124.0001	, link_barangay:	'Cogon'	, link_city:	'Compostela'	},
	{lat:	10.4963	, long:	123.9372	, link_barangay:	'Dapdap'	, link_city:	'Compostela'	},
	{lat:	10.4395	, long:	124.0063	, link_barangay:	'Estaca'	, link_city:	'Compostela'	},
	{lat:	10.4934	, long:	123.9551	, link_barangay:	'Lupa'	, link_city:	'Compostela'	},
	{lat:	10.4708	, long:	124.0135	, link_barangay:	'Magay'	, link_city:	'Compostela'	},
	{lat:	10.4784	, long:	123.9384	, link_barangay:	'Mulao'	, link_city:	'Compostela'	},
	{lat:	10.4719	, long:	123.9652	, link_barangay:	'Panangban'	, link_city:	'Compostela'	},
	{lat:	10.4508	, long:	124.0092	, link_barangay:	'Poblacion'	, link_city:	'Compostela'	},
	{lat:	10.4933	, long:	123.9128	, link_barangay:	'Tag-Ube'	, link_city:	'Compostela'	},
	{lat:	10.4427	, long:	123.9947	, link_barangay:	'Tamiao'	, link_city:	'Compostela'	},
	{lat:	10.4699	, long:	123.9935	, link_barangay:	'Tubigan'	, link_city:	'Compostela'	},
	{lat:	10.4017	, long:	123.9311	, link_barangay:	'Cabangahan'	, link_city:	'Consolacion'	},
	{lat:	10.3785	, long:	123.9646	, link_barangay:	'Cansaga'	, link_city:	'Consolacion'	},
	{lat:	10.3802	, long:	123.9460	, link_barangay:	'Casili'	, link_city:	'Consolacion'	},
	{lat:	10.3997	, long:	123.9509	, link_barangay:	'Danglag'	, link_city:	'Consolacion'	},
	{lat:	10.4069	, long:	123.9473	, link_barangay:	'Garing'	, link_city:	'Consolacion'	},
	{lat:	10.3766	, long:	123.9754	, link_barangay:	'Jugan'	, link_city:	'Consolacion'	},
	{lat:	10.3897	, long:	123.9636	, link_barangay:	'Lamac'	, link_city:	'Consolacion'	},
	{lat:	10.4379	, long:	123.9296	, link_barangay:	'Lanipga'	, link_city:	'Consolacion'	},
	{lat:	10.3692	, long:	123.9669	, link_barangay:	'Nangka'	, link_city:	'Consolacion'	},
	{lat:	10.4263	, long:	123.9327	, link_barangay:	'Panas'	, link_city:	'Consolacion'	},
	{lat:	10.4137	, long:	123.9293	, link_barangay:	'Panoypoy'	, link_city:	'Consolacion'	},
	{lat:	10.3851	, long:	123.9704	, link_barangay:	'Pitogo'	, link_city:	'Consolacion'	},
	{lat:	10.3758	, long:	123.9573	, link_barangay:	'Poblacion Occidental'	, link_city:	'Consolacion'	},
	{lat:	10.3712	, long:	123.9566	, link_barangay:	'Poblacion Oriental'	, link_city:	'Consolacion'	},
	{lat:	10.4156	, long:	123.9399	, link_barangay:	'Polog'	, link_city:	'Consolacion'	},
	{lat:	10.3823	, long:	123.9562	, link_barangay:	'Pulpogan'	, link_city:	'Consolacion'	},
	{lat:	10.3907	, long:	123.9361	, link_barangay:	'Sacsac'	, link_city:	'Consolacion'	},
	{lat:	10.3606	, long:	123.9848	, link_barangay:	'Tayud'	, link_city:	'Consolacion'	},
	{lat:	10.3989	, long:	123.9609	, link_barangay:	'Tilhaong'	, link_city:	'Consolacion'	},
	{lat:	10.3921	, long:	123.9487	, link_barangay:	'Tolotolo'	, link_city:	'Consolacion'	},
	{lat:	10.3653	, long:	123.9616	, link_barangay:	'Tugbongan'	, link_city:	'Consolacion'	},
	{lat:	10.2593	, long:	123.9644	, link_barangay:	'Alegria'	, link_city:	'Cordova'	},
	{lat:	10.2582	, long:	123.9411	, link_barangay:	'Bangbang'	, link_city:	'Cordova'	},
	{lat:	10.2540	, long:	123.9539	, link_barangay:	'Buagsong'	, link_city:	'Cordova'	},
	{lat:	10.2457	, long:	123.9465	, link_barangay:	'Catarman'	, link_city:	'Cordova'	},
	{lat:	10.2661	, long:	123.9566	, link_barangay:	'Cogon'	, link_city:	'Cordova'	},
	{lat:	10.2626	, long:	123.9496	, link_barangay:	'Dapitan'	, link_city:	'Cordova'	},
	{lat:	10.2519	, long:	123.9390	, link_barangay:	'Day-As'	, link_city:	'Cordova'	},
	{lat:	10.2625	, long:	123.9610	, link_barangay:	'Gabi'	, link_city:	'Cordova'	},
	{lat:	10.2063	, long:	123.9892	, link_barangay:	'Gilutongan'	, link_city:	'Cordova'	},
	{lat:	10.2721	, long:	123.9526	, link_barangay:	'Ibabao'	, link_city:	'Cordova'	},
	{lat:	10.2663	, long:	123.9434	, link_barangay:	'Pilipog'	, link_city:	'Cordova'	},
	{lat:	10.2528	, long:	123.9474	, link_barangay:	'Poblacion'	, link_city:	'Cordova'	},
	{lat:	10.2614	, long:	123.9441	, link_barangay:	'San Miguel'	, link_city:	'Cordova'	},
	{lat:	11.2640	, long:	124.0033	, link_barangay:	'Aguho'	, link_city:	'Daanbantayan'	},
	{lat:	11.2020	, long:	124.0255	, link_barangay:	'Bagay'	, link_city:	'Daanbantayan'	},
	{lat:	11.2023	, long:	123.9723	, link_barangay:	'Bakhawan'	, link_city:	'Daanbantayan'	},
	{lat:	11.1460	, long:	124.0228	, link_barangay:	'Bateria'	, link_city:	'Daanbantayan'	},
	{lat:	11.2306	, long:	124.0015	, link_barangay:	'Bitoon'	, link_city:	'Daanbantayan'	},
	{lat:	11.1770	, long:	124.0214	, link_barangay:	'Calape'	, link_city:	'Daanbantayan'	},
	{lat:	11.5142	, long:	124.0980	, link_barangay:	'Carnaza'	, link_city:	'Daanbantayan'	},
	{lat:	11.1992	, long:	123.9957	, link_barangay:	'Dalingding'	, link_city:	'Daanbantayan'	},
	{lat:	11.2563	, long:	124.0276	, link_barangay:	'Lanao'	, link_city:	'Daanbantayan'	},
	{lat:	11.3366	, long:	124.1156	, link_barangay:	'Logon'	, link_city:	'Daanbantayan'	},
	{lat:	11.1617	, long:	124.0221	, link_barangay:	'Malbago'	, link_city:	'Daanbantayan'	},
	{lat:	11.2181	, long:	124.0144	, link_barangay:	'Malingin'	, link_city:	'Daanbantayan'	},
	{lat:	11.2714	, long:	124.0537	, link_barangay:	'Maya'	, link_city:	'Daanbantayan'	},
	{lat:	11.2395	, long:	124.0128	, link_barangay:	'Pajo'	, link_city:	'Daanbantayan'	},
	{lat:	11.2137	, long:	123.9847	, link_barangay:	'Paypay'	, link_city:	'Daanbantayan'	},
	{lat:	11.2508	, long:	124.0013	, link_barangay:	'Poblacion'	, link_city:	'Daanbantayan'	},
	{lat:	11.2458	, long:	124.0553	, link_barangay:	'Talisay'	, link_city:	'Daanbantayan'	},
	{lat:	11.2707	, long:	124.0307	, link_barangay:	'Tapilon'	, link_city:	'Daanbantayan'	},
	{lat:	11.1797	, long:	124.0013	, link_barangay:	'Tinubdan'	, link_city:	'Daanbantayan'	},
	{lat:	11.2345	, long:	124.0324	, link_barangay:	'Tominjao'	, link_city:	'Daanbantayan'	},
	{lat:	9.8328	, long:	123.4715	, link_barangay:	'Ablayan'	, link_city:	'Dalaguete'	},
	{lat:	9.8340	, long:	123.5025	, link_barangay:	'Babayongan'	, link_city:	'Dalaguete'	},
	{lat:	9.7569	, long:	123.5165	, link_barangay:	'Balud'	, link_city:	'Dalaguete'	},
	{lat:	9.7983	, long:	123.5263	, link_barangay:	'Banhigan'	, link_city:	'Dalaguete'	},
	{lat:	9.8497	, long:	123.4976	, link_barangay:	'Bulak'	, link_city:	'Dalaguete'	},
	{lat:	9.8018	, long:	123.4466	, link_barangay:	'Caleriohan'	, link_city:	'Dalaguete'	},
	{lat:	9.7808	, long:	123.4794	, link_barangay:	'Caliongan'	, link_city:	'Dalaguete'	},
	{lat:	9.8160	, long:	123.5489	, link_barangay:	'Casay'	, link_city:	'Dalaguete'	},
	{lat:	9.7686	, long:	123.4477	, link_barangay:	'Catolohan'	, link_city:	'Dalaguete'	},
	{lat:	9.8080	, long:	123.5299	, link_barangay:	'Cawayan'	, link_city:	'Dalaguete'	},
	{lat:	9.7518	, long:	123.5078	, link_barangay:	'Consolacion'	, link_city:	'Dalaguete'	},
	{lat:	9.7898	, long:	123.5243	, link_barangay:	'Coro'	, link_city:	'Dalaguete'	},
	{lat:	9.7861	, long:	123.4394	, link_barangay:	'Dugyan'	, link_city:	'Dalaguete'	},
	{lat:	9.8182	, long:	123.4871	, link_barangay:	'Dumalan'	, link_city:	'Dalaguete'	},
	{lat:	9.7672	, long:	123.4980	, link_barangay:	'Jolomaynon'	, link_city:	'Dalaguete'	},
	{lat:	9.8012	, long:	123.5031	, link_barangay:	'Lanao'	, link_city:	'Dalaguete'	},
	{lat:	9.7933	, long:	123.4597	, link_barangay:	'Langkas'	, link_city:	'Dalaguete'	},
	{lat:	9.8159	, long:	123.5378	, link_barangay:	'Lumbang'	, link_city:	'Dalaguete'	},
	{lat:	9.8193	, long:	123.5220	, link_barangay:	'Malones'	, link_city:	'Dalaguete'	},
	{lat:	9.8452	, long:	123.4764	, link_barangay:	'Maloray'	, link_city:	'Dalaguete'	},
	{lat:	9.7842	, long:	123.5194	, link_barangay:	'Mananggal'	, link_city:	'Dalaguete'	},
	{lat:	9.8606	, long:	123.4836	, link_barangay:	'Manlapay'	, link_city:	'Dalaguete'	},
	{lat:	9.8150	, long:	123.4661	, link_barangay:	'Mantalongon'	, link_city:	'Dalaguete'	},
	{lat:	9.7792	, long:	123.4576	, link_barangay:	'Nalhub'	, link_city:	'Dalaguete'	},
	{lat:	9.8024	, long:	123.4807	, link_barangay:	'Obo'	, link_city:	'Dalaguete'	},
	{lat:	9.7540	, long:	123.4822	, link_barangay:	'Obong'	, link_city:	'Dalaguete'	},
	{lat:	9.8158	, long:	123.5105	, link_barangay:	'Panas'	, link_city:	'Dalaguete'	},
	{lat:	9.7617	, long:	123.5278	, link_barangay:	'Poblacion'	, link_city:	'Dalaguete'	},
	{lat:	9.7812	, long:	123.5035	, link_barangay:	'Sacsac'	, link_city:	'Dalaguete'	},
	{lat:	9.8393	, long:	123.4855	, link_barangay:	'Salug'	, link_city:	'Dalaguete'	},
	{lat:	9.8042	, long:	123.4581	, link_barangay:	'Tabon'	, link_city:	'Dalaguete'	},
	{lat:	9.7736	, long:	123.5229	, link_barangay:	'Tapun'	, link_city:	'Dalaguete'	},
	{lat:	9.8289	, long:	123.5290	, link_barangay:	'Tuba'	, link_city:	'Dalaguete'	},
	{lat:	10.5109	, long:	123.9548	, link_barangay:	'Baliang'	, link_city:	'Danao City'	},
	{lat:	10.5834	, long:	123.9342	, link_barangay:	'Bayabas'	, link_city:	'Danao City'	},
	{lat:	10.5437	, long:	123.9912	, link_barangay:	'Binaliw'	, link_city:	'Danao City'	},
	{lat:	10.5176	, long:	123.9815	, link_barangay:	'Cabungahan'	, link_city:	'Danao City'	},
	{lat:	10.5519	, long:	123.9995	, link_barangay:	'Cagat-Lamac'	, link_city:	'Danao City'	},
	{lat:	10.5574	, long:	123.9865	, link_barangay:	'Cahumayan'	, link_city:	'Danao City'	},
	{lat:	10.5411	, long:	124.0061	, link_barangay:	'Cambanay'	, link_city:	'Danao City'	},
	{lat:	10.5314	, long:	123.9060	, link_barangay:	'Cambubho'	, link_city:	'Danao City'	},
	{lat:	10.5254	, long:	124.0092	, link_barangay:	'Cogon-Cruz'	, link_city:	'Danao City'	},
	{lat:	10.5656	, long:	123.9248	, link_barangay:	'Danasan'	, link_city:	'Danao City'	},
	{lat:	10.5577	, long:	123.9738	, link_barangay:	'Dungga'	, link_city:	'Danao City'	},
	{lat:	10.5538	, long:	124.0121	, link_barangay:	'Dunggoan'	, link_city:	'Danao City'	},
	{lat:	10.5253	, long:	123.9951	, link_barangay:	'Guinacot'	, link_city:	'Danao City'	},
	{lat:	10.5456	, long:	124.0259	, link_barangay:	'Guinsay'	, link_city:	'Danao City'	},
	{lat:	10.5286	, long:	123.9595	, link_barangay:	'Ibo'	, link_city:	'Danao City'	},
	{lat:	10.5211	, long:	123.9186	, link_barangay:	'Langosig'	, link_city:	'Danao City'	},
	{lat:	10.5567	, long:	123.9417	, link_barangay:	'Lawaan'	, link_city:	'Danao City'	},
	{lat:	10.5320	, long:	123.9463	, link_barangay:	'Licos'	, link_city:	'Danao City'	},
	{lat:	10.5130	, long:	124.0235	, link_barangay:	'Looc'	, link_city:	'Danao City'	},
	{lat:	10.5742	, long:	123.9516	, link_barangay:	'Magtagobtob'	, link_city:	'Danao City'	},
	{lat:	10.5337	, long:	124.0059	, link_barangay:	'Malapoc'	, link_city:	'Danao City'	},
	{lat:	10.5031	, long:	123.9797	, link_barangay:	'Manlayag'	, link_city:	'Danao City'	},
	{lat:	10.5482	, long:	123.9533	, link_barangay:	'Mantija'	, link_city:	'Danao City'	},
	{lat:	10.5477	, long:	123.9686	, link_barangay:	'Masaba'	, link_city:	'Danao City'	},
	{lat:	10.4813	, long:	124.0187	, link_barangay:	'Maslog'	, link_city:	'Danao City'	},
	{lat:	10.5407	, long:	123.9550	, link_barangay:	'Nangka'	, link_city:	'Danao City'	},
	{lat:	10.5586	, long:	123.9577	, link_barangay:	'Oguis'	, link_city:	'Danao City'	},
	{lat:	10.5744	, long:	123.9724	, link_barangay:	'Pili'	, link_city:	'Danao City'	},
	{lat:	10.5220	, long:	124.0230	, link_barangay:	'Poblacion'	, link_city:	'Danao City'	},
	{lat:	10.5350	, long:	123.9873	, link_barangay:	'Quisol'	, link_city:	'Danao City'	},
	{lat:	10.4986	, long:	124.0176	, link_barangay:	'Sabang'	, link_city:	'Danao City'	},
	{lat:	10.5458	, long:	123.9314	, link_barangay:	'Sacsac'	, link_city:	'Danao City'	},
	{lat:	10.5627	, long:	123.9991	, link_barangay:	'Sandayong Norte'	, link_city:	'Danao City'	},
	{lat:	10.4960	, long:	123.9945	, link_barangay:	'Sandayong Sur'	, link_city:	'Danao City'	},
	{lat:	10.5336	, long:	123.9733	, link_barangay:	'Santa Rosa'	, link_city:	'Danao City'	},
	{lat:	10.5661	, long:	123.9705	, link_barangay:	'Santican'	, link_city:	'Danao City'	},
	{lat:	10.5459	, long:	123.9435	, link_barangay:	'Sibacan'	, link_city:	'Danao City'	},
	{lat:	10.5269	, long:	124.0285	, link_barangay:	'Suba'	, link_city:	'Danao City'	},
	{lat:	10.5317	, long:	124.0188	, link_barangay:	'Taboc'	, link_city:	'Danao City'	},
	{lat:	10.5329	, long:	124.0274	, link_barangay:	'Taytay'	, link_city:	'Danao City'	},
	{lat:	10.5161	, long:	123.9351	, link_barangay:	'Togonon'	, link_city:	'Danao City'	},
	{lat:	10.5138	, long:	124.0059	, link_barangay:	'Tuburan Sur'	, link_city:	'Danao City'	},
	{lat:	10.6733	, long:	124.3501	, link_barangay:	'Danao Lake'	, link_city:	'Danao Lake'	},
	{lat:	10.0532	, long:	123.4698	, link_barangay:	'Balaygtiki'	, link_city:	'Dumanjug'	},
	{lat:	10.0817	, long:	123.4596	, link_barangay:	'Bitoon'	, link_city:	'Dumanjug'	},
	{lat:	10.0477	, long:	123.5079	, link_barangay:	'Bulak'	, link_city:	'Dumanjug'	},
	{lat:	10.0153	, long:	123.4653	, link_barangay:	'Bullogan'	, link_city:	'Dumanjug'	},
	{lat:	10.0516	, long:	123.4225	, link_barangay:	'Calaboon'	, link_city:	'Dumanjug'	},
	{lat:	10.0379	, long:	123.4203	, link_barangay:	'Camboang'	, link_city:	'Dumanjug'	},
	{lat:	10.0275	, long:	123.4466	, link_barangay:	'Candabong'	, link_city:	'Dumanjug'	},
	{lat:	10.0438	, long:	123.4429	, link_barangay:	'Cogon'	, link_city:	'Dumanjug'	},
	{lat:	10.0206	, long:	123.4548	, link_barangay:	'Cotcoton'	, link_city:	'Dumanjug'	},
	{lat:	10.0622	, long:	123.4947	, link_barangay:	'Doldol'	, link_city:	'Dumanjug'	},
	{lat:	10.0558	, long:	123.4383	, link_barangay:	'Ilaya'	, link_city:	'Dumanjug'	},
	{lat:	10.0088	, long:	123.4793	, link_barangay:	'Kabalaasnan'	, link_city:	'Dumanjug'	},
	{lat:	10.0148	, long:	123.4906	, link_barangay:	'Kabatbatan'	, link_city:	'Dumanjug'	},
	{lat:	10.0023	, long:	123.5009	, link_barangay:	'Kambanog'	, link_city:	'Dumanjug'	},
	{lat:	10.0271	, long:	123.4637	, link_barangay:	'Kang-Actol'	, link_city:	'Dumanjug'	},
	{lat:	10.0307	, long:	123.5014	, link_barangay:	'Kanghalo'	, link_city:	'Dumanjug'	},
	{lat:	10.0699	, long:	123.4766	, link_barangay:	'Kanghumaod'	, link_city:	'Dumanjug'	},
	{lat:	10.0213	, long:	123.4759	, link_barangay:	'Kanguha'	, link_city:	'Dumanjug'	},
	{lat:	10.0371	, long:	123.4587	, link_barangay:	'Kantangkas'	, link_city:	'Dumanjug'	},
	{lat:	10.0861	, long:	123.4712	, link_barangay:	'Kanyuko'	, link_city:	'Dumanjug'	},
	{lat:	10.0372	, long:	123.4695	, link_barangay:	'Kolabtingon'	, link_city:	'Dumanjug'	},
	{lat:	10.0291	, long:	123.4921	, link_barangay:	'Lamak'	, link_city:	'Dumanjug'	},
	{lat:	10.0328	, long:	123.4349	, link_barangay:	'Lawaan'	, link_city:	'Dumanjug'	},
	{lat:	10.0478	, long:	123.4301	, link_barangay:	'Liong'	, link_city:	'Dumanjug'	},
	{lat:	10.0467	, long:	123.4931	, link_barangay:	'Manlapay'	, link_city:	'Dumanjug'	},
	{lat:	10.0162	, long:	123.5089	, link_barangay:	'Masa'	, link_city:	'Dumanjug'	},
	{lat:	10.0447	, long:	123.4791	, link_barangay:	'Matalao'	, link_city:	'Dumanjug'	},
	{lat:	10.0455	, long:	123.4566	, link_barangay:	'Paculob'	, link_city:	'Dumanjug'	},
	{lat:	10.0643	, long:	123.4594	, link_barangay:	'Panlaan'	, link_city:	'Dumanjug'	},
	{lat:	10.0339	, long:	123.4848	, link_barangay:	'Pawa'	, link_city:	'Dumanjug'	},
	{lat:	10.0585	, long:	123.4351	, link_barangay:	'Poblacion Central'	, link_city:	'Dumanjug'	},
	{lat:	10.0572	, long:	123.4314	, link_barangay:	'Poblacion Looc'	, link_city:	'Dumanjug'	},
	{lat:	10.0551	, long:	123.4338	, link_barangay:	'Poblacion Sima'	, link_city:	'Dumanjug'	},
	{lat:	10.0741	, long:	123.4517	, link_barangay:	'Tangil'	, link_city:	'Dumanjug'	},
	{lat:	10.0587	, long:	123.4468	, link_barangay:	'Tapon'	, link_city:	'Dumanjug'	},
	{lat:	10.0777	, long:	123.4682	, link_barangay:	'Tubod-Bitoon'	, link_city:	'Dumanjug'	},
	{lat:	10.0347	, long:	123.5198	, link_barangay:	'Tubod-Dugoan'	, link_city:	'Dumanjug'	},
	{lat:	9.6134	, long:	123.3682	, link_barangay:	'Anao'	, link_city:	'Ginatilan'	},
	{lat:	9.5821	, long:	123.3376	, link_barangay:	'Cagsing'	, link_city:	'Ginatilan'	},
	{lat:	9.5649	, long:	123.3251	, link_barangay:	'Calabawan'	, link_city:	'Ginatilan'	},
	{lat:	9.5900	, long:	123.3713	, link_barangay:	'Cambagte'	, link_city:	'Ginatilan'	},
	{lat:	9.6062	, long:	123.3373	, link_barangay:	'Campisong'	, link_city:	'Ginatilan'	},
	{lat:	9.5558	, long:	123.3334	, link_barangay:	'Canorong'	, link_city:	'Ginatilan'	},
	{lat:	9.5888	, long:	123.3233	, link_barangay:	'Guiwanon'	, link_city:	'Ginatilan'	},
	{lat:	9.6156	, long:	123.3263	, link_barangay:	'Looc'	, link_city:	'Ginatilan'	},
	{lat:	9.5790	, long:	123.3517	, link_barangay:	'Malatbo'	, link_city:	'Ginatilan'	},
	{lat:	9.5650	, long:	123.3547	, link_barangay:	'Mangaco'	, link_city:	'Ginatilan'	},
	{lat:	9.5576	, long:	123.3145	, link_barangay:	'Palanas'	, link_city:	'Ginatilan'	},
	{lat:	9.5700	, long:	123.3161	, link_barangay:	'Poblacion'	, link_city:	'Ginatilan'	},
	{lat:	9.5976	, long:	123.3536	, link_barangay:	'Salamanca'	, link_city:	'Ginatilan'	},
	{lat:	9.5647	, long:	123.3157	, link_barangay:	'San Roque'	, link_city:	'Ginatilan'	},
	{lat:	10.2838	, long:	123.9837	, link_barangay:	'Agus'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2856	, long:	123.9529	, link_barangay:	'Babag'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3048	, long:	123.9746	, link_barangay:	'Bankal'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2842	, long:	124.0665	, link_barangay:	'Baring'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2924	, long:	123.9641	, link_barangay:	'Basak'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3151	, long:	123.9909	, link_barangay:	'Buaya'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2685	, long:	123.9274	, link_barangay:	'Calawisan'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2843	, long:	123.9382	, link_barangay:	'Canjulao'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3468	, long:	124.0823	, link_barangay:	'Caubian'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2735	, long:	124.0682	, link_barangay:	'Caw-Oy'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2028	, long:	124.0196	, link_barangay:	'Cawhagan'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2954	, long:	123.9514	, link_barangay:	'Gun-Ob'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3252	, long:	123.9819	, link_barangay:	'Ibo'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2991	, long:	123.9415	, link_barangay:	'Looc'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3054	, long:	124.0075	, link_barangay:	'Mactan'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2907	, long:	123.9979	, link_barangay:	'Maribago'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2760	, long:	123.9780	, link_barangay:	'Marigondon'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2986	, long:	123.9856	, link_barangay:	'Pajac'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3102	, long:	123.9587	, link_barangay:	'Pajo'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2196	, long:	124.0386	, link_barangay:	'Pangan-An'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3085	, long:	123.9494	, link_barangay:	'Poblacion'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3201	, long:	124.0309	, link_barangay:	'Punta Engaño'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.3156	, long:	123.9698	, link_barangay:	'Pusok'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2496	, long:	124.0545	, link_barangay:	'Sabang'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2530	, long:	124.0308	, link_barangay:	'San Vicente'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2521	, long:	124.0387	, link_barangay:	'Santa Rosa'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2672	, long:	123.9741	, link_barangay:	'Subabasbas'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2757	, long:	124.0594	, link_barangay:	'Talima'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2818	, long:	124.0707	, link_barangay:	'Tingo'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.2640	, long:	124.0591	, link_barangay:	'Tungasan'	, link_city:	'Lapu-Lapu City'	},
	{lat:	10.4411	, long:	123.9701	, link_barangay:	'Cabadiangan'	, link_city:	'Liloan'	},
	{lat:	10.3737	, long:	123.9975	, link_barangay:	'Calero'	, link_city:	'Liloan'	},
	{lat:	10.3849	, long:	124.0119	, link_barangay:	'Catarman'	, link_city:	'Liloan'	},
	{lat:	10.4289	, long:	123.9912	, link_barangay:	'Cotcot'	, link_city:	'Liloan'	},
	{lat:	10.4157	, long:	123.9856	, link_barangay:	'Jubay'	, link_city:	'Liloan'	},
	{lat:	10.4285	, long:	123.9607	, link_barangay:	'Lataban'	, link_city:	'Liloan'	},
	{lat:	10.4634	, long:	123.9431	, link_barangay:	'Mulao'	, link_city:	'Liloan'	},
	{lat:	10.3956	, long:	123.9954	, link_barangay:	'Poblacion'	, link_city:	'Liloan'	},
	{lat:	10.4330	, long:	123.9443	, link_barangay:	'San Roque'	, link_city:	'Liloan'	},
	{lat:	10.4049	, long:	123.9708	, link_barangay:	'San Vicente'	, link_city:	'Liloan'	},
	{lat:	10.4201	, long:	123.9529	, link_barangay:	'Santa Cruz'	, link_city:	'Liloan'	},
	{lat:	10.4471	, long:	123.9422	, link_barangay:	'Tabla'	, link_city:	'Liloan'	},
	{lat:	10.3802	, long:	124.0051	, link_barangay:	'Tayud'	, link_city:	'Liloan'	},
	{lat:	10.3882	, long:	123.9838	, link_barangay:	'Yati'	, link_city:	'Liloan'	},
	{lat:	11.2668	, long:	123.7189	, link_barangay:	'Bunakan'	, link_city:	'Madridejos'	},
	{lat:	11.2627	, long:	123.7175	, link_barangay:	'Kangwayan'	, link_city:	'Madridejos'	},
	{lat:	11.2714	, long:	123.7441	, link_barangay:	'Kaongkod'	, link_city:	'Madridejos'	},
	{lat:	11.2499	, long:	123.7132	, link_barangay:	'Kodia'	, link_city:	'Madridejos'	},
	{lat:	11.2746	, long:	123.7217	, link_barangay:	'Maalat'	, link_city:	'Madridejos'	},
	{lat:	11.2817	, long:	123.7441	, link_barangay:	'Malbago'	, link_city:	'Madridejos'	},
	{lat:	11.2913	, long:	123.7385	, link_barangay:	'Mancilang'	, link_city:	'Madridejos'	},
	{lat:	11.2686	, long:	123.7295	, link_barangay:	'Pili'	, link_city:	'Madridejos'	},
	{lat:	11.2975	, long:	123.7328	, link_barangay:	'Poblacion'	, link_city:	'Madridejos'	},
	{lat:	11.2544	, long:	123.7421	, link_barangay:	'San Agustin'	, link_city:	'Madridejos'	},
	{lat:	11.2581	, long:	123.7167	, link_barangay:	'Tabagak'	, link_city:	'Madridejos'	},
	{lat:	11.2962	, long:	123.7277	, link_barangay:	'Talangnan'	, link_city:	'Madridejos'	},
	{lat:	11.2865	, long:	123.7259	, link_barangay:	'Tarong'	, link_city:	'Madridejos'	},
	{lat:	11.2818	, long:	123.7337	, link_barangay:	'Tugas'	, link_city:	'Madridejos'	},
	{lat:	9.6722	, long:	123.3608	, link_barangay:	'Armeña'	, link_city:	'Malabuyoc'	},
	{lat:	9.6742	, long:	123.3361	, link_barangay:	'Barangay I'	, link_city:	'Malabuyoc'	},
	{lat:	9.6587	, long:	123.3323	, link_barangay:	'Barangay II'	, link_city:	'Malabuyoc'	},
	{lat:	9.6492	, long:	123.3900	, link_barangay:	'Cerdeña'	, link_city:	'Malabuyoc'	},
	{lat:	9.6297	, long:	123.3508	, link_barangay:	'Labrador'	, link_city:	'Malabuyoc'	},
	{lat:	9.6902	, long:	123.3717	, link_barangay:	'Lombo'	, link_city:	'Malabuyoc'	},
	{lat:	9.6318	, long:	123.3335	, link_barangay:	'Looc'	, link_city:	'Malabuyoc'	},
	{lat:	9.6309	, long:	123.3785	, link_barangay:	'Mahanlud'	, link_city:	'Malabuyoc'	},
	{lat:	9.6443	, long:	123.3453	, link_barangay:	'Mindanao'	, link_city:	'Malabuyoc'	},
	{lat:	9.6950	, long:	123.3456	, link_barangay:	'Montañeza'	, link_city:	'Malabuyoc'	},
	{lat:	9.6489	, long:	123.3662	, link_barangay:	'Salmeron'	, link_city:	'Malabuyoc'	},
	{lat:	9.6448	, long:	123.3298	, link_barangay:	'Santo Niño'	, link_city:	'Malabuyoc'	},
	{lat:	9.6593	, long:	123.3497	, link_barangay:	'Sorsogon'	, link_city:	'Malabuyoc'	},
	{lat:	9.6776	, long:	123.3976	, link_barangay:	'Tolosa'	, link_city:	'Malabuyoc'	},
	{lat:	10.3319	, long:	123.9513	, link_barangay:	'Alang-Alang'	, link_city:	'Mandaue City'	},
	{lat:	10.3241	, long:	123.9300	, link_barangay:	'Bakilid'	, link_city:	'Mandaue City'	},
	{lat:	10.3393	, long:	123.9236	, link_barangay:	'Banilad'	, link_city:	'Mandaue City'	},
	{lat:	10.3562	, long:	123.9491	, link_barangay:	'Basak'	, link_city:	'Mandaue City'	},
	{lat:	10.3514	, long:	123.9270	, link_barangay:	'Cabancalan'	, link_city:	'Mandaue City'	},
	{lat:	10.3255	, long:	123.9483	, link_barangay:	'Cambaro'	, link_city:	'Mandaue City'	},
	{lat:	10.3635	, long:	123.9296	, link_barangay:	'Canduman'	, link_city:	'Mandaue City'	},
	{lat:	10.3794	, long:	123.9328	, link_barangay:	'Casili'	, link_city:	'Mandaue City'	},
	{lat:	10.3393	, long:	123.9348	, link_barangay:	'Casuntingan'	, link_city:	'Mandaue City'	},
	{lat:	10.3221	, long:	123.9430	, link_barangay:	'Centro'	, link_city:	'Mandaue City'	},
	{lat:	10.3718	, long:	123.9399	, link_barangay:	'Cubacub'	, link_city:	'Mandaue City'	},
	{lat:	10.3217	, long:	123.9386	, link_barangay:	'Guizo'	, link_city:	'Mandaue City'	},
	{lat:	10.3325	, long:	123.9469	, link_barangay:	'Ibabao-Estancia'	, link_city:	'Mandaue City'	},
	{lat:	10.3673	, long:	123.9500	, link_barangay:	'Jagobiao'	, link_city:	'Mandaue City'	},
	{lat:	10.3526	, long:	123.9585	, link_barangay:	'Labogon'	, link_city:	'Mandaue City'	},
	{lat:	10.3206	, long:	123.9452	, link_barangay:	'Looc'	, link_city:	'Mandaue City'	},
	{lat:	10.3368	, long:	123.9414	, link_barangay:	'Maguikay'	, link_city:	'Mandaue City'	},
	{lat:	10.3257	, long:	123.9422	, link_barangay:	'Mantuyong'	, link_city:	'Mandaue City'	},
	{lat:	10.3264	, long:	123.9574	, link_barangay:	'Opao'	, link_city:	'Mandaue City'	},
	{lat:	10.3595	, long:	123.9396	, link_barangay:	'Pagsabungan'	, link_city:	'Mandaue City'	},
	{lat:	10.3421	, long:	123.9615	, link_barangay:	'Pakna-An'	, link_city:	'Mandaue City'	},
	{lat:	10.3142	, long:	123.9298	, link_barangay:	'Subangdaku'	, link_city:	'Mandaue City'	},
	{lat:	10.3457	, long:	123.9458	, link_barangay:	'Tabok'	, link_city:	'Mandaue City'	},
	{lat:	10.3767	, long:	123.9270	, link_barangay:	'Tawason'	, link_city:	'Mandaue City'	},
	{lat:	10.3521	, long:	123.9369	, link_barangay:	'Tingub'	, link_city:	'Mandaue City'	},
	{lat:	10.3194	, long:	123.9348	, link_barangay:	'Tipolo'	, link_city:	'Mandaue City'	},
	{lat:	10.3342	, long:	123.9572	, link_barangay:	'Umapad'	, link_city:	'Mandaue City'	},
	{lat:	11.1599	, long:	123.9568	, link_barangay:	'Antipolo'	, link_city:	'Medellin'	},
	{lat:	11.1117	, long:	123.9903	, link_barangay:	'Canhabagat'	, link_city:	'Medellin'	},
	{lat:	11.1523	, long:	123.9784	, link_barangay:	'Caputatan Norte'	, link_city:	'Medellin'	},
	{lat:	11.1279	, long:	123.9807	, link_barangay:	'Caputatan Sur'	, link_city:	'Medellin'	},
	{lat:	11.1412	, long:	124.0013	, link_barangay:	'Curva'	, link_city:	'Medellin'	},
	{lat:	11.1451	, long:	123.9565	, link_barangay:	'Daanlungsod'	, link_city:	'Medellin'	},
	{lat:	11.1801	, long:	123.9785	, link_barangay:	'Dalingding Sur'	, link_city:	'Medellin'	},
	{lat:	11.0824	, long:	123.9784	, link_barangay:	'Dayhagon'	, link_city:	'Medellin'	},
	{lat:	11.0967	, long:	123.9878	, link_barangay:	'Don Virgilio Gonzales'	, link_city:	'Medellin'	},
	{lat:	11.1847	, long:	123.9214	, link_barangay:	'Gibitngil'	, link_city:	'Medellin'	},
	{lat:	11.1866	, long:	123.9589	, link_barangay:	'Kawit'	, link_city:	'Medellin'	},
	{lat:	11.1013	, long:	123.9704	, link_barangay:	'Lamintak Norte'	, link_city:	'Medellin'	},
	{lat:	11.0929	, long:	123.9674	, link_barangay:	'Lamintak Sur'	, link_city:	'Medellin'	},
	{lat:	11.1105	, long:	123.9678	, link_barangay:	'Luy-A'	, link_city:	'Medellin'	},
	{lat:	11.1841	, long:	123.9708	, link_barangay:	'Maharuhay'	, link_city:	'Medellin'	},
	{lat:	11.1744	, long:	123.9492	, link_barangay:	'Mahawak'	, link_city:	'Medellin'	},
	{lat:	11.1613	, long:	123.9967	, link_barangay:	'Panugnawan'	, link_city:	'Medellin'	},
	{lat:	11.1343	, long:	123.9623	, link_barangay:	'Poblacion'	, link_city:	'Medellin'	},
	{lat:	11.1272	, long:	124.0090	, link_barangay:	'Tindog'	, link_city:	'Medellin'	},
	{lat:	10.2749	, long:	123.7730	, link_barangay:	'Cadulawan'	, link_city:	'Minglanilla'	},
	{lat:	10.2427	, long:	123.8060	, link_barangay:	'Calajo-An'	, link_city:	'Minglanilla'	},
	{lat:	10.3367	, long:	123.7633	, link_barangay:	'Camp 7'	, link_city:	'Minglanilla'	},
	{lat:	10.3017	, long:	123.7584	, link_barangay:	'Camp 8'	, link_city:	'Minglanilla'	},
	{lat:	10.2761	, long:	123.7942	, link_barangay:	'Cuanos'	, link_city:	'Minglanilla'	},
	{lat:	10.2634	, long:	123.7692	, link_barangay:	'Guindaruhan'	, link_city:	'Minglanilla'	},
	{lat:	10.2522	, long:	123.8093	, link_barangay:	'Linao'	, link_city:	'Minglanilla'	},
	{lat:	10.3003	, long:	123.7778	, link_barangay:	'Manduang'	, link_city:	'Minglanilla'	},
	{lat:	10.2561	, long:	123.8060	, link_barangay:	'Pakigne'	, link_city:	'Minglanilla'	},
	{lat:	10.2439	, long:	123.8016	, link_barangay:	'Poblacion Ward I'	, link_city:	'Minglanilla'	},
	{lat:	10.2427	, long:	123.7970	, link_barangay:	'Poblacion Ward II'	, link_city:	'Minglanilla'	},
	{lat:	10.2529	, long:	123.7968	, link_barangay:	'Poblacion Ward III'	, link_city:	'Minglanilla'	},
	{lat:	10.2503	, long:	123.7914	, link_barangay:	'Poblacion Ward IV'	, link_city:	'Minglanilla'	},
	{lat:	10.2626	, long:	123.8034	, link_barangay:	'Tubod'	, link_city:	'Minglanilla'	},
	{lat:	10.2393	, long:	123.8002	, link_barangay:	'Tulay'	, link_city:	'Minglanilla'	},
	{lat:	10.2458	, long:	123.7833	, link_barangay:	'Tunghaan'	, link_city:	'Minglanilla'	},
	{lat:	10.2434	, long:	123.8115	, link_barangay:	'Tungkil'	, link_city:	'Minglanilla'	},
	{lat:	10.2376	, long:	123.7882	, link_barangay:	'Tungkop'	, link_city:	'Minglanilla'	},
	{lat:	10.2714	, long:	123.7865	, link_barangay:	'Vito'	, link_city:	'Minglanilla'	},
	{lat:	9.9198	, long:	123.4484	, link_barangay:	'Agbalanga'	, link_city:	'Moalboal'	},
	{lat:	9.9506	, long:	123.4360	, link_barangay:	'Bala'	, link_city:	'Moalboal'	},
	{lat:	9.9126	, long:	123.4096	, link_barangay:	'Balabagon'	, link_city:	'Moalboal'	},
	{lat:	9.9389	, long:	123.3783	, link_barangay:	'Basdiot'	, link_city:	'Moalboal'	},
	{lat:	9.9293	, long:	123.4578	, link_barangay:	'Batadbatad'	, link_city:	'Moalboal'	},
	{lat:	9.9410	, long:	123.4158	, link_barangay:	'Bugho'	, link_city:	'Moalboal'	},
	{lat:	9.9433	, long:	123.4682	, link_barangay:	'Buguil'	, link_city:	'Moalboal'	},
	{lat:	9.9032	, long:	123.4488	, link_barangay:	'Busay'	, link_city:	'Moalboal'	},
	{lat:	9.9530	, long:	123.4186	, link_barangay:	'Lanao'	, link_city:	'Moalboal'	},
	{lat:	9.9416	, long:	123.3914	, link_barangay:	'Poblacion East'	, link_city:	'Moalboal'	},
	{lat:	9.9381	, long:	123.3966	, link_barangay:	'Poblacion West'	, link_city:	'Moalboal'	},
	{lat:	9.9771	, long:	123.3788	, link_barangay:	'Saavedra'	, link_city:	'Moalboal'	},
	{lat:	9.9282	, long:	123.4056	, link_barangay:	'Tomonoy'	, link_city:	'Moalboal'	},
	{lat:	9.9503	, long:	123.3779	, link_barangay:	'Tuble'	, link_city:	'Moalboal'	},
	{lat:	9.9522	, long:	123.4009	, link_barangay:	'Tunga'	, link_city:	'Moalboal'	},
	{lat:	10.2747	, long:	123.7035	, link_barangay:	'Alfaco'	, link_city:	'Naga City'	},
	{lat:	10.1983	, long:	123.7192	, link_barangay:	'Bairan'	, link_city:	'Naga City'	},
	{lat:	10.2633	, long:	123.7173	, link_barangay:	'Balirong'	, link_city:	'Naga City'	},
	{lat:	10.2099	, long:	123.7228	, link_barangay:	'Cabungahan'	, link_city:	'Naga City'	},
	{lat:	10.2476	, long:	123.7581	, link_barangay:	'Cantao-An'	, link_city:	'Naga City'	},
	{lat:	10.2090	, long:	123.7579	, link_barangay:	'Central Poblacion'	, link_city:	'Naga City'	},
	{lat:	10.2647	, long:	123.7423	, link_barangay:	'Cogon'	, link_city:	'Naga City'	},
	{lat:	10.2217	, long:	123.7557	, link_barangay:	'Colon'	, link_city:	'Naga City'	},
	{lat:	10.2112	, long:	123.7592	, link_barangay:	'East Poblacion'	, link_city:	'Naga City'	},
	{lat:	10.2379	, long:	123.7734	, link_barangay:	'Inayagan'	, link_city:	'Naga City'	},
	{lat:	10.1953	, long:	123.7369	, link_barangay:	'Inoburan'	, link_city:	'Naga City'	},
	{lat:	10.2282	, long:	123.7032	, link_barangay:	'Jaguimit'	, link_city:	'Naga City'	},
	{lat:	10.2912	, long:	123.7357	, link_barangay:	'Lanas'	, link_city:	'Naga City'	},
	{lat:	10.1849	, long:	123.7327	, link_barangay:	'Langtad'	, link_city:	'Naga City'	},
	{lat:	10.2489	, long:	123.7247	, link_barangay:	'Lutac'	, link_city:	'Naga City'	},
	{lat:	10.2103	, long:	123.7370	, link_barangay:	'Mainit'	, link_city:	'Naga City'	},
	{lat:	10.2661	, long:	123.6932	, link_barangay:	'Mayana'	, link_city:	'Naga City'	},
	{lat:	10.2159	, long:	123.7475	, link_barangay:	'Naalad'	, link_city:	'Naga City'	},
	{lat:	10.2149	, long:	123.7582	, link_barangay:	'North Poblacion'	, link_city:	'Naga City'	},
	{lat:	10.2299	, long:	123.7336	, link_barangay:	'Pangdan'	, link_city:	'Naga City'	},
	{lat:	10.2579	, long:	123.7052	, link_barangay:	'Patag'	, link_city:	'Naga City'	},
	{lat:	10.2042	, long:	123.7569	, link_barangay:	'South Poblacion'	, link_city:	'Naga City'	},
	{lat:	10.2444	, long:	123.6820	, link_barangay:	'Tagjaguimit'	, link_city:	'Naga City'	},
	{lat:	10.2157	, long:	123.7547	, link_barangay:	'Tangke'	, link_city:	'Naga City'	},
	{lat:	10.2004	, long:	123.7486	, link_barangay:	'Tinaan'	, link_city:	'Naga City'	},
	{lat:	10.2305	, long:	123.7619	, link_barangay:	'Tuyan'	, link_city:	'Naga City'	},
	{lat:	10.2832	, long:	123.7169	, link_barangay:	'Uling'	, link_city:	'Naga City'	},
	{lat:	10.2112	, long:	123.7577	, link_barangay:	'West Poblacion'	, link_city:	'Naga City'	},
	{lat:	9.5001	, long:	123.3918	, link_barangay:	'Alo'	, link_city:	'Oslob'	},
	{lat:	9.4541	, long:	123.3660	, link_barangay:	'Bangcogon'	, link_city:	'Oslob'	},
	{lat:	9.5599	, long:	123.4402	, link_barangay:	'Bonbon'	, link_city:	'Oslob'	},
	{lat:	9.5109	, long:	123.4058	, link_barangay:	'Calumpang'	, link_city:	'Oslob'	},
	{lat:	9.5418	, long:	123.3953	, link_barangay:	'Can-Ukban'	, link_city:	'Oslob'	},
	{lat:	9.5207	, long:	123.3753	, link_barangay:	'Cañang'	, link_city:	'Oslob'	},
	{lat:	9.5625	, long:	123.4115	, link_barangay:	'Canangca-An'	, link_city:	'Oslob'	},
	{lat:	9.6011	, long:	123.3956	, link_barangay:	'Cansalo-Ay'	, link_city:	'Oslob'	},
	{lat:	9.5269	, long:	123.3943	, link_barangay:	'Daanlungsod'	, link_city:	'Oslob'	},
	{lat:	9.5942	, long:	123.4349	, link_barangay:	'Gawi'	, link_city:	'Oslob'	},
	{lat:	9.5073	, long:	123.3989	, link_barangay:	'Hagdan'	, link_city:	'Oslob'	},
	{lat:	9.5418	, long:	123.4287	, link_barangay:	'Lagunde'	, link_city:	'Oslob'	},
	{lat:	9.5519	, long:	123.4351	, link_barangay:	'Looc'	, link_city:	'Oslob'	},
	{lat:	9.4874	, long:	123.3839	, link_barangay:	'Luka'	, link_city:	'Oslob'	},
	{lat:	9.4433	, long:	123.3624	, link_barangay:	'Mainit'	, link_city:	'Oslob'	},
	{lat:	9.5665	, long:	123.3873	, link_barangay:	'Manlum'	, link_city:	'Oslob'	},
	{lat:	9.5855	, long:	123.4170	, link_barangay:	'Nueva Caceres'	, link_city:	'Oslob'	},
	{lat:	9.5285	, long:	123.4194	, link_barangay:	'Poblacion'	, link_city:	'Oslob'	},
	{lat:	9.5778	, long:	123.4458	, link_barangay:	'Pungtod'	, link_city:	'Oslob'	},
	{lat:	9.4683	, long:	123.3735	, link_barangay:	'Tan-Awan'	, link_city:	'Oslob'	},
	{lat:	9.4998	, long:	123.3653	, link_barangay:	'Tumalog'	, link_city:	'Oslob'	},
	{lat:	10.7530	, long:	124.5182	, link_barangay:	'Biasong'	, link_city:	'Pilar'	},
	{lat:	10.7341	, long:	124.5033	, link_barangay:	'Cawit'	, link_city:	'Pilar'	},
	{lat:	10.7732	, long:	124.5202	, link_barangay:	'Dapdap'	, link_city:	'Pilar'	},
	{lat:	10.7922	, long:	124.5351	, link_barangay:	'Esperanza'	, link_city:	'Pilar'	},
	{lat:	10.7589	, long:	124.5068	, link_barangay:	'Imelda'	, link_city:	'Pilar'	},
	{lat:	10.7462	, long:	124.5008	, link_barangay:	'Lanao'	, link_city:	'Pilar'	},
	{lat:	10.8033	, long:	124.5624	, link_barangay:	'Lower Poblacion'	, link_city:	'Pilar'	},
	{lat:	10.7438	, long:	124.5233	, link_barangay:	'Moabog'	, link_city:	'Pilar'	},
	{lat:	10.7607	, long:	124.5356	, link_barangay:	'Montserrat'	, link_city:	'Pilar'	},
	{lat:	10.7711	, long:	124.5423	, link_barangay:	'San Isidro'	, link_city:	'Pilar'	},
	{lat:	10.7807	, long:	124.5455	, link_barangay:	'San Juan'	, link_city:	'Pilar'	},
	{lat:	10.8057	, long:	124.5526	, link_barangay:	'Upper Poblacion'	, link_city:	'Pilar'	},
	{lat:	10.7916	, long:	124.5499	, link_barangay:	'Villahermosa'	, link_city:	'Pilar'	},
	{lat:	10.2354	, long:	123.6155	, link_barangay:	'Anislag'	, link_city:	'Pinamungahan'	},
	{lat:	10.2953	, long:	123.6389	, link_barangay:	'Anopog'	, link_city:	'Pinamungahan'	},
	{lat:	10.2610	, long:	123.5987	, link_barangay:	'Binabag'	, link_city:	'Pinamungahan'	},
	{lat:	10.2813	, long:	123.6214	, link_barangay:	'Buhingtubig'	, link_city:	'Pinamungahan'	},
	{lat:	10.2191	, long:	123.6292	, link_barangay:	'Busay'	, link_city:	'Pinamungahan'	},
	{lat:	10.2797	, long:	123.6051	, link_barangay:	'Butong'	, link_city:	'Pinamungahan'	},
	{lat:	10.2985	, long:	123.5920	, link_barangay:	'Cabiangon'	, link_city:	'Pinamungahan'	},
	{lat:	10.2808	, long:	123.6569	, link_barangay:	'Camugao'	, link_city:	'Pinamungahan'	},
	{lat:	10.2561	, long:	123.6196	, link_barangay:	'Duangan'	, link_city:	'Pinamungahan'	},
	{lat:	10.2395	, long:	123.6369	, link_barangay:	'Guimbawian'	, link_city:	'Pinamungahan'	},
	{lat:	10.2555	, long:	123.6639	, link_barangay:	'Lamac'	, link_city:	'Pinamungahan'	},
	{lat:	10.2710	, long:	123.6049	, link_barangay:	'Lut-Od'	, link_city:	'Pinamungahan'	},
	{lat:	10.2519	, long:	123.5843	, link_barangay:	'Mangoto'	, link_city:	'Pinamungahan'	},
	{lat:	10.2217	, long:	123.6055	, link_barangay:	'Opao'	, link_city:	'Pinamungahan'	},
	{lat:	10.2843	, long:	123.5932	, link_barangay:	'Pandacan'	, link_city:	'Pinamungahan'	},
	{lat:	10.2717	, long:	123.5874	, link_barangay:	'Poblacion'	, link_city:	'Pinamungahan'	},
	{lat:	10.2706	, long:	123.6282	, link_barangay:	'Punod'	, link_city:	'Pinamungahan'	},
	{lat:	10.2981	, long:	123.6137	, link_barangay:	'Rizal'	, link_city:	'Pinamungahan'	},
	{lat:	10.2259	, long:	123.5932	, link_barangay:	'Sacsac'	, link_city:	'Pinamungahan'	},
	{lat:	10.2427	, long:	123.5985	, link_barangay:	'Sambagon'	, link_city:	'Pinamungahan'	},
	{lat:	10.2504	, long:	123.6488	, link_barangay:	'Sibago'	, link_city:	'Pinamungahan'	},
	{lat:	10.3223	, long:	123.5934	, link_barangay:	'Tajao'	, link_city:	'Pinamungahan'	},
	{lat:	10.2138	, long:	123.6143	, link_barangay:	'Tangub'	, link_city:	'Pinamungahan'	},
	{lat:	10.3106	, long:	123.6211	, link_barangay:	'Tanibag'	, link_city:	'Pinamungahan'	},
	{lat:	10.2063	, long:	123.6272	, link_barangay:	'Tupas'	, link_city:	'Pinamungahan'	},
	{lat:	10.2354	, long:	123.5794	, link_barangay:	'Tutay'	, link_city:	'Pinamungahan'	},
	{lat:	10.6791	, long:	124.4316	, link_barangay:	'Adela'	, link_city:	'Poro'	},
	{lat:	10.6537	, long:	124.4368	, link_barangay:	'Altavista'	, link_city:	'Poro'	},
	{lat:	10.6958	, long:	124.4378	, link_barangay:	'Cagcagan'	, link_city:	'Poro'	},
	{lat:	10.6705	, long:	124.4476	, link_barangay:	'Cansabusab'	, link_city:	'Poro'	},
	{lat:	10.6982	, long:	124.4710	, link_barangay:	'Daan Paz'	, link_city:	'Poro'	},
	{lat:	10.6332	, long:	124.4061	, link_barangay:	'Eastern Poblacion'	, link_city:	'Poro'	},
	{lat:	10.6945	, long:	124.4172	, link_barangay:	'Esperanza'	, link_city:	'Poro'	},
	{lat:	10.6366	, long:	124.4365	, link_barangay:	'Libertad'	, link_city:	'Poro'	},
	{lat:	10.6370	, long:	124.4218	, link_barangay:	'Mabini'	, link_city:	'Poro'	},
	{lat:	10.6707	, long:	124.4056	, link_barangay:	'Mercedes'	, link_city:	'Poro'	},
	{lat:	10.6610	, long:	124.4215	, link_barangay:	'Pagsa'	, link_city:	'Poro'	},
	{lat:	10.7084	, long:	124.4449	, link_barangay:	'Paz'	, link_city:	'Poro'	},
	{lat:	10.6870	, long:	124.4632	, link_barangay:	'Rizal'	, link_city:	'Poro'	},
	{lat:	10.6893	, long:	124.4510	, link_barangay:	'San Jose'	, link_city:	'Poro'	},
	{lat:	10.6776	, long:	124.4212	, link_barangay:	'Santa Rita'	, link_city:	'Poro'	},
	{lat:	10.6503	, long:	124.4016	, link_barangay:	'Teguis'	, link_city:	'Poro'	},
	{lat:	10.6403	, long:	124.4022	, link_barangay:	'Western Poblacion'	, link_city:	'Poro'	},
	{lat:	10.0053	, long:	123.4271	, link_barangay:	'Butong'	, link_city:	'Ronda'	},
	{lat:	9.9989	, long:	123.4390	, link_barangay:	'Can-Abuhon'	, link_city:	'Ronda'	},
	{lat:	10.0163	, long:	123.4142	, link_barangay:	'Canduling'	, link_city:	'Ronda'	},
	{lat:	10.0150	, long:	123.4370	, link_barangay:	'Cansalonoy'	, link_city:	'Ronda'	},
	{lat:	10.0260	, long:	123.4181	, link_barangay:	'Cansayahon'	, link_city:	'Ronda'	},
	{lat:	10.0016	, long:	123.4176	, link_barangay:	'Ilaya'	, link_city:	'Ronda'	},
	{lat:	10.0027	, long:	123.4672	, link_barangay:	'Langin'	, link_city:	'Ronda'	},
	{lat:	9.9904	, long:	123.4178	, link_barangay:	'Libo-O'	, link_city:	'Ronda'	},
	{lat:	9.9864	, long:	123.4673	, link_barangay:	'Malalay'	, link_city:	'Ronda'	},
	{lat:	9.9910	, long:	123.4052	, link_barangay:	'Palanas'	, link_city:	'Ronda'	},
	{lat:	9.9992	, long:	123.4100	, link_barangay:	'Poblacion'	, link_city:	'Ronda'	},
	{lat:	10.0066	, long:	123.4137	, link_barangay:	'Santa Cruz'	, link_city:	'Ronda'	},
	{lat:	9.9874	, long:	123.4315	, link_barangay:	'Tupas'	, link_city:	'Ronda'	},
	{lat:	9.9855	, long:	123.4484	, link_barangay:	'Vive'	, link_city:	'Ronda'	},
	{lat:	9.5206	, long:	123.3353	, link_barangay:	'Basak'	, link_city:	'Samboan'	},
	{lat:	9.5162	, long:	123.3142	, link_barangay:	'Bonbon'	, link_city:	'Samboan'	},
	{lat:	9.5191	, long:	123.3502	, link_barangay:	'Bulangsuran'	, link_city:	'Samboan'	},
	{lat:	9.4938	, long:	123.3310	, link_barangay:	'Calatagan'	, link_city:	'Samboan'	},
	{lat:	9.5364	, long:	123.3346	, link_barangay:	'Cambigong'	, link_city:	'Samboan'	},
	{lat:	9.4811	, long:	123.3348	, link_barangay:	'Camburoy'	, link_city:	'Samboan'	},
	{lat:	9.5402	, long:	123.3530	, link_barangay:	'Canorong'	, link_city:	'Samboan'	},
	{lat:	9.4811	, long:	123.3088	, link_barangay:	'Colase'	, link_city:	'Samboan'	},
	{lat:	9.4936	, long:	123.3095	, link_barangay:	'Dalahikan'	, link_city:	'Samboan'	},
	{lat:	9.5074	, long:	123.3343	, link_barangay:	'Jumangpas'	, link_city:	'Samboan'	},
	{lat:	9.4998	, long:	123.3474	, link_barangay:	'Monteverde'	, link_city:	'Samboan'	},
	{lat:	9.5277	, long:	123.3162	, link_barangay:	'Poblacion'	, link_city:	'Samboan'	},
	{lat:	9.4698	, long:	123.3168	, link_barangay:	'San Sebastian'	, link_city:	'Samboan'	},
	{lat:	9.5454	, long:	123.3190	, link_barangay:	'Suba'	, link_city:	'Samboan'	},
	{lat:	9.5074	, long:	123.3119	, link_barangay:	'Tangbo'	, link_city:	'Samboan'	},
	{lat:	10.1509	, long:	123.6890	, link_barangay:	'Balud'	, link_city:	'San Fernando'	},
	{lat:	10.1695	, long:	123.6510	, link_barangay:	'Balungag'	, link_city:	'San Fernando'	},
	{lat:	10.1520	, long:	123.6692	, link_barangay:	'Basak'	, link_city:	'San Fernando'	},
	{lat:	10.1977	, long:	123.6512	, link_barangay:	'Bugho'	, link_city:	'San Fernando'	},
	{lat:	10.2103	, long:	123.6751	, link_barangay:	'Cabatbatan'	, link_city:	'San Fernando'	},
	{lat:	10.1372	, long:	123.6778	, link_barangay:	'Greenhills'	, link_city:	'San Fernando'	},
	{lat:	10.1935	, long:	123.7050	, link_barangay:	'Ilaya'	, link_city:	'San Fernando'	},
	{lat:	10.1450	, long:	123.6812	, link_barangay:	'Lantawan'	, link_city:	'San Fernando'	},
	{lat:	10.1650	, long:	123.6663	, link_barangay:	'Liburon'	, link_city:	'San Fernando'	},
	{lat:	10.1828	, long:	123.6693	, link_barangay:	'Magsico'	, link_city:	'San Fernando'	},
	{lat:	10.1557	, long:	123.6975	, link_barangay:	'Panadtaran'	, link_city:	'San Fernando'	},
	{lat:	10.1823	, long:	123.7169	, link_barangay:	'Pitalo'	, link_city:	'San Fernando'	},
	{lat:	10.1609	, long:	123.7086	, link_barangay:	'Poblacion North'	, link_city:	'San Fernando'	},
	{lat:	10.1584	, long:	123.7059	, link_barangay:	'Poblacion South'	, link_city:	'San Fernando'	},
	{lat:	10.1725	, long:	123.7091	, link_barangay:	'San Isidro'	, link_city:	'San Fernando'	},
	{lat:	10.1332	, long:	123.6877	, link_barangay:	'Sangat'	, link_city:	'San Fernando'	},
	{lat:	10.1745	, long:	123.6815	, link_barangay:	'Tabionan'	, link_city:	'San Fernando'	},
	{lat:	10.1836	, long:	123.6937	, link_barangay:	'Tananas'	, link_city:	'San Fernando'	},
	{lat:	10.1675	, long:	123.6977	, link_barangay:	'Tinubdan'	, link_city:	'San Fernando'	},
	{lat:	10.1633	, long:	123.6815	, link_barangay:	'Tonggo'	, link_city:	'San Fernando'	},
	{lat:	10.1815	, long:	123.6339	, link_barangay:	'Tubod'	, link_city:	'San Fernando'	},
	{lat:	10.6928	, long:	124.3403	, link_barangay:	'Cabunga-An'	, link_city:	'San Francisco'	},
	{lat:	10.6536	, long:	124.3420	, link_barangay:	'Campo'	, link_city:	'San Francisco'	},
	{lat:	10.6372	, long:	124.3117	, link_barangay:	'Consuelo'	, link_city:	'San Francisco'	},
	{lat:	10.6959	, long:	124.3206	, link_barangay:	'Esperanza'	, link_city:	'San Francisco'	},
	{lat:	10.6092	, long:	124.2849	, link_barangay:	'Himensulan'	, link_city:	'San Francisco'	},
	{lat:	10.6318	, long:	124.3378	, link_barangay:	'Montealegre'	, link_city:	'San Francisco'	},
	{lat:	10.6576	, long:	124.3710	, link_barangay:	'Northern Poblacion'	, link_city:	'San Francisco'	},
	{lat:	10.6257	, long:	124.3566	, link_barangay:	'San Isidro'	, link_city:	'San Francisco'	},
	{lat:	10.6829	, long:	124.3622	, link_barangay:	'Santa Cruz'	, link_city:	'San Francisco'	},
	{lat:	10.6030	, long:	124.3025	, link_barangay:	'Santiago'	, link_city:	'San Francisco'	},
	{lat:	10.7038	, long:	124.3363	, link_barangay:	'Sonog'	, link_city:	'San Francisco'	},
	{lat:	10.6335	, long:	124.3758	, link_barangay:	'Southern Poblacion'	, link_city:	'San Francisco'	},
	{lat:	10.6121	, long:	124.3299	, link_barangay:	'Unidos'	, link_city:	'San Francisco'	},
	{lat:	10.6670	, long:	124.3200	, link_barangay:	'Union'	, link_city:	'San Francisco'	},
	{lat:	10.6437	, long:	124.3654	, link_barangay:	'Western Poblacion'	, link_city:	'San Francisco'	},
	{lat:	11.0245	, long:	123.9167	, link_barangay:	'Anapog'	, link_city:	'San Remigio'	},
	{lat:	11.0761	, long:	123.9506	, link_barangay:	'Argawanon'	, link_city:	'San Remigio'	},
	{lat:	10.9041	, long:	123.9392	, link_barangay:	'Bagtic'	, link_city:	'San Remigio'	},
	{lat:	11.0383	, long:	123.9162	, link_barangay:	'Bancasan'	, link_city:	'San Remigio'	},
	{lat:	11.0213	, long:	123.9525	, link_barangay:	'Batad'	, link_city:	'San Remigio'	},
	{lat:	10.9624	, long:	123.9312	, link_barangay:	'Busogon'	, link_city:	'San Remigio'	},
	{lat:	10.9189	, long:	123.9447	, link_barangay:	'Calambua'	, link_city:	'San Remigio'	},
	{lat:	10.9612	, long:	123.9471	, link_barangay:	'Canagahan'	, link_city:	'San Remigio'	},
	{lat:	11.0350	, long:	123.9307	, link_barangay:	'Dapdap'	, link_city:	'San Remigio'	},
	{lat:	10.9960	, long:	123.9521	, link_barangay:	'Gawaygaway'	, link_city:	'San Remigio'	},
	{lat:	11.0927	, long:	123.9415	, link_barangay:	'Hagnaya'	, link_city:	'San Remigio'	},
	{lat:	11.0452	, long:	123.9461	, link_barangay:	'Kayam'	, link_city:	'San Remigio'	},
	{lat:	10.9437	, long:	123.9260	, link_barangay:	'Kinawahan'	, link_city:	'San Remigio'	},
	{lat:	11.0067	, long:	123.9389	, link_barangay:	'Lambusan'	, link_city:	'San Remigio'	},
	{lat:	11.0585	, long:	123.9503	, link_barangay:	'Lawis'	, link_city:	'San Remigio'	},
	{lat:	10.9350	, long:	123.9395	, link_barangay:	'Libaong'	, link_city:	'San Remigio'	},
	{lat:	11.0675	, long:	123.9409	, link_barangay:	'Looc'	, link_city:	'San Remigio'	},
	{lat:	10.9094	, long:	123.9145	, link_barangay:	'Luyang'	, link_city:	'San Remigio'	},
	{lat:	11.0530	, long:	123.9321	, link_barangay:	'Mano'	, link_city:	'San Remigio'	},
	{lat:	11.0839	, long:	123.9395	, link_barangay:	'Poblacion'	, link_city:	'San Remigio'	},
	{lat:	11.1007	, long:	123.9366	, link_barangay:	'Punta'	, link_city:	'San Remigio'	},
	{lat:	10.9350	, long:	123.9523	, link_barangay:	'Sab-A'	, link_city:	'San Remigio'	},
	{lat:	10.9000	, long:	123.9550	, link_barangay:	'San Miguel'	, link_city:	'San Remigio'	},
	{lat:	10.9882	, long:	123.9378	, link_barangay:	'Tacup'	, link_city:	'San Remigio'	},
	{lat:	11.0161	, long:	123.9284	, link_barangay:	'Tambongon'	, link_city:	'San Remigio'	},
	{lat:	11.0338	, long:	123.9469	, link_barangay:	'To-Ong'	, link_city:	'San Remigio'	},
	{lat:	10.9285	, long:	123.9160	, link_barangay:	'Victoria'	, link_city:	'San Remigio'	},
	{lat:	11.1798	, long:	123.7734	, link_barangay:	'Balidbid'	, link_city:	'Santa Fe'	},
	{lat:	11.3423	, long:	123.8947	, link_barangay:	'Hagdan'	, link_city:	'Santa Fe'	},
	{lat:	11.2032	, long:	123.8197	, link_barangay:	'Hilantagaan'	, link_city:	'Santa Fe'	},
	{lat:	11.3263	, long:	123.8931	, link_barangay:	'Kinatarkan'	, link_city:	'Santa Fe'	},
	{lat:	11.3070	, long:	123.8914	, link_barangay:	'Langub'	, link_city:	'Santa Fe'	},
	{lat:	11.1579	, long:	123.7684	, link_barangay:	'Maricaban'	, link_city:	'Santa Fe'	},
	{lat:	11.1708	, long:	123.7836	, link_barangay:	'Okoy'	, link_city:	'Santa Fe'	},
	{lat:	11.1557	, long:	123.8005	, link_barangay:	'Poblacion'	, link_city:	'Santa Fe'	},
	{lat:	11.1517	, long:	123.7841	, link_barangay:	'Pooc'	, link_city:	'Santa Fe'	},
	{lat:	11.1651	, long:	123.7950	, link_barangay:	'Talisay'	, link_city:	'Santa Fe'	},
	{lat:	9.4402	, long:	123.3198	, link_barangay:	'Bunlan'	, link_city:	'Santander'	},
	{lat:	9.4591	, long:	123.3322	, link_barangay:	'Cabutongan'	, link_city:	'Santander'	},
	{lat:	9.4480	, long:	123.3389	, link_barangay:	'Candamiang'	, link_city:	'Santander'	},
	{lat:	9.4389	, long:	123.3075	, link_barangay:	'Canlumacad'	, link_city:	'Santander'	},
	{lat:	9.4254	, long:	123.3104	, link_barangay:	'Liloan'	, link_city:	'Santander'	},
	{lat:	9.4384	, long:	123.3405	, link_barangay:	'Lip-Tong'	, link_city:	'Santander'	},
	{lat:	9.4210	, long:	123.3230	, link_barangay:	'Looc'	, link_city:	'Santander'	},
	{lat:	9.4267	, long:	123.3425	, link_barangay:	'Pasil'	, link_city:	'Santander'	},
	{lat:	9.4212	, long:	123.3317	, link_barangay:	'Poblacion'	, link_city:	'Santander'	},
	{lat:	9.4533	, long:	123.3075	, link_barangay:	'Talisay'	, link_city:	'Santander'	},
	{lat:	10.0613	, long:	123.6215	, link_barangay:	'Abugon'	, link_city:	'Sibonga'	},
	{lat:	9.9973	, long:	123.5317	, link_barangay:	'Bae'	, link_city:	'Sibonga'	},
	{lat:	9.9888	, long:	123.6017	, link_barangay:	'Bagacay'	, link_city:	'Sibonga'	},
	{lat:	10.0055	, long:	123.6115	, link_barangay:	'Bahay'	, link_city:	'Sibonga'	},
	{lat:	10.0446	, long:	123.5830	, link_barangay:	'Banlot'	, link_city:	'Sibonga'	},
	{lat:	10.0446	, long:	123.5434	, link_barangay:	'Basak'	, link_city:	'Sibonga'	},
	{lat:	10.0166	, long:	123.5817	, link_barangay:	'Bato'	, link_city:	'Sibonga'	},
	{lat:	10.0386	, long:	123.5560	, link_barangay:	'Cagay'	, link_city:	'Sibonga'	},
	{lat:	9.9945	, long:	123.5501	, link_barangay:	'Can-Aga'	, link_city:	'Sibonga'	},
	{lat:	10.0452	, long:	123.6189	, link_barangay:	'Candaguit'	, link_city:	'Sibonga'	},
	{lat:	9.9999	, long:	123.5172	, link_barangay:	'Cantolaroy'	, link_city:	'Sibonga'	},
	{lat:	10.0180	, long:	123.5329	, link_barangay:	'Dugoan'	, link_city:	'Sibonga'	},
	{lat:	10.0437	, long:	123.6025	, link_barangay:	'Guimbangco-An'	, link_city:	'Sibonga'	},
	{lat:	10.0238	, long:	123.5985	, link_barangay:	'Lamacan'	, link_city:	'Sibonga'	},
	{lat:	10.0298	, long:	123.5672	, link_barangay:	'Libo'	, link_city:	'Sibonga'	},
	{lat:	9.9775	, long:	123.5970	, link_barangay:	'Lindogon'	, link_city:	'Sibonga'	},
	{lat:	10.0053	, long:	123.5963	, link_barangay:	'Magcagong'	, link_city:	'Sibonga'	},
	{lat:	10.0160	, long:	123.5647	, link_barangay:	'Manatad'	, link_city:	'Sibonga'	},
	{lat:	10.0696	, long:	123.6003	, link_barangay:	'Mangyan'	, link_city:	'Sibonga'	},
	{lat:	10.0164	, long:	123.5504	, link_barangay:	'Papan'	, link_city:	'Sibonga'	},
	{lat:	10.0200	, long:	123.6147	, link_barangay:	'Poblacion'	, link_city:	'Sibonga'	},
	{lat:	10.0330	, long:	123.6161	, link_barangay:	'Sabang'	, link_city:	'Sibonga'	},
	{lat:	10.0722	, long:	123.5726	, link_barangay:	'Sayao'	, link_city:	'Sibonga'	},
	{lat:	9.9749	, long:	123.6162	, link_barangay:	'Simala'	, link_city:	'Sibonga'	},
	{lat:	9.9911	, long:	123.5712	, link_barangay:	'Tubod'	, link_city:	'Sibonga'	},
	{lat:	10.7715	, long:	123.9787	, link_barangay:	'Ampongol'	, link_city:	'Sogod'	},
	{lat:	10.7780	, long:	123.9571	, link_barangay:	'Bagakay'	, link_city:	'Sogod'	},
	{lat:	10.7600	, long:	123.9974	, link_barangay:	'Bagatayam'	, link_city:	'Sogod'	},
	{lat:	10.7347	, long:	123.9995	, link_barangay:	'Bawo'	, link_city:	'Sogod'	},
	{lat:	10.7391	, long:	123.9604	, link_barangay:	'Cabalawan'	, link_city:	'Sogod'	},
	{lat:	10.7581	, long:	123.9582	, link_barangay:	'Cabangahan'	, link_city:	'Sogod'	},
	{lat:	10.7958	, long:	124.0187	, link_barangay:	'Calumboyan'	, link_city:	'Sogod'	},
	{lat:	10.7894	, long:	123.9853	, link_barangay:	'Dakit'	, link_city:	'Sogod'	},
	{lat:	10.7709	, long:	124.0023	, link_barangay:	'Damolog'	, link_city:	'Sogod'	},
	{lat:	10.7468	, long:	123.9936	, link_barangay:	'Ibabao'	, link_city:	'Sogod'	},
	{lat:	10.7897	, long:	123.9972	, link_barangay:	'Liki'	, link_city:	'Sogod'	},
	{lat:	10.7968	, long:	123.9751	, link_barangay:	'Lubo'	, link_city:	'Sogod'	},
	{lat:	10.8045	, long:	123.9536	, link_barangay:	'Mohon'	, link_city:	'Sogod'	},
	{lat:	10.7137	, long:	123.9524	, link_barangay:	'Nahus-An'	, link_city:	'Sogod'	},
	{lat:	10.7441	, long:	123.9793	, link_barangay:	'Pansoy'	, link_city:	'Sogod'	},
	{lat:	10.7412	, long:	124.0001	, link_barangay:	'Poblacion'	, link_city:	'Sogod'	},
	{lat:	10.7825	, long:	124.0164	, link_barangay:	'Tabunok'	, link_city:	'Sogod'	},
	{lat:	10.8024	, long:	123.9663	, link_barangay:	'Takay'	, link_city:	'Sogod'	},
	{lat:	10.9219	, long:	124.0216	, link_barangay:	'Alang-Alang'	, link_city:	'Tabogon'	},
	{lat:	10.9198	, long:	123.9699	, link_barangay:	'Caduawan'	, link_city:	'Tabogon'	},
	{lat:	10.9582	, long:	124.0332	, link_barangay:	'Camoboan'	, link_city:	'Tabogon'	},
	{lat:	10.9019	, long:	124.0021	, link_barangay:	'Canaocanao'	, link_city:	'Tabogon'	},
	{lat:	10.9510	, long:	124.0111	, link_barangay:	'Combado'	, link_city:	'Tabogon'	},
	{lat:	10.9136	, long:	124.0276	, link_barangay:	'Daantabogon'	, link_city:	'Tabogon'	},
	{lat:	10.9001	, long:	123.9651	, link_barangay:	'Ilihan'	, link_city:	'Tabogon'	},
	{lat:	10.9396	, long:	124.0107	, link_barangay:	'Kal-Anan'	, link_city:	'Tabogon'	},
	{lat:	10.9194	, long:	123.9915	, link_barangay:	'Labangon'	, link_city:	'Tabogon'	},
	{lat:	10.9525	, long:	123.9641	, link_barangay:	'Libjo'	, link_city:	'Tabogon'	},
	{lat:	10.9322	, long:	123.9976	, link_barangay:	'Loong'	, link_city:	'Tabogon'	},
	{lat:	10.9580	, long:	123.9808	, link_barangay:	'Mabuli'	, link_city:	'Tabogon'	},
	{lat:	10.8986	, long:	123.9788	, link_barangay:	'Managase'	, link_city:	'Tabogon'	},
	{lat:	10.9532	, long:	123.9934	, link_barangay:	'Manlagtang'	, link_city:	'Tabogon'	},
	{lat:	10.9278	, long:	124.0222	, link_barangay:	'Maslog'	, link_city:	'Tabogon'	},
	{lat:	10.8942	, long:	124.0423	, link_barangay:	'Muabog'	, link_city:	'Tabogon'	},
	{lat:	10.9386	, long:	124.0243	, link_barangay:	'Pio'	, link_city:	'Tabogon'	},
	{lat:	10.9436	, long:	124.0266	, link_barangay:	'Poblacion'	, link_city:	'Tabogon'	},
	{lat:	10.9724	, long:	124.0368	, link_barangay:	'Salag'	, link_city:	'Tabogon'	},
	{lat:	10.9592	, long:	124.0027	, link_barangay:	'Sambag'	, link_city:	'Tabogon'	},
	{lat:	10.9697	, long:	124.0201	, link_barangay:	'San Isidro'	, link_city:	'Tabogon'	},
	{lat:	10.9580	, long:	124.0180	, link_barangay:	'San Vicente'	, link_city:	'Tabogon'	},
	{lat:	10.9755	, long:	123.9992	, link_barangay:	'Somosa'	, link_city:	'Tabogon'	},
	{lat:	10.8983	, long:	124.0226	, link_barangay:	'Taba-Ao'	, link_city:	'Tabogon'	},
	{lat:	10.9481	, long:	124.0268	, link_barangay:	'Tapul'	, link_city:	'Tabogon'	},
	{lat:	10.2634	, long:	123.8350	, link_barangay:	'Lawaan III'	, link_city:	'Talisay City'	},
	{lat:	10.2594	, long:	123.8188	, link_barangay:	'Linao'	, link_city:	'Talisay City'	},
	{lat:	10.2731	, long:	123.8185	, link_barangay:	'Maghaway'	, link_city:	'Talisay City'	},
	{lat:	10.3087	, long:	123.7918	, link_barangay:	'Manipis'	, link_city:	'Talisay City'	},
	{lat:	10.2501	, long:	123.8230	, link_barangay:	'Mohon'	, link_city:	'Talisay City'	},
	{lat:	10.2435	, long:	123.8469	, link_barangay:	'Poblacion'	, link_city:	'Talisay City'	},
	{lat:	10.2425	, long:	123.8242	, link_barangay:	'Pooc'	, link_city:	'Talisay City'	},
	{lat:	10.2541	, long:	123.8452	, link_barangay:	'San Isidro'	, link_city:	'Talisay City'	},
	{lat:	10.2587	, long:	123.8606	, link_barangay:	'San Roque'	, link_city:	'Talisay City'	},
	{lat:	10.2637	, long:	123.8445	, link_barangay:	'Tabunoc'	, link_city:	'Talisay City'	},
	{lat:	10.2548	, long:	123.8649	, link_barangay:	'Tangke'	, link_city:	'Talisay City'	},
	{lat:	10.2846	, long:	123.8019	, link_barangay:	'Tapul'	, link_city:	'Talisay City'	},
	{lat:	10.3402	, long:	123.6247	, link_barangay:	'Awihao'	, link_city:	'Toledo City'	},
	{lat:	10.3693	, long:	123.7457	, link_barangay:	'Bagakay'	, link_city:	'Toledo City'	},
	{lat:	10.3413	, long:	123.5973	, link_barangay:	'Bato'	, link_city:	'Toledo City'	},
	{lat:	10.3424	, long:	123.7327	, link_barangay:	'Biga'	, link_city:	'Toledo City'	},
	{lat:	10.2995	, long:	123.6667	, link_barangay:	'Bulongan'	, link_city:	'Toledo City'	},
	{lat:	10.2955	, long:	123.6977	, link_barangay:	'Bunga'	, link_city:	'Toledo City'	},
	{lat:	10.3533	, long:	123.6122	, link_barangay:	'Cabitoonan'	, link_city:	'Toledo City'	},
	{lat:	10.4178	, long:	123.6709	, link_barangay:	'Calongcalong'	, link_city:	'Toledo City'	},
	{lat:	10.3622	, long:	123.6797	, link_barangay:	'Cambang-Ug'	, link_city:	'Toledo City'	},
	{lat:	10.3169	, long:	123.7470	, link_barangay:	'Camp 8'	, link_city:	'Toledo City'	},
	{lat:	10.3712	, long:	123.6674	, link_barangay:	'Canlumampao'	, link_city:	'Toledo City'	},
	{lat:	10.3096	, long:	123.7348	, link_barangay:	'Cantabaco'	, link_city:	'Toledo City'	},
	{lat:	10.4029	, long:	123.7132	, link_barangay:	'Capitan Claudio'	, link_city:	'Toledo City'	},
	{lat:	10.3921	, long:	123.6696	, link_barangay:	'Carmen'	, link_city:	'Toledo City'	},
	{lat:	10.3896	, long:	123.6433	, link_barangay:	'Daanglungsod'	, link_city:	'Toledo City'	},
	{lat:	10.3239	, long:	123.7131	, link_barangay:	'Don Andres Soriano'	, link_city:	'Toledo City'	},
	{lat:	10.3983	, long:	123.6525	, link_barangay:	'Dumlog'	, link_city:	'Toledo City'	},
	{lat:	10.3712	, long:	123.7006	, link_barangay:	'Gen. Climaco'	, link_city:	'Toledo City'	},
	{lat:	10.3681	, long:	123.6316	, link_barangay:	'Ibo'	, link_city:	'Toledo City'	},
	{lat:	10.3825	, long:	123.6594	, link_barangay:	'Ilihan'	, link_city:	'Toledo City'	},
	{lat:	10.3481	, long:	123.6673	, link_barangay:	'Juan Climaco, Sr.'	, link_city:	'Toledo City'	},
	{lat:	10.3443	, long:	123.6543	, link_barangay:	'Landahan'	, link_city:	'Toledo City'	},
	{lat:	10.3514	, long:	123.7488	, link_barangay:	'Loay'	, link_city:	'Toledo City'	},
	{lat:	10.3760	, long:	123.6563	, link_barangay:	'Luray II'	, link_city:	'Toledo City'	},
	{lat:	10.4220	, long:	123.6868	, link_barangay:	'Matab-Ang'	, link_city:	'Toledo City'	},
	{lat:	10.3361	, long:	123.6809	, link_barangay:	'Media Once'	, link_city:	'Toledo City'	},
	{lat:	10.3974	, long:	123.7422	, link_barangay:	'Pangamihan'	, link_city:	'Toledo City'	},
	{lat:	10.3707	, long:	123.6469	, link_barangay:	'Poblacion'	, link_city:	'Toledo City'	},
	{lat:	10.3191	, long:	123.6883	, link_barangay:	'Poog'	, link_city:	'Toledo City'	},
	{lat:	10.3576	, long:	123.7230	, link_barangay:	'Putingbato'	, link_city:	'Toledo City'	},
	{lat:	10.3182	, long:	123.6419	, link_barangay:	'Sagay'	, link_city:	'Toledo City'	},
	{lat:	10.3264	, long:	123.6661	, link_barangay:	'Sam-Ang'	, link_city:	'Toledo City'	},
	{lat:	10.3830	, long:	123.6510	, link_barangay:	'Sangi'	, link_city:	'Toledo City'	},
	{lat:	10.4385	, long:	123.6907	, link_barangay:	'Santo Niño'	, link_city:	'Toledo City'	},
	{lat:	10.3353	, long:	123.6475	, link_barangay:	'Subayon'	, link_city:	'Toledo City'	},
	{lat:	10.4064	, long:	123.6715	, link_barangay:	'Talavera'	, link_city:	'Toledo City'	},
	{lat:	10.3571	, long:	123.6498	, link_barangay:	'Tubod'	, link_city:	'Toledo City'	},
	{lat:	10.3827	, long:	123.7588	, link_barangay:	'Tungkay'	, link_city:	'Toledo City'	},
	{lat:	10.6996	, long:	123.8463	, link_barangay:	'Alegria'	, link_city:	'Tuburan'	},
	{lat:	10.6958	, long:	123.9141	, link_barangay:	'Amatugan'	, link_city:	'Tuburan'	},
	{lat:	10.6594	, long:	123.7764	, link_barangay:	'Antipolo'	, link_city:	'Tuburan'	},
	{lat:	10.7527	, long:	123.8391	, link_barangay:	'Apalan'	, link_city:	'Tuburan'	},
	{lat:	10.7659	, long:	123.8438	, link_barangay:	'Bagasawe'	, link_city:	'Tuburan'	},
	{lat:	10.6649	, long:	123.8193	, link_barangay:	'Bakyawan'	, link_city:	'Tuburan'	},
	{lat:	10.6775	, long:	123.9104	, link_barangay:	'Bangkito'	, link_city:	'Tuburan'	},
	{lat:	10.7304	, long:	123.8280	, link_barangay:	'Barangay I'	, link_city:	'Tuburan'	},
	{lat:	10.7270	, long:	123.8300	, link_barangay:	'Barangay II'	, link_city:	'Tuburan'	},
	{lat:	10.7273	, long:	123.8267	, link_barangay:	'Barangay III'	, link_city:	'Tuburan'	},
	{lat:	10.7289	, long:	123.8251	, link_barangay:	'Barangay IV'	, link_city:	'Tuburan'	},
	{lat:	10.7263	, long:	123.8261	, link_barangay:	'Barangay V'	, link_city:	'Tuburan'	},
	{lat:	10.7278	, long:	123.8223	, link_barangay:	'Barangay VI'	, link_city:	'Tuburan'	},
	{lat:	10.7249	, long:	123.8239	, link_barangay:	'Barangay VII'	, link_city:	'Tuburan'	},
	{lat:	10.7221	, long:	123.8147	, link_barangay:	'Barangay VIII'	, link_city:	'Tuburan'	},
	{lat:	10.7577	, long:	123.9273	, link_barangay:	'Bulwang'	, link_city:	'Tuburan'	},
	{lat:	10.6780	, long:	123.8082	, link_barangay:	'Caridad'	, link_city:	'Tuburan'	},
	{lat:	10.7021	, long:	123.8016	, link_barangay:	'Carmelo'	, link_city:	'Tuburan'	},
	{lat:	10.7128	, long:	123.8261	, link_barangay:	'Cogon'	, link_city:	'Tuburan'	},
	{lat:	10.6535	, long:	123.7936	, link_barangay:	'Colonia'	, link_city:	'Tuburan'	},
	{lat:	10.7366	, long:	123.8351	, link_barangay:	'Daan Lungsod'	, link_city:	'Tuburan'	},
	{lat:	10.6713	, long:	123.8001	, link_barangay:	'Fortaliza'	, link_city:	'Tuburan'	},
	{lat:	10.6714	, long:	123.8803	, link_barangay:	'Ga-Ang'	, link_city:	'Tuburan'	},
	{lat:	10.6862	, long:	123.8329	, link_barangay:	'Gimama-A'	, link_city:	'Tuburan'	},
	{lat:	10.6884	, long:	123.8140	, link_barangay:	'Jagbuaya'	, link_city:	'Tuburan'	},
	{lat:	10.6838	, long:	123.8989	, link_barangay:	'Kabangkalan'	, link_city:	'Tuburan'	},
	{lat:	10.7263	, long:	123.8722	, link_barangay:	'Kabkaban'	, link_city:	'Tuburan'	},
	{lat:	10.6498	, long:	123.8279	, link_barangay:	'Kagba-O'	, link_city:	'Tuburan'	},
	{lat:	10.7089	, long:	123.9210	, link_barangay:	'Kalangahan'	, link_city:	'Tuburan'	},
	{lat:	10.6914	, long:	123.9318	, link_barangay:	'Kamansi'	, link_city:	'Tuburan'	},
	{lat:	10.7837	, long:	123.8973	, link_barangay:	'Kampoot'	, link_city:	'Tuburan'	},
	{lat:	10.6781	, long:	123.9274	, link_barangay:	'Kan-An'	, link_city:	'Tuburan'	},
	{lat:	10.6689	, long:	123.8975	, link_barangay:	'Kanlunsing'	, link_city:	'Tuburan'	},
	{lat:	10.6657	, long:	123.8431	, link_barangay:	'Kansi'	, link_city:	'Tuburan'	},
	{lat:	10.6668	, long:	123.9138	, link_barangay:	'Kaorasan'	, link_city:	'Tuburan'	},
	{lat:	10.6476	, long:	123.8070	, link_barangay:	'Libo'	, link_city:	'Tuburan'	},
	{lat:	10.7513	, long:	123.8938	, link_barangay:	'Lusong'	, link_city:	'Tuburan'	},
	{lat:	10.7599	, long:	123.8628	, link_barangay:	'Macupa'	, link_city:	'Tuburan'	},
	{lat:	10.6588	, long:	123.8859	, link_barangay:	'Mag-Alwa'	, link_city:	'Tuburan'	},
	{lat:	10.6597	, long:	123.8662	, link_barangay:	'Mag-Antoy'	, link_city:	'Tuburan'	},
	{lat:	10.6757	, long:	123.8521	, link_barangay:	'Mag-Atubang'	, link_city:	'Tuburan'	},
	{lat:	10.6531	, long:	123.8490	, link_barangay:	'Maghan-Ay'	, link_city:	'Tuburan'	},
	{lat:	10.7001	, long:	123.8175	, link_barangay:	'Mangga'	, link_city:	'Tuburan'	},
	{lat:	10.6885	, long:	123.8740	, link_barangay:	'Marmol'	, link_city:	'Tuburan'	},
	{lat:	10.6930	, long:	123.7979	, link_barangay:	'Molobolo'	, link_city:	'Tuburan'	},
	{lat:	10.7310	, long:	123.9275	, link_barangay:	'Montealegre'	, link_city:	'Tuburan'	},
	{lat:	10.7858	, long:	123.8662	, link_barangay:	'Putat'	, link_city:	'Tuburan'	},
	{lat:	10.7193	, long:	123.8471	, link_barangay:	'San Juan'	, link_city:	'Tuburan'	},
	{lat:	10.7131	, long:	123.8935	, link_barangay:	'Sandayong'	, link_city:	'Tuburan'	},
	{lat:	10.6791	, long:	123.7896	, link_barangay:	'Santo Niño'	, link_city:	'Tuburan'	},
	{lat:	10.7799	, long:	123.9277	, link_barangay:	'Siotes'	, link_city:	'Tuburan'	},
	{lat:	10.6779	, long:	123.8225	, link_barangay:	'Sumon'	, link_city:	'Tuburan'	},
	{lat:	10.7350	, long:	123.8494	, link_barangay:	'Tominjao'	, link_city:	'Tuburan'	},
	{lat:	10.6630	, long:	123.9025	, link_barangay:	'Tomugpa'	, link_city:	'Tuburan'	},
	{lat:	10.6728	, long:	124.4651	, link_barangay:	'Buenavista'	, link_city:	'Tudela'	},
	{lat:	10.6460	, long:	124.4863	, link_barangay:	'Calmante'	, link_city:	'Tudela'	},
	{lat:	10.6609	, long:	124.4841	, link_barangay:	'Daan Secante'	, link_city:	'Tudela'	},
	{lat:	10.6396	, long:	124.4544	, link_barangay:	'General'	, link_city:	'Tudela'	},
	{lat:	10.6582	, long:	124.5046	, link_barangay:	'Mcarthur'	, link_city:	'Tudela'	},
	{lat:	10.6414	, long:	124.4706	, link_barangay:	'Northern Poblacion'	, link_city:	'Tudela'	},
	{lat:	10.6859	, long:	124.4864	, link_barangay:	'Puertobello'	, link_city:	'Tudela'	},
	{lat:	10.6546	, long:	124.4639	, link_barangay:	'Santander'	, link_city:	'Tudela'	},
	{lat:	10.6732	, long:	124.4768	, link_barangay:	'Secante Bag-O'	, link_city:	'Tudela'	},
	{lat:	10.6375	, long:	124.4700	, link_barangay:	'Southern Poblacion'	, link_city:	'Tudela'	},
	{lat:	10.6728	, long:	124.5035	, link_barangay:	'Villahermosa'	, link_city:	'Tudela'	},

];