import React, { Component } from 'react';
import '../Forms.css';
import kumusta_img from '../../kumusta.png';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

    const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
  
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Intro extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
   
    render() {
        return (
            <React.Fragment>
				<div className="wrapper">
					<div className = "card">
						<div className = "progress"> 
							<Slider
								disabled
								track = "inverted"
								orientation="vertical"
								defaultValue={5}
								valueLabelFormat={valueLabelFormat}
								getAriaValueText={valuetext}
								aria-labelledby="discrete-slider-restrict"
								step={1}
								min={0}
								max={5}
								valueLabelDisplay="auto"
								marks={marks}
							/>
						</div>
						<div className = "screen">
							<div className = "progress_change">
								<h2>Intro</h2>
							</div>
							<img className="intro_img" src={kumusta_img} />
							<br/><br/><br/>
							<p>You are about to use a short, and safe CoVID-19 self-assessment tool.
							<br/><br/><b>Please answer the CoVID-19 screening questions <b>HONESTLY</b>.</b>
							<br/><br/></p>
							<div>
								<button class = "button-choice" onClick = {this.continue}>Next</button>
							</div>
							<br/>
							<br/>
						</div>
					</div>
				</div>
            </React.Fragment>
        );
    };
}

export default SelfAssessment_Intro;
