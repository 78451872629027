import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import StatusLogin from './StatusLogin';
import StatusDescription from './StatusDescription';
import moment from 'moment';

export class Status extends Component {
	state = {
		step: 1,

        report_id:'',
        patient_code:'',
		verification_key:'',
		status:0,
        loading:false,
		classification:'',
		date_responded:'',
		name:'',
		logs: [],
		report: {}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Proceed to the next Step
    nextStep = () => {
        const { step } = this.state;

        this.setState({
            step: step + 1
        });
    }
    //Go back to the previous Step
    prevStep = () => {
        const { step } = this.state;

        this.setState({
            step: step - 1
        });
    }

	//handle textview input change
    handleChange = input => e => {
        this.setState({[input]: e.target.value});
    }

	setReportID = (input) => {

		const reportId = input;
		if (process.env.NODE_ENV === 'development') {
			const username = this.state.patient_code;
			const password = this.state.verification_key;
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					var t_name = result.data.name.toUpperCase();
					this.setPatientCode(this.state.patient_code);
					this.setVerification(this.state.verification_key);
					this.setName(t_name);
					this.setStatus(result.data.status);
					this.setClassification(result.data.classification);
					this.setDate_responded(result.data.date_responded, reportId);
				})
				.catch(error =>{

                        // console.log(error);
                    })
		} else {
			const username = this.state.patient_code;
			const password = this.state.verification_key;
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {
					auth: {
						username,
						password
					}
				})
				.then(result => {
					// console.log("result")
					var t_name = result.data.name.toUpperCase();
					this.setPatientCode(this.state.patient_code);
					this.setVerification(this.state.verification_key);
					this.setName(t_name);
					this.setStatus(result.data.status);
					this.setClassification(result.data.classification);
					this.setDate_responded(result.data.date_responded, reportId);
				})
				.catch(error =>{

                        // console.log(error);
                    })
		}

        this.setState({
            report_id: input,
        })

    }

	setName = (input) => {
		this.setState({
			name: input
		});
	}

	setPatientCode = (input) => {
		this.setState({
			patient_code: input
		});
	}

	setVerification = (input) => {
		this.setState({
			verification_key: input
		});
	}

	setStatus = (input) => {
		this.setState({
			status: input
		});
	}

	setClassification = (input) => {
		this.setState({
			classification: input
		});
	}

	setDate_responded = (input, id) => {
		const reportId = id;
		var logged = 0;
		var rec_date = '';
		var rec_time = '';

		if (process.env.NODE_ENV === 'development') {
			const username = this.state.patient_code;
			const password = this.state.verification_key;
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates?report_id=${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						logs: result.data.report_updates
					});

					if(this.state.logs && this.state.logs.length){
						rec_date = moment(this.state.logs[0].date_created).format('lll');
						logged = 1;
						this.setState({
							date_responded: rec_date
						});
					}else{
						rec_date = moment(input).format('lll');
						this.setState({
							date_responded: rec_date
						});
					}
				})
				.catch(error =>{
                        // console.log(error);
                    })
		} else {
			const username = this.state.patient_code;
			const password = this.state.verification_key;
			const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates?report_id=${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reportUpdates?report_id=${reportId}`;

			axios(reportUpdatesUrl, {
					auth: {
						username,
						password
					}
				})
				.then(result => {
					this.setState({
						logs: result.data.report_updates
					});

					if(this.state.logs && this.state.logs.length){
						rec_date = moment(this.state.logs[0].date_created).format('lll');

						logged = 1;
						this.setState({
							date_responded: rec_date
						});
					}else{
						rec_date = moment(input).format('lll');
						this.setState({
							date_responded: rec_date
						});
					}
				})
				.catch(error =>{

                        // console.log(error);
                    })
		}


	}

    render() {
		const {step} = this.state;
		const {
            report_id,
			patient_code,
			verification_key,
            loading,
            name,
            classification,
            status,
			date_responded
		} = this.state;

		const values = {
            report_id,
			patient_code,
			verification_key,
            loading,
            name,
            classification,
            status,
			date_responded
		}

		switch(step){
			case 1:
				return (
					<StatusLogin
                        setReportID = {this.setReportID}
                        setClassification = {this.setClassification}
                        setStatus = {this.setStatus}
                        setDate_responded = {this.setDate_responded}
						nextStep = {this.nextStep}
                        handleChange = {this.handleChange}
						values={values}
					/>
				)
			case 2:
				return (
					<StatusDescription
                        setStatus = {this.setStatus}
						values={values}
					/>
				)
		}
    }
}

export default Status
