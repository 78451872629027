import React, { Component } from 'react';
import '../Forms.css';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CreatableSelect from 'react-select/creatable';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Select from 'react-select';
import options_departments from '.././Departments';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];

function valuetext(value) {
    return `${value}°C`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Unit_Department extends Component {
    state = {
        errorText : "",
        options: [],
        select: {},
        isLoading:false,
        selectedOption: {},
    }
	componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({
            select: {
                label: this.props.values.unit_department,
                value: this.props.values.unit_department,
            },
        });
    };
    //-----------------------------------------------------------------------------------------------------------------
    handleDropChange = (newValue: any, actionMeta: any) => {
		var tempArr = [];
		var prevSelComp = this.state.select;
		
		if(newValue != null){
			tempArr.push({
				label: newValue.label,
				value: newValue.value
			});
            
            this.props.handleUnitDepartment(newValue.value);
			this.setState({select: tempArr})
			// report_s['company'] = newValue.value;
			// this.setState({ report: report_s });
			// this.setState({selectedCompany: tempArr})
		}else{
			this.setState({select: prevSelComp})
		}
	};
	
	handleCreate = (inputValue: any) => {
		this.setState({ isLoading: true });
		setTimeout(() => {
		  const { options } = this.state;
		  const newOption = this.createOption(inputValue);
		  
          this.props.handleUnitDepartment(inputValue);   
		  this.setState({
			isLoading: false,
			options: [...options, newOption],
			select: newOption,
		  });
		}, 1000);
	  };

	createOption = (label: string) => ({
		label: label,
		value: label,
    });
	

    //-----------------------------------------------------------------------------------------------------------------
    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        if(this.props.values.unit_department.trim() == ""){
            this.setState({ errorText: "Input is required" });
        }else{
            this.setState({ errorText: "" });
            this.props.nextStep();
        }
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };


	handleChange = selectedOption => {
        this.props.handleUnitDepartment(selectedOption.value, selectedOption)
	};

    render() {
        const {values, handleChange, handleUnitDepartment } = this.props;
		const { selectedOption } = this.state;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress">
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Personal Information</h2>
							</div>
                            <h1>Please select your unit/office/program.</h1>

							<br/>
							<br/>
							<div className = "for_select">
								<Select
								    name="department"
								    value={this.props.values.selected_department}
									searchable
								    onChange={this.handleChange}
								    options={options_departments}
									placeholder="Select.."
								/>
							</div>
							<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorText}</h4>
							<br/>
							<div class="bot_nav">
								<button class = "button-backchoice"
									primary = {true}
									onClick = {this.back}>
										&#60;  Back
								</button>
								<button class = "button-nextchoice"
									primary = {true}
									onClick = {this.continue}>
										Continue
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const selectStyles = {
	menu: base => ({
		...base,
		zIndex: 100
	})
};
export default SelfAssessment_Unit_Department;
