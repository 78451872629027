import React, { Component, createRef } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';

import {Map,Marker,Popup,TileLayer}from 'react-leaflet';
import L from 'leaflet';

class UpdateAddressLatLong extends Component {
	state = {
		report_id: -1,
		zoom: 14,
		address_latitude: 0,
		address_longitude: 0,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ 
						report_id: result.data.id,
						address_latitude: result.data.address_latitude,
						address_longitude: result.data.address_longitude
					});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ 
                        report_id: result.data.id,
                        address_latitude: result.data.address_latitude,
                        address_longitude: result.data.address_longitude
                    });
				});
		}
	}
	render() {
		const customMarker = L.icon({ 
            iconUrl: require('./covcheck_logo.png'), 
            iconSize: [70, 80], // size of the icon
            iconAnchor: [35, 80], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -80] // point from which the popup should open relative to the iconAnchor
        });
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<Map 
								center={[this.state.address_latitude, this.state.address_longitude]}
	                            zoom={this.state.zoom}
	                            onClick = {this.getPosition}
	                            style={{width: '95vw', height: '90vh', margin: 'auto'}}
							>
	                            <TileLayer
		                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
		                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
	                            />
	                            <Marker
	                                icon={customMarker}
	                                draggable={false}
	                                position={[this.state.address_latitude, this.state.address_longitude]}
                                />
                        	</Map>
						</div>
			      	</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateAddressLatLong;
