import React, { Component } from 'react';
import '../Forms.css';
import axios from 'axios';
import {
  Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Typography } from '@material-ui/core';
import { html2canvas } from 'html2canvas';
import allow_img from './allow.png';
import deny_img from './deny.png';
import info_img from './info.png';
import moment from 'moment';
import LoadingIcon from '@material-ui/icons/Schedule';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

export class StatusDescription extends Component {
	state = {
		date_responded:'',
		description:'',
		name:'',
		image:null,
		report: {},
		color: '',
		status: '',
		generated: false
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	};
	
    render() {
        const {values, handleChange } = this.props;
		
        return (
            <MuiThemeProvider>
                <React.Fragment>
					<div className="wrapper_home">
                        <br/><br/>
						{(() => {
							if(this.props.values.status == 1 || this.props.values.status == 2){
								return(
									<h3>Hi, <b>{this.props.values.name}</b>!</h3>
									);
							}else{
								return(
									<div>
										<br/><br/><br/><br/><br/><br/><br/>
										<LoadingIcon className="svg_icons"/><br/>
										<h3>LOADING...</h3>
									</div>
								);
							}
						})()}
						{(() => {
							if(this.props.values.status == 1){
								return(
									<div className="clearance-pass-box" style={{ backgroundColor: '#61b896' }}>
										<br/><br/><br/>
										<img class="clearance_img" src={allow_img} />
										<br/><br/><br/>
										<Typography variant="button" style={{ color: '#fff' }}>YOU ARE CLEARED TO ENTER THE UNIVERSITY PREMISES!</Typography> 
										<br/><br/><br/>
										<Typography variant="caption" style={{ color: '#fff' }}>Valid until {moment(this.props.values.date_responded).add(1, 'days').format('lll')}</Typography> 
										<br/><br/><br/>
									</div>
								);
							}else if(this.props.values.status == 2){
								return(
									<div className="clearance-pass-box" style={{ backgroundColor: '#e54341' }}>
										<br/><br/><br/>
										<img class="clearance_img" src={deny_img} />
										<br/><br/><br/>
										<Typography variant="button" style={{ color: '#fff' }}>YOU ARE ADVISED TO STAY AT HOME TODAY!</Typography> 
										<br/><br/><br/>
										<Typography variant="caption" style={{ color: '#fff' }}>{this.props.values.date_responded}</Typography> 
										<br/><br/><br/>
									</div>
								);
							}else{
								return(
									<div className="clearance-pass-box">
										<br/><br/>
										<Typography variant="button" style={{ color: '#fff' }}></Typography> 
										<br/><br/><br/>
										<Typography variant="caption" style={{ color: '#fff' }}></Typography> 
										<br/><br/>
									</div>
								);
							}
						})()}
						<br/><br/>
						{(() => {
							if(this.props.values.status == 1){
								return(
									<div className="code-description">
										<br/><img class="info_img" src={info_img} />
										<Typography variant="body1">Upon entering the premises, you will be required to present a clearance pass to the security officer through your smartphone. You may take a screenshot of this image.</Typography>
									</div>
								);
							}else if(this.props.values.status == 2){
								return(
									<div className="code-description">
										<br/><img class="info_img" src={info_img} />
										<Typography variant="body1">A red stop indicates that you should stay at home. Persons who have symptoms consistent with CoVID-19 or may have been exposed to someone with CoVID-19 are not yet allowed on campus. For now, you are advised to study or work from home. You are also required to send your health updates daily through CovidCheck while you are at home. You may contact the Health Services Unit for more information, clarification and updates.</Typography>
									</div>
								);
							}else{
								return(
									null
								);
							}
						})()}
						{(() => {
							if(this.props.values.status == 1 || this.props.values.status == 2){
								return(
									<div className="return-home">
										<br/>
										<Link to = '/'>
											<button class = "button-home" 
												primary = {true}>
												Home
											</button>
										</Link>
										<br/>
									</div>
								);
							}else{
								return(
									null
								);
							}
						})()}
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default StatusDescription;
