import RaisedButton from 'material-ui/RaisedButton';
import React from 'react';
import TextField from 'material-ui/TextField';
import {
  Link
} from "react-router-dom";

const ChangePasswordComponent = props => (
	<>
		<div className="card">
			<div>	
				<TextField
					floatingLabelText = "Email"
					hintText="Email"
					onChange={props.onEmailChange}
					value={props.email}
				/>
			</div>
			<div>
				<TextField
					floatingLabelText="Current Password"
					hintText="Current Password"
					value={props.currPassword}
					onChange={props.onCurrPasswordChange}
					type="password"
				/>
			</div>
			<div>
				<TextField
					floatingLabelText="New Password"
					hintText="New Password"
					value={props.password}
					onChange={props.onPasswordChange}
					type="password"
				/>
			</div>
			<div>
				<TextField
					floatingLabelText="Confirm Password"
					hintText="Confirm Password"
					value={props.confirmPassword}
					onChange={props.onConfirmPasswordChange}
					type="password"
				/>
			</div>
		</div>
		<div style={{color: 'red', 'font-size': '0.9em'}}>
			<br/>
			{props.errors}
		</div>
		<div class="bot_nav">
			<Link to = '/'>
				<button class = "button-backchoice"
					primary = {true}>
						Cancel
				</button>
			</Link>
	        <button class = "button-nextchoice"
				primary = {true}
				onClick = {props.onSubmit}>
					Confirm
			</button>
			<br/><br/>
		</div>
	</>
);

export default ChangePasswordComponent;
