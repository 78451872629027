import React from 'react';
import moment from 'moment';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';
import HealthStatusActionMenu from './HealthStatusActionMenu';

import { Link, withRouter } from 'react-router-dom';

import {
	Button, Divider, Paper, Grid, Typography
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const customStyles = {
	headCells: {
		style: {
			textAlign: 'center',
			color: '#202124',
			fontSize: '12px',
			fontWeight: 'bold',
		},
	},
};

function symptoms(fever, cough, colds, diff_breath, body_pain,
					headache, chest_pain, confusion, bluish_lips_fingers,
					psych_distress, others){
	let str = "";
	if(fever){
		str = str+"Fever, ";
	}
	if(cough){
		str = str+"Cough, ";
	}
	if(diff_breath){
		str = str+"Difficulty Breathing, ";
	}
	if(colds){
		str = str+"Colds, ";
	}
	if(body_pain){
		str = str+"Body Pain, ";
	}
	if(chest_pain){
		str = str+"Chest Pain, ";
	}
	if(confusion){
		str = str+"Confusion, ";
	}
	if(headache){
		str = str+"Headache, ";
	}
	if(bluish_lips_fingers){
		str = str+"Bluish Lips/Fingers, ";
	}
	if(psych_distress){
		str = str+"Psychological Distress, ";
	}
	if(others){
		str = str+"others";
	}
	return str;
}

class HealthStatusMonitoring extends React.PureComponent {
	state = {
		health_status: [],
		patient: {},
		patietName: '',
		patientId: this.props.match.params.id,
		loading: false,
		patient_id: '',
		userRole: cookies.get('qb_role'),
		userId: cookies.get('qb_id'),
	};

	componentDidMount(){
		window.scrollTo(0,0);
		const patientId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					const patient = result.data;
                    var name = patient.last_name + ", " + patient.first_name + " " + patient.middle_name;
                    this.setState({
                    	patient: patient,
                        patientName: name,
                    });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
			axios(url, {withCredentials: true})
				.then(result => {
                    const patient = result.data;
                    var name = patient.last_name + ", " + patient.first_name + " " + patient.middle_name;
                    this.setState({
                    	patient: patient,
                        patientName: name,
                    });
				});
		}
		this.setState({patient_id: patientId});
		this.loadData();
	}

	loadData = () => {
		this.setState({ loading: true });
		let url = `/api/qb/patients/${this.state.patientId}/logs?`;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					// console.log(result.data.patientLogs);
					this.setState({
						health_status: result.data.patientLogs,
						loading: false
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						health_status: result.data.patientLogs,
						loading: false
					});
				});
		}
	}

	filterLogs = () => {
		this.loadData();
	}

	daysLapsed = (date) => {
		const time = '08:00';
		var dateFrom = new Date(date);
		dateFrom = new Date(dateFrom.getFullYear(), dateFrom.getUTCMonth(),
							dateFrom.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
		var today = new Date();
		today = new Date(today.getFullYear(), today.getUTCMonth(),
							today.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
		const diffTime = today.getTime() - dateFrom.getTime();
		const diffDays = Math.ceil(diffTime / (1000*3600*24));
		return diffDays;
	}

	isAfter = (dateRecovered, dateStatus) => {
		const time = '08:00';
		var dateFrom = new Date(dateRecovered);
		dateFrom = new Date(dateFrom.getFullYear(), dateFrom.getUTCMonth(),
							dateFrom.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
		var today = new Date(dateStatus);
		today = new Date(today.getFullYear(), today.getUTCMonth(),
							today.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
		const diffTime = today.getTime() - dateFrom.getTime();
		const diffDays = Math.ceil(diffTime / (1000*3600*24));
		if(diffDays <= 0){
			return false;
		}else{
			return true;
		}
	}

	render(){
		const conditionalRowStyles = [
			{ when: row => (this.state.patient.status === 'Recovered' && this.isAfter(this.state.patient.date_status, row.date_time)),
				style: {
					backgroundColor: 'rgba(92,255,145,0.3)',
				},
			},
		];

		const columns = [
			{ cell: item => (this.state.userRole === 'Admin' || this.state.userRole === 'Doctor') && <HealthStatusActionMenu
																										row={item}
																										filterLogs={this.filterLogs}
																										userRole={this.state.userRole}
																										userId={this.state.userId}/>,
				allowOverflow: true,
				button: true,
				width: '15px',
			},
			{ name: 'Date', selector: 'date_time', sortable: true, width: '175px',
				cell: item => <div align='left'>{moment(item.date_time).format('lll')}</div>,
			},
			{ name: 'Temperature', selector: 'temperature', sortable: true, center: true, width: '100px',
			},
			{ name: 'Blood Pressure', selector: 'blood_pressure', center: true, width: '100px',
			},
			{ name: 'Heart Rate', selector: 'heart_rate', center: true, width: '100px',
			},
			{ name: 'O2 Saturation', selector: 'o2_saturation', center: true, width: '100px',
			},
			{ name: 'Symptoms', selector: 'symptoms', wrap: true,
				cell: item => <div align='left'>{symptoms(item.symptom_fever, item.symptom_cough, item.symptom_colds, item.symptom_difficulty_breathing,
															item.symptom_body_pain, item.symptom_headache, item.symptom_chest_pain, item.symptom_confusion,
															item.symptom_bluish_lips_fingers, item.symptom_psychological_distress, item.symptom_others)}</div>
			},
			{ name: 'Taken Medications', selector: 'medications', center: true, width: '100px',
				cell: item => item.maintenance_medications_taken ? 'YES' : 'NO',
			},
			{ name: 'Notes', selector: 'remarks', wrap: true,
			},
			{ name: 'Doctor/Nurse', selector: 'monitoring_doctor.lastname', sortable: true, wrap: true,
				cell: item => <div align='left'>{item.monitoring_doctor.last_name ? item.monitoring_doctor.last_name+', '+item.monitoring_doctor.first_name+' '+item.monitoring_doctor.middle_name : ''}</div>,
			},
		];

		return(
			<>
				<Paper style={{ padding : 20 }}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Health Status Logs
					</Typography>
					<Typography component="h1" variant="subtitle1" color="textPrimary" gutterBottom>
						{ this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator' ? this.state.patientName+' / '+this.state.patient.sex : this.state.patient.first_name+' / '+this.state.patient.contact_number}
					</Typography>
					{(() => {
						if(this.state.patient.status === 'Recovered'){
							return(
								<>
									<Typography component="h1" variant="caption" color="textPrimary" gutterBottom>
										Recovered / No. of Days from Discharge: {this.daysLapsed(this.state.patient.date_status)}
									</Typography>
								</>
							);
						}else if(this.state.patient.status === 'Admitted'){
							return(
								<>
									<Typography component="h1" variant="caption" color="textPrimary" gutterBottom>
										{this.state.patient.medical_conditions ? 'Medical Conditions - ' + this.state.patient.medical_conditions : ''}
									</Typography>
									<Typography component="h1" variant="caption" color="textPrimary" gutterBottom>
										{this.state.patient.medications ? 'Medications - ' + this.state.patient.medications : ''}
									</Typography>
								</>
							);
						}
					})()}
					<Divider/>
					<br/>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left' style={{ paddingBottom: 10 }}>
					        	<Button align='left' size="small" variant="outlined" startIcon={<ArrowBackIcon />}
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			component={Link} to='/qb-patients'>
				        			Back</Button>
							</div>
						</Grid>
						{ this.state.userRole === 'Admin' || this.state.userRole === 'Doctor' ?
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button size="small"
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									variant="outlined"
									color="primary"
									component={Link}
									to ={`/qb-add-health-status/${this.state.patient_id}`}
									startIcon={<AddIcon />}>
										Add Health Status
								</Button>
							</div>
						</Grid> : null }
					</Grid>
					<Divider/>
					<DataTable
						noHeader
						columns={columns}
						data={this.state.health_status}
						progressPending={this.state.loading}
						progressComponent={<TableLoader loadingText="Health Status"/>}
						pagination
						customStyles={customStyles}
						conditionalRowStyles={conditionalRowStyles}
					/>
				</Paper>
			</>
		);
	}
}

export default withRouter(HealthStatusMonitoring);
