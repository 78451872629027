import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import { Typography, Divider } from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/ArrowRight';
import 'typeface-roboto';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Result extends Component {
    state = {
        title: null,
        symptoms: null,
        medical_condition: null,
        response: null,
        thanks:<Typography variant='body2' style={{ color: '#363636' }} ><b>Thank you</b> for cooperating with us in this fight against COVID-19.</Typography>,
		end:<Typography variant='caption' style={{ color: '#363636' }} >Please wait for the Health Services Unit to accept your registration. Once accepted, you will receive an email containing your password. Use this to submit subsequent health status reports and get clearance.
		<br/></Typography>,
        infected_place: null,
        infected_people: null,
    }
	componentDidMount() {
        window.scrollTo(0, 0);
		
        if(this.props.values.classification == 'SAFE-1'){
            this.setState({
                title:<Typography variant='body1' style={{ color: '#242424' }} ><b>Your answers indicate that you are at low risk for CoVID-19 at this time.</b></Typography>,
                response:<Typography variant='body2' style={{ color: '#363636' }} >There is still a need for you, to remain vigilant and cautious to avoid being infected.
					<br/><br/>Depending on the work arrangements with your unit or department, you may be allowed to report for work.</Typography>,
            })
        }else if(this.props.values.classification == 'SAFE-2'){
            this.setState({
                title:<Typography variant='body1' style={{ color: '#242424' }} ><b>Your answers indicate that you are at low risk to CoVID-19 at this time.</b></Typography>,
                response:<Typography variant='body2' style={{ color: '#363636' }} >It is possible that the symptoms you are experiencing are caused by other factors. Please call the University Health Services Unit for assistance and instructions.
					<br/><br/>Depending on the work arrangements with your unit or department, you may be allowed to report for work.</Typography>,
            })
        }else if(this.props.values.classification == 'PUM'){
            this.setState({
                title:<Typography variant='body1' style={{ color: '#242424' }} >Based on your responses, you may be considered as a <b>Person Under Monitoring (PUM)</b></Typography>,
                response:
                    <Typography variant='body2' style={{ color: '#363636' }} >PUMs are individuals who have been exposed to COVID-19 by visiting or living in COVID-19 hotspots or who have physically interacted or lived with COVID-19 patients.
                    <br/><br/>It is possible that a PUM will experience minor or no symptoms at all (asymptomatic) even if exposed to COVID-19. However, PUMs can potentially infect others.
                    <br/><br/>Once confirmed as a PUM, you are required to send your health updates for 14 days using CovidCheck.  Please contact your supervisor for your work-from-home or alternative work arrangements.
					<br/><br/>For now, you are advised to work from home until the HSU has given you clearance to report for work. HSU will contact you to assess your health and work status.</Typography>,
            })
        }else if(this.props.values.classification == 'ILI-1'){
            this.setState({
                title:<Typography variant='body1' style={{ color: '#242424' }} >Based on your responses, you may be considered as a <b>Person Presenting mild Influenza-like Illness (PPI)</b>. There is a need to monitor your health to ensure that the symptoms you are presenting are not related to COVID-19.</Typography>,
                response:
                    <Typography variant='body2' style={{ color: '#363636' }} >For now, you are advised to stay at home, rest and get better. You will be required to send your health updates daily through CovidCheck while you are at home.
                    <br/><br/>Please contact your supervisor for  alternative work assignments.
                    <br/><br/>There is no need to worry. You may call the UP Cebu Health Services Unit for assistance and instructions through this office number: 032 232 2642 local 305.</Typography>,
            })
        }else if(this.props.values.classification == 'ILI-2A' || this.props.values.classification == 'SARI'){
            this.setState({
                title:<Typography variant='body1' style={{ color: '#242424' }} ><b>Based on your symptoms and your current medical condition, there is  a need to monitor your health to ensure that the symptoms you are presenting are not from COVID-19.</b></Typography>,
                response:
                    <Typography variant='body2' style={{ color: '#363636' }} >For now, you are advised to stay at home, rest and get better. You will be required to send your health updates daily while you are at home.
                    <br/><br/><b>Please call the University Health Services Unit immediately for assistance and instructionsthrough this office number: 032 232 2642 local 305.</b></Typography>,
            })
        }

        if(this.props.values.has_symptoms){
            let symp = '';
            if(this.props.values.s_fever){
                symp = symp + "fever, "
            }if(this.props.values.s_cough){
                symp = symp + "cough, "
            }if(this.props.values.s_sore_throat){
                symp = symp + "sore throat, "
            }if(this.props.values.s_short_breath){
                symp = symp + "shortness of breath, "
            }if(this.props.values.s_fatigue){
                symp = symp + "fatigue, "
            }if(this.props.values.s_phlegm){
                symp = symp + "phlegm, "
            }if(this.props.values.s_muscle_joint_pain){
                symp = symp + "muscle or joint pain, "
            }if(this.props.values.s_headache){
                symp = symp + "headache, "
            }if(this.props.values.s_chills){
                symp = symp + "chills, "
            }if(this.props.values.s_vomiting){
                symp = symp + "vomiting, "
            }if(this.props.values.s_stuffed_nose){
                symp = symp + "stuffy nose, "
            }if(this.props.values.s_diarrhea){
                symp = symp + "diarrhea, "
            }
            symp = symp + "and nothing more."
            this.setState({
            symptoms:<>You have the following symptom(s): {symp}</>,
            })
        }else{
            this.setState({
                symptoms:<>You are not experiencing any symptoms. </>,
            })
        }
        
        if(this.props.values.has_comorbidity){
            let med = '';
            if(this.props.values.m_pregnancy){
                med = med + 'pregnancy, '
            }
            if(this.props.values.m_asthma){
                med = med + 'asthma, '
            }
            if(this.props.values.m_cancer){
                med = med + 'cancer, '
            }
            if(this.props.values.m_diabetes){
                med = med + 'diabetes, '
            }
            if(this.props.values.m_high_blood_pressure){
                med = med + 'high blood pressure, '
            }
            if(this.props.values.m_immunodeficiency_conditions){
                med = med + 'immunodeficiency conditions, '
            }
            if(this.props.values.m_renal_failure){
                med = med + 'renal failure, '
            }
            if(this.props.values.m_tuberculosis){
                med = med + 'tuberculosis, '
            }if(this.props.values.m_others.trim() != ''){
                med = med + 'and ' + this.props.values.m_others
            }else{
                med = med + 'and nothing more'
            }

            this.setState({
                medical_condition:<>You have the following medical condition(s): {med}.</>,
            })
            
        }else{
            this.setState({
                medical_condition:<>You do not have any underlying medical condition.</>,
            })
        }

        if(this.props.values.has_visited_infected_areas){
            this.setState({
                infected_place:<>You have been in areas with high incidence of confirmed cases of CoVID-19</>,
            })
        }else{
            this.setState({
                infected_place:<>You have not been in areas with high incidence of confirmed cases of CoVID-19</>,
            })
        }

	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, handleChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress"> 
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={0}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Results</h2>
							</div>
                            <br/>{this.state.title}
                            <br/>{this.state.response}
                            <br/>{this.state.thanks}
							<br/>
							<Divider/>
							<div className="summary-results">
								<br/>
								<Typography variant="subtitle2">Summary of your self-assessment:</Typography><br/>
								<ul>
									<li>
										{this.state.symptoms}
									</li>
									<li>
										{this.state.medical_condition}
									</li>
									<li>
										{this.state.infected_place}
									</li>
								</ul>
							</div>
                            <br/><div className="end-container">{this.state.end}</div>
                            
							<div className="return-home">
								<Link to = '/'>
									<button class = "button-home" 
										primary = {true}>
											Home
									</button>
								</Link>
							</div>
							<br/>
							<br/>
						</div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Result;
