import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Select from 'react-select';

import '../Forms.css';
import case_status from './case_status';

class TestedPositive extends Component {
	state = {
		report: {},
		history_travel_exposure: "",
		date_onset_symptoms: null,
		date_admission: null,
		admission_facility: "",
		date_recovered: null,
		date_deceased: null,
		status: "Admitted",
		selectedStatus: { value: "Admitted", label: "Admitted"},
		date_lab_confirmation: null,
		date_lab_confirmation_error: false,
		date_lab_confirmation_error_text: "",
		loading: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
					this.setState({ admission_facility: this.state.report.admit_quarantine_facility});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
					this.setState({ admission_facility: this.state.report.admit_quarantine_facility});
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		this.setState({ loading: true, });
		event.preventDefault();

		if(this.state.date_lab_confirmation == null || this.state.date_lab_confirmation == ""){
			this.setState({ date_lab_confirmation_error: true, date_lab_confirmation_error_text: "Input is required"});
			this.setState({ loading: false, });
		}else{
			if(this.state.date_onset_symptoms == ""){
				this.setState({ date_onset_symptoms: null });
			}
			if(this.state.date_admission == ""){
				this.setState({ date_admission: null });
			}
			const body = {
				"report_id": this.state.report.id,
				"history_travel_exposure": this.state.history_travel_exposure,
				"date_onset_symptoms": this.state.date_onset_symptoms,
				"date_admission": this.state.date_admission,
				"admission_facility": this.state.admission_facility,
				"date_lab_confirmation": this.state.date_lab_confirmation,
				"status": "Admitted",
				"deleted": false
			}

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.post("https://covidcheck.upcebu.edu.ph/dev/api/cases", body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 201){
							const report_body = {
								"status": 3 //report tested positive
							}

							axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, report_body, {headers: {'Authorization': `Basic ${token}`}})
								.then(put_result => {
									if(put_result.status === 200){
										this.props.history.push('/view-reports');
									}else{
										this.setState({ loading: false, });
									}
								});
						}else{
							this.setState({ loading: false, });
						}
					});
			} else {
				const casesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? "https://covidcheck.upcebu.edu.ph/dev/api/cases" : "https://covidcheck.upcebu.edu.ph/api/cases";
				const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
				axios.post(casesUrl, body, {withCredentials: true})
					.then(result => {
						if(result.status === 201){
							const report_body = {
								"status": 3 //report tested positive
							}

							axios.put(reportsUrl, report_body, {withCredentials: true})
								.then(put_result => {
									if(put_result.status === 200){
										this.props.history.push('/view-reports');
									}else{
										this.setState({ loading: false, });
									}
								});
						}else{
							this.setState({ loading: false, });}
					});
			}

		}
	}

	handleChange = field => event => {
		this.setState({ [field]: event.target.value });
	}

	handleStatusChange = selectedOption => {
		this.setState({
			selectedStatus: selectedOption,
			status: selectedOption.value
		});
	}

	dateStr = d => {
		if(d == null){
			return "";
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<p>{this.state.report.name} is tested POSITIVE for COVID-19.<br/>Please fill-out additional information.</p>
							<br/>
						</div>
						<div className="for_select">
							<TextField
		                        label = "Date of Laboratory Confirmation"
		                        type = "date"
		                        onChange={ this.handleChange('date_lab_confirmation') }
		                        value = { this.dateStr(this.state.date_lab_confirmation) }
		                        style = {{ width: 300}}
		                        InputLabelProps={{ shrink: true, }}
		                        error = { this.state.date_lab_confirmation_error }
		                        helperText = { this.state.date_lab_confirmation_error_text }
		                    />
		                    <br/>
		                    <br/>
							<TextField
		                        label = "History of Travel / Exposure"
		                        onChange={ this.handleChange('history_travel_exposure') }
		                        value = { this.state.history_travel_exposure }
		                        style = {{ width: 300}}
		                    />
		                    <br/>
		                    <br/>
		                    <TextField
		                        label = "Date of Onset of Symptoms"
		                        type = "date"
		                        onChange={ this.handleChange('date_onset_symptoms') }
		                        value = { this.dateStr(this.state.date_onset_symptoms) }
		                        style = {{ width: 300}}
		                        InputLabelProps={{ shrink: true, }}
		                    />
		                    <br/>
		                    <br/>
		                    <h4 align="left">Status:</h4>
		                    <br/>
		                    <Select
								name="status"
								value={this.state.selectedStatus}
								onChange={this.handleStatusChange}
								options={case_status}
								placeholder="Status"
							/>
							<br/>
		                    {(() => {
		                    	if(this.state.status == "Admitted" || this.state.status == "Quarantined"){
		                    		return(
		                    			<React.Fragment>
						                    <TextField
						                        label = "Date of Admission/Quarantine"
						                        type = "date"
						                        onChange={ this.handleChange('date_admission') }
						                        value = { this.dateStr(this.state.date_admission) }
						                        style = {{ width: 300}}
						                        InputLabelProps={{ shrink: true, }}
						                    />
						                    <br/>
						                    <br/>
						                    <TextField
						                        label = "Admission/Quarantine Facility"
						                        onChange={ this.handleChange('admission_facility') }
						                        value = { this.state.admission_facility }
						                        style = {{ width: 300}}
						                    />
					                    </React.Fragment>
				                    );
			                	}else if(this.state.status == "Recovered"){
			                		return(
		                    			<React.Fragment>
						                    <TextField
						                        label = "Date Recovered"
						                        type = "date"
						                        onChange={ this.handleChange('date_recovered') }
						                        value = { this.dateStr(this.state.date_recovered) }
						                        style = {{ width: 300}}
						                        InputLabelProps={{ shrink: true, }}
						                    />
					                    </React.Fragment>
				                    );
			                	}else if(this.state.status == "Deceased"){
			                		return(
		                    			<React.Fragment>
						                    <TextField
						                        label = "Date Deceased"
						                        type = "date"
						                        onChange={ this.handleChange('date_deceased') }
						                        value = { this.dateStr(this.state.date_deceased) }
						                        style = {{ width: 300}}
						                        InputLabelProps={{ shrink: true, }}
						                    />
					                    </React.Fragment>
				                    );
			                	}
		                    })()}
						</div>
						<div>
							<br/>
		                    <Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
		                    &nbsp;&nbsp;&nbsp;
							<Button 
							size="small" 
							variant="contained" 
							color="primary" 
							onClick={this.handleConfirm}
							disabled = {this.state.loading}>
								{this.state.loading? "CONFIRMING..":"CONFIRM"}
							</Button>
						</div>
					</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default TestedPositive;
