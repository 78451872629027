import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GetAppIcon from '@material-ui/icons/GetApp';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TestActionMenu from '../../components/Testing/TestActionMenu';

import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const mobilePhonesBreakPoint = 400;

const cookies_key = {
	user_city: 'user_city',
	user_barangay: 'user_barangay',
	user_role: 'user_role'
};

function symptoms(fever, cough, short_breath, fatigue, phlegm, muscle_joint_pain, sore_throat,
	headache, chills, vomiting, stuffed_nose, diarrhea, others){
	let str = "";
	if(fever){
		str = str+"Fever, ";
	}
	if(cough){
		str = str+"Cough, ";
	}
	if(short_breath){
		str = str+"Shortness of Breath, ";
	}
	if(fatigue){
		str = str+"Fatigue, ";
	}
	if(phlegm){
		str = str+"Phlegm, ";
	}
	if(muscle_joint_pain){
		str = str+"Muscle or Joint Pain, ";
	}
	if(sore_throat){
		str = str+"Sore Throat, ";
	}
	if(headache){
		str = str+"Headache, ";
	}
	if(chills){
		str = str+"Chills, ";
	}
	if(vomiting){
		str = str+"Vomiting, ";
	}
	if(stuffed_nose){
		str = str+"Stuffy Nose, ";
	}
	if(diarrhea){
		str = str+"Diarrhea, ";
	}
	if(others){
		str = str+others;
	}
	return str;
}

const ExpandedStyle = styled.div`
	padding: 20px;
	display: block;
	width: 100%;
`;

const ExpandedComponent = ({ data }) => (
	<ExpandedStyle>
		<img height="75%" width="75%" alt={data.image_url} src={data.image_url} />
	</ExpandedStyle>
);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ViewPatientMonitoring extends Component {
	_exporter;
	export = () => {
		this._exporter.save();
	}

	state = {
		tab: 0,

		tests: [],

		logs: [],
		report: {},
		report_id: null,
		name: '',
		age: '',
		sex: '',
		patient_code: '',
		date_responded: null,
		quarantine_days: 1,
		quarantine_color: '',
		loading: true,
		selectedRows: [],
		openDelete: false,
		toggleClear: false,
		entryText: 'Allow Entry',
		openEntryStatus: false,
		entryStatusValue: 1,
	}

	changeTab = (event, newValue) => {
		this.setState({tab: newValue});
		cookies.set('monitoring_tab', newValue);
	}

	loadTests = () => {
		const reportId = this.props.match.params.id;
		this.setState({ loading: true });
		let url = `/api/reports/${reportId}/tests?`;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						tests: result.data.labTests,
						loading: false
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						tests: result.data.labTests,
						loading: false
					});
				});
		}
	}

	filterTests = () => {
		this.loadTests();
	}

	loadLogs = () => {
		this.setState({ loading: true });

		const reportId = this.props.match.params.id;
		const userRole = cookies.get(cookies_key.user_role);
		const userCity = cookies.get(cookies_key.user_city);
		const userBarangay = cookies.get(cookies_key.user_barangay);

		let add_filter = "";

		if(userRole == 'City'){
			add_filter = `&report__address_city=${userCity}`;
		}else if(userRole == 'Barangay'){
			add_filter = `&report__address_city=${userCity}&report__address_barangay=${userBarangay}`;
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates?report_id=${reportId}${add_filter}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						logs: result.data.report_updates,
						loading: false
					});
					axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							const report = result.data;
							this.setState({
								report: report,
								report_id: reportId,
								name: report.name,
								age: report.age,
								sex: report.sex,
								patient_code: report.patient_code,
								date_responded: report.date_responded,
								loading: false
							});
							this.getQuarantineDays();
						});
				});
		} else {
			const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates?report_id=${reportId}${add_filter}` : `https://covidcheck.upcebu.edu.ph/api/reportUpdates?report_id=${reportId}${add_filter}`;
			const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(reportUpdatesUrl, {withCredentials: true})
				.then(result => {
					this.setState({
						logs: result.data.report_updates,
						loading: false
					});
					axios(reportsUrl, {withCredentials: true})
						.then(result => {
							const report = result.data;
							this.setState({
								report: report,
								report_id: reportId,
								name: report.name,
								age: report.age,
								sex: report.sex,
								patient_code: report.patient_code,
								date_responded: report.date_responded,
								loading: false
							});
							this.getQuarantineDays();
						});
				});
		}
	}

	componentDidMount(){
		window.scrollTo(0,0);
		this.setState({
			report_id: this.props.match.params.id
		});
		this.loadLogs();
		this.loadTests();
		const monitoringTab = cookies.get('monitoring_tab');
		if(monitoringTab){
			let tabValue = parseInt(monitoringTab);
			if(tabValue < 0 || tabValue > 1){
				tabValue = 0;
			}
			this.setState({tab: tabValue});
		}else{
			cookies.set('monitoring_tab', this.state.tab);
		}
	}

	handleCloseEntryStatus = () => {
		this.setState({ openEntryStatus: false });
	}

	handleAllowEntry = () => {
		this.setState({
			openEntryStatus: true,
			entryText: 'Allow Entry',
			entryStatusValue: 1
		});
	}

	handleDoNotAllowEntry = () => {
		this.setState({
			openEntryStatus: true,
			entryText: 'Do Not Allow Entry',
			entryStatusValue: 2
		});
	}

	handleConfirmEntryStatusUpdate = () => {
		const body = { "status" : this.state.entryStatusValue };
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						const report = this.state.report;
						report.status = this.state.entryStatusValue;
						this.setState({ report: report, openEntryStatus: false });
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report_id}`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						const report = this.state.report;
						report.status = this.state.entryStatusValue;
						this.setState({ report: report, openEntryStatus: false });
					}
				});
		}
	}

	handleOpenDelete = () => {
		if(this.state.selectedRows.length){
			this.setState({ openDelete: true });
		}
	}

	handleCloseDelete = () => {
		this.setState({ openDelete: false });
	}

	getQuarantineDays = () => {
		const time = '08:00';
		var dateResponded = new Date(this.state.date_responded);
		dateResponded = new Date(dateResponded.getFullYear(), dateResponded.getUTCMonth(),
								dateResponded.getUTCDate(), parseInt(time.split(':')[0]),
								 parseInt(time.split(':')[1]), 0);
		var today = new Date();
		today = new Date(today.getFullYear(), today.getUTCMonth(),
								today.getUTCDate(), parseInt(time.split(':')[0]),
								 parseInt(time.split(':')[1]), 0);
		const diffTime = today.getTime() - dateResponded.getTime();
		const days = Math.ceil(diffTime / (1000*3600*24));
		let color = '';
		if(days <= 5){
			color = '#ff1100';
		}else if(days > 5 && days <= 10){
			color = '#ffa600';
		}else if(days > 10 && days <= 14){
			color = '#d9ff00';
		}else{
			color = '#02b523';
		}
		this.setState({
			quarantine_days: days,
			quarantine_color: color
		});
	}

	monitoringToExcel = (logs) => {
		let data = [];
		logs.map((log) => {
			const xl = {};
			Object.assign(xl, log);
			const date = {"date": moment(log.date_created).format('L')}
			const time = {"time": moment(log.date_created).format('LT')}
			const symps = {"symptoms": symptoms(log.s_fever, log.s_cough, log.s_short_breath, log.s_fatigue, log.s_phlegm,
												log.s_muscle_joint_pain, log.s_sore_throat, log.s_headache, log.s_chills, log.s_vomiting,
												log.s_stuffed_nose, log.s_diarrhea, log.s_others)}
			data.push(Object.assign(xl, date, time, symps));
		});
		return data;
	}

	handleRowSelected = (selectedRows) => {
		let sRows = [];
		selectedRows.selectedRows.map(item => {
			sRows.push(item.id);
		});
		this.setState({ selectedRows : sRows });
	}

	handleDelete = () => {
		if(this.state.selectedRows.length){
			const length = this.state.selectedRows.length;
			let index = 0;
			this.state.selectedRows.map((row) => {
				if (process.env.NODE_ENV === 'development') {
					const username = 'testing@example.com';
					const password = 'p@ssw0rd';
					const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
					const header = { headers: {'Authorization': `Basic ${token}`} };

					axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates/${row}`, header)
						.then(result => {
							if(result.status === 200){
								this.loadLogs();
								// console.log('Health status with ID: '+row+' deleted');
							}
						}).catch((e) => {
							// console.log('Error deleting health status: '+e.message);
						});
				} else {
					const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates/${row}` : `https://covidcheck.upcebu.edu.ph/api/reportUpdates/${row}`;
					axios.delete(url, {withCredentials: true})
						.then(result => {
							if(result.status === 200){
								this.loadLogs();
								// console.log('Health status with ID: '+row+' deleted');
							}
						}).catch((e) => {
							// console.log('Error deleting health status: '+e.message);
						});
				}
				index = index + 1;
				if(index === length){
					this.setState({
						selectedRows: [],
						toggleClear: !this.state.toggleClear,
						openDelete: false
					});
				}
			});
		}
	}

	render(){
		const PrettoSlider = withStyles({
			root: {
				color: this.state.quarantine_color,
				height: 2,
				width: 300,
				marginTop: 15,
			},
				thumb: {
				height: 24,
				width: 24,
				backgroundColor: '#fff',
				border: '2px solid currentColor',
				marginTop: -8,
				marginLeft: -12,
				'&:focus, &:hover, &$active': {
				  boxShadow: 'inherit',
			},
			},
				active: {},
				valueLabel: {
				left: 'calc(-50% + 4px)',
			},
				track: {
				height: 8,
				borderRadius: 4,
			},
				rail: {
				height: 8,
				borderRadius: 4,
			},
		})(Slider);
		const marks = [{value: 	this.state.quarantine_days > 14 ? 14 : this.state.quarantine_days, label: `Day ${this.state.quarantine_days}`}];
		const conditionalRowStyles = [
			{ when: row => row.temperature >= 37,
				style: {
					backgroundColor: 'rgba(153,0,0,0.3)',
				},
			},
		];
		const columns = [
			{ name: 'Date', selector: 'date', sortabale: true, width: '200px',
				cell: row => <div align="center">{moment(row.date_created).format('lll')}</div>
			},
			{ name: 'Temperature', selector: 'temperature', sortabale: true, center:true, width: '120px',
			},
			{ name: 'Symptoms', selector: 'symptoms', wrap: true,
				cell: data => <div align="left">{symptoms(data.s_fever, data.s_cough, data.s_short_breath, data.s_fatigue, data.s_phlegm,
												data.s_muscle_joint_pain, data.s_sore_throat, data.s_headache, data.s_chills, data.s_vomiting,
												data.s_stuffed_nose, data.s_diarrhea, data.s_others)}</div>
			},
			{ name: 'Medications', selector: 'medications', wrap: true,
				cell: row => <div align="left">{row.medications}</div>
			},
			{ width: '100px', cell: item => <Button size='small'
													color='primary'
													component={Link}
													to={`/update-health-status/${item.id}`}
													startIcon={<SystemUpdateAltIcon/>}>
												Update
											</Button>,
				allowOverflow: true,
    			button: true,
			},
		];
		const data = this.state.logs.map(item => {
			let disabled = false;
			if(item.image_url === null || item.image_url === ''){
				disabled = true;
			}
			return { ...item, disabled };
		});

		const customStyles = {
			headCells: {
				style: {
				  color: '#202124',
				  fontSize: '12px',
				  fontWeight: 'bold',
				},
			},
		};

		const tests_columns = [
			{ cell: item => <TestActionMenu row={item} filterTests={this.filterTests}/>,
				allowOverflow: true,
				button: true,
				width: '15px',
			},
			{ name: 'Date', sortable: true, width: '150px',
				cell: item => item.date_time ? moment(item.date_time).format('L') : '',
			},
			{ name: 'Type of Test', selector: 'type', sortable: true, width: '250px',
			},
			{ name: 'Result', selector: 'result', wrap: true,
			},
			{ name: 'Remarks', selector: 'remarks', wrap: true,
			},
		];

		return(
			<MuiThemeProvider>
            	<React.Fragment>
            			<div>
            				<Paper style={{ padding : 20 }}>
								<div align='left'>
									<Typography component="h2" variant="h6" color="primary" gutterBottom>
										Health Monitoring
									</Typography>
									<Typography component="h1" variant="subtitle1" color="textPrimary" gutterBottom>
										{this.state.report.name}
									</Typography>
								</div>
								<Divider/>
								<Tabs
									value={this.state.tab}
									onChange={this.changeTab}
									indicatorColor="primary"
									textColor="primary"
								>
									<Tab label="Health Status"/>
									<Tab label="Laboratory Tests" />
								</Tabs>
								{this.state.tab === 0 ?
									<>
										<br/>
										<Grid container spacing={1}>
											<Grid item xs={12} md={6}>
												<div align='left'>
										        	<Button align='left' size="small" variant="outlined" startIcon={<ArrowBackIcon />}
										        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
										        			component={Link} to='/view-reports'>
									        			Back</Button>
												</div>
											</Grid>
											<Grid item xs={12} md={6}>
												<div align='right' style={{ paddingBottom: 10 }}>
													{(() => {
				      									if(this.state.selectedRows.length){
				      										return(
				      											<>
								      								<Button size="small"
																		style={{ color: 'red' }}
																		variant="outlined"
																		onClick={this.handleOpenDelete}
																		startIcon={<DeleteIcon />}
																		>
																		Delete
																	</Button>
																	<Dialog
														        		fullWidth={true}
														        		maxWidth={'xs'}
																		open={this.state.openDelete}
																		TransitionComponent={Transition}
																		keepMounted
																		onClose={this.handleCloseDelete}
																		aria-labelledby="alert-dialog-slide-title"
																		aria-describedby="alert-dialog-slide-description"
																	>
																		<DialogTitle id="alert-dialog-slide-title">Health Status</DialogTitle>
																		<DialogContent>
																			<DialogContentText id="alert-dialog-slide-description">
																				Delete selected health status?
																			</DialogContentText>
																		</DialogContent>
																		<DialogActions>
																			<Button onClick={this.handleCloseDelete} color="primary">
																				Cancel
																			</Button>
																			<Button onClick={this.handleDelete} color="secondary">
																				Confirm
																			</Button>
																		</DialogActions>
																	</Dialog>
																	&nbsp;&nbsp;&nbsp;
																</>
															);
														}
													})()}
													{this.state.report.status === 1 ?
														<>
															<Button size="small"
																	style={{ color: 'red' }}
																	variant="outlined"
																	startIcon={<DirectionsWalkIcon />}
																	onClick={this.handleDoNotAllowEntry}>
																Do Not Allow Entry
															</Button>
														</> :
														<>
															<Button size="small"
																	style={{ color: 'green' }}
																	variant="outlined"
																	startIcon={<DirectionsWalkIcon />}
																	onClick={this.handleAllowEntry}>
																Allow Entry
															</Button>
														</>
													}
													<Dialog
										        		fullWidth={true}
										        		maxWidth={'xs'}
														open={this.state.openEntryStatus}
														TransitionComponent={Transition}
														keepMounted
														onClose={this.handleCloseEntryStatus}
														aria-labelledby="alert-dialog-slide-title"
														aria-describedby="alert-dialog-slide-description"
													>
														<DialogTitle id="alert-dialog-slide-title">Update Entry Status</DialogTitle>
														<DialogContent>
															<DialogContentText id="alert-dialog-slide-description">
																{this.state.entryText} - {this.state.report.name}?
															</DialogContentText>
														</DialogContent>
														<DialogActions>
															<Button onClick={this.handleCloseEntryStatus} color="primary">
																Cancel
															</Button>
															<Button onClick={this.handleConfirmEntryStatusUpdate} color="secondary">
																Confirm
															</Button>
														</DialogActions>
													</Dialog>
													&nbsp;&nbsp;&nbsp;
				      								<Button size="small"
														color="primary"
														variant="outlined"
														startIcon={<AddIcon />}
														component={Link}
														to={`/add-health-status/${this.state.report_id}`}
														>
													Add Health Status
													</Button>
													{(() => {
														if(data.length){
															return(
																<>
																	&nbsp;&nbsp;&nbsp;
																	<Button size="small"
																		color="primary"
																		variant="outlined"
																		onClick={this.export}
																		startIcon={<GetAppIcon/>}
																		>
																		Export
																	</Button>
																	<ExcelExport
													                    data={this.monitoringToExcel(data)}
													                    fileName={this.state.name+"_"+this.state.sex+"_"+this.state.age+ "_Monitoring - "+moment(new Date()).format('L')+".xlsx"}
													                    ref={(exporter) => { this._exporter = exporter; }}
													                >
													                	<ExcelExportColumn field="date" title="Date" width={100} cellOptions={{ textAlign: 'center' }} />
																        <ExcelExportColumn field="time" title="Time" width={100} cellOptions={{ textAlign: 'center' }} />
																        <ExcelExportColumn field="temperature" title="Temperature" cellOptions={{ textAlign: 'center' }} />
																        <ExcelExportColumn field="symptoms" title="Symptoms" width={300} />
																        <ExcelExportColumn field="medications" title="Medications" width={300} />
													                </ExcelExport>
												                </>
										                	);
										            	}
									                })()}
												</div>
											</Grid>
										</Grid>
										<Divider/>
				            			<DataTable
				            				noHeader={true}
											columns={columns}
											data={data}
											selectableRows
											onSelectedRowsChange={this.handleRowSelected}
											clearSelectedRows={this.state.toggleClear}
											progressPending={this.state.loading}
											progressComponent={<TableLoader loadingText="Monitoring Logs"/>}
											conditionalRowStyles={conditionalRowStyles}
				      						pagination
				      						expandableRows
				      						expandableRowsDisabled={row => row.disabled}
				      						expandableRowsComponent={<ExpandedComponent />}
				      						customStyles={customStyles}
										/>
									</> : null}
									{this.state.tab === 1 ?
										<>
											<br/>
											<Grid container spacing={1}>
												<Grid item xs={12} md={6}>
													<div align='left'>
											        	<Button align='left' size="small" variant="outlined" startIcon={<ArrowBackIcon />}
											        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
											        			component={Link} to='/view-reports'>
										        			Back</Button>
													</div>
												</Grid>
												<Grid item xs={12} md={6}>
													<div align='right' style={{ paddingBottom: 10 }}>
														{this.state.report.status === 1 ?
															<>
																<Button size="small"
																		style={{ color: 'red' }}
																		variant="outlined"
																		startIcon={<DirectionsWalkIcon />}
																		onClick={this.handleDoNotAllowEntry}>
																	Do Not Allow Entry
																</Button>
															</> :
															<>
																<Button size="small"
																		style={{ color: 'green' }}
																		variant="outlined"
																		startIcon={<DirectionsWalkIcon />}
																		onClick={this.handleAllowEntry}>
																	Allow Entry
																</Button>
															</>
														}
														<Dialog
											        		fullWidth={true}
											        		maxWidth={'xs'}
															open={this.state.openEntryStatus}
															TransitionComponent={Transition}
															keepMounted
															onClose={this.handleCloseEntryStatus}
															aria-labelledby="alert-dialog-slide-title"
															aria-describedby="alert-dialog-slide-description"
														>
															<DialogTitle id="alert-dialog-slide-title">Update Entry Status</DialogTitle>
															<DialogContent>
																<DialogContentText id="alert-dialog-slide-description">
																	{this.state.entryText} - {this.state.report.name}?
																</DialogContentText>
															</DialogContent>
															<DialogActions>
																<Button onClick={this.handleCloseEntryStatus} color="primary">
																	Cancel
																</Button>
																<Button onClick={this.handleConfirmEntryStatusUpdate} color="secondary">
																	Confirm
																</Button>
															</DialogActions>
														</Dialog>
														&nbsp;&nbsp;&nbsp;
														<Button size="small"
															fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
															variant="outlined"
															color="primary"
															component={Link}
															to ={`/report-add-covid-test/${this.state.report.id}`}
															startIcon={<AddIcon />}>
																Add Lab. Test
														</Button>
													</div>
												</Grid>
											</Grid>
											<Divider/>
											<DataTable
												noHeader
												columns={tests_columns}
												data={this.state.tests}
												progressPending={this.state.loading}
												progressComponent={<TableLoader loadingText="Laboratory Tests"/>}
												pagination
												customStyles={customStyles}
											/>
										</> : null}
							</Paper>
						</div>
            	</React.Fragment>
            </MuiThemeProvider>
		);
	}
}

export default ViewPatientMonitoring;
