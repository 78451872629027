import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import sample_pdf from './components/Documents/Guidelines.pdf';
import help_img from './help_img.png';
import pubmat_img from './components/Documents/userguide_covidheck.png';
import { Divider, Typography } from '@material-ui/core';

import { Modal, createId, open, close } from "react-modal-syaku";
import "react-modal-syaku/dist/react-modal.css";

export class Help extends Component  {
	constructor(props) {
		super(props);

		this.id = createId();
	}

	onOpen(name) {
		this.setState({ [name]: true });
	}

	onClose(name) {
		this.setState({ [name]: false });
	}

	render () {
		return (
			<div className="wrapper_help">
				<br/><img class="help_img" src={help_img} /><br/><br/>
				
				<table className="videos-table">
					<tr valign="top"><td>
						<button
							type="button"
							className="btn-vid-1"
							onClick={() => open("isOpen1")}
						/>

						<Modal
							id="isOpen1"
							className="fontSize-20"
							style={{ color: "#fff", background: "gray"}}
							width={500}
						>
						  <div>
							<ReactPlayer
								url={"https://www.facebook.com/FirecheckProject/videos/1857448397719664/"}
								width='100%'
								height='100%'
								controls
								config={{
									facebook: {
										appId: '557979651509434'
									}
								}}	
							/>
						  </div>
						</Modal>
					</td><td>

						<button
							type="button"
							className="btn-vid-2"
							onClick={() => open("isOpen2")}
						/>

						<Modal
							id="isOpen2"
							className="fontSize-20"
							style={{ color: "#fff", background: "gray" }}
							width={500}
						>
							<div>
							<ReactPlayer
								url={"https://www.facebook.com/FirecheckProject/videos/1630359243780690/"}
								width='100%'
								height='100%'
								controls
								config={{
									facebook: {
										appId: '557979651509434'
									}
								}}
							/>
							</div>
						</Modal>
					</td></tr>
					<tr valign="top"><td>	
						<button
							type="button"
							className="btn-vid-3"
							onClick={() => open("isOpen3")}
						/>

						<Modal
							id="isOpen3"
							className="fontSize-20"
							style={{ color: "#fff", background: "gray" }}
							width={500}
						>
							<div>
							<ReactPlayer
								url={"https://www.facebook.com/FirecheckProject/videos/716886595723869/"}
								width='100%'
								height='100%'
								controls
								config={{
									facebook: {
										appId: '557979651509434'
									}
								}}
							/>
							</div>
						</Modal>
					</td><td>
								
						<button
							type="button"
							className="btn-vid-4"
							onClick={() => open("isOpen4")}
						/>

						<Modal
							id="isOpen4"
							className="fontSize-20"
							style={{ color: "#fff", background: "gray" }}
							width={500}
						>
							<div>
							<ReactPlayer
								url={"https://www.facebook.com/FirecheckProject/videos/261691105044138/"}
								width='100%'
								height='100%'
								controls
								config={{
									facebook: {
										appId: '557979651509434'
									}
								}}
							/>
							</div>
						</Modal>
					</td></tr>
				</table>

				<br/><br/>
				<img class="img-help" src={pubmat_img} />
				<br/><br/>
					
			</div>
		);
	}
}

export default Help;
