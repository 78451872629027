import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Checkbox from 'material-ui/Checkbox';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CreatableSelect from 'react-select/creatable';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import Select from 'react-select';
import NumberFormat from 'react-number-format';

import '../Forms.css';
import options_nationalities from '../Nationalities';
import options_provinces from '../Provinces';
import options_cities from '../Cities';
import options_barangays from '../Barangays';
import options_departments from '.././Departments';

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 5px;	

	td {
		padding: 5px;
	}
`;

class UpdatePersonalInfo extends Component {
	state = {
		report: {},
		selectedNationality: {},
		selectedProvince: {},
		selectedCity: {},
		selectedBarangay: {},
		selectedCompany:null,
		errorName: "",
		errorAge: "",
		errorContactNumber: "",
		company_suggestions: [],
		isLoading:false,
		reportId: this.props.match.params.id,
		loading:false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
					const initialNationality = { value: this.state.report.nationality, label: this.state.report.nationality }
					const inititalProvince = { value: this.state.report.address_province, label: this.state.report.address_province }
					const initialCity = { value: this.state.report.address_city, label: this.state.report.address_city }
					const initialBarangay = { value: this.state.report.address_barangay, label: this.state.report.address_barangay }
					this.setState({
						selectedNationality: initialNationality,
						selectedProvince: inititalProvince,
						selectedCity: initialCity,
						selectedBarangay: initialBarangay,
						selectedCompany: {label: this.state.report.company, value: this.state.report.company}
					});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
					const initialNationality = { value: this.state.report.nationality, label: this.state.report.nationality }
					const inititalProvince = { value: this.state.report.address_province, label: this.state.report.address_province }
					const initialCity = { value: this.state.report.address_city, label: this.state.report.address_city }
					const initialBarangay = { value: this.state.report.address_barangay, label: this.state.report.address_barangay }
					this.setState({
						selectedNationality: initialNationality,
						selectedProvince: inititalProvince,
						selectedCity: initialCity,
						selectedBarangay: initialBarangay,
						selectedCompany: {label: this.state.report.company, value: this.state.report.company}
					});
				});
		}

		
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			var val = ''
			var url = `/api/reports/companies?company=${val}`;
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						result.data.companies.forEach(element => {
							if(element != ''){
								this.setState({
									company_suggestions: this.state.company_suggestions.concat({label: element, value: element}),
								})
							}
						});
					}else{

					}
				})
				.catch(error => {
				})
		}else{
			var val = '';
			var url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 
			`https://covidcheck.upcebu.edu.ph/dev/api/reports/companies?company=${val}` : 
			`https://covidcheck.upcebu.edu.ph/api/reports/companies?company=${val}`;
			axios(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						result.data.companies.forEach(element => {
							if(element != ''){
								this.setState({
									company_suggestions: this.state.company_suggestions.concat({label: element, value: element}),
								})
							}
						});
					}else{
					}
				})
				.catch(error => {
				})
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		event.preventDefault();

		let valid = true;

		if(this.state.report.name == ""){
			valid = false;
			this.setState({ errorName: "Input is required" });
		}else{
			this.setState({ errorName: "" });
		}
		if(this.state.report.age != 0){
			const n = parseInt(this.state.report.age);
			if(n == 0){
				valid = false;
				this.setState({ errorAge: "Input is required" });
			}else{
				this.setState({ errorAge: "" });
			}
		}else{
			if(this.state.report.age_month != 0){
				const m = parseInt(this.state.report.age_month);
				if((m < 1 || m > 11)){
					valid = false;
					this.setState({ errorAge: "Only 1 to 11 values accepted for month" });
				}else{
					this.setState({ errorAge: "" });
				}
			}else{
				valid = false;
				this.setState({ errorAge: "Input is required" });
			}
		}

		if(valid){
			this.setState({ loading: true });
			const report = this.state.report;
			const reportId = report.id;

			report.age = report.age ? parseInt(report.age) : 0;
			report.age_month = report.age ? 0 : (report.age_month ? parseInt(report.age_month) : 0);

			const body = {
				name: report.name,
				age: report.age,
				age_month: report.age_month,
				sex: report.sex,
				nationality: report.nationality,
				company: report.company,
				address_province: report.address_province,
				address_city: report.address_city,
				address_barangay: report.address_barangay,
				address_sitio: report.address_sitio,
				address_street: report.address_street,
				contact_number: report.contact_number
			}

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.reportId}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}else{
							this.setState({ loading: false });
						}
					}).catch(() => {
						const r = this.state.report;
						r.id = reportId;
						this.setState({ 
							report: r,
							loading: false 
						});
					});
			} else {
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.reportId}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}else{
							this.setState({ loading: false });
						}
					}).catch(() => {
						const r = this.state.report;
						r.id = reportId;
						this.setState({ 
							report: r,
							loading: false 
						});
					});
			}
		}
	}

	handleChange = (newValue: any, actionMeta: any) => {
		var tempArr = [];
		var prevSelComp = this.state.selectedCompany;
		
		if(newValue != null){
			const report_s = this.state.report;
			
			tempArr.push({
				label: newValue.label,
				value: newValue.value
			});
			
			report_s['company'] = newValue.value;
			this.setState({ report: report_s });
			this.setState({selectedCompany: tempArr})
		}else{
			this.setState({selectedCompany: prevSelComp})
		}
	};
	
	
	handleCreate = (inputValue: any) => {
		const report_s = this.state.report;
		this.setState({ isLoading: true });
		setTimeout(() => {
		  const { company_suggestions } = this.state;
		  const newOption = this.createOption(inputValue);
		  report_s['company'] = newOption.value;
		  this.setState({
			isLoading: false,
			company_suggestions: [...company_suggestions, newOption],
			selectedCompany: newOption,
		  });
		}, 1000);
	  };

	createOption = (label: string) => ({
		label: label,
		value: label,
	});

	handleAgeYearChange = (values) => {
		const report = this.state.report;
		report.age = values.value;
		this.setState({ report });
	}

	handleAgeMonthChange = (values) => {
		const report = this.state.report;
		report.age_month = values.value;
		this.setState({ report });
	}

	handleDepartmentChange = selectedOption => {
        const report = this.state.report;
		report.department = selectedOption.value;
		this.setState({ report: report});
	};

	handleDesignationChange = event => {
		const report = this.state.report;
		report.designation = event.target.value;
		this.setState({ report: report });
	}

	handleFieldChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.value;
		this.setState({ report: report });
	}

	handleCheckChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.checked;
		this.setState({ report: report });
	}

	handleRadioChange = event => {
		const report = this.state.report;
		report.sex = event.target.value;
		this.setState({ report: report });
	}

	handleNationalityChange = selectedOption => {
        const report = this.state.report;
		report.nationality = selectedOption.value;
		this.setState({ report: report, selectedNationality: selectedOption });
	};

	handleProvinceChange = selectedOption => {
        const report = this.state.report;
		report.address_province = selectedOption.value;
		this.setState({ report: report, selectedProvince: selectedOption });
	};

	handleCityChange = selectedOption => {
        const report = this.state.report;
		report.address_city = selectedOption.value;
		this.setState({ report: report, selectedCity: selectedOption });
	};

	handleBarangayChange = selectedOption => {
        const report = this.state.report;
		report.address_barangay = selectedOption.value;
		this.setState({ report: report, selectedBarangay: selectedOption });
	};

	handleCompanyChange = input => {
		const report = this.state.report;
		report['company'] = input.target.value;
		this.setState({ report: report });
	}

	render() {
		const classes = {margin:1};
		const filtered_city_options = options_cities.filter((o) => o.link === this.state.selectedProvince.value)
		const filtered_barangay_options = options_barangays.filter((o) => o.link === this.state.selectedCity.value)
		const company_suggestions = [
			{ title: "firecheck", value: "firecheck" },
			{ title: "papercraft", value: "papercraft" }
		];
		return(
        	<div className="for_select" style={{ width: 350 }}>
				<Paper style = {{ padding: 10 }}>
					<Grid item xs={24} md={12} lg={12} xl={12}>
						<Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
							Update Patient Information
						</Typography>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Name
								</Typography>
								<TextField 
									value = {this.state.report.name}
									margin = "dense"
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={this.handleFieldChange('name')}
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorName}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Age
								</Typography>
								<Typography component="h1" variant="caption" color="textSecondary" align='left'>
									Year
								</Typography>
								<NumberFormat
									value={this.state.report.age}
									onValueChange={this.handleAgeYearChange}
									allowNegative={false}
									style={{ width: 300, height: 30, fontSize:18, paddingLeft:10 }}
									margin="dense"
								/>
								</td>
							</tr>
							{/* <tr>
								<td>
								{this.state.report.age == 0?
									<div>
									<Typography component="h1" variant="caption" color="textSecondary" align='left'>
										Month
									</Typography>
									<NumberFormat
										value={this.state.report.age_month}
										onValueChange={this.handleAgeMonthChange}
										allowNegative={false}
										style={{ width: 300, height: 30, fontSize:18, paddingLeft:10 }}
										margin="dense"
									/>
									</div>
									:null
								}
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorAge}</h4>
								</td>
							</tr> */}
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Sex
								</Typography>
								<FormControlLabel
									control={<Radio
										checked={this.state.report.sex === 'Female'}
										onChange={this.handleRadioChange}
										value="Female"
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Female' }}
									/>} 
									label="Female"
								/>
								&nbsp;&nbsp;&nbsp;
								<FormControlLabel
									control={<Radio
											checked={this.state.report.sex === 'Male'}
											onChange={this.handleRadioChange}
											value="Male"
											name="radio-button-male"
											color="primary" 
											inputProps={{ 'aria-label': 'Male' }}
										/>} 
									label="Male"
								/><br/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
										Nationality
									</Typography>
									<Select
										options={options_nationalities}
										value={this.state.selectedNationality }
										searchable
										placeholder="Select.."
										onChange={this.handleNationalityChange}
										styles={selectStyles}
									/>
									<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorNationality}</h4>
								</td>
							</tr>
							{/* <tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
									Company
								</Typography>
								<CreatableSelect
									isDisabled={this.state.isLoading}
									isLoading={this.state.isLoading}
									onChange={this.handleChange}
									onCreateOption={this.handleCreate}
									options={this.state.company_suggestions}
									value={this.state.selectedCompany}
									styles={selectStyles}
								/>
								</td>
							</tr> */}
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Designation
								</Typography>
								<FormControlLabel
									control={<Radio
										disabled = {this.state.isLoading}
										checked={this.state.report.designation === 'Staff'}
										onChange={this.handleDesignationChange}
										value="Staff"
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Staff' }}
									/>} 
									label="Staff"
								/>
								<FormControlLabel
									control={<Radio
										checked={this.state.report.designation === 'Faculty'}
										onChange={this.handleDesignationChange}
										value="Faculty"
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Faculty' }}
									/>} 
									label="Faculty"
								/>
								&nbsp;&nbsp;&nbsp;
								<FormControlLabel
								control={<Radio
										checked={this.state.report.designation === 'Student'}
										onChange={this.handleDesignationChange}
										value="Student"
										name="radio-button-male"
										inputProps={{ 'aria-label': 'Student' }}
									/>} 
								label="Student"
								/><br/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
										Unit/Office/Program
									</Typography>
									<Select
										options={options_departments}
										value={{value:this.state.report.department, label:this.state.report.department}}
										searchable
										placeholder="Select.."
										onChange={this.handleDepartmentChange}
										styles={selectStyles}
									/>
									<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorDepartment}</h4>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						<Typography component="h1" variant="subtitle1" color="primary" align='left' style={{paddingLeft:15, paddingTop:10}}>
							Address
						</Typography>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Select
									name="province"
									value={this.state.selectedProvince}
									onChange={this.handleProvinceChange}
									options={options_provinces}
									styles={selectStyles}
									placeholder="Province.."
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorProvince}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Select
									name="city"
									value={this.state.selectedCity}
									onChange={this.handleCityChange}
									options={filtered_city_options}
									styles={selectStyles}
									placeholder="City.."
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorCity}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Select
									name="barangay"
									value={this.state.selectedBarangay}
									onChange={this.handleBarangayChange}
									options={filtered_barangay_options}
									styles={selectStyles}
									placeholder="Barangay.."
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorBrgy}</h4>
									
								</td>
							</tr>
							<tr>
								<td>
								<TextField
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									label = "Sitio / Purok / Zone"
									onChange={this.handleFieldChange('address_sitio')}
									value = {this.state.report.address_sitio}
									margin="dense"
								/>
								</td>
							</tr>
							<tr>
								<td>
								<TextField
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									label = "Street"
									onChange={this.handleFieldChange('address_street')}
									value = {this.state.report.address_street}
									margin="dense"
								/>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Contact No.
								</Typography>
								<TextField
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={this.handleFieldChange('contact_number')}
									value = {this.state.report.contact_number}
									margin="dense"
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorContactNumber}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Email
								</Typography>
								<TextField
									disabled = {true}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={this.handleFieldChange('email')}
									value = {this.state.report.email}
									margin="dense"
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorEmail}</h4>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						<br/>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<div align='left'>
						        	<Button align='left' size="large" variant="outlined" color='secondary'
						        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
						        			onClick={this.handleBack}>
					        			CANCEL</Button>
								</div>
							</Grid>
							<Grid item xs={12} md={6}>
								<div align='right' style={{ paddingBottom: 10 }}>
									<Button
										fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
										size='large'
										variant='contained'
	                                    color='primary'
										onClick={this.handleConfirm}
										disabled = {this.state.loading}>
											{this.state.loading? "SAVING..":"SAVE"}
									</Button>
								</div>
							</Grid>
						</Grid>
						</Grid>
					</Paper>
				</div>
		);
	}
}

const selectStyles = {
	menu: base => ({
		...base,
		zIndex: 100
	})
};

export default UpdatePersonalInfo;
