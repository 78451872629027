import React from 'react';
import PeopleIcon from '@material-ui/icons/People';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const NumbersCard = (props) => {
	const value = props.value;
	const label = props.label;
	const color = props.color;

	return(
		<Box
			borderLeft={4}
			borderColor={color}
			borderRadius={5}
		>
		<Paper style={{padding: 5}} variant="elevation2" elevation={10}>
			<div className="card-title" align='left' >&nbsp;{label}</div>
			<Divider style={{ backgroundColor: '#f0f0f0'}}/>
			<div className="cc-content" align='center' style={{ color: color}}>
				{value}
			</div>
		</Paper>
		</Box>
	);
}

export default NumbersCard;