import React from 'react';
import logo from './logo.svg';
import './Intro.css';

function Intro() {
  return (
    <div>
      <h1>INTRO</h1>
    </div>
  );
}

export default Intro;

