import React from 'react';
import {Line} from 'react-chartjs-2';
import styled from 'styled-components';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const GraphStatusCard = (props) => {
	
	const state = {
	  labels: props.dates.map(a => a.label),
	  datasets: [{
		  label: "PUM",
		  fill: false,
		  lineTension: 0.1,
		  backgroundColor: "#8abcd0",
		  borderColor: "#8abcd0",
		  borderCapStyle: 'square',
		  borderDash: [],
		  borderDashOffset: 0.0,
		  borderJoinStyle: 'miter',
		  pointBorderColor: "#8abcd0",
		  pointBackgroundColor: "#8abcd0",
		  pointBorderWidth: 1,
		  pointHoverRadius: 2,
		  pointHoverBackgroundColor: "#8abcd0",
		  pointHoverBorderColor: "#8abcd0",
		  pointHoverBorderWidth: 2,
		  pointRadius: 1,
		  pointHitRadius: 10,
		  // notice the gap in the data and the spanGaps: false
		  data: props.pumData.map(a => a.value),
		  spanGaps: false,
		}, {
		  label: "SARI",
		  fill: false,
		  lineTension: 0.1,
		  backgroundColor: "#e56f66",
		  borderColor: "#e56f66", // The main line color
		  borderCapStyle: 'square',
		  borderDash: [], // try [5, 15] for instance
		  borderDashOffset: 0.0,
		  borderJoinStyle: 'miter',
		  pointBorderColor: "#e56f66",
		  pointBackgroundColor: "#e56f66",
		  pointBorderWidth: 0,
		  pointHoverRadius: 2,
		  pointHoverBackgroundColor: "#e56f66",
		  pointHoverBorderColor: "#e56f66",
		  pointHoverBorderWidth: 0,
		  pointRadius: 1,
		  pointHitRadius: 10,
		  // notice the gap in the data and the spanGaps: true
		  data: props.sariData.map(a => a.value),
		  spanGaps: true,
		},{
		  label: "ILI",
		  fill: false,
		  lineTension: 0.1,
		  backgroundColor: "#b270e5",
		  borderColor: "#b270e5", // The main line color
		  borderCapStyle: 'square',
		  borderDash: [], // try [5, 15] for instance
		  borderDashOffset: 0.0,
		  borderJoinStyle: 'miter',
		  pointBorderColor: "#b270e5",
		  pointBackgroundColor: "#b270e5",
		  pointBorderWidth: 0,
		  pointHoverRadius: 2,
		  pointHoverBackgroundColor: "#b270e5",
		  pointHoverBorderColor: "#b270e5",
		  pointHoverBorderWidth: 0,
		  pointRadius: 1,
		  pointHitRadius: 10,
		  // notice the gap in the data and the spanGaps: true
		  data: props.iliData.map(a => a.value),
		  spanGaps: true,
		}, {
		  label: "SAFE",
		  fill: false,
		  lineTension: 0.1,
		  backgroundColor: "#3AD5C1",
		  borderColor: "#3AD5C1",
		  borderCapStyle: 'square',
		  borderDash: [],
		  borderDashOffset: 0.0,
		  borderJoinStyle: 'miter',
		  pointBorderColor: "#3AD5C1",
		  pointBackgroundColor: "#3AD5C1",
		  pointBorderWidth: 1,
		  pointHoverRadius: 2,
		  pointHoverBackgroundColor: "#3AD5C1",
		  pointHoverBorderColor: "#3AD5C1",
		  pointHoverBorderWidth: 2,
		  pointRadius: 1,
		  pointHitRadius: 10,
		  // notice the gap in the data and the spanGaps: false
		  data: props.safeData.map(a => a.value),
		  spanGaps: false,
		}
	]
	  
	}
	
	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={10}>
			<div className="card-title" align='left' style={{paddingTop: 8, paddingBottom: 8 }}>REGISTERED PERSONNEL</div>
			<Divider style={{ backgroundColor: '#f0f0f0'}}/>
				<div align='center' className="dashboard-maxWidth" style={{ padding: 10 }}>
					<Line 
					  data={state}
					  options={{
						legend:{
						  position: 'right',
						  display:true,
						  labels: {
							  fontSize: 10,
							  boxWidth: 10
						  }
						},
						scales: {
							xAxes: [{
								gridLines: {
									drawOnChartArea: false
								},
								scaleLabel: {
									display: true,
									labelString: 'Date (MM/DD)',
								    fontColor: "#556CB3",
									padding: 5,
								    fontSize: 12
								},
								ticks: {
								    fontSize: 10,
									minRotation: 0,
									maxRotation: 0,
									autoSkip: true,
									maxTicksLimit: 5
								},
							}],
							yAxes: [{
								gridLines: {
									drawOnChartArea: true
								},
								scaleLabel: {
									display: true,
									labelString: 'Report Count',
								    fontColor: "#556CB3",
								    fontSize: 12
								},
								ticks: {
								    fontSize: 10,
									autoSkip: true,
									precision:0
								},
							}]
						}
					  }}
					/>
				</div>
		</Paper>
	);
}

export default GraphStatusCard;