import React, { Component } from 'react';
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class BoardMenu extends Component {
    state = {
        doctor: {},
        open: false,
    }

    componentWillReceiveProps({props}) {
      this.setState({open: this.props.drawerOpen ? false : this.state.open});
    }

    componentDidMount(){
        this.setState({open: !this.props.drawerOpen ? false : this.state.open});
        if (process.env.NODE_ENV === 'development') {
            const username = 'admin';
            const password = 'V09VL7';
            const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}`, {headers: {'Authorization': `Basic ${token}`}})
                .then(result => {
                    this.setState({
                        doctor: result.data
                    })
                });
        }else{
            const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
             `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${cookies.get('qb_id')}`;
            axios(url, {withCredentials: true})
                .then(result => {
                    this.setState({
                        doctor: result.data
                    })
                });
        }
    }

    handleOpenDashboard = () => {
        this.setState({ open: this.state.open ? false : true }, () => {
          if(this.state.open){
            if(!this.props.drawerOpen){
              this.props.handleDrawerOpen();
            }
          }
        });
    }

  render(){
    const userRole = cookies.get('qb_role');
    return(
      <>
        <Divider/>
        <List>
          {(() => {
            if(this.props.loggedIn){
              return(
                <>
                <div>
                { this.state.doctor.facilities && this.state.doctor.facilities.length <= 1 ?
                  <ListItem button component={Link} to='/qb/all'>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem> 
                : 
                <>

                    {this.state.doctor.facilities ?
                        <>
                            <ListItem button onClick={this.handleOpenDashboard}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                                &nbsp;&nbsp;
                                {this.state.open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                              <div className="facilities-list" style={{paddingLeft: 30}}>
                                    <Link style={{paddingBottom:10}} to={`/qb/all`}>
                                      <li button>
                                         ALL FACILITIES
                                      </li>
                                    </Link>
                                    {this.state.doctor.facilities.map(f => {
                                        return(
                                            <Link style={{paddingBottom:10}} to={`/qb/${f.id}`}>
                                              <li button>
                                                    {f.name.toUpperCase()}
                                              </li>
                                            </Link>
                                        );
                                    })}
                              </div>
                            </Collapse>
                        </>
                    : null}
                </> }
                  {(() => {
                    if(userRole === 'Admin'){
                      return(
                        <ListItem button component={Link} to='/qb-admit-patient'>
                          <ListItemIcon>
                            <PersonAddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Admit Patient" />
                        </ListItem>
                      );
                    }
                  })()}
                  <ListItem button component={Link} to='/qb-patients'>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Patients" />
                  </ListItem>
                  {(() => {
                    if(userRole === 'Coordinator'){
                      return(
                        <ListItem button component={Link} to='/qb-doctors'>
                          <ListItemIcon>
                            <LocalHospitalIcon />
                          </ListItemIcon>
                          <ListItemText primary="Users" />
                        </ListItem>
                      );
                    }
                  })()}
                </div>
                </>
              );
            }
          })()}
        </List>
      </>  
    );
  }
}

export default BoardMenu;