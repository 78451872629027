export default [
	{value:	'Accounting Office'	, label:	'Accounting Office'	},
	{value:	'Budget Office'	, label:	'Budget Office'	},
	{value:	'Campus Maintenance Office (CMO)'	, label:	'Campus Maintenance Office (CMO)'	},
	{value:	'Campus Safety & Security Office'	, label:	'Campus Safety & Security Office'	},
	{value:	'Canteen'	, label:	'Canteen'	},
	{value:	'Cash Office'	, label:	'Cash Office'	},
	{value:	'Center for Environmental Informatics in Central Visayas (CENVI)'	, label:	'Center for Environmental Informatics in Central Visayas (CENVI)'	},
	{value:	'Central Visayas Studies Center (CVSC)'	, label:	'Central Visayas Studies Center (CVSC)'	},
	{value:	'College of Communication, Art and Design (CCAD)'	, label:	'College of Communication, Art and Design (CCAD)'	},
	{value:	'College of Science'	, label:	'College of Science'	},
	{value:	'College of Social Sciences'	, label:	'College of Social Sciences'	},
	{value:	'FabLab'	, label:	'FabLab'	},
	{value:	'FireCheck'	, label:	'FireCheck'	},
	{value:	'Gender and Development (GAD)'	, label:	'Gender and Development (GAD)'	},
	{value:	'Health Services Unit (HSU)'	, label:	'Health Services Unit (HSU)'	},
	{value:	'Human Resource Development Office (HRDO)'	, label:	'Human Resource Development Office (HRDO)'	},
	{value:	'Information Technology Center (ITC)'	, label:	'Information Technology Center (ITC)'	},
	{value:	'Legal Office'	, label:	'Legal Office'	},
	{value:	'Library'	, label:	'Library'	},
	{value:	'National Service Training Program'	, label:	'National Service Training Program'	},
	{value:	'Office of the Campus Architect'	, label:	'Office of the Campus Architect'	},
	{value:	'Office of the Continuing Education and Pahinungod (OCEP)'	, label:	'Office of the Continuing Education and Pahinungod (OCEP)'	},
	{value:	'Office of the Chancellor'	, label:	'Office of the Chancellor'	},
	{value:	'Office of the Vice Chancellor for Academic Affairs (OVCAA)'	, label:	'Office of the Vice Chancellor for Academic Affairs (OVCAA)'	},
	{value:	'Office of the Vice Chancellor for Administration (OVCA)'	, label:	'Office of the Vice Chancellor for Administration (OVCA)'	},
	{value:	'Office of the University Registrar (OUR)'	, label:	'Office of the University Registrar (OUR)'	},
	{value:	'Office of Student Affairs (OSA)'	, label:	'Office of Student Affairs (OSA)'	},
	{value:	'Public Information Office (PIO)'	, label:	'Public Information Office (PIO)'	},
	{value:	'School of Management'	, label:	'School of Management'	},
	{value:	'Supply and Property Management Office (SPMO)'	, label:	'Supply and Property Management Office (SPMO)'	},
	{value:	'Teaching and Learning Resource Center (TLRC)'	, label:	'Teaching and Learning Resource Center (TLRC)'	},
	{value:	'Technology Business Incubator (TBI)'	, label:	'Technology Business Incubator (TBI)'	},
	{value:	'Technology Transfer and Business Development Office (TTBDO)'	, label:	'Technology Transfer and Business Development Office (TTBDO)'	},
	{value:	'Ugnayan ng Pahinungod / Oblation Corps'	, label:	'Ugnayan ng Pahinungod / Oblation Corps'	},
	{value:	'UP High School'	, label:	'UP High School'	},

];