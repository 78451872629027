import React from 'react';
import {Line} from 'react-chartjs-2';
import Chart from '@material-ui/icons/ShowChart';

import {
	Typography, Divider, Paper
} from '@material-ui/core';

const AdmittedPatientperDay = (props) => {
	const data = {
	  labels: props.dates.map(a => a.label),
	  datasets: [
		{
		  label: 'Patient Count',
		  fill: false,
		  lineTension: 0.1,
		  backgroundColor: '#303030',
		  borderColor: '#303030',
		  borderCapStyle: 'butt',
		  borderDash: [],
		  borderDashOffset: 0.0,
		  borderJoinStyle: 'miter',
		  pointBorderColor: '#303030',
		  pointBackgroundColor: '#303030',
		  pointBorderWidth: 1,
		  pointHoverRadius: 5,
		  pointHoverBackgroundColor: '#4C6FEE',
		  pointHoverBorderColor: '#4C6FEE',
		  pointHoverBorderWidth: 2,
		  pointRadius: 2,
		  pointHitRadius: 7,
		  data: props.data.map(a => a.value)
		}
	  ]
	};
	
	const data_today = props.data_today;
	
	function getDate() {
		var today = new Date();
		return today;
	};	
	
	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={5}>
			<div className="dashboard-maxWidth">
				<div className="card-title"><Chart className="svg_icons"/>&nbsp;PATIENTS ADMITTED PER DAY</div>
				<Divider/>
				<div style={{ paddingBottom: 5, paddingTop: 5 }}>
					<article className="canvas-graph-container">
						<Line
							data={data}
							options={{
								maintainAspectRatio: false,
								responsive: true,
								legend:{
								  display:false
								},
								scales: {
									xAxes: [{
										gridLines: {
											drawOnChartArea: false
										},
										scaleLabel: {
											display: false
										},
										ticks: {
											fontSize: 10,
											minRotation: 0,
											maxRotation: 0,
											autoSkip: false,
										},
									}],
									yAxes: [{
										gridLines: {
											drawOnChartArea: false
										},
										scaleLabel: {
											display: true,
											labelString: 'Patient Count',
											fontColor: "#4C6FEE",
											fontSize: 12
										},
										ticks: {
											fontSize: 10,
											beginAtZero: true,
											precision:0,
										},
									}]
								}
						  }}					
						/>
					</article>
				</div>
			</div>
			<div className="dashboard-minWidth">
				<Typography variant='overline' style={{ paddingLeft: 1, paddingBottom: 10 }}><Chart className="svg_icons"/>&nbsp;PATIENTS ADMITTED TODAY</Typography>
				<Divider/>
				<br/>
				<div align='center'>
					<Typography variant='h1' className="card-label" style={{ color: '#4C6FEE', paddingBottom: 20, paddingTop: 20  }}>{data_today}</Typography>
				</div>
				<br/>	
			</div>
		</Paper>
	);
}

export default AdmittedPatientperDay;