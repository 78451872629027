import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import '../Forms.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import axios from 'axios';
import { Divider, Typography } from '@material-ui/core';

export class UpdateLogin extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    state = {
        idErrorText: "",
        passErrorText: "",
        loginErrorText: "",
        valid:false,
        loading:false,
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        this.setState({
            loading: true,
        });
        e.preventDefault();
        this.state.valid = true;

        if(this.props.values.patient_code.trim() == ""){
            this.state.valid = false;
            this.setState({
                idErrorText: "Input is required",
            });
        }else{
            this.setState({
                idErrorText: "",
            });
        }

        if(this.props.values.verification_key.trim() == ""){
            this.state.valid = false;
            this.setState({
                passErrorText: "Input is required",
            });
        }else{
            this.setState({
                passErrorText: "",
            });
        }


        if(this.state.valid){
            if (process.env.NODE_ENV === 'development') {
                const username = this.props.values.patient_code.trim();
                const password = this.props.values.verification_key.trim();
                const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
                const head = {
                    'Authorization': `Basic ${token}`
                  }

                axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates/auth`, {headers: head})
                    .then(result => {
                        // console.log(result);
                        //set received result report id to state report id
                        this.setState({
                            loginErrorText: "",
                        });
                        if(result.data.report_id != null){
                            this.props.setReportID(result.data.report_id);
                        }

                        this.props.nextStep();
                    })
                    .catch(error =>{
                        this.setState({
                            loginErrorText: "Incorrect email or password",
                            loading: false,
                        });
                        // console.log(error);
                    })
            }else{
                const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates/auth` : `https://covidcheck.upcebu.edu.ph/api/reportUpdates/auth`;

                const username = this.props.values.patient_code.trim();
                const password = this.props.values.verification_key.trim();
                const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

				axios.get(reportUpdatesUrl, {
					auth: {
						username,
						password
					}
				})
                    .then(result => {
                        // console.log(result);
                        //set received result report id to state report id
                        this.setState({
                            loginErrorText: "",
                            loading: false,
                        });
                        if(result.data.report_id != null){
                            this.props.setReportID(result.data.report_id);
                        }

                        this.props.nextStep();
                    })
                    .catch(error =>{
                        this.setState({
                            loginErrorText: "Incorrect email or password",
                            loading: false,
                        });
                        // console.log(error);
                    })
            }
        }else{

            this.setState({
                loading: false,
            });
        }
    };

    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, handleChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
					<div className="wrapper_home">
						<div className="typo-divider">
							Send Update
							<br/><Typography variant="caption">To send health status for HSU monitoring</Typography>
						</div>
						<h3>Please enter your Email and Password</h3>
						<TextField
							floatingLabelText = "Email"
							onChange={handleChange('patient_code')}
							defaultValue = {values.patient_code}
							style={{ width: 300, marginLeft: 10, padding: 0}}
							margin="dense"
							errorText = {this.state.idErrorText}
						/>
						<br/>
						<TextField
							floatingLabelText = "Password"
							onChange={handleChange('verification_key')}
							type="password"
							defaultValue = {values.verification_key}
							style={{ width: 300, marginLeft: 10, padding: 0}}
                            margin="dense"
                            type = 'password'
							errorText = {this.state.passErrorText}
						/>
						<h4 style = {{fontSize: 12, color: "red"}}>{this.state.loginErrorText}</h4>
                        <br/>
                        <div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)'}}>
                            <br/>
                            <Link to = '/change-password' style={{ color: '#3AD5C1', fontSize: '14px'}}>
                                Change Password
                            </Link>
                            <br/>
                            <Link to = '/forgot-password' style={{ color: '#e6675c', fontSize: '14px', paddingTop: 10}}>
                                Forgot Password?
                            </Link>
                        </div>
                        <br/><br/>
						<div class="bot_nav">
							<Link to = '/'>
								<button class = "button-backchoice"
									primary = {true}>
										&#60; Back
								</button>
							</Link>
                            <button class = "button-nextchoice"
                                disabled = {this.state.loading}
								primary = {true}
								onClick = {this.continue}>
									{this.state.loading? "Authenticating..":"Continue"}
							</button>
							<br/><br/>
						</div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );

    }
}

export default UpdateLogin
