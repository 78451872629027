import React from 'react';
import PeopleIcon from '@material-ui/icons/People';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';
import styled from 'styled-components';

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const ReportsCard = (props) => {
	const selfReports = props.selfReports;
	const encoded = props.encoded;
	const responded = props.responded;

	return(
		<Paper>
			<Box
				borderLeft={2}
				borderColor={'#b472e5'}
				borderRadius={8}
			>
			<div align='left'>
				<Typography variant='overline' style={{ color: '#b472e5', paddingLeft: 10, paddingBottom: 5 }}>Total No. of Records</Typography>
			</div>
			<Divider/>
			<Table className="dashboard-table">
				<tbody>
	            	<tr>
	            		<td>
							<div align='center'>
								<Typography variant='h3' style={{ color: '#313336' }}>{selfReports+encoded}</Typography>
							</div>
						</td>
						<td>
	            			<div align='center' style={{ paddingLeft: 5, paddingBottom: 5 }}>
								<Typography variant='caption' style={{ color: '#b472e5' }}>Self-Reports</Typography>
								<Typography variant='h5' style={{ color: '#313336' }}>{selfReports}</Typography>
							</div>
						</td>
						<td>
							<div align='center'>
								<Typography variant='caption' style={{ color: '#b472e5' }}>Encoded</Typography>
								<Typography variant='h5' style={{ color: '#313336' }}>{encoded}</Typography>
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
			</Box>
		</Paper>
	);
}

export default ReportsCard;