import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const THEME = createMuiTheme({
  typography: {
    "lineHeight": 1.5,
    "letterSpacing": 0.32,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h1: {
      "fontWeight": 600,
	  "fontSize": 95,
    },
  },
});

const NumberOfPatients = (props) => {
	const numberOfpatients = props.numberOfpatients;
	
	return(
		
		<Box
			borderLeft={4}
			borderColor={'#4C6FEE'}
			borderRadius={5}
		>
			<Paper variant="elevation16" elevation={5} style={{padding: 5}} >
			<div className="card-title"><PeopleIcon className="svg_icons"/>&nbsp;PATIENTS</div>
			<Divider/>
			<br/>
			
			<div className="dashboard-maxWidth">
				<MuiThemeProvider theme={THEME}>
					<div className="card-content" align='center' style={{ color: '#4C6FEE' }}>
						{numberOfpatients}
					</div>
				</MuiThemeProvider>
				
			</div>
			<div className="dashboard-minWidth">
				<div align='center'>
					<MuiThemeProvider theme={THEME}>
						<Typography variant='h1' className="card-label" style={{ color: '#4C6FEE', paddingBottom: 20, paddingTop: 20  }}>{numberOfpatients}</Typography>
					</MuiThemeProvider>
				</div>
			</div>
			<br/>
			</Paper>
		</Box>
	);
}

export default NumberOfPatients;