import React from 'react';

import { Typography } from '@material-ui/core';

const ILIReclassPrompt = () => {
	return(
		<>
			<Typography variant='body1' style={{ color: '#242424' }} >Thank you for sending an update on your health status.</Typography>
			<Typography variant='body1' style={{ color: '#242424' }} >Based on your responses, you may be considered as a Person Presenting Influenza-like Illness (<b>PPI</b>).
				<br/>There is a need to monitor your health to ensure that the symptoms you are presenting are not related to COVID-19.
				<br/><br/>For now, you are advised to stay at home, rest and get better. You will be required to <b>send your health updates daily</b> through CovidCheck while you are at home.
				<br/><br/>Please contact your supervisor for alternative work assignments. There is no need to worry. You may call the UP Cebu Health Services Unit for assistance and instructions through this office number: 032 232 2642 local 305
				<br/><br/><b>Thank you</b> for cooperating with us in this fight against COVID-19.
			</Typography>
		</>
	);
}

export default ILIReclassPrompt;