import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';

import ChangePasswordComponent from './components/ChangePasswordComponent';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class ChangePassword extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			currPassword: '',
			password: '',
			confirmPassword: '',
			errors: '',
			success: false,
			successMsg: 'Your password has been changed successfully!',
		}
	}

	onSubmit = () => {
		if(this.state.currPassword.trim() && this.state.password.trim() && this.state.confirmPassword.trim()){
		}else{
			return this.setState({
				errors: 'Please fill-out all fields'
			});
		}

		if (this.state.password !== this.state.confirmPassword) {
			return this.setState({
				errors: 'New passwords do not match!'
			});
		}

		const changePwBody = {
			old_password: this.state.currPassword.trim(),
			new_password: this.state.password.trim()
		};

		if (process.env.NODE_ENV === 'development') {
			const username = cookies.get('user_email');
            const password = this.state.currPassword.trim();
            const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            const head = {'Authorization': `Basic ${token}`}
			axios.put('https://covidcheck.upcebu.edu.ph/dev/api/auth', 
					changePwBody, 
					{headers: head}).then(response => {
				this.setState({ success: true, errors: this.state.successMsg }, () => {
					setTimeout(() => {this.props.onSuccess();}, 3000);
				});
			}).catch(err => {
				this.setState({
					errors: 'Something went wrong.'
				});
			});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/auth' : 'https://covidcheck.upcebu.edu.ph/api/auth';
			axios.put(url, changePwBody, {withCredentials: true}).then(response => {
				this.setState({ success: true, errors: this.state.successMsg }, () => {
					setTimeout(() => {this.props.onSuccess();}, 3000);
				});
			}).catch(err => {
				this.setState({
					errors: 'Something went wrong.'
				});
			});
		}
	}

	onCurrPasswordChange = event => {
		this.setState({
			currPassword: event.target.value
		});
	}

	onPasswordChange = event => {
		this.setState({
			password: event.target.value
		});
	}

	onConfirmPasswordChange = event => {
		this.setState({
			confirmPassword: event.target.value
		});
	}

	sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	}

	render() {
		return (
			<MuiThemeProvider>
				<div className="wrapper_home">
					<div className="typo-divider">
						Change Password
					</div>
					<div className="wrapper_login">
						<ChangePasswordComponent
							success={this.state.success}
							successMsg={this.state.successMsg}
							onSubmit={this.onSubmit}
							onCurrPasswordChange={this.onCurrPasswordChange}
							onPasswordChange={this.onPasswordChange}
							onConfirmPasswordChange={this.onConfirmPasswordChange}
							errors={this.state.errors}
							password={this.state.password}
							confirmPassword={this.state.confirmPassword}
						/>
					</div>
				</div>
			</MuiThemeProvider>
		)
	}
}

export default ChangePassword;
