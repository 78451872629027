import React from 'react';

import { Typography } from '@material-ui/core';

const SAFEReclassPrompt = () => {
	return(
		<>
			<Typography variant='body1' style={{ color: '#242424' }} >Thank you for sending an update on your health status.</Typography>
			<Typography variant='body1' style={{ color: '#242424' }} >You have completed the recommended 14 days of active monitoring for Persons Presenting Influenza-like Illnesses (PPI).
				<br/><br/>Based on your latest responses, you are now at low risk to COVID-19.
				<br/><br/>Depending on the work arrangements with your unit or department, you may be allowed to report for work. 
				<br/><br/>There is still a need for you to remain vigilant and cautious to avoid being infected.
				<br/><br/><b>Thank you</b> for cooperating with us in this fight against COVID-19.
			</Typography>
		</>
	);
}

export default SAFEReclassPrompt;