import React from 'react';
import PeopleIcon from '@material-ui/icons/People';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const NumberOfPatientsBasedOnStatus = (props) => {
	const numberOfpatients = props.numberOfpatients;
	const status = props.status;
	const color = props.color;

	return(
		<Box
			borderLeft={4}
			borderColor={color}
			borderRadius={5}
		>
			<Paper variant="elevation16" elevation={5} style={{padding: 5}} >
			<div className="card-title"><PeopleIcon className="svg_icons"/>&nbsp;{status}</div>
			<Divider/>
			<br/>
			
			<div className="dashboard-maxWidth">
				<div className="card-content" align='center' style={{ color: color}}>
					{numberOfpatients}
				</div>
			</div>
			<div className="dashboard-minWidth">
				<div align='center'>
					<Typography variant='h1' className="card-label" style={{ color: color, paddingBottom: 20, paddingTop: 20  }}>{numberOfpatients}</Typography>
				</div>
			</div>
			
			<br/>
			</Paper>
		</Box>
	);
}

export default NumberOfPatientsBasedOnStatus;