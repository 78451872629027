import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {isMobile} from 'react-device-detect';

import DeleteIcon from '@material-ui/icons/Delete';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import ListIcon from '@material-ui/icons/List';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 5px;

	td {
		padding: 5px;
		vertical-align: top;
	}
`;

class PatientInfo extends Component {
	state = {
		patient: {admitting_doctor: {}, doctor: {}},
		openDelete: false,
		userRole: cookies.get('qb_role'),
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const patientId = this.props.match.params.id;

		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					// console.log(result.data);
					this.setState({ patient: result.data });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ patient: result.data });
				});
		}
	}

	handleOpenDelete = () => {
		this.setState({ openDelete: true });
	}

	handleCloseDelete = () => {
		this.setState({ openDelete: false });
	}

	handleDelete = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${this.state.patient.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/qb-patients');
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${this.state.patient.id}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${this.state.patient.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/qb-patients');
					}
				});
		}
	}

	render(){
		const typoFieldColor = 'textSecondary';
		const typoDataColor = 'textPrimary';
		const typoVariant = 'body2';
		return(
			<Paper style = {{ padding: 5 }}>
				<Table className='adPatient-table'>
					<tbody>
						<tr>
							<td colspan='2'>
								<Paper style={{ padding: 10 }}>
									<Grid container spacing={1} justify='center'>
										<Grid item xs={12} sm={2}>
								        	<Button size="small" variant="outlined" startIcon={<ArrowBackIcon />}
								        		fullWidth
								        		component={Link} to='/qb-patients'>
							        			Back</Button>
										</Grid>
										<Grid item xs={12} sm={2}>
											<Button size="small" color="primary" variant="outlined" startIcon={<ListIcon />}
												fullWidth
												component={Link} to={`/qb-patient-health-status/${this.state.patient.id}`}>
									        	Monitoring</Button>
										</Grid>
										<Grid item xs={12} sm={2}>
											<Button size="small" color="primary" variant="outlined" startIcon={<ListIcon />}
												fullWidth
												component={Link} to={`/qb-patient-lab-tests/${this.state.patient.id}`}>
									        	Lab. Tests</Button>
										</Grid>
										{(() => {
											if(this.state.userRole === 'Admin'){
												return(
													<>
													    <Grid item xs={12} sm={2}>
															<Button size="small" color="primary" variant="outlined" startIcon={<SystemUpdateAltIcon />}
																fullWidth
																component={Link} to={`/qb-update-patient/${this.state.patient.id}`}
																>
													        	Update</Button>
											        	</Grid>
									        			<Grid item xs={12} sm={2}>
															<Button onClick={this.handleOpenDelete} size="small" color="secondary" variant="outlined" startIcon={<DeleteIcon />}
																fullWidth>
													        	Delete</Button>
												        	<Dialog
												        		fullWidth={true}
												        		maxWidth={'xs'}
																open={this.state.openDelete}
																keepMounted
																onClose={this.handleCloseDelete}
																aria-labelledby="alert-dialog-slide-title"
																aria-describedby="alert-dialog-slide-description"
															>
																<DialogTitle id="alert-dialog-slide-title">Delete Patient</DialogTitle>
																<DialogContent>
																	<DialogContentText id="alert-dialog-slide-description">
																		Delete Patient - {this.state.patient.last_name}, {this.state.patient.first_name} {this.state.patient.middle_name}?
																	</DialogContentText>
																</DialogContent>
																<DialogActions>
																	<Button onClick={this.handleCloseDelete} color="primary">
																		Cancel
																	</Button>
																	<Button onClick={this.handleDelete} color="secondary">
																		Confirm
																	</Button>
																</DialogActions>
															</Dialog>
														</Grid>
										        	</>
												);
											}
										})()}
									</Grid>
								</Paper>
							</td>
						</tr>
		            	<tr>
		            		<td>
		            			<Paper style={{ padding: 10 }}>
							 	<Typography component="h2" variant="h6" color="primary" gutterBottom>
							 		Personal Information
						    	</Typography>
						    	<Divider/>
						    	<Table>
						    		<tbody>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Name : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.last_name}, {this.state.patient.first_name} {this.state.patient.middle_name}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Birthday : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.birthday ? moment(this.state.patient.birthday).format('L') : ''}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Sex : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.sex}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Contact # : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.contact_number}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Nationality : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.nationality}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Address : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.address_street}, {this.state.patient.address_sitio}, {this.state.patient.address_barangay}, {this.state.patient.address_city}, {this.state.patient.address_province}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Occupation : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.occupation}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Religion : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.religion}</Typography></td>
						    			</tr>
						    		</tbody>
						    	</Table>
						    	</Paper>
						    </td>
						    <td>
						    	<Paper style={{ padding: 10 }}>
								 	<Typography component="h2" variant="h6" color="primary" gutterBottom>
								 		Guardian
							    	</Typography>
							    	<Divider/>
							    	<Table>
							    		<tbody>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Name : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.guardian}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Address : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.guardian_address}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Contact # : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.guardian_contact_number}</Typography></td>
							    			</tr>
							    		</tbody>
							    	</Table>
							    	<Divider/>
							    	<Typography component="h2" variant="h6" color="primary" style={{ paddingTop: 10, paddingBottom: 8 }}>
								 		Medical Conditions
							    	</Typography>
							    	<Divider/>
							    	<Table>
							    		<tbody>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Medical Conditions : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.medical_conditions}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Medications : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.medications}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Family Medical History : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.family_medical_history}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Food Restrictions : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.food_restrictions}</Typography></td>
							    			</tr>
							    		</tbody>
							    	</Table>
						    	</Paper>
						    </td>
					    </tr>
						<tr>
		            		<td>
		            			<Paper style={{ padding: 10 }}>
								 	<Typography component="h2" variant="h6" color="primary" gutterBottom>
								 		Case Information
							    	</Typography>
							    	<Divider/>
							    	<Table>
							    		<tbody>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Case Number : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.case_number}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Date of Exposure : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.date_exposure ? moment(this.state.patient.date_exposure).format('L') : ''}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>History of Exposure : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.history}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Referring Facility : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.referring_facility}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Quarantine Facility : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.facility ? this.state.patient.facility.name : ''}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Doctor/Buddy : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.doctor.last_name}, {this.state.patient.doctor.first_name} {this.state.patient.doctor.middle_name}</Typography></td>
							    			</tr>
							    		</tbody>
							    	</Table>
							    	<Divider/>
							    	<Typography component="h2" variant="h6" color="primary" style={{ paddingTop: 10, paddingBottom: 8 }}>
								 		Testing Information
							    	</Typography>
							    	<Divider/>
							    	<Table>
							    		<tbody>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Date Tested : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.date_tested ? moment(this.state.patient.date_tested).format('L') : ''}</Typography></td>
							    			</tr>
							    			<tr>
							    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Result Disclosure : </Typography></td>
							    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.result_disclosure}</Typography></td>
							    			</tr>
							    		</tbody>
							    	</Table>
						    	</Paper>
						    </td>
						    <td>
						    	<Paper style={{ padding: 10 }}>
							 	<Typography component="h2" variant="h6" color="primary" gutterBottom>
							 		Admission Information
						    	</Typography>
						    	<Divider/>
						    	<Table>
						    		<tbody>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Date Admitted : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.date_admitted ? moment(this.state.patient.date_admitted).format('L') : ''}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Room/Bed Number : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.room_number}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Temperature : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.admission_temperature}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Blood Pressure : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.admission_blood_pressure}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Heart Rate : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.admission_heart_rate}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>O2 Saturation : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.admission_o2_saturation}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Symptoms : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.admission_symptoms}</Typography></td>
						    			</tr>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Admitting Doctor : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.admitting_doctor.last_name}, {this.state.patient.admitting_doctor.first_name} {this.state.patient.admitting_doctor.middle_name}</Typography></td>
						    			</tr>
						    		</tbody>
						    	</Table>
						    	<Divider/>
						    	<Typography component="h2" variant="h6" color="primary" style={{ paddingTop: 10, paddingBottom: 8 }}>
							 		Status
						    	</Typography>
						    	<Divider/>
						    	<Table>
						    		<tbody>
						    			<tr>
						    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Status : </Typography></td>
						    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.status}</Typography></td>
						    			</tr>
						    			{(() => {
						    				if(this.state.patient.status === 'Discharged' || this.state.patient.status === 'Recovered'){
						    					return(
						    						<>
						    							<tr>
										    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Date : </Typography></td>
										    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.date_status ? moment(this.state.patient.date_status).format('L') : ''}</Typography></td>
										    			</tr>
										    			<tr>
										    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Note : </Typography></td>
										    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.discharge_memo}</Typography></td>
										    			</tr>
						    						</>
						    					);
						    				}
						    			})()}
						    			{(() => {
						    				if(this.state.patient.status === 'Deceased'){
						    					return(
						    						<>
						    							<tr>
										    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Date/Time : </Typography></td>
										    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.date_status ? moment(this.state.patient.date_status).format('lll') : ''}</Typography></td>
										    			</tr>
										    			<tr>
										    				<td><Typography color={typoFieldColor} variant={typoVariant} noWrap>Cause of Death : </Typography></td>
										    				<td><Typography color={typoDataColor} variant={typoVariant}>{this.state.patient.cause_of_death}</Typography></td>
										    			</tr>
						    						</>
						    					);
						    				}
						    			})()}
						    		</tbody>
						    	</Table>
						    	</Paper>
						    </td>
					    </tr>
		         	</tbody>
				</Table>
			</Paper>
		);
	}
}

export default PatientInfo;
