import React, { Component } from 'react'
import axios from 'axios';
import SelfAssessment_Intro from './SelfAssessment_Intro';
import SelfAssessment_Terms from './SelfAssessment_Terms';
import SelfAssessment_Name from './SelfAssessment_Name';
import SelfAssessment_Age from './SelfAssessment_Age';
import SelfAssessment_Sex from './SelfAssessment_Sex';
import SelfAssessment_Email from './SelfAssessment_Email';
import SelfAssessment_Faculty_Student from './SelfAssessment_Faculty_Student';
import SelfAssessment_Unit_Department from './SelfAssessment_Unit_Department';
import SelfAssessment_Nationality from './SelfAssessment_Nationality';
import SelfAssessment_Address from './SelfAssessment_Address';
import SelfAssessment_Contacts from './SelfAssessment_Contacts';
import SelfAssessment_Symptoms_Details from './SelfAssessment_Symptoms_Details';
import SelfAssessment_Medical_Condition from './SelfAssessment_Medical_Condition';
import SelfAssessment_Symptoms_Check1 from './SelfAssessment_Symptoms_Check1';
import SelfAssessment_Symptoms_Check2 from './SelfAssessment_Symptoms_Check2';
import SelfAssessment_Result from './SelfAssessment_Result';
import Barangay_coords from '../lat_long';

export class UserForm extends Component {
    state = {
        step:1,

        name:'',
        age:'',
        age_month:'0',
        sex:'',
        email:'',
        faculty_student:'',
        unit_department:'',
        nationality:'',
        address_street:'',
        address_sitio:'',
        address_barangay:'',
        address_city:'',
        address_province:'',
        contact_number:'',
        s_fever:false,
        s_cough:false,
        s_short_breath:false,
        s_fatigue:false,
        s_phlegm:false,
        s_muscle_joint_pain:false,
        s_sore_throat:false,
        s_headache:false,
        s_chills:false,
        s_vomiting:false,
        s_stuffed_nose:false,
        s_diarrhea:false,
        classification:'',
        status:0,
        admit_quarantine_facility:'',
        date_testing:null,
        testing_center:'',
        deleted:false,

        address_latitude:10.3165,
        address_longitude:123.9652,

        has_ILIsymptoms:false,
        has_comorbidity:false,
        has_symptoms:false,
        has_visited_infected_areas:false,
        has_met_infected:false,

        m_cancer:false,
        m_tuberculosis:false,
        m_asthma:false,
        m_diabetes:false,
        m_high_blood_pressure:false,
        m_renal_failure:false,
        m_immunodeficiency_conditions:false,
        m_pregnancy:false,
        m_others:'',

        selected_nationality: null,
        selected_department: null,
        selected_street: null,
        selected_sitio: null,
        selected_barangay:null,
        selected_city: null,
        selected_province: null,

        zoom:14
    }

    //Proceed to the next Step
    nextStep = () => {
        const { step } = this.state;

        this.setState({
            step: step + 1
        });
    }
    //Go back to the previous Step
    prevStep = () => {
        const { step } = this.state;

        this.setState({
            step: step - 1
        });
    }

    //Proceed to the next Step
    changeStep = (value) => {
        this.setState({
            step: value
        });
    }

    //==================Sex Change===================
    sexMale = () => {
        this.setState({
            sex: 'Male',
            m_pregnancy: false,
        });
    }
    sexFemale = () => {
        this.setState({
            sex: 'Female',
        });
    }

    //---------------------------------------------------------------
    isFaculty = () => {
        this.setState({
            faculty_student: 'Faculty'
        });
    }
    isStudent = () => {
        this.setState({
            faculty_student: 'Student'
        });
    }
	isStaff = () => {
        this.setState({
            faculty_student: 'Staff'
        });
    }

    //Proceed to the next Step
    enterSymptomsCheck = () => {
        const { step } = this.state;

        this.setState({
            step: 10
        });
    }
    //Go back to the previous Step
    enterExposureCheck = () => {
        const { step } = this.state;

        this.setState({
            step: 13,
            has_symptoms: true
        });
    }

    //if user has symptoms
    confirmSymptoms = () => {
        const { step } = this.state;

        this.setState({
            step: step+1,
            has_symptoms: true
        });
    }

    //if user has no symptoms
    denySymptoms = () => {
        const { step } = this.state;

        this.setState({
            step: step+1,
            has_symptoms: false
        });
    }

    //check if symptoms are ILI
    hasILIsymptoms = () => {
        const { step } = this.state;
        if(this.state.s_fever && (this.state.s_cough || this.state.s_sore_throat)) {
            this.setState({
                step: 23, // has ILI
                has_ILIsymptoms: true
            });
        }else{
            this.setState({
                step: step + 1, //non ILI, check for PUM
                has_ILIsymptoms: false
            });
        }
    }

    prevStep_ILI = () => {
        const { step } = this.state;

        this.setState({
            step: 11
        });
    }

    prevStep_comorbidity = () => {
        const { step } = this.state;

        this.setState({
            step: 23
        });
    }

    result = () => {
        let classification = '';
        let save = false;
        if(this.state.has_symptoms){
            if(this.state.s_fever){ //ILI
				if(this.state.s_cough || this.state.s_sore_throat){
					this.setState({
						has_ILIsymptoms: true,
					});
					if(this.state.s_short_breath){ //SARI
						classification = 'SARI';
						this.setState({
							has_comorbidity: true,
						});
						save = true;
					}else{
						if(this.state.m_cancer || this.state.m_tuberculosis || this.state.m_asthma ||
							this.state.m_diabetes || this.state.m_high_blood_pressure || this.state.m_renal_failure ||
							this.state.m_immunodeficiency_conditions || this.state.m_pregnancy || this.state.m_others.trim()){ //ILI-2A
							//ILI-2A
							classification = 'ILI-2A';
							save = true;
							this.setState({
								has_comorbidity: true
							});
						}else{ //ILI-1
							classification = 'ILI-1';
							save = true;
						}
					}
				}else{
					this.setState({
						has_ILIsymptoms: false,
					});
					classification = 'ILI-1';
					save = true;
				}
            }else{ //NON-ILI
				//check for comorbidity
				if(this.state.m_cancer || this.state.m_tuberculosis || this.state.m_asthma ||
					this.state.m_diabetes || this.state.m_high_blood_pressure || this.state.m_renal_failure ||
					this.state.m_immunodeficiency_conditions || this.state.m_pregnancy || this.state.m_others.trim()){

					this.setState({
                            has_comorbidity: true
                        });
				}

                if(this.state.has_visited_infected_areas || this.state.has_met_infected){ //PUM
                    classification = 'PUM';
                    save = true;
                }else{
					classification = 'SAFE-2';
                    save = true;
                }
            }
        }else{
			//check for comorbidity
			if(this.state.m_cancer || this.state.m_tuberculosis || this.state.m_asthma ||
				this.state.m_diabetes || this.state.m_high_blood_pressure || this.state.m_renal_failure ||
				this.state.m_immunodeficiency_conditions || this.state.m_pregnancy || this.state.m_others.trim()){

				this.setState({
						has_comorbidity: true
					});
			}

            if(this.state.has_visited_infected_areas || this.state.has_met_infected){
                classification = 'PUM';
                save = true;
            }else{
                classification = 'SAFE-1';
                save = true;
            }
        }

        this.setState({
            classification: classification,
        }, () => {
            if(save){
                this.submitForm();
            }
            this.nextStep();
        });
    }

    //no exposure to places with case, first question before people exposure
	continueNotExposedPlace = () => {
		const { step } = this.state;

        this.setState({
            step: step + 1
        });
	}

    //with exposure to places with case
    confirmInfectedPlace = () => {
        const { step } = this.state;

        this.setState({
            has_visited_infected_areas: true
        }, () => {
            this.result();
        });
    }

    //no exposure to places with case
    denyInfectedPlace = () => {
        const { step } = this.state;

        this.setState({
            has_visited_infected_areas: false
        }, () => {
            this.result();
        });
    }


    //with exposure to people with case
    confirmInfectedPerson = () => {
        const { step } = this.state;

        this.setState({
            has_met_infected: true
        }, () => {
            this.result();
        });
    }

    //no exposure to people with case
    denyInfectedPerson = () => {
        const { step } = this.state;
        // console.log("denying infected person");
        this.setState({
            has_met_infected: false
        }, () => {
            this.result();
        });
    }

    //---------------------------Handle field change-------------------------------
    //handle textview input change
    handleChange = input => e => {
        this.setState({[input]: e.target.value.trim()});
    }
    //handle textview number input change
    handleNumberChange = input => e => {
        if(!e.target.value.includes('-')){
            this.setState({[input]: e.target.value.trim()});
        }else{
            this.setState({[input]: ''});
        }
    }
    //Handle checkbox input change
    handleCheckChange = name => e => {
        this.setState({[name]: e.target.checked});
    };
    //Handle slider input change
    handleSliderChange = input => event => {
        this.setState({ [input]: event.target.innerText });
    }
    //handle marker change
    handleCoordinateChange = (lat, lng, zm) => {
        this.setState({
            address_latitude: lat,
            address_longitude: lng,
            zoom: zm
        });
    }


	handleUnitDepartment = (dept, value) =>{
        this.setState({
            unit_department: dept,
            selected_department: value
        });
    }
    //handle dropdown nationality change
    handleNationality = (natio, value) => {
        this.setState({
            nationality: natio,
            selected_nationality: value
        })
    }
//-------------Handle Address Dropdown----------------
    handleStreet = (a, value) => {
        this.setState({
            address_street: a,
            selected_street: value
        })
    }
    handleSitio = (a, value) => {
        this.setState({
            address_sitio: a,
            selected_sitio: value
        })
    }
    handleBarangay = (a, value) => {
        const barangay = Barangay_coords.find( ({ link_barangay, link_city }) => link_barangay === value.value && link_city === this.state.address_city);
        this.setState({
            address_barangay: a,
            selected_barangay: value,
            address_latitude:barangay.lat,
            address_longitude:barangay.long
        })
    }
    handleCity = (a, value) => {
        //Set coordinates based on chosen city
        this.setState({
            address_city: a,
            selected_city: value
        })
    }

	handleProvince = (a, value) => {
        this.setState({
            address_province: a,
            selected_province: value
        })
    }
//----------------------------Results---------------------------------------

    //-------------Submit Form--------------

    submitForm = () => {
        const pass = {
            name:this.state.name,
            age:this.state.age,
            age:this.state.age,
            age_month:this.state.age_month,
            sex:this.state.sex,

            email:this.state.email,
            designation:this.state.faculty_student,
            department:this.state.unit_department,

            nationality:this.state.nationality,
            address_street:this.state.address_street,
            address_sitio:this.state.address_sitio,
            address_barangay:this.state.address_barangay,
            address_city:this.state.address_city,
            address_province:this.state.address_province,
            contact_number:this.state.contact_number,
            s_fever:this.state.s_fever,
            s_cough:this.state.s_cough,
            s_short_breath:this.state.s_short_breath,
            s_fatigue:this.state.s_fatigue,
            s_phlegm:this.state.s_phlegm,
            s_muscle_joint_pain:this.state.s_muscle_joint_pain,
            s_sore_throat:this.state.s_sore_throat,
            s_headache:this.state.s_headache,
            s_chills:this.state.s_chills,
            s_vomiting:this.state.s_vomiting,
            s_stuffed_nose:this.state.s_stuffed_nose,
            s_diarrhea:this.state.s_diarrhea,
            classification:this.state.classification,
            status:this.state.status,
            admit_quarantine_facility:this.state.admit_quarantine_facility,
            date_testing:this.state.date_testing,
            testing_center:this.state.testing_center,
            deleted:this.state.deleted,

            address_latitude:this.state.address_latitude,
            address_longitude:this.state.address_longitude,

            m_cancer:this.state.m_cancer,
            m_tuberculosis:this.state.m_tuberculosis,
            m_asthma:this.state.m_asthma,
            m_diabetes:this.state.m_diabetes,
            m_high_blood_pressure:this.state.m_high_blood_pressure,
            m_renal_failure:this.state.m_renal_failure,
            m_immunodeficiency_conditions:this.state.m_immunodeficiency_conditions,
            m_pregnancy:this.state.m_pregnancy,
            m_others:this.state.m_others,

            has_symptoms:this.state.has_symptoms,
            has_visited_infected_areas:this.state.has_visited_infected_areas,
            has_met_infected:this.state.has_met_infected,
			submitted_by: 'Self-report'
        }

        var xhr = new XMLHttpRequest()

        // xhr.open('POST', 'https://covidcheck.upcebu.edu.ph/api/reports');
        // xhr.send(JSON.stringify(this.props.values));
        if (process.env.NODE_ENV === 'development') {
            axios.post('https://covidcheck.upcebu.edu.ph/dev/api/reports', pass)
                .then(response => {

                })
                .catch(error => {

                })
        }else{
			const reportUrl = process.env.REACT_APP_COVCHECK_ENV  === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/reports' : 'https://covidcheck.upcebu.edu.ph/api/reports';
            axios.post(reportUrl, pass)
                .then(response => {

                })
                .catch(error => {

                })
        }
    }

    render() {
        const {step} = this.state;
        const values = this.state;

        switch(step){
            case 1:
                return (
                    <SelfAssessment_Intro
                        nextStep = {this.nextStep}
                        handleChange = {this.handleChange}
                    />
                )
            case 2:
                return (
                    <SelfAssessment_Terms
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        values={values}
                    />
                );
            case 3:
                return (
                    <SelfAssessment_Name
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 4:
                return (
                    <SelfAssessment_Age
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleNumberChange = {this.handleNumberChange}
                        values={values}
                    />
                );
            case 5:
                return (
                    <SelfAssessment_Sex
                        sexMale = {this.sexMale}
                        sexFemale = {this.sexFemale}
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 6:
                return (
                    <SelfAssessment_Email
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 7:
                return (
                    <SelfAssessment_Faculty_Student
                        isFaculty = {this.isFaculty}
                        isStudent = {this.isStudent}
                        isStaff = {this.isStaff}
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 8:
                return (
                    <SelfAssessment_Unit_Department
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleUnitDepartment = {this.handleUnitDepartment}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 9:
                return (
                    <SelfAssessment_Nationality
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleNationality = {this.handleNationality}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 10:
                return (
                    <SelfAssessment_Address
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        handleStreet = {this.handleStreet}
                        handleSitio = {this.handleSitio}
                        handleBarangay = {this.handleBarangay}
                        handleCity = {this.handleCity}
                        handleProvince = {this.handleProvince}
                        values={values}
                    />
                );
            case 11:
                return (
                    <SelfAssessment_Contacts
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                    />
                );
            case 12: //has_symptoms
                return (
                    <SelfAssessment_Symptoms_Details
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleCheckChange = {this.handleCheckChange}
                        confirmSymptoms = {this.confirmSymptoms}
                        denySymptoms = {this.denySymptoms}
                        values={values}
                    />
                );
            case 13:
                return (
                    <SelfAssessment_Medical_Condition
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        handleChange = {this.handleChange}
                        handleCheckChange = {this.handleCheckChange}
                        values={values}
                    />
                );
            case 14:
                return (
                    <SelfAssessment_Symptoms_Check1
                        confirmInfectedPlace = {this.confirmInfectedPlace}
                        denyInfectedPlace = {this.denyInfectedPlace}
                        result = {this.result}
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        values={values}
                    />
                );
            case 15:
                return(
                <SelfAssessment_Result
                    values={values}
                />
                );
        }
    }
}

export default UserForm
