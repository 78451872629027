import React from 'react';
import {UserForm} from './components/SelfAssessment/UserForm'
import {} from "material-ui";

import './Report.css';

function Report() {
  return (
    <div className = "App">
        <UserForm/>
    </div>
  );
}

export default Report;
