import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import './QB_Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'react-select';

import options_nationalities from '../Nationalities';
import options_provinces from '../Provinces';
import options_cities from '../Cities';
import options_barangays from '../Barangays';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const cookies_key = {
	user_role: 'qb_role',
	user_id: 'qb_id'
};

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 10px;

	td {
		padding: 5px;
	}
`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const BlueCheckbox = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyleRadio = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const customStyles = {
	control: (base, state) => ({
		...base,
		background: 'white',
		fontSize: 16,
		height: 56,
		borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
		"&:hover": {
		borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
		}
	}),
    menu: base => ({
		...base,
		zIndex: 100
	})
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4C6FEE',
    },
	'& .MuiInput-underline-root': {
		'& fieldset': {
			borderBottomColor: '#4C6FEE',
		},
		'&:after': {
		    borderBottomColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderBottomColor: '#4C6FEE',
		},
    },
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderColor: '#3AD5C1',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#4C6FEE',
		},
    },
  },
})(TextField);

const sex_options = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' }
]

const religion_options = [
	{ value: 'Roman Catholic', label: 'Roman Catholic' },
	{ value: 'Protestant Christianity', label: 'Protestant Christianity' },
	{ value: 'Islam', label: 'Islam' },
	{ value: 'N/A', label: 'N/A' }
]

class UpdatePatientInfo extends Component {
	state = {
        selectedOption: {},
		testing: {},
		testResult: {},
		disabled_Select: true,
		patient: {},
		doctors: [],
		searchValue: "",
		selectedSex: {},
		selectedNationality: {},
		selectedProvince: {},
		selectedCity: {},
		selectedBarangay: {},
		selectedReligion: {},
		selectedQF: {},
		selectedDoctor: {},
		selectedResultDisclosure: {},
		facilities: [],
		facilitiesOptions: [],
		time: '08:30',
		s_cough: false,
		s_colds: false,
		s_sorethroat: false,
		s_diarrhea: false,
		s_other: false,
		s_othersymptoms: "",
		m_cancer: false,
		m_tb: false,
		m_asthma: false,
		m_diabetes: false,
		m_highblood: false,
		m_immunodeficiency: false,
		m_renalfailure: false,
		m_other: false,
		m_othersymptoms: "",

		items: [],
		patient_history: "",
		item_fammedhist: [],
		patient_fammedhistory: "",
		qf_options: [],

		dateadmittedError: "",
		casenumberError: "",
		rfError: "",
		qfError: "",
		roomnumberError: "",
		lastnameError: "",
		firstnameError: "",
		birthdayError: "",
		sexError: "",
		contactnumberError: "",
		provinceError: "",
		cityError: "",
		barangayError: "",
		guardiannameError: "",
		guardiannumberError: "",
		datetestedError: "",
		testingError: "",
		adPatientError: "",
	}

	componentDidMount(){
		window.scrollTo(0,0);
		this.loadData_qf();
		const patientId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ patient: result.data }, () => {
						const initialNationality = { value: this.state.patient.nationality, label: this.state.patient.nationality }
						const inititalProvince = { value: this.state.patient.address_province, label: this.state.patient.address_province }
						const initialCity = { value: this.state.patient.address_city, label: this.state.patient.address_city }
						const initialBarangay = { value: this.state.patient.address_barangay, label: this.state.patient.address_barangay }
						const initialSex = { value: this.state.patient.sex, label: this.state.patient.sex }
						const initialReligion = { value: this.state.patient.religion, label: this.state.patient.religion }
						const initialDoctor = { value: this.state.patient.admitting_doctor_id, label: this.state.patient.admitting_doctor_id }
						const initialResultDisclosure = { value: this.state.patient.result_disclosure, label: this.state.patient.result_disclosure }
						const initialQF = { value: this.state.patient.facility_id, label: this.state.patient.quarantine_facility }

						this.setMedConditions(this.state.patient.medical_conditions);
						this.setMedHistory(this.state.patient.history);
						this.setFamMedHistory(this.state.patient.family_medical_history);

						var adSymptoms = this.state.patient.admission_symptoms;
						var adSymptoms_Arr = adSymptoms.split(',');

						var i;
						var tempStr2 = "";
						for(i=0;i<adSymptoms_Arr.length;i++) {
							var tempStr = adSymptoms_Arr[i].trim();

							if(tempStr == "Cough"){
								this.setState({ s_cough: true });
							}
							if(tempStr == "Colds"){
								this.setState({ s_colds: true });
							}
							if(tempStr == "Sore Throat"){
								this.setState({ s_sorethroat: true });
							}
							if(tempStr == "Diarrhea"){
								this.setState({ s_diarrhea: true });
							}

							if(tempStr != "Cough" && tempStr != "Colds" && tempStr != "Sore Throat" && tempStr != "Diarrhea") {
								if(tempStr !== "") {
									if(i < adSymptoms_Arr.length - 1) {
										tempStr2 = tempStr2 + tempStr + ", ";
									} else {
										tempStr2 = tempStr2 + tempStr;
									}
								}
							}
						}

						if(tempStr2 !== "") {
							this.setState({
								s_other: true,
								s_othersymptoms: tempStr2 });
						}

						var j;
						for(j=0;j<this.state.doctors.length;j++) {
							if(initialDoctor.value == this.state.doctors[j].value){
								this.setState({selectedDoctor: this.state.doctors[j]});
								break;
							}
						}

						this.setState({
							selectedSex: initialSex,
							selectedNationality: initialNationality,
							selectedProvince: inititalProvince,
							selectedCity: initialCity,
							selectedBarangay: initialBarangay,
							selectedReligion: initialReligion,
							selectedQF: initialQF,
							selectedResultDisclosure: initialResultDisclosure,
						});
					});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ patient: result.data }, () => {
						const initialNationality = { value: this.state.patient.nationality, label: this.state.patient.nationality }
						const inititalProvince = { value: this.state.patient.address_province, label: this.state.patient.address_province }
						const initialCity = { value: this.state.patient.address_city, label: this.state.patient.address_city }
						const initialBarangay = { value: this.state.patient.address_barangay, label: this.state.patient.address_barangay }
						const initialSex = { value: this.state.patient.sex, label: this.state.patient.sex }
						const initialReligion = { value: this.state.patient.religion, label: this.state.patient.religion }
						const initialDoctor = { value: this.state.patient.admitting_doctor_id, label: this.state.patient.admitting_doctor_id }
						const initialResultDisclosure = { value: this.state.patient.result_disclosure, label: this.state.patient.result_disclosure }
						const initialQF = { value: this.state.patient.facility_id, label: this.state.patient.quarantine_facility }

						this.setMedConditions(this.state.patient.medical_conditions);
						this.setMedHistory(this.state.patient.history);
						this.setFamMedHistory(this.state.patient.family_medical_history);

						var adSymptoms = this.state.patient.admission_symptoms;
						var adSymptoms_Arr = adSymptoms.split(',');

						var i;
						var tempStr2 = "";
						for(i=0;i<adSymptoms_Arr.length;i++) {
							var tempStr = adSymptoms_Arr[i].trim();
							if(tempStr == "Cough"){
								this.setState({ s_cough: true });
							}
							if(tempStr == "Colds"){
								this.setState({ s_colds: true });
							}
							if(tempStr == "Sore Throat"){
								this.setState({ s_sorethroat: true });
							}
							if(tempStr == "Diarrhea"){
								this.setState({ s_diarrhea: true });
							}

							if(tempStr != "Cough" && tempStr != "Colds" && tempStr != "Sore Throat" && tempStr != "Diarrhea") {
								if(tempStr !== "") {
									if(i < adSymptoms_Arr.length - 1) {
										tempStr2 = tempStr2 + tempStr + ", ";
									} else {
										tempStr2 = tempStr2 + tempStr;
									}
								}
							}
						}

						if(tempStr2 !== "") {
							this.setState({
								s_other: true,
								s_othersymptoms: tempStr2 });
						}

						var j;
						for(j=0;j<this.state.doctors.length;j++) {
							if(initialDoctor.value == this.state.doctors[j].value){
								this.setState({selectedDoctor: this.state.doctors[j]});
								break;
							}
						}

						this.setState({
							selectedSex: initialSex,
							selectedNationality: initialNationality,
							selectedProvince: inititalProvince,
							selectedCity: initialCity,
							selectedBarangay: initialBarangay,
							selectedReligion: initialReligion,
							selectedQF: initialQF,
							selectedResultDisclosure: initialResultDisclosure
						});
					});
				});
		}
	}

	handleFieldChange = field => event => {
		const patient = this.state.patient;
		patient[field] = event.target.value;
		this.setState({ patient: patient, searchValue: event.target.value });
	}

	handleTimeChange = event => {
		let time = event.target.value;
		if(time === null || time === ''){
			time = this.timeStr(new Date());
		}
		const health_status = this.state.health_status;
		const currDate = new Date(health_status.date_created);
		const newDate = new Date(currDate.getFullYear(), currDate.getUTCMonth(),
								currDate.getUTCDate(), parseInt(time.split(':')[0]),
								 parseInt(time.split(':')[1]), 0);
		health_status.date_created = newDate;
		this.setState({ health_status, time });
	}

	handleDateChange = (event) => {
		let date = event.target.value;
		if(date === null || date === ''){
			date = this.dateStr(new Date());
		}
		let currDate = this.state.health_status.date_created;
		if(currDate instanceof Date){
			currDate = this.state.health_status.date_created.toISOString();
		}
		const timeStr = currDate.split('T')[1];
		const health_status = this.state.health_status;
		health_status.date_created = date+'T'+timeStr;
		this.setState({ health_status });
	}

	dateStr = d => {
		if(d == null){
			d = new Date();
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	timeStr = d => {
		if(d == null){
			d = new Date();
		}
		// console.log(moment(d).format('LT'));
		let timeStr = moment(d).format('LT').split(' ')[0];
		const amPm = moment(d).format('LT').split(' ')[1];
		let hh = timeStr.split(':')[0];
		hh = hh <= 9 ? '0'+hh : hh;
		const mm = timeStr.split(':')[1];
		timeStr = hh+':'+mm;
		if(parseInt(hh) === 12 && parseInt(mm) === 0){
			return '24:00';
		}
		if(amPm === 'PM' || (amPm === 'AM' && parseInt(hh) === 12 && parseInt(mm) >= 0)){
			let adjustedHH = parseInt(hh) + 12;
			if(adjustedHH === 24 && parseInt(mm) && amPm === 'AM'){
				adjustedHH = 0;
			}
			timeStr = adjustedHH+':'+mm;
		}
		return timeStr;
	}

	handleQFChange = selectedOption  => {
		const patient = this.state.patient;
		patient.quarantine_facility = selectedOption.label;
		patient.facility_id = selectedOption.value;
		this.setState({ patient: patient, selectedQF: selectedOption });
	}

	handleSexChange = selectedOption  => {
		const patient = this.state.patient;
		patient.sex = selectedOption.value;
		this.setState({ patient: patient, selectedSex: selectedOption });
	}

	handleNationalityChange = selectedOption => {
        const patient = this.state.patient;
		patient.nationality = selectedOption.value;
		this.setState({ patient: patient, selectedNationality: selectedOption });
	};

	handleReligionChange = selectedOption => {
        const patient = this.state.patient;
		patient.religion = selectedOption.value;
		this.setState({ patient: patient, selectedReligion: selectedOption });
	};

	handleProvinceChange = selectedOption => {
        const patient = this.state.patient;
		patient.address_province = selectedOption.value;
		this.setState({ patient: patient, selectedProvince: selectedOption });
	};

	handleCityChange = selectedOption => {
        const patient = this.state.patient;
		patient.address_city = selectedOption.value;
		this.setState({ patient: patient, selectedCity: selectedOption });
	};

	handleBarangayChange = selectedOption => {
        const patient = this.state.patient;
		patient.address_barangay = selectedOption.value;
		this.setState({ patient: patient, selectedBarangay: selectedOption });
	};

	handleChange_testing = event => {
		const patient = this.state.patient;
		patient.result_disclosure = event.target.value;
		this.setState({selectedResultDisclosure: event.target.value});
		this.setState({ patient : patient });
	};

	handleCheckChange = name => e => {
        this.setState({[name]: e.target.checked});
    };

	handleChange_oSymptoms = input => e => {
        this.setState({[input]: e.target.value});
	}

	handleDoctor  = selectedOption  => {
		const patient = this.state.patient;
		patient.admitting_doctor_id = selectedOption.value;
		this.setState({ patient: patient, selectedDoctor: selectedOption });
	}

	handleKeyDown = evt => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();

			var value = this.state.patient_history.trim();

			if (value) {
				this.setState({
					items: [...this.state.items, this.state.patient_history],
					patient_history: ""
				});
			}
		}
	}

	handleChange = evt => {
		this.setState({
			patient_history: evt.target.value,
			error: null
		});
	};

	handleDelete = item => {
		this.setState({
			items: this.state.items.filter(i => i !== item)
		});
	};

	handleKeyDown_fam = evt => {
		if (["Enter", "Tab", ","].includes(evt.key)) {
			evt.preventDefault();

			var value = this.state.patient_fammedhistory.trim();

			if (value) {
				this.setState({
					item_fammedhist: [...this.state.item_fammedhist, this.state.patient_fammedhistory],
					patient_fammedhistory: ""
				});
			}
		}
	}

	handleChange_fam = evt => {
		this.setState({
			patient_fammedhistory: evt.target.value
		});
	};

	handleDelete_fam = item => {
		this.setState({
			item_fammedhist: this.state.item_fammedhist.filter(i => i !== item)
		});
	};

	saveHistory = string => {
		const patient = this.state.patient;
		patient.history = string;
		this.setState({ patient: patient });
	}

	compare(a, b) {
		const bandA = a.label.toUpperCase();
		const bandB = b.label.toUpperCase();

		let comparison = 0;
		if (bandA > bandB) {
			comparison = 1;
		} else if (bandA < bandB) {
			comparison = -1;
		}
		return comparison;
	}

	loadData_qf = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						facilities: result.data.facilities
					}, () => {
						this.filterFacilities();
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities` : `https://covidcheck.upcebu.edu.ph/api/qb/facilities`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						facilities: result.data.facilities
					}, () => {
						this.filterFacilities();
					});
				});
		}

	}

	filterFacilities = () => {
		const facilities = this.state.facilities;
		let newFacilities = [];
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					const userAssignedFacilities = result.data.assigned_facilities;
					facilities.map(f => {
						if(userAssignedFacilities.includes(f.id)){
							newFacilities.push({value: f.id, label: f.name});
						}
					});

				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${cookies.get('qb_id')}`;
			axios(address, {withCredentials: true})
				.then(result => {
					const userAssignedFacilities = result.data.assigned_facilities;
					facilities.map(f => {
						if(userAssignedFacilities.includes(f.id)){
							newFacilities.push({value: f.id, label: f.name});
						}
					});
				});
		}

		this.setState({
			facilitiesOptions: newFacilities
		});


	}

	handleSave = e => {
        e.preventDefault();

		let valid = true;
		if(this.state.patient.date_admitted == ""){
			valid = false;
			this.setState({ dateadmittedError: "Input is required" });
		}else{
			this.setState({ dateadmittedError: "" });
		}

		if(this.state.patient.case_number == ""){
			valid = false;
			this.setState({ casenumberError: "Input is required" });
		}else{
			this.setState({ casenumberError: "" });
		}

		if(this.state.patient.referring_facility == ""){
			valid = false;
			this.setState({ rfError: "Input is required" });
		}else{
			this.setState({ rfError: "" });
		}

		if(this.state.patient.quarantine_facility == ""){
			valid = false;
			this.setState({ qfError: "Input is required" });
		}else{
			this.setState({ qfError: "" });
		}

		if(this.state.patient.room_number == ""){
			valid = false;
			this.setState({ roomnumberError: "Input is required" });
		}else{
			this.setState({ roomnumberError: "" });
		}

		if(this.state.patient.last_name == ""){
			valid = false;
			this.setState({ lastnameError: "Input is required" });
		}else{
			this.setState({ lastnameError: "" });
		}

		if(this.state.patient.first_name == ""){
			valid = false;
			this.setState({ firstnameError: "Input is required" });
		}else{
			this.setState({ firstnameError: "" });
		}

		if(this.state.patient.birthday == ""){
			valid = false;
			this.setState({ birthdayError: "Input is required" });
		}else{
			this.setState({ birthdayError: "" });
		}

		if(this.state.patient.sex == ""){
			valid = false;
			this.setState({ sexError: "Input is required" });
		}else{
			this.setState({ sexError: "" });
		}

		if(this.state.patient.contact_number == ""){
			valid = false;
			this.setState({ contactnumberError: "Input is required" });
		}else{
			this.setState({ contactnumberError: "" });
		}

		if(this.state.patient.address_province == ""){
			valid = false;
			this.setState({ provinceError: "Input is required" });
		}else{
			this.setState({ provinceError: "" });
		}

		if(this.state.patient.address_city == ""){
			valid = false;
			this.setState({ cityError: "Input is required" });
		}else{
			this.setState({ cityError: "" });
		}

		if(this.state.patient.address_barangay == ""){
			valid = false;
			this.setState({ barangayError: "Input is required" });
		}else{
			this.setState({ barangayError: "" });
		}

		if(this.state.patient.guardian == ""){
			valid = false;
			this.setState({ guardiannameError: "Input is required" });
		}else{
			this.setState({ guardiannameError: "" });
		}

		if(this.state.patient.guardian_contact_number == ""){
			valid = false;
			this.setState({ guardiannumberError: "Input is required" });
		}else{
			this.setState({ guardiannumberError: "" });
		}

		if(this.state.patient.date_tested == ""){
			valid = false;
			this.setState({ datetestedError: "Input is required" });
		}else{
			this.setState({ datetestedError: "" });
		}

		if(this.state.patient.result_disclosure == ""){
			valid = false;
			this.setState({ testingError: "Input is required" });
		}else{
			this.setState({ testingError: "" });
		}


		if(valid) {
			var adSymptoms = "";
			if(this.state.s_cough || this.state.s_colds || this.state.s_sorethroat || this.state.s_diarrhea || this.state.s_other){

				if(this.state.s_cough) {
					var cough = "Cough";
					adSymptoms = adSymptoms + cough + ", ";
				}
				if(this.state.s_colds) {
					var colds = "Colds";
					adSymptoms = adSymptoms + colds + ", ";
				}
				if(this.state.s_sorethroat) {
					var sorethroat = "Sore Throat";
					adSymptoms = adSymptoms + sorethroat + ", ";
				}
				if(this.state.s_diarrhea) {
					var diarrhea = "Diarrhea";
					adSymptoms = adSymptoms + diarrhea + ", ";
				}
				if(this.state.s_other) {
					adSymptoms = adSymptoms + this.state.s_othersymptoms;
				}

			}
			this.state.patient.admission_symptoms = adSymptoms;

			const patient = this.state.patient;
			if(patient.date_admitted instanceof Date){
				patient.date_admitted = patient.date_admitted.toISOString();
			}
			if(patient.date_exposure instanceof Date){
				patient.date_exposure = patient.date_exposure.toISOString();
			}
			if(patient.date_tested instanceof Date){
				patient.date_tested = patient.date_tested.toISOString();
			}
			if(patient.birthday instanceof Date){
				patient.birthday = patient.birthday.toISOString();
			}

			var i;
			var patient_hist_list = "";

			if(this.state.items.length != 0){
				for(i=0;i<this.state.items.length;i++) {
					if(i != this.state.items.length-1) {
						patient_hist_list = patient_hist_list + this.state.items[i] + ", ";
					}else{
						patient_hist_list = patient_hist_list + this.state.items[i];
					}
				}
			}

			var i;
			var patient_fammedhist_list = "";
			if(this.state.item_fammedhist.length != 0){
				for(i=0;i<this.state.item_fammedhist.length;i++) {
					if(i != this.state.item_fammedhist.length-1) {
						patient_fammedhist_list = patient_fammedhist_list + this.state.item_fammedhist[i] + ", ";
					}else{
						patient_fammedhist_list = patient_fammedhist_list + this.state.item_fammedhist[i];
					}
				}
			}

			this.saveMedConditions();
			this.saveHistory(patient_hist_list);
			this.saveFamMedHistory(patient_fammedhist_list);

			patient.admission_heart_rate = patient.admission_heart_rate ? parseInt(patient.admission_heart_rate) : null;
			patient.admission_o2_saturation = patient.admission_o2_saturation ? parseFloat(patient.admission_o2_saturation) : null;

			const patientId = patient.id;
			delete patient.id;

			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, patient, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/qb-patients');
						}
					}).catch(() => {
						const pat = this.state.patient;
						pat.id = patientId;
						this.setState({ patient: pat });
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
				axios.put(url, patient, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/qb-patients');
						}
					}).catch(() => {
						const pat = this.state.patient;
						pat.id = patientId;
						this.setState({ patient: pat });
					});
			}

		} else {
			this.setState({ adPatientError: "*Review required fields" });
		}
    };

	saveHistory = string => {
		const patient = this.state.patient;
		patient.history = string;
		this.setState({ patient: patient });
	}

	saveFamMedHistory = string => {
		const patient = this.state.patient;
		patient.family_medical_history = string;
		this.setState({ patient: patient });
	}

	saveMedConditions = () => {
		var adSymptoms = "";
		if(this.state.m_cancer || this.state.m_tb || this.state.m_asthma || this.state.m_diabetes || this.state.m_highblood || this.state.m_immunodeficiency || this.state.m_other){

			if(this.state.m_cancer) {
				var cancer = "Cancer";
				adSymptoms = adSymptoms + cancer + ", ";
			}
			if(this.state.m_tb) {
				var tb = "Tuberculosis";
				adSymptoms = adSymptoms + tb + ", ";
			}
			if(this.state.m_asthma) {
				var asthma = "Asthma";
				adSymptoms = adSymptoms + asthma + ", ";
			}
			if(this.state.m_diabetes) {
				var diabetes = "Diabetes";
				adSymptoms = adSymptoms + diabetes + ", ";
			}
			if(this.state.m_highblood) {
				var highblood = "High Blood";
				adSymptoms = adSymptoms + highblood + ", ";
			}
			if(this.state.m_immunodeficiency) {
				var immunodeficiency = "Immunodeficiency Conditions";
				adSymptoms = adSymptoms + immunodeficiency + ", ";
			}
			if(this.state.m_other) {
				adSymptoms = adSymptoms + this.state.m_othersymptoms;
			}

		}

		const patient = this.state.patient;
		patient.medical_conditions = adSymptoms;
		this.setState({ patient: patient });
	}

	setMedConditions = string => {
		if(string) {
			var medConditions_Arr = string.split(',');
			var tempStr2 = "";
			var i;
			for(i=0;i<medConditions_Arr.length;i++) {
				var tempStr = medConditions_Arr[i].trim();
				if(tempStr == "Cancer"){
					this.setState({ m_cancer: true });
				}
				if(tempStr == "Diabetes"){
					this.setState({ m_diabetes: true });
				}
				if(tempStr == "Immunodeficiency Conditions"){
					this.setState({ m_immunodeficiency: true });
				}
				if(tempStr == "Tuberculosis"){
					this.setState({ m_tb: true });
				}
				if(tempStr == "High Blood"){
					this.setState({ m_highblood: true });
				}
				if(tempStr == "Asthma"){
					this.setState({ m_asthma: true });
				}


				if(tempStr != "Cancer" && tempStr != "Diabetes" && tempStr != "Immunodeficiency Conditions" && tempStr != "Tuberculosis" && tempStr != "High Blood" && tempStr != "Asthma") {
					if(tempStr !== "") {
						if(i < medConditions_Arr.length - 1) {
							tempStr2 = tempStr2 + tempStr + ", ";
						} else {
							tempStr2 = tempStr2 + tempStr;
						}
					}
				}

			}

			if(tempStr2 !== "") {
				this.setState({
					m_other: true,
					m_othersymptoms: tempStr2 });
			}
		}

	}

	setMedHistory = string => {
		if(string) {
			if(string.length > 0){
				var medHistory_Arr = string.split(',');
				var items_Arr = [];
				var i;
				for(i=0;i<medHistory_Arr.length;i++) {
					var tempStr = medHistory_Arr[i].trim();
					items_Arr.push(tempStr);
				}

				this.setState({ items: items_Arr });
			}
		}
	}

	setFamMedHistory = string => {
		if(string) {
			if(string.length > 0){
				var fammedHistory_Arr = string.split(',');
				var items_Arr = [];
				var i;
				for(i=0;i<fammedHistory_Arr.length;i++) {
					var tempStr = fammedHistory_Arr[i].trim();
					items_Arr.push(tempStr);
				}

				this.setState({ item_fammedhist: items_Arr });
			}
		}
	}

    render() {
		const classes = {margin:1};
		let filtered_city_options = [];
		let filtered_barangay_options = [];
		if(this.state.selectedProvince){
			filtered_city_options = options_cities.filter((o) => o.link === this.state.selectedProvince.value)
		}
		if(this.state.selectedCity){
			filtered_barangay_options = options_barangays.filter((o) => o.link === this.state.selectedCity.value)
		}
        return (
			<Paper style = {{ padding: 10 }}>
				<Grid item xs={24} md={12} lg={12} xl={12}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Quarantine Information
					</Typography>

					<Table className="adPatient-table">
					    <tbody>
						<tr valign="top">
							<td>
								<CssTextField
									onChange={this.handleFieldChange('date_admitted')}
									value = {this.dateStr(new Date(this.state.patient.date_admitted))}
									type='date'
									id="outlined-basic"
									label="Date Admitted"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									error = {this.state.dateadmittedError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.dateadmittedError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('case_number')}
									value = {this.state.patient.case_number}
									disabled
									id="outlined-basic"
									label="Case Number"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									error = {this.state.casenumberError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.casenumberError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('referring_facility')}
									value = {this.state.patient.referring_facility}
									id="outlined-basic"
									label="Referring Facility"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									error = {this.state.rfError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.rfError}</h7>
							</td>
							<td>
								<Select
									styles={customStyles}
									options={this.state.facilitiesOptions}
									value={this.state.selectedQF}
									searchable
									placeholder={this.state.qfError !== "" ? 'Please select facility' : 'Quarantine Facility '}
									fullWidth={true}
									onChange={this.handleQFChange}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.qfError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('room_number')}
									value = {this.state.patient.room_number}
									id="outlined-basic"
									label="Room/Bed Number"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									error = {this.state.roomnumberError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red", textAlign: "center"}}>{this.state.roomnumberError}</h7>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Personal Information
					</Typography>
					<Table className="adPatient-table">
					    <tbody>
						<tr valign="top">
							<td>
								<CssTextField
									onChange={this.handleFieldChange('last_name')}
									value = {this.state.patient.last_name}
									id="outlined-basic"
									label="Last Name"
									variant="outlined"
									fullWidth={true}
									error = {this.state.lastnameError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.lastnameError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('first_name')}
									value = {this.state.patient.first_name}
									id="outlined-basic"
									label="First Name"
									variant="outlined"
									fullWidth={true}
									error = {this.state.firstnameError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.firstnameError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('middle_name')}
									value = {this.state.patient.middle_name}
									id="outlined-basic"
									label="Middle Name"
									variant="outlined"
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('birthday')}
									value = {this.dateStr(new Date(this.state.patient.birthday))}
									type='date'
									id="outlined-basic"
									label="Birthday"
									variant="outlined"
									fullWidth={true}
									error = {this.state.birthdayError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.birthdayError}</h7>
							</td>
							<td>
								<Select
									styles={customStyles}
									options={sex_options}
									value={this.state.selectedSex}
									searchable
									fullWidth={true}
									onChange={this.handleSexChange}
									placeholder={this.state.sexError !== "" ? 'Please select gender' : 'Sex '}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.sexError}</h7>
							</td>
						</tr>
						<tr valign="top">
							<td>
								<CssTextField
									onChange={this.handleFieldChange('contact_number')}
									value = {this.state.patient.contact_number}
									type='number'
									id="outlined-basic"
									label="Contact Number"
									variant="outlined"
									fullWidth={true}
									error = {this.state.contactnumberError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.contactnumberError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('occupation')}
									value = {this.state.patient.occupation}
									id="outlined-basic"
									label="Occupation"
									variant="outlined"
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
							<td>
								<Select
									styles={customStyles}
									options={options_nationalities}
									value={this.state.selectedNationality}
									searchable
									placeholder="Nationality"
									fullWidth={true}
									onChange={this.handleNationalityChange}
								/>
							</td>
							<td>
								<Select
									styles={customStyles}
									options={religion_options}
									value={this.state.selectedReligion}
									searchable
									placeholder="Religion"
									fullWidth={true}
									onChange={this.handleReligionChange}
								/>
							</td>
						</tr>


						</tbody>
						</Table>
						<Typography component="h1" variant="subtitle1" color="textSecondary" style={{ paddingLeft: 20 }}>
							Address
						</Typography>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
									<Select
										styles={customStyles}
										options={options_provinces}
										value={this.state.selectedProvince}
										searchable
										fullWidth={true}
										onChange={this.handleProvinceChange}
										menuPlacement="auto"
										menuPosition="fixed"
										placeholder={this.state.provinceError !== "" ? 'Please select province' : 'Province '}
									/>
									<h7 style = {{fontSize: 12, color: "red"}}>{this.state.provinceError}</h7>
								</td>
								<td>
									<Select
										styles={customStyles}
										options={filtered_city_options}
										value={this.state.selectedCity}
										searchable
										fullWidth={true}
										onChange={this.handleCityChange}
										placeholder={this.state.cityError !== "" ? 'Please select city' : 'City'}
									/>
									<h7 style = {{fontSize: 12, color: "red"}}>{this.state.cityError}</h7>
								</td>
								<td>
									<Select
										styles={customStyles}
										options={filtered_barangay_options}
										value={this.state.selectedBarangay}
										searchable
										fullWidth={true}
										onChange={this.handleBarangayChange}
										placeholder={this.state.barangayError !== "" ? 'Please select barangay' : 'Barangay '}
									/>
									<h7 style = {{fontSize: 12, color: "red"}}>{this.state.barangayError}</h7>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('address_sitio')}
										value = {this.state.patient.address_sitio}
										id="outlined-basic"
										label="Sitio"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('address_street')}
										value = {this.state.patient.address_street}
										id="outlined-basic"
										label="Street"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
								</tr>
								</tbody>
							</Table>

					<br/>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Guardian
					</Typography>
					<Table className="adPatient-guardian-table">
						<tbody>
						<tr valign="top">
							<td>
								<CssTextField
									onChange={this.handleFieldChange('guardian')}
									value = {this.state.patient.guardian}
									id="outlined-basic"
									label="Name"
									variant="outlined"
									fullWidth={true}
									error = {this.state.guardiannameError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.guardiannameError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('guardian_contact_number')}
									value = {this.state.patient.guardian_contact_number}
									type="number"
									id="outlined-basic"
									label="Contact Number"
									variant="outlined"
									fullWidth={true}
									error = {this.state.guardiannumberError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.guardiannumberError}</h7>
							</td>
							<td>
								<CssTextField
									onChange={this.handleFieldChange('guardian_address')}
									value = {this.state.patient.guardian_address}
									id="outlined-basic"
									label="Address"
									variant="outlined"
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Testing
					</Typography>
					<Table className="adPatient-table">
						<tbody>
						<tr valign="top">
							<td>
								<CssTextField
									onChange={this.handleFieldChange('date_tested')}
									value = {this.dateStr(new Date(this.state.patient.date_tested))}
									type='date'
									id="outlined-basic"
									label="When was the testing?"
									variant="outlined"
									fullWidth={true}
									error = {this.state.datetestedError !== ""}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.datetestedError}</h7>
							</td>
							<td colspan="2">
								<Table className="admission-symptoms-table">
								<tbody>
									<tr>
										<td colspan="2">
											How were the results conveyed?
										</td>
									</tr>
									<tr>
										<td>
											<FormControlLabel
												value= "Official Result (Paper)"
												control={<StyleRadio
															checked={this.state.patient.result_disclosure === 'Official Result (Paper)'}
															onChange={this.handleChange_testing}
															error = {this.state.testingError !== ""}
															helperText = {this.state.testingError}
														/>}
												label="Official Result (Paper)"
											/>
										</td>
										<td>
											<FormControlLabel
												value= "Phone Call"
												control={<StyleRadio
															checked={this.state.patient.result_disclosure === 'Phone Call'}
															onChange={this.handleChange_testing}
															error = {this.state.testingError !== ""}
															helperText = {this.state.testingError}
														/>}
												label="Phone Call" />
										</td>
										<td>
											<FormControlLabel
												value= "Text Message"
												control={<StyleRadio
															checked={this.state.patient.result_disclosure === 'Text Message'}
															onChange={this.handleChange_testing}
															error = {this.state.testingError !== ""}
															helperText = {this.state.testingError}
														/>}
												label="Text Message" />
										</td>
									</tr>
									</tbody>
								</Table>
								<h7 style = {{fontSize: 12, color: "red"}}>{this.state.testingError}</h7>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Vitals
					</Typography>
					<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('admission_heart_rate')}
										value = {this.state.patient.admission_heart_rate}
										id="outlined-basic"
										label="Heart Rate"
										variant="outlined"
										fullWidth={true}
										InputProps={{
											endAdornment: <InputAdornment position="end">BPM</InputAdornment>,
										}}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('admission_blood_pressure')}
										value = {this.state.patient.admission_blood_pressure}
										id="outlined-basic"
										label="Blood Pressure"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('admission_o2_saturation')}
										value = {this.state.patient.admission_o2_saturation}
										id="outlined-basic"
										label="Oxygen Saturation"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('admission_temperature')}
										value = {this.state.patient.admission_temperature}
										id="outlined-basic"
										label="Admission Temperature"
										variant="outlined"
										fullWidth={true}
										InputProps={{
											endAdornment: <InputAdornment position="end">°C</InputAdornment>,
										}}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
							</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Medical Information
					</Typography>
					<Table className="adPatient-table">
						<tbody>
							<tr valign="top">
								<td>
									<CssTextField
										onChange={this.handleFieldChange('date_exposure')}
										value = {this.state.patient.date_exposure}
										type='date'
										id="outlined-basic"
										label="Date of onset of Symptoms"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>

								<td rowspan="3" colspan="2">
									<Table className="admission-symptoms-table">
										<tr>
											<td colspan="2" >
												Medical Conditions/Comorbidities:
											</td>
										</tr>
										<tr>
											<td>
												<FormControlLabel
													value= "m_cancer"
													control={<BlueCheckbox
																checked={this.state.m_cancer}
																onChange={this.handleCheckChange('m_cancer')}
															/>}
													label="Cancer" />
											</td>
											<td>
												<FormControlLabel
													value= "m_tb"
													control={<BlueCheckbox
																checked={this.state.m_tb}
																onChange={this.handleCheckChange('m_tb')}
															/>}
													label="Tuberculosis (TB)" />
											</td>
										</tr>
										<tr>
											<td>
												<FormControlLabel
													value= "m_diabetes"
													control={<BlueCheckbox
																checked={this.state.m_diabetes}
																onChange={this.handleCheckChange('m_diabetes')}
															/>}
													label="Diabetes" />
											</td>
											<td>
												<FormControlLabel
													value= "m_highblood"
													control={<BlueCheckbox
																checked={this.state.m_highblood}
																onChange={this.handleCheckChange('m_highblood')}
															/>}
													label="High Blood Pressure" />
											</td>
										</tr>
										<tr>
											<td>
												<FormControlLabel
													value= "m_immunodeficiency"
													control={<BlueCheckbox
																checked={this.state.m_immunodeficiency}
																onChange={this.handleCheckChange('m_immunodeficiency')}
															/>}
													label="Immunodeficiency Conditions (AIDS)" />
											</td>
											<td>
												<FormControlLabel
													value= "m_asthma"
													control={<BlueCheckbox
																checked={this.state.m_asthma}
																onChange={this.handleCheckChange('m_asthma')}
															/>}
													label="Asthma" />

											</td>

										</tr>
										<tr>
											<td>
												<FormControlLabel
													value= "m_other"
													control={<BlueCheckbox
																checked={this.state.m_other}
																onChange={this.handleCheckChange('m_other')}
															/>}
													label="Other" />
											</td>

										</tr>
										<tr>
											<td>
												<CssTextField
													onChange={this.handleChange_oSymptoms('m_othersymptoms')}
													value = {this.state.m_othersymptoms}
													disabled= {!this.state.m_other}
													id="outlined-basic"
													label="Other Symptoms"
													variant="outlined"
													fullWidth={true}
													helperText="Separate by comma"
													className={classes.margin}
													InputLabelProps={{
														shrink: true,
														className: classes.floatingLabelFocusStyle,
													}}
												/>
											</td>
										</tr>
									</Table>
								</td>

							</tr>
							<tr>
								<td rowspan="2">
									<Table className="admission-symptoms-table">
										<tr>
											<td colspan="2" >
												Admission Symptoms:
											</td>
										</tr>
										<tr>
											<td>
												<FormControlLabel
													value= "s_cough"
													control={<BlueCheckbox
																checked={this.state.s_cough}
																onChange={this.handleCheckChange('s_cough')}
															/>}
													label="Cough" />
											</td>
											<td>
												<FormControlLabel
													value= "s_colds"
													control={<BlueCheckbox
																checked={this.state.s_colds}
																onChange={this.handleCheckChange('s_colds')}
															/>}
													label="Colds" />
											</td>
										</tr>
										<tr>
											<td>
												<FormControlLabel
													value= "s_sorethroat"
													control={<BlueCheckbox
																checked={this.state.s_sorethroat}
																onChange={this.handleCheckChange('s_sorethroat')}
															/>}
													label="Sore Throat" />
											</td>
											<td>
												<FormControlLabel
													value= "s_diarrhea"
													control={<BlueCheckbox
																checked={this.state.s_diarrhea}
																onChange={this.handleCheckChange('s_diarrhea')}
															/>}
													label="Diarrhea" />
											</td>
										</tr>
										<tr>
											<td colspan="2">
												<FormControlLabel
													value= "s_other"
													control={<BlueCheckbox
																checked={this.state.s_other}
																onChange={this.handleCheckChange('s_other')}
															/>}
													label="Other" />
												<CssTextField
													onChange={this.handleChange_oSymptoms('s_othersymptoms')}
													value = {this.state.s_othersymptoms}
													disabled= {!this.state.s_other}
													id="outlined-basic"
													label="Other Symptoms"
													variant="outlined"
													helperText="Separate by comma"
													fullWidth={true}
													className={classes.margin}
													InputLabelProps={{
														shrink: true,
														className: classes.floatingLabelFocusStyle,
													}}
												/>
											</td>
										</tr>
									</Table>
								</td>
							</tr>

						</tbody>
					</Table>
					<Table className="adPatient-table">
						<tbody>
							<tr valign="top">
								<td rowspan="3">
									<Table className="adPatient-list-table">
										<tbody>
											<tr>
												<td>
													<CssTextField
														placeholder="Input here and press `Enter`..."
														value = {this.state.patient_fammedhistory}
														id="outlined-basic"
														label="Family Medical History"
														variant="outlined"
														fullWidth={true}
														onKeyDown={this.handleKeyDown_fam}
														onChange={this.handleChange_fam}
														InputLabelProps={{
															shrink: true,
															className: classes.floatingLabelFocusStyle,
														}}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<div className="items-div">
														{this.state.item_fammedhist.map(item => (
															<div className="tag-item" key={item}>
																{item}
																<button
																	type="button"
																	className="button"
																	onClick={() => this.handleDelete_fam(item)}
																>
																&times;
																</button>
															</div>
														))}
													</div>
												</td>
											</tr>
										</tbody>
									</Table>
								</td>
								<td rowspan="3">
									<Table className="adPatient-list-table">
										<tbody>
											<tr>
												<td>
													<CssTextField
														value={this.state.patient_history}
														placeholder="Input here and press `Enter`..."
														id="outlined-basic"
														label="History of Present Illness"
														variant="outlined"
														fullWidth={true}
														onKeyDown={this.handleKeyDown}
														onChange={this.handleChange}
														InputLabelProps={{
															shrink: true,
															className: classes.floatingLabelFocusStyle,
														}}
													/>

												</td>

											</tr>
											<tr>
												<td>
													<div className="items-div">
														{this.state.items.map(item => (
															<div className="tag-item" key={item}>
																{item}
																<button
																	type="button"
																	className="button"
																	onClick={() => this.handleDelete(item)}
																>
																&times;
																</button>
															</div>
														))}
													</div>
												</td>
											</tr>
										</tbody>
									</Table>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('medications')}
										value = {this.state.patient.medications}
										id="outlined-basic"
										label="Maintenance Medications"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
								<td>
									<CssTextField
										onChange={this.handleFieldChange('food_restrictions')}
										value = {this.state.patient.food_restrictions}
										id="outlined-basic"
										label="Food Restrictions/Allergies"
										variant="outlined"
										fullWidth={true}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
							</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<div align="right">
						<h7 style = {{fontSize: 18, color: "red", paddingRight: 10}}>{this.state.adPatientError}</h7>
						<br/><br/>
					</div>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left'>
					        	<Button align='left' size="large" variant="outlined" color='secondary'
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			onClick={() => this.props.history.push('/qb-patients') }>
				        			CANCEL</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									size='large'
									variant='contained'
									color='primary'
									onClick = {this.handleSave}>
										SAVE
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
        );
    };
}

export default UpdatePatientInfo;
