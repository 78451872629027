import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';

import QBChangePassword from './QBChangePassword';

class QBChangePasswordComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			oldPassword: '',
			password: '',
			confirmPassword: '',
			errors: ''
		}
	}

	onSubmit = () => {
		if (this.state.password !== this.state.confirmPassword) {
			return this.setState({
				errors: 'Passwords do not match!'
			});
		}
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 'https://covidcheck.upcebu.edu.ph/dev/api/qb/auth' : 'https://covidcheck.upcebu.edu.ph/api/qb/auth';
			const body = {
				new_password: this.state.password
			};
			if(this.state.oldPassword){
				body.old_password = this.state.oldPassword;
			}
			axios.put(url, body, {headers: {'Authorization': `Basic ${token}`}}).then(response => {
				this.props.onSuccess();
			}).catch(err => {
				this.setState({
					errors: 'Something went wrong.'
				});
			});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 'https://covidcheck.upcebu.edu.ph/dev/api/qb/auth' : 'https://covidcheck.upcebu.edu.ph/api/qb/auth';
			const body = {
				new_password: this.state.password
			};
			if(this.state.oldPassword){
				body.old_password = this.state.oldPassword;
			}
			axios.put(url, body, {withCredentials: true}).then(response => {
				this.props.onSuccess();
			}).catch(err => {
				this.setState({
					errors: 'Something went wrong.'
				});
			});
		}
	}

	onPasswordChange = field => event => {
		this.setState({ [field]: event.target.value });
	}

	render() {
		return (
			<QBChangePassword 
				onSubmit = {this.onSubmit}
				onPasswordChange = {this.onPasswordChange}
				errors = {this.state.errors}
			/>
		)
	}
}

export default QBChangePasswordComponent;
