import React from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const ReportedPlacesCard = (props) => {
	
	const state = {
	  labels: props.dept.map(a => a.label),
	  datasets: [
		{
		  label: 'Report Count',
		  backgroundColor: '#556CB3',
		  borderColor: '#556CB3',
		  borderWidth: 1,
		  data: props.dept.map(a => a.value)
		}
	  ]
	}
	
	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={10}>
			<div className="card-title" align='left' style={{paddingTop: 8, paddingBottom: 8 }}>REPORTS PER DEPARTMENT</div>
			<Divider style={{ backgroundColor: '#f0f0f0'}}/>
				<div align='center' className="dashboard-maxWidth" style={{ paddingRight: 5 }}>
					<br/>
					<Bar 
					  data={state}
					  height={177}
					  options={{
						legend:{
						  display:false
						},
						scales: {
							xAxes: [{
								gridLines: {
									drawOnChartArea: true
								},
								scaleLabel: {
									display: true,
									labelString: 'Department/Unit',
								    fontColor: "#556CB3",
								    fontSize: 12
								},
								ticks: {
									display: false,
								    fontSize: 6,
									minRotation: 0,
									maxRotation: 0,
								},
							}],
							yAxes: [{
								gridLines: {
									drawOnChartArea: true
								},
								scaleLabel: {
									display: true,
									color: 'red',
									labelString: 'Report Count',
								    fontColor: "#556CB3",
								    fontSize: 12
								},
								ticks: {
								    fontSize: 10,
									precision: 0,
									beginAtZero: true,
									min: 0
								},
							}]
						}
					  }}
					/>
				</div>
		</Paper>
	);
}

export default ReportedPlacesCard;