import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Select from 'react-select';
import options_provinces from '.././Provinces';
import options_cities from '.././Cities';
import options_barangays from '.././Barangays';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

    const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
  
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Address extends Component {
    state = {
        errorStreet: "",
        errorSitio: "",
        errorBrgy: "",
        errorCity: "",
        errorProvince: "",
        valid: false,
        name: 'React',
        selectedOption: {},
        selectedOption2: {},
        selectedOption3: {},
        selectedOption4: {},
        selectedOption5: {},
    };

	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        this.state.valid = true;
        if(this.props.values.address_street.trim() == ""){
            this.state.valid = false;
            this.setState({ 
                errorStreet: "Input is required",
                valid: false
            });
        }else{
            this.setState({ errorStreet: ""});
        }
        
        if(this.props.values.address_barangay.trim() == ""){
            this.state.valid = false;
            this.setState({ 
                errorBrgy: "Input is required",
                valid: false
            });
        }else{
            this.setState({ errorBrgy: ""});
        }
        if(this.props.values.address_city.trim() == ""){
            this.state.valid = false;
            this.setState({ 
                errorCity: "Input is required",
                valid: false
            });
        }else{
            this.setState({ errorCity: ""});
        }
        if(this.props.values.address_province.trim() == ""){
            this.state.valid = false;
            this.setState({ 
                errorProvince: "Input is required",
                valid: false
            });
        }else{
            this.setState({ errorProvince: ""});
        }
        if(this.state.valid){
            this.setState({ 
                errorStreet: "",
                errorSitio: "",
                errorBrgy: "",
                errorCity: "",
                errorProvince: "",
            });
            this.props.nextStep();
        }
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };


	handleChange1 = (selectedOption) => {
		this.setState({selectedOption: selectedOption});
        this.props.handleProvince(selectedOption.value, selectedOption);
    }
	handleChange2 = (selectedOption) => {
		this.setState({selectedOption2: selectedOption});
        this.props.handleCity(selectedOption.value, selectedOption);
    }
	handleChange3 = (selectedOption2) => {
		this.setState({selectedOption3: selectedOption2});
        this.props.handleBarangay(selectedOption2.value, selectedOption2);
	}
	handleChange4 = (selectedOption) => {
		this.setState({selectedOption4: selectedOption});
        this.props.handleSitio(selectedOption.value, selectedOption);
	}
	handleChange5 = (selectedOption) => {
        this.setState({selectedOption5: selectedOption});
        this.props.handleStreet(selectedOption.value, selectedOption);
	};
	
    
    render() {
        const { values, handleChange, city, handleStreet, handleSitio, handleBarangay, handleCity, handleProvince } = this.props; 
		const filteredOptions = options_cities.filter((o) => o.link === this.state.selectedOption.value)
		const filteredOptions2 = options_barangays.filter((o) => o.link === this.state.selectedOption2.value)
		
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress"> 
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Personal Information</h2>
							</div>
                            <h1>Please input your home address.</h1>
							
                            <br/>
                            <br/>
							<div className = "for_select">
								<Select
								  name="province"
								  value={values.selected_province}
								  onChange={this.handleChange1}
								  options={options_provinces}
								  placeholder="Province"
									errorText = {this.state.errorProvince}
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorProvince}</h4>
								<br/>
								<Select
								  name="city"
								  key={city}
								  value={values.selected_city}
								  onChange={this.handleChange2}
								  options={filteredOptions}
									placeholder="City"
									errorText = {this.state.errorCity}
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorCity}</h4>
								<br/>
								<Select
								  name="barangay"
								  value={values.selected_barangay}
								  onChange={this.handleChange3}
								  options={filteredOptions2}
									placeholder="Barangay"
									errorText = {this.state.errorBrgy}
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorBrgy}</h4>
								
                            </div>
							<br/>
							<TextField
                                placeholder = "Street"
                                onChange={handleChange('address_street')}
                                defaultValue = {values.address_street}
								style={{ width: 300, marginLeft: 10, padding: 0}}
                                margin="dense"
                                errorText = {this.state.errorStreet}
                            />
							<br/>
							<br/>
                            <TextField
                                placeholder = "Sitio"
                                onChange={handleChange('address_sitio')}
                                defaultValue = {values.address_sitio}
								style={{ width: 300, marginLeft: 10, padding: 0}}
								margin="dense"
                                errorText = {this.state.errorSitio}
                            />
							<br/>
							<br/>
							<div>
								<button class = "button-backchoice" 
									primary = {true} 
									onClick = {this.back}>
										&#60;  Back
								</button>
								<button class = "button-nextchoice" 
									primary = {true} 
									onClick = {this.continue}>
										Continue
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}


const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Address;
