export default [
	{value:	'Alcantara'	, label:	'Alcantara'	, link:	'Cebu'	},
	{value:	'Alcoy'	, label:	'Alcoy'	, link:	'Cebu'	},
	{value:	'Alegria'	, label:	'Alegria'	, link:	'Cebu'	},
	{value:	'Aloguinsan'	, label:	'Aloguinsan'	, link:	'Cebu'	},
	{value:	'Argao'	, label:	'Argao'	, link:	'Cebu'	},
	{value:	'Asturias'	, label:	'Asturias'	, link:	'Cebu'	},
	{value:	'Badian'	, label:	'Badian'	, link:	'Cebu'	},
	{value:	'Balamban'	, label:	'Balamban'	, link:	'Cebu'	},
	{value:	'Bantayan'	, label:	'Bantayan'	, link:	'Cebu'	},
	{value:	'Barili'	, label:	'Barili'	, link:	'Cebu'	},
	{value:	'Bogo City'	, label:	'Bogo City'	, link:	'Cebu'	},
	{value:	'Boljoon'	, label:	'Boljoon'	, link:	'Cebu'	},
	{value:	'Borbon'	, label:	'Borbon'	, link:	'Cebu'	},
	{value:	'Carcar'	, label:	'Carcar'	, link:	'Cebu'	},
	{value:	'Carmen'	, label:	'Carmen'	, link:	'Cebu'	},
	{value:	'Catmon'	, label:	'Catmon'	, link:	'Cebu'	},
	{value:	'Cebu City'	, label:	'Cebu City'	, link:	'Cebu'	},
	{value:	'Compostela'	, label:	'Compostela'	, link:	'Cebu'	},
	{value:	'Consolacion'	, label:	'Consolacion'	, link:	'Cebu'	},
	{value:	'Cordova'	, label:	'Cordova'	, link:	'Cebu'	},
	{value:	'Daanbantayan'	, label:	'Daanbantayan'	, link:	'Cebu'	},
	{value:	'Dalaguete'	, label:	'Dalaguete'	, link:	'Cebu'	},
	{value:	'Danao City'	, label:	'Danao City'	, link:	'Cebu'	},
	{value:	'Dumanjug'	, label:	'Dumanjug'	, link:	'Cebu'	},
	{value:	'Ginatilan'	, label:	'Ginatilan'	, link:	'Cebu'	},
	{value:	'Lapu-Lapu City'	, label:	'Lapu-Lapu City'	, link:	'Cebu'	},
	{value:	'Liloan'	, label:	'Liloan'	, link:	'Cebu'	},
	{value:	'Madridejos'	, label:	'Madridejos'	, link:	'Cebu'	},
	{value:	'Malabuyoc'	, label:	'Malabuyoc'	, link:	'Cebu'	},
	{value:	'Mandaue City'	, label:	'Mandaue City'	, link:	'Cebu'	},
	{value:	'Medellin'	, label:	'Medellin'	, link:	'Cebu'	},
	{value:	'Minglanilla'	, label:	'Minglanilla'	, link:	'Cebu'	},
	{value:	'Moalboal'	, label:	'Moalboal'	, link:	'Cebu'	},
	{value:	'Naga City'	, label:	'Naga City'	, link:	'Cebu'	},
	{value:	'Oslob'	, label:	'Oslob'	, link:	'Cebu'	},
	{value:	'Pilar'	, label:	'Pilar'	, link:	'Cebu'	},
	{value:	'Pinamungahan'	, label:	'Pinamungahan'	, link:	'Cebu'	},
	{value:	'Poro'	, label:	'Poro'	, link:	'Cebu'	},
	{value:	'Ronda'	, label:	'Ronda'	, link:	'Cebu'	},
	{value:	'Samboan'	, label:	'Samboan'	, link:	'Cebu'	},
	{value:	'San Fernando'	, label:	'San Fernando'	, link:	'Cebu'	},
	{value:	'San Francisco'	, label:	'San Francisco'	, link:	'Cebu'	},
	{value:	'San Remigio'	, label:	'San Remigio'	, link:	'Cebu'	},
	{value:	'Santa Fe'	, label:	'Santa Fe'	, link:	'Cebu'	},
	{value:	'Santander'	, label:	'Santander'	, link:	'Cebu'	},
	{value:	'Sibonga'	, label:	'Sibonga'	, link:	'Cebu'	},
	{value:	'Sogod'	, label:	'Sogod'	, link:	'Cebu'	},
	{value:	'Tabogon'	, label:	'Tabogon'	, link:	'Cebu'	},
	{value:	'Tabuelan'	, label:	'Tabuelan'	, link:	'Cebu'	},
	{value:	'Talisay City'	, label:	'Talisay City'	, link:	'Cebu'	},
	{value:	'Toledo City'	, label:	'Toledo City'	, link:	'Cebu'	},
	{value:	'Tuburan'	, label:	'Tuburan'	, link:	'Cebu'	},
	{value:	'Tudela'	, label:	'Tudela'	, link:	'Cebu'	},



];