export default [
	{ value: 'Filipino', label: 'Filipino' },
	{ value: 'South Korean', label: 'South Korean' },
	{ value: 'Chinese', label: 'Chinese' },
	{ value: 'American', label: 'American' },
	{ value: 'Japanese', label: 'Japanese' },
	{ value: 'Taiwanese', label: 'Taiwanese' },
	{ value: 'Australian', label: 'Australian' },
	{ value: 'Canadian', label: 'Canadian' },
	{ value: 'British', label: 'British' },
	{ value: 'Singaporean', label:	'Singaporean'},
{ value:	'Malaysian'	, label:	'Malaysian'	},
{ value:	'Italian'	, label:	'Italian'	},
{ value:	'Spanish'	, label:	'Spanish'	},
{ value:	'German'	, label:	'German'	},

];