import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
	Typography, Paper, Divider, TextField, Grid
} from '@material-ui/core';

const Table = styled.table`
	padding: 5px;

	td {
		padding: 5px;
	}
`;

const BlueCheckbox = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const mobilePhonesBreakPoint = 400;

class AddHealthStatus extends Component {
	state = {
		report: {},
		health_status: {},
		patient_code: "",
		patient_password: "",
		temperatureError: "",
		dateError: "",
		time: '08:00',
		loading: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const health_status = {};
		health_status.report_id = parseInt(this.props.match.params.report_id);

		health_status.temperature = 36;

		health_status.s_fever = false;
		health_status.s_cough = false;
		health_status.s_fatigue = false;
		health_status.s_phlegm = false;
		health_status.s_headache = false;
		health_status.s_chills = false;
		health_status.s_vomiting = false;
		health_status.s_diarrhea = false;
		health_status.s_short_breath = false;
		health_status.s_sore_throat = false;
		health_status.s_stuffed_nose = false;
		health_status.s_muscle_joint_pain = false;
		health_status.s_others = "";

		health_status.medications = "";

		health_status.date_created = new Date();

		health_status.image_url = null;
		health_status.deleted = false;

		this.setState({ health_status: health_status, time: this.timeStr(new Date()) });
		const reportId = health_status.report_id;

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						report: result.data,
						patient_code: result.data.patient_code,
						patient_password: result.data.patient_password
					});
				});
		} else {
			const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(reportsUrl, {withCredentials: true})
				.then(result => {
					this.setState({
						report: result.data,
						patient_code: result.data.patient_code,
						patient_password: result.data.patient_password
					});
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push(`/patient-monitoring/${this.state.health_status.report_id}`);
	}

	handleConfirm = event => {
		event.preventDefault();

		const health_status = this.state.health_status;

		//validation here (date, temp)
		let valid = true;
		if(this.state.health_status.date_created == null || this.state.health_status.date_created == ""){
			valid = false;
			this.setState({ dateError: "Input is required" });
		}else{
			this.setState({ dateError: "" });
		}
		if(this.state.health_status.temperature){
			health_status.temperature = parseFloat(this.state.health_status.temperature);
		}else{
			valid = false;
			this.setState({ temperatureError: "Invalid Input" });
		}

		if(valid){
			this.setState({ loading: true });
			if(health_status.date_created instanceof Date){
				health_status.date_created = health_status.date_created.toISOString();
			}

			var bodyFormData = new FormData();
			bodyFormData.append('date_created', health_status.date_created);
	        bodyFormData.append('deleted', false);
	        bodyFormData.append('report_id', health_status.report_id);
	        bodyFormData.append('temperature', health_status.temperature);
	        bodyFormData.append('s_fever', health_status.s_fever);
	        bodyFormData.append('s_cough', health_status.s_cough);
	        bodyFormData.append('s_short_breath', health_status.s_short_breath);
	        bodyFormData.append('s_fatigue', health_status.s_fatigue);
	        bodyFormData.append('s_phlegm', health_status.s_phlegm);
	        bodyFormData.append('s_muscle_joint_pain', health_status.s_muscle_joint_pain);
	        bodyFormData.append('s_sore_throat', health_status.s_sore_throat);
	        bodyFormData.append('s_headache', health_status.s_headache);
	        bodyFormData.append('s_chills', health_status.s_chills);
	        bodyFormData.append('s_vomiting', health_status.s_vomiting);
	        bodyFormData.append('s_stuffed_nose', health_status.s_stuffed_nose);
	        bodyFormData.append('s_diarrhea', health_status.s_diarrhea);
	        bodyFormData.append('s_others', health_status.s_others);
	        bodyFormData.append('medications', health_status.medications);
	        bodyFormData.append('image', health_status.image_url);

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios({
					method: 'post',
					url: 'https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates',
					data: bodyFormData,
					headers: {'Authorization': `Basic ${token}`}
				}).then(result => {
						if(result.status === 201){
							this.props.history.push(`/patient-monitoring/${this.state.health_status.report_id}`);
						}else{
							this.setState({ loading: false });
						}
					});
			} else {
				const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates` : `https://covidcheck.upcebu.edu.ph/api/reportUpdates`;
				const token = Buffer.from(`${this.state.report.email}:${this.state.report.password}`, 'utf8').toString('base64');
				axios({
					method: 'post',
					url: reportUpdatesUrl,
					data: bodyFormData,
					withCredentials: true
				}).then(result => {
						if(result.status === 201){
							this.props.history.push(`/patient-monitoring/${this.state.health_status.report_id}`);
						}else{
							this.setState({ loading: false });
						}
					});
			}
		}
	}

	handleCheckChange = field => event => {
		const health_status = this.state.health_status;
		health_status[field] = event.target.checked;
		this.setState({ health_status });
	}

	handleChange = field => event => {
		const health_status = this.state.health_status;
		health_status[field] = event.target.value;
		this.setState({ health_status });
	}

	fileSelectedChange = event => {
		const health_status = this.state.health_status;
		health_status.image_url = event.target.files[0];
		this.setState({ health_status });
	}

	handleTimeChange = event => {
		let time = event.target.value;
		if(time === null || time === ''){
			time = this.timeStr(new Date());
		}
		const health_status = this.state.health_status;
		const currDate = new Date(health_status.date_created);
		const newDate = new Date(currDate.getFullYear(), currDate.getUTCMonth(),
								currDate.getUTCDate(), parseInt(time.split(':')[0]),
								 parseInt(time.split(':')[1]), 0);
		health_status.date_created = newDate;
		this.setState({ health_status, time });
	}

	handleDateChange = (event) => {
		let date = event.target.value;
		if(date === null || date === ''){
			date = this.dateStr(new Date());
		}
		let currDate = this.state.health_status.date_created;
		if(currDate instanceof Date){
			currDate = this.state.health_status.date_created.toISOString();
		}
		const timeStr = currDate.split('T')[1];
		const health_status = this.state.health_status;
		health_status.date_created = date+'T'+timeStr;
		this.setState({ health_status });
	}

	dateStr = d => {
		if(d == null){
			d = new Date();
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	timeStr = d => {
		if(d == null){
			d = new Date();
		}
		// console.log(moment(d).format('LT'));
		let timeStr = moment(d).format('LT').split(' ')[0];
		const amPm = moment(d).format('LT').split(' ')[1];
		let hh = timeStr.split(':')[0];
		hh = hh <= 9 ? '0'+hh : hh;
		const mm = timeStr.split(':')[1];
		timeStr = hh+':'+mm;
		if(parseInt(hh) === 12 && parseInt(mm) === 0){
			return '24:00';
		}
		if(amPm === 'PM' || (amPm === 'AM' && parseInt(hh) === 12 && parseInt(mm) >= 0)){
			let adjustedHH = parseInt(hh) + 12;
			if(adjustedHH === 24 && parseInt(mm) && amPm === 'AM'){
				adjustedHH = 0;
			}
			if(adjustedHH === 24 && parseInt(mm) && amPm === 'PM'){
				adjustedHH = 12;
			}
			timeStr = adjustedHH+':'+mm;
		}
		// console.log(timeStr);
		return timeStr;
	}

	render() {
		const classes = {margin:1}
		return(
			<MuiThemeProvider>
                <React.Fragment>
                        <Paper style={{padding: 10}}>
                            <Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
                                Add Health Status
                            </Typography>
                            <Divider/>
		                    <Typography component="h1" variant="subtitle1" color="textSecondary" style={{ padding: 10 }} align='left' gutterBottom>
                                Patient: {this.state.report.name}
                            </Typography>
                            <Table className="adPatient-table">
							<tbody>
								<tr>
									<td colspan='2'>
										<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
											{'Date Observed'}
										</Typography>
										<TextField
											id="outlined-basic"
											variant="outlined"
											fullWidth={true}
											className={classes.margin}
											InputLabelProps={{
												shrink: true,
												className: classes.floatingLabelFocusStyle,
											}}
											margin="dense"
											type = "date"
											InputProps={{inputProps: {max: moment().format("YYYY-MM-DD")} }}
					                        onChange={ this.handleDateChange }
					                        value = { this.dateStr(new Date(this.state.health_status.date_created)) }
				                    	/>
				                    </td>
				                    <td colspan='2'>
				                    	<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
											{'Time Observed'}
										</Typography>
				                    	<TextField
				                    		id="outlined-basic"
											variant="outlined"
											fullWidth={true}
											className={classes.margin}
											InputLabelProps={{
												shrink: true,
												className: classes.floatingLabelFocusStyle,
											}}
											margin="dense"
					                        type = "time"
					                        onChange={ this.handleTimeChange }
					                        value = { this.state.time }
				                    	/>
				                    </td>
			                    </tr>
			                    <tr>
			                    	<td colspan='2'>
			                    		<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
											Temperature
										</Typography>
			                    		<TextField
                                            value = {this.state.health_status.temperature}
                                            id="outlined-basic"
                                            type="number"
                                            variant="outlined"
                                            fullWidth={true}
                                            className={classes.margin}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.floatingLabelFocusStyle,
                                            }}
                                            onChange={this.handleChange('temperature')}
                                            margin="dense"
                                        />
										<Typography component="h1" variant="caption" color="error" align='left' style={{paddingLeft:5}}>
											{this.state.temperatureError}
										</Typography>
			                    	</td>
			                    	<td colspan='2'>
			                    		<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
											Thermometer Image
										</Typography>
			                    		<div style={{ padding: 5 }} align='left'>
											<input style = {{width: '90%', height: '30px'}} type = 'file' onChange = {this.fileSelectedChange} accept="image/png, image/jpeg"/>
										</div>
			                    	</td>
			                    </tr>
			                    <Typography component="h1" variant="subtitle1" color="primary" align='left' style={{paddingLeft:5, paddingBottom: 5, paddingTop: 5}}>
									Symptoms
								</Typography>
			                    <tr>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_fever}
																onChange={this.handleCheckChange('s_fever')} name="s_fever" color="primary" />}
											label="Fever"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_cough}
																onChange={this.handleCheckChange('s_cough')} name="s_cough" color="primary" />}
											label="Cough"
										/>
									</td>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_short_breath}
																onChange={this.handleCheckChange('s_short_breath')} name="s_short_breath" color="primary" />}
											label="Short Breath"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_fatigue}
																onChange={this.handleCheckChange('s_fatigue')} name="s_fatigue" color="primary" />}
											label="Fatigue"
										/>
			                    	</td>
			                    </tr>
			                    <tr>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_phlegm}
																onChange={this.handleCheckChange('s_phlegm')} name="s_phlegm" color="primary" />}
											label="Phlegm"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		 <FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_muscle_joint_pain}
																onChange={this.handleCheckChange('s_muscle_joint_pain')} name="s_muscle_joint_pain" color="primary" />}
											label="Muscle or Joint Pain"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_sore_throat}
																onChange={this.handleCheckChange('s_sore_throat')} name="s_sore_throat" color="primary" />}
											label="Sore Throat"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		 <FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_headache}
																onChange={this.handleCheckChange('s_headache')} name="s_headache" color="primary" />}
											label="Headache"
										/>
			                    	</td>
			                    </tr>
			                    <tr>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_chills}
																onChange={this.handleCheckChange('s_chills')} name="s_chills" color="primary" />}
											label="Chills"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_vomiting}
																onChange={this.handleCheckChange('s_vomiting')} name="s_vomiting" color="primary" />}
											label="Vomiting"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		<FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_stuffed_nose}
																onChange={this.handleCheckChange('s_stuffed_nose')} name="s_stuffed_nose" color="primary" />}
											label="Stuffy Nose"
										/>
			                    	</td>
			                    	<td align='left'>
			                    		 <FormControlLabel
											control={<BlueCheckbox checked={this.state.health_status.s_diarrhea}
																onChange={this.handleCheckChange('s_diarrhea')} name="s_diarrhea" color="primary" />}
											label="Diarrhea"
										/>
			                    	</td>
			                    </tr>
			                    <tr>
			                    	<td colspan='2'>
			                    		<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
											Other Symptoms
										</Typography>
			                    		<TextField
				                            id="outlined-basic"
											variant="outlined"
											fullWidth={true}
											className={classes.margin}
											InputLabelProps={{
												shrink: true,
												className: classes.floatingLabelFocusStyle,
											}}
											margin="dense"
				                            onChange={this.handleChange('s_others')}
				                            value={this.state.health_status.s_others}
				                        />
			                    	</td>
			                    	<td colspan='2'>
			                    		<Typography component="h1" variant="body2" color="textSecondary" align='left' style={{paddingLeft:5}}>
											Medications Taken
										</Typography>
			                    		<TextField
				                            id="outlined-basic"
											variant="outlined"
											fullWidth={true}
											className={classes.margin}
											InputLabelProps={{
												shrink: true,
												className: classes.floatingLabelFocusStyle,
											}}
											margin="dense"
				                            onChange={this.handleChange('medications')}
				                            value={this.state.health_status.medications}
				                        />
			                    	</td>
			                    </tr>
		                    </tbody>
		                    </Table>
		                    <br/>
                            <Divider/>
                            <br/>
                            <Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<div align='left'>
							        	<Button align='left' size="large" variant="outlined" color='secondary'
							        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
							        			onClick={this.handleBack}>
						        			CANCEL</Button>
									</div>
								</Grid>
								<Grid item xs={12} md={6}>
									<div align='right' style={{ paddingBottom: 10 }}>
										<Button
											fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
											size='large'
											variant='contained'
		                                    color='primary'
											onClick={this.handleConfirm}
                                            disabled = {this.state.loading}>
                                                {this.state.loading? "SAVING..":"SAVE"}
										</Button>
									</div>
								</Grid>
							</Grid>
	                    </Paper>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default AddHealthStatus;
