import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Checkbox from 'material-ui/Checkbox';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Map,Marker,Popup,TileLayer}from 'react-leaflet';
import L from 'leaflet';
import Barangay_coords from '../lat_long';
import Slider from '@material-ui/core/Slider';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CreatableSelect from 'react-select/creatable';
import options_departments from '.././Departments';
import Control from 'react-leaflet-control';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import Select from 'react-select';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';

import '../Forms.css';
import options_nationalities from '../Nationalities';
import options_provinces from '../Provinces';
import options_cities from '../Cities';
import options_barangays from '../Barangays';
import lat_long from '../lat_long';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 5px;	

	td {
		padding: 5px;
	}
`;

class AddPatient extends Component {
	state = {
		report: {},
		selectedNationality: {},
		selectedProvince: null,
		selectedCity: null,
		selectedBarangay: null,
		selectedCompany:null,
		errorName: "",
		errorAge: "",
		errorNationality: "",
		errorDepartment: "",
		errorProvince: "",
		errorCity: "",
		errorBrgy: "",
		errorContactNumber: "",
		errorEmail: "",
		errorDateResponded: "",
		errorQuarantine: "",

		map_open: false,
		zoom_level: 14,
		company_suggestions: [],
		isLoading:false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const report = {};
		report.name = "";
		report.age = 0;
		report.age_month = 0;
		report.sex = "Female";
		report.nationality = 'Filipino';

		report.email = "";
		report.designation = "Staff";
		report.department = "";

		report.address_province = 'Cebu';
		report.address_city = cookies.get('user_city');
		report.address_barangay = cookies.get('user_role') === 'Barangay' ? (cookies.get('user_barangay').includes(',') ? '' : cookies.get('user_barangay')) : '';
		report.address_latitude = 10.3377;
		report.address_longitude = 123.9079;
		report.contact_number = "";
		report.company = "";
		report.date_responded = new Date();
		report.admit_quarantine_facility = "";
		report.classification = this.props.match.params.classification;
		report.status = 2;
		this.setState({ 
			report: report,
			selectedNationality: { value:'Filipino', label:'Filipino' },
			selectedProvince: { value:'Cebu', label: 'Cebu' },
			selectedCity: { value:cookies.get('user_city'), label:cookies.get('user_city') },
			selectedBarangay: cookies.get('user_role') === 'Barangay' 
			? (cookies.get('user_barangay').includes(',') ? null : {value:cookies.get('user_barangay'), label:cookies.get('user_barangay')})
			: null
		});
		
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			var val = ''
			var url = `/api/reports/companies?company=${val}`;
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						result.data.companies.forEach(element => {
							if(element != ''){
								this.setState({
									company_suggestions: this.state.company_suggestions.concat({label: element, value: element}),
								})
							}
						});
					}else{

					}
				})
				.catch(error => {
				})
		}else{
			var val = '';
			var url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 
			`https://covidcheck.upcebu.edu.ph/dev/api/reports/companies?company=${val}` : 
			`https://covidcheck.upcebu.edu.ph/api/reports/companies?company=${val}`;
			axios(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						result.data.companies.forEach(element => {
							if(element != ''){
								this.setState({
									company_suggestions: this.state.company_suggestions.concat({label: element, value: element}),
								})
							}
						});
					}else{
					}
				})
				.catch(error => {
				})
		}
	}

    // $FlowFixMe: ref
	refmarker = React.createRef();
	
	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	validate = (email) => {
		const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
				
		return expression.test(email.toLowerCase());
	}

	handleConfirm = event => {
		event.preventDefault();
		
		let valid = true;
		if(this.state.report.name == ""){
			valid = false;
			this.setState({ errorName: "Input is required" });
		}else{
			this.setState({ errorName: "" });
		}
		if(this.state.report.age != 0){
			const n = parseInt(this.state.report.age);
			if(n == 0){
				valid = false;
				this.setState({ errorAge: "Input is required" });
			}else{
				this.setState({ errorAge: "" });
			}
		}else{
			if(this.state.report.age_month != 0){
				const m = parseInt(this.state.report.age_month);
				if((m < 1 || m > 11)){
					valid = false;
					this.setState({ errorAge: "Only 1 to 11 values accepted for month" });
				}else{
					this.setState({ errorAge: "" });
				}
			}else{
				valid = false;
				this.setState({ errorAge: "Input is required" });
			}
		}
		if(this.state.report.nationality == ""){
			valid = false;
			this.setState({ errorNationality: "Input is required" });
		}else{
			this.setState({ errorNationality: "" });
		}
		if(this.state.report.address_province == ""){
			valid = false;
			this.setState({ errorProvince: "Input is required" });
		}else{
			this.setState({ errorProvince: "" });
		}
		if(this.state.report.address_city == ""){
			valid = false;
			this.setState({ errorCity: "Input is required" });
		}else{
			this.setState({ errorCity: "" });
		}
		if(this.state.report.address_barangay == ""){
			valid = false;
			this.setState({ errorBrgy: "Input is required" });
		}else{
			this.setState({ errorBrgy: "" });
		}
		if(this.state.report.department == ""){
			valid = false;
			this.setState({ errorDepartment: "Input is required" });
		}else{
			this.setState({ errorDepartment: "" });
		}
		if(this.state.report.date_responded == null || this.state.report.date_responded == ""){
			valid = false;
			this.setState({ errorDateResponded: "Input is required" });
		}else{
			this.setState({ errorDateResponded: "" });
		}
		// if(this.state.report.admit_quarantine_facility == "" && this.state.report.classification != "PUM-RT"){
		// 	valid = false;
		// 	this.setState({ errorQuarantine: "Input is required" });
		// }else{
		// 	this.setState({ errorQuarantine: "" });
		// }
		if( this.state.report.contact_number.length != 0 && this.state.report.contact_number.length != 11 && this.state.report.contact_number.length != 7){
			valid = false;
			this.setState({ errorContactNumber: "Input is required" });
		}else{
			this.setState({ errorContactNumber: "" });
		}
        if(this.state.report.email.trim() == ""){
			valid = false;
            this.setState({ errorEmail: "Input is required" });
        }else{
			if(this.state.report.email.includes("@")){
				var val = this.validate(this.state.report.email.trim());
				
				if(val){
					var pre_email = this.state.report.email.trim().split('@');
					var domain = pre_email[1].split('.');
					
					var end_dom = domain[domain.length-1];
					
					if(end_dom.length == 1){
						valid = false;
						this.setState({ errorEmail: "Invalid format" });
					}else{
						this.setState({ errorEmail: "" });
					}
				}else{
					valid = false;
					this.setState({ errorEmail: "Invalid format" });
				}
			}else{
				valid = false;
				this.setState({ errorEmail: "Invalid email address" });
			}
		}
		
		if(valid){
			this.setState({ isLoading: true});
			const report = this.state.report;

			report.age = report.age ? parseInt(report.age) : 0;
			report.age_month = report.age ? 0 : (report.age_month ? parseInt(report.age_month) : 0);
			
			report.status = 1; //responded

			//set other fields' values
			report.s_fever = false;
			report.s_cough = false;
			report.s_short_breath = false;
			report.s_fatigue = false;
			report.s_phlegm = false;
			report.s_muscle_joint_pain = false;
			report.s_sore_throat = false;
			report.s_headache = false;
			report.s_chills = false;
			report.s_vomiting = false;
			report.s_stuffed_nose = false;
			report.s_diarrhea = false;
			
			report.date_testing = null;
			report.testing_center = '';

			report.m_cancer = false;
			report.m_tuberculosis = false;
			report.m_asthma = false;
			report.m_diabetes = false;
			report.m_high_blood_pressure = false;
			report.m_renal_failure = false;
			report.m_immunodeficiency_conditions = false;
			report.m_others = '';

			report.has_symptoms = false;
			report.has_visited_infected_areas = false;
			report.has_met_infected = false

			report.deleted = false;
			report.submitted_by = 'Encoded';
			
			if (process.env.NODE_ENV === 'development') {
				axios.post('https://covidcheck.upcebu.edu.ph/dev/api/reports', report)
					.then(result => {
						if(result.status === 201){
							this.props.history.push('/view-reports');
						}else{
							this.setState({ isLoading: false});
						}
					})
					.catch(error => {
						
						this.setState({ isLoading: false});
					})
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/reports' : 'https://covidcheck.upcebu.edu.ph/api/reports';
				axios.post(url, report)
					.then(result => {
						if(result.status === 201){
							this.props.history.push('/view-reports');
						}else{
							
							this.setState({ isLoading: false});
						}
					})
					.catch(error => {
						
						this.setState({ isLoading: false});
					})
			}
		}
	}

	handleChange = (newValue: any, actionMeta: any) => {
		var tempArr = [];
		var prevSelComp = this.state.selectedCompany;
		
		if(newValue != null){
			const report_s = this.state.report;
			
			tempArr.push({
				label: newValue.label,
				value: newValue.value
			});
			
			report_s['company'] = newValue.value;
			this.setState({ report: report_s });
			this.setState({selectedCompany: tempArr})
		}else{
			this.setState({selectedCompany: prevSelComp})
		}
	};
	
	
	handleCreate = (inputValue: any) => {
		this.setState({ isLoading: true });
		const report_s = this.state.report;
		setTimeout(() => {
		  const { company_suggestions } = this.state;
		  const newOption = this.createOption(inputValue);
		  
		report_s['company'] = newOption.value;
		  this.setState({
			isLoading: false,
			company_suggestions: [...company_suggestions, newOption],
			selectedCompany: newOption,
		  });
		}, 1000);
	  };

	createOption = (label: string) => ({
		label: label,
		value: label,
	});
    //handle marker change
    handleCoordinateChange = (lat, lng, zm) => {
        const report = this.state.report;
		report.address_latitude= lat;
		report.address_longitude= lng;
		this.state.zoom_level= zm;
    }
    updatePosition = () => {
        const marker = this.refmarker.current
        this.handleCoordinateChange(
            marker.leafletElement.getLatLng().lat,  
            marker.leafletElement.getLatLng().lng, 
            this.refmarker.current.contextValue.map._zoom
        );
        if (marker != null) {
            this.setState({
                marker: marker.leafletElement.getLatLng(),
            })
        }
    }

    getPosition = (e) => {
        const marker = this.refmarker.current
        this.handleCoordinateChange(
            e.latlng.lat,  
            e.latlng.lng, 
            this.refmarker.current.contextValue.map._zoom
        );
        if (marker != null) {
            this.setState({
                marker: e.latlng,
            })
        }
    }
	handleFieldChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.value;
		this.setState({ report: report });
	}

	handleRadioChange = event => {
		const report = this.state.report;
		report.sex = event.target.value;
		this.setState({ report: report });
	}

	
	handleAllowedChange = event => {
		const report = this.state.report;
		if(event.target.value === "1"){
			report.status = 1;
		}else{
			report.status = 2;
		}
		this.setState({ report: report });
	}


	handleDesignationChange = event => {
		const report = this.state.report;
		report.designation = event.target.value;
		this.setState({ report: report });
	}

	handleNationalityChange = selectedOption => {
        const report = this.state.report;
		report.nationality = selectedOption.value;
		this.setState({ report: report, selectedNationality: selectedOption });
	};


	handleDepartmentChange = selectedOption => {
        const report = this.state.report;
		report.department = selectedOption.value;
		this.setState({ report: report});
	};

	handleProvinceChange = selectedOption => {
        const report = this.state.report;
		report.address_province = selectedOption.value;
		this.setState({ report: report, selectedProvince: selectedOption });
	};

	handleCityChange = selectedOption => {
        const report = this.state.report;
		report.address_city = selectedOption.value;
		this.setState({ report: report, selectedCity: selectedOption });
	};

	handleBarangayChange = selectedOption => {
        const report = this.state.report;
		report.address_barangay = selectedOption.value;
		const barangay = Barangay_coords.find( ({ link_barangay, link_city }) => link_barangay === selectedOption.value && link_city === report.address_city);
		report.address_latitude = barangay.lat;
		report.address_longitude = barangay.long;
		this.setState({ 
			report: report, 
			selectedBarangay: selectedOption,
			map_open: true, 
		});
	};

	handleAgeYearChange = (values) => {
		const report = this.state.report;
		report.age = values.value;
		this.setState({ report });
	}

	handleAgeMonthChange = (values) => {
		const report = this.state.report;
		report.age_month = values.value;
		this.setState({ report });
	}

    handleMapOpen = () => {
        this.setState({
            map_open: true
        });
    };
  
    handleMapClose = () => {
        this.setState({
            map_open: false
        });
    };
	dateStr = d => {
		if(d == null){
			d = new Date();
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		const classes = {margin:1};
        const customMarker = L.icon({ 
			iconUrl: require('../../covcheck_logo.png'), 
			iconSize: [40,48], // size of the icon
			iconAnchor:   [20, 48], // point of the icon which will correspond to marker's location
			popupAnchor:  [0, -48] // point from which the popup should open relative to the iconAnchor
		});
		let filtered_city_options = [];
		let filtered_barangay_options = [];
		if(this.state.selectedProvince){
			filtered_city_options = options_cities.filter((o) => o.link === this.state.selectedProvince.value)
		}
		if(this.state.selectedCity){
			filtered_barangay_options = options_barangays.filter((o) => o.link === this.state.selectedCity.value)
		}
		return(
        	<div className="for_select" style={{ width: 350 }}>
				<Paper style = {{ padding: 10 }}>
					<Grid item xs={24} md={12} lg={12} xl={12}>
						<Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
							Add {this.state.report.classification} Employee
						</Typography>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Name
								</Typography>
								<TextField 
									disabled = {this.state.isLoading}
									value = {this.state.report.name}
									margin = "dense"
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={this.handleFieldChange('name')}
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorName}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Age
								</Typography>
								<Typography component="h1" variant="caption" color="textSecondary" align='left'>
									Year
								</Typography>
								<NumberFormat
									disabled = {this.state.isLoading}
									value={this.state.report.age}
									onValueChange={this.handleAgeYearChange}
									allowNegative={false}
									style={{ width: 300, height: 30, fontSize:18, paddingLeft:10 }}
									margin="dense"
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorAge}</h4>
								</td>
							</tr>
							{/* <tr>
								<td>
								{this.state.report.age == 0?
									<div>
									<Typography component="h1" variant="caption" color="textSecondary" align='left'>
										Month
									</Typography>
									<NumberFormat
										disabled = {this.state.isLoading}
										value={this.state.report.age_month}
										onValueChange={this.handleAgeMonthChange}
										allowNegative={false}
										style={{ width: 300, height: 30, fontSize:18, paddingLeft:10 }}
										margin="dense"
									/>
									</div>
									:null
								}
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorAge}</h4>
								</td>
							</tr> */}
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Sex
								</Typography>
								<FormControlLabel
									control={<Radio
										disabled = {this.state.isLoading}
										checked={this.state.report.sex === 'Female'}
										onChange={this.handleRadioChange}
										value="Female"
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Female' }}
									/>} 
									label="Female"
								/>
								&nbsp;&nbsp;&nbsp;
								<FormControlLabel
									control={<Radio
											disabled = {this.state.isLoading}
											checked={this.state.report.sex === 'Male'}
											onChange={this.handleRadioChange}
											value="Male"
											name="radio-button-male"
											color="primary" 
											inputProps={{ 'aria-label': 'Male' }}
										/>} 
									label="Male"
								/><br/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
										Nationality
									</Typography>
									<Select
										disabled = {this.state.isLoading}
										options={options_nationalities}
										value={this.state.selectedNationality }
										searchable
										placeholder="Select.."
										onChange={this.handleNationalityChange}
										styles={selectStyles}
									/>
									<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorNationality}</h4>
								</td>
							</tr>
							{/* <tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
										Company
									</Typography>
									<CreatableSelect
										isDisabled={this.state.isLoading}
										isLoading={this.state.isLoading}
										onChange={this.handleChange}
										onCreateOption={this.handleCreate}
										options={this.state.company_suggestions}
										value={this.state.selectedCompany}
										styles={selectStyles}
									/>
								</td>
							</tr> */}
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Designation
								</Typography>
								<FormControlLabel
									control={<Radio
										disabled = {this.state.isLoading}
										checked={this.state.report.designation === 'Staff'}
										onChange={this.handleDesignationChange}
										value="Staff"
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Staff' }}
									/>} 
									label="Staff"
								/>
								<FormControlLabel
									control={<Radio
										checked={this.state.report.designation === 'Faculty'}
										onChange={this.handleDesignationChange}
										value="Faculty"
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Faculty' }}
									/>} 
									label="Faculty"
								/>
								&nbsp;&nbsp;&nbsp;
								<FormControlLabel
								control={<Radio
										checked={this.state.report.designation === 'Student'}
										onChange={this.handleDesignationChange}
										value="Student"
										name="radio-button-male"
										inputProps={{ 'aria-label': 'Student' }}
									/>} 
								label="Student"
								/><br/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
										Unit/Office/Program
									</Typography>
									<Select
										options={options_departments}
										value={{value:this.state.report.department, label:this.state.report.department}}
										searchable
										placeholder="Select.."
										onChange={this.handleDepartmentChange}
										styles={selectStyles}
									/>
									<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorDepartment}</h4>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						<Typography component="h1" variant="subtitle1" color="primary" align='left' style={{paddingLeft:15, paddingTop:10}}>
							Address
						</Typography>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Select
									disabled = {this.state.isLoading}
									name="province"
									value={this.state.selectedProvince}
									onChange={this.handleProvinceChange}
									options={options_provinces}
									styles={selectStyles}
									placeholder="Province.."
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorProvince}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Select
									disabled = {this.state.isLoading}
									name="city"
									value={this.state.selectedCity}
									onChange={this.handleCityChange}
									options={filtered_city_options}
									styles={selectStyles}
									placeholder="City.."
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorCity}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Select
									disabled = {this.state.isLoading}
									name="barangay"
									value={this.state.selectedBarangay}
									onChange={this.handleBarangayChange}
									options={filtered_barangay_options}
									styles={selectStyles}
									placeholder="Barangay.."
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorBrgy}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<TextField
									disabled = {this.state.isLoading}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									label = "Sitio / Purok / Zone"
									onChange={this.handleFieldChange('address_sitio')}
									value = {this.state.report.address_sitio}
									margin="dense"
								/>
								</td>
							</tr>
							<tr>
								<td>
								<TextField
									disabled = {this.state.isLoading}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									label = "Street"
									onChange={this.handleFieldChange('address_street')}
									value = {this.state.report.address_street}
									margin="dense"
								/>
								</td>
							</tr>
							{/* <tr>
								<td>
									<Button 
										fullWidth 
										size="small" 
										variant="outlined" 
										color="primary" 
										onClick = {this.handleMapOpen}>
										Set Exact Location using Map
									</Button>
								</td>
							</tr> */}
						</tbody>
						</Table>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Contact No.
								</Typography>
								<TextField
									disabled = {this.state.isLoading}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={this.handleFieldChange('contact_number')}
									value = {this.state.report.contact_number}
									margin="dense"
									type='number'
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorContactNumber}</h4>
								</td>
							</tr>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Email
								</Typography>
								<TextField
									disabled = {this.state.isLoading}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={this.handleFieldChange('email')}
									value = {this.state.report.email}
									margin="dense"
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorEmail}</h4>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left' style={{paddingLeft:5}}>
									{this.state.report.classification === 'PUM-RT' ? 'Date Tested' : 'Started Monitoring'}
								</Typography>
								<TextField
									disabled = {this.state.isLoading}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									type = "date"
									InputProps={{inputProps: {max: moment().format("YYYY-MM-DD")} }}
									onChange={ this.handleFieldChange('date_responded') }
									value = { this.dateStr(this.state.report.date_responded) }
									style = {{ width: 300 }}
									InputLabelProps={{ shrink: true, }}
									error = { this.state.date_responded_error }
									helperText = { this.state.date_responded_error_text }
									margin="dense"
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorDateResponded}</h4>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left'>
									Status
								</Typography>
								<FormControlLabel
									control={<Radio
										disabled = {this.state.isLoading}
										checked={this.state.report.status === 1}
										onChange={this.handleAllowedChange}
										value={1}
										name="radio-button-female"
										inputProps={{ 'aria-label': 'Allowed' }}
									/>} 
									label="Allowed"
								/>
								&nbsp;&nbsp;&nbsp;
								<FormControlLabel
									control={<Radio
											disabled = {this.state.isLoading}
											checked={this.state.report.status === 2}
											onChange={this.handleAllowedChange}
											value={2}
											name="radio-button-male"
											color="primary" 
											inputProps={{ 'aria-label': 'Not Allowed' }}
										/>} 
									label="Not Allowed"
								/><br/>
								</td>
							</tr>
						</tbody>
						</Table>
						<Divider/>
						{/* {this.state.report.classification != "PUM-RT"?
						<div>
						<Divider/>
						<Table className="adPatient-table">
						<tbody>
							<tr>
								<td>
								<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom style={{paddingLeft:5}}>
									Admission / Quarantine Facility
								</Typography>
								<TextField
									disabled = {this.state.isLoading}
									id="outlined-basic" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={ this.handleFieldChange('admit_quarantine_facility') }
									style = {{ width: 300}}
									value = { this.state.report.admit_quarantine_facility }
									error = {this.state.aqfError}
									helperText = { this.state.aqfErrorText }
									margin="dense"
								/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorQuarantine}</h4>
								</td>
							</tr>
						</tbody>
						</Table>
						</div>:null} */}
						<Divider/>
						<br/>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<div align='left'>
						        	<Button align='left' size="large" variant="outlined" color='secondary'
						        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
						        			onClick={this.handleBack}>
					        			CANCEL</Button>
								</div>
							</Grid>
							<Grid item xs={12} md={6}>
								<div align='right' style={{ paddingBottom: 10 }}>
									<Button
										fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
										size='large'
										variant='contained'
	                                    color='primary'
										disabled = {this.state.isLoading}
										onClick={this.handleConfirm} >
											{this.state.isLoading? 'ADDING...' : 'ADD'}
									</Button>
								</div>
							</Grid>
						</Grid>
						</Grid>
					</Paper>
					{/* <div>
						<Dialog
							fullWidth
							maxWidth={'lg'}
							open={this.state.map_open}
							onClose={this.handleMapClose}
							aria-describedby="alert-dialog-description"
							repositionOnUpdate={false}
							keepMounted
						>
							<Map 
								center={[this.state.report.address_latitude,this.state.report.address_longitude]} 
								zoom={this.state.zoom_level}
								onClick = {this.getPosition}
								style={{width: '80vw', height: '80vh', margin: 'auto'}}
							>
								<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright" target = "_blank">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								<Marker
									icon={customMarker}
									draggable={true}
									onDragend={this.updatePosition}
									position={[this.state.report.address_latitude,this.state.report.address_longitude]}
									ref={this.refmarker}>
								</Marker>
								<Control position="bottomleft">
									<Button variant='contained' color='primary'
									 onClick={this.handleMapClose}>
										SAVE
									</Button>
								</Control>
							</Map>
						</Dialog>
			      	</div> */}
				</div>
		);
	}
}

const selectStyles = {
	menu: base => ({
		...base,
		zIndex: 100
	})
};

export default AddPatient;