import RaisedButton from 'material-ui/RaisedButton';
import React from 'react';
import TextField from 'material-ui/TextField';

const ChangePasswordComponent = props => (
	<>
		<div className="card">
			<div>
				<TextField
					floatingLabelText="Current Password"
					hintText="Current Password"
					value={props.currPassword}
					onChange={props.onCurrPasswordChange}
					type="password"
				/>
			</div>
			<div>
				<TextField
					floatingLabelText="New Password"
					hintText="New Password"
					value={props.password}
					onChange={props.onPasswordChange}
					type="password"
				/>
			</div>
			<div>
				<TextField
					floatingLabelText="Confirm Password"
					hintText="Confirm Password"
					value={props.confirmPassword}
					onChange={props.onConfirmPasswordChange}
					type="password"
				/>
			</div>
		</div>
		<div style={{color: props.success ? 'green' : 'red', 'font-size': '0.9em'}}>
			<br/>
			{props.errors}
			{props.success &&
				<>
					<br/><br/>
					Redirecting to View Reports...
				</>
			}
		</div>
		{ !props.success && 
		<RaisedButton
			primary={true}
			onClick={props.onSubmit}
			style={{margin: 35}}
		>
			Confirm
		</RaisedButton> }
	</>
);

export default ChangePasswordComponent;
