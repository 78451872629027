import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';

class DeleteReport extends Component {
	state = {
		report: {},
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		event.preventDefault();

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}
				});
		}
	}

	render() {
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<p>Delete report by {this.state.report.name}?</p>
						</div>
						<div>
							<br/>
		                    <Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
		                    &nbsp;&nbsp;&nbsp;
		                    <Button size="small" variant="contained" color="primary" onClick={this.handleConfirm}>Confirm</Button>
						</div>
					</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default DeleteReport;
