import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];

function valuetext(value) {
    return `${value}°C`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Contacts extends Component {
    state = {
        errorText: ""
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    };

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        if(this.props.values.contact_number.trim() == ""){
            this.setState({ errorText: "Input is required" });
        }else{
			if(this.props.values.contact_number > 1){
				if(this.props.values.contact_number.length == 11 || this.props.values.contact_number.length == 7){
					this.setState({ errorText: "" });
					this.props.nextStep();
				}
				else{
					this.setState({ errorText: "Contact number is invalid (Format: Telephone (1234567), Celphone (09123456789))" });
				} 
            }else{
                this.setState({ errorText: "Contact number is invalid (Format: Telephone (1234567), Celphone (09123456789))" });
            }      
        }
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, handleChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>

                    <div className="wrapper">
                        <div className = "progress">
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
                            <div className = "progress_change">
                                <h2>Personal Information</h2>
                            </div>
                            <h1>Please enter your cellphone or telephone number.</h1>
                            <p></p>
                            <TextField
                                type = 'number'
                                label = "Telephone or Celphone"
                                placeholder = "ex: 1234567 or 09123456789"
                                onChange={handleChange('contact_number')}
                                defaultValue = {values.contact_number}
                                style={{ width: 300, marginLeft: 10, padding: 0}}
                                margin="dense"
                                errorText = {this.state.errorText}
                                multiline
                            /><br/>

                            <div class="bot_nav">
                                <button class = "button-backchoice"
                                    primary = {true}
                                    onClick = {this.back}>
                                        &#60;  Back
                                </button>
                                <button class = "button-nextchoice"
                                    primary = {true}
                                    onClick = {this.continue}>
                                        Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Contacts;
