import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class UpdateCaseDeceased extends Component {
	state = {
		case_data: {},
		date_deceased: null,
	}

	componentDidMount(){
		window.scrollTo(0,0);

		const caseId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ case_data: result.data });
				});
		} else {
			const casesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}` : `https://covidcheck.upcebu.edu.ph/api/cases/${caseId}`;
			
			axios(casesUrl, {withCredentials: true})
				.then(result => {
					this.setState({ case_data: result.data });
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-cases');
	}

	handleConfirm = event => {
		event.preventDefault();

		const case_data = this.state.case_data;
		case_data.status = "Deceased";
		const caseId = case_data.id;

		if(case_data.date_deceased == ""){
			case_data.date_deceased = null;
		}

		delete case_data.id;
		delete case_data.name;
		delete case_data.age;
		delete case_data.sex;
		delete case_data.nationality;
		delete case_data.address_barangay;
		delete case_data.address_city;

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}`, case_data, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-cases');
					}
				});
		} else {
			const casesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/cases/${caseId}` : `https://covidcheck.upcebu.edu.ph/api/cases/${caseId}`;
			
			axios.put(casesUrl, case_data, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-cases');
					}
				});
		}
	}

	handleChange = field => event => {
		const case_data = this.state.case_data;
		case_data[field] = event.target.value;
		this.setState({ case_data: case_data });
	}

	dateStr = d => {
		if(d == null){
			return "";
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<p>Set status of {this.state.case_data.name} to DECEASED. Please fill-out additional information.</p>
							<br/>
						</div>
						<div>
		                    <TextField
		                        label = "Date Deceased"
		                        type = "date"
		                        onChange={ this.handleChange('date_deceased') }
		                        value = { this.dateStr(this.state.case_data.date_deceased) }
		                        style = {{ width: 250 }}
		                        InputLabelProps={{ shrink: true, }}
		                    />
		                    <br/>
						</div>
						<div>
							<br/>
		                    <Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
		                    &nbsp;&nbsp;&nbsp;
		                    <Button size="small" variant="contained" color="primary" onClick={this.handleConfirm}>Confirm</Button>
						</div>
					</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateCaseDeceased;
