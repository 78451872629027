import React from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {
  Link
} from "react-router-dom";

const LoginComponent = props => (
	<>
		<div className="card">
			<div>
				<TextField
					floatingLabelText="Email"
					hintText="Email"
					value={props.email}
					onChange={props.onEmailChange}
				/>
			</div>
			<div>
				<TextField
					floatingLabelText="Password"
					hintText="Password"
					value={props.password}
					type="password"
					onChange={props.onPasswordChange}
				/>
			</div>
			<br/>
			{/* <div style={{ position: 'absolute', left: '49%', transform: 'translate(-50%, -50%)'}}>
                <Link to = '/hsu-forgot-password' style={{ color: '#e6675c', fontSize: '14px'}}>
                    Forgot Password?
                </Link>
            </div> */}
		</div>
		<RaisedButton
			primary={true}
			onClick={props.onLogin}
			style={{margin: 35}}
		>
			Login
		</RaisedButton>
		<div style={{color: 'red', 'font-size': '0.9em'}}>
			{props.errors}
		</div>
	</>
);

export default LoginComponent;
