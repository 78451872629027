import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];

function valuetext(value) {
    return `${value}°C`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Symptoms_Details extends Component {
    state = {
        errorText: ""
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        if(this.props.values.s_chills
            ||this.props.values.s_cough
            ||this.props.values.s_diarrhea
            ||this.props.values.s_fatigue
            ||this.props.values.s_fever
            ||this.props.values.s_headache
            ||this.props.values.s_muscle_joint_pain
            ||this.props.values.s_phlegm
            ||this.props.values.s_short_breath
            ||this.props.values.s_sore_throat
            ||this.props.values.s_stuffed_nose
            ||this.props.values.s_vomiting
            ){
                this.setState({ errorText: "" });
				this.props.confirmSymptoms();
        }else{
			this.props.denySymptoms();
            //this.setState({ errorText: "Pumili ng symptomas" });
        }
    };

    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, handleCheckChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress">
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={3}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Symptoms</h2>
							</div>
							<h1>Have you experienced any of these symptoms today or in the last 14 days? Select all that apply.</h1>
							<br/>
							<div className = "checkbox_layout">
								<Checkbox
									label="Fever (Hilanat)"
									value="s_fever"
									onCheck={handleCheckChange('s_fever')}
									checked={values.s_fever}
								/>
								<br/>
								<Checkbox
									label="Cough (Gahi nga ubo)"
									value="s_cough"
									onCheck={handleCheckChange('s_cough')}
									checked={values.s_cough}
								/>
								<br/>
								<Checkbox
									label="Sore Throat (Sakit ang tutunlan)"
									value="s_sore_throat"
									onCheck={handleCheckChange('s_sore_throat')}
									checked={values.s_sore_throat}
								/>
								<br/>
								<Checkbox
									label="Shortness of Breath (Lisud ug ginhawa)"
									value="s_short_breath"
									onCheck={handleCheckChange('s_short_breath')}
									checked={values.s_short_breath}
								/>
								<br/>
								<Checkbox
									label="Fatigue (Kakapoy sa panglawas)"
									value="s_fatigue"
									onCheck={handleCheckChange('s_fatigue')}
									checked={values.s_fatigue}
								/>
								<br/>
								<Checkbox
									label="Phlegm(Plema)"
									value="s_phlegm"
									onCheck={handleCheckChange('s_phlegm')}
									checked={values.s_phlegm}
								/>
								<br/>
								<Checkbox
									label="Muscle or Joint Pain (Sakit ang kalawasan)"
									value="s_muscle_joint_pain"
									onCheck={handleCheckChange('s_muscle_joint_pain')}
									checked={values.s_muscle_joint_pain}
								/>
								<br/>
								<Checkbox
									label="Headache (Labad ang ulo)"
									value="s_headache"
									onCheck={handleCheckChange('s_headache')}
									checked={values.s_headache}
								/>
								<br/>
								<Checkbox
									label="Chills (Pagkurog sa kalawasan)"
									value="s_chills"
									onCheck={handleCheckChange('s_chills')}
									checked={values.s_chills}
								/>
								<br/>
								<Checkbox
									label="Vomiting (Nagsuka-suka)"
									value="s_vomiting"
									onCheck={handleCheckChange('s_vomiting')}
									checked={values.s_vomiting}
								/>
								<br/>
								<Checkbox
									label="Stuffy Nose (Ping-ot ang ilong)"
									value="s_stuffed_nose"
									onCheck={handleCheckChange('s_stuffed_nose')}
									checked={values.s_stuffed_nose}
								/>
								<br/>
								<Checkbox
									label="Diarrhea (Kalibanga)"
									value="s_diarrhea"
									onCheck={handleCheckChange('s_diarrhea')}
									checked={values.s_diarrhea}
								/>
							</div>
                            <h3 style = {{fontSize: 12, color: "red"}}>{this.state.errorText}</h3>
							<div>
								<button class = "button-backchoice"
									primary = {true}
									onClick = {this.back}>
										&#60; Back
								</button>
								<button class = "button-nextchoice"
									primary = {true}
									onClick = {this.continue}>
										Continue
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Symptoms_Details;
