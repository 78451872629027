import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Button from '@material-ui/core/Button';

class UpdateTestingInformation extends Component {
	state = {
		report: {},
		dateChanged: false,
		loading:false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
				})
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
				})
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		this.setState({ loading: true });
		event.preventDefault();

		if(this.state.report.date_testing == null && this.state.dateChanged == false){
			const report = this.state.report;
			report.date_testing = new Date();
			this.setState({ report: report });
		}

		const body = {
			"date_testing": this.state.report.date_testing,
			"testing_center": this.state.report.testing_center
		}
		if(this.state.report.status < 2){
			body.status = 2;
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false });
					}
				})
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false });
					}
				})
		}
	}

	handleChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.value;
		this.setState({ report: report });

		if (field == 'date_testing'){
			this.setState({ dateChanged: true });
		}
	}

	dateStr = date_testing => {
		if(date_testing == null){
			date_testing = new Date();
		}
		const dateNow = new Date(date_testing);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<TextField
		                        floatingLabelText = "Date of Testing"
		                        type = "date"
		                        onChange={ this.handleChange('date_testing') }
		                        value = { this.dateStr(this.state.report.date_testing) }
		                        InputLabelProps={{ shrink: true, }}
		                    />
		                    <br/>
		                    <TextField
		                        floatingLabelText = "Testing Center"
		                        onChange={ this.handleChange('testing_center') }
		                        value = { this.state.report.testing_center }
		                    />
						</div>
						<div>
		                    <br/>
		                    <Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
		                    &nbsp;&nbsp;&nbsp;
							<Button 
							size="small" 
							variant="contained" 
							color="primary" 
							onClick={this.handleConfirm}
							disabled = {this.state.loading}>
								{this.state.loading? "SAVING..":"SAVE"}
							</Button>
						</div>
			      	</div>
		      	</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateTestingInformation;
