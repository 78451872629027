import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ContactsIcon from '@material-ui/icons/Contacts';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import copy from 'copy-to-clipboard';

const DoctorActionMenu = (props) => {
	const item = props.row;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openDelete, setOpenDelete] = React.useState(false);
	const [openResetPassword, setOpenResetPassword] = React.useState(false);
	const [newPassword, setNewPassword] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenResetPassword = () => {
		setOpenResetPassword(true);
		setAnchorEl(null);
	}

	const handleCloseResetPassword = () => {
		setOpenResetPassword(false);
	}

	const handleResetPassword = () => {
		if(newPassword){
			copy(newPassword);
			setOpenResetPassword(false);
		}else{
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios.post(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${item.id}/resetPassword`, {}, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 201){
							setNewPassword(result.data.password);
						}
					});
			} else {
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${item.id}/resetPassword` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${item.id}/resetPassword`;
				axios.post(url, {}, {withCredentials: true})
					.then(result => {
						if(result.status === 201){
							setNewPassword(result.data.password);
						}
					});
			}
		}
	}

	const handleOpenDelete = () => {
		setOpenDelete(true);
		setAnchorEl(null);
	}

	const handleCloseDelete = () => {
		setOpenDelete(false);
	}

	const handleDelete = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${item.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						props.filterDoctors();
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${item.id}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${item.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						props.filterDoctors();
					}
				});
		}
	}

	return(
		<React.Fragment>
			<Button size="small" 
					aria-controls="actions-menu" 
					aria-haspopup="true" 
					startIcon={<ArrowForwardIosIcon/>}
					onClick={handleClick}>
			</Button>
			<Menu
				id="actions-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem component={Link} to={`/qb-update-doctor/${item.id}`}>
					Update
				</MenuItem>
				<MenuItem onClick={handleOpenResetPassword}>
					Reset Password
				</MenuItem>
				<Dialog
	        		fullWidth={true}
	        		maxWidth={'xs'}
					open={openResetPassword}
					keepMounted
					onClose={handleCloseResetPassword}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">Reset Password</DialogTitle>
					<DialogContent>
						<Typography component="h2" variant="subtitle1" color="textPrimary" gutterBottom>
					 		{ newPassword ?
					 		 'New Password - '+newPassword :
					 		 'Reset Password of '+item.last_name+', '+item.first_name+' '+item.middle_name+' with username - '+item.username}
				    	</Typography>
				    	<br/>
					</DialogContent>
					<DialogActions>
						{(() => {
							if(!newPassword){
								return(
									<Button onClick={handleCloseResetPassword} color="primary">
										Cancel
									</Button>
								);
							}
						})()}
						<Button onClick={handleResetPassword} color="secondary">
							{ newPassword ? 'Ok' : 'Reset'}
						</Button>
					</DialogActions>
				</Dialog>
				<MenuItem onClick={handleOpenDelete}>
					Delete
				</MenuItem>
				<Dialog
	        		fullWidth={true}
	        		maxWidth={'xs'}
					open={openDelete}
					keepMounted
					onClose={handleCloseDelete}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">Delete Buddy</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Delete selected Buddy - {item.last_name}, {item.first_name} {item.middle_name}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDelete} color="primary">
							Cancel
						</Button>
						<Button onClick={handleDelete} color="secondary">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</Menu>
		</React.Fragment>
	);
}

export default DoctorActionMenu;