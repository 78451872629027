import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';
import {
  Link
} from "react-router-dom";
import RaisedButton from 'material-ui/RaisedButton';
import Typography from '@material-ui/core/Typography';

import ChangePasswordComponent from './ChangePasswordComponent';

class PersonnelChangePassword extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			currPassword: '',
			password: '',
			confirmPassword: '',
			errors: '',
			success: false,
			successMsg: 'Your password has been changed successfully!',
		}
	}

	onSubmit = () => {
		if(this.state.email.trim() && this.state.currPassword.trim() && this.state.password.trim() && this.state.confirmPassword.trim()){
		}else{
			return this.setState({
				errors: 'Please fill-out all fields'
			});
		}

		if (this.state.password !== this.state.confirmPassword) {
			return this.setState({
				errors: 'New passwords do not match!'
			});
		}

		if (process.env.NODE_ENV === 'development') {
			const username = this.state.email.trim();
            const password = this.state.currPassword.trim();
            const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            const head = {'Authorization': `Basic ${token}`}
            axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates/auth`, {headers: head})
                .then(result => {
                	const reportId = result.data.report_id;
                	const body = {
                		old_password: this.state.currPassword.trim(),
                		new_password: this.state.password.trim()
                	};
                	axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, body, {headers: head})
                		.then(result => {
                			this.setState({
                				success: true
                			});
                		}).catch(error => {
                			this.setState({
                				errors: 'Something went wrong'
                			});
                		});
                }).catch(error =>{
                    this.setState({
                    	errors: 'Incorrect Email or Current Password'
                    });
                });
		} else {
			const authUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates/auth' : 'https://covidcheck.upcebu.edu.ph/api/reportUpdates/auth';
			const changePassUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 'https://covidcheck.upcebu.edu.ph/dev/api/reports/' : 'https://covidcheck.upcebu.edu.ph/api/reports/'
			const username = this.state.email.trim();
            const password = this.state.currPassword.trim();
            const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            const head = {'Authorization': `Basic ${token}`}
            axios.get(authUrl, {headers: head})
                .then(result => {
                	const reportId = result.data.report_id;
                	const body = {
                		old_password: this.state.currPassword.trim(),
                		new_password: this.state.password.trim()
                	};
                	axios.put(`${changePassUrl}${reportId}`, body, {headers: head})
                		.then(result => {
                			this.setState({
                				success: true
                			});
                		}).catch(error => {
                			this.setState({
                				errors: 'Something went wrong'
                			});
                		});
                }).catch(error =>{
                    this.setState({
                    	errors: 'Incorrect Email or Current Password'
                    });
                });
		}
	}

	onEmailChange = event => {
		this.setState({
			email: event.target.value
		});
	}

	onCurrPasswordChange = event => {
		this.setState({
			currPassword: event.target.value
		});
	}

	onPasswordChange = event => {
		this.setState({
			password: event.target.value
		});
	}

	onConfirmPasswordChange = event => {
		this.setState({
			confirmPassword: event.target.value
		});
	}

	render() {
		return (
			<MuiThemeProvider>
			<div className="wrapper_home">
				<div className="typo-divider">
					Change Password
				</div>
				{this.state.success ?
					<>
						<div className='wrapper_login'>
							<h3>{this.state.successMsg}</h3>
							<br/><br/>
							<div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)'}}>
								<Link to="/">
									<button class = "button-return-to-home"
										primary = {true}>
										Return to Home
								</button>
								</Link>
							</div>
							<br/><br/><br/><br/><br/>
						</div>
					</> :
					<>
						<div className="wrapper_login">
							<ChangePasswordComponent
								onSubmit={this.onSubmit}
								onEmailChange={this.onEmailChange}
								onCurrPasswordChange={this.onCurrPasswordChange}
								onPasswordChange={this.onPasswordChange}
								onConfirmPasswordChange={this.onConfirmPasswordChange}
								errors={this.state.errors}
								email={this.state.email}
								currPassword={this.state.currPassword}
								password={this.state.password}
								confirmPassword={this.state.confirmPassword}
							/>
						</div>
					</> }
			</div>
			</MuiThemeProvider>
		)
	}
}

export default PersonnelChangePassword;
