import React, { Component } from 'react';
import axios from 'axios';
import './QB_Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SelectReact from 'react-select';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 10px;

	td {
		padding: 5px;
	}
`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const BlueCheckbox = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyleRadio = withStyles({
    root: {
      color: '#4C6FEE',
      '&$checked': {
        color: '#4C6FEE',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const customStyles = {
  control: (base, state) => ({
      ...base,
	 background: 'white',
	 height: 56,
      borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
      "&:hover": {
        borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
      }
    })
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4C6FEE',
    },
	'& .MuiInput-underline-root': {
		'& fieldset': {
			borderBottomColor: '#4C6FEE',
		},
		'&:after': {
		    borderBottomColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderBottomColor: '#4C6FEE',
		},
    },
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderColor: '#3AD5C1',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#4C6FEE',
		},
    },
  },
})(TextField);


const qf_options = [
	{ value: 'CBC', label: 'CBC' },
	{ value: 'Urinalysis', label: 'Urinalysis' },
	{ value: 'COVID Rapid Test', label: 'COVID Rapid Test' },
	{ value: 'COVID RT-PCR', label: 'COVID RT-PCR' }
]

const result_options = [
	{ value: 'Pending', label: 'Pending' },
	{ value: 'Positive', label: 'Positive' },
	{ value: 'Negative', label: 'Negative' }
]

class EditLabTest extends Component {
	state = {
        userRole: cookies.get('qb_role'),
        id:'',
        doctor_id: parseInt(cookies.get('qb_id')),

		date_time:null,
        patient_id: parseInt(this.props.match.params.patient_id),
		type:'',
		result:'',
		image:null,
        remarks:'',
        deleted:false,

		other:false,
		testError:'',
		resultError:'',
		disabled:false,
		result_selected:'',

		lab_test_id:parseInt(this.props.match.params.lab_test_id)

    }

	componentDidMount() {
		window.scrollTo(0, 0);
        const patient_id = this.props.match.params.patient_id;
        const lab_test_id = this.props.match.params.lab_test_id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patient_id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
                    var name = result.data.first_name;
                    if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
                        name = result.data.last_name + ", " + result.data.first_name + " " + result.data.middle_name;
                    }
                    this.setState({
                        patient_name: name,
                        medication: result.data.medications,
                        case_no: result.data.case_number,
                    });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patient_id}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patient_id}`;
			axios(url, {withCredentials: true})
				.then(result => {
                    var name = result.data.first_name;
                    if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
                        name = result.data.last_name + ", " + result.data.first_name + " " + result.data.middle_name;
                    }
                    this.setState({
                        patient_name: name,
                        medication: result.data.medications,
                        case_no: result.data.case_number,
                    });
					//this.setState({ patient: result.data });
				});
		}


		let url = `/api/qb/patients/${patient_id}/tests/${lab_test_id}`;
        if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						date_time:result.data.date_time,
						patient_id: parseInt(this.props.match.params.patient_id),
						type:result.data.type,
						result:result.data.result,
						image:result.data.image,
						remarks:result.data.remarks,
						deleted:result.data.deleted,

						type_selected:{ value: result.data.type, label: result.data.type },
					});

					// console.log(result);

				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						date_time:result.data.date_time,
						patient_id: parseInt(this.props.match.params.patient_id),
						type:result.data.type,
						result:result.data.result,
						image:result.data.image,
						remarks:result.data.remarks,
						deleted:result.data.deleted,
						type_selected:{ value: result.data.type, label: result.data.type },

					});


					// console.log(result);
				});
		}

	};

	fileSelectedChange = event => {
		this.setState({
			image:event.target.files
		});
	}

	handleQFChange = selectedOption  => {
		// console.log(selectedOption)
		this.setState({
			type_selected: selectedOption,
			type: selectedOption.value,
		});

		if(selectedOption.value=="COVID Rapid Test"||
		selectedOption.value == "COVID RT-PCR"){
			this.setState({
				result: "Pending",
			});
		}else{
			this.setState({
				result: "",
			});
		}
	}

	handleResultChange = selectedOption  => {
		// console.log(selectedOption)
		this.setState({
			result: selectedOption.value,
		});
	}

	handleFieldChange = input => e => {
        this.setState({[input]: e.target.value});
	}
    //handle textview number input change
    handleNumberChange = (value) => {
        this.setState({temperature: value.floatValue});
    }
    //Handle checkbox input change
    handleCheckChange = name => e => {
        this.setState({[name]: e.target.checked});
    };
    //handle yes no input
	handleChange_testing = (decision) => (event) => {
        this.setState({maintenance_medications_taken: decision});
    }

	handleSave = e => {
		var valid = true;
        const date = new Date();
		var bodyFormData = new FormData();

        bodyFormData.append('deleted', false);
        bodyFormData.append('type', this.state.type);
        bodyFormData.append('result', this.state.result);
        bodyFormData.append('remarks', this.state.remarks);
        bodyFormData.append('image', this.state.image);

		if(this.state.type == ""){
			valid = false;
			this.setState({
				testError: 'Select a test'
			})
		}else{
			this.setState({
				testError: ''
			})
		}


		if(this.state.result == ""){
			valid = false;
			this.setState({
				resultError: 'Input results of test'
			})
		}else{
			this.setState({
				resultError: ''
			})
		}

		if(valid){
            this.setState({disabled: true});
		var xhr = new XMLHttpRequest()
		const username = 'admin';
		const password = 'V09VL7';
        if (process.env.NODE_ENV === 'development') {
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            axios({
                method: 'put',
                url:  `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${this.state.patient_id}/tests/${this.state.lab_test_id}`,
                data: bodyFormData,
                headers: {'Authorization': `Basic ${token}`}
                })
                .then((response) => {
                    //handle success
                    this.props.history.push(`/qb-patient-lab-tests/${this.state.patient_id}`);
                })
                .catch((response) => {
					this.setState({disabled: false});
                    //handle error
                });
        }else{
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
            const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?  `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${this.state.patient_id}/tests/${this.state.lab_test_id}` :  `https://covidcheck.upcebu.edu.ph/api/qb/patients/${this.state.patient_id}/tests/${this.state.lab_test_id}`;
			axios({
                method: 'put',
                url: reportUpdatesUrl,
                data: bodyFormData,
                headers: {'Authorization': `Basic ${token}`}
                })
                .then((response) => {
                    //handle success
					this.props.history.push(`/qb-patient-lab-tests/${this.state.patient_id}`);
                })
                .catch((response) => {
					this.setState({disabled: false});
                    //handle error
                });
			// axios.post(reportUpdatesUrl, pass,{headers: {'Authorization': `Basic ${token}`}})
            //     .then(response => {

            //     })
            //     .catch(error => {
            //     })
		}
		}
    };

    render() {
		const classes = {margin:1};
        return (
			<Paper style = {{ padding: 10 }}>
				<Grid item xs={24} md={12} lg={12} xl={12}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Patient Details
					</Typography>
                    <Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
								<CssTextField
                                    disabled = {true}
									value = {this.state.patient_name}
									id="outlined-basic"
									label="Name"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
                            <td>
								<CssTextField
                                    disabled = {true}
									value = {this.state.case_no}
									id="outlined-basic"
									label="Case Number"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
                        Laboratory Test
                    </Typography>
					<Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
								<SelectReact
									styles={customStyles}
									options={qf_options}
									value={this.state.type_selected}
									searchable
									placeholder="Type of Test"
									fullWidth={true}
									onChange={this.handleQFChange}
								/>
								{this.state.testError}
							</td>
							{this.state.type === "COVID Rapid Test"||
							this.state.type === "COVID RT-PCR"
							?
							<td>
								<SelectReact
								styles={customStyles}
								options={result_options}
								value= {{ value: this.state.result, label: this.state.result }}
								searchable
								placeholder="Test Results"
								fullWidth={true}
								onChange={this.handleResultChange}
							/>
								{this.state.resultError}
							</td>
							:
							<td>
								<CssTextField
								onChange={this.handleFieldChange('result')}
								value = {this.state.result}
								id="outlined-basic"
								label="Test Results"
								variant="outlined"
								helperText = {this.state.tempErrorText}
								fullWidth={true}
								className={classes.margin}
								InputLabelProps={{
									shrink: true,
									className: classes.floatingLabelFocusStyle,
								}}
								helperText = {this.state.resultError}
								/>
							</td>
							}

						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Typography component="h1" variant="subtitle1" color="textSecondary" style={{ paddingLeft: 20 }}>
						Supporting Image
					</Typography>
					<div style={{ padding: 15 }}>
						<input class="custom-file-upload" style = {{width: '100%', height: '55px'}} type = 'file' onChange = {this.fileSelectedChange} accept="image/png, image/jpeg"/>
					</div>
					<br/>
					<Divider/>
					<br/>
					<Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
							<CssTextField
								onChange={this.handleFieldChange('remarks')}
								value = {this.state.remarks}
								id="outlined-basic"
								label="Remarks"
								variant="outlined"
								fullWidth={true}
                                multiline
								className={classes.margin}
								InputLabelProps={{
									shrink: true,
									className: classes.floatingLabelFocusStyle,
								}}
							/>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left'>
					        	<Button align='left' size="large" variant="outlined" color='secondary'
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			onClick={() => this.props.history.push(`/qb-patient-lab-tests/${this.state.patient_id}`) }>
				        			CANCEL</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									size='large'
									variant='contained'
                                    color='primary'
                                    disabled={this.state.disabled}
									onClick = {this.handleSave}>
										{this.state.disabled? 'SAVING...' : 'SAVE'}
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
        );
    };
}

export default EditLabTest;
