import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Select from 'react-select';
import options_nationalities from '../Nationalities';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];

function valuetext(value) {
    return `${value}°C`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Nationality extends Component {
    state = {
        errorText : ""
    }
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        if(this.props.values.nationality.trim() == ""){
            this.setState({ errorText: "Input is required" });
        }else{
            this.setState({ errorText: "" });
            this.props.nextStep();
        }
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };


	handleChange = selectedOption => {
        this.props.handleNationality(selectedOption.value, selectedOption)
	};

    render() {
        const {values, handleChange, handleNationality } = this.props;
		const { selectedOption } = this.state;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress">
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Personal Information</h2>
							</div>
                            <h1>Please enter your nationality.</h1>

							<br/>
							<br/>
							<div className = "for_select">
								<Select
									options={options_nationalities}
									value={this.props.values.selected_nationality}
									searchable
									placeholder="Select.."
									onChange={this.handleChange}
									style={{ width: 30}}
								/>
							</div>
                            <h4 style = {{fontSize: 12, color: "red"}}>{this.state.errorText}</h4>
							<br/>
							<div class="bot_nav">
								<button class = "button-backchoice"
									primary = {true}
									onClick = {this.back}>
										&#60;  Back
								</button>
								<button class = "button-nextchoice"
									primary = {true}
									onClick = {this.continue}>
										Continue
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

export default SelfAssessment_Nationality;
