import React from 'react';

import { Typography } from '@material-ui/core';

const SARIReclassPrompt = () => {
	return(
		<>
			<Typography variant='body1' style={{ color: '#242424' }} >Thank you for sending an update on your health status.</Typography>
			<Typography variant='body1' style={{ color: '#242424' }} >Based on your responses, there is a need to monitor your health.
				<br/><br/>For now, you are advised to stay at home, rest and get better. You will be required to <b>send your health updates daily</b> through CovidCheck while you are at home.
				<br/><br/>Please call the University Health Services Unit immediately for assistance and instructions through this office number: 032 232 2642 local 305.
				<br/><br/><b>Thank you</b> for cooperating with us in this fight against COVID-19.
			</Typography>
		</>
	);
}

export default SARIReclassPrompt;