import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Medical_Condition extends Component {
	state = {
		other: false
	}

	otherCheck = name => e => {
		this.setState({[name]: e.target.checked});
    };

	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
   
    render() {
        const {values, handleCheckChange, handleChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress"> 
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={2}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Exposure</h2>
							</div>
                            <h1>Do you have any of these medical conditions? Select all that apply.</h1>
                            <br/>
							<div className = "checkbox_layout2">
								<Checkbox
									label="Cancer"
									value="m_cancer" 
									onCheck={handleCheckChange('m_cancer')} 
									checked={values.m_cancer}
								/>
								<br/>
								<Checkbox
									label="Tuberculosis or TB"
									value="m_tuberculosis" 
									onCheck={handleCheckChange('m_tuberculosis')} 
									checked={values.m_tuberculosis}
								/>
								<br/>
								<Checkbox
									label="Asthma"
									value="m_asthma" 
									onCheck={handleCheckChange('m_asthma')} 
									checked={values.m_asthma}
								/>
								<br/>
								<Checkbox
									label="Diabetes"
									value="m_diabetes" 
									onCheck={handleCheckChange('m_diabetes')} 
									checked={values.m_diabetes}
								/>
								<br/>
								<Checkbox
									label="High blood pressure"
									value="m_high_blood_pressure" 
									onCheck={handleCheckChange('m_high_blood_pressure')} 
									checked={values.m_high_blood_pressure}
								/>
								<br/>
								<Checkbox
									label="Renal Failure"
									value="m_renal_failure" 
									onCheck={handleCheckChange('m_renal_failure')} 
									checked={values.m_renal_failure}
								/>
								<br/>
								<Checkbox
									label="Immunodeficiency conditions (AIDS)"
									value="m_immunodeficiency_conditions" 
									onCheck={handleCheckChange('m_immunodeficiency_conditions')} 
									checked={values.m_immunodeficiency_conditions}
								/>
								{values.sex == "Female"?
								<>
								<br/>
								<Checkbox
									label="Are you pregnant? (Check if yes)"
									value="m_pregnancy" 
									onCheck={handleCheckChange('m_pregnancy')} 
									checked={values.m_pregnancy}
								/>
								</>
								:null}
								<br/>
								<br/>
								<Checkbox
									label="Others"
									value="others" 
									onCheck={this.otherCheck("other")} 
									checked={this.state.other}
								/>
							</div>
							
							{this.state.other?
									<TextField
									floatingLabelText = "Other Medical Conditions"
									onChange={handleChange('m_others')}
									defaultValue = {values.m_others}
									/>
									:null
							}
							<br/>
							<br/>
							<div>
								<button class = "button-backchoice" 
									primary = {true} 
									onClick = {this.back}>
										&#60;  Back
								</button>
								<button class = "button-nextchoice" 
									primary = {true} 
									onClick = {this.continue}>
										Continue
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Medical_Condition;
