import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import Button from '@material-ui/core/Button';

import {
    Divider, Paper, Typography, TextField, Grid
} from '@material-ui/core';

const mobilePhonesBreakPoint = 400;

class UpdateComorbidity extends Component {
	state = {
		report: {},
		loading: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ report: result.data });
				})
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					this.setState({ report: result.data });
				})
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		this.setState({ loading: true, });
		event.preventDefault();
		const report = this.state.report;
		const reportId = report.id;
		delete report.id;

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, report, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false, });
					}
				}).catch(() => {
					const r = this.state.report;
					r.id = reportId;
					this.setState({ report: r, loading: false, });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios.put(url, report, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false, });
					}
				}).catch(() => {
					const r = this.state.report;
					r.id = reportId;
					this.setState({ report: r, loading: false, });
				});
		}
	}

	handleCheckChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.checked;
		this.setState({ report: report });
	}

	handleChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.value;
		this.setState({ report: report });
	}

	render() {
		const classes = {margin:1};
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div className="for_select" align="left" style={{ width: 350}}>
                        <Paper style={{padding: 10}}>
                            <Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
                                Update Comorbidities
                            </Typography>
                            <Divider/>
		                    <Typography component="h1" variant="subtitle1" color="textSecondary" style={{ padding: 10 }} align='left' gutterBottom>
                                Patient: {this.state.report.name}
                            </Typography>
                            <div className="for_select" style={{paddingLeft: 10}}>
							<Checkbox
								color="primary"
                                label="Cancer"
                                value="m_cancer"
                                onCheck={this.handleCheckChange('m_cancer')}
                                checked={this.state.report.m_cancer}
                            />
                            <br/>
                            <Checkbox
                            	color="primary"
                                label="Tuberculosis or TB"
                                value="m_tuberculosis"
                                onCheck={this.handleCheckChange('m_tuberculosis')}
                                checked={this.state.report.m_tuberculosis}
                            />
                            <br/>
                            <Checkbox
                            	color="primary"
                                label="Asthma"
                                value="m_asthma"
                                onCheck={this.handleCheckChange('m_asthma')}
                                checked={this.state.report.m_asthma}
                            />
                            <br/>
                            <Checkbox
                            	color="primary"
                                label="Diabetes"
                                value="m_diabetes"
                                onCheck={this.handleCheckChange('m_diabetes')}
                                checked={this.state.report.m_diabetes}
                            />
                            <br/>
                            <Checkbox
                            	color="primary"
                                label="High blood pressure"
                                value="m_high_blood_pressure"
                                onCheck={this.handleCheckChange('m_high_blood_pressure')}
                                checked={this.state.report.m_high_blood_pressure}
                            />
                            <br/>
                            <Checkbox
                            	color="primary"
                                label="Renal Failure"
                                value="m_renal_failure"
                                onCheck={this.handleCheckChange('m_renal_failure')}
                                checked={this.state.report.m_renal_failure}
                            />
                            <br/>
                            <Checkbox
                            	color="primary"
                                label="Immunodeficiency conditions (AIDS)"
                                value="m_immunodeficiency_conditions"
                                onCheck={this.handleCheckChange('m_immunodeficiency_conditions')}
                                checked={this.state.report.m_immunodeficiency_conditions}
                            />
                            <br/>
                            <TextField
								id="outlined-basic" 
								variant="outlined" 
								fullWidth={true}
								className={classes.margin}
								InputLabelProps={{
									shrink: true,
									className: classes.floatingLabelFocusStyle,
								}}
								label = "Other Medical Conditions"
								onChange={this.handleChange('m_others')}
								value = {this.state.report.m_others}
							/>
							</div>
                            <br/>
                            <Divider/>
                            <br/>
                            <Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<div align='left'>
							        	<Button align='left' size="large" variant="outlined" color='secondary'
							        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
							        			onClick={this.handleBack}>
						        			CANCEL</Button>
									</div>
								</Grid>
								<Grid item xs={12} md={6}>
									<div align='right' style={{ paddingBottom: 10 }}>
										<Button
											fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
											size='large'
											variant='contained'
		                                    color='primary'
											onClick={this.handleConfirm} 
											disabled = {this.state.loading}>
												{this.state.loading? "SAVING..":"SAVE"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Paper>
						</div>
			      	</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateComorbidity;
