import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';
import ActionMenu from './ActionMenu';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import GetAppIcon from '@material-ui/icons/GetApp';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from 'react-select';
import Cookies from 'universal-cookie';

import options_barangays from '../Barangays';
import options_cities from '../Cities';

import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

const cookies = new Cookies();

const cookies_key = { 
	selected_status: 'c_selected_status',
	per_page: 'c_per_page',
	page: 'c_page',
	user_city: 'user_city',
	user_role: 'user_role',
	selected_city: 'c_selected_city',
	selected_barangay: 'c_selected_barangay',
};

const status_options = [
	{value:'', label:'Status (All)'},
	{value:'Quarantined', label:'Quarantined'},
	{value:'Admitted', label:'Admitted'},
	{value:'Recovered', label:'Recovered'},
	{value:'Deceased', label:'Deceased'},
];

const customStyles = {
	headCells: {
		style: {
		  color: '#202124',
		  fontSize: '12px',
		  fontWeight: 'bold',
		},
	},
};

class ViewCases extends Component {
	_exporter;
	export = () => {
		this._exporter.save();
	}

	state = {
		cases: [],
		excelCases: [],
		loading: true,
		totalRows: 0,
		perPage: 10,
		page: 1,
		anchorEl: null,
		selectedStatusOption: {value:'', label:'Status (All)'},
		selectedStatusValue: "",
		userRole: '',
		cityLevelUser: false,
		userCity: '',
		selectedCityValue: '',
		selectedCityOption: {value:'', label: 'City (All)'},
		selectedBarangayValue: '',
		selectedBarangayOption: {value:'', label: 'Barangay (All)'},
		filtersPanel: false,
	}

	loadData = (perPage, page) => {
		this.setState({ loading: true });
		const offset = (perPage * page) - perPage;

		let url = `/api/cases?limit=${perPage}&offset=${offset}`;

		if(this.state.selectedStatusValue != ""){
			url = `${url}&status=${this.state.selectedStatusValue}`;
		}

		if(this.state.userRole === 'Admin' || this.state.cityLevelUser){
			if(this.state.userRole === 'Admin'){
				if(this.state.selectedCityValue){
					url = `${url}&report__address_city=${this.state.selectedCityValue}`;
				}
				if(this.state.selectedBarangayValue){
					url = `${url}&report__address_barangay=${this.state.selectedBarangayValue}`;
				}
			}else{
				url = `${url}&report__address_city=${this.state.userCity}`;
				if(this.state.selectedBarangayValue){
					url = `${url}&report__address_barangay=${this.state.selectedBarangayValue}`;
				}
			}
			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
				axios(address, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						this.setState({
							cases: result.data.cases,
							totalRows: result.data.total_rows,
							loading: false
						}, () => {
							if(this.state.cases.length){
								this.setState({ filtersPanel: false });
							}
						});
					});
			} else {
				const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
				axios(address, {withCredentials: true})
					.then(result => {
						this.setState({
							cases: result.data.cases,
							totalRows: result.data.total_rows,
							loading: false
						}, () => {
							if(this.state.cases.length){
								this.setState({ filtersPanel: false });
							}
						});
					});
			}
		}else{
			this.setState({
				cases: {},
				totalRows: 0,
				loading: false
			});
		}
	}

	handlePanelChange = (panel) => (event, isExpanded) => {
		this.setState({ filtersPanel: isExpanded ? panel : false });
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handlePageChange = page => {
		cookies.set(cookies_key.page, page);
		this.setState({ page });
		this.loadData(this.state.perPage, page);
	}

	handlePerRowsChange = (perPage, page) => {
		cookies.set(cookies_key.per_page, perPage);
		this.setState({ perPage });
		this.loadData(perPage, page);
	}

	handleChangeStatus = selectedOption => {
		this.setState({
			selectedStatusValue: selectedOption.value,
			selectedStatusOption: selectedOption
		});
	}

	handleChangeBarangay = selectedOption => {
		this.setState({
			selectedBarangayValue: selectedOption.value,
			selectedBarangayOption: selectedOption
		});
	}

	handleChangeCity = selectedOption => {
		this.setState({
			selectedCityValue: selectedOption.value,
			selectedCityOption: selectedOption,
			selectedBarangayValue: '',
			selectedBarangayOption: {value:'', label: 'Barangay (All)'}
		});
	}

	filterCases = () => {
		cookies.set(cookies_key.selected_status, this.state.selectedStatusOption);
		cookies.set(cookies_key.selected_city, this.state.selectedCityOption);
		cookies.set(cookies_key.selected_barangay, this.state.selectedBarangayOption);
		cookies.set(cookies_key.page, 1);

		this.loadData(this.state.perPage, 1);
		this.loadCasesForExcel();
	}

	componentDidMount(){
		window.scrollTo(0,0);

		//set this from cookies
		const userRole = cookies.get(cookies_key.user_role);
		const userCity = cookies.get(cookies_key.user_city);
		let cityLevelUser = false;
		if(userRole === "City"){
			cityLevelUser = true;
		}

		let selectedStatusOption = cookies.get(cookies_key.selected_status);
		if(selectedStatusOption == null){
			cookies.set(cookies_key.selected_status, this.state.selectedStatusOption);
			selectedStatusOption = this.state.selectedStatusOption;
		}

		let selectedCityOption = cookies.get(cookies_key.selected_city);
		if(selectedCityOption == null){
			cookies.set(cookies_key.selected_city, this.state.selectedCityOption);
			selectedCityOption = this.state.selectedCityOption;
		}

		let selectedBarangayOption = cookies.get(cookies_key.selected_barangay);
		if(selectedBarangayOption == null){
			cookies.set(cookies_key.selected_barangay, this.state.selectedBarangayOption);
			selectedBarangayOption = this.state.selectedBarangayOption;
		}

		let perPage = cookies.get(cookies_key.per_page);
		if(perPage == null){
			cookies.set(cookies_key.per_page, this.state.perPage);
			perPage = this.state.perPage;
		}

		let page = cookies.get(cookies_key.page);
		if(page == null){
			cookies.set(cookies_key.page, this.state.page);
			page = this.state.page;
		}

		this.setState({
			selectedStatusValue: selectedStatusOption.value,
			selectedStatusOption: selectedStatusOption,
			selectedCityValue: selectedCityOption.value,
			selectedCityOption: selectedCityOption,
			selectedBarangayValue: selectedBarangayOption.value,
			selectedBarangayOption: selectedBarangayOption,
			userRole: userRole,
			perPage: perPage,
			page: page,
			cityLevelUser: cityLevelUser,
			userCity: userCity,
		}, () => {
			this.loadData(perPage, page);
			this.loadCasesForExcel();
		});
	}

	loadCasesForExcel = () => {
		let url = `/api/cases?limit=999999999&offset=0`;

		if(this.state.selectedStatusValue){
			url = `${url}&status=${this.state.selectedStatusValue}`;
		}

		if(this.state.userRole === 'Admin' || this.state.cityLevelUser){
			if(this.state.userRole === 'Admin'){
				if(this.state.selectedCityValue){
					url = `${url}&report__address_city=${this.state.selectedCityValue}`;
				}
				if(this.state.selectedBarangayValue){
					url = `${url}&report__address_barangay=${this.state.selectedBarangayValue}`;
				}
			}else{
				url = `${url}&report__address_city=${this.state.userCity}`;
				if(this.state.selectedBarangayValue){
					url = `${url}&report__address_barangay=${this.state.selectedBarangayValue}`;
				}
			}
			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
				axios(address, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						this.setState({ excelCases: result.data.cases });
					});
			} else {
				const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
				axios(address, {withCredentials: true})
					.then(result => {
						this.setState({ excelCases: result.data.cases });
					});
			}
		}
	}

	casesToExcel = (cases) => {
		let data = [];
		cases.map((c) => {
			const xl = {};
			Object.assign(xl, c);
			xl.date_admission = xl.date_admission ? moment(xl.date_admission).format('L') : '';
			xl.date_onset_symptoms = xl.date_onset_symptoms ? moment(xl.date_onset_symptoms).format('L') : '';
			xl.date_lab_confirmation = xl.date_lab_confirmation ? moment(xl.date_lab_confirmation).format('L') : '';
			xl.status = xl.status + (xl.status === 'Recovered' 
				? (xl.date_recovered ? " (" + moment(xl.date_recovered).format('L') + ")" : '') 
				: (xl.status === 'Deceased' ? (xl.date_deceased ? " (" + moment(xl.date_deceased).format('L') + ")" : '') : ''));
			data.push(xl);
		});
		return data;
	}

	filtersStr = () => {
		const str = 'Filters: ';
		const status_str = (this.state.selectedStatusValue ? `${this.state.selectedStatusValue} ` : 'Status (All) ');
		const city_str = this.state.userRole === 'Admin' ? ('/ '+(this.state.selectedCityValue ? `${this.state.selectedCityValue} ` : 'City (All) ')) : '';
		const barangay_str = ((this.state.userRole === 'Admin' && this.state.selectedCityValue) || this.state.cityLevelUser) ? ('/ '+(this.state.selectedBarangayValue ? `${this.state.selectedBarangayValue}` : 'Barangay (All)')) : '';
		return str+status_str+city_str+barangay_str;
 	}

	render() {
		let filtered_city_options = [];
		let filtered_barangay_options = [];
		if(this.state.userRole === 'Admin'){
			//filter cebu province citimunies
			filtered_city_options = [{value:'', label: 'City (All)'}].concat(options_cities.filter((o) => o.link === 'Cebu'));
			if(this.state.selectedCityValue){
				filtered_barangay_options = [{value:'', label: 'Barangay (All)'}].concat(options_barangays.filter((o) => o.link === this.state.selectedCityValue));
			}
		}else if(this.state.cityLevelUser){
			filtered_barangay_options = [{value:'', label: 'Barangay (All)'}].concat(options_barangays.filter((o) => o.link === this.state.userCity));
		}

		const conditionalRowStyles = [
			{ when: row => row.status === "Admitted",
				style: {
					backgroundColor: 'rgba(255,153,0,0.3)',
				},
			},
			{ when: row => row.status === "Recovered",
				style: {
					backgroundColor: 'rgba(0,102,0,0.3)',
				},
			},
			{ when: row => row.status === "Deceased",
				style: {
					backgroundColor: 'rgba(153,0,0,0.3)',
				},
			},
		];
		const columns = [
			{ name: 'Name/Age/Sex', selector: 'name', sortable: true, wrap: true,
				cell: item => <div align="left">{item.name}<br/>({item.sex} / {item.age})</div>,
			},
			{ name: 'Nationality', selector: 'nationality', sortable: true, wrap: true, },
			{ name: 'History of Travel or Exposure', selector: 'history_travel_exposure', wrap:true,
				cell: item => <div align="left">{item.history_travel_exposure}</div>,
			},
			{ name: 'Date of Onset of Symptoms', selector: 'data_onset_symptoms', sortable: true,
				cell: item => <div align="center">
				{item.date_onset_symptoms === null ? <div align="center">For Validation</div> : <div align="center">{moment(item.date_onset_symptoms).format('L')}</div>}
				</div>,
			},
			{ name: 'Date of Admission/Quarantine', selector: 'date_admission', sortable: true,
				cell: item => <div align="center">
				{item.date_admission === null ? <div align="center">For Validation</div> : <div align="center">{moment(item.date_admission).format('L')}</div>}
				</div>,
			},
			{ name: 'Admission/Quarantine Facility', selector: 'admission_facility', sortable: true, wrap: true,
				cell: item => <div align="left">{item.admission_facility}</div>,
			},
			{ name: 'Date of Laboratory Confirmation', selector: 'date_lab_confirmation', sortable: true,
				cell: item => <div align="center">
				{item.date_lab_confirmation === null ? <div align="center">For Validation</div> : <div align="center">{moment(item.date_lab_confirmation).format('L')}</div>}
				</div>,
			},
			{ name: 'Status', selector: 'status', sortable: true, wrap: true,
				cell: item => <div align="left">
					<div align="left">{item.status}</div>
					{item.status == "Recovered" && <div>{item.date_recovered && <div>({moment(item.date_recovered).format('L')})</div>}</div>}
					{item.status == "Deceased" && <div>{item.date_deceased && <div>({moment(item.date_deceased).format('L')})</div>}</div>}
				</div>,
			},
			{ cell: item => <ActionMenu row={item} />,
				allowOverflow: true,
    			button: true,
			},
		];

		return (
			<MuiThemeProvider>
            	<React.Fragment>
            			<div>
            				<Paper style={{ padding: 10 }}>
            					<div align='center'>
            					<ExpansionPanel 
            						expanded={this.state.filtersPanel === 'panel'}
            						onChange={this.handlePanelChange('panel')}
            						style={{ width: 350 }}>
            						<ExpansionPanelSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel-header"
							        >
							        	<Typography color='primary'>Filter Cases</Typography>
            						</ExpansionPanelSummary>
            						<ExpansionPanelDetails>
            							<div className="for_select">
											<div className="for_select">
												<Select
													options={status_options}
													value={this.state.selectedStatusOption}
													placeholder="Status"
													onChange={this.handleChangeStatus}
													style={{ width: 100}}
												/>
											</div>
											<br/>
											{(() => {
												if(this.state.userRole === 'Admin'){
													return(
														<React.Fragment>
															<div className="for_select">
																<Select
																	options={filtered_city_options}
																	value={this.state.selectedCityOption}
																	placeholder="City"
																	onChange={this.handleChangeCity}
																	style={{ width: 100}}
																/>
															</div>
															<br/>
														</React.Fragment>
													);
												}
											})()}
											{(() => {
												if(this.state.userRole === 'Admin' && this.state.selectedCityValue){
													return(
														<React.Fragment>
															<div className="for_select">
																<Select
																	options={filtered_barangay_options}
																	value={this.state.selectedBarangayOption}
																	placeholder="Barangay"
																	onChange={this.handleChangeBarangay}
																	style={{ width: 100}}
																/>
															</div>
															<br/>
														</React.Fragment>
													);
												}
											})()}
											{(() => {
												if(this.state.cityLevelUser){
													return(
														<React.Fragment>
															<div className="for_select">
																<Select
																	options={filtered_barangay_options}
																	value={this.state.selectedBarangayOption}
																	placeholder="Barangay"
																	onChange={this.handleChangeBarangay}
																	style={{ width: 100}}
																/>
															</div>
															<br/>
														</React.Fragment>
													);
												}
											})()}
											<Button size="small"
													color="primary"
													variant="contained"
													startIcon={<FindReplaceIcon />}
													onClick={this.filterCases}>
												Filter
											</Button>
											</div>
									</ExpansionPanelDetails>
								</ExpansionPanel>
								</div>
								{(() => {
									if(!this.state.filtersPanel){
										return(
											<>
												<br/>
												<Typography variant='overline'>
													{this.filtersStr()}
												</Typography>
											</>
										);
									}
								})()}
							</Paper>
						</div>
						<Divider/>
            			<DataTable
            				noHeader={true}
							columns={columns}
							data={this.state.cases}
							conditionalRowStyles={conditionalRowStyles}
							progressPending={this.state.loading}
							progressComponent={<TableLoader loadingText="Cases"/>}
      						pagination
      						paginationServer
      						paginationTotalRows={this.state.totalRows}
      						paginationDefaultPage={this.state.page}
      						paginationPerPage={this.state.perPage}
      						onChangeRowsPerPage={this.handlePerRowsChange}
      						onChangePage={this.handlePageChange}
      						customStyles={customStyles}
      						subHeader
      						subHeaderAlign={'right'}
      						subHeaderComponent={
      							<>
      								{(() => {
										if(this.state.cases.length){
											return(
												<>
													<Button size="small"
														color="primary"
														variant="contained"
														onClick={this.export}
														startIcon={<GetAppIcon/>}
														>
														Export
													</Button>
													<ExcelExport
									                    data={this.casesToExcel(this.state.excelCases)}
									                    fileName={"Cases - "+moment(new Date()).format('L')+".xlsx"}
									                    ref={(exporter) => { this._exporter = exporter; }}
									                >
									                	<ExcelExportColumn field="name" title="Name" width={200} />
												        <ExcelExportColumn field="age" title="Age" width={75} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="sex" title="Sex" width={75} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="nationality" title="Nationality" width={100} cellOptions={{ textAlign: 'center' }} />
												        <ExcelExportColumn field="history_travel_exposure" title="History of Travel or Exposure"/>
												        <ExcelExportColumn field="date_onset_symptoms" title="Date of Onset of Symptoms" cellOptions={{ textAlign: 'center' }} />
									                	<ExcelExportColumn field="date_admission" title="Date of Admission/Quarantine" cellOptions={{ textAlign: 'center' }} />
									                	<ExcelExportColumn field="admission_facility" title="Admission/Quarantine Facility"/>
									                	<ExcelExportColumn field="date_lab_confirmation" title="Date of Laboratory Confirmation" cellOptions={{ textAlign: 'center' }} />
									                	<ExcelExportColumn field="status" title="Status"/>																																																																										
									                </ExcelExport>
								                </>
						                	);													
						            	}
					                })()}
      							</>
      						}
						/>
            	</React.Fragment>
            </MuiThemeProvider>
		);
	}
}

export default ViewCases;
