import MarkerClusterGroup from 'react-leaflet-markercluster';
import React from 'react';
import {Map, TileLayer, Marker} from 'react-leaflet';

import Control from 'react-leaflet-control';
import Choropleth from 'react-leaflet-choropleth';

import {
	Paper, FormControlLabel, Typography, Box, IconButton, Tooltip
} from '@material-ui/core';

const style = {
    fillColor: '#ffe0d6',
    weight: 2,
    opacity: 0.7,
    color: '#5c5b59',
    dashArray: '3',
    fillOpacity: 0.8
}

const HeatmapComponent = props => {
	const [showClusters, setShowClusters] = React.useState(true);
	const [showHeatmap, setShowHeatmap] = React.useState(true);

	const colorScale = ['#ffc0ad', '#ff9e83', '#ff7858', '#ff4327'];

	const handleCheckChange = field => event => {
		if(field === 'show_clusters'){
			setShowClusters(event.target.checked);
		}else if(field === 'show_heatmap'){
			setShowHeatmap(event.target.checked);
		}
	}

	return (
		<>
			<Map
				zoom={13}
				maxZoom={19}
				center={[10.3262643,123.8969322]}
				style={props.dimension}
			>
				<Control position="topright">
					<Paper style={{opacity:0.8}}>
					<Box
						border={1}
						borderRadius={8}
						style={{paddingLeft: 10, paddingRight:21}}>
						<div align='left'>
							<Typography variant='body2' color='primary' align='center' style={{paddingTop:5, paddingBottom: 5, fontWeight: 'bold'}}>Layers</Typography>
							<input onClick={handleCheckChange('show_clusters')} type="checkbox" id="show_clusters" name="show_clusters" value="show_clusters" checked={showClusters ? true : false}></input>
							<label for="show_clusters" style={{paddingLeft: 5}}>Clusters</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleCheckChange('show_heatmap')} type="checkbox" id="show_heatmap" name="show_heatmap" value="show_heatmap" checked={showHeatmap ? true : false}></input>
							<label for="show_heatmap" style={{paddingLeft: 5}}>{props.userRole === 'Barangay' ? 'Boundary' : 'Heatmap'}</label>
	                    </div>
                    </Box>
                    </Paper>
				</Control>

				{showHeatmap ?
				<Control position="bottomright">
					<Paper style={{padding: 5, opacity: 0.9}}>
						<div>
							<div class='my-legend'>
								<div class='legend-title'>No. of Cases</div>
								<div class='legend-scale'>
									<ul class='legend-labels'>
										<li><span style={{background:'#ffe0d6', opacity: 0.8, opacity: 0.7}}></span>0</li>
										<li><span style={{background:colorScale[0], opacity: 0.8}}></span>{props.intervals[0]}</li>
										<li><span style={{background:colorScale[1], opacity: 0.8}}></span>{props.intervals[1]}</li>
										<li><span style={{background:colorScale[2], opacity: 0.8}}></span>{props.intervals[2]}</li>
										<li><span style={{background:colorScale[3], opacity: 0.8}}></span>{props.intervals[3]}</li>
									</ul>
								</div>
							</div>
						</div>
					</Paper>
				</Control>
				 : null }

				
				{showClusters ?
				<MarkerClusterGroup
						singleMarkerMode={true}>
					{ props.points.map((point, idx) => (<Marker key={idx} position={point} />)) }
				</MarkerClusterGroup>
				: null }
				{showHeatmap ?
				<Choropleth
					data={{type: 'FeatureCollection', features: props.features}}
					visible={(feature) => feature.properties.numberOfReports !== 0}
					valueProperty={(feature) => feature.properties.numberOfReports}
					scale={colorScale}
					steps={4}
					mode='e'
					style={style}
					onEachFeature={(feature, layer) => layer.bindPopup(feature.properties.NAME_3)}
				/>
				: null }
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
			</Map>
		</>
	);
}

export default HeatmapComponent;
