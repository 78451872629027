import React, { Component, createRef } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';

import {Map,Marker,Popup,TileLayer}from 'react-leaflet';
import L from 'leaflet';

import lat_long from '../lat_long';

class UpdateAddressLatLong extends Component {
	state = {
		report_id: -1,
		zoom: 14,
		address_latitude: 0,
		address_longitude: 0,
		loading: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					const lat = result.data.address_latitude;
					const lng = result.data.address_longitude;
                    // console.log(result.data);
					if(lat == null || lng == null){
						this.setState({ report_id: result.data.id });
						this.setLatLng(result.data.address_city, result.data.address_barangay);
					}else{
						this.setState({
							report_id: result.data.id,
							address_latitude: lat,
							address_longitude: lng
						});
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					const lat = result.data.address_latitude;
					const lng = result.data.address_longitude;
					if(lat == null || lng == null){
						this.setState({ report_id: result.data.id });
						this.setLatLng(result.data.address_city, result.data.address_barangay);
					}else{
						this.setState({
							report_id: result.data.id,
							address_latitude: lat,
							address_longitude: lng
						});
					}
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirm = event => {
		this.setState({ loading: true, });
		event.preventDefault();

		const body = {
			"address_latitude" : this.state.address_latitude,
			"address_longitude" : this.state.address_longitude
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false, });}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report_id}`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}else{
						this.setState({ loading: false, });}
				});
		}
	}

	refmarker = React.createRef();

	updatePosition = () => {
		const marker = this.refmarker.current;
		if(marker != null){
			this.setState({
				address_latitude: marker.leafletElement.getLatLng().lat,
				address_longitude: marker.leafletElement.getLatLng().lng,
				zoom: marker.contextValue.map._zoom
			});
		}
	}

    getPosition = (e) => {
		this.setState({
			address_latitude: e.latlng.lat,
			address_longitude: e.latlng.lng,
		 	zoom: this.refmarker.current.contextValue.map._zoom
		 });
    }

    setLatLng = (city, barangay) => {
        const latlng = lat_long.filter((item) => (item.link_barangay === barangay && item.link_city === city));
        this.setState({ address_latitude: latlng[0].lat, address_longitude: latlng[0].long });
    }

	render() {
		const customMarker = L.icon({
            iconUrl: require('./covcheck_logo.png'),
            iconSize: [70, 80], // size of the icon
            iconAnchor: [35, 80], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -80] // point from which the popup should open relative to the iconAnchor
        });
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div>
							<Map
								center={[this.state.address_latitude, this.state.address_longitude]}
	                            zoom={this.state.zoom}
	                            onClick = {this.getPosition}
	                            style={{width: '95vw', height: '80vh', margin: 'auto'}}
							>
	                            <TileLayer
		                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
		                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
	                            />
	                            <Marker
	                                icon={customMarker}
	                                draggable={true}
	                                onDragend={this.updatePosition}
	                                position={[this.state.address_latitude, this.state.address_longitude]}
	                                ref={this.refmarker}
                                />
                        	</Map>
						</div>
						<div>
		                    <br/>
		                    <Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
		                    &nbsp;&nbsp;&nbsp;
							<Button
							size="small"
							variant="contained"
							color="primary"
							onClick={this.handleConfirm}
							disabled = {this.state.loading}>
								{this.state.loading? "SAVING..":"SAVE"}
							</Button>
						</div>
			      	</div>
				</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default UpdateAddressLatLong;
