import React, { Component } from 'react';
import axios from 'axios';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
    Divider, Paper, Typography, TextField, Grid
} from '@material-ui/core';

const mobilePhonesBreakPoint = 400;

class MarkReportAsResponded extends Component {
	state = {
		report: {},
		classification: this.props.match.params.classification,
		status: '1',
		patient_code: "",
		patient_password: "",
		date_responded_error: false,
		date_responded_error_text: "",
		aqfError: false,
		aqfErrorText: "",
		duplicate: false,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					//check duplicate
					const report = result.data;
					this.setState({ report: report });
					let duplicate = false;
					axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports?name=${report.name}&department=${report.department}&sex=${report.sex}`, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							const reports = result.data.reports;
							if(reports.length > 1){
								duplicate = true;
								this.setState({ duplicate: true });
							}
						});
					if(!duplicate){
						report.date_responded = new Date();
						this.state.report.classification && this.setState({ classification: this.state.report.classification }, () => {
							if(this.state.classification === 'SAFE-1' || this.state.classification === 'SAFE-2'){
								this.setState({ status: '1' });
							}else{
								this.setState({ status: '2' });
							}
						});
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					const report = result.data;
					this.setState({ report: report });
					let duplicate = false;
					const dUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 
						`https://covidcheck.upcebu.edu.ph/dev/api/reports?name=${report.name}&department=${report.department}&sex=${report.sex}` : 
						`https://covidcheck.upcebu.edu.ph/api/reports?name=${report.name}&department=${report.department}&sex=${report.sex}`;
					axios(dUrl, {withCredentials: true})
						.then(result => {
							const reports = result.data.reports;
							if(reports.length > 1){
								duplicate = true;
								this.setState({ duplicate: true });
							}
						});
					if(!duplicate){
						report.date_responded = new Date();
						this.state.report.classification && this.setState({ classification: this.state.report.classification }, () => {
							if(this.state.classification === 'SAFE-1' || this.state.classification === 'SAFE-2'){
								this.setState({ status: '1' });
							}else{
								this.setState({ status: '2' });
							}
						});
					}
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-reports');
	}

	handleConfirmDelete = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}
				}).catch((e) => {
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						this.props.history.push('/view-reports');
					}
				}).catch((e) => {
				});
		}
	}

	handleConfirm = event => {
		event.preventDefault();
		var valid = true;

		// if(this.state.report.admit_quarantine_facility.trim() == ""){
		// 	valid = false;
		// 	this.setState({ aqfError: true, aqfErrorText: "Input is required"});
		// }

		if(this.state.report.date_responded == null || this.state.report.date_responded == ""){
			valid = false;
			this.setState({ date_responded_error: true, date_responded_error_text: "Input is required!"});
		}

		if(valid){
			const body = {
				"status" : parseInt(this.state.status),
				"classification": this.state.classification,
				"admit_quarantine_facility": this.state.report.admit_quarantine_facility,
				"date_responded": this.state.report.date_responded
			}

			if (process.env.NODE_ENV === 'development') {
				const username = 'testing@example.com';
				const password = 'p@ssw0rd';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

				axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}
					});
			} else {
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report.id}`;
				axios.put(url, body, {withCredentials: true})
					.then(result => {
						if(result.status === 200){
							this.props.history.push('/view-reports');
						}
					});
			}
		}
	}

	handleChange = field => event => {
		const report = this.state.report;
		report[field] = event.target.value;
		this.setState({ report: report });
	}

	handleRadioChange = field => event => {
		const value = event.target.value;
		this.setState({ [field]: value });
		if(field === 'classification'){
			if(value === 'SAFE-1' || value === 'SAFE-2'){
				this.setState({ status: '1' });	
			}else{
				this.setState({ status: '2' });
			}
		}
	}

	dateStr = d => {
		if(d == null){
			d = new Date();
		}
		const dateNow = new Date(d);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}

	render() {
		const classes = {margin:1};
		return(
			<MuiThemeProvider>
                <React.Fragment>
					<div>
						<div className="for_select" align="left" style={{ width: 350}}>
                        <Paper style={{padding: 10}}>
                            <Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
                                Respond Self-Assessment
                            </Typography>
                            <Divider/>
		                    <Typography component="h1" variant="subtitle1" color="textSecondary" style={{ padding: 10 }} align='left' gutterBottom>
                                Name: {this.state.report.name}
                            </Typography>
                            {this.state.duplicate ? 
                            	<>
                            		<div align='center'>
                            			<Typography variant='subtitle1' color='error'>This is a duplicate entry</Typography>
                    				</div>
                    				<br/>
                            	</> : 
                            	<>
		                            <div className="for_select" style={{paddingLeft: 10}}>
										<Typography component="h1" variant="subtitle1" color="primary" align='left' gutterBottom>
											Classification
										</Typography>
					                    <FormControl component="fieldset">
											<RadioGroup
												aria-label="classification"
												name="classification1"
												value={this.state.classification}
												onChange={this.handleRadioChange('classification')}
											>
												<FormControlLabel value="ILI-1" control={<Radio color="primary" />} label="ILI-1 (Influenza-Like Illness - 1)" />
												<FormControlLabel value="ILI-2A" control={<Radio color="primary" />} label="ILI-2A (Influenza-Like Illness - 2A)" />
												<FormControlLabel value="ILI-2B" control={<Radio color="primary" />} label="ILI-2B (Influenza-Like Illness - 2B)" />
												<FormControlLabel value="SARI" control={<Radio color="primary" />} label="SARI (Severe Acute Respiratory Infection)" />
												<FormControlLabel value="PUM" control={<Radio color="primary" />} label="PUM (Person Under Monitoring)" />
												<FormControlLabel value="SAFE-1" control={<Radio color="primary" />} label="SAFE-1" />
												<FormControlLabel value="SAFE-2" control={<Radio color="primary" />} label="SAFE-2" />
											</RadioGroup>
										</FormControl>
										<br/>
										<Divider/>
					                    <br/>
					                    <Typography component="h1" variant="subtitle1" color="primary" align='left'>
											Entry Status
										</Typography>
										<FormControlLabel
											control={<Radio
												checked={this.state.status === '1'}
												onChange={this.handleRadioChange('status')}
												value='1'
												name="radio-button-allowed"
												style={{ color: 'green' }}
												inputProps={{ 'aria-label': 'Allowed' }}
											/>} 
											label="Allowed"
										/>
										&nbsp;&nbsp;&nbsp;
										<FormControlLabel
											control={<Radio
													checked={this.state.status === '2'}
													onChange={this.handleRadioChange('status')}
													value='2'
													name="radio-button-not-allowed"
													style={{ color: 'red' }} 
													inputProps={{ 'aria-label': 'Not Allowed' }}
												/>} 
											label="Not Allowed"
										/><br/>
										<br/>
										{this.state.status === '2' ? 
										<>
											<TextField
												id="outlined-basic" 
												variant="outlined" 
						                        label = "Date Started Monitoring"
						                        type = "date"
						                        onChange={ this.handleChange('date_responded') }
						                        value = { this.dateStr(this.state.report.date_responded) }
						                        fullWidth
						                        className={classes.margin}
												InputLabelProps={{
													shrink: true,
													className: classes.floatingLabelFocusStyle,
												}}
						                        error = { this.state.date_responded_error }
						                        helperText = { this.state.date_responded_error_text }
						                    />
											<br/>
											<br/>
						                    <TextField
						                   	 	id="outlined-basic" 
						                   	 	variant="outlined" 
						                        label = "Admission/Quarantine Facility"
						                        onChange={ this.handleChange('admit_quarantine_facility') }
						                        fullWidth
						                        value = { this.state.report.admit_quarantine_facility }
						                        error = {this.state.aqfError}
						                        helperText = { this.state.aqfErrorText }
						                        className={classes.margin}
												InputLabelProps={{
													shrink: true,
													className: classes.floatingLabelFocusStyle,
												}}
						                    />
						                    <br/>
											<br/>
					                    </> : null}
									</div> 
								</> }
                            <Divider/>
                            <br/>
                            {this.state.duplicate ? 
                            	<>
                            		<div align='center'>
							        	<Button align='center' size="large" variant="outlined" color='secondary'
							        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
							        			onClick={this.handleConfirmDelete}>
						        			DELETE</Button>
									</div>
                            	</> :
                            	<>
		                            <Grid container spacing={1}>
										<Grid item xs={12} md={6}>
											<div align='left'>
									        	<Button align='left' size="large" variant="outlined" color='secondary'
									        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									        			onClick={this.handleBack}>
								        			CANCEL</Button>
											</div>
										</Grid>
										<Grid item xs={12} md={6}>
											<div align='right' style={{ paddingBottom: 10 }}>
												<Button
													fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
													size='large'
													variant='contained'
				                                    color='primary'
													onClick={this.handleConfirm} >
														CONFIRM
												</Button>
											</div>
										</Grid>
									</Grid>
								</> }
						</Paper>
						</div>
			      	</div>
		      	</React.Fragment>
			</MuiThemeProvider>
		);
	}
}

export default MarkReportAsResponded;
