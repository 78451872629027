import React from 'react';
import {
  Link
} from "react-router-dom";


import './SideDrawer.css';
import right_arrow from './rarrow.svg';
import terms_pdf from '../Documents/CovidCheck-DPA.pdf';
import { Divider } from '@material-ui/core';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const sideDrawer = props => {
    let drawerClasses = 'side_drawer';
    if (props.show) {
      drawerClasses = 'side_drawer open';
    }
    return (
        <nav className={drawerClasses}>
			<br/>
            <ul>
                {cookies.get('user_role') === 'City Admin' ? null :
                    <>
                        <Link to = '/' onClick={props.click}><li><a href="/">Home<img class="r_arrow" src={right_arrow} /></a></li></Link>
                        <Link to = '/help' onClick={props.click}><li><a href="/">Help<img class="r_arrow" src={right_arrow} /></a></li></Link>
                		{ props.loggedIn && (<Link to = '/view-reports' onClick={props.click}><li><a href="/">Reports<img class="r_arrow" src={right_arrow} /></a></li></Link>) }
                        { (props.loggedIn && false) && (<Link to = '/view-cases' onClick={props.click}><li><a href="/">Cases<img class="r_arrow" src={right_arrow} /></a></li></Link>) }
                        <Link to = '/report' onClick={props.click}><li><a href="/">Self-Assessment<img class="r_arrow" src={right_arrow} /></a></li></Link>
                        <Link to = '/update' onClick={props.click}><li><a href="/">Send Update<img class="r_arrow" src={right_arrow} /></a></li></Link>
                        <Link to = '/getstatus' onClick={props.click}><li><a href="/">Get Clearance<img class="r_arrow" src={right_arrow} /></a></li></Link>
                        <Link><li></li></Link>
                    </>
                }
				<Divider/>
				<Link><li></li></Link>
        		{ !props.loggedIn && (<Link to = '/login' onClick={props.click}><li><a href="/">Admin Log-in<img class="r_arrow" src={right_arrow} /></a></li></Link>) }
        		{ props.loggedIn && (<Link to = '/hsu-change-password' onClick={props.click}><li><a href="/">Change Password<img class="r_arrow" src={right_arrow} /></a></li></Link>) }
                { props.loggedIn && (<Link to = '/logout' onClick={props.click}><li><a href="/">Admin Logout<img class="r_arrow" src={right_arrow} /></a></li></Link>) }
                    
				<li class="footnote"><a class="footnote_info" href={terms_pdf} target='_blank'>Terms of Use
					and Privacy Policy</a></li>
				{ !props.loggedIn && (<li class="footnote_team">Firecheck 2020</li>) }
            </ul>
        </nav>
    );
}

export default sideDrawer;
