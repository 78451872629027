import React from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

import {
	Typography, Divider, Paper
} from '@material-ui/core';

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const PlaceDistribution = (props) => {
	const state = {
	  labels: props.data.map(a => a.label),
	  datasets: [
		{
		  label: 'Report Count',
		  backgroundColor: '#4C6FEE',
		  borderColor: '#4C6FEE',
		  borderWidth: 1,
		  data: props.data.map(a => a.value)
		}
	  ]
	}
	
	return(
		<article className="canvas-bargraph-container">
			<div className="dashboard-maxWidth" >
				<Paper style={{padding: 5}} variant="elevation16" elevation={5}>
				<div className="card-title" style={{paddingTop: 8, paddingBottom: 8 }}>PATIENTS PER FACILITY</div>
				<Divider/>
					<div align='center' style={{ paddingTop: 17, paddingRight: 5 }}>
						<Bar 
						  data={state}
						  options={{
							legend:{
							  display:false
							},
							scales: {
								xAxes: [{
									gridLines: {
										drawOnChartArea: true
									},
									scaleLabel: {
										display: true,
										labelString: "Facility",
										fontColor: "#4C6FEE",
										fontSize: 12
									},
									ticks: {
										fontSize: 10,
										minRotation: 0,
										maxRotation: 0,
									},
								}],
								yAxes: [{
									gridLines: {
										drawOnChartArea: true
									},
									scaleLabel: {
										display: true,
										color: 'red',
										labelString: 'Report Count',
										fontColor: "#4C6FEE",
										fontSize: 12
									},
									ticks: {
										fontSize: 10,
										precision:0,
										beginAtZero: true,
										autoSkip: true,
									},
								}]
							}
						  }}
						/>
						<br/>
					</div>
				</Paper>
			</div>
		</article>
	);
}


export default PlaceDistribution;