import React from 'react';
import PieChart from 'react-minimal-pie-chart';
import { Pie } from 'react-chartjs-2';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const SexDistributionCard = (props) => {
	const male_count = props.male_count;
	const female_count = props.female_count;
	const label = props.label;
	const data = {
		datasets: [{
			data: [male_count, female_count],
			backgroundColor: ['#556CB3', '#3AD5C1']
		}],
		labels: [
			'Male',
			'Female'
		]
	};
	
	const options = {
		maintainAspectRatio: true,
		responsive: true,
		legend: {
			display: false,
		}
		
	}

	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={10}>
			<div className="card-title" align='left' style={{paddingTop: 8, paddingBottom: 8 }}>{label}</div>
			<Divider style={{ backgroundColor: '#f0f0f0'}}/>
			<div className="pie-chart-cv" align="center">
				<Pie
				  data={data}
				  options={options}
				/>
				<br/>
				<Typography variant='caption' style={{ color: '#3AD5C1' }}>FEMALE </Typography>
				/
				<Typography variant='caption' style={{ color: '#556CB3' }}> MALE</Typography>
				<br/>
			</div>
				
		</Paper>
	);
}

export default SexDistributionCard;