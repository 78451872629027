import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import axios from 'axios';
import UpdateLogin from './UpdateLogin';
import UpdateForm from './UpdateForm';
import UpdateEnd from './UpdateEnd';

var wait = 0;

export class Update extends Component {
    state = {
		step: 1,

        report_id:'',

        patient_code:'',
		verification_key:'',

		temperature:0.0,
		image_url: null,
        s_fever:false,
        s_cough:false,
        s_short_breath:false,
        s_fatigue:false,
        s_phlegm:false,
        s_muscle_joint_pain:false,
        s_sore_throat:false,
        s_headache:false,
        s_chills:false,
        s_vomiting:false,
        s_stuffed_nose:false,
		s_diarrhea:false,
		s_others:'',

        medications:'',
        test_cbc:{},
        test_urinalysis:{},
        test_rapid_test:{},
        test_rt_pcr:{},
        loading:false,
        has_cbc:false,
        has_urinalysis:false,
        has_rapid_test:false,
        has_rt_pcr:false,

        has_symptoms:true,

        statusChanged: false,
        newClass: '',
    }


	componentDidMount() {
		window.scrollTo(0, 0);
	};



    //Proceed to the next Step
    nextStep = () => {
        const { step } = this.state;

        this.setState({
            step: step + 1
        });
    }
    //Go back to the previous Step
    prevStep = () => {
        const { step } = this.state;

        this.setState({
            step: step - 1
        });
    }

    //---------------------------Handle field change-------------------------------
    //handle textview input change
    handleChange = input => e => {
        this.setState({[input]: e.target.value});
    }
    //handle textview number input change
    handleNumberChange = (value) => {
        this.setState({temperature: value.floatValue});
    }
    //handle yes no
	handleRadioChange = event => {
		// const report = this.state.report;
		// report.has_symptoms = event.target.value;
		this.setState({ has_symptoms: event.target.value === "true" });
	}
    //Handle checkbox input change
    handleCheckChange = name => e => {
        // console.log(name);
        this.setState({[name]: e.target.checked});
        if(name+'' == 'has_cbc'){
            const test = {};
            test.date_time = new Date();
            test.type = 'CBC';
            test.result = 'Pending';
            test.image = null;
            test.deleted = false;
            this.setState({
                test_cbc: test,
            });
        }
        if(name+'' == 'has_urinalysis'){
            const test = {};
            test.date_time = new Date();
            test.type = 'Urinalysis';
            test.result = 'Pending';
            test.image = null;
            test.deleted = false;
            this.setState({
                test_urinalysis: test,
            });
        }
        if(name+'' == 'has_rapid_test'){
            const test = {};
            test.date_time = new Date();
            test.type = 'COVID Rapid Test';
            test.result = 'Pending';
            test.image = null;
            test.deleted = false;
            this.setState({
                test_rapid_test: test,
            });
        }
        if(name+'' == 'has_rt_pcr'){
            const test = {};
            test.date_time = new Date();
            test.type = 'COVID RT-PCR';
            test.result = 'Pending';
            test.image = null;
            test.deleted = false;
            this.setState({
                test_rt_pcr: test,
            });
        }
    };

    //Handle slider input change
    handleSliderChange = input => event => {
        this.setState({ [input]: event.target.innerText });
	}

	fileSelectedChange = event => {
        // console.log(event.target.files[0]);
		this.setState({
			image_url	:event.target.files[0]
		});
    }

	fileSelectedChangeTest = (test_name) => event => {
        // console.log(event.target.files[0]);
        const test = this.state[test_name];
        test.image = event.target.files[0];
        this.setState({
            test,
        });
        // console.log(this.state[test_name].image);
    }

	handleChange_testing = (test_name, test_category) => e => {
		const test = this.state[test_name];
        // console.log(test);
		test[test_category] = e.target.value;
        this.setState({ test });
    }

    setReportID = (input) => {
        this.setState({
            report_id: input,
        })
    }

	ChangeType = (value)  => {
		const test = this.state.test;
		test.type = value;
		this.setState({
			test: test,
		});
	}

	ChangeResult = (test_name, value)  => {
		const test = this.state[test_name];
		test.result = value;
		this.setState({
			test,
		});
	}

	handleFieldChange = input => e => {
		const test = this.state.test;
		test[input] = e.target.value;
        this.setState({ test });
	}

    //----------------------------------------------Submit Form---------------------------------------------------

    submitTest = (test_name) =>{
        wait = wait + 1;
        // console.log(wait);
        var bodyFormData = new FormData();
        const date = this.state[test_name].date_time;

        bodyFormData.append('date_time', (date instanceof Date) ? date.toISOString() : date);
        bodyFormData.append('remarks', "");
        bodyFormData.append('deleted', false);
        bodyFormData.append('type', this.state[test_name].type);
        bodyFormData.append('result', this.state[test_name].result);
        bodyFormData.append('image', this.state[test_name].image);

        // console.log(bodyFormData);
        var xhr = new XMLHttpRequest()
        if (process.env.NODE_ENV === 'development') {
            const token = Buffer.from(`${this.state.patient_code.trim()}:${this.state.verification_key.trim()}`, 'utf8').toString('base64');
            axios({
                method: 'post',
                url: `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}/tests`,
                data: bodyFormData,
                headers: {'Authorization': `Basic ${token}`}
                })
                .then((response) => {
                    //handle success
                    // console.log(response);
                    wait = wait - 1;
                    if(wait == 0){
                        this.setState({
                            step: this.state.step + 1,
                            loading: false,
                        })
                    }
                })
                .catch((response) => {
                    //handle error
                    // console.log(response);
                    wait = wait - 1;
                    this.setState({
                        loading: false,
                    })
                });
        }else{
            const token = Buffer.from(`${this.state.patient_code.trim()}:${this.state.verification_key.trim()}`, 'utf8').toString('base64');
            const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
            `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}/tests` :
            `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report_id}/tests`;
            axios({
                method: 'post',
                url: reportUpdatesUrl,
                data: bodyFormData,
                headers: {'Authorization': `Basic ${token}`}
                })
                .then((response) => {
                    //handle success
                    // console.log(response);
                    wait = wait - 1;
                    if(wait == 0){
                        this.setState({
                            step: this.state.step + 1,
                            loading: false,
                        })
                    }
                })
                .catch((response) => {
                    //handle error
                    // console.log(response);
                    wait = wait - 1;
                    this.setState({
                        loading: false,
                    })
                }
            );
        }
    }
    submitForm = () => {
        wait = wait + 1;
        // console.log(wait);
        if(this.state.has_cbc){
            this.submitTest('test_cbc')
        }
        if(this.state.has_urinalysis){
            this.submitTest('test_urinalysis')
        }
        if(this.state.has_rapid_test){
            this.submitTest('test_rapid_test')
        }
        if(this.state.has_rt_pcr){
            this.submitTest('test_rt_pcr')
        }

        const date = new Date();
        var bodyFormData = new FormData();

        bodyFormData.append('date_created', date.toISOString());
        bodyFormData.append('deleted', false);
        bodyFormData.append('report_id', this.state.report_id);

        if(this.state.has_symptoms){
            bodyFormData.append('temperature', this.state.temperature);
            bodyFormData.append('s_fever', this.state.s_fever);
            bodyFormData.append('s_cough', this.state.s_cough);
            bodyFormData.append('s_short_breath', this.state.s_short_breath);
            bodyFormData.append('s_fatigue', this.state.s_fatigue);
            bodyFormData.append('s_phlegm', this.state.s_phlegm);
            bodyFormData.append('s_muscle_joint_pain', this.state.s_muscle_joint_pain);
            bodyFormData.append('s_sore_throat', this.state.s_sore_throat);
            bodyFormData.append('s_headache', this.state.s_headache);
            bodyFormData.append('s_chills', this.state.s_chills);
            bodyFormData.append('s_vomiting', this.state.s_vomiting);
            bodyFormData.append('s_stuffed_nose', this.state.s_stuffed_nose);
            bodyFormData.append('s_diarrhea', this.state.s_diarrhea);
            bodyFormData.append('s_others', this.state.s_others);
            bodyFormData.append('medications', this.state.medications);
            bodyFormData.append('image', this.state.image_url);
        }else{
            bodyFormData.append('temperature', 37.0);
            bodyFormData.append('s_fever', false);
            bodyFormData.append('s_cough', false);
            bodyFormData.append('s_short_breath', false);
            bodyFormData.append('s_fatigue', false);
            bodyFormData.append('s_phlegm', false);
            bodyFormData.append('s_muscle_joint_pain', false);
            bodyFormData.append('s_sore_throat', false);
            bodyFormData.append('s_headache', false);
            bodyFormData.append('s_chills', false);
            bodyFormData.append('s_vomiting', false);
            bodyFormData.append('s_stuffed_nose', false);
            bodyFormData.append('s_diarrhea', false);
            bodyFormData.append('s_others', "");
            bodyFormData.append('medications', "");
            bodyFormData.append('image', null);
        }

        // console.log(bodyFormData);
        var xhr = new XMLHttpRequest()
        if (process.env.NODE_ENV === 'development') {
            const token = Buffer.from(`${this.state.patient_code.trim()}:${this.state.verification_key.trim()}`, 'utf8').toString('base64');

            //get report object before send update
            let currReport = {};
            axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}`, {headers: {'Authorization': `Basic ${token}`}})
                .then(result => {
                    currReport = result.data;
                    axios({
                        method: 'post',
                        url: 'https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates',
                        data: bodyFormData,
                        headers: {'Authorization': `Basic ${token}`}
                        })
                        .then((response) => {
                            //handle success
                            // console.log(response);
                            axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}`, {headers: {'Authorization': `Basic ${token}`}})
                                .then(result => {
                                    const newReport = result.data;
                                    if(currReport.status !== newReport.status){
                                        this.setState({ statusChanged: true, newClass: newReport.classification });
                                    }

                                    wait = wait - 1;
                                    if(wait == 0){
                                        this.setState({
                                            step: this.state.step + 1,
                                            loading: false,
                                        })
                                    }
                                }).catch(error => {});
                        })
                        .catch((response) => {
                            //handle error
                            // console.log(response);
                            wait = wait - 1;
                            this.setState({
                                loading: false,
                            })
                        });
                })
                .catch(error =>{});
        }else{
            const token = Buffer.from(`${this.state.patient_code.trim()}:${this.state.verification_key.trim()}`, 'utf8').toString('base64');

            const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
                `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.report_id}` :
                `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.report_id}`;

            let currReport = {};
            axios(reportsUrl, {headers: {'Authorization': `Basic ${token}`}})
                .then(result => {
                    currReport = result.data;
                    const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reportUpdates` : `https://covidcheck.upcebu.edu.ph/api/reportUpdates`;
        			axios({
                        method: 'post',
                        url: reportUpdatesUrl,
                        data: bodyFormData,
                        headers: {'Authorization': `Basic ${token}`}
                        })
                        .then((response) => {
                            //handle success
                            // console.log(response);
                            axios(reportsUrl, {headers: {'Authorization': `Basic ${token}`}})
                                .then(result => {
                                    const newReport = result.data;
                                    if(currReport.status !== newReport.status){
                                        this.setState({ statusChanged: true, newClass: newReport.classification });
                                    }

                                    wait = wait - 1;
                                    if(wait == 0){
                                        this.setState({
                                            step: this.state.step + 1,
                                            loading: false,
                                        })
                                    }
                                }).catch(error => {});
                        })
                        .catch((response) => {
                            //handle error
                            // console.log(response);
                            wait = wait - 1;
                            this.setState({
                                loading: false,
                            })
                        });
                })
                .catch(error =>{});
        }

    }

    render() {
		const {step} = this.state;
		const values = this.state;

		switch(step){
			case 1:
				return (
					<UpdateLogin
						nextStep = {this.nextStep}
                        handleChange = {this.handleChange}
                        setReportID = {this.setReportID}
						fileSelectedChange = {this.fileSelectedChange}
						handleCheckChange = {this.handleCheckChange}
						values={values}
					/>
				)
			case 2:
				return (
					<UpdateForm
						nextStep = {this.nextStep}
						prevStep = {this.prevStep}
						handleChange = {this.handleChange}
						fileSelectedChange = {this.fileSelectedChange}
                        handleCheckChange = {this.handleCheckChange}
                        handleNumberChange = {this.handleNumberChange}
                        handleFieldChange = {this.handleFieldChange}
                        handleRadioChange = {this.handleRadioChange}
                        ChangeType = {this.ChangeType}
                        ChangeResult = {this.ChangeResult}
                        handleChange_testing = {this.handleChange_testing}
                        fileSelectedChangeTest = {this.fileSelectedChangeTest}
						submitForm = {this.submitForm}
						values={values}
					/>
				);
			case 3:
				return (
					<UpdateEnd
						prevStep = {this.prevStep}
						values={values}
					/>
				);
		}

    }
}

export default Update
