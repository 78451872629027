import React from 'react';
import wip_img from './undraw_work_in_progress.png';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

function News() {
  return (
	<div className="wrapper_home">
		<div style={{ padding: 20 }}>
			<TwitterTimelineEmbed 
				sourceType="profile"
  				screenName="DOHgovph"
				noHeader
	  			noFooter 
	  			options={{height: 800}} />
		</div>
    </div>
  );
}

export default News;

