import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';
import {
  Link
} from "react-router-dom";
import RaisedButton from 'material-ui/RaisedButton';
import Typography from '@material-ui/core/Typography';
import TextField from 'material-ui/TextField';

import ChangePasswordComponent from './ChangePasswordComponent';

class PersonnelChangePassword extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			type: props.type,
			email: '',
			errors: '',
			success: false,
			successMsg: 'An email containing the instructions on how to reset your password has been sent to you.',
		}
	}

	onSubmit = () => {
		if(this.state.email.trim()){
		}else{
			return this.setState({
				errors: 'Please fill-out email field'
			});
		}

		const body = { "email": this.state.email };
		let url = '';

		if (process.env.NODE_ENV === 'development') {
			if(this.state.type === 'admin'){

			}else if(this.state.type === 'personnel'){
				url = 'https://covidcheck.upcebu.edu.ph/dev/api/reports/reset_password';
			}
			axios.post(url, body)
				.then(response => {
					this.setState({
						success: true
					});
				})
				.catch(error => {
					this.setState({
						errors: 'E-mail address not registered'
					});
				});
		} else {
			if(this.state.type === 'admin'){
				
			}else if(this.state.type === 'personnel'){
				url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 
					'https://covidcheck.upcebu.edu.ph/dev/api/reports/reset_password' :
					'https://covidcheck.upcebu.edu.ph/api/reports/reset_password';
			}
			axios.post(url, body)
				.then(response => {
					this.setState({
						success: true
					});
				})
				.catch(error => {
					this.setState({
						errors: 'E-mail address not registered'
					});
				});
		}
	}

	onEmailChange = event => {
		this.setState({
			email: event.target.value
		});
	}

	render() {
		return (
			<MuiThemeProvider>
			<div className="wrapper_home">
				<div className="typo-divider">
					Forgot Password
				</div>
				{this.state.success ?
					<>
						<div className='wrapper_login'>
							<h3>{this.state.successMsg}</h3>
							<br/><br/>
							<div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)'}}>
								<Link to="/">
									<button class = "button-return-to-home"
										primary = {true}>
										Return to Home
									</button>
								</Link>
							</div>
							<br/><br/><br/><br/><br/>
						</div>
					</> :
					<>
						<div className="card">
							<div>	
								<TextField
									floatingLabelText = "Email"
									hintText="Email"
									onChange={this.onEmailChange}
									value={this.state.email}
								/>
							</div>
						</div>
						<div style={{color: 'red', 'font-size': '0.9em'}}>
							<br/>
							{this.state.errors}
						</div>
						<div class="bot_nav">
							<Link to = '/'>
								<button class = "button-backchoice"
									primary = {true}>
									Cancel
								</button>
							</Link>
					        <button class = "button-nextchoice"
								primary = {true}
								onClick = {this.onSubmit}>
									Confirm
							</button>
							<br/><br/>
						</div>
					</> }
			</div>
			</MuiThemeProvider>
		)
	}
}

export default PersonnelChangePassword;
