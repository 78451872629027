import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
function valuetext(value) {
    return `${value}°C`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Email extends Component {
    state = {
        nameError: "",
        valid:false
    }
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        if(this.props.values.email.trim() == ""){
            this.setState({ nameError: "Input is required" });
        }else{
			if(this.props.values.email.includes("@")){
				var val = this.validate(this.props.values.email.trim());
				
				
				if(val){
					var pre_email = this.props.values.email.trim().split('@');
					var domain = pre_email[1].split('.');
					
					var end_dom = domain[domain.length-1];
					
					if(end_dom.length == 1){
						this.setState({ nameError: "Invalid format" });
					}else{
						this.setState({ nameError: "" });
						this.props.nextStep();
					}
				}else{
					this.setState({ nameError: "Invalid format" });
				}
			}else{
				this.setState({ nameError: "Invalid email address" });
			}
        }
    };
	
	validate = (email) => {
		const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
				
		return expression.test(email.toLowerCase());
	}
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {values, handleChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>

					<div className="wrapper">
                        <div className = "progress">
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Personal Information</h2>
							</div>
                            <h1>Please enter your email.</h1>

                            <TextField
                                floatingLabelText = "Email"
                                onChange={handleChange('email')}
                                defaultValue = {values.email}
								style={{ width: 300, marginLeft: 10, padding: 0}}
                                margin="dense"
                                errorText = {this.state.nameError}
                            /><br/>
							<div class="bot_nav">
								<button class = "button-backchoice"
									primary = {true}
									onClick = {this.back}>
										&#60;  Back
								</button>
								<button class = "button-nextchoice"
									primary = {true}
									onClick = {this.continue}>
										Continue
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Email;
