import React, {Component} from 'react';
import axios from 'axios';

import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import moment from 'moment';

const ExpandedStyleDiv = styled.div`
	display: block;
	width: 100%;
	align: left;

	table {
		padding: 5px;
		font-size: 13px;
		font-weight: 500;
	}
	td {
		padding: 5px;
	}
`;

const MainDiv = styled.div`
	width: 750px;
	margin: auto;
`;

function symptoms(fever, cough, short_breath, fatigue, phlegm, muscle_joint_pain, sore_throat,
	headache, chills, vomiting, stuffed_nose, diarrhea){
	let str = "";
	if(fever){
		str = str+"Fever, ";
	}
	if(cough){
		str = str+"Cough, ";
	}
	if(short_breath){
		str = str+"Shortness of Breath, ";
	}
	if(fatigue){
		str = str+"Fatigue, ";
	}
	if(phlegm){
		str = str+"Phlegm, ";
	}
	if(muscle_joint_pain){
		str = str+"Muscle or Joint Pain, ";
	}
	if(sore_throat){
		str = str+"Sore Throat, ";
	}
	if(headache){
		str = str+"Headache, ";
	}
	if(chills){
		str = str+"Chills, ";
	}
	if(vomiting){
		str = str+"Vomiting, ";
	}
	if(stuffed_nose){
		str = str+"Stuffed Nose, ";
	}
	if(diarrhea){
		str = str+"Diarrhea";
	}
	return str;
}

function morbidity(cancer, tuberculosis, asthma, diabetes, high_blood_pressure, renal_failure, immunodeficiency_conditions, others){
	let str = "";
	if(cancer){
		str = str + "Cancer, ";
	}
	if(tuberculosis){
		str = str + "Tuberculosis, ";
	}
	if(asthma){
		str = str + "Asthma, ";
	}
	if(diabetes){
		str = str + "Diabetes, ";
	}
	if(high_blood_pressure){
		str = str + "High Blood Pressure, ";
	}
	if(renal_failure){
		str = str + "Renal Failure, ";
	}
	if(immunodeficiency_conditions){
		str = str + "Immunodeficiency Conditions, ";
	}
	if(others != ""){
		str = str + others;
	}
	return str;
}

class ViewPatientInfo extends Component {
	state = {
		data: {},
	}

	componentDidMount(){
		window.scrollTo(0,0);
		const reportId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ data: result.data });
				});
		} else {
			const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			
			axios(reportsUrl, {withCredentials: true})
				.then(result => {
					this.setState({ data: result.data });
				});
		}
	}

	handleBack = event => {
		event.preventDefault();
		this.props.history.push('/view-cases');
	}

	render(){
		const status_str = ["PENDING", "RESPONDED", "TESTED", "TESTED POSITIVE", "TESTED NEGATIVE"];
		const data = this.state.data;
		return(
			<MainDiv>
				<Card>
					<CardContent>
						<ExpandedStyleDiv>
							<table>
								<tbody>
									<tr>
										<td><div align="right"><b>Patient:</b></div></td><td><div align="left">{data.name} / {data.sex} / {data.age} / {data.nationality}</div></td>
									</tr>
									<tr>
										<td><div align="right"><b>Address:</b></div></td><td><div align="left">{data.address_street}, {data.address_sitio},
																	{data.address_barangay}, {data.address_city}, {data.address_province}</div></td>
									</tr>
									<tr>
										<td><div align="right"><b>Contact:</b></div></td><td><div align="left">{data.contact_number}</div></td>
									</tr>
									<tr>
										<td><div align="right"><b>Classification:</b></div></td><td><div align="left">{data.classification}</div></td>
									</tr>
									<tr><td><div align="right"><b>Symptoms:</b></div></td><td><div align="left">{
																	symptoms(data.s_fever, data.s_cough, data.s_short_breath, data.s_fatigue, data.s_phlegm,
																	data.s_muscle_joint_pain, data.s_sore_throat, data.s_headache, data.s_chills, data.s_vomiting,
																	data.s_stuffed_nose, data.s_diarrhea)
																}</div>
										</td>
									</tr>
									<tr>
										<td><div align="right"><b>Comorbidity:</b></div></td><td><div align="left">{
																	morbidity(data.m_cancer, data.m_tuberculosis, data.m_asthma, data.m_diabetes, data.m_high_blood_pressure, data.m_renal_failure,
																	data.m_immunodeficiency_conditions, data.m_others)
																}</div>
										</td>
									</tr>
									<tr>
										<td><div align="right"><b>Admission/Quarantine Facility:</b></div></td><td><div align="left">{data.admit_quarantine_facility}</div></td>
									</tr>
									<tr>
										<td><div align="right"><b>Testing:</b></div></td><td>{data.status >= 2 ?
											<div align="left">{moment(data.date_testing).format('ll')} - {data.testing_center}</div> : <div align="left">N/A</div>}
										</td>
									</tr>
									<tr>
										<td><div align="right"><b>Visited Infected Area(s):</b></div></td><td>{data.has_visited_infected_areas == true ? <div align="left">YES</div> : <div align="left">NO</div>}</td>
									</tr>
									<tr>
										<td><div align="right"><b>Met Infected Person(s):</b></div></td><td>{data.has_met_infected == true ? <div align="left">YES</div> : <div align="left">NO</div>}</td>
									</tr>
									<tr>
										<td><div align="right"><b>Status:</b></div></td><td><div align="left">{status_str[data.status]}</div></td>
									</tr>
								</tbody>
							</table>
							<div align="center">
								<Button size="small" variant="contained" onClick={this.handleBack}>Back</Button>
							</div>
						</ExpandedStyleDiv>
					</CardContent>
				</Card>
			</MainDiv>
		);
	}
}

export default ViewPatientInfo;
