import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';

import HeatmapComponent from './DashboardHeatMapComponent';
import markerClusterStyles from 'react-leaflet-markercluster/dist/styles.min.css';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import geojson from './cebuShp';
import lat_long from '../../../components/lat_long';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const cookies_key = {
	user_city: 'user_city',
	user_barangay: 'user_barangay',
};

const StyleRadio = withStyles({
  root: {
    color: '#556CB3',
    '&$checked': {
      color: '#556CB3',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const Table = styled.table`
	padding: 1px;

	td {
		padding: 1px;
	}
`;

class DashboardHeatmap extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			features: [],
			points: [],
			center_lat: 10.31454,
			center_lng: 123.9225,
			userRole: cookies.get('user_role'),
			intervals: [],
		};
	}

	componentDidMount() {
		const userRole = cookies.get('user_role');
		const userCity = cookies.get(cookies_key.user_city);
		const userBrgy = cookies.get(cookies_key.user_barangay);
		var filter = '';

		const latlng = lat_long.find( ({ link_barangay, link_city }) => link_barangay === userBrgy && link_city === userCity);
		if(latlng){
			this.setState({
				center_lat: latlng.lat,
				center_lng: latlng.long
			});
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports/points?`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						points: this.removePendings(result.data)
					});
				}).catch(error => {
					// console.log(error);
				});

			axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports?limit=999999999&offset=0&`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setFeatures(result.data.reports, userCity, userBrgy, userRole);
				}).catch(error => {
					// console.log(error);
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/points?` : `https://covidcheck.upcebu.edu.ph/api/reports/points?`;
			axios.get(url, {withCredentials: true})
				.then(result => {
					this.setState({
						points: this.removePendings(result.data)
					});
				}).catch(error => {
					// console.log(error);
				});

			const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				`https://covidcheck.upcebu.edu.ph/dev/api/reports?limit=999999999&offset=0&` :
				`https://covidcheck.upcebu.edu.ph/api/reports?limit=999999999&offset=0&`;
			axios.get(reportsUrl, {withCredentials: true})
				.then(result => {
					this.setFeatures(result.data.reports, userCity, userBrgy, userRole);
				}).catch(error => {
					// console.log(error);
				});
		}

	}

	removePendings = (points) => {
		const newPoints = [];
		points.map(p => {
			if(p.status > 0){
				newPoints.push(p);
			}
		});
		return newPoints;
	}

	handleChangeCase = (statusValue) => {
		const userRole = cookies.get('user_role');
		const userCity = cookies.get(cookies_key.user_city);
		const userBrgy = cookies.get(cookies_key.user_barangay);
		var x_ili1 = 'ILI-1';
		var x_ili2a = 'ILI-2A';
		var x_ili2b = 'ILI-2B';
		var x_sari = 'SARI';
		var filter = '';
		let status = parseInt(statusValue);

		if(userRole === 'City' || userRole === 'City Admin'){
			filter = `address_city=${userCity}`;
		}else if(userRole === 'Barangay'){
			filter = `address_city=${userCity}&address_barangay=${userBrgy}`;
		}
		
		if(status){
			if(status > 0){
				if(status === 1){
					status = 1;
					filter = `${filter}&classification=SAFE-1`;
				}else if(status === 2){
					status = 1;
					filter = `${filter}&classification=SAFE-2`;
				}else if(status === 3){
					status = 1;
					filter = `${filter}&classification=ILI-1`;
				}else if(status === 4){
					status = 1;
					filter = `${filter}&classification=ILI-2A`;
				}else if(status === 5){
					status = 1;
					filter = `${filter}&classification=SARI`;
				}else if(status === 6){
					status = 1;
					filter = `${filter}&classification=PUM`;
				}else{
					filter = `${filter}`;
				}
			}
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports/points?${filter}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						points: this.removePendings(result.data)
					});
				}).catch(error => {
					// console.log(error);
				});

			axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports?limit=999999999&offset=0&${filter}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setFeatures(result.data.reports, userCity, userBrgy, userRole);
				}).catch(error => {
					// console.log(error);
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/reports/points?${filter}` :
			  `https://covidcheck.upcebu.edu.ph/api/reports/points?${filter}`;
			axios.get(url, {withCredentials: true})
				.then(result => {
					this.setState({
						points: this.removePendings(result.data)
					});
				}).catch(error => {
					// console.log(error);
				});

			const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				`https://covidcheck.upcebu.edu.ph/dev/api/reports?limit=999999999&offset=0&${filter}` :
				`https://covidcheck.upcebu.edu.ph/api/reports?limit=999999999&offset=0&${filter}`;
			axios.get(reportsUrl, {withCredentials: true})
				.then(result => {
					this.setFeatures(result.data.reports, userCity, userBrgy, userRole);
				}).catch(error => {
					// console.log(error);
				});
		}
	};

	setFeatures = (reports, userCity, userBrgy, userRole) => {
		let filteredFeatures = [];
		let maxCount = 0;
		geojson.features.map((f) => {
					let countR = 0;
					let countPumRt = 0;
					let countSuspect = 0;
					let countProbable = 0;
					let countConfirmed = 0;
					let countGraduates = 0;
					let countPum = 0;
					
					let countSafe = 0;
					let countIli = 0;
					let countSari = 0;
					
					reports.map(r => {
						if(r.address_city === f.properties.NAME_2 && r.address_barangay === f.properties.NAME_3 && r.status > 0){
							countR = countR + 1;
							if(r.status == 1 || r.status == 2){ //NOT PENDING
								if(r.classification == 'SAFE-1' || r.classification == 'SAFE-2') {
									countSafe += 1;
								} else if(r.classification == 'ILI-1' || r.classification == 'ILI-2A' || r.classification == 'ILI-2B') {
									countIli += 1;
								} else if(r.classification == 'SARI') {
									countSari += 1;
								} else {
									countPum += 1;
								}
								
							}
						}
					});
					if(countR > maxCount){
						maxCount = countR;
					}
					f.properties.numberOfReports = countR;
					f.properties.safe = countSafe;
					f.properties.ili = countIli;
					f.properties.sari = countSari;
					f.properties.pum = countPum;
					f.properties.total = countR;
					filteredFeatures.push(f);
				
		});
		const equidistantDiv = (maxCount / 4).toFixed();
		let intervals = [];
		if(equidistantDiv > 0){
			intervals = [
				' < ' + equidistantDiv,
				equidistantDiv + ' - ' + (equidistantDiv*2),
				(equidistantDiv*2) + ' - ' + (equidistantDiv*3),
				' > ' + (equidistantDiv*3)
			];
		}else{
			intervals = ['>= 1'];
		}
		this.setState({
			intervals: intervals,
			features: filteredFeatures
		});
	}

	render() {
		return (
			<MuiThemeProvider>
				<HeatmapComponent
					intervals={this.state.intervals}
					userRole={this.state.userRole}
					tooltipText={this.props.tooltipText}
					onExpand={this.props.onExpand}
					center_lat={this.state.center_lat}
					center_lng={this.state.center_lng}
					handleChangeCase={this.handleChangeCase}
					features={this.state.features}
					points={this.state.points}
					dimension={{ width: this.props.width, height: this.props.height, margin: 'auto' }}
				/>
			</MuiThemeProvider>
		);
	}
}

export default DashboardHeatmap;
