import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import UpdateCaseInfo from './UpdateCaseInfo';
import Divider from '@material-ui/core/Divider';

const ActionMenu = (props) => {
	const item = props.row;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return(
		<React.Fragment>
			<Button size="small" variant="outlined" color="primary" aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
				Actions
			</Button>
			<Menu
				id="actions-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem component={Link} to={`/view-personal-info/${item.report_id}`}>
					View Patient Information
				</MenuItem>
				<Divider/>
				<MenuItem component={Link} to={`/update-case-info/${item.id}`}>
					Update Case Information
				</MenuItem>
				{(() => {
					if(item.status == "Quarantined"){
						return(
							<React.Fragment>
								<Divider/>
								<MenuItem component={Link} to={`/update-case-admitted/${item.id}`}>
									Admitted
								</MenuItem>
								<Divider/>
								<MenuItem component={Link} to={`/update-case-recovered/${item.id}`}>
									Recovered
								</MenuItem>
								<Divider/>
								<MenuItem component={Link} to={`/update-case-deceased/${item.id}`}>
									Deceased
								</MenuItem>
							</React.Fragment>
						);
					}else if(item.status == "Admitted"){
						return(
							<React.Fragment>
								<Divider/>
								<MenuItem component={Link} to={`/update-case-quarantined/${item.id}`}>
									Quarantined
								</MenuItem>
								<Divider/>
								<MenuItem component={Link} to={`/update-case-recovered/${item.id}`}>
									Recovered
								</MenuItem>
								<Divider/>
								<MenuItem component={Link} to={`/update-case-deceased/${item.id}`}>
									Deceased
								</MenuItem>
							</React.Fragment>
						);
					}
				})()}
			</Menu>
		</React.Fragment>
	);
}

export default ActionMenu;