import React from 'react';
import {
  Link
} from "react-router-dom";

import './Toolbar.css';
import '../SideDrawer/DrawerButtonToggle';
import DrawerButtonToggle from '../SideDrawer/DrawerButtonToggle';
import logo from '../../logo192.png';
import News from '../../News';
import Help from '../../Help';
import Report from '../../Report';
import title from './title.png';
import LoginIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { AnimateKeyframes } from 'react-simple-animate';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Toolbar = props => (
    <header className = "toolbar">
        <nav className = "toolbar_navigation">
            <div className = "toolbar_toggle_button">
			<AnimateKeyframes
				play={true}
				iterationCount="infinite"
				direction="alternate"
				duration={1}
				keyframes={[
					{ 0: 'opacity: 0' }, // 0%
					{ 50: 'opacity: 0.5' }, // 50%
					{ 100: 'opacity: 1' } 
				]}
			>
				<DrawerButtonToggle click = {props.drawerClickHandler} />
		    </AnimateKeyframes>
                
            </div>
            <div className = "toolbar_logo">
				<Link to = '/'><a href="/"><img class="logo_icon" src={title} /></a></Link>
			</div>
            <div className = "spacer"/>
            <div className = "toolbar_navigation_items">
                <ul>
                    {cookies.get('user_role') === 'City Admin' ? null :
                        <>
                        <Link to = '/'><li><a href="/">Home</a></li></Link>
                        { !props.loggedIn && (<Link to = '/news'><li><a href="/">News</a></li></Link>) }
                        <Link to = '/help'><li><a href="/">Help</a></li></Link>
    					{ props.loggedIn && (<Link to = '/view-reports'><li><a href="/">Reports</a></li></Link>) }
                        { (props.loggedIn && false) && (<Link to = '/view-cases'><li><a href="/">Cases</a></li></Link>) }
                        <Link to = '/report'><li><a href="/">Self-Assessment</a></li></Link>
                        <Link to = '/update'><li><a href="/">Send Update</a></li></Link>
                        <Link to = '/getstatus'><li><a href="/">Get Clearance</a></li></Link>
                        </>
                    }
					{ !props.loggedIn &&  <Link to = '/login'><li><LoginIcon className="login_icon"/></li></Link>}
                    { props.loggedIn &&  <Link to = '/hsu-change-password'><li><VpnKeyIcon className="login_icon"/></li></Link>}
					{ props.loggedIn &&  <Link to = '/logout'><li><LogoutIcon className="login_icon"/></li></Link>}
                </ul>
            </div>
        </nav>
    </header>
);

export default Toolbar;
