import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
	Redirect
} from "react-router-dom";

//get app.js and put it on root

const options = {
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	offset: '30px',
	transition: transitions.SCALE
}

const Root = () => (
	<AlertProvider template={AlertTemplate} {...options}>
		{(() => {
			if(window.location.pathname.includes('/qb')){
				return(
					<App viewType = {'qb'}/>
				);
			}else{
				return(
					<App viewType = {'covcheck'}/>
				);
			}
		})()}
	</AlertProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
