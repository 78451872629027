import React from 'react';
import { Pie } from 'react-chartjs-2';

import {
	Typography, Divider, Paper
} from '@material-ui/core';

const SexDistribution = (props) => {
	const male_count = props.male_count;
	const female_count = props.female_count;
	const label = props.label;
	const data = {
		datasets: [{
			data: [male_count, female_count],
			backgroundColor: ['#4C6FEE', '#3AD5C1']
		}],
		labels: [
			'Male',
			'Female'
		]
	};
	
	const options = {
		maintainAspectRatio: true,
		responsive: true,
		legend: {
			display: false,
		},
		tooltips: {
		callbacks: {
		  label: function(tooltipItem, data) {
			var dataset = data.datasets[tooltipItem.datasetIndex];
			var meta = dataset._meta[Object.keys(dataset._meta)[0]];
			var total = meta.total;
			var currentValue = dataset.data[tooltipItem.index];
			var percentage = parseFloat((currentValue/total*100).toFixed(1));
			return currentValue + ' (' + percentage + '%)';
		  },
		  title: function(tooltipItem, data) {
			return data.labels[tooltipItem[0].index];
		  }
		}
	  }
		
	}
const option = {
  
}
	return(
		<Paper style={{padding: 5}} variant="elevation16" elevation={5}>
			<div className="card-title" style={{paddingTop: 8, paddingBottom: 8 }}>GENDER DISTRIBUTION</div>
			<Divider/>
			<div className="pie-chart" align="center" style={{ paddingTop: 10, paddingBottom: 15 }}>
				<Pie
				    data={data}
				    options={options}
				    width={300}
				    height={345}
				/>
				<Typography variant='caption' style={{ color: '#3AD5C1' }}>FEMALE </Typography>
				/
				<Typography variant='caption' style={{ color: '#4C6FEE' }}> MALE</Typography>
			</div>
		</Paper>
	);
}

export default SexDistribution;