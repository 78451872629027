import React, { Component } from 'react';
import '../Forms.css';
import terms3_img from './undraw_accept_terms.png';
import terms2_img from './undraw_security.png';
import terms1_img from './undraw_questions.png';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import terms_pdf from '../Documents/CovidCheck-DPA.pdf';
import 'typeface-roboto';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
  
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Terms extends Component {
    state = {
        setOpen: false,
		disabled: true
    }
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
	
	onChangeDisabled = e => {
		if (!this.state.disabled){
			this.setState({disabled: true});
		}else{
			this.setState({disabled: false});
		}    
    }

    handleClickOpen = () => {
        this.setState({
            setOpen: true
        });
    };
  
    handleClose = () => {
        this.setState({
            setOpen: false
        });
    };
   
    render() {
        return (
            <React.Fragment>
				<div className="wrapper">
					<meta name="viewport" content="width=device-width, initial-scale=1.0"/>	
					<div className = "progress"> 
						<Slider
							disabled
							track = "inverted"
							orientation="vertical"
							defaultValue={5}
							valueLabelFormat={valueLabelFormat}
							getAriaValueText={valuetext}
							aria-labelledby="discrete-slider-restrict"
							step={1}
							min={0}
							max={5}
							valueLabelDisplay="auto"
							marks={marks}
						/>
					</div>
					<div class = "screen">
						<div className = "progress_change">
							<h2>Intro</h2>
						</div>
						<br/>
						
						<h5>Terms of Service</h5>
						<br/><img className="terms_img" src={terms1_img} /><p class="content">Help us help you.</p><p class="content">You will answer a few questions about symptoms, travel, and contact with a Covid case  you may have had.</p>
						<br/><img className="terms_img2" src={terms2_img} /><br/><p>The information that you have provided (symptoms, medical conditions, and more) will be used by the University to identify targeted actions to help its constituents.</p>
						<br/><img className="terms_img" src={terms3_img} /><br/><p class="content">By using <b>CovidCheck</b>,  you agree to its <b class="link_this" onClick = {this.handleClickOpen}>Terms of Use and Privacy Policy</b>.</p>
						<div className="caption-left"><Typography variant="caption" align="left" style={{ color: '#616161' }}>Recommendations provided by this tool do not constitute medical advice and should not be used to diagnose or treat medical conditions.</Typography>
						</div><br/><br/><br/>
						<div className="user_terms">
							<input 
								type="checkbox" 
								onChange={this.onChangeDisabled}
							/>
							<span class="terms_condition">
							  I have read and agreed to the Terms of Service and Privacy Policy.
							</span>
						</div>
						<br/><br/>
						<button className = "button-backchoice" onClick = {this.back}>&#60; Back</button>
						<button className = "button-nextchoice" onClick = {this.continue} disabled={this.state.disabled}>Next</button>
						
                        <Dialog
                            open={this.state.setOpen}
                            onClose={this.handleClose}
                            aria-describedby="alert-dialog-description"
							repositionOnUpdate={false}
							style={{ marginTop: '60px' }}
                        >
							<div className="dialog_window">
								<Typography variant="h6" align="center" >TERMS OF USE AND PRIVACY POLICY</Typography><br/>
                                <p>The University of the Philippines Cebu (UP Cebu) CoVcheck Team developed the CovidCheck application in light of the rising cases of the CoronaVirus Disease 2019 (COVID-19) in the Philippines. 
								</p><p>CovidCheck is an application developed to collect health data of the personnel of business organizations or public institutions through self-reporting. The data will be used by the establishment’s health service unit to assess the health status of their workforce and to ensure that only those who are cleared of COVID-19 are allowed to report for work.
								</p><p>This document is used to inform the application’s users regarding the policies with the collection, use, and disclosure of Personal Information shared through the CovidCheck application.  
								</p><p>By accessing or using CovidCheck the user agrees to be bound by these Terms.
								</p><br/>
								<Typography variant="subtitle2" align="left" >TERMS OF USE</Typography><br/>
								<p>Your access to and use of CovidCheck is conditioned on your acceptance of and compliance with the Terms set here. Specifically, you agree to the collection and use of your personal information in relation to the application’s purpose. The Personal Information that we collect is used to assess whether you will be allowed to report for work based on your health status and exposure to COVID-19. 
								</p><p>We will not use or share your information with anyone except as described in the Privacy Policy section of this document.
								</p><p>Reliance on the application for medical guidance is not recommended. Any and all unusual medical concerns must be directed towards and addressed by qualified health professionals and hospital clinics near you.
								</p><p>The University of the Philippines Cebu disclaims any and all representations and warranties with respect to the use of this application, including accuracy, fitness for use, and merchantability. In no event shall UP Cebu or any member of the CoVcheck Team be liable for any personal, special, incidental, indirect or consequential damages whatsoever including, damages or loss, alleged harm, threats of death, disturbance of normal living or any other pecuniary loss arising from the adoption or use of the CovidCheck Web Application.
								</p><br/>
								<h7>Information Collection and Use</h7>
								<p>For better assessment and tracking purposes, CovidCheck will require you to provide truthfully certain personally identifiable information, including but not limited to name, age, sex, nationality, home address, and contact number. In addition, some personal health data such as experienced COVID-19 symptoms and pre-existing medical conditions will also be collected. These data will be retained by the application and will be used as described in the privacy policy.
								</p><br/>
								<h7>Cookies</h7>
								<p>Cookies are small pieces of text used to store information on web browsers. User data such as User ID, Name, or Access Rights are stored in the cookies. Cookies enable CovidCheck to enforce security measures and improve the navigability of the application for a better user experience. These data are eventually deleted from the cookie upon log-out.
								</p><br/>
								<h7>Security</h7>
								<p>We value your trust in providing us your Personal Information, thus we are strive to implement known measures of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
								</p><br/>
								<Typography variant="subtitle2" align="left" >PRIVACY POLICY</Typography><br/>
								<h7>Confidentiality under the Philippine Law</h7>
								<p>The information that we collect from you are generally protected as privileged communications and it is our responsibility to keep that information confidential.
								</p><br/>
								<h7>Collection and Processing of Personal Data</h7>
								<p>The data that you provide through the CovidCheck Self-Assessment and Send Update features will be stored and used by your organization’s health services unit to monitor the employees’ health status, extend assistance where possible and determine if an employee is cleared to report for work. Individual health records will also be used to assess the health status of an employee for possible COVID-19 infection.
								</p><p>In addition, all the user’s inputs will be processed or summarized to provide the administration or department heads with data to plan and manage task assignments and work arrangements. 
								</p><p>We urge the users to report truthfully.
								</p><br/>
								<h7>Technical and Health Service Providers</h7>
								<p>The data that will be collected will be processed and shared to the following persons as needed and as part of their function in the organization:
								</p><p>a.	Physician and Nurses at the Health Services Unit
								</p><p>b.	Department Heads
								</p><p>c.	High-level administrators
								</p><p>d.	Security
								</p><p>We want to inform users that other parties may have access to your Personal Information. The reason is to perform the tasks assigned to them in the management of the health crisis. However, they are obligated not to disclose or use the information for any other purpose. Depending on the extent of access to data, the above-mentioned persons shall execute their own Non-Disclosure Agreements per employee to further ensure confidentiality of the data that will be collected.
								</p><br/>
								Changes to This Privacy Policy
								<p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
								</p><br/>
								<Typography variant="subtitle2" align="left" >CONTACT US</Typography>
								<p>If you have any questions or suggestions about our Terms of Use and Privacy Policy, do not hesitate to contact us at firecheckupcebu@gmail.com.
								</p><br/>
								<Typography variant="caption">Download the <a href={terms_pdf} target='_blank'>Terms of Use and Privacy Policy</a>.</Typography><br/><br/>
                                <button className = "button-nextchoice" onClick = {this.handleClose}>Back</button>
								<br/>
							</div>
						</Dialog>
					</div>
				</div>
            </React.Fragment>
        );
    };
}

export default SelfAssessment_Terms;
