import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });


  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
  
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Symptoms_Check1 extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Forward
    continue = e => {
        e.preventDefault();
        this.props.confirmInfectedPlace();
    };
    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    continueNotExposedPlace = e => {
        e.preventDefault();
        this.props.denyInfectedPlace();
    };
   
    render() {
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress"> 
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={1}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Vulnerability</h2>
							</div>
							<br/><br/>
                            <p>In the past 14 days, have you been in the following:</p>
                            <div style = {{marginLeft : '20px', marginRight : '20px'}}>
                                <p>i.  area, sitio, or barangay with high incidence of confirmed cases of CoVID-19?</p>
                                <p>ii. household with a confirmed case of CoVID-19?</p>
                            </div>
                            <div class = "two_choice">
								<button class = "button-yeschoice" 
									primary = {true} 
									onClick = {this.continue}>
										Yes
								</button>
								<button class = "button-nochoice" 
									primary = {true} 
									onClick = {this.continueNotExposedPlace}>
										No
								</button>
							</div>
												
							<div class="bot_nav">
								<button class = "button-backchoice" 
									primary = {true} 
									onClick = {this.back}>
										&#60; Back
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Symptoms_Check1;
