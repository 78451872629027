import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';

import HeatmapComponent from './HeatmapComponent';

import markerClusterStyles from 'react-leaflet-markercluster/dist/styles.min.css';

import geojson from '../../components/CoVcheckDashboard/DashboardCards/cebuShp';
import lat_long from '../../components/lat_long';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const cookies_key = { 
	selected_source: 'selected_source',
	selected_status: 'selected_status',
	selected_classification: 'selected_classification',
	selected_barangay: 'selected_barangay',
	selected_city: 'selected_city',
	selected_company: 'selected_company',
	filter_text: 'filter_text',
	user_city: 'user_city',
	user_barangay: 'user_barangay',
	user_role: 'user_role' 
};

class Heatmap extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			points: [],
			features: [],
			intervals: [],
			center_lat: 10.31454,
			center_lng: 123.9225,
		};
	}

	componentDidMount() {
		const userRole = cookies.get(cookies_key.user_role);
		const userCity = cookies.get(cookies_key.user_city);
		const userBarangay = cookies.get(cookies_key.user_barangay);
		const selectedSourceOption = cookies.get(cookies_key.selected_source);
		const selectedStatusOption = cookies.get(cookies_key.selected_status);
		const selectedClassificationOption = cookies.get(cookies_key.selected_classification);
		const selectedCityOption = cookies.get(cookies_key.selected_city);
		const selectedBarangayOption = cookies.get(cookies_key.selected_barangay);
		const selectedCompanyOption = cookies.get(cookies_key.selected_company);
		const filterText = cookies.get(cookies_key.filter_text);

		const latlng = lat_long.find( ({ link_barangay, link_city }) => link_barangay === userBarangay && link_city === userCity);
		if(latlng){
			this.setState({
				center_lat: latlng.lat,
				center_lng: latlng.long
			});
		}

		let filter = '';

		if(selectedCityOption.value){
			filter = `${filter}&address_city=${selectedCityOption.value}`;
		}

		if(selectedCityOption.value && selectedBarangayOption.value){
			filter = `${filter}&address_barangay=${selectedBarangayOption.value}`;
		}

		if(selectedSourceOption){
			if(selectedSourceOption.value){
				filter = `${filter}&submitted_by=${selectedSourceOption.value}`;
			}
		}

		if(selectedStatusOption !== null){
			if(selectedStatusOption.value >= 0){
				filter = `${filter}&status=${selectedStatusOption.value}`;
			}
		}

		if(selectedClassificationOption !== null){
			if(selectedClassificationOption.value !== ''){
				filter = `${filter}&classification=${selectedClassificationOption.value}`;
			}
		}

		if(filterText !== null){
			if(filterText !== ''){
				filter = `${filter}&name=%${filterText}%`;
			}
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports/points?${filter}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						points: result.data
					});
				}).catch(error => {
					// console.log(error);
				});

			axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports?limit=999999999&offset=0&${filter}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setFeatures(result.data.reports, userCity, userBarangay, userRole);
				}).catch(error => {
					// console.log(error);
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/points?${filter}` : `https://covidcheck.upcebu.edu.ph/api/reports/points?${filter}`;
			axios.get(url, {withCredentials: true})
				.then(result => {
					this.setState({
						points: result.data
					});
				}).catch(error => {
					// console.log(error);
				});

			const reportsUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				`https://covidcheck.upcebu.edu.ph/dev/api/reports?limit=999999999&offset=0&${filter}` :
				`https://covidcheck.upcebu.edu.ph/api/reports?limit=999999999&offset=0&${filter}`;
			axios.get(reportsUrl, {withCredentials: true})
				.then(result => {
					this.setFeatures(result.data.reports, userCity, userBarangay, userRole);
				}).catch(error => {
					// console.log(error);
				});
		}
	}

	setFeatures = (reports, userCity, userBrgy, userRole) => {
		var filteredFeatures = [];
		var maxCount = 0;
		geojson.features.map((f) => {
			if(f.properties.NAME_1 === 'Cebu' && (userRole === 'Admin' ? true : f.properties.NAME_2 === userCity)){
				if(userRole === 'Barangay' ? userBrgy.includes(f.properties.NAME_3) : true){
					var countR = 0;
					reports.map(r => {
						if(r.address_city === f.properties.NAME_2 && r.address_barangay === f.properties.NAME_3){
							countR = countR + 1;
						}
					});
					if(countR > maxCount){
						maxCount = countR;
					}
					f.properties.numberOfReports = countR;
					filteredFeatures.push(f);
				}
			}
		});
		const equidistantDiv = (maxCount / 4).toFixed();
		const intervals = [
			' < ' + equidistantDiv,
			equidistantDiv + ' - ' + (equidistantDiv*2),
			(equidistantDiv*2) + ' - ' + (equidistantDiv*3),
			' > ' + (equidistantDiv*3)
		]
		this.setState({
			intervals: intervals,
			features: filteredFeatures
		});
	}

	render() {
		return (
			<MuiThemeProvider>
			<div className="heatmap_wrapper">
				<HeatmapComponent
					intervals={this.state.intervals}
					features={this.state.features}
					points={this.state.points}
					center_lat={this.state.center_lat}
					center_lng={this.state.center_lng}
					dimension={{width: '95vw', height: '90vh', margin: 'auto'}}
				/>
			</div>
			</MuiThemeProvider>
		);
	}
}

export default Heatmap;
