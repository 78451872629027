import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import NumberOfPatients from './DashboardCards/NumberOfPatients';
import NumberOfDoctors from './DashboardCards/NumberOfDoctors';
import DoctorPatientsRatio from './DashboardCards/DoctorPatientsRatio';
import NumberOfPatientsBasedOnStatus from './DashboardCards/NumberOfPatientsBasedOnStatus';
import SexDistribution from './DashboardCards/SexDistribution';
import AgeDistribution from './DashboardCards/AgeDistribution';
import PlaceDistribution from './DashboardCards/PlaceDistribution';
import AdmittedPatientperDay from './DashboardCards/AdmittedPatientperDay';
import banner from './DashboardCards/undraw_medicine.svg';
import DateIcon from '@material-ui/icons/DateRange';

import {
	Button, Divider, Grid, Paper, Typography, Box
} from '@material-ui/core';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const THEME = createMuiTheme({
  typography: {
    "lineHeight": 1.5,
    "letterSpacing": 0.32,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    h1: {
      "fontWeight": 600,
	  "fontSize": 95,
    },
  },
});

class Dashboard extends Component {
	state={
		userRole: cookies.get('qb_role'),
		userId: cookies.get('qb_id'),
		userAssignedFacilities: '',
		facility: '',
		patients: [],
		doctors: [],
		facilities: [],
		facilitiesOptions: [],
		numberOfpatients: 0,
		numberOfDoctors: 0,
		sexDistribution: 0,
		ageDistribution: 0,
		age_group: [],
		reports: [],
		reportedPlaces: [],
		patientAdmission: [],
		patientAdmittedToday: 0,
		dates_15: [],
		admitted: 0,
		discharged: 0,
		recovered: 0,
		deceased: 0,
		female: 0,
		male: 0,
		doct: 0,
		doctorPatientsRatio: 0,
	}

	componentDidMount(){
		window.scrollTo(0,0);
		let patientsUrl = `/api/qb/patients?limit=999999999&offset=0`;
		let doctorsUrl = `/api/qb/doctors?limit=999999999&offset=0`;
		let facility = this.props.match.params.facility ? this.props.match.params.facility : 'all';
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					let userAssignedFacilities = result.data.assigned_facilities;
					if(facility === 'all'){
						patientsUrl = `${patientsUrl}&facility_id=${userAssignedFacilities}`;
					}else{
						patientsUrl = `${patientsUrl}&facility_id=${facility}`;
						userAssignedFacilities = facility;
					}
					if(!userAssignedFacilities.includes(',')){
						facility = userAssignedFacilities;
					}
					const patientAddress = `https://covidcheck.upcebu.edu.ph/dev${patientsUrl}`;
					axios(patientAddress, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							this.setState({
								facility: facility,
								patients: result.data.patients,
								numberOfpatients: result.data.total_rows
							}, () => {this.getNumberOfPatientsPerStatus()
									  this.getSexDistribution()
									  this.getAgeDistribution()
									  this.getPatientAdmitted()
									  this.loadData_qf()
							});
						});

					const doctorAddress = `https://covidcheck.upcebu.edu.ph/dev${doctorsUrl}`;
					axios(doctorAddress, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							this.setState({
								userAssignedFacilities: userAssignedFacilities,
								doctors: result.data.doctors,
								numberOfDoctors: parseInt(result.data.total_rows) - 1
							}, () => {this.getDoctorsPatientsRatio()});
						});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${this.state.userId}`;
			axios(url, {withCredentials: true})
				.then(result => {
					let userAssignedFacilities = result.data.assigned_facilities;
					if(facility === 'all'){
						patientsUrl = `${patientsUrl}&facility_id=${userAssignedFacilities}`;
					}else{
						patientsUrl = `${patientsUrl}&facility_id=${facility}`;
						userAssignedFacilities = facility;
					}
					const patientAddress = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 `https://covidcheck.upcebu.edu.ph/dev${patientsUrl}` : `https://covidcheck.upcebu.edu.ph${patientsUrl}`;
					axios(patientAddress, {withCredentials: true})
						.then(result => {
							this.setState({
								facility: facility,
								patients: result.data.patients,
								numberOfpatients: result.data.total_rows
							}, () => {this.getNumberOfPatientsPerStatus()
									  this.getSexDistribution()
									  this.getAgeDistribution()
									  this.getPatientAdmitted()
									  this.loadData_qf()
							});
						});

					const doctorAddress = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 `https://covidcheck.upcebu.edu.ph/dev${doctorsUrl}` : `https://covidcheck.upcebu.edu.ph${doctorsUrl}`;
					axios(doctorAddress, {withCredentials: true})
						.then(result => {
							this.setState({
								userAssignedFacilities: userAssignedFacilities,
								doctors: result.data.doctors,
								numberOfDoctors: parseInt(result.data.total_rows) - 1
							}, () => {this.getDoctorsPatientsRatio()});
						});
				});
		}
	}

	getNumberOfPatientsPerStatus = () => {
		var admitted = 0;
		var discharged = 0;
		var recovered = 0;
		var deceased = 0;
		this.state.patients.map(p => {
			switch(p.status){
				case 'Admitted':
					admitted = admitted + 1;
					break;
				case 'Discharged':
					discharged = discharged + 1;
					break;
				case 'Recovered':
					recovered = recovered + 1;
					break;
				case 'Deceased':
					deceased = deceased + 1;
					break;
			}
		});
		this.setState({ admitted, discharged, recovered, deceased});
	}
	
	getSexDistribution = () => {
		var male = 0;
		var female = 0;
		
		this.state.patients.map(p => {
			switch(p.sex){
				case 'Female':
					female = female + 1;
					break;
				case 'Male':
					male = male + 1;
					break;
			}
		});
		this.setState({ male, female});
	}
	
	getAgeDistribution = () => {
		//0-15; 16-30; 31-45; 46-60; 61-75; 76-100
		var countA = 0;
		var countB = 0;
		var countC = 0;
		var countD = 0;
		var countE = 0;
		var countF = 0;
		var age = 0;
		let ageValue = [];
		this.state.patients.map(p => {
			age = this.getAge(p.birthday);
			if(age >= 0 && age < 15){
				countA = countA + 1;
			}else if(age >= 15 && age < 30){
				countB = countB + 1;
			}else if(age >= 30 && age < 45){
				countC = countC + 1;
			}else if(age >= 45 && age < 60){
				countD = countD + 1;
			}else if(age >= 60 && age < 75){
				countE = countE + 1;
			}else if(age >= 75 && age <= 100){
				countF = countF + 1;
			}else{
				
			}
		});
		
		ageValue.push(countA);
		ageValue.push(countB);
		ageValue.push(countC);
		ageValue.push(countD);
		ageValue.push(countE);
		ageValue.push(countF);
		this.setState({age_group: ageValue});
	}
	
	getAge(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	
	construct15days = () => {
		var days = 15
		var date = new Date();
		var _15daysPrior = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
		var count = 1;
		var dates = [];
		dates.push({label: this.getThisDate(_15daysPrior)});
		while(count <= 15){
			var newDate = new Date(_15daysPrior.getTime() + (count * 24 * 60 * 60 * 1000));
			dates.push({label: this.getThisDate(newDate)});
			count = count + 1;
		}
		return dates;
	}
	
	getPatientAdmitted = () => {
		var counter = 0;
		var index = 0;
		var dateObj = [];
		var dateStr = "";
		var dates = this.construct15days();
		
		dates.map(currDate => {
			this.state.patients.map(p => {
				dateStr = this.getThisDate(p.date_admitted);
				if(currDate.label === dateStr){
					if(dateObj.some(o => o.label === dateStr)){
						index = dateObj.findIndex(o => o.label === dateStr);
						dateObj[index].value = dateObj[index].value + 1;
					} else{
						dateObj.push({
							label: dateStr,
							value: 1
						});
					}
				}
			});
		});
		
		var check = false;
		dates.map(p => {
			check = dateObj.some(n => n.label === p.label);
			if(!check){
				dateObj.push({
					label: p.label,
					value: 0
				});
			}
		});
		var dateToday = new Date();
		dateToday = this.getThisDate(dateToday);
		dateObj.map(q => {
			if(q.label == dateToday){
				this.setState({patientAdmittedToday: q.value});
			}
		});
		
		var pa_arr = this.re_sort(dateObj, dates);
		
		var dates_array = [];
		
		dates.map(p => {
			var ctrD = 1;
			var strD = "";
			var strArr = p.label.split('/');
			strArr.map(q => {
				if(ctrD == 1) {
					strD = q ;
					ctrD = ctrD + 1;
				} else if(ctrD == 2) {
					strD = q + "/" + strD;
					ctrD = ctrD + 1;
				} else {
					ctrD = 1;
					dates_array.push({
						label: strD
					});
				}
			});
		});
		
		
		this.setState({
			patientAdmission: pa_arr,
			dates_15: dates_array});
	}
	
	re_sort = (arr, dates) => {
		var temp_arr = [];
		
		dates.map(a => {
			arr.map(b => {
				if(a.label == b.label) {
					temp_arr.push({
						label: b.label,
						value: b.value
					});
				}
			})
		})
		
		return temp_arr;
	}
	
	compare(a, b) {
		const bandA = a.label.toUpperCase();
		const bandB = b.label.toUpperCase();

		let comparison = 0;
		if (bandA > bandB) {
			comparison = 1;
		} else if (bandA < bandB) {
			comparison = -1;
		}
		return comparison;
	}
	
	getThisDate(dateString) {
		var date = new Date(dateString)
		var dateTemp = date.getDate();
		var monthTemp = date.getMonth() + 1;
		var yearTemp = date.getFullYear();

		var dateStr = dateTemp + "/" + monthTemp + "/" + yearTemp;
		return dateStr;
	}
	

	dIncludes = (assigned, check) => {
		if(assigned){
			const assignedArr = assigned.split(',');
			let flag = false;
			assignedArr.map(a => {
				if(check){
					const checkArr = check.split(',');
					checkArr.map(c => {
						if(a.trim() === c.trim()){
							flag = true;
						}
					});
				}
			});
			return flag;
		}
		return false;
	}

	getDoctorsPatientsRatio = () => {
		var totalPatients = 0;
		var countWithPatients = 0;
		let numberOfDoctors = 0;
		this.state.doctors.map(d => {
			if(this.dIncludes(this.state.userAssignedFacilities, d.assigned_facilities)){
				if(d.role === 'Admin' || d.role === 'Doctor'){
					numberOfDoctors = numberOfDoctors + 1;
				}
				if(d.meta.patient_count && (d.role === 'Admin' || d.role === 'Doctor')){
					totalPatients = totalPatients + parseInt(d.meta.patient_count);
					countWithPatients = countWithPatients + 1;
				}
			}
		});
		const avePperD = totalPatients / countWithPatients;
		this.setState({ 
			doctorPatientsRatio: countWithPatients ? avePperD : 0,
			numberOfDoctors: numberOfDoctors
		});
	}
	
	loadData_qf = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						facilities: result.data.facilities
					}, () => {
						this.filterFacilities();
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities` : `https://covidcheck.upcebu.edu.ph/api/qb/facilities`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						facilities: result.data.facilities
					}, () => {
						this.filterFacilities();
					});
				});
		}
		
	}
	
	filterFacilities = () => {
		const facilities = this.state.facilities;
		let newFacilities = [];
		let userAssignedFacilities = "";
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					userAssignedFacilities = result.data.assigned_facilities;
					facilities.map(f => {
						if(userAssignedFacilities.includes(f.id)){
							newFacilities.push({id: f.id, name: f.name});
						}
					});
					this.setState({
						facilitiesOptions: newFacilities
					}, () => {
						this.getPlacesReports();
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${cookies.get('qb_id')}`;
			axios(address, {withCredentials: true})
				.then(result => {
					userAssignedFacilities = result.data.assigned_facilities;
					facilities.map(f => {
						if(userAssignedFacilities.includes(f.id)){
							newFacilities.push({id: f.id, name: f.name});
						}
					});
					this.setState({
						facilitiesOptions: newFacilities
					}, () => {
						this.getPlacesReports();
					});
				});
		}
		
		
		
	}
	
	getPlacesReports = () => {
		var temp ="";
		var index = 0;
		var placesObj = [];
		let facObj = this.state.facilitiesOptions;
		
		this.state.patients.map(p => {
			facObj.map(f => {
				if(f.name == p.quarantine_facility) {
					if(placesObj.some(o => o.label === f.name)){
						index = placesObj.findIndex(o => o.label === f.name);
						placesObj[index].value = placesObj[index].value + 1;
					} else{
						placesObj.push({
							label: f.name,
							value: 1
						});
					}
				}
			});
		});

		this.setState({reportedPlaces: placesObj.sort(this.compare)});
	}
	
	render(){
		return(
			<Grid container direction="row" spacing={1} alignItems="justify">
	            <Grid item xs={12} sm={12} md={12} lg={12} xl={24}>
	            	<Grid container direction="column" spacing={1}>
						<Grid item xl={12}>
	            			<Table className="dashboard-table">
								<tbody>
									<tr valign="top">
										<td>
											<Box
												borderLeft={4}
												borderColor={'#303030'}
												borderRadius={5}
											>
												<Paper variant="elevation16" elevation={5} style={{padding: 5}} >
												<div className="card-title" ><DateIcon className="svg_icons"/>AS OF:</div>
												<Divider/>
												<div className="dashboard-maxWidth">
													<div className="card-content-date" align='center' style={{ color: '#303030', paddingBottom: 32, paddingTop: 33}}>
														{moment(new Date()).format('M/D')}
													</div>
												</div>
												<div className="dashboard-minWidth">
													<div align='center'>
														<Typography variant='h1' className="card-label" style={{ color: '#303030', paddingBottom: 20, paddingTop: 20  }}>{moment(new Date()).format('MMMM DD')}</Typography>
													</div>
												</div>
												</Paper>
											</Box>
										</td>
										<td>
											<NumberOfPatients numberOfpatients={this.state.numberOfpatients}/>
										</td>
										<td>
											<NumberOfPatientsBasedOnStatus status={'ADMITTED'} numberOfpatients={this.state.admitted} color={'#74b8e3'}/>
										</td>
										<td>
											<NumberOfPatientsBasedOnStatus status={'TRANSFERRED'} numberOfpatients={this.state.discharged} color={'#e3b574'}/>
										</td>
										<td>
											<NumberOfPatientsBasedOnStatus status={'RECOVERED'} numberOfpatients={this.state.recovered} color={'#74e388'}/>
										</td>
										<td>
											<NumberOfPatientsBasedOnStatus status={'DECEASED'} numberOfpatients={this.state.deceased} color={'#e37474'}/>
										</td>
										
									</tr>
									<tr valign="top">
										{(() => {
											if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
												return(
													<td>
														<NumberOfDoctors numberOfDoctors={this.state.numberOfDoctors}/>
													</td>
												);
											}
										})()}
										{(() => {
											if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
												return(
													<td colspan="5">
														<AdmittedPatientperDay data={this.state.patientAdmission} data_today={this.state.patientAdmittedToday} dates={this.state.dates_15} />
													</td>
												);
											}else{
												return(
													<td colspan="6">
														<AdmittedPatientperDay data={this.state.patientAdmission} data_today={this.state.patientAdmittedToday} dates={this.state.dates_15} />
													</td>
												);
											}
										})()}
									</tr>
									<tr valign="top">
										{(() => {
											if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
												return(
													<td>
														<DoctorPatientsRatio doctorPatientsRatio={this.state.doctorPatientsRatio}/>
													</td>
												);
											}
										})()}
										<td>
											<SexDistribution label={'Gender Distribution'} male_count={this.state.male} female_count={this.state.female}/>
										</td>
										<td colspan="2">
											<AgeDistribution age_group={this.state.age_group}/>
										</td>
										<td colspan="2">
											{ this.state.facility === 'all' && <PlaceDistribution data={this.state.reportedPlaces} /> }
										</td>
									</tr>
								</tbody>
							</Table>
	            		</Grid>
	            	</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default Dashboard;