import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import CallIcon from '@material-ui/icons/Call';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import ListIcon from '@material-ui/icons/List';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import moment from 'moment';
import call from 'react-native-phone-call';
import {isMobile} from 'react-device-detect';
import { useAlert } from 'react-alert'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MapIcon from '@material-ui/icons/Map';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import axios from 'axios';

function symptoms(fever, cough, short_breath, fatigue, phlegm, muscle_joint_pain, sore_throat,
	headache, chills, vomiting, stuffed_nose, diarrhea){
	let str = "";
	if(fever){
		str = str+"Fever, ";
	}
	if(cough){
		str = str+"Cough, ";
	}
	if(short_breath){
		str = str+"Shortness of Breath, ";
	}
	if(fatigue){
		str = str+"Fatigue, ";
	}
	if(phlegm){
		str = str+"Phlegm, ";
	}
	if(muscle_joint_pain){
		str = str+"Muscle or Joint Pain, ";
	}
	if(sore_throat){
		str = str+"Sore Throat, ";
	}
	if(headache){
		str = str+"Headache, ";
	}
	if(chills){
		str = str+"Chills, ";
	}
	if(vomiting){
		str = str+"Vomiting, ";
	}
	if(stuffed_nose){
		str = str+"Stuffy Nose, ";
	}
	if(diarrhea){
		str = str+"Diarrhea";
	}
	return str;
}


function morbidity(cancer, tuberculosis, asthma, diabetes, high_blood_pressure, renal_failure, immunodeficiency_conditions, others){
	let str = "";
	if(cancer){
		str = str + "Cancer, ";
	}
	if(tuberculosis){
		str = str + "Tuberculosis, ";
	}
	if(asthma){
		str = str + "Asthma, ";
	}
	if(diabetes){
		str = str + "Diabetes, ";
	}
	if(high_blood_pressure){
		str = str + "High Blood Pressure, ";
	}
	if(renal_failure){
		str = str + "Renal Failure, ";
	}
	if(immunodeficiency_conditions){
		str = str + "Immunodeficiency Conditions, ";
	}
	if(others != ""){
		str = str + others;
	}
	return str;
}

const ExpandedStyleDiv = styled.div`
	display: block;
	width: 100%;
	align: left;

	table {
		padding: 5px;
		font-size: 14px;
		font-weight: 400;
	}
	td {
		padding: 4px;
	}
`;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ReportInfoExpandedComponent(props){
	const status_str = ["PENDING", "ALLOWED ENTRY", "NOT ALLOWED ENTRY"];
	const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
	const [anchorElTesting, setAnchorElTesting] = React.useState(null);
	const [openDelete, setOpenDelete] = React.useState(false);
	const [openGraduate, setOpenGraduate] = React.useState(false);
	const [openDoNotAllowEntry, setOpenDoNotAllowEntry] = React.useState(false);
	const data = props.data;
	const userRole = props.userRole;
	const alert = useAlert();

	const handleOpenDelete = () => {
		setOpenDelete(true);
	}

	const handleCloseDelete = () => {
		setOpenDelete(false);
	}

	const handleOpenDoNotAllowEntry = () => {
		setOpenDoNotAllowEntry(true);
	}

	const handleCloseDoNotAllowEntry = () => {
		setOpenDoNotAllowEntry(false);
	}

	const hanldeConfirmDoNotAllowEntry = () => {
		const body = { "status" : 2 }
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${data.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						props.filterReports();
						props.loadReportsForExcel();
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${data.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${data.id}`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						props.filterReports();
						props.loadReportsForExcel();
					}
				});
		}
	}

	const handleOpenGraduate = () => {
		setOpenGraduate(true);
	}

	const handleCloseGraduate = () => {
		setOpenGraduate(false);
	}

	const handleConfirmGraduate = () => {
		const body = { "status" : 1 }
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${data.id}`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						props.filterReports();
						props.loadReportsForExcel();
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${data.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${data.id}`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						props.filterReports();
						props.loadReportsForExcel();
					}
				});
		}
	}

	const handleConfirmDelete = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${data.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						setOpenDelete(false);
						props.filterReports();
						props.loadReportsForExcel();
					}
				}).catch((e) => {
					setOpenDelete(false);
					alert.show('Error deleting report: '+e.message);
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/reports/${data.id}` : `https://covidcheck.upcebu.edu.ph/api/reports/${data.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						setOpenDelete(false);
						props.filterReports();
						props.loadReportsForExcel();
					}
				}).catch((e) => {
					setOpenDelete(false);
					alert.show('Error deleting report: '+e.message);
				});
		}
	}

	const handleUpdateClick = event => {
		setAnchorElUpdate(event.currentTarget);
	};

	const handleUpdateClose = () => {
		setAnchorElUpdate(null);
	};

	const handleTestingClick = event => {
		setAnchorElTesting(event.currentTarget);
	};

	const handleTestingClose = () => {
		setAnchorElTesting(null);
	};

	const handleResponded = event => {
		let classification = data.classification;
		if(classification == null || classification == "")
			classification = "PUM";
		props.history.push(`/report-mark-responded/${data.id}/${classification}`);
	};

	const handleShowMap = event => {
		props.history.push(`/show-report-address-lat-long/${data.id}`);
	}

	const handleCallPatient = () => {
		if(isMobile){
			const args = {
				number: data.contact_number,
				prompt: false
			}
			call(args);
		}else{
			alert.show('This feature is only available when using a mobile phone');
		}
	}

	const getQuarantineDays = (date_responded) => {
		const dateResponded = new Date(date_responded);
		const today = new Date();
		const diffTime = today.getTime() - dateResponded.getTime();
		const diffDays = Math.ceil(diffTime / (1000*3600*24));
		return diffDays;
	}

	return(
		<Card>
			<CardActionArea>
				<CardContent style={{ background: '#dae2e8'}}>
					<ExpandedStyleDiv>
						<table>
							<tbody>
								{isMobile ? 
									<>
										<tr>
											<td><div align="left" style={{ width: 400 }}><b>Patient: </b>{data.name} / {data.sex} / {data.age} / {data.nationality}</div></td>
										</tr>
										<tr>
											<td><div align="left" style={{ width: 400 }}><b>Address: </b>{data.address_street}, {data.address_sitio}, {data.address_barangay}, {data.address_city}, {data.address_province}</div></td>
										</tr>
										<tr>
											<td><div align="left"><b>Contact: </b>{data.contact_number}</div></td>
										</tr>
										<tr>
											<td><div align="left"><b>E-mail Address: </b>{data.email}</div></td>
										</tr>
										<tr>
											<td><div align='left'><b>Designation: </b>{data.designation}</div></td>
										</tr>
										<tr>
											<td><div align='left'><b>Department: </b>{data.department}</div></td>
										</tr>
										<tr>
											<td><div align="left" style={{ width: 400 }}><b>Symptoms: </b>{
																		symptoms(data.s_fever, data.s_cough, data.s_short_breath, data.s_fatigue, data.s_phlegm, 
																		data.s_muscle_joint_pain, data.s_sore_throat, data.s_headache, data.s_chills, data.s_vomiting, 
																		data.s_stuffed_nose, data.s_diarrhea)
																	}</div></td>
										</tr>
										<tr>
											<td><div align="left" style={{ width: 400 }}><b>Comorbidity: </b>{
																		morbidity(data.m_cancer, data.m_tuberculosis, data.m_asthma, data.m_diabetes, data.m_high_blood_pressure, data.m_renal_failure, 
																		data.m_immunodeficiency_conditions, data.m_others)
																	}</div></td>
										</tr>
										<tr>
											<td><div align="left"><b>Classification: </b>{data.classification}</div></td>
										</tr>
										<tr>
											<td>{data.has_visited_infected_areas == true ? <div align="left"><b>Visited Infected Area(s): </b>YES</div> : <div align="left"><b>Visited Infected Area(s): </b>NO</div>}</td>
										</tr>
										<tr>
											<td><div align="left"><b>Status: </b>{status_str[data.status]}</div></td>
										</tr>
									</>
									:
									<>
										<tr>
											<td><div align="right"><b>Patient:</b></div></td><td><div align="left" style={{ width: 400 }}>{data.name} / {data.sex} / {data.age} / {data.nationality}</div></td>
											<td><div align="right"><b>Classification:</b></div></td><td><div align="left">{data.classification}</div></td>
										</tr>
										<tr>
											<td><div align="right"><b>Address:</b></div></td><td><div align="left" style={{ width: 400 }}>{data.address_street}, {data.address_sitio}, {data.address_barangay}, {data.address_city}, {data.address_province}</div></td>
											<td><div align="right"><b>Designation:</b></div></td><td><div align='left'>{data.designation}</div></td>
										</tr>
										<tr>
											<td><div align="right"><b>Contact:</b></div></td><td><div align="left">{data.contact_number}</div></td>
											<td><div align="right"><b>Department:</b></div></td><td><div align='left'>{data.department}</div></td>
										</tr>
										<tr><td><div align="right"><b>Symptoms:</b></div></td><td><div align="left" style={{ width: 400 }}>{
																		symptoms(data.s_fever, data.s_cough, data.s_short_breath, data.s_fatigue, data.s_phlegm, 
																		data.s_muscle_joint_pain, data.s_sore_throat, data.s_headache, data.s_chills, data.s_vomiting, 
																		data.s_stuffed_nose, data.s_diarrhea)
																	}</div></td>
											<td><div align="right"><b>Admission/Quarantine Facility:</b></div></td><td><div align="left">{data.admit_quarantine_facility}</div></td>
										</tr>
										<tr>
											<td><div align="right"><b>Comorbidity:</b></div></td><td><div align="left" style={{ width: 400 }}>{
																		morbidity(data.m_cancer, data.m_tuberculosis, data.m_asthma, data.m_diabetes, data.m_high_blood_pressure, data.m_renal_failure, 
																		data.m_immunodeficiency_conditions, data.m_others)
																	}</div></td>
											<td><div align="right"><b>Visited Infected Area(s):</b></div></td><td>{data.has_visited_infected_areas == true ? <div align="left">YES</div> : <div align="left">NO</div>}</td>
										</tr>
										<tr>
											<td><div align="right"><b>Status:</b></div></td><td><div align="left">{status_str[data.status]}</div></td>
											<td><div align="right"><b>E-mail Address:</b></div></td><td><div align="left">{data.email}</div></td>
										</tr>
									</>}
							</tbody>
						</table>
					</ExpandedStyleDiv>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<div align="center">
				{(() => {
					if(data.status == 0){ //pending
						return(
							<React.Fragment>
						        <Button size="small" color="primary" variant="outlined" onClick={handleResponded} startIcon={<LocalHospitalIcon />}>
						        	Accept</Button>
						        &nbsp;&nbsp;&nbsp;
						        <Button size="small" color="primary" variant="outlined" onClick={handleShowMap} startIcon={<MapIcon />}>
						        	Show Map</Button>
					        	&nbsp;&nbsp;&nbsp;
						        <Button size="small" style={{ color: 'red' }} variant="outlined" onClick={handleOpenDelete} startIcon={<DeleteIcon />}>
						        	Delete</Button>
					        	<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openDelete}
									TransitionComponent={Transition}
									keepMounted
									onClose={handleCloseDelete}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Delele Report</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-slide-description">
											Delete Report by {data.name}?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseDelete} color="primary">
											Cancel
										</Button>
										<Button onClick={handleConfirmDelete} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
					        </React.Fragment>
				      	);
			      	}else{ //responded
			      		return(
			      			<React.Fragment>
				      			<Button size="small" 
				      				variant="outlined" 
				      				color="primary" 
				      				aria-controls="update-menu" 
				      				aria-haspopup="true" 
				      				onClick={handleUpdateClick}
				      				startIcon={<SystemUpdateAltIcon />}>
									Update
								</Button>
								<Menu
									id="update-menu"
									anchorEl={anchorElUpdate}
									keepMounted
									open={Boolean(anchorElUpdate)}
									onClose={handleUpdateClose}
								>
									<MenuItem component={Link} to={`/update-report-personal-info/${data.id}`}>
										Personal Information
									</MenuItem>
									<Divider/>
									<MenuItem component={Link} to={`/update-report-address-lat-long/${data.id}`}>
										Address (Lat/Long)
									</MenuItem>
									<Divider/>
									<MenuItem component={Link} to={`/update-report-symptoms/${data.id}`}>
										Symptoms
									</MenuItem>
									<Divider/>
									<MenuItem component={Link} to={`/update-report-comorbidity/${data.id}`}>
										Comorbidity
									</MenuItem>
									<Divider/>
									<MenuItem component={Link} to={`/update-report-exposure/${data.id}`}>
										Exposure
									</MenuItem>
									<Divider/>
									<MenuItem component={Link} to={`/update-classification/${data.id}/${data.classification}`}>
										Classification
									</MenuItem>
									<Divider/>
									<MenuItem component={Link} to={`/update-quarantine-info/${data.id}`}>
										Monitoring Information
									</MenuItem>
								</Menu>
								&nbsp;&nbsp;&nbsp;
								<Button size="small" 
					      			color="primary" 
					      			variant="outlined" 
					      			startIcon={<ListIcon />}
					      			component={Link}
					      			to={`/patient-monitoring/${data.id}`}>
					        		Monitoring
					        	</Button>
					        	{(() => {
					        		if(data.status === 2){
					        			return(
					        				<>
									        	&nbsp;&nbsp;&nbsp;
										        <Button size="small" style={{ color: 'green' }} variant="outlined" onClick={handleOpenGraduate} startIcon={<DirectionsWalkIcon />}>
										        	Allow Entry</Button>
									        	<Dialog
									        		fullWidth={true}
									        		maxWidth={'xs'}
													open={openGraduate}
													TransitionComponent={Transition}
													keepMounted
													onClose={handleCloseGraduate}
													aria-labelledby="alert-dialog-slide-title-graduate"
													aria-describedby="alert-dialog-slide-description-graduate"
												>
													<DialogTitle id="alert-dialog-slide-title-graduate">Update Entry Status</DialogTitle>
													<DialogContent>
														<DialogContentText id="alert-dialog-slide-description-graduate">
															Allow Entry - {data.name}?
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button onClick={handleCloseGraduate} color="primary">
															Cancel
														</Button>
														<Button onClick={handleConfirmGraduate} color="secondary">
															Confirm
														</Button>
													</DialogActions>
												</Dialog>
											</>
										);
									}else if(data.status === 1){
										return(
					        				<>
									        	&nbsp;&nbsp;&nbsp;
										        <Button size="small" style={{ color: 'red' }} variant="outlined" onClick={handleOpenDoNotAllowEntry} startIcon={<DirectionsWalkIcon />}>
										        	Do Not Allow Entry</Button>
									        	<Dialog
									        		fullWidth={true}
									        		maxWidth={'xs'}
													open={openDoNotAllowEntry}
													TransitionComponent={Transition}
													keepMounted
													onClose={handleCloseDoNotAllowEntry}
													aria-labelledby="alert-dialog-slide-title-graduate"
													aria-describedby="alert-dialog-slide-description-graduate"
												>
													<DialogTitle id="alert-dialog-slide-title-graduate">Update Entry Status</DialogTitle>
													<DialogContent>
														<DialogContentText id="alert-dialog-slide-description-graduate">
															Do Not Allow Entry - {data.name}?
														</DialogContentText>
													</DialogContent>
													<DialogActions>
														<Button onClick={handleCloseDoNotAllowEntry} color="primary">
															Cancel
														</Button>
														<Button onClick={hanldeConfirmDoNotAllowEntry} color="secondary">
															Confirm
														</Button>
													</DialogActions>
												</Dialog>
											</>
										);
									}
								})()}
								&nbsp;&nbsp;&nbsp;
						        <Button size="small" style={{ color: 'red' }} variant="outlined" onClick={handleOpenDelete} startIcon={<DeleteIcon />}>
						        	Delete</Button>
					        	<Dialog
					        		fullWidth={true}
					        		maxWidth={'xs'}
									open={openDelete}
									TransitionComponent={Transition}
									keepMounted
									onClose={handleCloseDelete}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Delele Report</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-slide-description">
											Delete Report by {data.name}?
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseDelete} color="primary">
											Cancel
										</Button>
										<Button onClick={handleConfirmDelete} color="secondary">
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
							</React.Fragment>			
			      		);
			      	}
		      	})()}
		      	{isMobile ? 
		      		<>
		      			&nbsp;&nbsp;&nbsp;
				      	<Button size="small" 
				      			color="primary" 
				      			variant="outlined" 
				      			onClick={handleCallPatient}
				      			startIcon={<CallIcon />}>
				        	Call</Button>
		      		</> : null}
		      	</div>
	      	</CardActions>
		</Card>
	);
}

export default ReportInfoExpandedComponent;