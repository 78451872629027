import React from 'react';
import moment from 'moment';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';
import LaboratoryTestActionMenu from './LaboratoryTestActionMenu';

import { Link, withRouter } from 'react-router-dom';

import {
	Button, Divider, Paper, Grid, Typography
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const customStyles = {
	headCells: {
		style: {
			textAlign: 'center',
			color: '#202124',
			fontSize: '12px',
			fontWeight: 'bold',
		},
	},
};

class LaboratoryTests extends React.PureComponent {
	state = {
		lab_tests: [],
		patient: {},
		patietName: '',
		patientId: this.props.match.params.id,
		loading: false,
		patient_id: '',
		userRole: cookies.get('qb_role'),
	};

	componentDidMount(){
		window.scrollTo(0,0);
		const patientId = this.props.match.params.id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					const patient = result.data;
                    var name = patient.last_name + ", " + patient.first_name + " " + patient.middle_name;
                    this.setState({
                    	patient: patient,
                        patientName: name, 
                    });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
			axios(url, {withCredentials: true})
				.then(result => {
                    const patient = result.data;
                    var name = patient.last_name + ", " + patient.first_name + " " + patient.middle_name;
                    this.setState({
                    	patient: patient,
                        patientName: name, 
                    });
				});
		}
		this.setState({patient_id: patientId});
		this.loadData();
	}

	loadData = () => {
		this.setState({ loading: true });
		let url = `/api/qb/patients/${this.state.patientId}/tests?`;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
						lab_tests: result.data.labTests,
						loading: false
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
						lab_tests: result.data.labTests,
						loading: false
					});
				});
		}
	}

	filterTests = () => {
		this.loadData();
	}

	render(){
		const columns = [
			{ cell: item => <LaboratoryTestActionMenu 
								row={item} 
								filterTests={this.filterTests} 
								userRole={this.state.userRole}/>,
				allowOverflow: true,
				button: true,
				width: '15px',
			},
			{ name: 'Date', sortable: true, width: '150px',
				cell: item => item.date_time ? moment(item.date_time).format('L') : '',
			},
			{ name: 'Type of Test', selector: 'type', sortable: true, width: '250px',
			},
			{ name: 'Result', selector: 'result', wrap: true,
			},
			{ name: 'Remarks', selector: 'remarks', wrap: true,
			},
		];

		return(
			<>
				<Paper style={{ padding : 20 }}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Laboratory Tests
					</Typography>
					<Typography component="h1" variant="subtitle1" color="textPrimary" gutterBottom>
						{ this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator' ? this.state.patientName+' / '+this.state.patient.sex : this.state.patient.first_name+' / '+this.state.patient.contact_number}
					</Typography>
					<Divider/>
					<br/>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left' style={{ paddingBottom: 10 }}>
					        	<Button align='left' size="small" variant="outlined" startIcon={<ArrowBackIcon />} 
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			component={Link} to='/qb-patients'>
				        			Back</Button>
							</div>
						</Grid>
						{ this.state.userRole === 'Admin' ?
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button size="small"
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									variant="outlined"
									color="primary"
									component={Link}
									to ={`/qb-add-lab-test/${this.state.patient_id}`}
									startIcon={<AddIcon />}>
										Add Lab. Test
								</Button>
							</div>
						</Grid> : null }
					</Grid>
					<Divider/>
					<DataTable
						noHeader
						columns={columns}
						data={this.state.lab_tests}
						progressPending={this.state.loading}
						progressComponent={<TableLoader loadingText="Laboratory Tests"/>}
						pagination
						customStyles={customStyles}
					/>
				</Paper>
			</>
		);
	}
}

export default withRouter(LaboratoryTests);