export default [
	{value:	'Matutinao'	, label:	'Matutinao'	, link:	'Badian'	},
	{value:	'Patong'	, label:	'Patong'	, link:	'Badian'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Badian'	},
	{value:	'Sanlagan'	, label:	'Sanlagan'	, link:	'Badian'	},
	{value:	'Santicon'	, label:	'Santicon'	, link:	'Badian'	},
	{value:	'Sohoton'	, label:	'Sohoton'	, link:	'Badian'	},
	{value:	'Sulsugan'	, label:	'Sulsugan'	, link:	'Badian'	},
	{value:	'Talayong'	, label:	'Talayong'	, link:	'Badian'	},
	{value:	'Taytay'	, label:	'Taytay'	, link:	'Badian'	},
	{value:	'Tigbao'	, label:	'Tigbao'	, link:	'Badian'	},
	{value:	'Tiguib'	, label:	'Tiguib'	, link:	'Badian'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'Badian'	},
	{value:	'Zaragosa'	, label:	'Zaragosa'	, link:	'Badian'	},
	{value:	'Abucayan'	, label:	'Abucayan'	, link:	'Balamban'	},
	{value:	'Aliwanay'	, label:	'Aliwanay'	, link:	'Balamban'	},
	{value:	'Arpili'	, label:	'Arpili'	, link:	'Balamban'	},
	{value:	'Baliwagan'	, label:	'Baliwagan'	, link:	'Balamban'	},
	{value:	'Bayong'	, label:	'Bayong'	, link:	'Balamban'	},
	{value:	'Biasong'	, label:	'Biasong'	, link:	'Balamban'	},
	{value:	'Buanoy'	, label:	'Buanoy'	, link:	'Balamban'	},
	{value:	'Cabagdalan'	, label:	'Cabagdalan'	, link:	'Balamban'	},
	{value:	'Cabasiangan'	, label:	'Cabasiangan'	, link:	'Balamban'	},
	{value:	'Cambuhawe'	, label:	'Cambuhawe'	, link:	'Balamban'	},
	{value:	'Sudlonon'	, label:	'Sudlonon'	, link:	'Bogo City'	},
	{value:	'Taytayan'	, label:	'Taytayan'	, link:	'Bogo City'	},
	{value:	'Arbor'	, label:	'Arbor'	, link:	'Boljoon'	},
	{value:	'Baclayan'	, label:	'Baclayan'	, link:	'Boljoon'	},
	{value:	'El Pardo'	, label:	'El Pardo'	, link:	'Boljoon'	},
	{value:	'Granada'	, label:	'Granada'	, link:	'Boljoon'	},
	{value:	'Lower Becerril'	, label:	'Lower Becerril'	, link:	'Boljoon'	},
	{value:	'Lunop'	, label:	'Lunop'	, link:	'Boljoon'	},
	{value:	'Nangka'	, label:	'Nangka'	, link:	'Boljoon'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Boljoon'	},
	{value:	'San Antonio'	, label:	'San Antonio'	, link:	'Boljoon'	},
	{value:	'South Granada'	, label:	'South Granada'	, link:	'Boljoon'	},
	{value:	'Upper Becerril'	, label:	'Upper Becerril'	, link:	'Boljoon'	},
	{value:	'Bagacay'	, label:	'Bagacay'	, link:	'Borbon'	},
	{value:	'Bili'	, label:	'Bili'	, link:	'Borbon'	},
	{value:	'Bingay'	, label:	'Bingay'	, link:	'Borbon'	},
	{value:	'Bongdo Gua'	, label:	'Bongdo Gua'	, link:	'Borbon'	},
	{value:	'Bongdo'	, label:	'Bongdo'	, link:	'Borbon'	},
	{value:	'Bongoyan'	, label:	'Bongoyan'	, link:	'Borbon'	},
	{value:	'Cadaruhan'	, label:	'Cadaruhan'	, link:	'Borbon'	},
	{value:	'Cajel'	, label:	'Cajel'	, link:	'Borbon'	},
	{value:	'Campusong'	, label:	'Campusong'	, link:	'Borbon'	},
	{value:	'Clavera'	, label:	'Clavera'	, link:	'Borbon'	},
	{value:	'Bongon'	, label:	'Bongon'	, link:	'Tabuelan'	},
	{value:	'Dalid'	, label:	'Dalid'	, link:	'Tabuelan'	},
	{value:	'Kanlim-Ao'	, label:	'Kanlim-Ao'	, link:	'Tabuelan'	},
	{value:	'Kanluhangon'	, label:	'Kanluhangon'	, link:	'Tabuelan'	},
	{value:	'Kantubaon'	, label:	'Kantubaon'	, link:	'Tabuelan'	},
	{value:	'Mabunao'	, label:	'Mabunao'	, link:	'Tabuelan'	},
	{value:	'Maravilla'	, label:	'Maravilla'	, link:	'Tabuelan'	},
	{value:	'Olivo'	, label:	'Olivo'	, link:	'Tabuelan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Tabuelan'	},
	{value:	'Tabunok'	, label:	'Tabunok'	, link:	'Tabuelan'	},
	{value:	'Tigbawan'	, label:	'Tigbawan'	, link:	'Tabuelan'	},
	{value:	'Villahermosa'	, label:	'Villahermosa'	, link:	'Tabuelan'	},
	{value:	'Biasong'	, label:	'Biasong'	, link:	'Talisay City'	},
	{value:	'Bulacao'	, label:	'Bulacao'	, link:	'Talisay City'	},
	{value:	'Cadulawan'	, label:	'Cadulawan'	, link:	'Talisay City'	},
	{value:	'Camp IV'	, label:	'Camp IV'	, link:	'Talisay City'	},
	{value:	'Cansojong'	, label:	'Cansojong'	, link:	'Talisay City'	},
	{value:	'Dumlog'	, label:	'Dumlog'	, link:	'Talisay City'	},
	{value:	'Jaclupan'	, label:	'Jaclupan'	, link:	'Talisay City'	},
	{value:	'Lagtang'	, label:	'Lagtang'	, link:	'Talisay City'	},
	{value:	'Lawaan I'	, label:	'Lawaan I'	, link:	'Talisay City'	},
	{value:	'Lawaan II'	, label:	'Lawaan II'	, link:	'Talisay City'	},
	{value:	'Cabadiangan'	, label:	'Cabadiangan'	, link:	'Alcantara'	},
	{value:	'Cabil-Isan'	, label:	'Cabil-Isan'	, link:	'Alcantara'	},
	{value:	'Candabong'	, label:	'Candabong'	, link:	'Alcantara'	},
	{value:	'Lawaan'	, label:	'Lawaan'	, link:	'Alcantara'	},
	{value:	'Manga'	, label:	'Manga'	, link:	'Alcantara'	},
	{value:	'Palanas'	, label:	'Palanas'	, link:	'Alcantara'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Alcantara'	},
	{value:	'Polo'	, label:	'Polo'	, link:	'Alcantara'	},
	{value:	'Salagmaya'	, label:	'Salagmaya'	, link:	'Alcantara'	},
	{value:	'Atabay'	, label:	'Atabay'	, link:	'Alcoy'	},
	{value:	'Daan-Lungsod'	, label:	'Daan-Lungsod'	, link:	'Alcoy'	},
	{value:	'Guiwang'	, label:	'Guiwang'	, link:	'Alcoy'	},
	{value:	'Nug-As'	, label:	'Nug-As'	, link:	'Alcoy'	},
	{value:	'Pasol'	, label:	'Pasol'	, link:	'Alcoy'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Alcoy'	},
	{value:	'Pugalo'	, label:	'Pugalo'	, link:	'Alcoy'	},
	{value:	'San Agustin'	, label:	'San Agustin'	, link:	'Alcoy'	},
	{value:	'Compostela'	, label:	'Compostela'	, link:	'Alegria'	},
	{value:	'Guadalupe'	, label:	'Guadalupe'	, link:	'Alegria'	},
	{value:	'Legaspi'	, label:	'Legaspi'	, link:	'Alegria'	},
	{value:	'Lepanto'	, label:	'Lepanto'	, link:	'Alegria'	},
	{value:	'Madridejos'	, label:	'Madridejos'	, link:	'Alegria'	},
	{value:	'Montpeller'	, label:	'Montpeller'	, link:	'Alegria'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Alegria'	},
	{value:	'Santa Filomena'	, label:	'Santa Filomena'	, link:	'Alegria'	},
	{value:	'Valencia'	, label:	'Valencia'	, link:	'Alegria'	},
	{value:	'Angilan'	, label:	'Angilan'	, link:	'Aloguinsan'	},
	{value:	'Bojo'	, label:	'Bojo'	, link:	'Aloguinsan'	},
	{value:	'Bonbon'	, label:	'Bonbon'	, link:	'Aloguinsan'	},
	{value:	'Esperanza'	, label:	'Esperanza'	, link:	'Aloguinsan'	},
	{value:	'Kandingan'	, label:	'Kandingan'	, link:	'Aloguinsan'	},
	{value:	'Kantabogon'	, label:	'Kantabogon'	, link:	'Aloguinsan'	},
	{value:	'Kawasan'	, label:	'Kawasan'	, link:	'Aloguinsan'	},
	{value:	'Olango'	, label:	'Olango'	, link:	'Aloguinsan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Aloguinsan'	},
	{value:	'Punay'	, label:	'Punay'	, link:	'Aloguinsan'	},
	{value:	'Rosario'	, label:	'Rosario'	, link:	'Aloguinsan'	},
	{value:	'Saksak'	, label:	'Saksak'	, link:	'Aloguinsan'	},
	{value:	'Tampa-An'	, label:	'Tampa-An'	, link:	'Aloguinsan'	},
	{value:	'Toyokon'	, label:	'Toyokon'	, link:	'Aloguinsan'	},
	{value:	'Zaragosa'	, label:	'Zaragosa'	, link:	'Aloguinsan'	},
	{value:	'Alambijud'	, label:	'Alambijud'	, link:	'Argao'	},
	{value:	'Anajao'	, label:	'Anajao'	, link:	'Argao'	},
	{value:	'Apo'	, label:	'Apo'	, link:	'Argao'	},
	{value:	'Balaas'	, label:	'Balaas'	, link:	'Argao'	},
	{value:	'Balisong'	, label:	'Balisong'	, link:	'Argao'	},
	{value:	'Binlod'	, label:	'Binlod'	, link:	'Argao'	},
	{value:	'Bogo'	, label:	'Bogo'	, link:	'Argao'	},
	{value:	'Bug-Ot'	, label:	'Bug-Ot'	, link:	'Argao'	},
	{value:	'Bulasa'	, label:	'Bulasa'	, link:	'Argao'	},
	{value:	'Butong'	, label:	'Butong'	, link:	'Argao'	},
	{value:	'Calagasan'	, label:	'Calagasan'	, link:	'Argao'	},
	{value:	'Canbantug'	, label:	'Canbantug'	, link:	'Argao'	},
	{value:	'Canbanua'	, label:	'Canbanua'	, link:	'Argao'	},
	{value:	'Cansuje'	, label:	'Cansuje'	, link:	'Argao'	},
	{value:	'Capio-An'	, label:	'Capio-An'	, link:	'Argao'	},
	{value:	'Casay'	, label:	'Casay'	, link:	'Argao'	},
	{value:	'Catang'	, label:	'Catang'	, link:	'Argao'	},
	{value:	'Colawin'	, label:	'Colawin'	, link:	'Argao'	},
	{value:	'Conalum'	, label:	'Conalum'	, link:	'Argao'	},
	{value:	'Guiwanon'	, label:	'Guiwanon'	, link:	'Argao'	},
	{value:	'Gutlang'	, label:	'Gutlang'	, link:	'Argao'	},
	{value:	'Jampang'	, label:	'Jampang'	, link:	'Argao'	},
	{value:	'Jomgao'	, label:	'Jomgao'	, link:	'Argao'	},
	{value:	'Lamacan'	, label:	'Lamacan'	, link:	'Argao'	},
	{value:	'Langtad'	, label:	'Langtad'	, link:	'Argao'	},
	{value:	'Langub'	, label:	'Langub'	, link:	'Argao'	},
	{value:	'Lapay'	, label:	'Lapay'	, link:	'Argao'	},
	{value:	'Lengigon'	, label:	'Lengigon'	, link:	'Argao'	},
	{value:	'Linut-Od'	, label:	'Linut-Od'	, link:	'Argao'	},
	{value:	'Mabasa'	, label:	'Mabasa'	, link:	'Argao'	},
	{value:	'Mandilikit'	, label:	'Mandilikit'	, link:	'Argao'	},
	{value:	'Mompeller'	, label:	'Mompeller'	, link:	'Argao'	},
	{value:	'Panadtaran'	, label:	'Panadtaran'	, link:	'Argao'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Argao'	},
	{value:	'Sua'	, label:	'Sua'	, link:	'Argao'	},
	{value:	'Sumaguan'	, label:	'Sumaguan'	, link:	'Argao'	},
	{value:	'Tabayag'	, label:	'Tabayag'	, link:	'Argao'	},
	{value:	'Talaga'	, label:	'Talaga'	, link:	'Argao'	},
	{value:	'Talaytay'	, label:	'Talaytay'	, link:	'Argao'	},
	{value:	'Talo-Ot'	, label:	'Talo-Ot'	, link:	'Argao'	},
	{value:	'Tiguib'	, label:	'Tiguib'	, link:	'Argao'	},
	{value:	'Tulang'	, label:	'Tulang'	, link:	'Argao'	},
	{value:	'Tulic'	, label:	'Tulic'	, link:	'Argao'	},
	{value:	'Ubaub'	, label:	'Ubaub'	, link:	'Argao'	},
	{value:	'Usmad'	, label:	'Usmad'	, link:	'Argao'	},
	{value:	'Agbanga'	, label:	'Agbanga'	, link:	'Asturias'	},
	{value:	'Agtugop'	, label:	'Agtugop'	, link:	'Asturias'	},
	{value:	'Bago'	, label:	'Bago'	, link:	'Asturias'	},
	{value:	'Bairan'	, label:	'Bairan'	, link:	'Asturias'	},
	{value:	'Banban'	, label:	'Banban'	, link:	'Asturias'	},
	{value:	'Baye'	, label:	'Baye'	, link:	'Asturias'	},
	{value:	'Bog-O'	, label:	'Bog-O'	, link:	'Asturias'	},
	{value:	'Kaluangan'	, label:	'Kaluangan'	, link:	'Asturias'	},
	{value:	'Lanao'	, label:	'Lanao'	, link:	'Asturias'	},
	{value:	'Langub'	, label:	'Langub'	, link:	'Asturias'	},
	{value:	'Looc Norte'	, label:	'Looc Norte'	, link:	'Asturias'	},
	{value:	'Lunas'	, label:	'Lunas'	, link:	'Asturias'	},
	{value:	'Magcalape'	, label:	'Magcalape'	, link:	'Asturias'	},
	{value:	'Manguiao'	, label:	'Manguiao'	, link:	'Asturias'	},
	{value:	'New Bago'	, label:	'New Bago'	, link:	'Asturias'	},
	{value:	'Owak'	, label:	'Owak'	, link:	'Asturias'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Asturias'	},
	{value:	'Saksak'	, label:	'Saksak'	, link:	'Asturias'	},
	{value:	'San Isidro'	, label:	'San Isidro'	, link:	'Asturias'	},
	{value:	'San Roque'	, label:	'San Roque'	, link:	'Asturias'	},
	{value:	'Santa Lucia'	, label:	'Santa Lucia'	, link:	'Asturias'	},
	{value:	'Santa Rita'	, label:	'Santa Rita'	, link:	'Asturias'	},
	{value:	'Tag-Amakan'	, label:	'Tag-Amakan'	, link:	'Asturias'	},
	{value:	'Tagbubonga'	, label:	'Tagbubonga'	, link:	'Asturias'	},
	{value:	'Tubigagmanok'	, label:	'Tubigagmanok'	, link:	'Asturias'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'Asturias'	},
	{value:	'Ubogon'	, label:	'Ubogon'	, link:	'Asturias'	},
	{value:	'Alawijao'	, label:	'Alawijao'	, link:	'Badian'	},
	{value:	'Balhaan'	, label:	'Balhaan'	, link:	'Badian'	},
	{value:	'Banhigan'	, label:	'Banhigan'	, link:	'Badian'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Badian'	},
	{value:	'Basiao'	, label:	'Basiao'	, link:	'Badian'	},
	{value:	'Bato'	, label:	'Bato'	, link:	'Badian'	},
	{value:	'Bugas'	, label:	'Bugas'	, link:	'Badian'	},
	{value:	'Calangcang'	, label:	'Calangcang'	, link:	'Badian'	},
	{value:	'Candiis'	, label:	'Candiis'	, link:	'Badian'	},
	{value:	'Dagatan'	, label:	'Dagatan'	, link:	'Badian'	},
	{value:	'Dobdob'	, label:	'Dobdob'	, link:	'Badian'	},
	{value:	'Ginablan'	, label:	'Ginablan'	, link:	'Badian'	},
	{value:	'Lambug'	, label:	'Lambug'	, link:	'Badian'	},
	{value:	'Malabago'	, label:	'Malabago'	, link:	'Badian'	},
	{value:	'Malhiao'	, label:	'Malhiao'	, link:	'Badian'	},
	{value:	'Manduyong'	, label:	'Manduyong'	, link:	'Badian'	},
	{value:	'Cansomoroy'	, label:	'Cansomoroy'	, link:	'Balamban'	},
	{value:	'Cantibas'	, label:	'Cantibas'	, link:	'Balamban'	},
	{value:	'Cantuod'	, label:	'Cantuod'	, link:	'Balamban'	},
	{value:	'Duangan'	, label:	'Duangan'	, link:	'Balamban'	},
	{value:	'Gaas'	, label:	'Gaas'	, link:	'Balamban'	},
	{value:	'Ginatilan'	, label:	'Ginatilan'	, link:	'Balamban'	},
	{value:	'Hingatmonan'	, label:	'Hingatmonan'	, link:	'Balamban'	},
	{value:	'Lamesa'	, label:	'Lamesa'	, link:	'Balamban'	},
	{value:	'Liki'	, label:	'Liki'	, link:	'Balamban'	},
	{value:	'Luca'	, label:	'Luca'	, link:	'Balamban'	},
	{value:	'Matun-Og'	, label:	'Matun-Og'	, link:	'Balamban'	},
	{value:	'Nangka'	, label:	'Nangka'	, link:	'Balamban'	},
	{value:	'Pondol'	, label:	'Pondol'	, link:	'Balamban'	},
	{value:	'Prenza'	, label:	'Prenza'	, link:	'Balamban'	},
	{value:	'Santa Cruz-Santo NiÃ±o'	, label:	'Santa Cruz-Santo NiÃ±o'	, link:	'Balamban'	},
	{value:	'Singsing'	, label:	'Singsing'	, link:	'Balamban'	},
	{value:	'Sunog'	, label:	'Sunog'	, link:	'Balamban'	},
	{value:	'Vito'	, label:	'Vito'	, link:	'Balamban'	},
	{value:	'Atop-Atop'	, label:	'Atop-Atop'	, link:	'Bantayan'	},
	{value:	'Baigad'	, label:	'Baigad'	, link:	'Bantayan'	},
	{value:	'Bantigue'	, label:	'Bantigue'	, link:	'Bantayan'	},
	{value:	'Baod'	, label:	'Baod'	, link:	'Bantayan'	},
	{value:	'Binaobao'	, label:	'Binaobao'	, link:	'Bantayan'	},
	{value:	'Botigues'	, label:	'Botigues'	, link:	'Bantayan'	},
	{value:	'Doong'	, label:	'Doong'	, link:	'Bantayan'	},
	{value:	'Guiwanon'	, label:	'Guiwanon'	, link:	'Bantayan'	},
	{value:	'Hilotongan'	, label:	'Hilotongan'	, link:	'Bantayan'	},
	{value:	'Kabac'	, label:	'Kabac'	, link:	'Bantayan'	},
	{value:	'Kabangbang'	, label:	'Kabangbang'	, link:	'Bantayan'	},
	{value:	'Kampingganon'	, label:	'Kampingganon'	, link:	'Bantayan'	},
	{value:	'Kangkaibe'	, label:	'Kangkaibe'	, link:	'Bantayan'	},
	{value:	'Lipayran'	, label:	'Lipayran'	, link:	'Bantayan'	},
	{value:	'Luyongbaybay'	, label:	'Luyongbaybay'	, link:	'Bantayan'	},
	{value:	'Mojon'	, label:	'Mojon'	, link:	'Bantayan'	},
	{value:	'Obo-Ob'	, label:	'Obo-Ob'	, link:	'Bantayan'	},
	{value:	'Patao'	, label:	'Patao'	, link:	'Bantayan'	},
	{value:	'Putian'	, label:	'Putian'	, link:	'Bantayan'	},
	{value:	'Sillon'	, label:	'Sillon'	, link:	'Bantayan'	},
	{value:	'Suba'	, label:	'Suba'	, link:	'Bantayan'	},
	{value:	'Sulangan'	, label:	'Sulangan'	, link:	'Bantayan'	},
	{value:	'Sungko'	, label:	'Sungko'	, link:	'Bantayan'	},
	{value:	'Tamiao'	, label:	'Tamiao'	, link:	'Bantayan'	},
	{value:	'Ticad'	, label:	'Ticad'	, link:	'Bantayan'	},
	{value:	'Azucena'	, label:	'Azucena'	, link:	'Barili'	},
	{value:	'Bagakay'	, label:	'Bagakay'	, link:	'Barili'	},
	{value:	'Balao'	, label:	'Balao'	, link:	'Barili'	},
	{value:	'Bolocboloc'	, label:	'Bolocboloc'	, link:	'Barili'	},
	{value:	'Budbud'	, label:	'Budbud'	, link:	'Barili'	},
	{value:	'Bugtong Kawayan'	, label:	'Bugtong Kawayan'	, link:	'Barili'	},
	{value:	'Cabcaban'	, label:	'Cabcaban'	, link:	'Barili'	},
	{value:	'Cagay'	, label:	'Cagay'	, link:	'Barili'	},
	{value:	'Campangga'	, label:	'Campangga'	, link:	'Barili'	},
	{value:	'Candugay'	, label:	'Candugay'	, link:	'Barili'	},
	{value:	'Dakit'	, label:	'Dakit'	, link:	'Barili'	},
	{value:	'Giloctog'	, label:	'Giloctog'	, link:	'Barili'	},
	{value:	'Giwanon'	, label:	'Giwanon'	, link:	'Barili'	},
	{value:	'Guibuangan'	, label:	'Guibuangan'	, link:	'Barili'	},
	{value:	'Gunting'	, label:	'Gunting'	, link:	'Barili'	},
	{value:	'Hilasgasan'	, label:	'Hilasgasan'	, link:	'Barili'	},
	{value:	'Japitan'	, label:	'Japitan'	, link:	'Barili'	},
	{value:	'Kalubihan'	, label:	'Kalubihan'	, link:	'Barili'	},
	{value:	'Kangdampas'	, label:	'Kangdampas'	, link:	'Barili'	},
	{value:	'Luhod'	, label:	'Luhod'	, link:	'Barili'	},
	{value:	'Lupo'	, label:	'Lupo'	, link:	'Barili'	},
	{value:	'Luyo'	, label:	'Luyo'	, link:	'Barili'	},
	{value:	'Maghanoy'	, label:	'Maghanoy'	, link:	'Barili'	},
	{value:	'Maigang'	, label:	'Maigang'	, link:	'Barili'	},
	{value:	'Malolos'	, label:	'Malolos'	, link:	'Barili'	},
	{value:	'Mantalongon'	, label:	'Mantalongon'	, link:	'Barili'	},
	{value:	'Mantayupan'	, label:	'Mantayupan'	, link:	'Barili'	},
	{value:	'Mayana'	, label:	'Mayana'	, link:	'Barili'	},
	{value:	'Minolos'	, label:	'Minolos'	, link:	'Barili'	},
	{value:	'Nabunturan'	, label:	'Nabunturan'	, link:	'Barili'	},
	{value:	'Nasipit'	, label:	'Nasipit'	, link:	'Barili'	},
	{value:	'Pancil'	, label:	'Pancil'	, link:	'Barili'	},
	{value:	'Pangpang'	, label:	'Pangpang'	, link:	'Barili'	},
	{value:	'Paril'	, label:	'Paril'	, link:	'Barili'	},
	{value:	'Patupat'	, label:	'Patupat'	, link:	'Barili'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Barili'	},
	{value:	'San Rafael'	, label:	'San Rafael'	, link:	'Barili'	},
	{value:	'Santa Ana'	, label:	'Santa Ana'	, link:	'Barili'	},
	{value:	'Sayaw'	, label:	'Sayaw'	, link:	'Barili'	},
	{value:	'Tal-Ot'	, label:	'Tal-Ot'	, link:	'Barili'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'Barili'	},
	{value:	'Vito'	, label:	'Vito'	, link:	'Barili'	},
	{value:	'Anonang Norte'	, label:	'Anonang Norte'	, link:	'Bogo City'	},
	{value:	'Anonang Sur'	, label:	'Anonang Sur'	, link:	'Bogo City'	},
	{value:	'Banban'	, label:	'Banban'	, link:	'Bogo City'	},
	{value:	'Binabag'	, label:	'Binabag'	, link:	'Bogo City'	},
	{value:	'Bungtod'	, label:	'Bungtod'	, link:	'Bogo City'	},
	{value:	'Carbon'	, label:	'Carbon'	, link:	'Bogo City'	},
	{value:	'Cayang'	, label:	'Cayang'	, link:	'Bogo City'	},
	{value:	'Cogon'	, label:	'Cogon'	, link:	'Bogo City'	},
	{value:	'Dakit'	, label:	'Dakit'	, link:	'Bogo City'	},
	{value:	'Don Pedro Rodriguez'	, label:	'Don Pedro Rodriguez'	, link:	'Bogo City'	},
	{value:	'Gairan'	, label:	'Gairan'	, link:	'Bogo City'	},
	{value:	'Guadalupe'	, label:	'Guadalupe'	, link:	'Bogo City'	},
	{value:	'La Paz'	, label:	'La Paz'	, link:	'Bogo City'	},
	{value:	'La Purisima Concepcion'	, label:	'La Purisima Concepcion'	, link:	'Bogo City'	},
	{value:	'Libertad'	, label:	'Libertad'	, link:	'Bogo City'	},
	{value:	'Lourdes'	, label:	'Lourdes'	, link:	'Bogo City'	},
	{value:	'Malingin'	, label:	'Malingin'	, link:	'Bogo City'	},
	{value:	'Marangog'	, label:	'Marangog'	, link:	'Bogo City'	},
	{value:	'Nailon'	, label:	'Nailon'	, link:	'Bogo City'	},
	{value:	'Odlot'	, label:	'Odlot'	, link:	'Bogo City'	},
	{value:	'Pandan'	, label:	'Pandan'	, link:	'Bogo City'	},
	{value:	'Polambato'	, label:	'Polambato'	, link:	'Bogo City'	},
	{value:	'Sambag'	, label:	'Sambag'	, link:	'Bogo City'	},
	{value:	'San Vicente'	, label:	'San Vicente'	, link:	'Bogo City'	},
	{value:	'Santo NiÃ±o'	, label:	'Santo NiÃ±o'	, link:	'Bogo City'	},
	{value:	'Santo Rosario'	, label:	'Santo Rosario'	, link:	'Bogo City'	},
	{value:	'Siocon'	, label:	'Siocon'	, link:	'Bogo City'	},
	{value:	'Don Gregorio Antigua'	, label:	'Don Gregorio Antigua'	, link:	'Borbon'	},
	{value:	'Laaw'	, label:	'Laaw'	, link:	'Borbon'	},
	{value:	'Lugo'	, label:	'Lugo'	, link:	'Borbon'	},
	{value:	'Managase'	, label:	'Managase'	, link:	'Borbon'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Borbon'	},
	{value:	'Sagay'	, label:	'Sagay'	, link:	'Borbon'	},
	{value:	'San Jose'	, label:	'San Jose'	, link:	'Borbon'	},
	{value:	'Tabunan'	, label:	'Tabunan'	, link:	'Borbon'	},
	{value:	'Tagnucan'	, label:	'Tagnucan'	, link:	'Borbon'	},
	{value:	'Bolinawan'	, label:	'Bolinawan'	, link:	'Carcar'	},
	{value:	'Buenavista'	, label:	'Buenavista'	, link:	'Carcar'	},
	{value:	'Calidngan'	, label:	'Calidngan'	, link:	'Carcar'	},
	{value:	'Can-Asujan'	, label:	'Can-Asujan'	, link:	'Carcar'	},
	{value:	'Guadalupe'	, label:	'Guadalupe'	, link:	'Carcar'	},
	{value:	'Liburon'	, label:	'Liburon'	, link:	'Carcar'	},
	{value:	'Napo'	, label:	'Napo'	, link:	'Carcar'	},
	{value:	'Ocana'	, label:	'Ocana'	, link:	'Carcar'	},
	{value:	'Perrelos'	, label:	'Perrelos'	, link:	'Carcar'	},
	{value:	'Poblacion I'	, label:	'Poblacion I'	, link:	'Carcar'	},
	{value:	'Poblacion II'	, label:	'Poblacion II'	, link:	'Carcar'	},
	{value:	'Poblacion III'	, label:	'Poblacion III'	, link:	'Carcar'	},
	{value:	'Tuyom'	, label:	'Tuyom'	, link:	'Carcar'	},
	{value:	'Valencia'	, label:	'Valencia'	, link:	'Carcar'	},
	{value:	'Valladolid'	, label:	'Valladolid'	, link:	'Carcar'	},
	{value:	'Baring'	, label:	'Baring'	, link:	'Carmen'	},
	{value:	'Cantipay'	, label:	'Cantipay'	, link:	'Carmen'	},
	{value:	'Cantukong'	, label:	'Cantukong'	, link:	'Carmen'	},
	{value:	'Cantumog'	, label:	'Cantumog'	, link:	'Carmen'	},
	{value:	'Caurasan'	, label:	'Caurasan'	, link:	'Carmen'	},
	{value:	'Cogon East'	, label:	'Cogon East'	, link:	'Carmen'	},
	{value:	'Cogon West'	, label:	'Cogon West'	, link:	'Carmen'	},
	{value:	'Corte'	, label:	'Corte'	, link:	'Carmen'	},
	{value:	'Dawis Norte'	, label:	'Dawis Norte'	, link:	'Carmen'	},
	{value:	'Dawis Sur'	, label:	'Dawis Sur'	, link:	'Carmen'	},
	{value:	'Hagnaya'	, label:	'Hagnaya'	, link:	'Carmen'	},
	{value:	'Ipil'	, label:	'Ipil'	, link:	'Carmen'	},
	{value:	'Lanipga'	, label:	'Lanipga'	, link:	'Carmen'	},
	{value:	'Liboron'	, label:	'Liboron'	, link:	'Carmen'	},
	{value:	'Lower Natimao-An'	, label:	'Lower Natimao-An'	, link:	'Carmen'	},
	{value:	'Luyang'	, label:	'Luyang'	, link:	'Carmen'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Carmen'	},
	{value:	'Puente'	, label:	'Puente'	, link:	'Carmen'	},
	{value:	'Sac-On'	, label:	'Sac-On'	, link:	'Carmen'	},
	{value:	'Triumfo'	, label:	'Triumfo'	, link:	'Carmen'	},
	{value:	'Upper Natimao-An'	, label:	'Upper Natimao-An'	, link:	'Carmen'	},
	{value:	'Agsuwao'	, label:	'Agsuwao'	, link:	'Catmon'	},
	{value:	'Amancion'	, label:	'Amancion'	, link:	'Catmon'	},
	{value:	'Anapog'	, label:	'Anapog'	, link:	'Catmon'	},
	{value:	'Bactas'	, label:	'Bactas'	, link:	'Catmon'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Catmon'	},
	{value:	'Binongkalan'	, label:	'Binongkalan'	, link:	'Catmon'	},
	{value:	'Bongyas'	, label:	'Bongyas'	, link:	'Catmon'	},
	{value:	'Cabungaan'	, label:	'Cabungaan'	, link:	'Catmon'	},
	{value:	'Cambangkaya'	, label:	'Cambangkaya'	, link:	'Catmon'	},
	{value:	'Can-Ibuang'	, label:	'Can-Ibuang'	, link:	'Catmon'	},
	{value:	'Catmondaan'	, label:	'Catmondaan'	, link:	'Catmon'	},
	{value:	'Corazon'	, label:	'Corazon'	, link:	'Catmon'	},
	{value:	'Duyan'	, label:	'Duyan'	, link:	'Catmon'	},
	{value:	'Flores'	, label:	'Flores'	, link:	'Catmon'	},
	{value:	'Ginabucan'	, label:	'Ginabucan'	, link:	'Catmon'	},
	{value:	'Macaas'	, label:	'Macaas'	, link:	'Catmon'	},
	{value:	'Panalipan'	, label:	'Panalipan'	, link:	'Catmon'	},
	{value:	'San Jose Poblacion'	, label:	'San Jose Poblacion'	, link:	'Catmon'	},
	{value:	'Tabili'	, label:	'Tabili'	, link:	'Catmon'	},
	{value:	'Tinabyonan'	, label:	'Tinabyonan'	, link:	'Catmon'	},
	{value:	'Adlaon'	, label:	'Adlaon'	, link:	'Cebu City'	},
	{value:	'Agsungot'	, label:	'Agsungot'	, link:	'Cebu City'	},
	{value:	'Apas'	, label:	'Apas'	, link:	'Cebu City'	},
	{value:	'Babag'	, label:	'Babag'	, link:	'Cebu City'	},
	{value:	'Bacayan'	, label:	'Bacayan'	, link:	'Cebu City'	},
	{value:	'Banilad'	, label:	'Banilad'	, link:	'Cebu City'	},
	{value:	'Basak Pardo'	, label:	'Basak Pardo'	, link:	'Cebu City'	},
	{value:	'Basak San Nicolas'	, label:	'Basak San Nicolas'	, link:	'Cebu City'	},
	{value:	'Binaliw'	, label:	'Binaliw'	, link:	'Cebu City'	},
	{value:	'Bonbon'	, label:	'Bonbon'	, link:	'Cebu City'	},
	{value:	'Budla-An'	, label:	'Budla-An'	, link:	'Cebu City'	},
	{value:	'Buhisan'	, label:	'Buhisan'	, link:	'Cebu City'	},
	{value:	'Bulacao'	, label:	'Bulacao'	, link:	'Cebu City'	},
	{value:	'Buot-Taup Pardo'	, label:	'Buot-Taup Pardo'	, link:	'Cebu City'	},
	{value:	'Busay'	, label:	'Busay'	, link:	'Cebu City'	},
	{value:	'Calamba'	, label:	'Calamba'	, link:	'Cebu City'	},
	{value:	'Cambinocot'	, label:	'Cambinocot'	, link:	'Cebu City'	},
	{value:	'Camputhaw'	, label:	'Camputhaw'	, link:	'Cebu City'	},
	{value:	'Capitol Site'	, label:	'Capitol Site'	, link:	'Cebu City'	},
	{value:	'Carreta'	, label:	'Carreta'	, link:	'Cebu City'	},
	{value:	'Cogon Pardo'	, label:	'Cogon Pardo'	, link:	'Cebu City'	},
	{value:	'Cogon Ramos'	, label:	'Cogon Ramos'	, link:	'Cebu City'	},
	{value:	'Day-As'	, label:	'Day-As'	, link:	'Cebu City'	},
	{value:	'Duljo'	, label:	'Duljo'	, link:	'Cebu City'	},
	{value:	'Ermita'	, label:	'Ermita'	, link:	'Cebu City'	},
	{value:	'Guadalupe'	, label:	'Guadalupe'	, link:	'Cebu City'	},
	{value:	'Guba'	, label:	'Guba'	, link:	'Cebu City'	},
	{value:	'Hippodromo'	, label:	'Hippodromo'	, link:	'Cebu City'	},
	{value:	'Inayawan'	, label:	'Inayawan'	, link:	'Cebu City'	},
	{value:	'Kalubihan'	, label:	'Kalubihan'	, link:	'Cebu City'	},
	{value:	'Kalunasan'	, label:	'Kalunasan'	, link:	'Cebu City'	},
	{value:	'Kamagayan'	, label:	'Kamagayan'	, link:	'Cebu City'	},
	{value:	'Kasambagan'	, label:	'Kasambagan'	, link:	'Cebu City'	},
	{value:	'Kinasang-An Pardo'	, label:	'Kinasang-An Pardo'	, link:	'Cebu City'	},
	{value:	'Labangon'	, label:	'Labangon'	, link:	'Cebu City'	},
	{value:	'Lahug'	, label:	'Lahug'	, link:	'Cebu City'	},
	{value:	'Lorega'	, label:	'Lorega'	, link:	'Cebu City'	},
	{value:	'Lusaran'	, label:	'Lusaran'	, link:	'Cebu City'	},
	{value:	'Luz'	, label:	'Luz'	, link:	'Cebu City'	},
	{value:	'Mabini'	, label:	'Mabini'	, link:	'Cebu City'	},
	{value:	'Mabolo'	, label:	'Mabolo'	, link:	'Cebu City'	},
	{value:	'Malubog'	, label:	'Malubog'	, link:	'Cebu City'	},
	{value:	'Mambaling'	, label:	'Mambaling'	, link:	'Cebu City'	},
	{value:	'Pahina Central'	, label:	'Pahina Central'	, link:	'Cebu City'	},
	{value:	'Pahina San Nicolas'	, label:	'Pahina San Nicolas'	, link:	'Cebu City'	},
	{value:	'Pamutan'	, label:	'Pamutan'	, link:	'Cebu City'	},
	{value:	'Pardo'	, label:	'Pardo'	, link:	'Cebu City'	},
	{value:	'Pari-An'	, label:	'Pari-An'	, link:	'Cebu City'	},
	{value:	'Paril'	, label:	'Paril'	, link:	'Cebu City'	},
	{value:	'Pasil'	, label:	'Pasil'	, link:	'Cebu City'	},
	{value:	'Pit-Os'	, label:	'Pit-Os'	, link:	'Cebu City'	},
	{value:	'Pulangbato'	, label:	'Pulangbato'	, link:	'Cebu City'	},
	{value:	'Pung-Ol-Sibugay'	, label:	'Pung-Ol-Sibugay'	, link:	'Cebu City'	},
	{value:	'Punta Princesa'	, label:	'Punta Princesa'	, link:	'Cebu City'	},
	{value:	'Quiot Pardo'	, label:	'Quiot Pardo'	, link:	'Cebu City'	},
	{value:	'Sambag I'	, label:	'Sambag I'	, link:	'Cebu City'	},
	{value:	'Sambag II'	, label:	'Sambag II'	, link:	'Cebu City'	},
	{value:	'San Antonio'	, label:	'San Antonio'	, link:	'Cebu City'	},
	{value:	'San Jose'	, label:	'San Jose'	, link:	'Cebu City'	},
	{value:	'San Nicolas Central'	, label:	'San Nicolas Central'	, link:	'Cebu City'	},
	{value:	'San Roque'	, label:	'San Roque'	, link:	'Cebu City'	},
	{value:	'Santa Cruz'	, label:	'Santa Cruz'	, link:	'Cebu City'	},
	{value:	'Sapangdaku'	, label:	'Sapangdaku'	, link:	'Cebu City'	},
	{value:	'Sawang Calero'	, label:	'Sawang Calero'	, link:	'Cebu City'	},
	{value:	'Sinsin'	, label:	'Sinsin'	, link:	'Cebu City'	},
	{value:	'Sirao'	, label:	'Sirao'	, link:	'Cebu City'	},
	{value:	'Sr Santo NiÃ±o'	, label:	'Sr Santo NiÃ±o'	, link:	'Cebu City'	},
	{value:	'Suba Poblacion'	, label:	'Suba Poblacion'	, link:	'Cebu City'	},
	{value:	'Sudlon I'	, label:	'Sudlon I'	, link:	'Cebu City'	},
	{value:	'Sudlon II'	, label:	'Sudlon II'	, link:	'Cebu City'	},
	{value:	'T. Padilla'	, label:	'T. Padilla'	, link:	'Cebu City'	},
	{value:	'Tabunan'	, label:	'Tabunan'	, link:	'Cebu City'	},
	{value:	'Tagbao'	, label:	'Tagbao'	, link:	'Cebu City'	},
	{value:	'Talamban'	, label:	'Talamban'	, link:	'Cebu City'	},
	{value:	'Taptap'	, label:	'Taptap'	, link:	'Cebu City'	},
	{value:	'Tejero'	, label:	'Tejero'	, link:	'Cebu City'	},
	{value:	'Tinago'	, label:	'Tinago'	, link:	'Cebu City'	},
	{value:	'Tisa'	, label:	'Tisa'	, link:	'Cebu City'	},
	{value:	'To-Ong Pardo'	, label:	'To-Ong Pardo'	, link:	'Cebu City'	},
	{value:	'Zapatera'	, label:	'Zapatera'	, link:	'Cebu City'	},
	{value:	'Bagalnga'	, label:	'Bagalnga'	, link:	'Compostela'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Compostela'	},
	{value:	'Buluang'	, label:	'Buluang'	, link:	'Compostela'	},
	{value:	'Cabadiangan'	, label:	'Cabadiangan'	, link:	'Compostela'	},
	{value:	'Cambayog'	, label:	'Cambayog'	, link:	'Compostela'	},
	{value:	'Canamucan'	, label:	'Canamucan'	, link:	'Compostela'	},
	{value:	'Cogon'	, label:	'Cogon'	, link:	'Compostela'	},
	{value:	'Dapdap'	, label:	'Dapdap'	, link:	'Compostela'	},
	{value:	'Estaca'	, label:	'Estaca'	, link:	'Compostela'	},
	{value:	'Lupa'	, label:	'Lupa'	, link:	'Compostela'	},
	{value:	'Magay'	, label:	'Magay'	, link:	'Compostela'	},
	{value:	'Mulao'	, label:	'Mulao'	, link:	'Compostela'	},
	{value:	'Panangban'	, label:	'Panangban'	, link:	'Compostela'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Compostela'	},
	{value:	'Tag-Ube'	, label:	'Tag-Ube'	, link:	'Compostela'	},
	{value:	'Tamiao'	, label:	'Tamiao'	, link:	'Compostela'	},
	{value:	'Tubigan'	, label:	'Tubigan'	, link:	'Compostela'	},
	{value:	'Cabangahan'	, label:	'Cabangahan'	, link:	'Consolacion'	},
	{value:	'Cansaga'	, label:	'Cansaga'	, link:	'Consolacion'	},
	{value:	'Casili'	, label:	'Casili'	, link:	'Consolacion'	},
	{value:	'Danglag'	, label:	'Danglag'	, link:	'Consolacion'	},
	{value:	'Garing'	, label:	'Garing'	, link:	'Consolacion'	},
	{value:	'Jugan'	, label:	'Jugan'	, link:	'Consolacion'	},
	{value:	'Lamac'	, label:	'Lamac'	, link:	'Consolacion'	},
	{value:	'Lanipga'	, label:	'Lanipga'	, link:	'Consolacion'	},
	{value:	'Nangka'	, label:	'Nangka'	, link:	'Consolacion'	},
	{value:	'Panas'	, label:	'Panas'	, link:	'Consolacion'	},
	{value:	'Panoypoy'	, label:	'Panoypoy'	, link:	'Consolacion'	},
	{value:	'Pitogo'	, label:	'Pitogo'	, link:	'Consolacion'	},
	{value:	'Poblacion Occidental'	, label:	'Poblacion Occidental'	, link:	'Consolacion'	},
	{value:	'Poblacion Oriental'	, label:	'Poblacion Oriental'	, link:	'Consolacion'	},
	{value:	'Polog'	, label:	'Polog'	, link:	'Consolacion'	},
	{value:	'Pulpogan'	, label:	'Pulpogan'	, link:	'Consolacion'	},
	{value:	'Sacsac'	, label:	'Sacsac'	, link:	'Consolacion'	},
	{value:	'Tayud'	, label:	'Tayud'	, link:	'Consolacion'	},
	{value:	'Tilhaong'	, label:	'Tilhaong'	, link:	'Consolacion'	},
	{value:	'Tolotolo'	, label:	'Tolotolo'	, link:	'Consolacion'	},
	{value:	'Tugbongan'	, label:	'Tugbongan'	, link:	'Consolacion'	},
	{value:	'Alegria'	, label:	'Alegria'	, link:	'Cordova'	},
	{value:	'Bangbang'	, label:	'Bangbang'	, link:	'Cordova'	},
	{value:	'Buagsong'	, label:	'Buagsong'	, link:	'Cordova'	},
	{value:	'Catarman'	, label:	'Catarman'	, link:	'Cordova'	},
	{value:	'Cogon'	, label:	'Cogon'	, link:	'Cordova'	},
	{value:	'Dapitan'	, label:	'Dapitan'	, link:	'Cordova'	},
	{value:	'Day-As'	, label:	'Day-As'	, link:	'Cordova'	},
	{value:	'Gabi'	, label:	'Gabi'	, link:	'Cordova'	},
	{value:	'Gilutongan'	, label:	'Gilutongan'	, link:	'Cordova'	},
	{value:	'Ibabao'	, label:	'Ibabao'	, link:	'Cordova'	},
	{value:	'Pilipog'	, label:	'Pilipog'	, link:	'Cordova'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Cordova'	},
	{value:	'San Miguel'	, label:	'San Miguel'	, link:	'Cordova'	},
	{value:	'Aguho'	, label:	'Aguho'	, link:	'Daanbantayan'	},
	{value:	'Bagay'	, label:	'Bagay'	, link:	'Daanbantayan'	},
	{value:	'Bakhawan'	, label:	'Bakhawan'	, link:	'Daanbantayan'	},
	{value:	'Bateria'	, label:	'Bateria'	, link:	'Daanbantayan'	},
	{value:	'Bitoon'	, label:	'Bitoon'	, link:	'Daanbantayan'	},
	{value:	'Calape'	, label:	'Calape'	, link:	'Daanbantayan'	},
	{value:	'Carnaza'	, label:	'Carnaza'	, link:	'Daanbantayan'	},
	{value:	'Dalingding'	, label:	'Dalingding'	, link:	'Daanbantayan'	},
	{value:	'Lanao'	, label:	'Lanao'	, link:	'Daanbantayan'	},
	{value:	'Logon'	, label:	'Logon'	, link:	'Daanbantayan'	},
	{value:	'Malbago'	, label:	'Malbago'	, link:	'Daanbantayan'	},
	{value:	'Malingin'	, label:	'Malingin'	, link:	'Daanbantayan'	},
	{value:	'Maya'	, label:	'Maya'	, link:	'Daanbantayan'	},
	{value:	'Pajo'	, label:	'Pajo'	, link:	'Daanbantayan'	},
	{value:	'Paypay'	, label:	'Paypay'	, link:	'Daanbantayan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Daanbantayan'	},
	{value:	'Talisay'	, label:	'Talisay'	, link:	'Daanbantayan'	},
	{value:	'Tapilon'	, label:	'Tapilon'	, link:	'Daanbantayan'	},
	{value:	'Tinubdan'	, label:	'Tinubdan'	, link:	'Daanbantayan'	},
	{value:	'Tominjao'	, label:	'Tominjao'	, link:	'Daanbantayan'	},
	{value:	'Ablayan'	, label:	'Ablayan'	, link:	'Dalaguete'	},
	{value:	'Babayongan'	, label:	'Babayongan'	, link:	'Dalaguete'	},
	{value:	'Balud'	, label:	'Balud'	, link:	'Dalaguete'	},
	{value:	'Banhigan'	, label:	'Banhigan'	, link:	'Dalaguete'	},
	{value:	'Bulak'	, label:	'Bulak'	, link:	'Dalaguete'	},
	{value:	'Caleriohan'	, label:	'Caleriohan'	, link:	'Dalaguete'	},
	{value:	'Caliongan'	, label:	'Caliongan'	, link:	'Dalaguete'	},
	{value:	'Casay'	, label:	'Casay'	, link:	'Dalaguete'	},
	{value:	'Catolohan'	, label:	'Catolohan'	, link:	'Dalaguete'	},
	{value:	'Cawayan'	, label:	'Cawayan'	, link:	'Dalaguete'	},
	{value:	'Consolacion'	, label:	'Consolacion'	, link:	'Dalaguete'	},
	{value:	'Coro'	, label:	'Coro'	, link:	'Dalaguete'	},
	{value:	'Dugyan'	, label:	'Dugyan'	, link:	'Dalaguete'	},
	{value:	'Dumalan'	, label:	'Dumalan'	, link:	'Dalaguete'	},
	{value:	'Jolomaynon'	, label:	'Jolomaynon'	, link:	'Dalaguete'	},
	{value:	'Lanao'	, label:	'Lanao'	, link:	'Dalaguete'	},
	{value:	'Langkas'	, label:	'Langkas'	, link:	'Dalaguete'	},
	{value:	'Lumbang'	, label:	'Lumbang'	, link:	'Dalaguete'	},
	{value:	'Malones'	, label:	'Malones'	, link:	'Dalaguete'	},
	{value:	'Maloray'	, label:	'Maloray'	, link:	'Dalaguete'	},
	{value:	'Mananggal'	, label:	'Mananggal'	, link:	'Dalaguete'	},
	{value:	'Manlapay'	, label:	'Manlapay'	, link:	'Dalaguete'	},
	{value:	'Mantalongon'	, label:	'Mantalongon'	, link:	'Dalaguete'	},
	{value:	'Nalhub'	, label:	'Nalhub'	, link:	'Dalaguete'	},
	{value:	'Obo'	, label:	'Obo'	, link:	'Dalaguete'	},
	{value:	'Obong'	, label:	'Obong'	, link:	'Dalaguete'	},
	{value:	'Panas'	, label:	'Panas'	, link:	'Dalaguete'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Dalaguete'	},
	{value:	'Sacsac'	, label:	'Sacsac'	, link:	'Dalaguete'	},
	{value:	'Salug'	, label:	'Salug'	, link:	'Dalaguete'	},
	{value:	'Tabon'	, label:	'Tabon'	, link:	'Dalaguete'	},
	{value:	'Tapun'	, label:	'Tapun'	, link:	'Dalaguete'	},
	{value:	'Tuba'	, label:	'Tuba'	, link:	'Dalaguete'	},
	{value:	'Baliang'	, label:	'Baliang'	, link:	'Danao City'	},
	{value:	'Bayabas'	, label:	'Bayabas'	, link:	'Danao City'	},
	{value:	'Binaliw'	, label:	'Binaliw'	, link:	'Danao City'	},
	{value:	'Cabungahan'	, label:	'Cabungahan'	, link:	'Danao City'	},
	{value:	'Cagat-Lamac'	, label:	'Cagat-Lamac'	, link:	'Danao City'	},
	{value:	'Cahumayan'	, label:	'Cahumayan'	, link:	'Danao City'	},
	{value:	'Cambanay'	, label:	'Cambanay'	, link:	'Danao City'	},
	{value:	'Cambubho'	, label:	'Cambubho'	, link:	'Danao City'	},
	{value:	'Cogon-Cruz'	, label:	'Cogon-Cruz'	, link:	'Danao City'	},
	{value:	'Danasan'	, label:	'Danasan'	, link:	'Danao City'	},
	{value:	'Dungga'	, label:	'Dungga'	, link:	'Danao City'	},
	{value:	'Dunggoan'	, label:	'Dunggoan'	, link:	'Danao City'	},
	{value:	'Guinacot'	, label:	'Guinacot'	, link:	'Danao City'	},
	{value:	'Guinsay'	, label:	'Guinsay'	, link:	'Danao City'	},
	{value:	'Ibo'	, label:	'Ibo'	, link:	'Danao City'	},
	{value:	'Langosig'	, label:	'Langosig'	, link:	'Danao City'	},
	{value:	'Lawaan'	, label:	'Lawaan'	, link:	'Danao City'	},
	{value:	'Licos'	, label:	'Licos'	, link:	'Danao City'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Danao City'	},
	{value:	'Magtagobtob'	, label:	'Magtagobtob'	, link:	'Danao City'	},
	{value:	'Malapoc'	, label:	'Malapoc'	, link:	'Danao City'	},
	{value:	'Manlayag'	, label:	'Manlayag'	, link:	'Danao City'	},
	{value:	'Mantija'	, label:	'Mantija'	, link:	'Danao City'	},
	{value:	'Masaba'	, label:	'Masaba'	, link:	'Danao City'	},
	{value:	'Maslog'	, label:	'Maslog'	, link:	'Danao City'	},
	{value:	'Nangka'	, label:	'Nangka'	, link:	'Danao City'	},
	{value:	'Oguis'	, label:	'Oguis'	, link:	'Danao City'	},
	{value:	'Pili'	, label:	'Pili'	, link:	'Danao City'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Danao City'	},
	{value:	'Quisol'	, label:	'Quisol'	, link:	'Danao City'	},
	{value:	'Sabang'	, label:	'Sabang'	, link:	'Danao City'	},
	{value:	'Sacsac'	, label:	'Sacsac'	, link:	'Danao City'	},
	{value:	'Sandayong Norte'	, label:	'Sandayong Norte'	, link:	'Danao City'	},
	{value:	'Sandayong Sur'	, label:	'Sandayong Sur'	, link:	'Danao City'	},
	{value:	'Santa Rosa'	, label:	'Santa Rosa'	, link:	'Danao City'	},
	{value:	'Santican'	, label:	'Santican'	, link:	'Danao City'	},
	{value:	'Sibacan'	, label:	'Sibacan'	, link:	'Danao City'	},
	{value:	'Suba'	, label:	'Suba'	, link:	'Danao City'	},
	{value:	'Taboc'	, label:	'Taboc'	, link:	'Danao City'	},
	{value:	'Taytay'	, label:	'Taytay'	, link:	'Danao City'	},
	{value:	'Togonon'	, label:	'Togonon'	, link:	'Danao City'	},
	{value:	'Tuburan Sur'	, label:	'Tuburan Sur'	, link:	'Danao City'	},
	{value:	'Danao Lake'	, label:	'Danao Lake'	, link:	'Danao Lake'	},
	{value:	'Balaygtiki'	, label:	'Balaygtiki'	, link:	'Dumanjug'	},
	{value:	'Bitoon'	, label:	'Bitoon'	, link:	'Dumanjug'	},
	{value:	'Bulak'	, label:	'Bulak'	, link:	'Dumanjug'	},
	{value:	'Bullogan'	, label:	'Bullogan'	, link:	'Dumanjug'	},
	{value:	'Calaboon'	, label:	'Calaboon'	, link:	'Dumanjug'	},
	{value:	'Camboang'	, label:	'Camboang'	, link:	'Dumanjug'	},
	{value:	'Candabong'	, label:	'Candabong'	, link:	'Dumanjug'	},
	{value:	'Cogon'	, label:	'Cogon'	, link:	'Dumanjug'	},
	{value:	'Cotcoton'	, label:	'Cotcoton'	, link:	'Dumanjug'	},
	{value:	'Doldol'	, label:	'Doldol'	, link:	'Dumanjug'	},
	{value:	'Ilaya'	, label:	'Ilaya'	, link:	'Dumanjug'	},
	{value:	'Kabalaasnan'	, label:	'Kabalaasnan'	, link:	'Dumanjug'	},
	{value:	'Kabatbatan'	, label:	'Kabatbatan'	, link:	'Dumanjug'	},
	{value:	'Kambanog'	, label:	'Kambanog'	, link:	'Dumanjug'	},
	{value:	'Kang-Actol'	, label:	'Kang-Actol'	, link:	'Dumanjug'	},
	{value:	'Kanghalo'	, label:	'Kanghalo'	, link:	'Dumanjug'	},
	{value:	'Kanghumaod'	, label:	'Kanghumaod'	, link:	'Dumanjug'	},
	{value:	'Kanguha'	, label:	'Kanguha'	, link:	'Dumanjug'	},
	{value:	'Kantangkas'	, label:	'Kantangkas'	, link:	'Dumanjug'	},
	{value:	'Kanyuko'	, label:	'Kanyuko'	, link:	'Dumanjug'	},
	{value:	'Kolabtingon'	, label:	'Kolabtingon'	, link:	'Dumanjug'	},
	{value:	'Lamak'	, label:	'Lamak'	, link:	'Dumanjug'	},
	{value:	'Lawaan'	, label:	'Lawaan'	, link:	'Dumanjug'	},
	{value:	'Liong'	, label:	'Liong'	, link:	'Dumanjug'	},
	{value:	'Manlapay'	, label:	'Manlapay'	, link:	'Dumanjug'	},
	{value:	'Masa'	, label:	'Masa'	, link:	'Dumanjug'	},
	{value:	'Matalao'	, label:	'Matalao'	, link:	'Dumanjug'	},
	{value:	'Paculob'	, label:	'Paculob'	, link:	'Dumanjug'	},
	{value:	'Panlaan'	, label:	'Panlaan'	, link:	'Dumanjug'	},
	{value:	'Pawa'	, label:	'Pawa'	, link:	'Dumanjug'	},
	{value:	'Poblacion Central'	, label:	'Poblacion Central'	, link:	'Dumanjug'	},
	{value:	'Poblacion Looc'	, label:	'Poblacion Looc'	, link:	'Dumanjug'	},
	{value:	'Poblacion Sima'	, label:	'Poblacion Sima'	, link:	'Dumanjug'	},
	{value:	'Tangil'	, label:	'Tangil'	, link:	'Dumanjug'	},
	{value:	'Tapon'	, label:	'Tapon'	, link:	'Dumanjug'	},
	{value:	'Tubod-Bitoon'	, label:	'Tubod-Bitoon'	, link:	'Dumanjug'	},
	{value:	'Tubod-Dugoan'	, label:	'Tubod-Dugoan'	, link:	'Dumanjug'	},
	{value:	'Anao'	, label:	'Anao'	, link:	'Ginatilan'	},
	{value:	'Cagsing'	, label:	'Cagsing'	, link:	'Ginatilan'	},
	{value:	'Calabawan'	, label:	'Calabawan'	, link:	'Ginatilan'	},
	{value:	'Cambagte'	, label:	'Cambagte'	, link:	'Ginatilan'	},
	{value:	'Campisong'	, label:	'Campisong'	, link:	'Ginatilan'	},
	{value:	'Canorong'	, label:	'Canorong'	, link:	'Ginatilan'	},
	{value:	'Guiwanon'	, label:	'Guiwanon'	, link:	'Ginatilan'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Ginatilan'	},
	{value:	'Malatbo'	, label:	'Malatbo'	, link:	'Ginatilan'	},
	{value:	'Mangaco'	, label:	'Mangaco'	, link:	'Ginatilan'	},
	{value:	'Palanas'	, label:	'Palanas'	, link:	'Ginatilan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Ginatilan'	},
	{value:	'Salamanca'	, label:	'Salamanca'	, link:	'Ginatilan'	},
	{value:	'San Roque'	, label:	'San Roque'	, link:	'Ginatilan'	},
	{value:	'Agus'	, label:	'Agus'	, link:	'Lapu-Lapu City'	},
	{value:	'Babag'	, label:	'Babag'	, link:	'Lapu-Lapu City'	},
	{value:	'Bankal'	, label:	'Bankal'	, link:	'Lapu-Lapu City'	},
	{value:	'Baring'	, label:	'Baring'	, link:	'Lapu-Lapu City'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Lapu-Lapu City'	},
	{value:	'Buaya'	, label:	'Buaya'	, link:	'Lapu-Lapu City'	},
	{value:	'Calawisan'	, label:	'Calawisan'	, link:	'Lapu-Lapu City'	},
	{value:	'Canjulao'	, label:	'Canjulao'	, link:	'Lapu-Lapu City'	},
	{value:	'Caubian'	, label:	'Caubian'	, link:	'Lapu-Lapu City'	},
	{value:	'Caw-Oy'	, label:	'Caw-Oy'	, link:	'Lapu-Lapu City'	},
	{value:	'Cawhagan'	, label:	'Cawhagan'	, link:	'Lapu-Lapu City'	},
	{value:	'Gun-Ob'	, label:	'Gun-Ob'	, link:	'Lapu-Lapu City'	},
	{value:	'Ibo'	, label:	'Ibo'	, link:	'Lapu-Lapu City'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Lapu-Lapu City'	},
	{value:	'Mactan'	, label:	'Mactan'	, link:	'Lapu-Lapu City'	},
	{value:	'Maribago'	, label:	'Maribago'	, link:	'Lapu-Lapu City'	},
	{value:	'Marigondon'	, label:	'Marigondon'	, link:	'Lapu-Lapu City'	},
	{value:	'Pajac'	, label:	'Pajac'	, link:	'Lapu-Lapu City'	},
	{value:	'Pajo'	, label:	'Pajo'	, link:	'Lapu-Lapu City'	},
	{value:	'Pangan-An'	, label:	'Pangan-An'	, link:	'Lapu-Lapu City'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Lapu-Lapu City'	},
	{value:	'Punta EngaÃ±o'	, label:	'Punta EngaÃ±o'	, link:	'Lapu-Lapu City'	},
	{value:	'Pusok'	, label:	'Pusok'	, link:	'Lapu-Lapu City'	},
	{value:	'Sabang'	, label:	'Sabang'	, link:	'Lapu-Lapu City'	},
	{value:	'San Vicente'	, label:	'San Vicente'	, link:	'Lapu-Lapu City'	},
	{value:	'Santa Rosa'	, label:	'Santa Rosa'	, link:	'Lapu-Lapu City'	},
	{value:	'Subabasbas'	, label:	'Subabasbas'	, link:	'Lapu-Lapu City'	},
	{value:	'Talima'	, label:	'Talima'	, link:	'Lapu-Lapu City'	},
	{value:	'Tingo'	, label:	'Tingo'	, link:	'Lapu-Lapu City'	},
	{value:	'Tungasan'	, label:	'Tungasan'	, link:	'Lapu-Lapu City'	},
	{value:	'Cabadiangan'	, label:	'Cabadiangan'	, link:	'Liloan'	},
	{value:	'Calero'	, label:	'Calero'	, link:	'Liloan'	},
	{value:	'Catarman'	, label:	'Catarman'	, link:	'Liloan'	},
	{value:	'Cotcot'	, label:	'Cotcot'	, link:	'Liloan'	},
	{value:	'Jubay'	, label:	'Jubay'	, link:	'Liloan'	},
	{value:	'Lataban'	, label:	'Lataban'	, link:	'Liloan'	},
	{value:	'Mulao'	, label:	'Mulao'	, link:	'Liloan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Liloan'	},
	{value:	'San Roque'	, label:	'San Roque'	, link:	'Liloan'	},
	{value:	'San Vicente'	, label:	'San Vicente'	, link:	'Liloan'	},
	{value:	'Santa Cruz'	, label:	'Santa Cruz'	, link:	'Liloan'	},
	{value:	'Tabla'	, label:	'Tabla'	, link:	'Liloan'	},
	{value:	'Tayud'	, label:	'Tayud'	, link:	'Liloan'	},
	{value:	'Yati'	, label:	'Yati'	, link:	'Liloan'	},
	{value:	'Bunakan'	, label:	'Bunakan'	, link:	'Madridejos'	},
	{value:	'Kangwayan'	, label:	'Kangwayan'	, link:	'Madridejos'	},
	{value:	'Kaongkod'	, label:	'Kaongkod'	, link:	'Madridejos'	},
	{value:	'Kodia'	, label:	'Kodia'	, link:	'Madridejos'	},
	{value:	'Maalat'	, label:	'Maalat'	, link:	'Madridejos'	},
	{value:	'Malbago'	, label:	'Malbago'	, link:	'Madridejos'	},
	{value:	'Mancilang'	, label:	'Mancilang'	, link:	'Madridejos'	},
	{value:	'Pili'	, label:	'Pili'	, link:	'Madridejos'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Madridejos'	},
	{value:	'San Agustin'	, label:	'San Agustin'	, link:	'Madridejos'	},
	{value:	'Tabagak'	, label:	'Tabagak'	, link:	'Madridejos'	},
	{value:	'Talangnan'	, label:	'Talangnan'	, link:	'Madridejos'	},
	{value:	'Tarong'	, label:	'Tarong'	, link:	'Madridejos'	},
	{value:	'Tugas'	, label:	'Tugas'	, link:	'Madridejos'	},
	{value:	'ArmeÃ±a'	, label:	'ArmeÃ±a'	, link:	'Malabuyoc'	},
	{value:	'Barangay I'	, label:	'Barangay I'	, link:	'Malabuyoc'	},
	{value:	'Barangay II'	, label:	'Barangay II'	, link:	'Malabuyoc'	},
	{value:	'CerdeÃ±a'	, label:	'CerdeÃ±a'	, link:	'Malabuyoc'	},
	{value:	'Labrador'	, label:	'Labrador'	, link:	'Malabuyoc'	},
	{value:	'Lombo'	, label:	'Lombo'	, link:	'Malabuyoc'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Malabuyoc'	},
	{value:	'Mahanlud'	, label:	'Mahanlud'	, link:	'Malabuyoc'	},
	{value:	'Mindanao'	, label:	'Mindanao'	, link:	'Malabuyoc'	},
	{value:	'MontaÃ±eza'	, label:	'MontaÃ±eza'	, link:	'Malabuyoc'	},
	{value:	'Salmeron'	, label:	'Salmeron'	, link:	'Malabuyoc'	},
	{value:	'Santo NiÃ±o'	, label:	'Santo NiÃ±o'	, link:	'Malabuyoc'	},
	{value:	'Sorsogon'	, label:	'Sorsogon'	, link:	'Malabuyoc'	},
	{value:	'Tolosa'	, label:	'Tolosa'	, link:	'Malabuyoc'	},
	{value:	'Alang-Alang'	, label:	'Alang-Alang'	, link:	'Mandaue City'	},
	{value:	'Bakilid'	, label:	'Bakilid'	, link:	'Mandaue City'	},
	{value:	'Banilad'	, label:	'Banilad'	, link:	'Mandaue City'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Mandaue City'	},
	{value:	'Cabancalan'	, label:	'Cabancalan'	, link:	'Mandaue City'	},
	{value:	'Cambaro'	, label:	'Cambaro'	, link:	'Mandaue City'	},
	{value:	'Canduman'	, label:	'Canduman'	, link:	'Mandaue City'	},
	{value:	'Casili'	, label:	'Casili'	, link:	'Mandaue City'	},
	{value:	'Casuntingan'	, label:	'Casuntingan'	, link:	'Mandaue City'	},
	{value:	'Centro'	, label:	'Centro'	, link:	'Mandaue City'	},
	{value:	'Cubacub'	, label:	'Cubacub'	, link:	'Mandaue City'	},
	{value:	'Guizo'	, label:	'Guizo'	, link:	'Mandaue City'	},
	{value:	'Ibabao-Estancia'	, label:	'Ibabao-Estancia'	, link:	'Mandaue City'	},
	{value:	'Jagobiao'	, label:	'Jagobiao'	, link:	'Mandaue City'	},
	{value:	'Labogon'	, label:	'Labogon'	, link:	'Mandaue City'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Mandaue City'	},
	{value:	'Maguikay'	, label:	'Maguikay'	, link:	'Mandaue City'	},
	{value:	'Mantuyong'	, label:	'Mantuyong'	, link:	'Mandaue City'	},
	{value:	'Opao'	, label:	'Opao'	, link:	'Mandaue City'	},
	{value:	'Pagsabungan'	, label:	'Pagsabungan'	, link:	'Mandaue City'	},
	{value:	'Pakna-An'	, label:	'Pakna-An'	, link:	'Mandaue City'	},
	{value:	'Subangdaku'	, label:	'Subangdaku'	, link:	'Mandaue City'	},
	{value:	'Tabok'	, label:	'Tabok'	, link:	'Mandaue City'	},
	{value:	'Tawason'	, label:	'Tawason'	, link:	'Mandaue City'	},
	{value:	'Tingub'	, label:	'Tingub'	, link:	'Mandaue City'	},
	{value:	'Tipolo'	, label:	'Tipolo'	, link:	'Mandaue City'	},
	{value:	'Umapad'	, label:	'Umapad'	, link:	'Mandaue City'	},
	{value:	'Antipolo'	, label:	'Antipolo'	, link:	'Medellin'	},
	{value:	'Canhabagat'	, label:	'Canhabagat'	, link:	'Medellin'	},
	{value:	'Caputatan Norte'	, label:	'Caputatan Norte'	, link:	'Medellin'	},
	{value:	'Caputatan Sur'	, label:	'Caputatan Sur'	, link:	'Medellin'	},
	{value:	'Curva'	, label:	'Curva'	, link:	'Medellin'	},
	{value:	'Daanlungsod'	, label:	'Daanlungsod'	, link:	'Medellin'	},
	{value:	'Dalingding Sur'	, label:	'Dalingding Sur'	, link:	'Medellin'	},
	{value:	'Dayhagon'	, label:	'Dayhagon'	, link:	'Medellin'	},
	{value:	'Don Virgilio Gonzales'	, label:	'Don Virgilio Gonzales'	, link:	'Medellin'	},
	{value:	'Gibitngil'	, label:	'Gibitngil'	, link:	'Medellin'	},
	{value:	'Kawit'	, label:	'Kawit'	, link:	'Medellin'	},
	{value:	'Lamintak Norte'	, label:	'Lamintak Norte'	, link:	'Medellin'	},
	{value:	'Lamintak Sur'	, label:	'Lamintak Sur'	, link:	'Medellin'	},
	{value:	'Luy-A'	, label:	'Luy-A'	, link:	'Medellin'	},
	{value:	'Maharuhay'	, label:	'Maharuhay'	, link:	'Medellin'	},
	{value:	'Mahawak'	, label:	'Mahawak'	, link:	'Medellin'	},
	{value:	'Panugnawan'	, label:	'Panugnawan'	, link:	'Medellin'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Medellin'	},
	{value:	'Tindog'	, label:	'Tindog'	, link:	'Medellin'	},
	{value:	'Cadulawan'	, label:	'Cadulawan'	, link:	'Minglanilla'	},
	{value:	'Calajo-An'	, label:	'Calajo-An'	, link:	'Minglanilla'	},
	{value:	'Camp 7'	, label:	'Camp 7'	, link:	'Minglanilla'	},
	{value:	'Camp 8'	, label:	'Camp 8'	, link:	'Minglanilla'	},
	{value:	'Cuanos'	, label:	'Cuanos'	, link:	'Minglanilla'	},
	{value:	'Guindaruhan'	, label:	'Guindaruhan'	, link:	'Minglanilla'	},
	{value:	'Linao'	, label:	'Linao'	, link:	'Minglanilla'	},
	{value:	'Manduang'	, label:	'Manduang'	, link:	'Minglanilla'	},
	{value:	'Pakigne'	, label:	'Pakigne'	, link:	'Minglanilla'	},
	{value:	'Poblacion Ward I'	, label:	'Poblacion Ward I'	, link:	'Minglanilla'	},
	{value:	'Poblacion Ward II'	, label:	'Poblacion Ward II'	, link:	'Minglanilla'	},
	{value:	'Poblacion Ward III'	, label:	'Poblacion Ward III'	, link:	'Minglanilla'	},
	{value:	'Poblacion Ward IV'	, label:	'Poblacion Ward IV'	, link:	'Minglanilla'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'Minglanilla'	},
	{value:	'Tulay'	, label:	'Tulay'	, link:	'Minglanilla'	},
	{value:	'Tunghaan'	, label:	'Tunghaan'	, link:	'Minglanilla'	},
	{value:	'Tungkil'	, label:	'Tungkil'	, link:	'Minglanilla'	},
	{value:	'Tungkop'	, label:	'Tungkop'	, link:	'Minglanilla'	},
	{value:	'Vito'	, label:	'Vito'	, link:	'Minglanilla'	},
	{value:	'Agbalanga'	, label:	'Agbalanga'	, link:	'Moalboal'	},
	{value:	'Bala'	, label:	'Bala'	, link:	'Moalboal'	},
	{value:	'Balabagon'	, label:	'Balabagon'	, link:	'Moalboal'	},
	{value:	'Basdiot'	, label:	'Basdiot'	, link:	'Moalboal'	},
	{value:	'Batadbatad'	, label:	'Batadbatad'	, link:	'Moalboal'	},
	{value:	'Bugho'	, label:	'Bugho'	, link:	'Moalboal'	},
	{value:	'Buguil'	, label:	'Buguil'	, link:	'Moalboal'	},
	{value:	'Busay'	, label:	'Busay'	, link:	'Moalboal'	},
	{value:	'Lanao'	, label:	'Lanao'	, link:	'Moalboal'	},
	{value:	'Poblacion East'	, label:	'Poblacion East'	, link:	'Moalboal'	},
	{value:	'Poblacion West'	, label:	'Poblacion West'	, link:	'Moalboal'	},
	{value:	'Saavedra'	, label:	'Saavedra'	, link:	'Moalboal'	},
	{value:	'Tomonoy'	, label:	'Tomonoy'	, link:	'Moalboal'	},
	{value:	'Tuble'	, label:	'Tuble'	, link:	'Moalboal'	},
	{value:	'Tunga'	, label:	'Tunga'	, link:	'Moalboal'	},
	{value:	'Alfaco'	, label:	'Alfaco'	, link:	'Naga City'	},
	{value:	'Bairan'	, label:	'Bairan'	, link:	'Naga City'	},
	{value:	'Balirong'	, label:	'Balirong'	, link:	'Naga City'	},
	{value:	'Cabungahan'	, label:	'Cabungahan'	, link:	'Naga City'	},
	{value:	'Cantao-An'	, label:	'Cantao-An'	, link:	'Naga City'	},
	{value:	'Central Poblacion'	, label:	'Central Poblacion'	, link:	'Naga City'	},
	{value:	'Cogon'	, label:	'Cogon'	, link:	'Naga City'	},
	{value:	'Colon'	, label:	'Colon'	, link:	'Naga City'	},
	{value:	'East Poblacion'	, label:	'East Poblacion'	, link:	'Naga City'	},
	{value:	'Inayagan'	, label:	'Inayagan'	, link:	'Naga City'	},
	{value:	'Inoburan'	, label:	'Inoburan'	, link:	'Naga City'	},
	{value:	'Jaguimit'	, label:	'Jaguimit'	, link:	'Naga City'	},
	{value:	'Lanas'	, label:	'Lanas'	, link:	'Naga City'	},
	{value:	'Langtad'	, label:	'Langtad'	, link:	'Naga City'	},
	{value:	'Lutac'	, label:	'Lutac'	, link:	'Naga City'	},
	{value:	'Mainit'	, label:	'Mainit'	, link:	'Naga City'	},
	{value:	'Mayana'	, label:	'Mayana'	, link:	'Naga City'	},
	{value:	'Naalad'	, label:	'Naalad'	, link:	'Naga City'	},
	{value:	'North Poblacion'	, label:	'North Poblacion'	, link:	'Naga City'	},
	{value:	'Pangdan'	, label:	'Pangdan'	, link:	'Naga City'	},
	{value:	'Patag'	, label:	'Patag'	, link:	'Naga City'	},
	{value:	'South Poblacion'	, label:	'South Poblacion'	, link:	'Naga City'	},
	{value:	'Tagjaguimit'	, label:	'Tagjaguimit'	, link:	'Naga City'	},
	{value:	'Tangke'	, label:	'Tangke'	, link:	'Naga City'	},
	{value:	'Tinaan'	, label:	'Tinaan'	, link:	'Naga City'	},
	{value:	'Tuyan'	, label:	'Tuyan'	, link:	'Naga City'	},
	{value:	'Uling'	, label:	'Uling'	, link:	'Naga City'	},
	{value:	'West Poblacion'	, label:	'West Poblacion'	, link:	'Naga City'	},
	{value:	'Alo'	, label:	'Alo'	, link:	'Oslob'	},
	{value:	'Bangcogon'	, label:	'Bangcogon'	, link:	'Oslob'	},
	{value:	'Bonbon'	, label:	'Bonbon'	, link:	'Oslob'	},
	{value:	'Calumpang'	, label:	'Calumpang'	, link:	'Oslob'	},
	{value:	'Can-Ukban'	, label:	'Can-Ukban'	, link:	'Oslob'	},
	{value:	'CaÃ±ang'	, label:	'CaÃ±ang'	, link:	'Oslob'	},
	{value:	'Canangca-An'	, label:	'Canangca-An'	, link:	'Oslob'	},
	{value:	'Cansalo-Ay'	, label:	'Cansalo-Ay'	, link:	'Oslob'	},
	{value:	'Daanlungsod'	, label:	'Daanlungsod'	, link:	'Oslob'	},
	{value:	'Gawi'	, label:	'Gawi'	, link:	'Oslob'	},
	{value:	'Hagdan'	, label:	'Hagdan'	, link:	'Oslob'	},
	{value:	'Lagunde'	, label:	'Lagunde'	, link:	'Oslob'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Oslob'	},
	{value:	'Luka'	, label:	'Luka'	, link:	'Oslob'	},
	{value:	'Mainit'	, label:	'Mainit'	, link:	'Oslob'	},
	{value:	'Manlum'	, label:	'Manlum'	, link:	'Oslob'	},
	{value:	'Nueva Caceres'	, label:	'Nueva Caceres'	, link:	'Oslob'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Oslob'	},
	{value:	'Pungtod'	, label:	'Pungtod'	, link:	'Oslob'	},
	{value:	'Tan-Awan'	, label:	'Tan-Awan'	, link:	'Oslob'	},
	{value:	'Tumalog'	, label:	'Tumalog'	, link:	'Oslob'	},
	{value:	'Biasong'	, label:	'Biasong'	, link:	'Pilar'	},
	{value:	'Cawit'	, label:	'Cawit'	, link:	'Pilar'	},
	{value:	'Dapdap'	, label:	'Dapdap'	, link:	'Pilar'	},
	{value:	'Esperanza'	, label:	'Esperanza'	, link:	'Pilar'	},
	{value:	'Imelda'	, label:	'Imelda'	, link:	'Pilar'	},
	{value:	'Lanao'	, label:	'Lanao'	, link:	'Pilar'	},
	{value:	'Lower Poblacion'	, label:	'Lower Poblacion'	, link:	'Pilar'	},
	{value:	'Moabog'	, label:	'Moabog'	, link:	'Pilar'	},
	{value:	'Montserrat'	, label:	'Montserrat'	, link:	'Pilar'	},
	{value:	'San Isidro'	, label:	'San Isidro'	, link:	'Pilar'	},
	{value:	'San Juan'	, label:	'San Juan'	, link:	'Pilar'	},
	{value:	'Upper Poblacion'	, label:	'Upper Poblacion'	, link:	'Pilar'	},
	{value:	'Villahermosa'	, label:	'Villahermosa'	, link:	'Pilar'	},
	{value:	'Anislag'	, label:	'Anislag'	, link:	'Pinamungahan'	},
	{value:	'Anopog'	, label:	'Anopog'	, link:	'Pinamungahan'	},
	{value:	'Binabag'	, label:	'Binabag'	, link:	'Pinamungahan'	},
	{value:	'Buhingtubig'	, label:	'Buhingtubig'	, link:	'Pinamungahan'	},
	{value:	'Busay'	, label:	'Busay'	, link:	'Pinamungahan'	},
	{value:	'Butong'	, label:	'Butong'	, link:	'Pinamungahan'	},
	{value:	'Cabiangon'	, label:	'Cabiangon'	, link:	'Pinamungahan'	},
	{value:	'Camugao'	, label:	'Camugao'	, link:	'Pinamungahan'	},
	{value:	'Duangan'	, label:	'Duangan'	, link:	'Pinamungahan'	},
	{value:	'Guimbawian'	, label:	'Guimbawian'	, link:	'Pinamungahan'	},
	{value:	'Lamac'	, label:	'Lamac'	, link:	'Pinamungahan'	},
	{value:	'Lut-Od'	, label:	'Lut-Od'	, link:	'Pinamungahan'	},
	{value:	'Mangoto'	, label:	'Mangoto'	, link:	'Pinamungahan'	},
	{value:	'Opao'	, label:	'Opao'	, link:	'Pinamungahan'	},
	{value:	'Pandacan'	, label:	'Pandacan'	, link:	'Pinamungahan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Pinamungahan'	},
	{value:	'Punod'	, label:	'Punod'	, link:	'Pinamungahan'	},
	{value:	'Rizal'	, label:	'Rizal'	, link:	'Pinamungahan'	},
	{value:	'Sacsac'	, label:	'Sacsac'	, link:	'Pinamungahan'	},
	{value:	'Sambagon'	, label:	'Sambagon'	, link:	'Pinamungahan'	},
	{value:	'Sibago'	, label:	'Sibago'	, link:	'Pinamungahan'	},
	{value:	'Tajao'	, label:	'Tajao'	, link:	'Pinamungahan'	},
	{value:	'Tangub'	, label:	'Tangub'	, link:	'Pinamungahan'	},
	{value:	'Tanibag'	, label:	'Tanibag'	, link:	'Pinamungahan'	},
	{value:	'Tupas'	, label:	'Tupas'	, link:	'Pinamungahan'	},
	{value:	'Tutay'	, label:	'Tutay'	, link:	'Pinamungahan'	},
	{value:	'Adela'	, label:	'Adela'	, link:	'Poro'	},
	{value:	'Altavista'	, label:	'Altavista'	, link:	'Poro'	},
	{value:	'Cagcagan'	, label:	'Cagcagan'	, link:	'Poro'	},
	{value:	'Cansabusab'	, label:	'Cansabusab'	, link:	'Poro'	},
	{value:	'Daan Paz'	, label:	'Daan Paz'	, link:	'Poro'	},
	{value:	'Eastern Poblacion'	, label:	'Eastern Poblacion'	, link:	'Poro'	},
	{value:	'Esperanza'	, label:	'Esperanza'	, link:	'Poro'	},
	{value:	'Libertad'	, label:	'Libertad'	, link:	'Poro'	},
	{value:	'Mabini'	, label:	'Mabini'	, link:	'Poro'	},
	{value:	'Mercedes'	, label:	'Mercedes'	, link:	'Poro'	},
	{value:	'Pagsa'	, label:	'Pagsa'	, link:	'Poro'	},
	{value:	'Paz'	, label:	'Paz'	, link:	'Poro'	},
	{value:	'Rizal'	, label:	'Rizal'	, link:	'Poro'	},
	{value:	'San Jose'	, label:	'San Jose'	, link:	'Poro'	},
	{value:	'Santa Rita'	, label:	'Santa Rita'	, link:	'Poro'	},
	{value:	'Teguis'	, label:	'Teguis'	, link:	'Poro'	},
	{value:	'Western Poblacion'	, label:	'Western Poblacion'	, link:	'Poro'	},
	{value:	'Butong'	, label:	'Butong'	, link:	'Ronda'	},
	{value:	'Can-Abuhon'	, label:	'Can-Abuhon'	, link:	'Ronda'	},
	{value:	'Canduling'	, label:	'Canduling'	, link:	'Ronda'	},
	{value:	'Cansalonoy'	, label:	'Cansalonoy'	, link:	'Ronda'	},
	{value:	'Cansayahon'	, label:	'Cansayahon'	, link:	'Ronda'	},
	{value:	'Ilaya'	, label:	'Ilaya'	, link:	'Ronda'	},
	{value:	'Langin'	, label:	'Langin'	, link:	'Ronda'	},
	{value:	'Libo-O'	, label:	'Libo-O'	, link:	'Ronda'	},
	{value:	'Malalay'	, label:	'Malalay'	, link:	'Ronda'	},
	{value:	'Palanas'	, label:	'Palanas'	, link:	'Ronda'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Ronda'	},
	{value:	'Santa Cruz'	, label:	'Santa Cruz'	, link:	'Ronda'	},
	{value:	'Tupas'	, label:	'Tupas'	, link:	'Ronda'	},
	{value:	'Vive'	, label:	'Vive'	, link:	'Ronda'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Samboan'	},
	{value:	'Bonbon'	, label:	'Bonbon'	, link:	'Samboan'	},
	{value:	'Bulangsuran'	, label:	'Bulangsuran'	, link:	'Samboan'	},
	{value:	'Calatagan'	, label:	'Calatagan'	, link:	'Samboan'	},
	{value:	'Cambigong'	, label:	'Cambigong'	, link:	'Samboan'	},
	{value:	'Camburoy'	, label:	'Camburoy'	, link:	'Samboan'	},
	{value:	'Canorong'	, label:	'Canorong'	, link:	'Samboan'	},
	{value:	'Colase'	, label:	'Colase'	, link:	'Samboan'	},
	{value:	'Dalahikan'	, label:	'Dalahikan'	, link:	'Samboan'	},
	{value:	'Jumangpas'	, label:	'Jumangpas'	, link:	'Samboan'	},
	{value:	'Monteverde'	, label:	'Monteverde'	, link:	'Samboan'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Samboan'	},
	{value:	'San Sebastian'	, label:	'San Sebastian'	, link:	'Samboan'	},
	{value:	'Suba'	, label:	'Suba'	, link:	'Samboan'	},
	{value:	'Tangbo'	, label:	'Tangbo'	, link:	'Samboan'	},
	{value:	'Balud'	, label:	'Balud'	, link:	'San Fernando'	},
	{value:	'Balungag'	, label:	'Balungag'	, link:	'San Fernando'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'San Fernando'	},
	{value:	'Bugho'	, label:	'Bugho'	, link:	'San Fernando'	},
	{value:	'Cabatbatan'	, label:	'Cabatbatan'	, link:	'San Fernando'	},
	{value:	'Greenhills'	, label:	'Greenhills'	, link:	'San Fernando'	},
	{value:	'Ilaya'	, label:	'Ilaya'	, link:	'San Fernando'	},
	{value:	'Lantawan'	, label:	'Lantawan'	, link:	'San Fernando'	},
	{value:	'Liburon'	, label:	'Liburon'	, link:	'San Fernando'	},
	{value:	'Magsico'	, label:	'Magsico'	, link:	'San Fernando'	},
	{value:	'Panadtaran'	, label:	'Panadtaran'	, link:	'San Fernando'	},
	{value:	'Pitalo'	, label:	'Pitalo'	, link:	'San Fernando'	},
	{value:	'Poblacion North'	, label:	'Poblacion North'	, link:	'San Fernando'	},
	{value:	'Poblacion South'	, label:	'Poblacion South'	, link:	'San Fernando'	},
	{value:	'San Isidro'	, label:	'San Isidro'	, link:	'San Fernando'	},
	{value:	'Sangat'	, label:	'Sangat'	, link:	'San Fernando'	},
	{value:	'Tabionan'	, label:	'Tabionan'	, link:	'San Fernando'	},
	{value:	'Tananas'	, label:	'Tananas'	, link:	'San Fernando'	},
	{value:	'Tinubdan'	, label:	'Tinubdan'	, link:	'San Fernando'	},
	{value:	'Tonggo'	, label:	'Tonggo'	, link:	'San Fernando'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'San Fernando'	},
	{value:	'Cabunga-An'	, label:	'Cabunga-An'	, link:	'San Francisco'	},
	{value:	'Campo'	, label:	'Campo'	, link:	'San Francisco'	},
	{value:	'Consuelo'	, label:	'Consuelo'	, link:	'San Francisco'	},
	{value:	'Esperanza'	, label:	'Esperanza'	, link:	'San Francisco'	},
	{value:	'Himensulan'	, label:	'Himensulan'	, link:	'San Francisco'	},
	{value:	'Montealegre'	, label:	'Montealegre'	, link:	'San Francisco'	},
	{value:	'Northern Poblacion'	, label:	'Northern Poblacion'	, link:	'San Francisco'	},
	{value:	'San Isidro'	, label:	'San Isidro'	, link:	'San Francisco'	},
	{value:	'Santa Cruz'	, label:	'Santa Cruz'	, link:	'San Francisco'	},
	{value:	'Santiago'	, label:	'Santiago'	, link:	'San Francisco'	},
	{value:	'Sonog'	, label:	'Sonog'	, link:	'San Francisco'	},
	{value:	'Southern Poblacion'	, label:	'Southern Poblacion'	, link:	'San Francisco'	},
	{value:	'Unidos'	, label:	'Unidos'	, link:	'San Francisco'	},
	{value:	'Union'	, label:	'Union'	, link:	'San Francisco'	},
	{value:	'Western Poblacion'	, label:	'Western Poblacion'	, link:	'San Francisco'	},
	{value:	'Anapog'	, label:	'Anapog'	, link:	'San Remigio'	},
	{value:	'Argawanon'	, label:	'Argawanon'	, link:	'San Remigio'	},
	{value:	'Bagtic'	, label:	'Bagtic'	, link:	'San Remigio'	},
	{value:	'Bancasan'	, label:	'Bancasan'	, link:	'San Remigio'	},
	{value:	'Batad'	, label:	'Batad'	, link:	'San Remigio'	},
	{value:	'Busogon'	, label:	'Busogon'	, link:	'San Remigio'	},
	{value:	'Calambua'	, label:	'Calambua'	, link:	'San Remigio'	},
	{value:	'Canagahan'	, label:	'Canagahan'	, link:	'San Remigio'	},
	{value:	'Dapdap'	, label:	'Dapdap'	, link:	'San Remigio'	},
	{value:	'Gawaygaway'	, label:	'Gawaygaway'	, link:	'San Remigio'	},
	{value:	'Hagnaya'	, label:	'Hagnaya'	, link:	'San Remigio'	},
	{value:	'Kayam'	, label:	'Kayam'	, link:	'San Remigio'	},
	{value:	'Kinawahan'	, label:	'Kinawahan'	, link:	'San Remigio'	},
	{value:	'Lambusan'	, label:	'Lambusan'	, link:	'San Remigio'	},
	{value:	'Lawis'	, label:	'Lawis'	, link:	'San Remigio'	},
	{value:	'Libaong'	, label:	'Libaong'	, link:	'San Remigio'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'San Remigio'	},
	{value:	'Luyang'	, label:	'Luyang'	, link:	'San Remigio'	},
	{value:	'Mano'	, label:	'Mano'	, link:	'San Remigio'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'San Remigio'	},
	{value:	'Punta'	, label:	'Punta'	, link:	'San Remigio'	},
	{value:	'Sab-A'	, label:	'Sab-A'	, link:	'San Remigio'	},
	{value:	'San Miguel'	, label:	'San Miguel'	, link:	'San Remigio'	},
	{value:	'Tacup'	, label:	'Tacup'	, link:	'San Remigio'	},
	{value:	'Tambongon'	, label:	'Tambongon'	, link:	'San Remigio'	},
	{value:	'To-Ong'	, label:	'To-Ong'	, link:	'San Remigio'	},
	{value:	'Victoria'	, label:	'Victoria'	, link:	'San Remigio'	},
	{value:	'Balidbid'	, label:	'Balidbid'	, link:	'Santa Fe'	},
	{value:	'Hagdan'	, label:	'Hagdan'	, link:	'Santa Fe'	},
	{value:	'Hilantagaan'	, label:	'Hilantagaan'	, link:	'Santa Fe'	},
	{value:	'Kinatarkan'	, label:	'Kinatarkan'	, link:	'Santa Fe'	},
	{value:	'Langub'	, label:	'Langub'	, link:	'Santa Fe'	},
	{value:	'Maricaban'	, label:	'Maricaban'	, link:	'Santa Fe'	},
	{value:	'Okoy'	, label:	'Okoy'	, link:	'Santa Fe'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Santa Fe'	},
	{value:	'Pooc'	, label:	'Pooc'	, link:	'Santa Fe'	},
	{value:	'Talisay'	, label:	'Talisay'	, link:	'Santa Fe'	},
	{value:	'Bunlan'	, label:	'Bunlan'	, link:	'Santander'	},
	{value:	'Cabutongan'	, label:	'Cabutongan'	, link:	'Santander'	},
	{value:	'Candamiang'	, label:	'Candamiang'	, link:	'Santander'	},
	{value:	'Canlumacad'	, label:	'Canlumacad'	, link:	'Santander'	},
	{value:	'Liloan'	, label:	'Liloan'	, link:	'Santander'	},
	{value:	'Lip-Tong'	, label:	'Lip-Tong'	, link:	'Santander'	},
	{value:	'Looc'	, label:	'Looc'	, link:	'Santander'	},
	{value:	'Pasil'	, label:	'Pasil'	, link:	'Santander'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Santander'	},
	{value:	'Talisay'	, label:	'Talisay'	, link:	'Santander'	},
	{value:	'Abugon'	, label:	'Abugon'	, link:	'Sibonga'	},
	{value:	'Bae'	, label:	'Bae'	, link:	'Sibonga'	},
	{value:	'Bagacay'	, label:	'Bagacay'	, link:	'Sibonga'	},
	{value:	'Bahay'	, label:	'Bahay'	, link:	'Sibonga'	},
	{value:	'Banlot'	, label:	'Banlot'	, link:	'Sibonga'	},
	{value:	'Basak'	, label:	'Basak'	, link:	'Sibonga'	},
	{value:	'Bato'	, label:	'Bato'	, link:	'Sibonga'	},
	{value:	'Cagay'	, label:	'Cagay'	, link:	'Sibonga'	},
	{value:	'Can-Aga'	, label:	'Can-Aga'	, link:	'Sibonga'	},
	{value:	'Candaguit'	, label:	'Candaguit'	, link:	'Sibonga'	},
	{value:	'Cantolaroy'	, label:	'Cantolaroy'	, link:	'Sibonga'	},
	{value:	'Dugoan'	, label:	'Dugoan'	, link:	'Sibonga'	},
	{value:	'Guimbangco-An'	, label:	'Guimbangco-An'	, link:	'Sibonga'	},
	{value:	'Lamacan'	, label:	'Lamacan'	, link:	'Sibonga'	},
	{value:	'Libo'	, label:	'Libo'	, link:	'Sibonga'	},
	{value:	'Lindogon'	, label:	'Lindogon'	, link:	'Sibonga'	},
	{value:	'Magcagong'	, label:	'Magcagong'	, link:	'Sibonga'	},
	{value:	'Manatad'	, label:	'Manatad'	, link:	'Sibonga'	},
	{value:	'Mangyan'	, label:	'Mangyan'	, link:	'Sibonga'	},
	{value:	'Papan'	, label:	'Papan'	, link:	'Sibonga'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Sibonga'	},
	{value:	'Sabang'	, label:	'Sabang'	, link:	'Sibonga'	},
	{value:	'Sayao'	, label:	'Sayao'	, link:	'Sibonga'	},
	{value:	'Simala'	, label:	'Simala'	, link:	'Sibonga'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'Sibonga'	},
	{value:	'Ampongol'	, label:	'Ampongol'	, link:	'Sogod'	},
	{value:	'Bagakay'	, label:	'Bagakay'	, link:	'Sogod'	},
	{value:	'Bagatayam'	, label:	'Bagatayam'	, link:	'Sogod'	},
	{value:	'Bawo'	, label:	'Bawo'	, link:	'Sogod'	},
	{value:	'Cabalawan'	, label:	'Cabalawan'	, link:	'Sogod'	},
	{value:	'Cabangahan'	, label:	'Cabangahan'	, link:	'Sogod'	},
	{value:	'Calumboyan'	, label:	'Calumboyan'	, link:	'Sogod'	},
	{value:	'Dakit'	, label:	'Dakit'	, link:	'Sogod'	},
	{value:	'Damolog'	, label:	'Damolog'	, link:	'Sogod'	},
	{value:	'Ibabao'	, label:	'Ibabao'	, link:	'Sogod'	},
	{value:	'Liki'	, label:	'Liki'	, link:	'Sogod'	},
	{value:	'Lubo'	, label:	'Lubo'	, link:	'Sogod'	},
	{value:	'Mohon'	, label:	'Mohon'	, link:	'Sogod'	},
	{value:	'Nahus-An'	, label:	'Nahus-An'	, link:	'Sogod'	},
	{value:	'Pansoy'	, label:	'Pansoy'	, link:	'Sogod'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Sogod'	},
	{value:	'Tabunok'	, label:	'Tabunok'	, link:	'Sogod'	},
	{value:	'Takay'	, label:	'Takay'	, link:	'Sogod'	},
	{value:	'Alang-Alang'	, label:	'Alang-Alang'	, link:	'Tabogon'	},
	{value:	'Caduawan'	, label:	'Caduawan'	, link:	'Tabogon'	},
	{value:	'Camoboan'	, label:	'Camoboan'	, link:	'Tabogon'	},
	{value:	'Canaocanao'	, label:	'Canaocanao'	, link:	'Tabogon'	},
	{value:	'Combado'	, label:	'Combado'	, link:	'Tabogon'	},
	{value:	'Daantabogon'	, label:	'Daantabogon'	, link:	'Tabogon'	},
	{value:	'Ilihan'	, label:	'Ilihan'	, link:	'Tabogon'	},
	{value:	'Kal-Anan'	, label:	'Kal-Anan'	, link:	'Tabogon'	},
	{value:	'Labangon'	, label:	'Labangon'	, link:	'Tabogon'	},
	{value:	'Libjo'	, label:	'Libjo'	, link:	'Tabogon'	},
	{value:	'Loong'	, label:	'Loong'	, link:	'Tabogon'	},
	{value:	'Mabuli'	, label:	'Mabuli'	, link:	'Tabogon'	},
	{value:	'Managase'	, label:	'Managase'	, link:	'Tabogon'	},
	{value:	'Manlagtang'	, label:	'Manlagtang'	, link:	'Tabogon'	},
	{value:	'Maslog'	, label:	'Maslog'	, link:	'Tabogon'	},
	{value:	'Muabog'	, label:	'Muabog'	, link:	'Tabogon'	},
	{value:	'Pio'	, label:	'Pio'	, link:	'Tabogon'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Tabogon'	},
	{value:	'Salag'	, label:	'Salag'	, link:	'Tabogon'	},
	{value:	'Sambag'	, label:	'Sambag'	, link:	'Tabogon'	},
	{value:	'San Isidro'	, label:	'San Isidro'	, link:	'Tabogon'	},
	{value:	'San Vicente'	, label:	'San Vicente'	, link:	'Tabogon'	},
	{value:	'Somosa'	, label:	'Somosa'	, link:	'Tabogon'	},
	{value:	'Taba-Ao'	, label:	'Taba-Ao'	, link:	'Tabogon'	},
	{value:	'Tapul'	, label:	'Tapul'	, link:	'Tabogon'	},
	{value:	'Lawaan III'	, label:	'Lawaan III'	, link:	'Talisay City'	},
	{value:	'Linao'	, label:	'Linao'	, link:	'Talisay City'	},
	{value:	'Maghaway'	, label:	'Maghaway'	, link:	'Talisay City'	},
	{value:	'Manipis'	, label:	'Manipis'	, link:	'Talisay City'	},
	{value:	'Mohon'	, label:	'Mohon'	, link:	'Talisay City'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Talisay City'	},
	{value:	'Pooc'	, label:	'Pooc'	, link:	'Talisay City'	},
	{value:	'San Isidro'	, label:	'San Isidro'	, link:	'Talisay City'	},
	{value:	'San Roque'	, label:	'San Roque'	, link:	'Talisay City'	},
	{value:	'Tabunoc'	, label:	'Tabunoc'	, link:	'Talisay City'	},
	{value:	'Tangke'	, label:	'Tangke'	, link:	'Talisay City'	},
	{value:	'Tapul'	, label:	'Tapul'	, link:	'Talisay City'	},
	{value:	'Awihao'	, label:	'Awihao'	, link:	'Toledo City'	},
	{value:	'Bagakay'	, label:	'Bagakay'	, link:	'Toledo City'	},
	{value:	'Bato'	, label:	'Bato'	, link:	'Toledo City'	},
	{value:	'Biga'	, label:	'Biga'	, link:	'Toledo City'	},
	{value:	'Bulongan'	, label:	'Bulongan'	, link:	'Toledo City'	},
	{value:	'Bunga'	, label:	'Bunga'	, link:	'Toledo City'	},
	{value:	'Cabitoonan'	, label:	'Cabitoonan'	, link:	'Toledo City'	},
	{value:	'Calongcalong'	, label:	'Calongcalong'	, link:	'Toledo City'	},
	{value:	'Cambang-Ug'	, label:	'Cambang-Ug'	, link:	'Toledo City'	},
	{value:	'Camp 8'	, label:	'Camp 8'	, link:	'Toledo City'	},
	{value:	'Canlumampao'	, label:	'Canlumampao'	, link:	'Toledo City'	},
	{value:	'Cantabaco'	, label:	'Cantabaco'	, link:	'Toledo City'	},
	{value:	'Capitan Claudio'	, label:	'Capitan Claudio'	, link:	'Toledo City'	},
	{value:	'Carmen'	, label:	'Carmen'	, link:	'Toledo City'	},
	{value:	'Daanglungsod'	, label:	'Daanglungsod'	, link:	'Toledo City'	},
	{value:	'Don Andres Soriano'	, label:	'Don Andres Soriano'	, link:	'Toledo City'	},
	{value:	'Dumlog'	, label:	'Dumlog'	, link:	'Toledo City'	},
	{value:	'Gen. Climaco'	, label:	'Gen. Climaco'	, link:	'Toledo City'	},
	{value:	'Ibo'	, label:	'Ibo'	, link:	'Toledo City'	},
	{value:	'Ilihan'	, label:	'Ilihan'	, link:	'Toledo City'	},
	{value:	'Juan Climaco, Sr.'	, label:	'Juan Climaco, Sr.'	, link:	'Toledo City'	},
	{value:	'Landahan'	, label:	'Landahan'	, link:	'Toledo City'	},
	{value:	'Loay'	, label:	'Loay'	, link:	'Toledo City'	},
	{value:	'Luray II'	, label:	'Luray II'	, link:	'Toledo City'	},
	{value:	'Matab-Ang'	, label:	'Matab-Ang'	, link:	'Toledo City'	},
	{value:	'Media Once'	, label:	'Media Once'	, link:	'Toledo City'	},
	{value:	'Pangamihan'	, label:	'Pangamihan'	, link:	'Toledo City'	},
	{value:	'Poblacion'	, label:	'Poblacion'	, link:	'Toledo City'	},
	{value:	'Poog'	, label:	'Poog'	, link:	'Toledo City'	},
	{value:	'Putingbato'	, label:	'Putingbato'	, link:	'Toledo City'	},
	{value:	'Sagay'	, label:	'Sagay'	, link:	'Toledo City'	},
	{value:	'Sam-Ang'	, label:	'Sam-Ang'	, link:	'Toledo City'	},
	{value:	'Sangi'	, label:	'Sangi'	, link:	'Toledo City'	},
	{value:	'Santo NiÃ±o'	, label:	'Santo NiÃ±o'	, link:	'Toledo City'	},
	{value:	'Subayon'	, label:	'Subayon'	, link:	'Toledo City'	},
	{value:	'Talavera'	, label:	'Talavera'	, link:	'Toledo City'	},
	{value:	'Tubod'	, label:	'Tubod'	, link:	'Toledo City'	},
	{value:	'Tungkay'	, label:	'Tungkay'	, link:	'Toledo City'	},
	{value:	'Alegria'	, label:	'Alegria'	, link:	'Tuburan'	},
	{value:	'Amatugan'	, label:	'Amatugan'	, link:	'Tuburan'	},
	{value:	'Antipolo'	, label:	'Antipolo'	, link:	'Tuburan'	},
	{value:	'Apalan'	, label:	'Apalan'	, link:	'Tuburan'	},
	{value:	'Bagasawe'	, label:	'Bagasawe'	, link:	'Tuburan'	},
	{value:	'Bakyawan'	, label:	'Bakyawan'	, link:	'Tuburan'	},
	{value:	'Bangkito'	, label:	'Bangkito'	, link:	'Tuburan'	},
	{value:	'Barangay I'	, label:	'Barangay I'	, link:	'Tuburan'	},
	{value:	'Barangay II'	, label:	'Barangay II'	, link:	'Tuburan'	},
	{value:	'Barangay III'	, label:	'Barangay III'	, link:	'Tuburan'	},
	{value:	'Barangay IV'	, label:	'Barangay IV'	, link:	'Tuburan'	},
	{value:	'Barangay V'	, label:	'Barangay V'	, link:	'Tuburan'	},
	{value:	'Barangay VI'	, label:	'Barangay VI'	, link:	'Tuburan'	},
	{value:	'Barangay VII'	, label:	'Barangay VII'	, link:	'Tuburan'	},
	{value:	'Barangay VIII'	, label:	'Barangay VIII'	, link:	'Tuburan'	},
	{value:	'Bulwang'	, label:	'Bulwang'	, link:	'Tuburan'	},
	{value:	'Caridad'	, label:	'Caridad'	, link:	'Tuburan'	},
	{value:	'Carmelo'	, label:	'Carmelo'	, link:	'Tuburan'	},
	{value:	'Cogon'	, label:	'Cogon'	, link:	'Tuburan'	},
	{value:	'Colonia'	, label:	'Colonia'	, link:	'Tuburan'	},
	{value:	'Daan Lungsod'	, label:	'Daan Lungsod'	, link:	'Tuburan'	},
	{value:	'Fortaliza'	, label:	'Fortaliza'	, link:	'Tuburan'	},
	{value:	'Ga-Ang'	, label:	'Ga-Ang'	, link:	'Tuburan'	},
	{value:	'Gimama-A'	, label:	'Gimama-A'	, link:	'Tuburan'	},
	{value:	'Jagbuaya'	, label:	'Jagbuaya'	, link:	'Tuburan'	},
	{value:	'Kabangkalan'	, label:	'Kabangkalan'	, link:	'Tuburan'	},
	{value:	'Kabkaban'	, label:	'Kabkaban'	, link:	'Tuburan'	},
	{value:	'Kagba-O'	, label:	'Kagba-O'	, link:	'Tuburan'	},
	{value:	'Kalangahan'	, label:	'Kalangahan'	, link:	'Tuburan'	},
	{value:	'Kamansi'	, label:	'Kamansi'	, link:	'Tuburan'	},
	{value:	'Kampoot'	, label:	'Kampoot'	, link:	'Tuburan'	},
	{value:	'Kan-An'	, label:	'Kan-An'	, link:	'Tuburan'	},
	{value:	'Kanlunsing'	, label:	'Kanlunsing'	, link:	'Tuburan'	},
	{value:	'Kansi'	, label:	'Kansi'	, link:	'Tuburan'	},
	{value:	'Kaorasan'	, label:	'Kaorasan'	, link:	'Tuburan'	},
	{value:	'Libo'	, label:	'Libo'	, link:	'Tuburan'	},
	{value:	'Lusong'	, label:	'Lusong'	, link:	'Tuburan'	},
	{value:	'Macupa'	, label:	'Macupa'	, link:	'Tuburan'	},
	{value:	'Mag-Alwa'	, label:	'Mag-Alwa'	, link:	'Tuburan'	},
	{value:	'Mag-Antoy'	, label:	'Mag-Antoy'	, link:	'Tuburan'	},
	{value:	'Mag-Atubang'	, label:	'Mag-Atubang'	, link:	'Tuburan'	},
	{value:	'Maghan-Ay'	, label:	'Maghan-Ay'	, link:	'Tuburan'	},
	{value:	'Mangga'	, label:	'Mangga'	, link:	'Tuburan'	},
	{value:	'Marmol'	, label:	'Marmol'	, link:	'Tuburan'	},
	{value:	'Molobolo'	, label:	'Molobolo'	, link:	'Tuburan'	},
	{value:	'Montealegre'	, label:	'Montealegre'	, link:	'Tuburan'	},
	{value:	'Putat'	, label:	'Putat'	, link:	'Tuburan'	},
	{value:	'San Juan'	, label:	'San Juan'	, link:	'Tuburan'	},
	{value:	'Sandayong'	, label:	'Sandayong'	, link:	'Tuburan'	},
	{value:	'Santo NiÃ±o'	, label:	'Santo NiÃ±o'	, link:	'Tuburan'	},
	{value:	'Siotes'	, label:	'Siotes'	, link:	'Tuburan'	},
	{value:	'Sumon'	, label:	'Sumon'	, link:	'Tuburan'	},
	{value:	'Tominjao'	, label:	'Tominjao'	, link:	'Tuburan'	},
	{value:	'Tomugpa'	, label:	'Tomugpa'	, link:	'Tuburan'	},
	{value:	'Buenavista'	, label:	'Buenavista'	, link:	'Tudela'	},
	{value:	'Calmante'	, label:	'Calmante'	, link:	'Tudela'	},
	{value:	'Daan Secante'	, label:	'Daan Secante'	, link:	'Tudela'	},
	{value:	'General'	, label:	'General'	, link:	'Tudela'	},
	{value:	'Mcarthur'	, label:	'Mcarthur'	, link:	'Tudela'	},
	{value:	'Northern Poblacion'	, label:	'Northern Poblacion'	, link:	'Tudela'	},
	{value:	'Puertobello'	, label:	'Puertobello'	, link:	'Tudela'	},
	{value:	'Santander'	, label:	'Santander'	, link:	'Tudela'	},
	{value:	'Secante Bag-O'	, label:	'Secante Bag-O'	, link:	'Tudela'	},
	{value:	'Southern Poblacion'	, label:	'Southern Poblacion'	, link:	'Tudela'	},
	{value:	'Villahermosa'	, label:	'Villahermosa'	, link:	'Tudela'	},

];