import React from 'react';
import {HorizontalBar } from 'react-chartjs-2';
import styled from 'styled-components';

import {
	Typography, Divider, Paper
} from '@material-ui/core';

const Table = styled.table`
	td {
		padding: 5px;
	}
`;

const AgeDistribution = (props) => {
	const state = {
	  labels: ['0-14', '15-29', '30-44',
			   '45-59', '60-74', '> 75'],
	  datasets: [
		{
		  label: 'Patient Count',
		  backgroundColor: '#4C6FEE',
		  borderColor: '#4C6FEE',
		  borderWidth: 1,
		  data: props.age_group
		}
	  ]
	  
	}
	
	return(
		<article className="canvas-bargraph-container">
		<Paper style={{padding: 5}} variant="elevation16" elevation={5}>
			<div className="card-title" style={{paddingTop: 8, paddingBottom: 8 }}>PATIENT AGE DISTRIBUTION</div>
			<Divider/>
			<div align='center' className="dashboard-maxWidth" style={{ paddingTop: 17, paddingRight: 5  }}>
				<div className="bargraph-responsive">
					<HorizontalBar 
					  data={state}
					  options={{
						legend:{
						  display:false
						},
						scales: {
							xAxes: [{
								gridLines: {
									drawOnChartArea: true
								},
								scaleLabel: {
									display: true,
									labelString: 'Count',
									fontColor: "#4C6FEE",
									fontSize: 12
								},
								ticks: {
									fontSize: 10,
									minRotation: 0,
									maxRotation: 0,
									precision:0,
									beginAtZero: true,
									autoSkip: true,
								},
							}],
							yAxes: [{
								gridLines: {
									drawOnChartArea: true
								},
								scaleLabel: {
									display: true,
									color: 'red',
									labelString: 'Age Group',
									fontColor: "#4C6FEE",
									fontSize: 12
								},
								ticks: {
									fontSize: 10
								},
							}]
						}
					  }}
					/>
				</div>
			</div>
			<br/>
			<div className="dashboard-minWidth">
				<div className="minWidth-left">
					<Typography variant='body2' style={{ color: '#000' }}>AGE GROUP&nbsp;:</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4c9dee' }}>0 - 14&nbsp;:</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4c9dee' }}>15 - 29&nbsp;:</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4c9dee' }}>30 - 44&nbsp;:</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4c9dee' }}>45 - 59&nbsp;:</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4c9dee' }}>60 - 74&nbsp;:</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4c9dee' }}>75 - 100&nbsp;:</Typography>
				</div>
				<div className="minWidth-right">
					<Typography variant='body2' style={{ color: '#000' }}>&nbsp;COUNT</Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4C6FEE' }}>&nbsp;{props.age_group[0]} </Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4C6FEE' }}>&nbsp;{props.age_group[1]} </Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4C6FEE' }}>&nbsp;{props.age_group[2]} </Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4C6FEE' }}>&nbsp;{props.age_group[3]} </Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4C6FEE' }}>&nbsp;{props.age_group[4]} </Typography><Divider/>
					<Typography variant='body2' style={{ color: '#4C6FEE' }}>&nbsp;{props.age_group[5]} </Typography>
				</div>
				<br/>
			</div>
		</Paper>
		</article>
	);
}

export default AgeDistribution;