import React, { Component } from 'react';
import '../Forms.css';
import {
  Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ILIReclassPrompt from './ILIReclassPrompt';
import SARIReclassPrompt from './SARIReclassPrompt';
import SAFEReclassPrompt from './SAFEReclassPrompt';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });

export class UpdateEnd extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		console.log(this.props.values.patient_code)
		console.log(this.props.values.verification_key)
	};
   
    render() {
        const {values, handleChange } = this.props;
        return (
            <MuiThemeProvider>
                <React.Fragment>
					<div className="wrapper_home">
                        <br/>
                        {this.props.values.statusChanged ? 
                            <>
                                { this.props.values.newClass === 'ILI-1' && <ILIReclassPrompt /> }
                                { this.props.values.newClass === 'SARI' && <SARIReclassPrompt/> }
                                { this.props.values.newClass === 'SAFE-2' && <SAFEReclassPrompt/> }
                            </> :
                            <>
                                <h3><b>Thank you for submitting your health status.</b></h3>
        						<br/>
                                <h3>Do not hesitate to call the Health Services Unit for assistance and instructions.</h3>
                            </> }
						<br/><br/><br/><br/>
						<div className="return-home">
							<Link to = '/'>
								<button class = "button-home" 
									primary = {true}
                                    disabled = {values.loading}>
                                        {values.loading? "Please wait..":"Home"}
								</button>
							</Link>
							<Link to = '/getstatus'>
								<button class = "button-home" 
									primary = {true}>
                                        Get Clearance
								</button>
							</Link>
						</div>
						<br/>
						<br/>
						<br/>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default UpdateEnd;
