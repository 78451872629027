import React from 'react';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import {
	Typography, Divider, Paper, Box
} from '@material-ui/core';

const NumberOfDoctors = (props) => {
	const numberOfDoctors = props.numberOfDoctors;
	
	return(
		<Box
			borderLeft={4}
			borderColor={'#4C6FEE'}
			borderRadius={5}
		>
			<Paper variant="elevation16" elevation={5} style={{padding: 5}} >
			<div className="dashboard-maxWidth">
				<div className="card-title"><LocalHospitalIcon className="svg_icons"/>&nbsp;DOCTORS / BUDDIES</div>
			</div>
			<div className="dashboard-minWidth">
				<div className="card-title">DOCTORS / BUDDIES</div>
			</div>
			<Divider/>
			<br/>
			<article className="canvas-container">
				<div className="dashboard-maxWidth">
					<div className="card-content" align='center' style={{ color: '#4C6FEE', paddingTop: 15 }}>
						{numberOfDoctors}
					</div>
				</div>
				<div className="dashboard-minWidth">
					<div align='center'>
						<Typography variant='h1' className="card-label" style={{ color: '#4C6FEE',  paddingTop: 10  }}>{numberOfDoctors}</Typography>
					</div>
				</div>
			</article>
			<br/>
			</Paper>
		</Box>
	);
}

export default NumberOfDoctors;