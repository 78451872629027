import React, { Component } from 'react';
import axios from 'axios';
import './QB_Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SelectReact from 'react-select';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 10px;	

	td {
		padding: 5px;
	}
`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const BlueCheckbox = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyleRadio = withStyles({
    root: {
      color: '#4C6FEE',
      '&$checked': {
        color: '#4C6FEE',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const customStyles = {
  control: (base, state) => ({
      ...base,
	 background: 'white',
	 height: 56,
      borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
      "&:hover": {
        borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
      }
    })
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4C6FEE',
    },
	'& .MuiInput-underline-root': {
		'& fieldset': {
			borderBottomColor: '#4C6FEE',
		},
		'&:after': {
		    borderBottomColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderBottomColor: '#4C6FEE',
		},
    },
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderColor: '#3AD5C1',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#4C6FEE',
		},
    },
  },
})(TextField);


class EditHealthStatus extends Component {
	state = {
        userRole: cookies.get('qb_role'),
        id:'',

		date_time:null,
        patient_id: parseInt(this.props.match.params.id),
        call_answered:true,
		temperature:'36',
        symptom_fever:false,
        symptom_cough:false,
        symptom_colds:false,
        symptom_difficulty_breathing:false,
        symptom_body_pain:false,
        symptom_headache:false,
        symptom_chest_pain:false,
        symptom_confusion:false,
        symptom_bluish_lips_fingers:false,
        maintenance_medications_taken:false,
        symptom_psychological_distress:false,
        symptom_others:'',
        heart_rate:null,
        blood_pressure:'',
        o2_saturation:null,
        remarks:'',
        deleted:false,
        
		other:false,
		idError:'',
        medication: '',
        patient_name:'',
        case_no:'',
        disabled:false,

        tempError: false,
        heartRateError: false,
        o2Error: false,
    }
	
	componentDidMount() {
        window.scrollTo(0, 0);

        const patientId = this.props.match.params.patient_id;
        const logId = this.props.match.params.log_id;

		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
                    var name = result.data.first_name;
                    if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
                        name = result.data.last_name + ", " + result.data.first_name + " " + result.data.middle_name;
                    }
                    this.setState({ 
                        patient_name: name, 
                        medication: result.data.medications,
                        case_no: result.data.case_number,
                    });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${patientId}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${patientId}`;
			axios(url, {withCredentials: true})
				.then(result => {
                    var name = result.data.first_name;
                    if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
                        name = result.data.last_name + ", " + result.data.first_name + " " + result.data.middle_name;
                    }
                    this.setState({ 
                        patient_name: name,
                        medication: result.data.medications, 
                        case_no: result.data.case_number,
                    });
					//this.setState({ patient: result.data });
				});
        }
        
		let url = `/api/qb/patients/${patientId}/logs/${logId}`;
        if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({
                        id: result.data.id,
                        patient_id: result.data.patient_id,
                        date_time:result.date_time,
                        call_answered:result.data.call_responded,
                        temperature:result.data.temperature,
                        symptom_fever:result.data.symptom_fever,
                        symptom_cough:result.data.symptom_cough,
                        symptom_colds:result.data.symptom_colds,
                        symptom_difficulty_breathing:result.data.symptom_difficulty_breathing,
                        symptom_body_pain:result.data.symptom_body_pain,
                        symptom_headache:result.data.symptom_headache,
                        symptom_chest_pain:result.data.symptom_chest_pain,
                        symptom_confusion:result.data.symptom_confusion,
                        symptom_bluish_lips_fingers:result.data.symptom_bluish_lips_fingers,
                        maintenance_medications_taken:result.data.maintenance_medications_taken,
                        symptom_psychological_distress:result.data.symptom_psychological_distress,
                        symptom_others:result.data.symptom_others,
                        heart_rate:result.data.heart_rate,
                        blood_pressure:result.data.blood_pressure,
                        o2_saturation:result.data.o2_saturation,
                        remarks:result.data.remarks,
                        deleted:result.data.deleted,
						// loading: false
                    });
                    if(this.state.symptom_others.trim() != ''){
                        this.setState({
                            other:true,
                        });
                    }

				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({
                        id: result.data.id,
                        patient_id: result.data.patient_id,
                        date_time:result.date_time,
                        call_answered:result.data.call_responded,
                        temperature:result.data.temperature,
                        symptom_fever:result.data.symptom_fever,
                        symptom_cough:result.data.symptom_cough,
                        symptom_colds:result.data.symptom_colds,
                        symptom_difficulty_breathing:result.data.symptom_difficulty_breathing,
                        symptom_body_pain:result.data.symptom_body_pain,
                        symptom_headache:result.data.symptom_headache,
                        symptom_chest_pain:result.data.symptom_chest_pain,
                        symptom_confusion:result.data.symptom_confusion,
                        symptom_bluish_lips_fingers:result.data.symptom_bluish_lips_fingers,
                        maintenance_medications_taken:result.data.maintenance_medications_taken,
                        symptom_psychological_distress:result.data.symptom_psychological_distress,
                        symptom_others:result.data.symptom_others,
                        heart_rate:result.data.heart_rate,
                        blood_pressure:result.data.blood_pressure,
                        o2_saturation:result.data.o2_saturation,
                        remarks:result.data.remarks,
                        deleted:result.data.deleted,
					});
				});
                if(this.state.symptom_others.trim() != ''){
                    this.setState({
                        other:true,
                    });
                }
		}
	};
	
	handleFieldChange = input => e => {
        this.setState({[input]: e.target.value});
	}
    //handle textview number input change
    handleNumberChange = (value) => {
        this.setState({temperature: value.floatValue});
    }
    //Handle checkbox input change
    handleCheckChange = name => e => {
        this.setState({[name]: e.target.checked});
    };
    //handle yes no input
	handleChange_testing = (decision) => (event) => {
        this.setState({maintenance_medications_taken: decision});
    }

    handleChange_call = (decision) => (event) => {
        this.setState({call_answered: decision});
    }

	handleSave = e => {
        e.preventDefault();
        var patient = {
            doctor_id: this.state.doctor_id,
            patient_id : this.state.patient_id,
            call_responded:this.state.call_answered,
            temperature : this.state.temperature,
            symptom_fever : this.state.symptom_fever,
            symptom_cough : this.state.symptom_cough,
            symptom_colds : this.state.symptom_colds,
            symptom_difficulty_breathing : this.state.symptom_difficulty_breathing,
            symptom_body_pain : this.state.symptom_body_pain,
            symptom_headache : this.state.symptom_headache,
            symptom_chest_pain : this.state.symptom_chest_pain,
            symptom_confusion : this.state.symptom_confusion,
            symptom_bluish_lips_fingers : this.state.symptom_bluish_lips_fingers,
            maintenance_medications_taken : this.state.maintenance_medications_taken,
            symptom_psychological_distress : this.state.symptom_psychological_distress,
            symptom_others : this.state.symptom_others,
            heart_rate:this.state.heart_rate ? parseInt(this.state.heart_rate) : null,
            blood_pressure:this.state.blood_pressure,
            o2_saturation:this.state.o2_saturation ? parseFloat(this.state.o2_saturation) : null,
            remarks : this.state.remarks,
            deleted : false,
        };

        if(!this.state.other){
            this.setState({
                symptom_others: "",
            });
        }

        var valid = true;
        var temp = this.state.temperature + "";
		if(parseFloat(temp).toString() !== temp.toString()){
            valid = false;
            this.setState({
                tempError: true
            });
		}else{
            this.setState({
                tempError: false
            });
        }

        if(!this.state.call_answered){
            valid = true;
            var patient = {
                doctor_id: this.state.doctor_id,
                patient_id : this.state.patient_id,
                call_responded:this.state.call_answered,
                temperature:null,
                symptom_fever:false,
                symptom_cough:false,
                symptom_colds:false,
                symptom_difficulty_breathing:false,
                symptom_body_pain:false,
                symptom_headache:false,
                symptom_chest_pain:false,
                symptom_confusion:false,
                symptom_bluish_lips_fingers:false,
                maintenance_medications_taken:false,
                symptom_psychological_distress:false,
                symptom_others:'',
                heart_rate:null,
                blood_pressure:'',
                o2_saturation:null,
                remarks : this.state.remarks,
                deleted:false,
            };
        }
        if(valid){
            this.setState({disabled: true});
            if (process.env.NODE_ENV === 'development') {
                const username = 'admin';
                const password = 'V09VL7';
                const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
                axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${this.state.patient_id}/logs/${this.state.id}`, patient, {headers: {'Authorization': `Basic ${token}`}})
                    .then(result => {
                        if(result.status === 201||result.status === 200){
                            this.props.history.push(`/qb-patient-health-status/${this.state.patient_id}`);
                        }else{
                            this.setState({disabled: false});
                        }
                    });
            }else{
                const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${this.state.patient_id}/logs/${this.state.id}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${this.state.patient_id}/logs/${this.state.id}`;
                axios.put(url, patient, {withCredentials: true})
                    .then(result => {
                        if(result.status === 201||result.status === 200){
                            this.props.history.push(`/qb-patient-health-status/${this.state.patient_id}`);
                        }else{
                            this.setState({disabled: false});
                        }
                    });
            }
        }
    };
	
    render() {
		const classes = {margin:1};
        return (
			<Paper style = {{ padding: 10 }}>
				<Grid item xs={24} md={12} lg={12} xl={12}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Update Health Status
					</Typography>
                    <Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
								<CssTextField 
                                    disabled = {true}
									value = {this.state.patient_name}
									id="outlined-basic" 
									label="Name" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
                            <td>
								<CssTextField 
                                    disabled = {true}
									value = {this.state.case_no}
									id="outlined-basic" 
									label="Case Number" 
									variant="outlined" 
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
						</tr>
						</tbody>
					</Table>
                    <Divider/>
					<br/>
                    <Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
                        Is the patient responding to calls?
                    </Typography>
					<Table className="adPatient-twochoice-table">
						<tr>
							<td>
							<FormControlLabel 
								value="yes" 
								control={<StyleRadio 
										checked={this.state.call_answered}
										onChange={this.handleChange_call(true)}
									/>} 
								style={{ width: 100, height: 40 }}
								label="Yes" 
							/>
							</td>
						</tr>
						<tr>
							<td>
								<FormControlLabel 
									value="no" 
									control={<StyleRadio 
												checked={!this.state.call_answered}
												onChange={this.handleChange_call(false)}
											/>} 
									style={{ width: 100, height: 40 }} 
									label="No" 
								/>
							</td>
						</tr>
					</Table>
					<br/>
                    {(() => {
                        if(this.state.call_answered){
                            return(
                                <>
                					<Divider/>
                                    <Table className="adPatient-table">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <CssTextField 
                                                    disabled = {!this.state.call_answered}
                                                    value = {this.state.temperature}
                                                    id="outlined-basic" 
                                                    label="Temperature"
                                                    type="number" 
                                                    variant="outlined" 
                                                    fullWidth={true}
                                                    className={classes.margin}
                                                    error={this.state.tempError}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        className: classes.floatingLabelFocusStyle,
                                                    }}
                                                    onChange={this.handleFieldChange('temperature')}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <Divider/>
                					<br/>
                					<Typography component="h2" variant="h6" color="primary" gutterBottom>
                                        Symptoms
                                    </Typography>
                                    <Table className="adPatient-table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= 'symptom_fever'
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_fever}
                                                                onChange={this.handleCheckChange('symptom_fever')}
                                                            />} 
                                                    label="Fever" 
                                                />
                                            </td>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_cough"
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_cough}
                                                                onChange={this.handleCheckChange('symptom_cough')}
                                                            />} 
                                                    label="Cough" />
                                            </td>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_colds"
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_colds}
                                                                onChange={this.handleCheckChange('symptom_colds')}
                                                            />} 
                                                    label="Colds" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_difficulty_breathing" 
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_difficulty_breathing}
                                                                onChange={this.handleCheckChange('symptom_difficulty_breathing')}
                                                            />} 
                                                    label="Difficulty Breathing" />
                                            </td>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_body_pain" 
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_body_pain}
                                                                onChange={this.handleCheckChange('symptom_body_pain')}
                                                            />} 
                                                    label="Body Pain" />
                                            </td>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_headache"
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_headache}
                                                                onChange={this.handleCheckChange('symptom_headache')}
                                                            />} 
                                                    label="Headache" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_chest_pain" 
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_chest_pain}
                                                                onChange={this.handleCheckChange('symptom_chest_pain')}
                                                            />} 
                                                    label="Chest Pain" />
                                            </td>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_confusion" 
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_confusion}
                                                                onChange={this.handleCheckChange('symptom_confusion')}
                                                            />} 
                                                    label="Confusion" />
                                            </td>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_bluish_lips_fingers"
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_bluish_lips_fingers}
                                                                onChange={this.handleCheckChange('symptom_bluish_lips_fingers')}
                                                            />} 
                                                    label="Bluish Lip or Fingers" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                                                    value= "symptom_psychological_distress" 
                                                    control={<BlueCheckbox 
                                                                checked={this.state.symptom_psychological_distress}
                                                                onChange={this.handleCheckChange('symptom_psychological_distress')}
                                                            />} 
                                                    label="Psychological Distress" />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                					<br/>
                					<Divider/>
                					<br/>
                					<FormLabel component="legend" style={{ paddingLeft: 20 }}>Other Symptoms</FormLabel>
                					<Table className="adPatient-twochoice-table">
                						<tr>
                							<td>
                							<FormControlLabel 
                                                disabled = {!this.state.call_answered}
                								value="other" 
                								control={<BlueCheckbox 
                										checked={this.state.other}
                                                        onChange={this.handleCheckChange('other')}
                									/>} 
                								style={{ width: 100, height: 40 }}
                								label="Others"
                							/>
                							</td>
                							<td>
                								<CssTextField 
                                                    onChange={this.handleFieldChange('symptom_others')}
                                                    disabled={!this.state.other || !this.state.call_answered}
                									value = {this.state.symptom_others}
                									id="outlined-basic" 
                									label="Other Symptoms" 
                									variant="outlined" 
                									fullWidth={true}
                									className={classes.margin}
                									InputLabelProps={{
                										shrink: true,
                										className: classes.floatingLabelFocusStyle,
                									}}
                								/>
                							</td>
                						</tr>
                					</Table>
                					<br/>
                					<Divider/>
                					<br/>
                					<Typography component="h2" variant="h6" color="primary" gutterBottom>
                                        Has the patient taken maintenance medication?
                                    </Typography>
                					<Table className="adPatient-twochoice-table">
                						<tr>
                							<td>
                							<FormControlLabel 
                                                disabled = {!this.state.call_answered}
                								value="yes" 
                								control={<StyleRadio 
                										checked={this.state.maintenance_medications_taken}
                										onChange={this.handleChange_testing(true)}
                									/>} 
                								style={{ width: 100, height: 40 }}
                								label="Yes" 
                							/>
                							</td>
                						</tr>
                						<tr>
                							<td>
                								<FormControlLabel 
                                                    disabled = {!this.state.call_answered}
                									value="no" 
                									control={<StyleRadio 
                												checked={!this.state.maintenance_medications_taken}
                												onChange={this.handleChange_testing(false)}
                											/>} 
                									style={{ width: 100, height: 40 }} 
                									label="No" 
                								/>
                							</td>
                						</tr>
                					</Table>
                					<br/>
                                    <Divider/>
                					<br/>
                					<Typography component="h2" variant="h6" color="primary" gutterBottom>
                                        Vitals
                                    </Typography>
                					<Table className="adPatient-table">
                					    <tbody>
                						<tr>
                							<td>
                								<CssTextField 
                                                    disabled = {!this.state.call_answered}
                									value = {this.state.heart_rate}
                									id="outlined-basic" 
                									label="Heart Rate"
                                                    type="number" 
                									variant="outlined" 
                									fullWidth={true}
                									className={classes.margin}
                									InputLabelProps={{
                										shrink: true,
                										className: classes.floatingLabelFocusStyle,
                									}}
                                                    onChange={this.handleFieldChange('heart_rate')}
                								/>
                							</td>
                                            <td>
                								<CssTextField 
                                                    disabled = {!this.state.call_answered}
                									value = {this.state.blood_pressure}
                									id="outlined-basic" 
                									label="Blood Pressure" 
                									variant="outlined" 
                									fullWidth={true}
                									className={classes.margin}
                									InputLabelProps={{
                										shrink: true,
                										className: classes.floatingLabelFocusStyle,
                									}}
                                                    onChange={this.handleFieldChange('blood_pressure')}
                								/>
                							</td>
                                            <td>
                								<CssTextField 
                                                    disabled = {!this.state.call_answered}
                									value = {this.state.o2_saturation}
                									id="outlined-basic" 
                									label="O2 Saturation" 
                                                    type="number" 
                									variant="outlined" 
                									fullWidth={true}
                									className={classes.margin}
                									InputLabelProps={{
                										shrink: true,
                										className: classes.floatingLabelFocusStyle,
                									}}
                                                    onChange={this.handleFieldChange('o2_saturation')}
                								/>
                							</td>
                						</tr>
                						</tbody>
                					</Table>
                					<br/>
                                </>
                            );
                        }
                    })()}
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
                        Notes
                    </Typography>
					<Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
							<CssTextField 
								onChange={this.handleFieldChange('remarks')}
								value = {this.state.remarks}
								id="outlined-basic" 
								label="Notes" 
								variant="outlined"  
								fullWidth={true}
                                multiline
								className={classes.margin}
								InputLabelProps={{
									shrink: true,
									className: classes.floatingLabelFocusStyle,
								}}
							/>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left'>
					        	<Button align='left' size="large" variant="outlined" color='secondary'
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			onClick={() => this.props.history.push(`/qb-patient-health-status/${this.state.patient_id}`) }>
				        			CANCEL</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									size='large'
									variant='contained'
                                    color='primary'
                                    disabled={this.state.disabled}
									onClick = {this.handleSave}>
										{this.state.disabled? 'SAVING...' : 'SAVE'}
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
        );
    };
}

export default EditHealthStatus;