import React, { Component } from 'react';
import crypto from 'crypto';
import axios from 'axios';
import './QB_Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SelectReact from 'react-select';
import makeAnimated from 'react-select/animated';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
	Button, Divider, Grid, Paper, Typography, Checkbox
} from '@material-ui/core';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const animatedComponents = makeAnimated();
const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 10px;	

	td {
		padding: 5px;
	}
`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const customStyles = {
  control: (base, state) => ({
      ...base,
	 background: 'white',
	 height: 56,
      borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
      "&:hover": {
        borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
      }
    })
}

const BlueCheckbox = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4C6FEE',
    },
	'& .MuiInput-underline-root': {
		'& fieldset': {
			borderBottomColor: '#4C6FEE',
		},
		'&:after': {
		    borderBottomColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderBottomColor: '#4C6FEE',
		},
    },
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#4C6FEE',
		},
		'&:hover fieldset': {
			borderColor: '#3AD5C1',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#4C6FEE',
		},
    },
  },
})(TextField);

const roles_options = [
	{ value: 'Coordinator', label: 'Coordinator' },
	{ value: 'Admin', label: 'Doctor/Nurse' },
	{ value: 'Doctor', label: 'Buddy' }
]

class AddUpdateDoctor extends Component {
	state = {
		userAssignedFacilities: cookies.get('qb_assigned_facilities') ? cookies.get('qb_assigned_facilities') : '',
		doctor: {},
		facilities: [],
		facilitiesOptions: [],
		selectedFacilities: [],
		doctorId: this.props.match.params.id,
        selectedRoleOption: { value: 'Doctor', label: 'Buddy' },
        mode: this.props.match.params.id ? 'Update User Information' : 'Add User',
        showPassword: false,
        autoGeneratePassword: true,
        generatedPassword: '',
        password: '',
        confirmPassword: '',
        errorUsername: false,
        errorPassword: false,
        errorConfirmPassword: false,
        errorLastName: false,
        errorFirstName: false,
        errorPasswordText: '',
        errorFacilities: '',
        selectFacilitiesDisable: false,
		loading:false,
    }
	
	componentDidMount() {
		window.scrollTo(0, 0);
		const doctorId = this.props.match.params.id;

		if(doctorId){ //update
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${doctorId}`, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						const doctor = result.data;
						const selectedRoleOption = roles_options.filter(o => o.value === doctor.role)[0];
						this.setState({ doctor, selectedRoleOption }, () => {
							axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities`, {headers: {'Authorization': `Basic ${token}`}})
								.then(result => {
									this.setState({
										facilities: result.data.facilities
									}, () => {
										this.filterFacilities();
									});
								});
						});
					});						
			} else {
				const doctorUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${doctorId}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${doctorId}`;
				axios(doctorUrl, {withCredentials: true})
					.then(result => {
						const doctor = result.data;
						const selectedRoleOption = roles_options.filter(o => o.value === doctor.role)[0];
						this.setState({ doctor, selectedRoleOption }, () => {
							const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
								 `https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities` : `https://covidcheck.upcebu.edu.ph/api/qb/facilities`;
							axios(url, {withCredentials: true})
								.then(result => {
									this.setState({
										facilities: result.data.facilities
									}, () => {
										this.filterFacilities();
									});
							});
						});
					});
			}
		}else{ //add
			const doctor = this.state.doctor;
			if(process.env.NODE_ENV === 'development'){
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities`, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						this.setState({
							facilities: result.data.facilities
						}, () => {
							this.filterFacilities();
						});
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 `https://covidcheck.upcebu.edu.ph/dev/api/qb/facilities` : `https://covidcheck.upcebu.edu.ph/api/qb/facilities`;
				axios(url, {withCredentials: true})
					.then(result => {
						this.setState({
							facilities: result.data.facilities
						}, () => {
							this.filterFacilities();
					});
				});
			}
		}
	};

	stringToArray = (str) => {
		let arr = [];
		if(str){
			const strSplits = str.split(',');
			strSplits.map(s => {
				arr.push(s.trim());
			});
		}
		return arr;
	}

	stringToArrayObject = (str) => {
		let arr = [];
		if(str){
			const strSplits = str.split(',');
			strSplits.map(s => {
				const facilityId = parseInt(s.trim());
				arr.push({value: facilityId, label: this.state.facilities.filter(o => o.id === facilityId)[0].name});
			});
		}
		return arr;
	}

	arrayToString = (arr) => {
		if(arr){
			const reducer = (str, current) => str ? str+','+current.value : ''+current.value;
			return arr.reduce(reducer, '');
		}
		return '';
	}

	setInitialSelectedFacilities = (selectedFacilities) => {
		if(this.state.mode === 'Add User'){
			return selectedFacilities;
		}else if(this.state.mode === 'Update User Information'){
			return this.stringToArrayObject(this.state.doctor.assigned_facilities);
		}
	}

	filterFacilities = () => {
		const facilities = this.state.facilities;
		let newFacilities = [];
		let selectedFacilities = this.state.selectedFacilities;
		if(this.state.selectedRoleOption.value === 'Coordinator'){
			newFacilities = facilities.map(f => {
				return {value: f.id, label: f.name}
			});
		}else{
			this.setState({selectFacilitiesDisable : true});
			if (process.env.NODE_ENV === 'development') {
				const username = 'admin';
				const password = 'V09VL7';
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
				axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}`, {headers: {'Authorization': `Basic ${token}`}})
					.then(result => {
						const userAssignedFacilities = result.data.assigned_facilities;
						facilities.map(f => {
							if(userAssignedFacilities.includes(f.id)){
								newFacilities.push({value: f.id, label: f.name});
							}
						});
						this.setState({selectFacilitiesDisable : false});
					});
			}else{
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
				 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${cookies.get('qb_id')}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${cookies.get('qb_id')}`;
				axios(url, {withCredentials: true})
					.then(result => {
						const userAssignedFacilities = result.data.assigned_facilities;
						facilities.map(f => {
							if(userAssignedFacilities.includes(f.id)){
								newFacilities.push({value: f.id, label: f.name});
							}
						});
						this.setState({selectFacilitiesDisable : false});
					});
			}
		}
		this.setState({ 
			facilitiesOptions: newFacilities, 
			selectedFacilities: this.setInitialSelectedFacilities(selectedFacilities) 
		});
	}

	handlePasswordChange = field => event => {
		this.setState({ [field]: event.target.value });
	}
	
	handleFieldChange = field => event => {
		const doctor = this.state.doctor;
		doctor[field] = event.target.value;
		this.setState({ doctor: doctor });
	}
	
	handleRoleChange = selectedOption  => {
		const doctor = this.state.doctor;
		doctor.role = selectedOption.value;
		this.setState({ doctor: doctor, selectedRoleOption: selectedOption }, () => {this.filterFacilities()});
	}

	handleFacilitiesChange = selectedOptions => {
		this.setState({ selectedFacilities: selectedOptions });
	}

	handleCheckChange = event => {
		const auto = event.target.checked;
		let generatedPassword = '';
		if(auto){
			generatedPassword = parseInt(crypto.randomBytes(16).toString('hex'), 16).toString(36).toUpperCase().slice(2, 8);
		}
		this.setState({
			autoGeneratePassword: auto,
			generatedPassword: generatedPassword
		});
	}

	handleCloseShowPassword = () => {
		this.setState({ showPassword: false });
		this.props.history.push('/qb-doctors');
	}
	
	handleSave = e => {
        e.preventDefault();

        let valid = true;
        let errorUsername = false;
        let errorLastName = false;
        let errorFirstName = false;
        let errorPassword = false;
        let errorConfirmPassword = false;
        let errorPasswordText = '';
        let errorFacilities = '';

        if(!this.state.doctor.username){
        	valid = false;
        	errorUsername = true;
        }
        if(!this.state.doctor.last_name){
        	valid = false;
        	errorLastName = true;
        }
        if(!this.state.doctor.first_name){
        	valid = false;
        	errorFirstName = true;
        }

        if(this.state.selectedFacilities === null || (this.state.selectedFacilities ? this.state.selectedFacilities.length === 0 : false)){
        	valid = false;
        	errorFacilities = 'Please assign user to atleast one(1) facility';
        }

        if(this.state.mode === 'Add User' && false){
        	if(!this.state.autoGeneratePassword){
        		if(!this.state.password){
        			valid = false;
        			errorPassword = true;
        		}
        		if(!this.state.confirmPassword){
        			valid = false;
        			errorConfirmPassword = true;
        		}
        		if(!errorPassword && !errorConfirmPassword){
        			if(this.state.password !== this.state.confirmPassword){
        				valid = false;
        				errorPassword = true;
        				errorConfirmPassword = true;
        				errorPasswordText = 'Passwords do not match!';
        			}
        		}
        	}
        }

        if(valid){
			this.setState({ loading: true });
	        if(this.state.mode === 'Add User'){
				const doctor = this.state.doctor;
				doctor.middle_name = doctor.middle_name ? doctor.middle_name : '';
				doctor.role = this.state.selectedRoleOption.value;
				doctor.deleted = false;
				doctor.reset_password = this.state.autoGeneratePassword;
				doctor.password = this.state.autoGeneratePassword ? this.state.generatedPassword : this.state.password;
				doctor.assigned_facilities = this.arrayToString(this.state.selectedFacilities);

				if (process.env.NODE_ENV === 'development') {
					const username = 'admin';
					const password = 'V09VL7';
					const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
					axios.post('https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors', doctor, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							if(result.status === 201){
								this.setState({
									showPassword: true,
									generatedPassword: result.data.password,
									loading: false
								});
							}else{
								this.setState({ loading: false });
							}
						});
				}else{
					const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 'https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors' : 'https://covidcheck.upcebu.edu.ph/api/qb/doctors';
					axios.post(url, doctor, {withCredentials: true})
						.then(result => {
							if(result.status === 201){
								this.setState({
									showPassword: true,
									generatedPassword: result.data.password,
									loading: false
								});
							}else{
								this.setState({ loading: false });
							}
						});
				}
			}else if(this.state.mode === 'Update User Information'){
				const doctor = this.state.doctor;
				doctor.role = this.state.selectedRoleOption.value;
				doctor.assigned_facilities = this.arrayToString(this.state.selectedFacilities);
				const doctorId = doctor.id;

				const body = {
					first_name: doctor.first_name,
					last_name: doctor.last_name,
					middle_name: doctor.middle_name,
					role: doctor.role,
					username: doctor.username,
					assigned_facilities: doctor.assigned_facilities
				};

				if (process.env.NODE_ENV === 'development') {
					const username = 'admin';
					const password = 'V09VL7';
					const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
					axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${doctorId}`, body, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							if(result.status === 200){
								this.props.history.push('/qb-doctors');
							}else{
								this.setState({ loading: false });
							}
						});
				} else {
		            const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
		             `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${doctorId}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${doctorId}`;
					axios.put(url, body, {withCredentials: true})
						.then(result => {
							if(result.status === 200){
								this.props.history.push('/qb-doctors');
							}else{
								this.setState({ loading: false });
							}
						});
				}
			}
		}else{
			this.setState({ loading: false });
		}
		this.setState({
			errorUsername,
			errorLastName,
			errorFirstName,
			errorPassword,
			errorConfirmPassword,
			errorPasswordText,
			errorFacilities
		});
    };
	
    render() {
		const classes = {margin: 1};
        return (
			<Paper style = {{ padding: 10 }}>
				<Grid item xs={24} md={12} lg={12} xl={12}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						{this.state.mode}
					</Typography>
					<Table className="adPatient-table">
					    <tbody>
					    <Typography component="h1" variant="subtitle1" color="textSecondary" gutterBottom style={{ paddingLeft: 10 }}>
							User Details
						</Typography>
					    <tr>
							<td>
								<CssTextField 
									value = {this.state.doctor.username}
									onChange={this.handleFieldChange('username')}
									id="outlined-basic" 
									label="Username" 
									variant="outlined"  
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									error={this.state.errorUsername}
								/>
							</td>
						</tr>
						{(() => {
							if(this.state.mode === 'Add User' && false){
								return(
									<>
									<FormControlLabel 
										value="other" 
										control={<BlueCheckbox 
												checked={this.state.autoGeneratePassword}
												onChange={this.handleCheckChange}
											/>}
										style={{ height: 40, fontSize: 8, paddingLeft: 5, paddingBottom: 5 }}
										label="Auto-Generate Password"
									/>
									{(() => {
										if(this.state.autoGeneratePassword){
											return(
												<tr>
													<td>
														<CssTextField 
															value = {this.state.generatedPassword}
															id="outlined-basic" 
															label="Generated Password" 
															variant="outlined"  
															fullWidth={true}
															disabled
															style={{ fontWeight: 'bold' }}
															InputLabelProps={{
																shrink: true,
																className: classes.floatingLabelFocusStyle,
															}}
														/>
													</td>
												</tr>
											);
										}else{
											return(
												<>
													<tr>
														<td>
															<CssTextField 
																onChange={this.handlePasswordChange('password')}
																value = {this.state.password}
																id="outlined-basic" 
																label="Password"
																type="password"
																variant="outlined"  
																fullWidth={true}
																InputLabelProps={{
																	shrink: true,
																	className: classes.floatingLabelFocusStyle,
																}}
																error={this.state.errorPassword}
															/>
														</td>
														<td>
															<CssTextField 
																onChange={this.handlePasswordChange('confirmPassword')}
																value = {this.state.confirmPassword}
																id="outlined-basic" 
																label="Confirm Password"
																type="password"
																variant="outlined"  
																fullWidth={true}
																InputLabelProps={{
																	shrink: true,
																	className: classes.floatingLabelFocusStyle,
																}}
																error={this.state.errorConfirmPassword}
															/>
														</td>
													</tr>
													<Typography component="h1" variant="caption" color="error" gutterBottom style={{ paddingLeft: 10 }}>
														{this.state.errorPasswordText}
													</Typography>
												</>
											);
										}
									})()}
									</>
								);
							}
						})()}
						<Typography component="h1" variant="subtitle1" color="textSecondary" gutterBottom style={{ paddingLeft: 10 }}>
							Name
						</Typography>
						<tr>
							<td>
								<CssTextField 
									onChange={this.handleFieldChange('last_name')}
									value = {this.state.doctor.last_name}
									id="outlined-basic" 
									label="Last Name" 
									variant="outlined"  
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									error={this.state.errorLastName}
								/>
							</td>
							<td>
								<CssTextField 
									onChange={this.handleFieldChange('first_name')}
									value = {this.state.doctor.first_name}
									id="outlined-basic" 
									label="First Name" 
									variant="outlined" 
									fullWidth={true} 
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									error={this.state.errorFirstName}
								/>
							</td>
							<td>
								<CssTextField 
									onChange={this.handleFieldChange('middle_name')}
									value = {this.state.doctor.middle_name}
									id="outlined-basic" 
									label="Middle Name" 
									variant="outlined"  
									fullWidth={true}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<Typography component="h1" variant="subtitle1" color="textSecondary" gutterBottom>
									Role
								</Typography>
								<SelectReact
									styles={customStyles}
									options={roles_options}
									value={this.state.selectedRoleOption}
									searchable
									placeholder="Role"
									fullWidth={true}
									onChange={this.handleRoleChange}
									textFieldProps={{
										label: 'Label',
										InputLabelProps: {
										shrink: true,
										},
									}}
								/>
							</td>
							<td colspan='2'>
								<Typography component="h1" variant="subtitle1" color="textSecondary" gutterBottom>
									Assigned Facilities
								</Typography>
								<SelectReact
									disabled={this.state.selectFacilitiesDisable}
									styles={customStyles}
									options={this.state.facilitiesOptions}
									value={this.state.selectedFacilities}
									onChange={this.handleFacilitiesChange}
									searchable
									isMulti
									closeMenuOnSelect={false}
      								components={animatedComponents}
									fullWidth={true}
									textFieldProps={{
										label: 'Label',
										InputLabelProps: {
											shrink: true,
										},
									}}
								/>
							</td>
						</tr>
						<tr>
							<td/>
							<td colspan='2'>
								<Typography component="h1" variant="caption" color="error">
									{this.state.errorFacilities}
								</Typography>
							</td>
						</tr>
						</tbody>
					</Table>
					<br/>
					<Divider/>
					<br/>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<div align='left'>
					        	<Button align='left' size="large" variant="outlined" color='secondary'
					        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
					        			onClick={() => this.props.history.push('/qb-doctors') }>
				        			CANCEL</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div align='right' style={{ paddingBottom: 10 }}>
								<Button
									fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
									size='large'
									variant='contained'
									color='primary'
									onClick = {this.handleSave}
									disabled = {this.state.loading}>
										{this.state.loading? "SAVING..":"SAVE"}
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Dialog
	        		fullWidth={true}
	        		maxWidth={'xs'}
					open={this.state.showPassword}
					keepMounted
					onClose={this.handleCloseShowPassword}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">Generated Password</DialogTitle>
					<DialogContent>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							{this.state.generatedPassword}
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseShowPassword} color="primary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</Paper>
        );
    };
}

export default AddUpdateDoctor;