import React, { Component } from 'react';

class MainDashboard extends Component {

	render(){
		return(
			<React.Fragment>
				<div>Charts</div>
			</React.Fragment>
		);
	}
}

export default MainDashboard;