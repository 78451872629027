import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ContactsIcon from '@material-ui/icons/Contacts';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const HealthStatusActionMenu = (props) => {
	const item = props.row;
	const userRole = props.userRole;
	const userId = props.userId;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openDelete, setOpenDelete] = React.useState(false);

	const handleOpenDelete = () => {
		// console.log(item);
		setOpenDelete(true);
		setAnchorEl(null);
	}

	const handleCloseDelete = () => {
		setOpenDelete(false);
	}

	const handleDelete = () => {
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.delete(`https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${item.patient_id}/logs/${item.id}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					if(result.status === 200){
						props.filterLogs();
					}
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/patients/${item.patient_id}/logs/${item.id}` : `https://covidcheck.upcebu.edu.ph/api/qb/patients/${item.patient_id}/logs/${item.id}`;
			axios.delete(url, {withCredentials: true})
				.then(result => {
					if(result.status === 200){
						props.filterLogs();
					}
				});
		}
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return(
		<React.Fragment>
			{(userRole === 'Doctor' && userId != item.doctor_id) ? null :
			<>
			<Button size="small"
					aria-controls="actions-menu"
					aria-haspopup="true"
					startIcon={<ArrowForwardIosIcon/>}
					onClick={handleClick}>
			</Button>
			<Menu
				id="actions-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem component={Link} to={`/qb-update-health-status/${item.patient_id}/${item.id}`}>
					Update Health Status
				</MenuItem>
				<MenuItem onClick={handleOpenDelete}>
					Delete
				</MenuItem>
				<Dialog
	        		fullWidth={true}
	        		maxWidth={'xs'}
					open={openDelete}
					keepMounted
					onClose={handleCloseDelete}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle id="alert-dialog-slide-title">Delete Health Status</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Delete selected Health Status - {moment(item.date_time).format('lll')}?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDelete} color="primary">
							Cancel
						</Button>
						<Button onClick={handleDelete} color="secondary">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</Menu>
			</> }
		</React.Fragment>
	);
}

export default HealthStatusActionMenu;
