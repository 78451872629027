import React, { Component } from 'react';
import '../Forms.css';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
  
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Faculty_Student extends Component {
    state = {
        errorText: ""
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	};
    state = {
        gender: 'Male',
        maleSelect: 'none',
        femaleSelect: 'none'
    }

    //Function to Move Forward
    continue = position => e => {
		this.props.values.faculty_student = position;
		
		if(position == 'Faculty'){
			this.props.isFaculty();
		}else if(position == 'Student'){
			this.props.isStudent();
		}else{
			this.props.isStaff();
		}
		
        e.preventDefault();
        if(this.props.values.faculty_student.trim() == ""){
            this.setState({ errorText: "Please select role" });
        }else{
            this.setState({ errorText: "" });
            this.props.nextStep();
        }
    };

    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    isStudent = e => {
        e.preventDefault();
        this.props.isStudent();
    }
    isFaculty = e => {
        e.preventDefault();
        this.props.isFaculty();
    }

    render() {
        const {values, isFaculty, isStudent} = this.props;
        
        let options;
        if(values.faculty_student == 'Student'){
            options = <div class="sex_choice">
                <button className="faculty_button" 
                    value = "Faculty" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Faculty")}
                />
                <button className="student_button" 
                    value = "Student" 
                    style = {{
                        borderStyle: 'solid'
                    }} 
                    onClick = {this.continue("Student")}
                />
                <button className="staff_button" 
                    value = "Staff" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Staff")}
                />
            </div>;
        }else if(values.faculty_student == 'Faculty'){
            options = <div class="sex_choice">
                <button className="faculty_button" 
                    value = "Faculty" 
                    style = {{
                        borderStyle: 'solid'
                    }} 
                    onClick = {this.continue("Faculty")}
                />
                <button className="student_button" 
                    value = "Student" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Student")}
                />
                <button className="staff_button" 
                    value = "Staff" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Staff")}
                />
            </div>;
        }else if(values.faculty_student == 'Staff'){
            options = <div class="sex_choice">
                <button className="faculty_button" 
                    value = "Faculty" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Faculty")}
                />
                <button className="student_button" 
                    value = "Student" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Student")}
                />
                <button className="staff_button" 
                    value = "Staff" 
                    style = {{
                        borderStyle: 'solid'
                    }} 
                    onClick = {this.continue("Staff")}
                />
            </div>;
        }else{
            options=<div class="sex_choice">
                <button className="staff_button" 
                    value = "Staff" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Staff")}
                />
                <button className="faculty_button" 
                    value = "Faculty" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Faculty")}
                />
                <button className="student_button" 
                    value = "Student" 
                    style = {{
                        borderStyle: 'none'
                    }} 
                    onClick = {this.continue("Student")}
                />
            </div>;
        }

        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress"> 
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={4}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Personal Information</h2>
							</div>
                            <h1>Please select your position in the university.</h1>
                                {options}
							<div class="bot_nav">
								<button class = "button-backchoice" 
									primary = {true} 
									onClick = {this.back}>
										&#60; Back
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Faculty_Student;
