import Cookies from 'universal-cookie'
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
	Redirect
} from "react-router-dom";

import ChangePassword from './ChangePassword';
import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import Home from './Home';
import News from './News';
import Help from './Help';
import Report from './Report';
import LogIn from './LogIn';
import Logout from './Logout';
import Heatmap from './components/ReportsManagement/Heatmap';
import ViewReports from './components/ReportsManagement/ViewReports';
import MarkReportAsResponded from './components/ReportsManagement/MarkReportAsResponded';
import UpdateClassification from './components/ReportsManagement/UpdateClassification';
import UpdateQuarantineInfo from './components/ReportsManagement/UpdateQuarantineInfo';
import UpdateTestingInformation from './components/ReportsManagement/UpdateTestingInformation';
import TestedPositive from './components/ReportsManagement/TestedPositive';
import TestedNegative from './components/ReportsManagement/TestedNegative';
import DeleteReport from './components/ReportsManagement/DeleteReport';
import UpdateSymptoms from './components/ReportsManagement/UpdateSymptoms';
import UpdateComorbidity from './components/ReportsManagement/UpdateComorbidity';
import UpdateExposure from './components/ReportsManagement/UpdateExposure';
import UpdatePersonalInfo from './components/ReportsManagement/UpdatePersonalInfo';
import ViewCases from './components/CasesManagement/ViewCases';
import UpdateCaseInfo from './components/CasesManagement/UpdateCaseInfo';
import UpdateCaseAdmitted from './components/CasesManagement/UpdateCaseAdmitted';
import UpdateCaseRecovered from './components/CasesManagement/UpdateCaseRecovered';
import UpdateCaseDeceased from './components/CasesManagement/UpdateCaseDeceased';
import UpdateCaseQuarantined from './components/CasesManagement/UpdateCaseQuarantined';
import ViewPatientInfo from './components/CasesManagement/ViewPatientInfo';
import ViewPatientMonitoring from './components/PatientMonitoring/ViewPatientMonitoring';
import Update from './components/Update/Update';
import AddPatient from './components/ReportsManagement/AddPatient';
import AddHealthStatus from './components/PatientMonitoring/AddHealthStatus';
import UpdateAddressLatLong from './components/ReportsManagement/UpdateAddressLatLong';
import MainDashboard from './components/ReportsCharts/MainDashboard';
import ShowAddress from './components/ReportsManagement/ShowAddress';
import UpdateHealthStatus from './components/PatientMonitoring/UpdateHealthStatus';
import ViewPatients from './components/QuarantineBuddy/ViewPatients';
import QBMainBoard from './components/QuarantineBuddy/QBMainBoard';
import AdmitPatient_Form from './components/QuarantineBuddy/AdmitPatient_Form';
import UpdatePatientInfo from './components/QuarantineBuddy/UpdatePatientInfo';
import ViewTests from './components/Testing/ViewTests';
import AddTest from './components/Testing/AddTest';
import UpdateTest from './components/Testing/UpdateTest';
import GetStatus from './components/Status/Status';
import PersonnelChangePassword from './components/ChangePassword/PersonnelChangePassword';
import ForgotPassword from './components/ChangePassword/ForgotPassword';
import ResetPassword from './components/ChangePassword/ResetPassword';

import './App.css';
import packageJson from '../package.json';

const cookies = new Cookies();
cookies.set('app_version', packageJson.version);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sideDrawerOpen: false,
			loggedIn: cookies.get('user_email'),
			qbLoggedIn: cookies.get('qb_username'),
			currentUser: cookies.get('qb_username'),
			redirectTo: null,
			viewType: props.viewType,
			setOpen: false,
		};
	}

	componentDidMount(){
		if(!this.state.qbLoggedIn){
			if(this.state.viewType === 'qb'){
				this.setState({ redirectTo: '/qb-login'}, () => {this.setState({ redirectTo: null })});
			}
		}
	}

	drawerToggleClickHandler = () => {
		this.setState(prevState => {
			return { sideDrawerOpen: !prevState.sideDrawerOpen }
		})
	}

	backdropClickHandler = () => {
		this.setState({ sideDrawerOpen: false })
	}

	onLogout = () => {
	  if (process.env.NODE_ENV === 'development') {
		  cookies.remove('user_role');
		  cookies.remove('user_city');
		  cookies.remove('user_barangay');
		  cookies.remove('user_email');
	  }
	  this.setState({
		  loggedIn: false,
		  redirectTo: '/'
	  }, () => {
		  this.setState({redirectTo: null});
	  });
	}

	onLogin = user => {
	  this.setState({
		  loggedIn: true,
		  redirectTo: user.reset_password ? '/hsu-change-password' : cookies.get('user_role') === 'City Admin' ? '/' : '/view-reports',
	  }, () => {
		  this.setState({redirectTo: null});
	  });
	}

	onQBLogout = () => {
	  if (process.env.NODE_ENV === 'development') {
		  cookies.remove('qb_role');
		  cookies.remove('qb_id');
		  cookies.remove('qb_username');
		  cookies.remove('qb_reset_password');
		  cookies.remove('qb_terms_accepted');
		  cookies.remove('qb_assigned_facilities');
	  }
	  this.setState({
		  qbLoggedIn: false,
		  redirectTo: '/qb-login'
	  }, () => {
		  this.setState({redirectTo: null});
	  });
	}

	onQBLogin = user => {
		this.setState({
			qbLoggedIn: true,
			currentUser: cookies.get('qb_username'),
			redirectTo: user.reset_password ? '/qb-change-password' : '/qb-patients',
		}, () => {
			this.setState({redirectTo: null});
		});
	}

	onChangePasswordSuccess = () => {
		this.setState({
			redirectTo: '/view-reports',
		}, () => {
			this.setState({redirectTo: null});
		});
	}

	onQBChangePasswordSuccess = () => {
		this.setState({
			redirectTo: '/qb',
		}, () => {
			this.setState({redirectTo: null});
		});
	}
	
	handleClickOpen = () => {
        this.setState({
            setOpen: true
        });
    };
	
	handleClose = () => {
        this.setState({
            setOpen: false
        });
    };

  	render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
    	backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
	
    return (
	<body>
		<style>
			@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');
		</style>
		{(() => {
			if(this.state.viewType === 'qb'){
				return(
					<Router>
						<QBMainBoard 
							currentUser={this.state.currentUser}
							redirectTo={this.state.redirectTo}
							loggedIn={this.state.qbLoggedIn}
							onLogin={this.onQBLogin}
							onLogout={this.onQBLogout}
							onChangePasswordSuccess={this.onQBChangePasswordSuccess}
						/>
					</Router>
				);
			}else{
				return(
					<Router basename={process.env.REACT_APP_COVCHECK_ENV === 'testing' ? '/dev' : ''}>
						{ this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
						<div style={{ height: '100%' }}>
							<div className="App">
								<div className="app-header">
									<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
									<Toolbar drawerClickHandler={this.drawerToggleClickHandler} loggedIn={this.state.loggedIn}/>
									<SideDrawer show={this.state.sideDrawerOpen} loggedIn={this.state.loggedIn} click={this.backdropClickHandler}/>
									{backdrop}
								</div>
									<main style={{ marginTop: '70px' }}>
										<div>
											{cookies.get('user_role') === 'Admin' ?
											<Switch>
												<Route path="/login" render={() => (<LogIn
														onLogin={this.onLogin}
													/>)}/>
												{ this.state.loggedIn && <Route path = "/logout" render={() => (<Logout onLogout={this.onLogout} />)} /> }
												{ this.state.loggedIn && <Route path = "/hsu-change-password" render={() => (<ChangePassword onSuccess={this.onChangePasswordSuccess} />)} /> }
												<Route path = "/" exact render={() => (<Home loggedIn={this.state.loggedIn}/>)}/>
												<Route path = "/*" render={() => (<Home loggedIn={this.state.loggedIn}/>)}/>
											</Switch> :
											<Switch>
												<Route path="/login" render={() => (<LogIn
														onLogin={this.onLogin}
													/>)}/>
												{ this.state.loggedIn && <Route path = "/logout" render={() => (<Logout onLogout={this.onLogout} />)} /> }
												{ this.state.loggedIn && <Route path = "/hsu-change-password" render={() => (<ChangePassword onSuccess={this.onChangePasswordSuccess} />)} /> }
												<Route path = "/change-password" component = {PersonnelChangePassword}/>
												<Route path = "/password-reset" component = {(props) => <ResetPassword {...props} type='personnel'/>}/>
												<Route path = "/hsu-password-reset" component = {(props) => <ResetPassword {...props} type='admin'/>}/>
												<Route path = "/forgot-password" component = {(props) => <ForgotPassword {...props} type='personnel'/>}/>
												<Route path = "/hsu-forgot-password" component = {(props) => <ForgotPassword {...props} type='admin'/>}/>
												<Route path = "/" exact render={() => (<Home loggedIn={this.state.loggedIn} d_window={this.state.setOpen} handleClickOpen={this.handleClickOpen} handleClose={this.handleClose}/>)}/>
												<Route path = "/news" component = {News}/>
												<Route path = "/help" component = {Help}/>
												<Route path = "/report" component = {Report}/>
												<Route path = "/update" component = {Update} />
												<Route path = "/getstatus" component = {GetStatus} />
												{ this.state.loggedIn && <Route path = "/view-reports/heatmap" component = {Heatmap}/> }
												{ this.state.loggedIn && <Route path = "/view-reports" component = {ViewReports}/> }
												{ this.state.loggedIn && <Route path = "/report-mark-responded/:id/:classification" component = {MarkReportAsResponded}/> }
												{ this.state.loggedIn && <Route path = "/update-classification/:id/:classification" component = {UpdateClassification}/> }
												{ this.state.loggedIn && <Route path = "/update-quarantine-info/:id" component = {UpdateQuarantineInfo}/> }
												{ this.state.loggedIn && <Route path = "/update-testing-info/:id" component = {UpdateTestingInformation}/> }
												{ this.state.loggedIn && <Route path = "/tested-positive/:id" component = {TestedPositive} /> }
												{ this.state.loggedIn && <Route path = "/tested-negative/:id" component = {TestedNegative} /> }
												{ this.state.loggedIn && <Route path = "/update-report-symptoms/:id" component = {UpdateSymptoms} /> }
												{ this.state.loggedIn && <Route path = "/update-report-comorbidity/:id" component = {UpdateComorbidity} /> }
												{ this.state.loggedIn && <Route path = "/update-report-exposure/:id" component = {UpdateExposure} /> }
												{ this.state.loggedIn && <Route path = "/update-report-personal-info/:id" component = {UpdatePersonalInfo} /> }
												{ this.state.loggedIn && <Route path = "/update-report-address-lat-long/:id" component = {UpdateAddressLatLong} /> }
												{ this.state.loggedIn && <Route path = "/show-report-address-lat-long/:id" component = {ShowAddress} /> }
												{ this.state.loggedIn && <Route path = "/view-cases" render={() => (<ViewCases />)}/> }
												{ this.state.loggedIn && <Route path = "/update-case-info/:id" component = {UpdateCaseInfo}/> }
												{ this.state.loggedIn && <Route path = "/update-case-admitted/:id" component = {UpdateCaseAdmitted} /> }
												{ this.state.loggedIn && <Route path = "/update-case-quarantined/:id" component = {UpdateCaseQuarantined} /> }
												{ this.state.loggedIn && <Route path = "/update-case-recovered/:id" component = {UpdateCaseRecovered} /> }
												{ this.state.loggedIn && <Route path = "/update-case-deceased/:id" component = {UpdateCaseDeceased} /> }
												{ this.state.loggedIn && <Route path = "/view-personal-info/:id" component = {ViewPatientInfo} /> }
												{ this.state.loggedIn && <Route path = "/patient-monitoring/:id" component = {ViewPatientMonitoring} /> }
												{ this.state.loggedIn && <Route path = "/add-health-status/:report_id" component = {AddHealthStatus} /> }
												{ this.state.loggedIn && <Route path = "/update-health-status/:id" component = {UpdateHealthStatus} /> }
												{ this.state.loggedIn && <Route path = "/add-patient/:classification" component = {AddPatient} /> }
												{ this.state.loggedIn && <Route path = "/charts" component = {MainDashboard} /> }
												{ this.state.loggedIn && <Route path = "/report-tests/:id" component = {ViewTests} /> }
												{ this.state.loggedIn && <Route path = "/report-add-covid-test/:report_id" component = {AddTest} /> }
												{ this.state.loggedIn && <Route path = "/report-update-covid-test/:report_id/:test_id" component = {UpdateTest} /> }
											</Switch>}
										</div>
									</main>
							</div>
						</div>
					</Router>
				);
			 }
		  })()}
	  </body>
    )
  }
}

export default App
