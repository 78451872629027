import React from 'react';
import axios from 'axios';
import moment from 'moment';

import Cookies from 'universal-cookie';
import banner from './DashboardCards/undraw_medicine.svg';
import DashboardHeatmap from './DashboardCards/DashboardHeatmap';
import NumbersCard from './DashboardCards/NumbersCard';
import ReportsCard from './DashboardCards/ReportsCard';

import SexDistributionCard from './DashboardCards/SexDistributionCard';
import AgeDistributionCard from './DashboardCards/AgeDistributionCard';
import ReportedPlacesCard from './DashboardCards/ReportedPlacesCard';
import GraphStatusCard from './DashboardCards/GraphStatusCard';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DateIcon from '@material-ui/icons/DateRange';

import {
	Button, Divider, Grid, Paper, Typography, IconButton, Tooltip, Box
} from '@material-ui/core';
import styled from 'styled-components';

const Table = styled.table`
	td {
		vertical-align: top;
		padding: 5px;
	}
`;

const cookies = new Cookies();

class CoVcheckDashboard extends React.Component {
	state={
		userRole: cookies.get('user_role'),
		reports: [],
		safe: 0,
		ili: 0,
		sari: 0,
		pum: 0,
		not_pending: 0,
		pending: 0,
		total: 0,

		ageDistribution: 0,
		age_group: [],
		sexDistribution: 0,
		female: 0,
		male: 0,
		departments: [],
		pumCases: [],
		sariCases: [],
		iliCases: [],
		safeCases: [],
		datesAll: [],

		tooltipText: 'Expand Map',
		expand: true,
		heatmapWidth: '95vw',
		heatmapHeight: '90vh',
		heatmapBp: 4,
		heatmapCase: "",
		heatmapCaseValue: 0
	};

	componentDidMount(){
		let url = `/api/reports?limit=999999999&offset=0`;
		
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ 
						reports: result.data.reports
					}, () => {
						this.setNumbers();
						this.getSexDistribution();
						this.getAgeDistribution();
						this.getPlacesReports();
						this.getStatusRecord();
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({ 
						reports: result.data.reports
					}, () => {
						this.setNumbers();
						this.getSexDistribution();
						this.getAgeDistribution();
						this.getPlacesReports();
						this.getStatusRecord();
					});
				});
		}
		this.setState({ expand: false, heatmapWidth: '39vw', heatmapHeight: '69vh' });
	}

	onExpand = () => {
		this.setState({
			expand: this.state.expand ? false : true,
		}, () => {
			this.setState({
				heatmapWidth: this.state.expand ? '95vw' : '39vw',
				heatmapHeight: this.state.expand ? '90vh' : '69vh',
				heatmapBp: this.state.expand ? 8 : 4,
				tooltipText:  this.state.expand ? 'Exit Expanded Map' : 'Expand Map',
			});
		});
	}
	
	getSexDistribution = () => {
		var male = 0;
		var female = 0;
		var ctr = 0;
		
		this.state.reports.map(p => {
			if(p.status != 0){
				if (p.sex == 'Female'){
					female += 1;
				} else {
					male += 1;
				}
			}
		});
		this.setState({ male, female});
	}
	
	getAgeDistribution = () => {
		var countA = 0;
		var countB = 0;
		var countC = 0;
		var countD = 0;
		var countE = 0;
		var countF = 0;
		var age = 0;
		let ageValue = [];
		this.state.reports.map(p => {
			if(p.status != 0) {
				age = p.age;
				if(age >= 0 && age < 15){
					countA = countA + 1;
				}else if(age >= 15 && age < 30){
					countB = countB + 1;
				}else if(age >= 30 && age < 45){
					countC = countC + 1;
				}else if(age >= 45 && age < 60){
					countD = countD + 1;
				}else if(age >= 60 && age < 75){
					countE = countE + 1;
				}else if(age >= 75 && age <= 100){
					countF = countF + 1;
				}else{
					
				}
			} 
				
		});
		
		ageValue.push(countA);
		ageValue.push(countB);
		ageValue.push(countC);
		ageValue.push(countD);
		ageValue.push(countE);
		ageValue.push(countF);
		this.setState({age_group: ageValue});
	}
	
	getPlacesReports = () => {
		var temp ="";
		var index = 0;
		var deptObj = [];
		
		this.state.reports.map(p => {
			if(p.status != 0) {
				temp = p.department;
				
				if(deptObj.some(o => o.label === temp)){
					index = deptObj.findIndex(o => o.label === temp);
					deptObj[index].value = deptObj[index].value + 1;
				} else{
					deptObj.push({
						label: temp,
						value: 1
					});
				}
			}
		});
	
		this.setState({departments : deptObj.sort(this.compare)});
	}
	
	compare(a, b) {
		const bandA = a.label.toUpperCase();
		const bandB = b.label.toUpperCase();

		let comparison = 0;
		if (bandA > bandB) {
			comparison = 1;
		} else if (bandA < bandB) {
			comparison = -1;
		}
		return comparison;
	}
	
	
	re_sort = (arr, dates) => {
		var temp_arr = [];
		
		dates.map(a => {
			arr.map(b => {
				if(a.label == b.label) {
					temp_arr.push({
						label: b.label,
						value: b.value
					});
				}
			})
		})
		
		return temp_arr;
	}
	/*
		REPORTS STATUS:
			0 - PENDING
			1 - ALLOWED
			2 - NOT ALLOWED
	*/

	setNumbers = () => {
		let safe = 0;
		let ili = 0;
		let sari = 0;
		let pum = 0;
		let not_pending = 0;
		let pending = 0;
		let total = 0;
		
		this.state.reports.map(r => {
			if(r.status == 1 || r.status == 2){ //NOT PENDING
				if(r.classification == 'SAFE-1' || r.classification == 'SAFE-2') {
					safe += 1;
				} else if(r.classification == 'ILI-1' || r.classification == 'ILI-2A' || r.classification == 'ILI-2B') {
					ili += 1;
				} else if(r.classification == 'SARI') {
					sari += 1;
				} else {
					pum += 1;
				}
				
				not_pending += 1;
			}else{ //PENDING 
				pending += 1;
			}
			total += 1;
		});
		
		this.setState({
			safe, ili, sari, pum, not_pending, pending, total
		});
	}

	getThisDate(dateString) {
		var date = new Date(dateString)
		var dateTemp = date.getDate();
		var monthTemp = date.getMonth() + 1;
		var yearTemp = date.getFullYear();

		var dateStr = dateTemp + "/" + monthTemp + "/" + yearTemp;
		return dateStr;
	}
	
	removeYear(dateString) {
		var date = new Date(dateString)
		var dateTemp = date.getDate();
		var monthTemp = date.getMonth() + 1;
		var yearTemp = date.getFullYear();

		var dateStr = + monthTemp + "/" + dateTemp + "/" + yearTemp;
		return dateStr;
	}

	construct15days = () => {
		var days = 10;
		var date = new Date();
		var _15daysPrior = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
		var count = 1;
		var dates = [];
		dates.push({label: this.getThisDate(_15daysPrior)});
		while(count <= 10){
			var newDate = new Date(_15daysPrior.getTime() + (count * 24 * 60 * 60 * 1000));
			dates.push({label: this.getThisDate(newDate)});
			count = count + 1;
		}
		return dates;
	}
	
	getStatusRecord = () => {
		var temp = 0;
		var index = 0;
		var ctr = 0;
		var safeObj = [];
		var iliObj = [];
		var sariObj = [];
		var pumObj = [];
		
		var dates = this.construct15days();
		var dateStr = "";
		var testingDateStr = "";
		
		dates.map(currDate => {
			this.state.reports.map(p => {
				index = 0;
				temp = p.classification;
				
				if(p.status != 0){ //NOT PENDING
					dateStr = this.getThisDate(p.date_responded);
					
					if(currDate.label === dateStr){
						if(temp == 'SAFE-1' || temp == 'SAFE-2') { //safe
							if(safeObj.some(o => o.label === dateStr)){
								index = safeObj.findIndex(o => o.label === dateStr);
								safeObj[index].value = safeObj[index].value + 1;
							} else{
								safeObj.push({
									label: dateStr,
									value: 1
								});
							}
						} else if(temp == 'ILI-1' || temp == 'ILI-2A' || temp == 'ILI-2B') { //safe
							if(iliObj.some(o => o.label === dateStr)){
								index = iliObj.findIndex(o => o.label === dateStr);
								iliObj[index].value = iliObj[index].value + 1;
							} else{
								iliObj.push({
									label: dateStr,
									value: 1
								});
							}
						} else if(temp == 'SARI') { //safe
							if(sariObj.some(o => o.label === dateStr)){
								index = sariObj.findIndex(o => o.label === dateStr);
								sariObj[index].value = sariObj[index].value + 1;
							} else{
								sariObj.push({
									label: dateStr,
									value: 1
								});
							}
						} else if(temp == 'PUM') { //safe
							if(pumObj.some(o => o.label === dateStr)){
								index = pumObj.findIndex(o => o.label === dateStr);
								pumObj[index].value = pumObj[index].value + 1;
							} else{
								pumObj.push({
									label: dateStr,
									value: 1
								});
							}
						} 
					}
				}
			});
		});
		
		var check = false;
		dates.map(p => {
			check = safeObj.some(n => n.label === p.label);
			if(!check){
				safeObj.push({
					label: p.label,
					value: 0
				});
			}

			check = iliObj.some(n => n.label === p.label)
			if(!check){
				iliObj.push({
					label: p.label,
					value: 0
				});
			}

			check = sariObj.some(n => n.label === p.label)
			if(!check){
				sariObj.push({
					label: p.label,
					value: 0
				});
			}
			
			check = pumObj.some(n => n.label === p.label)
			if(!check){
				pumObj.push({
					label: p.label,
					value: 0
				});
			}
			
		});
		
		var safeObj_array = this.re_sort(safeObj, dates);
		var iliObj_array = this.re_sort(iliObj, dates);
		var sariObj_array = this.re_sort(sariObj, dates);
		var pumObj_array = this.re_sort(pumObj, dates);
		var dates_array = [];
		
		dates.map(p => {
			var ctrD = 1;
			var strD = "";
			var strArr = p.label.split('/');
			strArr.map(q => {
				if(ctrD == 1) {
					strD = q ;
					ctrD = ctrD + 1;
				} else if(ctrD == 2) {
					strD = q + "/" + strD;
					ctrD = ctrD + 1;
				} else {
					ctrD = 1;
					dates_array.push({
						label: strD
					});
				}
			});
		});
		
		this.setState({
			safeCases: safeObj_array,
			iliCases: iliObj_array,	
			sariCases: sariObj_array,	
			pumCases: pumObj_array,	
			datesAll: dates_array
		});
	}

	render(){
		return(
			<>
			{(() => {
				if(this.state.expand){
					return(
						<div>
							{(() => {
								if(this.state.userRole === 'City' || this.state.userRole === 'City Admin'){
									return(
										<Paper variant="elevation16" elevation={10}>
											<div className="dashboard-heatmap">
												<DashboardHeatmap tooltipText={this.state.tooltipText} onExpand={this.onExpand} width={this.state.heatmapWidth} height={this.state.heatmapHeight} filteredbyCase = {this.state.userCity}/>
											</div>
										</Paper>
									);
								} else {
									return(
										<Paper variant="elevation16" elevation={10}>
											<div className="dashboard-heatmap">
												<DashboardHeatmap tooltipText={this.state.tooltipText} onExpand={this.onExpand} width={this.state.heatmapWidth} height={this.state.heatmapHeight} filteredbyCase = {this.state.userBrgy}/>
											</div>
										</Paper>
									);
								}
							})()}
						</div>
					);
				}else{
					return(
						<Table className="cc-dashboard-table" >
							<tbody>
								<tr valign="top">
									<td className="left-align-text" style={{paddingTop: 5}}  >
										<Box
											borderLeft={4}
											borderColor='#212121'
											borderRadius={5}
										>
										
										<Paper style={{padding: 5}} variant="elevation2" elevation={10}>
											<div className="card-title" >AS OF:</div>
											<Divider style={{ backgroundColor: '#f0f0f0'}}/>
											<div className="cc-content-date" align='center' style={{ color: '#303030', paddingBottom: 1, paddingTop: 1}}>
												{moment(new Date()).format('MMM DD')}<br/>
												<div className="cc-content-time" align='center' style={{ color: '#303030', paddingBottom: 1, paddingTop: 1}}>
													{moment(new Date()).format(' h:mm a')}
												</div>
											</div>
										</Paper>
										</Box>
									</td>
									<td>
										<NumbersCard value={this.state.total} label={'TOTAL'} color={'#556CB3'}/>
									</td>
									<td>
										<NumbersCard value={this.state.safe} label={'SAFE'} color={'#3AD5C1'}/>
									</td>
									<td>
										<NumbersCard value={this.state.ili} label={'ILI'} color={'#b270e5'}/>
									</td>
									<td>
										<NumbersCard value={this.state.sari} label={'SARI'} color={'#e56f66'}/>
									</td>
									<td>
										<NumbersCard value={this.state.pum} label={'PUM'} color={'#8abcd0'}/>
									</td>
									<td>
										<NumbersCard value={this.state.pending} label={'PENDING'} color={'#74e3e3'}/>
									</td>
								</tr>
								<tr valign="top">
									<td colspan='2' className="dashboard-maxWidth">
										<GraphStatusCard 
											dates={this.state.datesAll} 
											pumData={this.state.pumCases} 
											sariData={this.state.sariCases} 
											iliData={this.state.iliCases} 
											safeData={this.state.safeCases} />
									</td>
									{(() => {
										if(this.state.userRole === 'City' || this.state.userRole === 'City Admin'){
											return(
												<td colspan='3' rowspan='2'>
													<Paper variant="elevation16" elevation={10}>
														<div className="dashboard-heatmap">
															<DashboardHeatmap tooltipText={this.state.tooltipText} onExpand={this.onExpand} width={this.state.heatmapWidth} height={this.state.heatmapHeight} filteredbyCase = {this.state.userCity}/>
														</div>
													</Paper>
												</td>
											);
										} else {
											return(
												<td colspan='3' rowspan='2'>
													<Paper variant="elevation16" elevation={10}>
														<div className="dashboard-heatmap">
															<DashboardHeatmap tooltipText={this.state.tooltipText} onExpand={this.onExpand} width={this.state.heatmapWidth} height={this.state.heatmapHeight} filteredbyCase = {this.state.userBrgy}/>
														</div>
													</Paper>
												</td>
											);
										}
									})()}
									<td colspan='2'>
										<AgeDistributionCard age_group={this.state.age_group} />
									</td>
								</tr>
								<tr valign="top">
									<td colspan='2' >
										<ReportedPlacesCard dept={this.state.departments} />
									</td>
									<td colspan='2'>
										<SexDistributionCard label={'GENDER DISTRIBUTION'} male_count={this.state.male} female_count={this.state.female}/>
									</td>
								</tr>
							</tbody>
						</Table>
					);
				}
			})()}
			</>
		);
	}
}

export default CoVcheckDashboard;