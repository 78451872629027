import React, { Component } from 'react';
import axios from 'axios';
import '../../components/QuarantineBuddy/QB_Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SelectReact from 'react-select';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 400;

const Table = styled.table`
	padding: 5px;	

	td {
		padding: 5px;
	}
`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const BlueCheckbox = withStyles({
  root: {
    color: '#4C6FEE',
    '&$checked': {
      color: '#4C6FEE',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyleRadio = withStyles({
    root: {
      color: '#4C6FEE',
      '&$checked': {
        color: '#4C6FEE',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const customStyles = {
  control: (base, state) => ({
      ...base,
	 background: 'white',
	 height: 56,
      borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
      "&:hover": {
        borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
      }
    })
}

const type_options = [
	{ value: 'CBC', label: 'CBC' },
	{ value: 'Urinalysis', label: 'Urinalysis' },
	{ value: 'COVID Rapid Test', label: 'COVID Rapid Test' },
	{ value: 'COVID RT-PCR', label: 'COVID RT-PCR' }
]

const result_options = [
	{ value: 'Pending', label: 'Pending' },
	{ value: 'Negative', label: 'Negative' },
	{ value: 'Positive', label: 'Positive' }
]

class AddTest extends Component {
	state = {
		reportId: this.props.match.params.report_id,
		report: {},
		test: {},
		selectedType: { value: 'CBC', label: 'CBC' },
		selectedResult: { value: 'Pending', label: 'Pending' },
		image: null,
		disabled: false,
		updatePatientStatus: true,
    }
	
	componentDidMount() {
		window.scrollTo(0, 0);
        const reportId = this.props.match.params.report_id;
		if (process.env.NODE_ENV === 'development') {
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
                    this.setState({ 
                       report: result.data
                    });
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/reports/${reportId}` : `https://covidcheck.upcebu.edu.ph/api/reports/${reportId}`;
			axios(url, {withCredentials: true})
				.then(result => {
                    this.setState({ 
                       report: result.data
                    });
				});
		}
		const test = {};
		test.date_time = new Date();
		test.type = 'CBC';
		test.result = '';
		test.remarks = '';
		test.deleted = false;
		this.setState({ test });
	};

	fileSelectedChange = event => {
		this.setState({
			image: event.target.files[0]
		});
	}
	
	handleTypeChange = selectedOption  => {
		const test = this.state.test;
		test.type = selectedOption.value;
		this.setState({ 
			test: test,
			selectedType: selectedOption
		});
	}

	handleResultChange = selectedOption  => {
		const test = this.state.test;
		test.result = selectedOption.value;
		this.setState({ 
			test: test,
			selectedResult: selectedOption
		});
	}

	handleFieldChange = input => e => {
		const test = this.state.test;
		test[input] = e.target.value;
        this.setState({ test });
	}

	handleCheckChange = name => e => {
        this.setState({[name]: e.target.checked});
    };

	handleSave = e => {
		var valid = true;

		if(valid){
            this.setState({disabled: true});
			const test = this.state.test;
			var bodyFormData = new FormData();
	        bodyFormData.append('date_time', (test.date_time instanceof Date) ? test.date_time.toISOString() : test.date_time);
	        bodyFormData.append('type', test.type);
	        bodyFormData.append('result', test.result);
	        bodyFormData.append('remarks', test.remarks);
	        bodyFormData.append('image', this.state.image);
	        bodyFormData.append('deleted', false);
			const username = 'testing@example.com';
			const password = 'p@ssw0rd';
	        if (process.env.NODE_ENV === 'development') {
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
	            axios({
	                method: 'post',
	                url:  `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.reportId}/tests`,
	                data: bodyFormData,
	                headers: {'Authorization': `Basic ${token}`}
	                })
	                .then((response) => {
                		this.props.history.push(`/patient-monitoring/${this.state.reportId}`);
	                })
	                .catch((response) => {
						this.setState({disabled: false});
	                });
	        }else{
				const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
	            const reportUpdatesUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?  `https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.reportId}/tests` :  `https://covidcheck.upcebu.edu.ph/api/reports/${this.state.reportId}/tests`;
				axios({
		                method: 'post',
		                url: reportUpdatesUrl,
		                data: bodyFormData,
		                withCredentials: true
	                })
	                .then((response) => {
                		this.props.history.push(`/patient-monitoring/${this.state.reportId}`);
	                })
	                .catch((response) => {
						this.setState({disabled: false});
	                });
			}
		}
    };

    dateStr = date_testing => {
		if(date_testing == null){
			date_testing = new Date();
		}
		const dateNow = new Date(date_testing);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}
	
    render() {
		const classes = {margin:1};
        return (
        	<div className="for_select" style={{ width: 350 }}>
				<Paper style = {{ padding: 10 }}>
					<Grid item xs={24} md={12} lg={12} xl={12}>
	                    <Table className="adPatient-table">
						    <tbody>
							<tr>
								<td>
									<TextField
										disabled 
										value = {this.state.report.name}
										id="outlined-basic" 
										label="Name" 
										variant="outlined" 
										fullWidth={true}
										className={classes.margin}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
							</tr>
							</tbody>
						</Table>
						<Divider/>
						<br/>
						<Typography component="h2" variant="h6" color="primary" align='left' style={{paddingLeft:10}}>
	                        Test Information
	                    </Typography>
						<Table className="adPatient-table">
						    <tbody>
						    <tr>
								<td>
									<TextField 
										value = {this.dateStr(this.state.test.date_time)}
										onChange={this.handleFieldChange('date_time')}
										id="outlined-basic" 
										label="Testing Date" 
										variant="outlined" 
										fullWidth={true}
										type='date'
										className={classes.margin}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="textSecondary" style={{ paddingLeft: 5 }} align='left' gutterBottom>
										Type of Test
									</Typography>
									<SelectReact
										styles={customStyles}
										options={type_options}
										value={this.state.selectedType}
										searchable
										placeholder="Type of Test"
										fullWidth={true}
										onChange={this.handleTypeChange}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<Typography component="h1" variant="subtitle1" color="textSecondary" style={{ paddingLeft: 5 }} align='left' gutterBottom>
										Result
									</Typography>
									{this.state.selectedType.value.includes('COVID') ?
									<SelectReact
										styles={customStyles}
										options={result_options}
										value={this.state.selectedResult}
										searchable
										placeholder="Result"
										fullWidth={true}
										onChange={this.handleResultChange}
									/> : 
									<TextField 
										onChange={this.handleFieldChange('result')}
										value = {this.state.test.result}
										id="outlined-basic" 
										variant="outlined"  
										fullWidth={true}
										className={classes.margin}
										InputLabelProps={{
											shrink: true,
											className: classes.floatingLabelFocusStyle,
										}}
									/>}
								</td>
							</tr>
							</tbody>
						</Table>
						<Typography component="h1" variant="subtitle1" color="textSecondary" style={{ paddingLeft: 20 }} align='left'>
							Supporting Image
						</Typography>
						<div style={{ padding: 5 }}>
							<input class="custom-file-upload" style = {{width: '87%', height: '30px'}} type = 'file' onChange = {this.fileSelectedChange} accept="image/png, image/jpeg"/>
						</div>
						<Table className="adPatient-table">
						    <tbody>
							<tr>
								<td>
								<TextField 
									onChange={this.handleFieldChange('remarks')}
									value = {this.state.test.remarks}
									id="outlined-basic" 
									label="Remarks" 
									variant="outlined"  
									fullWidth={true}
	                                multiline
	                                rows={3}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
								/>
								</td>
							</tr>
							</tbody>
						</Table>
						<Divider/>
						<br/>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<div align='left'>
						        	<Button align='left' size="large" variant="outlined" color='secondary'
						        			fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
						        			onClick={() => this.props.history.push(`/patient-monitoring/${this.state.reportId}`) }>
					        			CANCEL</Button>
								</div>
							</Grid>
							<Grid item xs={12} md={6}>
								<div align='right' style={{ paddingBottom: 10 }}>
									<Button
										fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
										size='large'
										variant='contained'
	                                    color='primary'
	                                    disabled={this.state.disabled}
										onClick = {this.handleSave}>
											{this.state.disabled? 'ADDING...' : 'ADD'}
									</Button>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</div>
        );
    };
}

export default AddTest;