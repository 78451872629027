import MarkerClusterGroup from 'react-leaflet-markercluster';
import React from 'react';
import {Map, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';

import Control from 'react-leaflet-control';
import Choropleth from 'react-leaflet-choropleth';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	Paper, Checkbox, FormControlLabel, Typography, Box, Radio, IconButton, Tooltip, Divider
} from '@material-ui/core';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';

const style = {
    fillColor: '#ffe0d6',
    weight: 2,
    opacity: 0.7,
    color: '#5c5b59',
    dashArray: '3',
    fillOpacity: 0.8
}

const BlueCheckbox = withStyles({
  root: {
    color: '#556CB3',
    '&$checked': {
      color: '#556CB3',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyleRadio = withStyles({
  root: {
    color: '#556CB3',
    '&$checked': {
      color: '#556CB3',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BarangayPopup = ({feature, status}) => {
	return(
		<Popup minWidth={150}>
			{status === '0' ? 
				<span>
					<Typography component="h1" variant="body1" color="primary" gutterBottom>
						{feature.properties.NAME_3} ({feature.properties.total})
					</Typography>
					<Divider/>
					<Typography component="h1" variant="body2" color="textSecondary" style={{paddingTop:5}}>
						SAFE - {feature.properties.safe}
					</Typography>
					<Typography component="h1" variant="body2" color="textSecondary" style={{paddingTop:5}}>
						ILI - {feature.properties.ili}
					</Typography>
					<Typography component="h1" variant="body2" color="textSecondary" style={{paddingTop:5}}>
						SARI - {feature.properties.sari}
					</Typography>
					<Typography component="h1" variant="body2" color="textSecondary" style={{paddingTop:5}}>
						PUM - {feature.properties.pum}
					</Typography>
					
				</span>
			:
				<span>
					<Typography component="h1" variant="body2" color="primary" gutterBottom>
						{feature.properties.NAME_3} ({feature.properties.numberOfReports})
					</Typography>	
				</span>
			}
		</Popup>
	);
}

const HeatmapComponent = props => {
	const [showClusters, setShowClusters] = React.useState(true);
	const [showHeatmap, setShowHeatmap] = React.useState(false);
	const [status, setStatus] = React.useState('0');
	const colorScale = ['#ffc0ad', '#ff9e83', '#ff7858', '#ff4327'];

	const handleChangeStatus = (event) => {
		const value = event.target.value;
		setStatus(value);
		props.handleChangeCase(value);
	}

	const handleCheckChange = field => event => {
		if(field === 'show_clusters'){
			setShowClusters(event.target.checked);
		}else if(field === 'show_heatmap'){
			setShowHeatmap(event.target.checked);
		}
	}

	return (
		<>
			<Map
				zoomControl={false}
				zoom={12}
				maxZoom={19}
				center={[props.center_lat,props.center_lng]}
				style={props.dimension}
			>
				<Control position="topleft">
					<Tooltip title={props.tooltipText}>
						<IconButton color="primary" variant='outlined' component="span" onClick={props.onExpand}>
							<AspectRatioIcon />
						</IconButton>
					</Tooltip>
				</Control>
				<Control position="topright">
					<Paper style={{opacity:0.8, borderRadius:8}}>
					<Box
						border={1}
						borderRadius={8}
						style={{paddingLeft: 10, paddingRight:15, paddingBottom:5}}>
						<div align='left'>
							<Typography variant='body2' color='primary' align='center' style={{paddingTop:5, paddingBottom: 5, fontWeight: 'bold'}}>Layers</Typography>
							<input onClick={handleCheckChange('show_clusters')} type="checkbox" id="show_clusters" name="show_clusters" value="show_clusters" checked={showClusters ? true : false}></input>
							<label for="show_clusters" style={{paddingLeft: 8}}>Clusters</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleCheckChange('show_heatmap')} type="checkbox" id="show_heatmap" name="show_heatmap" value="show_heatmap" checked={showHeatmap ? true : false}></input>
							<label for="show_heatmap" style={{paddingLeft: 8}}>{props.userRole === 'Barangay' ? 'Boundary' : 'Heatmap'}</label>
	                    </div>
                    </Box>
                    </Paper>
				</Control>

				{(showClusters || showHeatmap) ?
				<Control position="topright">
					<Paper style={{opacity:0.8, borderRadius:8}}>
					<Box
						border={1}
						borderRadius={8}
						style={{paddingLeft: 5, paddingRight:5, paddingBottom:5}}>
						<div align='left'>
							<Typography variant='body2' color='primary' align='center' style={{paddingTop:5, paddingBottom: 5, fontWeight: 'bold'}}>Classification</Typography>
							<input onClick={handleChangeStatus} type="radio" id="all" name="all" value="0" checked={status === '0' ? true : false}></input>
							<label for="all" style={{paddingLeft: 5}}>All</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleChangeStatus} type="radio" id="safe-1" name="safe-1" value="1" checked={status === '1' ? true : false}></input>
							<label for="safe-1" style={{paddingLeft: 5}}>SAFE-1</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleChangeStatus} type="radio" id="safe-2" name="safe-2" value="2" checked={status === '2' ? true : false}></input>
							<label for="safe-2" style={{paddingLeft: 5}}>SAFE-2</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleChangeStatus} type="radio" id="ili-1" name="ili-1" value="3" checked={status === '3' ? true : false}></input>
							<label for="ili-1" style={{paddingLeft: 5}}>ILI-1</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleChangeStatus} type="radio" id="ili-2A" name="ili-2A" value="4" checked={status === '4' ? true : false}></input>
							<label for="ili-2A" style={{paddingLeft: 5}}>ILI-2A</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleChangeStatus} type="radio" id="sari" name="sari" value="5" checked={status === '5' ? true : false}></input>
							<label for="sari" style={{paddingLeft: 5}}>SARI</label><br/>
							<div style={{padding: 2}}/>
							<input onClick={handleChangeStatus} type="radio" id="pum" name="pum" value="6" checked={status === '6' ? true : false}></input>
							<label for="pum" style={{paddingLeft: 5}}>PUM</label><br/>
							
	                    </div>
                    </Box>
                    </Paper>
				</Control>
				 : null }

				{showHeatmap ?
				<Control position="bottomright">
					<Paper style={{padding: 5, opacity: 0.9}}>
						<div>
							<div class='my-legend'>
								<div class='legend-title'>No. of Cases</div>
								<div class='legend-scale'>
									{
										props.intervals.length === 1 ? 
										<ul class='legend-labels'>
											<li><span style={{background:'#ffe0d6', opacity: 0.8, opacity: 0.7}}></span>0</li>
											<li><span style={{background:colorScale[3], opacity: 0.8}}></span>{props.intervals[0]}</li>
										</ul>
										:
										<ul class='legend-labels'>
											<li><span style={{background:'#ffe0d6', opacity: 0.8, opacity: 0.7}}></span>0</li>
											<li><span style={{background:colorScale[0], opacity: 0.8}}></span>{props.intervals[0]}</li>
											<li><span style={{background:colorScale[1], opacity: 0.8}}></span>{props.intervals[1]}</li>
											<li><span style={{background:colorScale[2], opacity: 0.8}}></span>{props.intervals[2]}</li>
											<li><span style={{background:colorScale[3], opacity: 0.8}}></span>{props.intervals[3]}</li>
										</ul> 
									}
								</div>
							</div>
						</div>
					</Paper>
				</Control>
				 : null }

				{showClusters ?
				<MarkerClusterGroup
					singleMarkerMode={true}>
					{ props.points.map((point, idx) => (<Marker key={idx} position={point} />)) }
				</MarkerClusterGroup>
				: null }
				{showHeatmap ?
				<Choropleth
					data={{type: 'FeatureCollection', features: props.features}}
					visible={(feature) => feature.properties.numberOfReports !== 0}
					valueProperty={(feature) => feature.properties.numberOfReports}
					scale={colorScale}
					steps={4}
					mode='e'
					style={style}
					onEachFeature={(feature, layer) => layer.bindPopup(feature.properties.NAME_3)}
				>
					<BarangayPopup status={status}/>
				</Choropleth>
				: null }

				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
			</Map>
		</>
	);
}

export default HeatmapComponent;
