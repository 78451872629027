import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';
import PatientActionMenu from './PatientActionMenu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {isMobile} from 'react-device-detect';

import styled from 'styled-components';

import Select from 'react-select';

import {
	Button, Divider, Paper, Typography, Grid, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mobilePhonesBreakPoint = 450;

const cookies_key = {
	user_role: 'qb_role',
	user_id: 'qb_id',
	selected_facility: 'qb_selected_facility',
	selected_status: 'qb_selected_status',
	num_of_days: 'qb_num_of_days'
};

const Table = styled.table`
	padding: 5px;	

	td {
		padding: 5px;
		vertical-align: center;
	}
`;

const customStyles = {
	headCells: {
		style: {
			textAlign: 'center',
		 	color: '#202124',
		  	fontSize: '12px',
		  	fontWeight: 'bold',
		},
	},
};

const selectStyles = {
	menu: base => ({
		...base,
		zIndex: 100
	})
};

const status_options = [
	{value:'', label:'Status (All)'},
	{value:'Admitted', label:'Admitted'},
	{value:'Discharged', label:'Discharged'},
	{value:'Recovered', label:'Recovered'},
	{value:'Completed Home Quarantine', label:'Completed Home Quarantine'},
];

class ViewPatients extends React.Component {
	state = {
		userDoctor: {},
		patients: [],
		doctors: [],
		doctorsOption: [],
		loading: true,
		totalRows: 0,
		perPage: 10,
		page: 1,
		pagination:true,
		userRole: '',
		userId: -1,

		selectedFacility: {value: -1, label: 'Facility (All)'},
		selectedStatus: {value:'', label:'Status (All)'},
		filterNumberOfDays: 0,
		errorFilterNumberOfDays: false,

		setOpen: cookies.get('qb_terms_accepted') === 'true' ? false : true,
		openFilters: true,
	};

	componentDidMount(){
		window.scrollTo(0,0);
		const userRole = cookies.get(cookies_key.user_role);
		const userId = cookies.get(cookies_key.user_id);
		const _selectedFacility = cookies.get(cookies_key.selected_facility);
		const _selectedStatus = cookies.get(cookies_key.selected_status);
		const _filterNumberOfDays = cookies.get(cookies_key.num_of_days);
		const selectedFacility = _selectedFacility ? _selectedFacility : {value: -1, label: 'Facility (All)'};
		const selectedStatus = _selectedStatus ? _selectedStatus : {value:'', label:'Status (All)'};
		const filterNumberOfDays = _filterNumberOfDays ? _filterNumberOfDays : 0;
		this.setState({
			userRole, userId, selectedFacility, selectedStatus, filterNumberOfDays
		}, () => {
			this.loadData();
			if(userRole === 'Coordinator' || userRole === 'Admin'){
				if (process.env.NODE_ENV === 'development') {
					const username = 'admin';
					const password = 'V09VL7';
					const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
					axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}`, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							this.setState({
								userDoctor: result.data
							}, () => {
								this.loadDoctors();
							});
						});
				} else {
					const doctorUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${this.state.userId}`;
					axios(doctorUrl, {withCredentials: true})
						.then(result => {
							this.setState({
								userDoctor: result.data
							}, () => {
								this.loadDoctors();
							});
						});	
				}	
			}
		});
	}
	
	handleAcceptTerms = () => {
        this.setState({
            setOpen: false
		});
		const body = {
			terms_accepted: true
		};
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/qb/auth/prefs`, body, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
				});
		}else{
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/auth/prefs` : `https://covidcheck.upcebu.edu.ph/api/qb/auth/prefs`;
			axios.put(url, body, {withCredentials: true})
				.then(result => {
				});
		}
		cookies.set('qb_terms_accepted', true);
	};

	loadDoctors = () => {
		let url = `/api/qb/doctors?limit=999999999&offset=0`;
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ 
						doctors: this.filterDoctorsByFacilities(result.data.doctors) 
					}, () => { 
						this.setDoctorsOption(this.state.doctors) 
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					this.setState({ 
						doctors: this.filterDoctorsByFacilities(result.data.doctors) 
					}, () => { 
						this.setDoctorsOption(this.state.doctors) 
					});
				});
		}
	}

	dIncludes = (assigned, check) => {
		if(assigned){
			const assignedArr = assigned.split(',');
			let flag = false;
			assignedArr.map(a => {
				if(check){
					const checkArr = check.split(',');
					checkArr.map(c => {
						if(a.trim() === c.trim()){
							flag = true;
						}
					});
				}
			});
			return flag;
		}
		return false;
	}

	filterDoctorsByFacilities = (doctors) => {
		const newDoctors = [];
		doctors.map(d => {
			if(this.dIncludes(this.state.userDoctor.assigned_facilities, d.assigned_facilities)){
				newDoctors.push(d);
			}
		});
		return newDoctors;
	}

	setDoctorsOption = (doctors) => {
    	let options = [];
    	doctors.map(d => {
    		if(d.role === 'Admin' || d.role === 'Doctor'){
    			options.push({value: d.id, label:d.last_name+", "+d.first_name+" "+d.middle_name, assigned_facilities: d.assigned_facilities});
    		}
    	});
    	options.sort((a, b) => {
    		const aName = a.label.toLowerCase();
    		const bName = b.label.toLowerCase();
    		if(aName < bName){
    			return -1;
    		}
    		if(aName > bName){
    			return 1;
    		}
    		if(aName === bName){
    			return 0;
    		}
    	});
    	this.setState({ doctorsOption: options });
    }

	loadData = () => {
		this.setState({ 
			loading: true,
			pagination: true,
			errorFilterNumberOfDays: true, 
		});

		let filterByDays = false;
		let _filterNumberOfDays = 0;
		if(this.state.filterNumberOfDays){
			_filterNumberOfDays = parseInt(this.state.filterNumberOfDays);
			if(_filterNumberOfDays.toString() === this.state.filterNumberOfDays.toString()){
				if(_filterNumberOfDays){
					this.setState({ errorFilterNumberOfDays: false, pagination: false });
					filterByDays = true;
				}else{
					this.setState({ errorFilterNumberOfDays: false, pagination: true });
				}
			}else{
				this.setState({ errorFilterNumberOfDays: true, pagination: true });
			}
		}else{
			this.setState({ errorFilterNumberOfDays: false, pagination: true });
		}

		const offset = (this.state.perPage * this.state.page) - this.state.perPage;
		let url = `/api/qb/patients?limit=${this.state.perPage}&offset=${offset}`;

		if(filterByDays){
			url = `/api/qb/patients?limit=999999999&offset=0`;
		}

		if(this.state.userRole === 'Doctor'){
			url = `${url}&doctor_id=${this.state.userId}`;
		}

		if(this.state.selectedStatus.value){
			url = `${url}&status=${this.state.selectedStatus.value}`;
		}

		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					const userDoctor = result.data;
					const userAssignedFacilities = result.data.assigned_facilities;
					if(this.state.selectedFacility.value === -1){
						url = `${url}&facility_id=${userAssignedFacilities}`;
					}else{
						url = `${url}&facility_id=${this.state.selectedFacility.value}`;
					}
					const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
					axios(address, {headers: {'Authorization': `Basic ${token}`}})
						.then(result => {
							this.setState({
								userDoctor: userDoctor,
								patients: result.data.patients,
								totalRows: result.data.total_rows,
								loading: false
							}, () => {
								if(filterByDays){
									this.setState({ loading: true });
									this.filterPatientsByDays(_filterNumberOfDays);
								}
							});
						});
				});
		} else {
			const doctorUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${this.state.userId}`;
			axios(doctorUrl, {withCredentials: true})
				.then(result => {
					const userDoctor = result.data;
					const userAssignedFacilities = result.data.assigned_facilities;
					if(this.state.selectedFacility.value === -1){
						url = `${url}&facility_id=${userAssignedFacilities}`;
					}else{
						url = `${url}&facility_id=${this.state.selectedFacility.value}`;
					}
					const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
					 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
					axios(address, {withCredentials: true})
						.then(result => {
							this.setState({
								userDoctor: userDoctor,
								patients: result.data.patients,
								totalRows: result.data.total_rows,
								loading: false
							}, () => {
								if(filterByDays){
									this.setState({ loading: true });
									this.filterPatientsByDays(_filterNumberOfDays);
								}
							});
						});
				});	
		}
	}

	filterPatientsByDays = (days) => {
		const newPatients = [];
		this.state.patients.map(p => {
			const d = this.daysLapsed(p.status === 'Admitted' ? p.date_admitted : p.date_status).days;
			if(d >= days){
				newPatients.push(p);
			}
		});
		this.setState({ patients: newPatients, loading: false });
	}

	toFacilitiesOptions = (facilities) => {
		const facilitiesOptions = [{value: -1, label: 'Facility (All)'}];
		if(facilities ? facilities.length > 1 : false){
			facilities.map(f => {
				facilitiesOptions.push({value: f.id, label: f.name});
			});
		}
		return facilitiesOptions;
	}

	handleToggleFilter = () => {
		this.setState({ openFilters: !this.state.openFilters });
	}

	handleFieldChange = (event) => {
		this.setState({ filterNumberOfDays: event.target.value });
	}

	handleFacilityChange = (selectedOption) => {
		this.setState({ selectedFacility: selectedOption });
	}

	handleStatusChange = (selectedOption) => {
		this.setState({ selectedStatus: selectedOption });
	}

	filterPatients = () => {
		cookies.set(cookies_key.selected_facility, this.state.selectedFacility);
		cookies.set(cookies_key.selected_status, this.state.selectedStatus);
		cookies.set(cookies_key.num_of_days, this.state.filterNumberOfDays);
		this.loadData();
	}

	handlePageChange = page => {
		this.setState({ page }, () => { this.filterPatients() });
	}

	handleRowsPerPageChange = (perPage, page) => {
		this.setState({ perPage, page }, () => { this.filterPatients() });
	}

	statusStr = (patient) => {
		const status = patient.status;
		let statusDate = '';
		let memo = '';
		if(status === 'Admitted'){
			statusDate = patient.date_admitted ? moment(patient.date_admitted).format('L') : '';
		}else if(status === 'Discharged' || status === 'Recovered'){
			statusDate = patient.date_status ? moment(patient.date_status).format('L') : '';
			memo = patient.discharge_memo;
		}else if(status === 'Deceased'){
			statusDate = patient.date_status ? moment(patient.date_status).format('L')+' '+ moment(patient.date_status).format('LT') : '';
			memo = patient.cause_of_death;
		}else if(status === 'Completed Home Quarantine'){
			statusDate = patient.date_status ? moment(patient.date_status).format('L') : '';
		}
		return (<>
					<div>{status}</div>
					<div>({statusDate + (memo ? ' - '+memo : '')})</div>
				</>);
	}

	daysLapsed = (date) => {
		const time = '08:00';
		var dateFrom = new Date(date);
		dateFrom = new Date(dateFrom.getFullYear(), dateFrom.getUTCMonth(), 
							dateFrom.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
		var today = new Date();
		today = new Date(today.getFullYear(), today.getUTCMonth(), 
							today.getUTCDate(), parseInt(time.split(':')[0]),
							 parseInt(time.split(':')[1]), 0);
		const diffTime = today.getTime() - dateFrom.getTime();
		const diffDays = Math.ceil(diffTime / (1000*3600*24));
		return {days: diffDays, daysStr: diffDays > 14 ? '> 14' : diffDays};
	}

	render(){
		const classes = {margin:0};
		const conditionalRowStyles = [
			{ when: row => (row.meta.latest_log.date_time && (row.meta.latest_log.symptom_difficulty_breathing 
				|| row.meta.latest_log.symptom_chest_pain 
				|| row.meta.latest_log.symptom_psychological_distress
				|| row.meta.latest_log.symptom_confusion)),
				style: {
					backgroundColor: 'rgba(153,0,0,0.3)',
				},
			},
			{ when: row => (row.meta.latest_log.date_time && (
				!row.meta.latest_log.call_responded)),
				style: {
					backgroundColor: 'rgba(140,140,140,0.3)',
				},
			},
		];
		let columns = [];
		if(this.state.userRole === 'Admin' || this.state.userRole === 'Coordinator'){
			columns = [
				{ cell: item => <PatientActionMenu 
									row={item} 
									daysLapsed={item.status === 'Recovered' ? this.daysLapsed(item.date_status).daysStr : '0'}
									filterPatients={this.filterPatients} 
									doctorsOption={this.state.doctorsOption}
									userRole={this.state.userRole}/>,
					allowOverflow: true,
					button: true,
					width: '15px',
				},
				{ name: 'Name', selector: 'last_name', sortable: true, wrap: true,
					cell: item => <div align='left'>{item.last_name}, {item.first_name} {item.middle_name}</div>,
				},
				{ name: 'Sex', selector: 'sex', sortable: true, width: '90px', center: true,
					cell: item => item.sex,
				},
				{ name: 'Admission Temperature', selector: 'admission_temperature', sortable: true, center: true, width: '100px',
					cell: item => item.admission_temperature,
				},
				{ name: 'Admission Symptoms', selector: 'admission_symptoms', wrap: true,
					cell: item => <div align='left'>{item.admission_symptoms}</div>,
				},
				{ name: 'Medical Conditions', selector: 'medical_conditions', wrap: true,
					cell: item => <div align='left'>{item.medical_conditions}</div>,
				},
				{ name: 'Admitting Doctor', selector: 'admitting_doctor.last_name', wrap: true,
					cell: item => <div align='left'>{item.admitting_doctor.last_name ? item.admitting_doctor.last_name+', '+item.admitting_doctor.first_name+' '+item.admitting_doctor.middle_name : ''}</div>,
				},
				{ name: 'Attending Doctor', selector: 'doctor.last_name', wrap: true,
					cell: item => <div align='left'>{item.doctor.last_name ? item.doctor.last_name+', '+item.doctor.first_name+' '+item.doctor.middle_name : ''}</div>,
				},
				{ name: 'Status', selector: 'status', sortable: true, center:true, wrap: true,
					cell: item => <div align='center'>{this.statusStr(item)}</div>
				},
				{ name: '# of Days', center:true, 
					cell: item => <div align='center'>{
						item.status === 'Admitted' 
						? this.daysLapsed(item.date_admitted).daysStr 
						: (item.status === 'Recovered' ? this.daysLapsed(item.date_status).daysStr : '')
					}</div>
				},
			];
		}else if(this.state.userRole === 'Doctor'){
			columns = [
				{ cell: item => <PatientActionMenu 
									row={item}
									daysLapsed={item.status === 'Recovered' ? this.daysLapsed(item.date_status).daysStr : '0'}
									filterPatients={this.filterPatients} 
									doctorsOption={this.state.doctorsOption}
									userRole={this.state.userRole}/>,
					allowOverflow: true,
					button: true,
					width: '15px',
				},
				{ name: 'Name', selector: 'first_name', sortable: true, wrap: true,
					cell: item => <div align='left'>{item.first_name}</div>,
				},
				{ name: 'Contact Number', selector: 'contact_number', sortable: true, wrap: true,
				},
			];
		}

		return(
			<>
			<Dialog
				open={this.state.setOpen}
				onClose={this.handleAcceptTerms}
				aria-describedby="alert-dialog-description"
				repositionOnUpdate={false}
				style={{ marginTop: '60px' }}
			>
			<div className="dialog_window">
				<h6>Terms of Use and Privacy Policy</h6>
				<p>
					The University of the Philippines Cebu (UP Cebu) Firecheck Team developed the CoVcheck application in light of the rising cases of the CoronaVirus Disease 2019 (COVID-19) in the Philippines. This application is developed by Firecheck for the local government units of Cebu province and is intended for the use of the province's constituents.
				</p><p>
					CoVcheck is an application developed to collect data about potential COVID19 transmissions through self-reporting. The data will be used by the local government and concerned agencies to identify targeted actions to help many of its constituents especially those who will need special care.
				</p><p>
					This document is used to inform users regarding the policies with the collection, use, and disclosure of Personal Information shared through the CoVcheck application. 
				</p><p>
					By accessing or using CoVcheck you agree to be bound by these Terms. If you disagree with any part of  the terms then you may not access the Service.
				</p>
				<h7>TERMS OF USE</h7>
				<p>The use of the CoVcheck, especially the Self-Assessment application, is for the public and is free of use.
				</p><p>Your access to and use of CoVcheck is conditioned on your acceptance of and compliance with the Terms set here. Specifically, you agree to the collection and use of information in relation to the application’s purpose. The Personal Information that we collect is used for expediting the monitoring of the spread of the CoronaVirus Disease 2019 (COVID-19) through reporting of potential infection and self-assessment. 
				</p><p>We will not use or share your information with anyone except as described in the Privacy Policy section of this document.
				</p><p>This application relies upon publicly available data from multiple sources that may not always agree and are constantly subject to changes pending further scientific developments. Reliance on the application for medical guidance may not be enough for some special cases. Any and all unusual medical concerns must still be directed towards and addressed by qualified health professionals and hospital clinics near you. The use of this application in commerce by other parties is strictly prohibited.
				</p><p>The University of the Philippines Cebu hereby disclaims any and all representations and warranties with respect to the use of this application, including accuracy, fitness for use, and merchantability. In no event shall UP Cebu or any member of the CoVcheck Project be liable for any personal, special, incidental, indirect or consequential damages whatsoever including, damages or loss, alleged harm, threats of death, disturbance of normal living or any other pecuniary loss arising from the adoption or use of the CoVcheck Web Application.
				</p>
				<h7>Information Collection and Use</h7>
				<p>For better assessment and tracking purposes, we will require you to provide us truthfully with certain personally identifiable information, including but not limited to name, age, sex, nationality, home address, and contact number. Some personal health information will also be collected. The information that we request will be retained by us and used as described in this privacy policy.
				</p><p>The application does use third party services that may validate information to identify you and point your exact location. 
				</p>
				<h7>Cookies</h7>
				<p>Cookies are small pieces of text used to store information on web browsers. User data such as User ID, Name, Role, City or Barangay affiliation are stored in the cookies. These data are eventually deleted from the cookie upon log-out. Cookies enable CoVcheck to enforce security measures and improve the navigability of the application for a better user experience.
				</p>
				<h7>Security</h7>
				<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
				</p>
				<h7>Links to Other Sites</h7>
				<p>The website may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these applications. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
				</p>
				<h7>PRIVACY POLICY</h7><br/>
				<h7>Confidentiality under the Philippine Law</h7>
				<p>The information that we collect from you are generally protected as privileged communications and it is our responsibility to keep that information confidential.
				</p>
				<h7>Collection and Processing of Personal Data</h7>
				<p>The data that you provide through the CoVcheck Self-Assessment application will be stored and used by the local government to identify measures to contain local transmissions and plan for rehabilitation and support activities.
				</p><p>All the user inputs will be summarized to provide the local government with an overview of potential COVID cases in the locality. This will prompt the local government to identify strategic measures for containment and public support. Individual records will also be used to help the local government monitor persons of interest for possible infection.
				</p><p>We urge the public to report truthfully.
				</p>
				<h7>Technical and Health Service Providers</h7>
				<p>The data that we will collect will be shared with the following agencies:
				</p><p>1.	LGU Mayor’s Office
				</p><p>2.	City Disaster Risk Reduction and Management Office
				</p><p>3.	City Health Office
				</p><p>4.	Department of Health
				</p><p>Each of the above stated agencies shall execute their own Non-Disclosure Agreements per employee to further ensure confidentiality of the data that will be collected.
				</p><p>
				</p><p>We may also employ other party’s units and companies and individuals due to the following reasons:
				</p><p>●	To facilitate the functionality of the CoVcheck application; (internet service provider, etc.)
				</p><p>●	To provide the necessary medical emergency service on our behalf; (Health Services Units, Disaster Response Units)
				</p><p>●	To deliver information dissemination for public awareness  (print and non-print media)
				</p><p>●	To assist in the monitoring and assessment of the submitted/reported cases (doctor/health experts)
				</p><p>We want to inform users that other parties may have access to your Personal Information. The reason is to perform the tasks assigned to them in the management of the health crisis. However, they are obligated not to disclose or use the information for any other purpose.
				Children’s Privacy
				</p><p>The CoVcheck application does not exempt anyone. Thus, a minor’s parent or guardian should be the one to provide us with personal information or at least is aware that your child has provided us with personal information so that we will be able to do necessary actions.
				</p>
				Changes to This Privacy Policy
				<p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
				</p>
				<h7>CONTACT US</h7>
				<p>If you have any questions or suggestions about our Terms of Use and Privacy Policy, do not hesitate to contact us at firecheckupcebu@gmail.com.
				</p>
				<button className = "button-nextchoice" onClick = {this.handleAcceptTerms}>Back</button>
				<br/>
			</div>
			</Dialog>
				<Paper style={{ padding : 20 }} width="60%">
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Patients
					</Typography>
					<Divider/>
					<Table className="adPatient-table">
					    <tbody>
						    <tr>
								<td align={window.innerWidth <= mobilePhonesBreakPoint ? 'left' : 'right'}>
									{isMobile ?
										<Grid container spacing={1} justify='center'>
											<Grid item xs={6}>
												<div align='left'>
													<Typography component="h2" variant="body2" color="primary">Filter Patients:</Typography>
												</div>
											</Grid>
											<Grid item xs={6}>
												<div align='right'>
													{this.state.openFilters ?
														<KeyboardArrowUpIcon onClick={this.handleToggleFilter} /> :
														<KeyboardArrowDownIcon onClick={this.handleToggleFilter} />}
												</div>
											</Grid>
										</Grid>
									: <Typography component="h2" variant="body2" color="primary">Filter Patients:</Typography>}
								</td>
								{(isMobile ? this.state.openFilters : true) ?
									<>
										{(this.state.userDoctor.facilities ? this.state.userDoctor.facilities.length > 1 : false) ?
										<td>
											<Select
												options={this.toFacilitiesOptions(this.state.userDoctor.facilities)}
												value={this.state.selectedFacility}
												placeholder="Filter by Facility"
												customStyles={selectStyles}
												onChange={this.handleFacilityChange}
											/>
										</td> : null }
										<td>
											<Select
												options={status_options}
												value={this.state.selectedStatus}
												placeholder="Filter by Status"
												customStyles={selectStyles}
												onChange={this.handleStatusChange}
											/>
										</td>
										<td>
											<TextField 
												value = {this.state.filterNumberOfDays}
												id="outlined-basic"
												type="number" 
												label="# of Days" 
												variant="outlined"  
												fullWidth={true}
												className={classes.margin}
												InputLabelProps={{
													shrink: true,
													className: classes.floatingLabelFocusStyle,
												}}
												margin="dense"
												onChange={this.handleFieldChange}
												error={this.state.errorFilterNumberOfDays}
											/>
										</td>
										<td>
											<Button size="small"
												fullWidth={window.innerWidth <= mobilePhonesBreakPoint ? true : false}
												color="primary"
												variant="contained"
												startIcon={<FindReplaceIcon />}
												onClick={this.filterPatients}>
												Filter
											</Button>
										</td>
									</>
									: null }
							</tr>
						</tbody>
					</Table>
					<Divider/>
					<DataTable
						noHeader
						columns={columns}
						data={this.state.patients}
						progressPending={this.state.loading}
						progressComponent={<TableLoader loadingText="Patients"/>}
						pagination={this.state.pagination}
						paginationServer
						paginationTotalRows={this.state.totalRows}
						paginationPage={this.state.page}
						paginationPerPage={this.state.perPage}
						onChangeRowsPerPage={this.handleRowsPerPageChange}
						onChangePage={this.handlePageChange}
						customStyles={customStyles}
						conditionalRowStyles={conditionalRowStyles}
					/>
					{!this.state.pagination && 
						<div align='left' style={{ paddingTop: 5 }}>
							<Divider/>
							<Typography color='primary' variant='overline' style={{ paddingTop: 30, paddingRight: 5 }}>No. of Patients: {this.state.patients.length}</Typography>
						</div>
					}
				</Paper>
			</>
		);
	}
}

export default ViewPatients;