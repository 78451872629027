import React, { Component } from 'react';
import '../Forms.css';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

const useStyles = makeStyles({
    root: {
      height: 300,
    },
  });


  const marks = [
    {
    value: 0,
    label: 'Results',
    },
    {
    value: 1,
    label: 'Exposure',
    },
    {
    value: 2,
    label: 'Vulnerability',
    },
    {
    value: 3,
    label: 'Symptoms',
    },
    {
    value: 4,
    label: 'Personal Details',
    },
    {
    value: 5,
    label: 'Intro',
    },
];
  
function valuetext(value) {
    return `${value}°C`;
}
  
function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

export class SelfAssessment_Symptoms_Check2 extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	};

    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    
    yes = e => {
        e.preventDefault();
        this.props.confirmInfectedPerson();
    };
	
	no = e => {
        e.preventDefault();
        this.props.denyInfectedPerson();
    };
   
    render() {
        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <div className = "wrapper">
                        <div className = "progress"> 
                            <Slider
                                disabled
                                track = "inverted"
                                orientation="vertical"
                                defaultValue={1}
                                valueLabelFormat={valueLabelFormat}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                marks={marks}
                            />
                        </div>
                        <div className = "screen">
							<div className = "progress_change">
								<h2>Vulnerability</h2>
							</div>
							<br/><br/>
                            <p>In the past 14 days, have you worked with a person confirmed to have CoVID-19?</p>
                            <div class="two_choice">
								<button class = "button-yeschoice" 
									primary = {true} 
									onClick = {this.yes}>
										Yes
								</button>
								<button class = "button-nochoice" 
									primary = {true} 
									onClick = {this.no}>
										No
								</button>
							</div>
							<div class="bot_nav">
								<button class = "button-backchoice" 
									primary = {true} 
									onClick = {this.back}>
										&#60; Back
								</button>
							</div>
                        </div>
                    </div>
                </React.Fragment>
            </MuiThemeProvider>
        );
    };
}

const styles = {
    button:{
        margin: 15
    }
}

export default SelfAssessment_Symptoms_Check2;
