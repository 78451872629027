import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React from 'react';
import axios from 'axios';
import {
  Link
} from "react-router-dom";
import RaisedButton from 'material-ui/RaisedButton';
import Typography from '@material-ui/core/Typography';
import TextField from 'material-ui/TextField';

import queryString from 'query-string';

import ChangePasswordComponent from './ChangePasswordComponent';

class PersonnelChangePassword extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			type: props.type,
			id: null,
			validationKey: '',
			password: '',
			confirmPassword: '',
			errors: '',
			validationKeyValid: true,
			success: false,
			successMsg: 'Your password has been changed successfully!',
		}
	}

	componentDidMount() {
		const value = queryString.parse(this.props.location.search);
		this.setState({
			id: value.r, validationKey: value.v
		}, () => {
			if (process.env.NODE_ENV === 'development') {
				axios.get(`https://covidcheck.upcebu.edu.ph/dev/api/reports/reset_password?v=${this.state.validationKey}&r=${this.state.id}`)
					.then(response => {
					})
					.catch(error => {
						this.setState({
							validationKeyValid: false, success: true, successMsg: 'Reset password link already expired!'
						});
					});
			} else {
				const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 
					`https://covidcheck.upcebu.edu.ph/dev/api/reports/reset_password?v=${this.state.validationKey}&r=${this.state.id}` : 
					`https://covidcheck.upcebu.edu.ph/api/reports/reset_password?v=${this.state.validationKey}&r=${this.state.id}`;
				axios.get(url)
					.then(response => {
					})
					.catch(error => {
						this.setState({
							validationKeyValid: false, success: true, successMsg: 'Reset password link already expired!'
						});
					});
			}
		});
	}

	onSubmit = () => {
		if(this.state.password.trim() && this.state.confirmPassword.trim()){
		}else{
			return this.setState({
				errors: 'Please fill-out all fields'
			});
		}

		const body = {
			"old_password": this.state.validationKey,
			"new_password": this.state.password
		};

		if (process.env.NODE_ENV === 'development') {
			axios.put(`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.id}`, body)
				.then(response => {
					this.setState({
						success: true
					});
				})
				.catch(error => {
					this.setState({
						errors: 'Something went wrong'
					});
				});
		} else {
			const url = process.env.REACT_APP_COVCHECK_ENV === 'testing' ? 
				`https://covidcheck.upcebu.edu.ph/dev/api/reports/${this.state.id}` : 
				`https://covidcheck.upcebu.edu.ph/api/reports/${this.state.id}`;
			axios.put(url, body)
				.then(response => {
					this.setState({
						success: true
					});
				})
				.catch(error => {
					this.setState({
						errors: 'Something went wrong'
					});
				});
		}
	}

	onPasswordChange = event => {
		this.setState({
			password: event.target.value
		});
	}

	onConfirmPasswordChange = event => {
		this.setState({
			confirmPassword: event.target.value
		});
	}

	render() {
		return (
			<MuiThemeProvider>
			<div className="wrapper_home">
				<div className="typo-divider">
					Forgot Password
					<br/>
				</div>
				{this.state.success ?
					<>
						<div className='wrapper_login'>
							<h3 style={{ color: !this.state.validationKeyValid && 'red' }}>{this.state.successMsg}</h3>
							<br/><br/><br/><br/>
							<div style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)'}}>
								{ !this.state.validationKeyValid && <Link to={this.state.type === 'admin' ? "/hsu-forgot-password" : "/forgot-password"}>
									<button class = "button-return-to-home"
										primary = {true}>
										Request Again
									</button>
								</Link> }
								<br/><br/>
								<Link to="/">
									<button class = "button-return-to-home"
										primary = {true}>
										Return to Home
									</button>
								</Link>
							</div>
							<br/><br/><br/><br/><br/>
						</div>
					</> :
					<>
						<div className="card">
							<div>
								<TextField
									floatingLabelText="New Password"
									hintText="New Password"
									value={this.state.password}
									onChange={this.onPasswordChange}
									type="password"
								/>
							</div>
							<div>
								<TextField
									floatingLabelText="Confirm Password"
									hintText="Confirm Password"
									value={this.state.confirmPassword}
									onChange={this.onConfirmPasswordChange}
									type="password"
								/>
							</div>
						</div>
						<div style={{color: 'red', 'font-size': '0.9em'}}>
							<br/>
							{this.state.errors}
						</div>
						<div class="bot_nav">
							<Link to = '/'>
								<button class = "button-backchoice"
									primary = {true}>
									Cancel
								</button>
							</Link>
					        <button class = "button-nextchoice"
								primary = {true}
								onClick = {this.onSubmit}>
									Confirm
							</button>
							<br/><br/>
						</div>
					</> }
			</div>
			</MuiThemeProvider>
		)
	}
}

export default PersonnelChangePassword;
