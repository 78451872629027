import React, { Component } from 'react';
import moment from 'moment';
import {
  Link
} from "react-router-dom";
import '../Forms.css';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import SelectReact from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';

import {
	Button, Divider, Grid, Paper, Typography
} from '@material-ui/core';


const Table = styled.table`
	padding: 10px;

	td {
		padding: 5px;
	}
`;

const BlueCheckbox = withStyles({
	root: {
	  color: '#4C6FEE',
	  '&$checked': {
		color: '#4C6FEE',
	  },
	},
	checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const StyleRadio = withStyles({
	root: {
	color: '#4C6FEE',
	'&$checked': {
		color: '#4C6FEE',
	},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);


const CssTextField = withStyles({
	root: {
	  '& label.Mui-focused': {
		color: '#4C6FEE',
	  },
	  '& .MuiInput-underline-root': {
		  '& fieldset': {
			  borderBottomColor: '#4C6FEE',
		  },
		  '&:after': {
			  borderBottomColor: '#4C6FEE',
		  },
		  '&:hover fieldset': {
			  borderBottomColor: '#4C6FEE',
		  },
	  },
	  '& .MuiOutlinedInput-root': {
		  '& fieldset': {
			  borderColor: '#4C6FEE',
		  },
		  '&:hover fieldset': {
			  borderColor: '#3AD5C1',
		  },
		  '&.Mui-focused fieldset': {
			  borderColor: '#4C6FEE',
		  },
	  },
	},
})(TextField);

const type_options = [
	{ value: 'CBC', label: 'CBC' },
	{ value: 'Urinalysis', label: 'Urinalysis' },
	{ value: 'COVID Rapid Test', label: 'COVID Rapid Test' },
	{ value: 'COVID RT-PCR', label: 'COVID RT-PCR' }
]

const result_options = [
	{ value: 'Pending', label: 'Pending' },
	{ value: 'Negative', label: 'Negative' },
	{ value: 'Positive', label: 'Positive' }
]
const customStyles = {
  control: (base, state) => ({
      ...base,
	 background: 'white',
	 height: 56,
      borderColor: state.isFocused ? "#4C6FEE" : "#4C6FEE",
      "&:hover": {
        borderColor: state.isFocused ? "#4C6FEE" : "#3AD5C1"
      }
    })
}

export class UpdateForm extends Component {
    state = {
		tempErrorText:'',
		fileErrorText:'',
		testErrorText:'',
		resultErrorText1:'',
		resultErrorText2:'',

		valid:false,
		other:false,

		has_test:false,
		selectedType: { value: 'RT', label: 'Rapid Test (RT)' },
		selectedResult: { value: 'Pending', label: 'Pending' },
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	};

	handleTypeChange = selectedOption  => {
		this.setState({
			selectedType: selectedOption
		});
		this.props.ChangeType(selectedOption.value);
	}

	handleResultChange = (test_name) => selectedOption  => {
		this.setState({
			selectedResult: selectedOption
		});
		this.props.ChangeResult(test_name,selectedOption.value);
	}

	otherCheck = name => e => {
		// console.log(e);
		this.setState({[name]: e.target.checked});
	};


	handleCheckChange = name => e => {
        this.setState({[name]: e.target.checked});
    };

	fileSelectedChange = event => {

		// console.log(event);
		this.setState({
			temperature_pic:event.target.files[0]
		});
	}

    dateStr = date_testing => {
		if(date_testing == null){
			date_testing = new Date();
		}
		const dateNow = new Date(date_testing);
		const year = dateNow.getFullYear();
		const monthWithOffset = dateNow.getUTCMonth() + 1;
		const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}` : monthWithOffset;
		const date = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
		const date_str = `${year}-${month}-${date}`;
		return date_str;
	}
    //----------------------------------------------Submit Form---------------------------------------------------

    continue = e => {
        this.props.values.loading = true;
        e.preventDefault();
		var valid = true;
        this.setState({
            valid:true,
        }, () => {
			if(this.props.values.has_cbc){
				if(this.props.values.test_cbc.result.trim() == ""){
					valid = false;
					this.setState({
						resultErrorText1: 'Input results of test'
					})
				}else{
					this.setState({
						resultErrorText1: ''
					})
				}
			}

			if(this.props.values.has_urinalysis){
				if(this.props.values.test_urinalysis.result.trim() == ""){
					valid = false;
					this.setState({
						resultErrorText2: 'Input results of test'
					})
				}else{
					this.setState({
						resultErrorText2: ''
					})
				}
			}

			if(valid){
				this.props.submitForm();//Added next step in submit form
				//this.props.nextStep();
			}else{

				this.props.values.loading = false;
			}
		});
    };

    //Go back to the previous Step
    prevStep = () => {
        const { step } = this.state;

        this.setState({
            step: step - 1
        });
    }

    //Function to Move Back
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
		const classes = {margin:1};
        const {values, handleChange, fileSelectedChange, handleCheckChange, handleNumberChange, handleRadioChange, fileSelectedChangeTest, handleChange_testing } = this.props;
        return (
			<Paper style = {{ padding: 10, textAlign: 'left' }}>
				<Grid item xs={24} md={12} lg={12} xl={12}>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Update existing symptoms:
					</Typography>
					<br/>
                    <Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						I have COVID-19 symptoms to report.
					</Typography>
                    <Table className="adPatient-table">
						<tr>
							<td>
							<FormControlLabel
								control={<Radio
									disabled = {this.state.isLoading}
									checked={values.has_symptoms}
									onChange={handleRadioChange}
									value={true}
									name="radio-button-male"
									inputProps={{ 'aria-label': 'Female' }}
								/>} 
								label="Yes"
							/>
							&nbsp;&nbsp;&nbsp;
							<FormControlLabel
								control={<Radio
										disabled = {this.state.isLoading}
										checked={!values.has_symptoms}
										onChange={handleRadioChange}
										value={false}
										name="radio-button-female"
										color="primary" 
										inputProps={{ 'aria-label': 'Male' }}
									/>} 
								label="No"
							/><br/>
							</td>
						</tr>
					</Table>
					{values.has_symptoms?
					<>
                    <Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Please enter your current temperature
					</Typography>
                    <Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
								{/* <CssTextField
									disabled = {values.loading}
									value = {values.temperature}
									id="outlined-basic"
									variant="outlined"
									type="number"
									fullWidth={true}
									className={classes.margin}
									error={this.state.tempErrorText}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={handleChange('temperature')}
								/> */}
								<NumberFormat
									onValueChange={handleNumberChange}
									defaultValue = {values.temperature}
									style={{ width: 300, marginLeft: 10, padding: 5, height: 20}}
									margin="dense"
									errorText = {this.state.tempErrorText}
									disabled = {values.loading}
								/>
								<br/>
								<h4 style = {{fontSize: 12, color: "red"}}>{this.state.tempErrorText}</h4>
							</td>
                            <td>
								<div>
									Please input an image of a thermometer after getting your temperature
								</div>
								<div>
									<input class="custom-file-upload" type = 'file' style = {{width: '85%'}	} onChange = {fileSelectedChange} accept="image/png, image/jpeg"
									disabled = {values.loading}/>
									<br/>
									<h4 style = {{fontSize: 12, color: "red"}}>{this.state.fileErrorText}</h4>
								</div>
							</td>
						</tr>
						</tbody>
					</Table>
                    <Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Have you experienced any of these symptoms today? Select all that apply.
					</Typography>
                    <Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
							<FormControlLabel
								value= "s_fever"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_fever}
											onChange={handleCheckChange('s_fever')}
										/>}
								label="Fever"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_cough"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_cough}
											onChange={handleCheckChange('s_cough')}
										/>}
								label="Cough"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_sore_throat"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_sore_throat}
											onChange={handleCheckChange('s_sore_throat')}
										/>}
								label="Sore Throat"
							/>
							</td>
						</tr>
						<tr>
							<td>
							<FormControlLabel
								value= "s_short_breath"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_short_breath}
											onChange={handleCheckChange('s_short_breath')}
										/>}
								label="Short Breath"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_fatigue"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_fatigue}
											onChange={handleCheckChange('s_fatigue')}
										/>}
								label="Fatigue"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_phlegm"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_phlegm}
											onChange={handleCheckChange('s_phlegm')}
										/>}
								label="Phlegm"
							/>
							</td>
						</tr>
						<tr>
							<td>
							<FormControlLabel
								value= "s_muscle_joint_pain"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_muscle_joint_pain}
											onChange={handleCheckChange('s_muscle_joint_pain')}
										/>}
								label="Muscle or Joint Pain"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_headache"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_headache}
											onChange={handleCheckChange('s_headache')}
										/>}
								label="Headache"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_chills"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_chills}
											onChange={handleCheckChange('s_chills')}
										/>}
								label="Chills"
							/>
							</td>
						</tr>
						<tr>
							<td>
							<FormControlLabel
								value= "s_vomiting"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_vomiting}
											onChange={handleCheckChange('s_vomiting')}
										/>}
								label="Vomiting"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_stuffed_nose"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_stuffed_nose}
											onChange={handleCheckChange('s_stuffed_nose')}
										/>}
								label="Stuffy Nose"
							/>
							</td>
                            <td>
							<FormControlLabel
								value= "s_diarrhea"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={values.s_diarrhea}
											onChange={handleCheckChange('s_diarrhea')}
										/>}
								label="Diarrhea"
							/>
							</td>
						</tr>
						<tr>
							<td>
							<FormControlLabel
								value= "other"
                                disabled = {values.loading}
								control={<BlueCheckbox
											checked={this.state.other}
											onChange={this.otherCheck('other')}
										/>}
								label="Other"
							/>
							</td>
							<td>
						{this.state.other?
							<CssTextField
								disabled = {values.loading}
								value = {values.s_others}
								id="outlined-basic"
								label="Other Symptoms"
								variant="outlined"
								fullWidth={true}
								className={classes.margin}
								InputLabelProps={{
									shrink: true,
									className: classes.floatingLabelFocusStyle,
								}}
								onChange={handleChange('s_others')}
							/>
							:null
						}
							</td>
						</tr>
						</tbody>
					</Table>
					<Divider/>
					<br/>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						Medications Taken
					</Typography>
					<Table className="adPatient-table">
					    <tbody>
						<tr>
							<td>
								<CssTextField
									disabled = {values.loading}
									value = {values.medications}
									id="outlined-basic"
									variant="outlined"
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									onChange={handleChange('medications')}
								/>
							</td>
						</tr>
						</tbody>
					</Table>
					</>:null}
					<Divider/>
						<br/>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Report Laboratory/CoVID Test
						</Typography>
						<Table className="adPatient-twochoice-table">
							<tr>
								<td>
									<FormControlLabel
										value= "has_cbc"
										disabled = {values.loading}
										control={<BlueCheckbox
													checked={values.has_cbc}
													onChange={handleCheckChange('has_cbc')}
												/>}
										label="CBC"
									/>
								</td>
								<td>
									<FormControlLabel
										value= "has_urinalysis"
										disabled = {values.loading}
										control={<BlueCheckbox
													checked={values.has_urinalysis}
													onChange={handleCheckChange('has_urinalysis')}
												/>}
										label="Urinalysis"
									/>
								</td>
								<td>
									<FormControlLabel
										value= "has_rapid_test"
										disabled = {values.loading}
										control={<BlueCheckbox
													checked={values.has_rapid_test}
													onChange={handleCheckChange('has_rapid_test')}
												/>}
										label="COVID Rapid Test"
									/>
								</td>
								<td>
									<FormControlLabel
										value= "has_rt_pcr"
										disabled = {values.loading}
										control={<BlueCheckbox
													checked={values.has_rt_pcr}
													onChange={handleCheckChange('has_rt_pcr')}
												/>}
										label="COVID RT-PCR"
									/>
								</td>
							</tr>
						</Table>
						{/* ---------------------------------------cbc----------------------------------------- */}
						{values.has_cbc?
						<>
						<Divider/>
						<br/>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Test Information: CBC
						</Typography>
						<Table className="adPatient-table">
							<tbody>
							<tr>
								<td>
									<h3>
										Testing Date
									</h3>
									<TextField
										value = {this.dateStr(values.test_cbc.date_time)}
										onChange={handleChange_testing('test_cbc','date_time')}
										id="outlined-basic"
										variant="outlined"
										fullWidth={true}
										type='date'
										InputProps={{inputProps: {max: moment().format("YYYY-MM-DD")} }}
									/>
									</td>
								<td>
									<h3>
										Supporting Image
									</h3>
									<div>
										<input class="custom-file-upload" style = {{width: '95%', height: '30px'}} type = 'file' onChange = {fileSelectedChangeTest('test_cbc')} accept="image/png, image/jpeg"/>
									</div>
								</td>
							</tr>
								<h3>
									Results
								</h3>
							<tr>
								<td>
									<CssTextField
									onChange={handleChange_testing('test_cbc','result')}
									value = {values.test_cbc.result}
									id="outlined-basic"
									label="Test Results"
									variant="outlined"
									error={this.state.resultErrorText1}
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									helperText = {this.state.resultErrorText1}
									/>
								</td>
							</tr>
						</tbody>
						</Table>
						</>
						:null
						}
						{/* -----------------------------------urinalysis--------------------------------------- */}
						{values.has_urinalysis?
						<>
						<Divider/>
						<br/>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Test Information: Urinalysis
						</Typography>
						<Table className="adPatient-table">
							<tbody>
							<tr>
								<td>
									<h3>
										Testing Date
									</h3>
									<TextField
										value = {this.dateStr(values.test_urinalysis.date_time)}
										onChange={handleChange_testing('test_urinalysis','date_time')}
										id="outlined-basic"
										variant="outlined"
										fullWidth={true}
										type='date'
										InputProps={{inputProps: {max: moment().format("YYYY-MM-DD")} }}
									/>
									</td>
								<td>
									<h3>
										Supporting Image
									</h3>
									<div>
										<input class="custom-file-upload" style = {{width: '95%', height: '30px'}} type = 'file' onChange = {fileSelectedChangeTest('test_urinalysis')} accept="image/png, image/jpeg"/>
									</div>
								</td>
							</tr>
								<h3>
									Results
								</h3>
							<tr>
								<td>
									<CssTextField
									onChange={handleChange_testing('test_urinalysis','result')}
									value = {values.test_urinalysis.result}
									id="outlined-basic"
									label="Test Results"
									variant="outlined"
									error={this.state.resultErrorText2}
									fullWidth={true}
									className={classes.margin}
									InputLabelProps={{
										shrink: true,
										className: classes.floatingLabelFocusStyle,
									}}
									helperText = {this.state.resultErrorText2}
									/>
								</td>
							</tr>
						</tbody>
						</Table>
						</>
						:null
						}
						{/* -----------------------------------rapid test--------------------------------------- */}
						{values.has_rapid_test?
						<>
						<Divider/>
						<br/>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Test Information: COVID Rapid Test
						</Typography>
						<Table className="adPatient-table">
							<tbody>
							<tr>
								<td>
									<h3>
										Testing Date
									</h3>
									<TextField
										value = {this.dateStr(values.test_rapid_test.date_time)}
										onChange={handleChange_testing('test_rapid_test','date_time')}
										id="outlined-basic"
										variant="outlined"
										fullWidth={true}
										type='date'
										InputProps={{inputProps: {max: moment().format("YYYY-MM-DD")} }}
									/>
									</td>
								<td>
									<h3>
										Supporting Image
									</h3>
									<div>
										<input class="custom-file-upload" style = {{width: '95%', height: '30px'}} type = 'file' onChange = {fileSelectedChangeTest('test_rapid_test')} accept="image/png, image/jpeg"/>
									</div>
								</td>
							</tr>
								<h3>
									Results
								</h3>
							<tr>
								<td>
									<SelectReact
										styles={customStyles}
										options={result_options}
										value= {{ value: values.test_rapid_test.result, label: values.test_rapid_test.result }}
										searchable
										placeholder="Test Results"
										fullWidth={true}
										onChange={this.handleResultChange('test_rapid_test')}
									/>
									{this.state.resultErrorText}
								</td>
							</tr>
						</tbody>
						</Table>
						</>
						:null
						}
						{/* -------------------------------------rt_pcr----------------------------------------- */}
						{values.has_rt_pcr?
						<>
						<Divider/>
						<br/>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Test Information: COVID RT-PCR
						</Typography>
						<Table className="adPatient-table">
							<tbody>
							<tr>
								<td>
									<h3>
										Testing Date
									</h3>
									<TextField
										value = {this.dateStr(values.test_rt_pcr.date_time)}
										onChange={handleChange_testing('test_rt_pcr','date_time')}
										id="outlined-basic"
										variant="outlined"
										fullWidth={true}
										type='date'
										InputProps={{inputProps: {max: moment().format("YYYY-MM-DD")} }}
									/>
									</td>
								<td>
									<h3>
										Supporting Image
									</h3>
									<div>
										<input class="custom-file-upload" style = {{width: '95%', height: '30px'}} type = 'file' onChange = {fileSelectedChangeTest('test_rt_pcr')} accept="image/png, image/jpeg"/>
									</div>
								</td>
							</tr>
								<h3>
									Results
								</h3>
							<tr>
								<td>
									<SelectReact
										styles={customStyles}
										options={result_options}
										value= {{ value: values.test_rt_pcr.result, label: values.test_rt_pcr.result }}
										searchable
										placeholder="Test Results"
										fullWidth={true}
										onChange={this.handleResultChange('test_rt_pcr')}
									/>
									{this.state.resultErrorText}
								</td>
							</tr>
						</tbody>
						</Table>
						</>
						:null
						}
						<Divider/>
						<div style ={{margin:'10px 0 50px 0'}}>
                            <button class = "button-backchoice"
                                disabled = {values.loading}
                                primary = {true}
                                onClick = {this.back}>
                                    &#60; Back
                            </button>
							<button class = "button-nextchoice"
                                disabled = {values.loading}
								primary = {true}
								onClick = {this.continue}>
									{values.loading? "Updating..":"Update"}
							</button>
							<br/><br/>
						</div>
					<Dialog
							maxWidth={'lg'}
							open={values.loading}
							aria-describedby="alert-dialog-description"
							repositionOnUpdate={false}
							keepMounted
						>
						<div className="wrapper_home" style ={{marginTop: '0px'}}>
						<h5>Uploading...</h5>
							<h3>This may take a while</h3>
						</div>
					</Dialog>
                </Grid>
            </Paper>
        );

    }
}

export default UpdateForm
