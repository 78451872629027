import React, {Component} from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import TableLoader from '../ReportsManagement/TableLoader';
import DoctorActionMenu from './DoctorActionMenu';
import { Link } from 'react-router-dom';

import {
	Button, Divider, Paper, Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const customStyles = {
	headCells: {
		style: {
			textAlign: 'center',
			color: '#202124',
			fontSize: '12px',
			fontWeight: 'bold',
		},
	},
};

const roles_options = [
	{ value: 'Coordinator', label: 'Coordinator' },
	{ value: 'Admin', label: 'Doctor/Nurse' },
	{ value: 'Doctor', label: 'Buddy' }
]

class ViewDoctors extends Component {
	state = {
		userId: cookies.get('qb_id'),
		userDoctor: {},
		doctors: [],
		loading: true,
		totalRows: 0,
		perPage: 10,
		page: 1,
		userRole: '',
	};

	componentDidMount(){
		window.scrollTo(0,0);
		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			axios(`https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}`, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					this.setState({ 
						userDoctor: result.data 
					}, () => {
						this.loadData();
					});
				});						
		} else {
			const doctorUrl = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev/api/qb/doctors/${this.state.userId}` : `https://covidcheck.upcebu.edu.ph/api/qb/doctors/${this.state.userId}`;
			axios(doctorUrl, {withCredentials: true})
				.then(result => {
					this.setState({ 
						userDoctor: result.data 
					}, () => {
						this.loadData();
					});
				});
		}
	}

	loadData = () => {
		this.setState({ loading: true });
		// const offset = (this.state.perPage * this.state.page) - this.state.perPage;
		// let url = `/api/qb/doctors?limit=${this.state.perPage}&offset=${offset}`;
		let url = `/api/qb/doctors?limit=999999999&offset=0`;

		if (process.env.NODE_ENV === 'development') {
			const username = 'admin';
			const password = 'V09VL7';
			const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
			const address = `https://covidcheck.upcebu.edu.ph/dev${url}`;
			axios(address, {headers: {'Authorization': `Basic ${token}`}})
				.then(result => {
					const doctors = this.filterDoctorsByFacilities(result.data.doctors);
					this.setState({
						doctors: doctors,
						totalRows: doctors.length,
						loading: false
					});
				});
		} else {
			const address = process.env.REACT_APP_COVCHECK_ENV === 'testing' ?
			 `https://covidcheck.upcebu.edu.ph/dev${url}` : `https://covidcheck.upcebu.edu.ph${url}`;
			axios(address, {withCredentials: true})
				.then(result => {
					const doctors = this.filterDoctorsByFacilities(result.data.doctors);
					this.setState({
						doctors: doctors,
						totalRows: doctors.length,
						loading: false
					});
				});
		}
	}

	dIncludes = (assigned, check) => {
		if(assigned){
			const assignedArr = assigned.split(',');
			let flag = false;
			assignedArr.map(a => {
				if(check){
					const checkArr = check.split(',');
					checkArr.map(c => {
						if(a.trim() === c.trim()){
							flag = true;
						}
					});
				}
			});
			return flag;
		}
		return false;
	}

	filterDoctorsByFacilities = (doctors) => {
		const newDoctors = [];
		doctors.map(d => {
			if(this.dIncludes(this.state.userDoctor.assigned_facilities, d.assigned_facilities)){
				newDoctors.push(d);
			}
		});
		return newDoctors;
	}

	filterDoctors = () => {
		this.loadData();
	}

	handlePageChange = page => {
		this.setState({ page });
	}

	handleRowsPerPageChange = (perPage, page) => {
		this.setState({ perPage, page });
	}

	render(){
		const columns = [
			{ cell: item => <DoctorActionMenu row={item} filterDoctors={this.filterDoctors}/>,
				allowOverflow: true,
				button: true,
				width: '15px',
			},
			{ name: 'Name', selector: 'name', sortable: true, wrap: true,
				cell: item => <div align='left'>{item.last_name}, {item.first_name} {item.middle_name}</div>,
			},
			{ name: 'Username', selector: 'username', sortable: true,
			},
			{ name: 'Role', selector: 'role', sortable: true, width: '150px',
				cell: item => roles_options.filter(o => o.value === item.role)[0].label,
			},
			{ name: 'Assigned Facilities',
				cell: item => item.facilities ? (item.facilities.length ? item.facilities.map(f => {return f.name+', '}) : '') : '',
			},
			{ name: 'No. of Patients', selector: 'meta.patient_count', sortable: true, center: true,
			},
		];

		return(
			<Paper style={{ padding : 20 }}>
				<Typography component="h2" variant="h6" color="primary" gutterBottom>
					Users
				</Typography>
				<Divider/>
				<br/>
				<div align='right' style={{ paddingBottom: 10 }}>
					<Button size="small"
						variant="outlined"
						color="primary"
	      				component={Link}
	      				to="/qb-add-doctor"
						startIcon={<AddIcon />}>
							Add User
					</Button>
				</div>
				<Divider/>
				<DataTable
					noHeader
					columns={columns}
					data={this.state.doctors}
					progressPending={this.state.loading}
					progressComponent={<TableLoader loadingText="Users"/>}
					pagination={false}
					paginationServer
					paginationTotalRows={this.state.totalRows}
					paginationPage={this.state.page}
					paginationPerPage={this.state.perPage}
					onChangeRowsPerPage={this.handleRowsPerPageChange}
					onChangePage={this.handlePageChange}
					customStyles={customStyles}
				/>
				<div align='left' style={{ paddingTop: 5 }}>
					<Divider/>
					<Typography color='primary' variant='overline' style={{ paddingTop: 30, paddingRight: 5 }}>No. of Users: {this.state.doctors.length}</Typography>
				</div>
			</Paper>
		);
	}
}

export default ViewDoctors;